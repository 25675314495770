import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Config from "../../config/label";
import { msg_config } from "../../config/messages";
import { auth, sendPasswordResetEmail } from "../../firebase";
import "./Reset.css";
function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type={Config?.lbl_Inp_type_text}
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button
          className="reset__btn"
          onClick={() => sendPasswordResetEmail(email)}
        >
          {Config?.rst_txt}
        </button>
        <div>
          {msg_config?.ggl_login_qry}<Link to="/register">{Config?.lbl_register_txt}</Link>{msg_config?.ggl_login_qry_1}
        </div>
      </div>
    </div>
  );
}
export default Reset;
