import React, { useState, useEffect, useContext } from "react";
import Styles from "./index.module.css";
import IconButton from "../UI/IconButton";
import { TrashIcon } from "../../utils/appIcons";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import Delete_icon from "../../assets/icons/Delete_icon.svg";
import Editaddress_icon from "../../assets/icons/Editaddress_icon.svg";
import PopConfirm from "../UI/PopConfirm";
import { AppAlertContext } from "../../store/alert-context.js";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import placeholder from "../../assets/placeholder.png";
import Config from "../../config/label";
import frontImgg from "../../assets/cart/syrup.png";

export type productProps = {
  name?: string;
  number?: string;
  endDate?: string;
  startDate?: string;
  frontImg?: any;
  backImg?: any;
  getInsurance: any;
  oneditbtnclick?: any;
  ondeletebtnclick?: any;
  coverage?: any;
  primaryCardHolder?: any;
};

const popupStyles = {
  content: {
    zIndex: "999999",
  },
};

const InsuranceCard: React.FC<productProps> = (props) => {
  const {
    name,
    number,
    endDate,
    startDate,
    frontImg,
    backImg,
    coverage,
    oneditbtnclick,
    ondeletebtnclick,
    primaryCardHolder,
  } = props;

  const [imageSlides, setImageSlides] = useState([frontImg, backImg]);
  const [popupIdx, setPopupIdx] = useState(0);
  const [isImagePopup, setIsImagePopup] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker("My Insurance page");

  return (
    <>
      <div className={`${Styles?.container}`}>
        <div
          className={`${Styles?.cardImages}`}
          onClick={() => setIsImagePopup(true)}
        >
          <div className={`${Styles?.imageBox}`}>
            {/* <img
              src={frontImg || placeholder}
              alt="front img"
              draggable="false"
            /> */}
            <img src={frontImgg} />
          </div>
          <div className={`${Styles?.imageBox}`}>
            {/* <img
              src={backImg || placeholder}
              alt="back img"
              draggable="false"
            /> */}
            <img src={frontImgg} />
          </div>
        </div>
        <div className={`${Styles?.details}`}>
          <div className={`${Styles?.heading}`}>{/* <h1>{name}</h1> */}</div>
          <div className={`${Styles?.insuranceInfoWrapper}`}>
            {/* <div className={`${Styles?.description}`}>
              <h3>{Config?.myinsurance_label_1}{number}</h3>
              <p>{Config?.myinsurance_label_2}{coverage}</p>
              <p>{Config?.myinsurance_label_3}{startDate}</p>
              <p>{Config?.myinsurance_label_4}{endDate}</p>
            </div> */}
            {primaryCardHolder ? (
              // <p className={`${Styles?.description}`}>Primary card holder</p>
              <div className={`${Styles?.badge_pulsate_outer}`}>
                <div className={`${Styles?.badge_pulsate}`}>
                  {Config?.primary_card_holder_Short_text}
                  <div className={`${Styles?.rx_tooltip}`}>
                    {Config?.primary_card_holder_text}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={`${Styles?.actionButtons}`}>
              <IconButton
                title="Edit"
                className={`${Styles?.penFill}`}
                onClick={oneditbtnclick}
              >
                
                <i className="fa-regular fa-pen-to-square"></i>
              </IconButton>
              <IconButton
                onClick={ondeletebtnclick}
                title="Delete"
                className={`${Styles?.penFill} ${Styles?.insurance_delete_btn}`}
              >
              
                <i className="fa-solid fa-trash-can"></i>
              </IconButton>
              {/* <PopConfirm title="Are you sure ?" onConfirm={ondeletebtnclick}>
                <IconButton onClick={()=>ondeletebtnclick} title="Delete" className={`${Styles?.penFill}`}>
                  <img src={Delete_icon} alt="Delete" draggable="false" />
                </IconButton>
              </PopConfirm> */}
            </div>
          </div>
        </div>
      </div>

      {isImagePopup && (
        <Lightbox
          mainSrc={imageSlides[popupIdx]}
          nextSrc={imageSlides[(popupIdx + 1) % imageSlides.length]}
          prevSrc={
            imageSlides[
              (popupIdx + imageSlides.length - 1) % imageSlides.length
            ]
          }
          onCloseRequest={() => {
            setIsImagePopup(false);
          }}
          onMovePrevRequest={() =>
            setPopupIdx(
              (popupIdx + imageSlides.length - 1) % imageSlides.length
            )
          }
          onMoveNextRequest={() =>
            setPopupIdx(
              (popupIdx + imageSlides.length + 1) % imageSlides.length
            )
          }
          reactModalStyle={popupStyles}
          discourageDownloads={true}
        />
      )}
    </>
  );
};

export default InsuranceCard;
