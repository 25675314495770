import React, {useState} from "react";
import FlexBox from "../../../components/UI/FlexBox";
import Config from "../../../config/label";
import style from "./index.module.css";
import { siteConfig } from "../../../config/site";
import { getDecryptedID } from "../../../utils/utils";
import { CART_COUNT } from "../../../config/Config";


export type billingProps = {
  subTotal?: number;
  shipping?: number;
  total?: number;
  discount?: number;
  tax?: number;
  netTotal?: number;
  delivery_charges?:any;
};

const BillingDetails: React.FC<billingProps> = (props) => {
  const { subTotal, shipping, total, discount, tax, netTotal , delivery_charges} = props;

  const itemlength: any = (getDecryptedID(CART_COUNT));
  


  return (
    <div className={`${style?.container}`}>
      <FlexBox className={`${style?.wrapper}`}>
        <h1 className={`${style?.title_1}`}>
          {Config?.checkout_billing_title_1}
        </h1>
        <span className={`${style?.price_1}`}>{`${`${Number(itemlength) > 0
                    ? itemlength
                    : 0.0
                    } qty.`}`}</span>
      </FlexBox>
      <hr className={`${style?.checkoutPriceDivider}`}/>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>
          {Config?.checkout_billing_heading_1}
        </p>
        <span className={`${style?.price_1}`}>{`${siteConfig?.site_currency}${
          Number(subTotal) > 0 ? Number(subTotal).toFixed(2) : (0).toFixed(2)
        }`}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>
          {Config?.checkkout_billing_title_4}
        </p>
        <span className={`${style?.price_1}`}>{`${siteConfig?.site_currency}${
          Number(discount) > 0 ? Number(discount).toFixed(2) : (0).toFixed(2)
        }`}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>CGST</p>       
        <span className={`${style?.price_1}`}>{`${siteConfig?.site_currency}${typeof tax !== 'undefined' &&
          Number(tax) > 0 ? (tax / 2).toFixed(2) : (0).toFixed(2)
        }`}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>SGST</p>       
        <span className={`${style?.price_1}`}>{`${siteConfig?.site_currency}${typeof tax !== 'undefined' &&
          Number(tax) > 0 ? (tax / 2).toFixed(2) : (0).toFixed(2)
        }`}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>{Config?.lbl_delivery_charges}</p>       
        <span className={`${style?.price_1}`}>{`${siteConfig?.site_currency}${typeof delivery_charges !== 'undefined' &&
          Number(delivery_charges) > 0 ? (delivery_charges).toFixed(2) : (0).toFixed(2)
        }`}</span>
      </FlexBox>
      {/* <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>Tax</p>
        <span className={`${style?.price_1}`}>{`$${
          Number(tax) > 0 ? tax : 0.0
        }`}</span>
      </FlexBox> */}

      {/* <FlexBox
        className={`${style?.wrapper}`}
        style={{
          marginBottom: "1.2rem",
        }}
      >
        <p className={`${style?.title_2}`}>
          {Config?.checkout_billing_title_2}
        </p>
        <span className={`${style?.price_2}`}>{`$${
          Number(shipping) > 0 ? shipping : 0.0
        }`}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title_1}`}>Net total</p>
        <span className={`${style?.price_1}`}>{`$${
          Number(netTotal) > 0 ? netTotal : 0.0
        }`}</span>
      </FlexBox> */}
      <hr className={`${style?.checkoutPriceDivider}`}/>
      <FlexBox className={`${style?.wrapper}`} style={{ border: "0" }}>
        <p className={`${style?.title_3}`}>
          {Config?.checkout_billing_title_3}
        </p>
        <span className={`${style?.price_3}`}>{`${siteConfig?.site_currency}${
          Number(total) > 0 ? total : Number(0).toFixed(2)
        }`}</span>
      </FlexBox>
    </div>
  );
};

export default BillingDetails;
