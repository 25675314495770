import style from "./index.module.css";

import React from "react";
import Header from "../../components/Header";

const HeaderLayout = (props: any) => {
  return (
    <div className={`${style?.container}`}>
      <Header />
      <main className={`${style?.main}`}>{props?.children}</main>
    </div>
  );
};

export default HeaderLayout;
