import React, { useState } from "react";
import styles from "./index.module.css";
import Group2 from "../../../assets/svg/Group2.svg";
import DigitInput from "../../../components/UI/DigitInput";
import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";

const ConfirmOTP = (props: any) => {
  // Created Digital inputs ref for focusing on next field when previous filled

  const pin1Ref = React.useRef(null);
  const pin2Ref = React.useRef();
  const pin3Ref = React.useRef();
  const pin4Ref = React.useRef();
  const pin5Ref = React.useRef();
  const pin6Ref = React.useRef();

  // created useStates for setting Digital input's current value

  const [pin1, setPin1] = useState();
  const [pin2, setPin2] = useState();
  const [pin3, setPin3] = useState();
  const [pin4, setPin4] = useState();
  const [pin5, setPin5] = useState();
  const [pin6, setPin6] = useState();
  return (
    <div className={`${styles?.container}`}>
      <div className={`${styles?.image}`}>
        <img
          src={Group2}
          alt="security lock with asterisk symbol for 6 digit verification code"
          draggable="false"
        />
      </div>
      <Heading className={`${styles?.page_heading}`}>
        {Config?.heading_OTP}
      </Heading>
      <div className={`${styles?.content_wrapper}`}>
        <p className={`${styles?.content}`}>
          {Config?.txt_OTP_1}
          <span> 587-353-3287</span>
        </p>
        <p className={`${styles?.content}`}>{Config?.txt_OTP_2}</p>
      </div>
      <ul>
        <li>
          <DigitInput
            name="dg1"
            id="dg1"
            type="text"
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin1Ref}
            value={pin1}
            onChange={(e: any) => {
              setPin1(e.target.value);
              if (e.target.value.length > 0) {
                const { current }: any = pin2Ref;
                current?.focus();
              }
            }}
            onKeyPress={({ nativeEvent }: any) => {
              if (pin1) {
                const { current }: any = pin2Ref;
                current?.focus();
                setPin2(nativeEvent.key);
              }
            }}
          />
        </li>
        <li>
          <DigitInput
            name="dg2"
            id="dg2"
            type="text"
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin2Ref}
            value={pin2}
            onChange={(e: any) => {
              setPin2(e.target.value);
              if (e.target.value.length > 0) {
                const { current }: any = pin3Ref;
                current?.focus();
              } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                const { current }: any = pin1Ref;
                current?.focus();
              }
            }}
            onKeyPress={({ nativeEvent }: any) => {
              if (pin2) {
                const { current }: any = pin3Ref;
                current?.focus();
                setPin3(nativeEvent.key);
              }
            }}
          />
        </li>
        <li>
          <DigitInput
            name="dg3"
            id="dg3"
            type="text"
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin3Ref}
            value={pin3}
            onChange={(e: any) => {
              setPin3(e.target.value);
              if (e.target.value.length > 0) {
                const { current }: any = pin4Ref;
                current?.focus();
              } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                const { current }: any = pin2Ref;
                current?.focus();
              }
            }}
            onKeyPress={({ nativeEvent }: any) => {
              if (pin3) {
                const { current }: any = pin4Ref;
                current?.focus();
                setPin4(nativeEvent.key);
              }
            }}
          />
        </li>
        <li>
          <DigitInput
            name="dg4"
            id="dg4"
            type="text"
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin4Ref}
            value={pin4}
            onChange={(e: any) => {
              setPin4(e.target.value);

              if (e.target.value.length > 0) {
                const { current }: any = pin5Ref;
                current?.focus();
              } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                const { current }: any = pin3Ref;
                current?.focus();
              }
            }}
            onKeyPress={(nativeEvent: any) => {
              if (pin4) {
                const { current }: any = pin5Ref;
                current?.focus();
                setPin5(nativeEvent.key);
              }
            }}
          />
        </li>
        <li>
          <DigitInput
            name="dg5"
            id="dg5"
            type="text"
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin5Ref}
            value={pin5}
            onChange={(e: any) => {
              setPin5(e.target.value);

              if (e.target.value.length > 0) {
                const { current }: any = pin6Ref;
                current?.focus();
              } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                const { current }: any = pin4Ref;
                current?.focus();
              }
            }}
            onKeyPress={(nativeEvent: any) => {
              if (pin5) {
                const { current }: any = pin6Ref;
                current?.focus();
                setPin6(nativeEvent.key);
              }
            }}
          />
        </li>
        <li>
          {/* <DigitInput
            name="dg6"
            id="dg6"
            type={Config?.lbl_Inp_type_text}
            placeholder={Config?.inp_P_Holder_OTP}
            myref={pin6Ref}
            value={pin6}
            onChange={(e: any) => {
              setPin6(e.target.value);

              if (e.target.value.length > 0) {
                const { current }: any = pin6Ref;
                current?.focus();
              } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                const { current }: any = pin5Ref;
                current?.focus();
              }
            }}
          /> */}
        </li>
      </ul>
    </div>
  );
};

export default ConfirmOTP;
