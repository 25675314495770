import React, { useState } from "react";
import { DeleteIcon, EyeIcon, PdfIcon } from "../../../../../utils/appIcons";

// import pdf from "../../../assets/svg/pdf.svg";
import eye from "../../../../../assets/svg/eye.svg";
import pdf_doc from "../../../../../assets/file-svg/pdf_doc.svg";
import png_doc from "../../../../../assets/file-svg/png_doc.svg";
import s from "./index.module.css";
import { niceBytes } from "../../../../../utils/utils";
import { formatDateTime } from "../../../../../utils/utils";

const UploadedFileContainer = ({
  content,
  onDeleteContent,
  setDrawerOpen,
  filePreviewHandler,
  handleDeleteFile,
}: any) => {
  let date = new Date(content?.created_on);
  return (
    <div className={s["fileContainer"]} key={content?.rx_file_name}>
      <div className={s["content-img"]}>
        <img
          className={s["imgWrapper"]}
          src={content?.rx_file_name?.includes("pdf") ? pdf_doc : png_doc}
          alt=""
          draggable="false"
        />
        <div className={s["content-info"]}>
          <p className={`${s["content-name"]} ${s["truncate"]}`}>
            {content?.rx_file_name}
          </p>
          <div className={s["content_tooltip_main"]}>
            <p className={s["tooltip_text"]}>
            {content?.rx_file_name}
          </p>
          </div>
          {/* <p className={s["content-date"]}>
            {formatDateTime(date)} - {niceBytes(content?.data?.size)}
          </p> */}
        </div>
      </div>
      <div className={s["fileActions"]}>
        <button
          type="button"
          title="View content"
          onClick={() => filePreviewHandler(content)}
        >
          {/* <img src={eye} alt="Eye icon" draggable="false" /> */}
          <i className="fa-regular fa-eye" draggable="false"></i>
        </button>
        <button
          type="button"
          title="View content"
          onClick={() => handleDeleteFile(content)}
        >
          {/* <img src={eye} alt="Eye icon" draggable="false" /> */}
          <i className="fa-solid fa-trash" draggable="false"></i>
        </button>
        {/* <button
          type="button"
          title="Delete content"
          onClick={() => onDeleteContent(content?.data?.name)}
        >
          <img src={DeleteIcon} alt="Trash icon" draggable="false"/>
        </button> */}
      </div>
    </div>
  );
};

export default UploadedFileContainer;
