import React, { useState, useRef } from "react";
import CreateAccount from "./CreateAccount";
import styles from "../SignUp/index.module.css";
import ConfirmOTP from "./ConfirmOTP/ConfirmOTP";
import Button from "../../components/UI/Button";
import { ChevronRight } from "../../utils/appIcons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import btnConfig from "../../config/button";
import Config from "../../config/label";
import EnterPhoneNumber from "./EnterPhoneNumber";
import CompanyProfile from "./CompanyProfile/CompanyProfile";
import SingleSelectComponent from "./SelectType/SelectType";
import SignUpVendorContainer from "./SignUpVendorContainer/SignUpVendorContainer";
const SignUpVendor = () => {
  const phoneInputRef = useRef<any>(null);

  const [isConfirmOTP, setIsConfirmOTP] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const location = useLocation();
  const [verifyPhone, setVerifyPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [enteredPhResponse, setEnteredPhResponse] = useState();
  const [loginScreens, setLoginScreens] = useState("");
  const [isAlert, setIsAlert] = useState({ type: "", msg: "" });
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const onRegisterSubmit = (e: any) => {
    // e.preventDefault();
    setIsConfirmOTP(true);
    (isConfirmOTP || activeStep > 1) && handleNext();
  };

  const onBackHandler = (e: any) => {
    e.preventDefault();

    activeStep === 0 && setIsConfirmOTP(false);
    activeStep !== 0 && handleBack();
    if (activeStep === 0 && !isConfirmOTP) {
      navigate("/");
    }
  };

  const getSignUpForm = () => {
    switch (activeStep) {
      // case 0:
      //   return (
      //     <EnterPhoneNumber
      //       setIsLoading={setIsLoading}
      //       isLoading={isLoading}
      //       setEnteredPhResponse={setEnteredPhResponse}
      //       setLoginScreens={setLoginScreens}
      //       setIsAlert={setIsAlert}
      //       handleNext={handleNext}
      //       isVendorSignUp={true}
      //     />
      //   );
      // case 1:
      //   return (
      //     <ConfirmOTP
      //       isVendorSignUp={true}
      //       handleNext={handleNext}
      //       setIsLoading={setIsLoading}
      //       isLoading={isLoading}
      //       setIsAlert={setIsAlert}
      //       // type="social-media"
      //       setLoginScreens={setLoginScreens}
      //     />
      //   );
      case 0:
        return <CompanyProfile isVendorSignUp={true} handleNext={handleNext} />;
      // case 1:
      //   return <SingleSelectComponent setOpenHealthInfoCard={() => {}} />;
      case 1:
        return <CreateAccount isVendor={true} handleNext={handleNext} />;
      // case 5:
      //   return <CreateAccount isVendor={true} handleNext={handleNext} />;

      default:
        break;
    }
  };

  const getBackButton = () => {
    switch (activeStep) {
      case 3:
        return null;
      case 0:
        return null;
      case 1:
        return (
          <Button
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={onBackHandler}
            className={`${styles?.ghostBtn}`}
          >
            {btnConfig?.signUp_Ghost_Text_Back}
          </Button>
        );
      default:
        return (
          <Button
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={onBackHandler}
            className={`${styles?.ghostBtn}`}
          >
            {btnConfig?.signUp_Ghost_Text_Back}
          </Button>
        );
    }
  };

  const getNextButton = () => {
    switch (activeStep) {
      case 0:
        return (
          // <Button
          //   type={Config?.lbl_Btn_type_primary}
          //   size={Config?.lbl_Btn_size_medium}
          //   onClick={handleNext}
          // >
          //   <div
          //     style={{
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //     }}
          //   >
          //     {
          //       // isConfirmOTP
          //       // ? `${btnConfig?.signUp_Primary_Text_Submit}`
          //       // :
          //       `${btnConfig?.signUp_Primary_Text_V1}`
          //     }
          //     <div style={{ marginTop: 1 }}>
          //       <ChevronRight />
          //     </div>
          //   </div>
          // </Button>
          <></>
        );
      case 1:
        return (
          // <Button
          //   type={Config?.lbl_Btn_type_primary}
          //   size={Config?.lbl_Btn_size_medium}
          //   onClick={handleNext}
          // >
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     {btnConfig?.signUp_Primary_Text_V2}
          //     <div style={{ marginTop: 2 }}>
          //       <ChevronRight />
          //     </div>
          //   </div>
          // </Button>
          <></>
        );

      case 0:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            onClick={handleNext}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_V2}
              <div style={{ marginTop: 2 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );
      // case 3:
      //   return (
      //     <Button
      //       type={Config?.lbl_Btn_type_primary}
      //       size={Config?.lbl_Btn_size_medium}
      //       onClick={handleNext}
      //     >
      //       <div style={{ display: "flex", justifyContent: "center" }}>
      //         {btnConfig?.signUp_Primary_Text_V2}
      //         <div style={{ marginTop: 2 }}>
      //           <ChevronRight />
      //         </div>
      //       </div>
      //     </Button>
      //   );
      case 3:
        return <></>;

      default:
        return null;
    }
  };

  return (
    <SignUpVendorContainer>
      <div>
        <div>
          {getSignUpForm()}

          <div className={`${styles?.page_buttons}`}>
            {getBackButton()}
            {getNextButton()}
          </div>
        </div>
      </div>
    </SignUpVendorContainer>
  );
};

export default SignUpVendor;
