import React from "react";
import Button from "../../../../components/UI/Button";
import Styles from "./index.module.css";
import Heading from "../../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import noshow from "../../../../assets/svg/noshow.svg";
import CloseIcon from "../../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import btnConfig from "../../../../config/button";
import Config from "../../../../config/label";

export type ModalProps = {
  setModalOpen?: any;
};

const EmptyCartPopup: React.FC<ModalProps> = (props) => {
  const { setModalOpen } = props;
  let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setModalOpen(false);
            navigate("/", { replace: true });
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={noshow} alt="Empty Cart icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            Your cart is empty
          </Heading>
        </div>

        <Button
          type={Config?.lbl_Btn_type_primary}
          htmlType={Config?.lbl_Btn_action_submit}
          size={Config?.lbl_Btn_size_medium}
          onClick={() => {
            setModalOpen(false);
            navigate("/", { replace: true });
          }}
          className={`${Styles?.modalBtn}`}
        >
          {/*btnConfig?.order_Completion*/} Continue Shopping
        </Button>
      </div>
    </div>
  );
};
export default EmptyCartPopup;
