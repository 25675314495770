import React, { useContext, useRef, useState } from "react";
import { CloseIcon } from "../../../../utils/appIcons";
import Button from "../../../../components/UI/Button/index";
import Styles from "./index.module.css";
import btnConfig from "../../../../config/button";
import Heading from "../../../../components/UI/Heading";
import Input from "../../../../components/UI/Input";
import Label from "../../../../components/UI/Label";
import Config from "../../../../config/label";
import PhoneNumber from "../../../../components/PhoneNumber";
import APIContext from "../../../../store/api-context";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { config } from "../../../../config/processId.js";
import Alert from "../../../../components/UI/Alert";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../../utils/utils";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../../components/UI/FormInput";
import { msg_config } from "../../../../config/messages";
import LocationSearchInput from "../../../../components/LocationSearchInput";
import { PhoneFormInput } from "../../../../components/UI/PhoneFormInput";
import { UserAuthContext } from "../../../../store/UserAuthContext.js";
import { USER_PROFILE } from "../../../../config/Config";

export type ModalProps = {
  heading?: string;
  setModalOpen?: any;
  setAddPractitionerAlert?: any;
  addPractitioner?: any;
};

const AddPractitioner: React.FC<ModalProps> = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
    clearErrors,
    setError,
  } = useForm();

  const { setModalOpen, heading, setAddPractitionerAlert, addPractitioner } =
    props;
  const [loading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [suggestUsInit] = useContext(APIContext);
  const [location, setLocation] = useState({ address: "" });
  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);
  const [addressDetails, setaddressDetails] = useState([]);
  const [isAlert, setIsAlert] = useState({ type: "", msg: "" });
  const [context, setContext] = useContext(UserAuthContext);

  const phoneInputRef = useRef<any>(null);

  const onPractitionerSubmitHandler = (data: any) => {
    const { name, phone } = data;
    setIsAlert({ type: "", msg: "" });
    if (
      suggestUsInit?.returnCode === true &&
      context?.phLength >= 10 &&
      location?.address
    ) {
      setLoading(true);
      CallAPI(config?.newRx_practitioner_add_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_resource_name: name,
        p_resource_location: location?.address,
        p_resource_phone_no: phone,
        p_sgusr_id: _user_profile?.usr_id,
      }).then((res) => {
        if (res?.returnCode) {
          setAddPractitionerAlert(true);
          setModalOpen(false);
          setLoading(false);
        }
        addPractitioner();
      });
    } else if (context?.phLength < 10) {
      // setIsAlert({
      //   type: "alert",
      //   msg: "Phone number should be at least 10 characters",
      // });
      setError("phone", {
        type: "required",
        message: "Invalid phone number",
      });
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setNameValue("");
      setValue("name", "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setNameValue(newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setNameValue(newString);
    } else {
      setNameValue(e.target.value);
      setValue("name", e.target.value);
    }
  };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <form
          id="add_practitioner"
          onSubmit={handleSubmit(onPractitionerSubmitHandler)}
        >
          <div
            className={Styles["titleCloseBtn"]}
            title="Close button"
            onClick={() => setModalOpen(false)}
            draggable="false"
          >
            <img src={CloseIcon} alt="Cross icon" draggable="false" />
          </div>
          <div className={Styles["title"]}>
            <Heading className={`${Styles?.page_heading}`}>{heading}</Heading>
          </div>
          <div className={Styles["inner_input"]}>
            <div className={Styles["inner_firstChild_input"]}>
              <Label className={Styles["form_label"]} for="GeneralPractitioner">
                {Config?.General_prac_label}
              </Label>

              <FormInput
                name="name"
                id="PractitionerName"
                type={Config?.lbl_Inp_type_text}
                placeholder="Enter practitioner name"
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={nameValue}
                rules={{
                  required: msg_config?.newRx_pharmacy_required,
                }}
                errors={errors}
                maxLength={100}
                onInputChangeHandler={(e: any) =>
                  onInputChangeHandler(e, "firstName")
                }
              />
            </div>

            <div className={Styles["inner_secondChild_input"]}>
              <Label className={Styles["form_label"]} for="GeneralPractitioner">
                {Config?.Practitioner_Contact_no}
              </Label>
              <PhoneFormInput
                name="phone"
                placeholder={Config?.Pharmacy_Contact_no_placeholder}
                register={register}
                rules={{ required: msg_config?.newRx_contact_required }}
                // @ts-ignore
                errors={errors}
                control={control}
                maxLength={15}
                phoneInputRef={phoneInputRef}
                clearErrors={clearErrors}
                setError={setError}
              />
            </div>
          </div>
          <div className={Styles["inner_input"]}>
            <div className={Styles["inner_firstChild_input"]}>
              <Label className={Styles["form_label"]} for="GeneralPractitioner">
                {Config?.Practitioner_location}
              </Label>
              <LocationSearchInput
                name="addressLine1"
                setState={setLocation}
                state={location}
                register={register}
                setaddressDetails={setaddressDetails}
                rules={{ required: msg_config?.newRx_location_required }}
                setValue={setValue}
                watch={watch}
                clearErrors={clearErrors}
                errors={errors}
                placeholder={Config?.Practitioner_location_placeholder}
                isFormattedAddress={true}
                maxLength={150}
              />
            </div>
          </div>

          <div className={Styles["button_style"]} style={{ marginTop: "40px" }}>
            {/* <Button
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => {
                setModalOpen(false);
              }}
              style={{ margin: 0 }}
            >
              {btnConfig?.prac_modal_cancel}
            </Button> */}
            <Button
              type={Config?.lbl_Btn_type_primary}
              htmlType={Config?.lbl_Btn_action_submit}
              size={Config?.lbl_Btn_size_medium}
              loading={loading}
              style={{ margin: 0 }}
            >
              {btnConfig?.Add_prac_modal_button}
            </Button>
          </div>
        </form>
      </div>
      {isAlert?.msg ? <Alert type={isAlert?.type} info={isAlert?.msg} /> : ""}
    </div>
  );
};
export default AddPractitioner;
