import { createSlice } from "@reduxjs/toolkit";
import paymentModes from "../../../config/paymentModes";

const paymentModeSlice = createSlice({
  name: "paymentModes",
  initialState: {
    allowedPaymentModes: paymentModes,
  },
  reducers: {
    SET_PAYMENT_MODE: (state, action) => {
      state.selectedMode = action.payload.selectedMode;
    },
  },
});

export const paymentModeActions = paymentModeSlice.actions;
export const paymentModeReducer = paymentModeSlice?.reducer;
