import React, { useContext, useState } from "react";
import Input from "../../../../../components/UI/Input";
import Label from "../../../../../components/UI/Label";
import Styles from "./index.module.css";
import Heading from "../../../../../components/UI/Heading";
import Config from "../../../../../config/label";
import { useNavigate } from "react-router-dom";
import Alert from "../../../../../components/UI/Alert";
import { FormInput } from "../../../../../components/UI/FormInput";
import { msg_config } from "../../../../../config/messages";
import PasswordStrengthBar from "react-password-strength-bar";
import PopConfirm from "../../CreatePassword/PopConfirm";

var md5 = require("md5");

const ChangePassword = (props: any) => {
  const { register, errors } = props;
  const navigate = useNavigate();
  // const [Value, setValue] = useState();
  const [passWordStrength, setPassWordStrength] = useState("");
  const [isAlert, setIsAlert] = useState({ istrue: "", type: "", msg: "" });
  const [alert, setAlert] = useState(false);
  const [value, setValue] = useState("");
  const [passwordValue, setPasswordValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onPasswordChange = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setPasswordValue((prev) => {
        return { ...prev, [fieldName]: "" };
      });
      setPassWordStrength("");
      //@ts-ignore
      // setValue(`${[fieldName]}`, "");
    } else if (fieldName === "confirmPassword" || fieldName === "newPassword") {
      let newString = cleanInput(e.target.value);

      setPasswordValue((prev) => {
        return { ...prev, [fieldName]: newString };
      });
      setPassWordStrength(newString);
      //@ts-ignore
      // setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setPasswordValue((prev) => {
        return { ...prev, [fieldName]: newString };
      });
      setPassWordStrength(newString);
      //@ts-ignore
      // setValue(`${[fieldName]}`, newString);
    } else {
      setPasswordValue((prev) => {
        return { ...prev, [fieldName]: e.target.value };
      });
      setPassWordStrength(e.target.value);
      //@ts-ignore
      // setValue(`${[fieldName]}`, e.target.value);
    }
  };

  return (
    <>
      <div className={`${Styles?.container}`}>
        <div>
          {/* <Heading className={`${Styles?.page_heading}`}>
            {Config?.changePassword_heading_Create_Account}
          </Heading> */}
          <div>
            <div className={`${Styles?.form_control}`}>
              <div className={`${Styles?.form_inner_control}`}>
                <Label className={`${Styles?.form_label}`} for="newPassword">
                  {Config?.new_password}
                </Label>
                <PopConfirm title="Password must be atleast 8 characters and contain uppercase, lowercase, number and special character">
                  <div className={`${Styles?.inputField}`}>
                    <FormInput
                      name="newPassword"
                      id="newPassword"
                      type={Config?.lbl_Inp_type_pass}
                      placeholder={Config?.inp_P_Holder_newPassword}
                      size={Config?.lbl_Inp_size_medium}
                      register={register}
                      value={passwordValue?.newPassword}
                      rules={{
                        required: msg_config?.psw_required,
                        pattern: {
                          value:
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/gm,
                          message:
                            "Password must be minimum 8 characters and contain uppercase, lowercase, numbers and special character",
                        },
                        // onChange: (e) => onPasswordChange(e),
                      }}
                    onInputChangeHandler={(e: any) => {
                      onPasswordChange(e, "newPassword");
                    }}
                    errors={errors}
                    maxLength={20}
                    />
                  </div>
                </PopConfirm>
              </div>
            </div>

            {/* <div className={`${Styles?.form_control}`}>
              <div className={`${Styles?.form_inner_control}`}>
                <Label
                  className={`${Styles?.form_label}`}
                  for="confirmPassword"
                >
                  {Config?.confirm_password}
                </Label>
                <div className={`${Styles?.inputField}`}>
                  <FormInput
                    name="confirmPassword"
                    id="confirmPassword"
                    type={Config?.lbl_Inp_type_pass}
                    placeholder={Config?.inp_P_Holder_confirmPassword}
                    size={Config?.lbl_Inp_size_medium}
                    register={register}
                    value={passwordValue?.confirmPassword}
                    onInputChangeHandler={(e: any) => {
                      onPasswordChange(e, "confirmPassword");
                    }}
                    rules={{
                      required: msg_config?.crm_psw_required,
                    }}
                    errors={errors}
                    maxLength={20}
                  />
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className={`${Styles?.pswStrength}`}>
            <Label
              className={`${Styles?.pswStrength_label}`}
              for="passwordStrength"
            >
              {Config?.lbl_Psw_strength}
            </Label>
            <PasswordStrengthBar
              password={passWordStrength}
              scoreWordStyle={{ height: "20px" }}
            />
          </div> */}
        </div>
      </div>
      {isAlert?.istrue ? (
        <Alert type={isAlert?.type} info={isAlert?.msg} />
      ) : (
        ""
      )}
    </>
  );
};

export default ChangePassword;
