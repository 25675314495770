import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./index.module.css";
import { CloseIcon } from "../../utils/appIcons";
import APIContext from "../../store/api-context";
import Heading from "../../components/UI/Heading";
import websitelogo from "../../../src/assets/appLogo/safe_pharmacy_logo.png";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import { ShimmerText } from "react-shimmer-effects";
import { getDecryptedID, getID } from "../../utils/utils";
import { decryptData } from "../../utils/util_helper";
import { ORG_ID, SG_ORG_ID } from "../../config/Config";

const TermModalPopup = (props: any) => {
  const [suggestUsInit] = useContext(APIContext);
  const [termcontent, setTermcontent] = useState<any>([]);
  const { checked, setChecked } = props;
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const localStorageDataorg =   getDecryptedID(ORG_ID);
  const [loading, setLoading] = useState(true);
  const localStorageDatasg = decryptData(getID(SG_ORG_ID));
  const Submitpopup = () => {
    if (props?.handleNext) {
      props?.handleNext();
    }
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.term_modalpopup_process_id, {
        p_org_id: localStorageDataorg,
        sgOrgId: localStorageDatasg,
        p_type_of_terms: "SAFEPHARMACY_TERMS",
      }).then((res) => {
        setTermcontent(res?.returnData[0]?.terms_conditions);
        setLoading(false);
      });
    }
  });

  return (
    <>
      <div className={Styles["modalpopupBackground"]}>
        <div className={Styles["modalpopupContainer"]}>
          <div
            className={Styles["titletopCloseBtn"]}
            title="Close button"
            draggable="false"
            onClick={() => {
              if (props?.handleNext) {
                props?.handleNext();
              }
            }}
          >
            <img src={CloseIcon} alt="Cross icon" draggable="false" />
          </div>
          <div className={Styles["modalpopup_logo"]}>
            {/* <img
              className={Styles["modal_logo"]}
              src={websitelogo}
              alt="Cross icon"
              draggable="false"
            /> */}
          </div>
          <div className={Styles["modalpopup_content"]}>
            {loading ? <ShimmerText line={25} gap={10} /> : <div
              className={Styles["modalpopup_element"]}
              dangerouslySetInnerHTML={{ __html: termcontent }}
            >

            </div>}


            <div className={Styles["modalpopup_chexkbox"]}>
              {/* <label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                I accept all the terms & conditions.
              </label> */}
            </div>
            <div className={Styles["modalpopup_button"]}>
              <button onClick={Submitpopup}>I agree</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermModalPopup;
