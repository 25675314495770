import { AddIcon } from "../../../../utils/appIcons";
import s from "./index.module.css";

function PlusUploadButton(props: any) {
  const { onFileUploadHandler } = props;
  return (

      <label className={`${s?.uploadContainer}`}>
        <img src={AddIcon} alt="Plus sign icon button" draggable="false" />

        <p className={`${s?.uploadTitle}`}>{props?.title}</p>
        <input
          name={props?.name}
          id={props?.name}
          type="file"
          onChange={onFileUploadHandler}
          multiple={props.multiple}
          accept=".pdf, image/png, image/jpeg"
        />
      </label>

  );
}

export default PlusUploadButton;
