import { pipe, Subject } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
const subject = new Subject();

// Context - patient_banner, parent_child, actions

const messageService = {
    sendMessage: (
        senderId,
        message = {
            source,
            source_details,
            action,
            action_details,
            params
        },
        target,
        tabID
    ) => subject.next({
        text: message,
        senderId: senderId,
        target: target,
        id: tabID !== undefined ? tabID : undefined
    }),
    clearMessages: () => subject.next(),
    onMessage: (tabID) => subject.asObservable().pipe(filter(m => m && (m.id === tabID || tabID === undefined))),
};
export default messageService