import styles from "./index.module.css";
import PersonalInfoCard from "./PersonalInfoCard/PersonalInfoCard";
import Config from "../../config/label";
import { siteConfig } from "../../config/site";
import { useState, useEffect } from "react";
import { ShimmerTable, ShimmerText } from "react-shimmer-effects";
const SummaryTable = (props) => {
  const { productList, cartTotal } = props;
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    try {
      if (productList.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [productList]);

  const getPrice = (actualPrice, price) => {
    try {
      if (actualPrice === price) {
        return `${siteConfig?.site_currency}${Number(price).toFixed(2)}`;
      } else {
        return (
          <>
            `${siteConfig?.site_currency}${Number(price).toFixed(2)}
            <del>
              {" "}
              ${siteConfig?.site_currency}${Number(actualPrice).toFixed(2)}
            </del>
            `
          </>
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles?.summaryTableContainer}>
      <div className={styles?.table_wrapper}>
        <table
          className={styles?.summaryTable}
          style={{ borderRadius: "10px" }}
        >
          <thead>
            <tr>
              <td colSpan={4}>
                {!isLoading ? (
                  !!cartTotal?.billing_address_id && (
                    <PersonalInfoCard
                      billingAddressId={cartTotal?.billing_address_id}
                      shippingAddressId={cartTotal?.shipping_address_id}
                      addresses={props?.addresses}
                      isLoading={isLoading}
                    />
                  )
                ) : (
                  <ShimmerText line={6} gap={10} variant="primary" />
                )}
              </td>
            </tr>
            {/* {isLoading ? (
                <ShimmerTable row={1} col={4} />
                // <ShimmerText line={6} gap={10}  variant="primary"/>
             */}
            <tr>
              <th className={styles?.item_one}>Item</th>
              <th className={styles?.item_price}>Price</th>
              {/* <th className={styles?.item_price}>Discount</th> */}
              <th className={styles?.item_quantity}>Quantity</th>
              <th className={styles?.item_amount}>Amount</th>
            </tr>
            {/* }  */}
          </thead>
          <tbody>
            {productList?.map((product) => {
              return (
                <tr key={"summaryScreen_" + product?.srv_id}>
                  <td className={styles?.item_one}>{product?.srv_name}</td>
                  <td className={styles?.item_price}>
                    {getPrice(
                      product?.cart_item_price,
                      product?.cart_item_applicable_price
                    )}
                    {/* {Number(product?.cart_item_price).toFixed(2)} */}
                  </td>
                  <td className={styles?.item_quantity}>
                    {product?.item_quantity}
                  </td>
                  {/* <td className={styles?.item_discount}>
                  {siteConfig?.site_currency+""+ product?.cart_item_price}
                </td> */}
                  <td className={styles?.item_amount}>
                    {" "}
                    {`${siteConfig?.site_currency}${Number(
                      product?.cart_item_grand_total
                    ).toFixed(2)}`}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <table width="100%" className={styles?.sub_total_main}>
          <tr>
            <td width="55%" className={styles?.no_space}></td>
            {isLoading ? (
              // <ShimmerTable row={1} col={4} />
              <ShimmerText line={6} gap={10} variant="primary" />
            ) : (
              <td className={styles?.no_space}>
                <table width="100%">
                  <tr className={styles?.sub_order_main}>
                    <td
                      colSpan={""}
                      className={styles?.summaryTableSubHeadings}
                    >
                      {Config?.checkout_billing_heading_1}
                    </td>
                    <td className={styles?.sub_total_price}>
                      {" "}
                      {}
                      {`${siteConfig?.site_currency}${Number(
                        cartTotal?.cart_subtotal
                      ).toFixed(2)}`}
                    </td>
                  </tr>
                  <tr className={styles?.sub_order_main}>
                    <td
                      colSpan={""}
                      className={styles?.summaryTableSubHeadings}
                    >
                      {Config?.checkkout_billing_title_4}
                    </td>
                    <td className={styles?.sub_total_price}>
                      {" "}
                      {}
                      {`${siteConfig?.site_currency}${
                        Number(cartTotal?.cart_discount).toFixed(2) ||
                        (0).toFixed(2)
                      }`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={""}
                      className={styles?.summaryTableSubHeadings}
                    >
                      {Config?.checkkout_billing_title_5}
                    </td>
                    <td className={styles?.sub_total_price}>
                      {}
                      {`${siteConfig?.site_currency}${Number(
                        cartTotal?.cart_tax / 2
                      ).toFixed(2)}`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={""}
                      className={styles?.summaryTableSubHeadings}
                    >
                      {Config?.checkkout_billing_title_6}
                    </td>
                    <td className={styles?.sub_total_price}>
                      {}
                      {`${siteConfig?.site_currency}${Number(
                        cartTotal?.cart_tax / 2
                      ).toFixed(2)}`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={""}
                      className={`${styles?.summaryTableSubHeadings} ${styles?.total_price}`}
                    >
                      {Config?.lbl_delivery_charges}
                    </td>
                    <td
                      className={`${styles?.sub_total_price} ${styles?.total_price}`}
                    >
                      {`${siteConfig?.site_currency}${Number(
                        cartTotal?.delivery_charges
                      ).toFixed(2)}`}
                    </td>
                  </tr>
                  {/* <tr className={styles?.sub_total_main}>
                <td colSpan={""} className="summaryTableSubHeadings">
                  <b>Total</b>
                </td>
                <td>
                  <b> {siteConfig?.site_currency}50 </b>
                </td>
              </tr> */}
                </table>
              </td>
            )}
          </tr>
        </table>
        <table width="100%" className={styles?.total_main}>
          <tr>
            <td>
              <table width="100%">
                <tr>
                  <td width="55%" className={styles?.no_space}></td>
                  <td className={styles?.no_space}>
                    <table width="100%">
                      {isLoading ? (
                        // <ShimmerTable row={1} col={4} />
                        <ShimmerText line={6} gap={10} variant="primary" />
                      ) : (
                        <tr className={styles?.sub_total_main}>
                          <td
                            colSpan={""}
                            className={`${styles?.summaryTableSubHeadings} ${styles?.summary_total_text}`}
                          >
                            <b>Total</b>
                          </td>
                          <td
                            className={`${styles?.sub_total_price} ${styles?.summary_total_amount}`}
                          >
                            <b>
                              {" "}
                              {siteConfig?.site_currency}
                              {cartTotal?.cart_grandtotal}{" "}
                            </b>
                          </td>
                        </tr>
                      )}
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

      {/* WIDGET FOR MOBILE VIEW */}
      <div className={styles?.table_wrapper_mobile}>
        <div className={styles?.address_wrapper}>
          {!isLoading ? (
            !!cartTotal?.billing_address_id && (
              <PersonalInfoCard
                billingAddressId={cartTotal?.billing_address_id}
                shippingAddressId={cartTotal?.shipping_address_id}
                addresses={props?.addresses}
                isLoading={isLoading}
              />
            )
          ) : (
            <ShimmerText line={6} gap={10} variant="primary" />
          )}
        </div>
        <div className={styles?.items_wrapper}>
          {productList?.map((product) => (
            <div className={styles?.items_body}>
              <div className={styles?.item_name}>{product?.srv_name}</div>
              <div className={styles?.item_details}>
                <div className={styles?.item_price}>
                  {`${siteConfig?.site_currency}${Number(
                    product?.cart_item_price
                  ).toFixed(2)}`}
                </div>
                <div className={styles?.item_quantity}>
                  {product?.item_quantity}
                </div>
                <div className={styles?.item_amount}>
                  {`${siteConfig?.site_currency}${Number(
                    product?.cart_item_grand_total
                  ).toFixed(2)}`}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles?.price_wrapper}>
          <div className={styles?.price_subtotal}>
            <div className={styles?.price_subtotal_title}>Sub Total</div>
            <div className={styles?.price_subtotal_amount}>
              {" "}
              {}
              {`${siteConfig?.site_currency}${Number(
                cartTotal?.cart_subtotal
              ).toFixed(2)}`}
            </div>
          </div>
          <div className={styles?.price_discount}>
            <div className={styles?.price_subtotal_title}>Discount</div>
            <div className={styles?.price_subtotal_amount}>
              {" "}
              {}
              {`${siteConfig?.site_currency}${
                Number(cartTotal?.cart_discount).toFixed(2) || (0).toFixed(2)
              }`}
            </div>
          </div>
          <div className={styles?.price_CGST}>
            <div className={styles?.price_subtotal_title}>CGST</div>
            <div className={styles?.price_subtotal_amount}>
              {}
              {`${siteConfig?.site_currency}${Number(
                cartTotal?.cart_tax / 2
              ).toFixed(2)}`}
            </div>
          </div>
          <div className={styles?.price_SGST}>
            <div className={styles?.price_subtotal_title}>SGST</div>
            <div className={styles?.price_subtotal_amount}>
              {}
              {`${siteConfig?.site_currency}${Number(
                cartTotal?.cart_tax / 2
              ).toFixed(2)}`}
            </div>
          </div>
          <div className={styles?.price_deliverycharges}>
            <div className={styles?.price_subtotal_title}>
              {}
              {Config?.lbl_delivery_charges}
            </div>
            <div className={styles?.price_subtotal_amount}>
              {`${siteConfig?.site_currency}${Number(
                cartTotal?.delivery_charges
              ).toFixed(2)}`}
            </div>
          </div>
          <div className={styles?.price_total}>
            <div
              className={`${styles?.price_subtotal_title} ${styles?.subtotal_main}`}
            >
              <b>Total</b>
            </div>
            <div className={styles?.price_subtotal_amount}>
              <b>
                {siteConfig?.site_currency}
                {cartTotal?.cart_grandtotal}{" "}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;
