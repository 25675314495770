import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FormErrorMessage } from "../UI/FormErrorMessage";
import styles from "./index.module.css";
import { color_config } from "../../config/colorConfig";
import { siteConfig } from "../../config/site";

const LocationSearchInput = (props) => {
  const {
    state,
    setState,
    setaddressDetails,
    placeholder,
    register,
    name,
    rules,
    watch,
    setValue,
    clearErrors,
    errors,
    isFormattedAddress = true,
    maxLength,
    setAddressForFill,
    setProfileField,
    setSelectedAddress
  } = props;
  const [result, setResult] = useState(null);
  const _addressWatch = watch(name);

  useEffect(() => {
    if (_addressWatch !== "") {
      clearErrors(name);
    }
  }, [_addressWatch]);

  const getSelectedAddress = () => {

    if (
      Array?.isArray(result?.[0]?.address_components) &&
      result?.[0]?.address_components?.length > 0
    ) {
      const res = result?.[0]?.address_components.filter((item) => {
        if (item?.types[0] === "street_number" ||  item?.types[0] === "route" ||item?.types[0] === "neighborhood" || item?.types.includes('sublocality') || item?.types.includes('administrative_area_level_3')) {
          return item;
        }
      });
      const address = res?.map((item) => item?.long_name);
      // return false;
      setState(address?.join(", "));
      setProfileField((prev)=>{
        return {...prev, p_address : address?.join(", ") }
      })
      setValue(name, address?.join(", "));
    }
  };

  const getAddressDetails = () => {
    if (
      Array?.isArray(result?.[0]?.address_components) &&
      result?.[0]?.address_components?.length > 0
    ) {
      const res = result?.[0]?.address_components.filter((item) => {
        if (
          item?.types[0] === "postal_code" ||
          item?.types[0] === "country" ||
          item?.types[0] === "administrative_area_level_1" ||
          item?.types[0] === "administrative_area_level_2" ||
          item?.types[0] === "locality"
        ) {
          return item;
        }
      });

      const address = res?.map((item) => {
        return {
          ...item,
          types: item?.types[0],
        };
      });
          setaddressDetails(address);
    }
  };

  useEffect(() => {
    try {
      getSelectedAddress();
      getAddressDetails();
      if (result) {
        // setProfileField((prev)=>{
        //   return {...prev, p_address : result?.formatted_address || ""} 
        // })
        const _result = result[0];
        const address_components = _result?.address_components;
        const formatted_address = _result?.formatted_address;
        // setValue(name, formatted_address)
        address_components.map((items) => {
          const types = items?.types;
          if (types[0] === "country") {
            setProfileField((prev) => {
              return { ...prev, coutry_desc: items?.long_name || "" }
            })
          }
          if (types[0] === "postal_code") {
            setProfileField((prev) => {
              return { ...prev, p_pcode: items?.long_name || "" }
            })
          }
          if (types[0] === "administrative_area_level_2") {
            setProfileField((prev) => {
              return { ...prev, city_desc: items?.long_name || "" }
            })
          }
          if (types[0] === "administrative_area_level_1") {
            setProfileField((prev) => {
              return { ...prev, state_desc: items?.long_name || "" }
            })

          }
        })
      }

    } catch (error) {
      console.error(error);
    }
  }, [result]);

  const handleChange = (address) => {
    setSelectedAddress({})
    if (address.trim() === "") {
      setState("");
      setValue(name, "");
    } else {
      setState(address);
      setValue(name, address);
    }
  };
  
  const handleSelect = (address) => {
    setSelectedAddress({})
    geocodeByAddress(address)
      .then((results) => {
        setResult(results);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setProfileField((prev) => {
          return { ...prev, latLng: JSON.stringify(latLng) }
        })
      })
      .catch((error) => console.error("Error", error));
  };
  const searchOptions = {
    componentRestrictions: { country: [siteConfig?.country_for_address_search] },
    types: ["address"],
  };
  return (
    <>
      <input
        {...register(name, rules)}
        style={{ display: "none" }}
        maxLength={maxLength}
      />
      <PlacesAutocomplete
        value={state}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={400}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className={`${styles?.autocomplete_dropdown_container}`}>
              <input
                {...getInputProps({
                  placeholder: `${placeholder}`,
                  className: `${styles?.location_search_input}`,
                  maxLength: `${maxLength}`,
                })}
              />

              <ul id="address_list" className={`${styles?.address_list}`}>
                {suggestions.map((suggestion) => {
                  // const className = suggestion.active
                  //   ? `${styles?.suggestion_item__active}`
                  //   : `${styles?.suggestion_item}`;
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                      backgroundColor: color_config?.list_hover_bg_color,
                      fontSize: "18px",
                      lineHeight: "28px",
                      fontWeight: "600",
                      letterSpacing: "0.18px",
                      cursor: "pointer",
                      color: color_config?.common_bg_color,
                    }
                    : {
                      backgroundColor: "#ffffff",
                      fontSize: "18px",
                      lineHeight: "28px",
                      letterSpacing: "0.18px",
                      cursor: "pointer",
                      color: color_config?.common_bg_color,
                    };
                  return (
                    <li
                      {...getSuggestionItemProps(suggestion, {
                        // className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </li>
                  );
                })}
              </ul>

              <ErrorMessage
                errors={errors}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                name={name}
                render={({ message }) => (
                  <FormErrorMessage className={`${styles?.error_msg}`}>
                    {message}
                  </FormErrorMessage>
                )}
              />
            </div>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};

export default LocationSearchInput;
