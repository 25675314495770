import React from "react";
import FlexBox from "../../../../components/UI/FlexBox";
import style from "./index.module.css";
import Config from "../../../../config/label";

export type contactProps = {
  contact?: string;
  shipping?: string;
  method?: string;
};

const ContactInfo: React.FC<contactProps> = (props) => {
  const {
    contact = "johndoe@gmail.com",
    shipping = "15135 101 Ave #200, Surrey, BC V3R 7Z1",
    method = "Credit card",
  } = props;
  //   Please delete the above defined default values after finalizing backend code
  return (
    <div className={`${style?.container}`}>
      <FlexBox className={`${style?.wrapper}`}>
        <p className={`${style?.title}`}>{Config?.payment_Contact_title}</p>
        <span className={`${style?.info}`}>{contact}</span>
      </FlexBox>
      <FlexBox
        className={`${style?.wrapper}`}
        style={{
          marginBottom: "1.2rem",
        }}
      >
        <p className={`${style?.title}`}>{Config?.payment_Shipping_title}</p>
        <span className={`${style?.info}`}>{shipping}</span>
      </FlexBox>
      <FlexBox className={`${style?.wrapper}`} style={{ border: "0" }}>
        <p className={`${style?.title}`}>{Config?.payment_Method_title}</p>
        <span className={`${style?.info}`}>{method}</span>
      </FlexBox>
    </div>
  );
};

export default ContactInfo;
