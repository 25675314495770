import React, { useContext } from "react";
import styles from "./index.module.css";
import Button from "../UI/Button";
import btnConfig from "../../config/button";
import { CompletedIcon } from "../../utils/appIcons";
import { UserAuthContext } from "../../store/UserAuthContext.js";
import { Link } from "react-router-dom";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import { USER_PROFILE } from "../../config/Config";

export type productProps = {
  pid?: string;
  leftCount?: number;
  heading?: string;
  onFile?: any;
  productImage?: any;
  product_CountLeft?: any;
  containerCount?: any;
  product_address?: any;
  productList?: any;
  setProductList?: any;
  index?: any;
  onClick?: any;
  addedToCart?: boolean;
};
const ProductCard: React.FC<productProps> = (props) => {
  const {
    pid,
    heading,
    containerCount,
    product_CountLeft,
    onFile,
    productImage,
    product_address,
    addedToCart,
    onClick,
  } = props;
  const [context, setContext] = useContext(UserAuthContext);
  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  let isUserLoggedIn = sessionStorageCopy &&  Object.keys(sessionStorageCopy)?.length > 0 ? true : false;

  return (
    <div className={`${styles?.cardContainer}`}>
      <div className={`${styles?.cardTopWrapper}`}>
        <Link to={`/product-detail/${pid}`} draggable="false">
          <div className={`${styles?.cardImageBox}`}>
            <img src={productImage} alt="" draggable="false" />
          </div>
        </Link>
        <div
          //  style={{ minHeight: "9rem" }}
          className={` `}
        >
          <Link
            to={`/product-detail/${pid}`}
            style={{ textDecoration: "none" }}
            className={styles["productLink"]}
            draggable="false"
          >
            <h2 className={`${styles?.heading} ${styles?.truncate}`}>
              {heading}
            </h2>
          </Link>

          <p className={`${styles?.containerCount} ${styles?.truncate}`}>
            {containerCount}
          </p>
          <p className={`${styles?.details}`}>
            <span>Balance: {props?.leftCount}</span>
          </p>
          <p className={`${styles?.details}`}>
            <span>{props?.onFile}</span>
          </p>
        </div>
      </div>
      <hr />
      {/* {product_address && (
        <address className={`${styles?.cardAddress}`}>
          <p>{product_address}</p>
        </address>
      )} */}
      {addedToCart === true ? (
        <button className={`${styles?.ghostCardButton}`}>
          <div className={`${styles?.ghostInner}`}>
            <img
              src={CompletedIcon}
              alt="cartDone"
              style={{ width: "24px", height: "24px", marginTop: "-4px" }}
              draggable="false"
            />
            <p>{btnConfig?.product_Button_2}</p>
          </div>
        </button>
      ) : (
        <Button
          type="primary"
          size="small"
          className={`${styles?.cardButton}`}
          onClick={() => {
            if (isUserLoggedIn) {
              onClick();
            } else {
              setContext((prev: any) => ({
                ...prev,
                isLoginPopUP: true,
              }));
            }
          }}
        >
          {btnConfig?.product_Button_1}
        </Button>
      )}
    </div>
  );
};

export default ProductCard;
