import React from 'react'
import messageService from '../../../../../components/MessageService';
import DocCarousel from '../DocCarousel';
import Styles from "./index.module.css"
const ChatDocPopUp = (props) => {
    const { files, showFileIndex, src } = props;
    const closePopUp = () => {
        messageService.sendMessage('TwilioDOC', { params: { state: false } }, 'chat-doc-pop-up')
    }
    return (
        <div className={`${Styles['chatModal']} chat-doc-modal modal show ui-flex ui-horizontal-align-center`}>
            <div className='chat-doc ui-border-radius ui-shadow'>
                <div className='chat-doc-header'>
                    <i className="fa fa-close" onClick={closePopUp} />
                </div>
                <div className='chat-doc-body'>
                    <DocCarousel files={files} showFileIndex={showFileIndex} src={src} />
                </div>
            </div>
        </div>
    )
}

export default ChatDocPopUp