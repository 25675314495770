import Styles from "./index.module.css";
import HomeLayout from "../../layouts/HomeLayout";
import Footer from "../../components/Footer";
import Config from "../../config/label";
import { SettingsIcon } from "../../utils/appIcons";
import Label from "../../components/UI/Label";
import Input from "../../components/UI/Input";
import { useContext, useEffect, useState, useRef } from "react";
import Button from "../../components/UI/Button";
import { msg_config } from "../../config/messages";
import { JSONCheckerFunc, emailPattern, extractCountryCodeAndNumber, getDecryptedID, getID, objDecrypt, phonePattern, triggerLocalStorage } from "../../utils/utils";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import Alert from "../../components/UI/Alert";
import PhoneIcon from "../../../src/assets/png/call.png";
import MapIcon from "../../../src/assets/png/map.png";
import EnvelopeIcon from "../../../src/assets/png/envelope.png";
import { useForm } from "react-hook-form";
import { FormInput } from "../../components/UI/FormInput";
import { PhoneFormInput } from "../../../src/components/UI/PhoneFormInput";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { USER_LOGGED_IN, USER_PROFILE } from "../../config/Config";
import TextArea from "../../components/UI/TextArea";
import { parsePhoneNumber } from "react-phone-number-input";

export type ContactUsFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  msg: string;
};
function Contact(props: any) {
  triggerLocalStorage();
  const phoneInputRef = useRef<any>(null);
  useEffect(() => {
    phoneInputRef.current.focus();
  }, []);
  const {
    phoneNumber,
    // setPhForPassword,
  } = props;
  const apiContext = useContext(APIContext);
  const [suggestUsInit] = apiContext;
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  const [loading, setLoading] = useState(false);
  const [userMobile, setUserMOobile] = useState("");
  // let userProfileJson = JSONCheckerFunc(userProfile);
  //getting user phone number to set default

  const userPhone = userProfileJson?.usr_phone?.replace("IN +91 ", "+91");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
    clearErrors,
    setError,
    register,
  } = useForm<ContactUsFormFields>
      ({
        defaultValues: { phone: userMobile },
      });

  const checkUser = getDecryptedID(USER_LOGGED_IN);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [contactForm, setContactForm] = useState({
    firstName: userProfileJson?.usr_first_name || "",
    lastName: userProfileJson?.usr_last_name || "",
    email: userProfileJson?.usr_email || "",
    phone: userPhone || "",
    msg: "" || "",
  });
  const [isAlert, setIsAlert] = useState<any>({
    istrue: false,
    type: "",
    msg: "",
  });


  useEffect(() => {
    try {
      if (isAlert?.istrue) {
        setTimeout(() => {
          setIsAlert((prev: any) => {
            return { ...prev, istrue: false }
          })
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isAlert])

  useEffect(() => {
    try {
      const _userProfileJson = getDecryptedID(USER_PROFILE)
      // setUserMOobile(_userProfileJson?.usr_phone?.replace("IN +91 ", "+91"));
      const phoneNumber = extractCountryCodeAndNumber(_userProfileJson?.usr_phone);
      //@ts-ignore
      setValue("phone", phoneNumber)
      setValue("firstName", _userProfileJson?.usr_name.split(" ")[0]?.toString() || "")
      setValue("lastName", _userProfileJson?.usr_name.split(" ")[1]?.toString() || "")
      setValue("email", _userProfileJson?.usr_email)
      setContactForm((prev: any) => {
        return {
          ...prev,
          firstName: _userProfileJson?.usr_name.split(" ")[0]?.toString() || "",
          lastName: _userProfileJson?.usr_name.split(" ")[1]?.toString() || "",
          email: _userProfileJson?.usr_email,
          phone: phoneNumber,
        };
      });
    } catch (error) {
      console.error(error);
    }
  }, [getID(USER_PROFILE)])




  const contactSubmitHandler = handleSubmit((data) => {

    // }else {

    // }
    // setIsAlert({ istrue: false });
    const fullMobileNumber = parsePhoneNumber(`${data?.phone}`)
    const fullMobileNumberUpdated = fullMobileNumber?.country ? `${fullMobileNumber?.country} +${fullMobileNumber?.countryCallingCode} ${fullMobileNumber?.nationalNumber}` : "";

    if (fullMobileNumberUpdated === "") {
      setIsAlert({ istrue: true, type: "error", msg: msg_config?.phone_valid});

    } else {
      setLoading(true);
      if (suggestUsInit?.returnCode) {
        CallAPI(config?.contact_us_process_id, {
          p_user_id: userProfileJson?.usr_id,
          p_customer_id: userProfileJson?.customer_id,
          p_first_name: data.firstName,
          p_last_name: data.lastName,
          p_email_id: data.email,
          p_phone_no: fullMobileNumberUpdated,
          p_message: data.msg,
        }).then((res) => {
          if (!getID(USER_PROFILE)) {
            resetField("phone");
            resetField("firstName");
            resetField("lastName");
            resetField("email");
            resetField("msg");
          }
          setIsAlert({ istrue: true, type: "success", msg: res?.msg });
          setContactForm((prev: any) => {
            if (checkUser === "Y") {
              return { ...prev, msg: "" };
            } else {
              return {
                ...prev,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                msg: "",
              };
            }
          });
          setLoading(false);
        });
      }
    }
  });


  useEffect(() => {
    if (checkUser === "Y") {
      setContactForm((prev: any) => {
        return {
          ...prev,
          firstName: userProfileJson?.usr_name.split(" ")[0]?.toString() || "",
          lastName: userProfileJson?.usr_name.split(" ")[1]?.toString() || "",
          email: userProfileJson?.usr_email,
          phone: userProfileJson?.usr_phone,
        };
      });
      setIsLoggedIn(true);
    }
  }, [suggestUsInit]);

  return (
    <div className={Styles?.pageContainer}>
      <Header />
      {/* <HomeLayout /> */}
      <div className="outer_container">
        <div className={`${Styles?.detailsContainer}`}>
          <div className={`${Styles?.detailsContainer_child}`}>
            <div className={`${Styles?.detailsContainer_1}`}>
              <div className={`${Styles?.det_header}`}>
                {msg_config?.cnt_page_header}
              </div>
              <div className={`${Styles?.det_title}`}>
                {msg_config?.cnt_page_det}
              </div>
              <div className={`${Styles?.det_about}`}>
                <div className={`${Styles?.det_about_child}`}>
                  <div className={`${Styles?.det_about_child_img}`}>
                    <img src={MapIcon} alt="address" draggable="false" />
                  </div>
                  <div className={`${Styles?.det_about_child_det}`}>
                    {/* <a
                    href="https://goo.gl/maps/coEm39WyxPTZSJZc9"
                    target="_blank"
                  > */}
                    {Config?.footer_address_Line2}
                    {/* </a> */}
                  </div>
                </div>
                <div className={`${Styles?.det_about_child}`}>
                  <div className={`${Styles?.det_about_child_img}`}>
                    <img src={EnvelopeIcon} alt="address" draggable="false" />
                  </div>
                  <div className={`${Styles?.det_about_child_det}`}>
                    <a
                      href={`mailto:${msg_config?.cnt_page_mail}?subject=${msg_config?.cnt_subject}`}
                    >
                      {msg_config?.cnt_page_mail}
                    </a>
                  </div>
                </div>
                <div className={`${Styles?.det_about_child}`}>
                  <div className={`${Styles?.det_about_child_img}`}>
                    <img src={PhoneIcon} alt="address" draggable="false" />
                  </div>
                  <div className={`${Styles?.det_about_child_det}`}>
                  <a href="tel:+919041888515">{msg_config?.cnt_page_cnt_1}</a>
                    {/* <a href="tel:+919809898020">{msg_config?.cnt_page_cnt_1}</a> */}
                  </div>
                </div>
                {/* <div className={`${Styles?.det_about_child}`}>
                <div className={`${Styles?.det_about_child_img}`}>
                  <img src={PhoneIcon} alt="address" draggable="false" />
                </div>
                <div className={`${Styles?.det_about_child_det}`}>
                  <a href="tel:7783953784">{msg_config?.cnt_page_cnt_2}</a>
                </div>
              </div> */}
              </div>
            </div>
            <div className={`${Styles?.detailsContainer_1}`}>
              <form onSubmit={contactSubmitHandler} noValidate>
                {/* First Row */}
                <div className={`${Styles?.form_control}`}>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Label for="firstName" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_First_N}*
                    </Label>
                    <FormInput<ContactUsFormFields>
                      id="firstName"
                      name="firstName"
                      type={Config?.lbl_Inp_type_text}
                      size={Config?.lbl_Inp_size_small}
                      value={contactForm?.firstName}
                      placeholder={Config?.address_placeholder_1}
                      rules={{ required: msg_config?.first_name_required }}
                      register={register}
                      maxLength={50}
                      errors={errors}
                      onInputChangeHandler={(e: any) => {
                        setContactForm((prev) => {
                          return { ...prev, firstName: e.target.value.trim() };
                        });
                      }}
                      required
                      disabled={isLoggedIn}
                    />
                  </div>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Label for="lastName" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_Last_N}*
                    </Label>
                    <FormInput<ContactUsFormFields>
                      id="lastName"
                      name="lastName"
                      type={Config?.lbl_Inp_type_text}
                      size={Config?.lbl_Inp_size_small}
                      value={contactForm?.lastName}
                      placeholder={Config?.address_placeholder_2}
                      rules={{ required: msg_config?.last_name_required }}
                      register={register}
                      maxLength={50}
                      errors={errors}
                      onInputChangeHandler={(e: any) => {
                        setContactForm((prev) => {
                          return { ...prev, lastName: e.target.value.trim() };
                        });
                      }}
                      required
                      disabled={isLoggedIn}
                    />
                  </div>
                </div>
                {/* Second Row */}
                <div className={`${Styles?.form_control}`}>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Label for="email" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_Email}
                    </Label>
                    <FormInput<ContactUsFormFields>
                      id="email"
                      name="email"
                      type={Config?.lbl_Inp_type_text}
                      size={Config?.lbl_Inp_size_small}
                      value={contactForm?.email}
                      placeholder={Config?.address_placeholder_9}
                      rules={{
                        required: msg_config?.email_required,
                        pattern: emailPattern,
                      }}
                      register={register}
                      maxLength={50}
                      errors={errors}
                      onInputChangeHandler={(e: any) => {
                        setContactForm((prev) => {
                          return { ...prev, email: e.target.value.trim() };
                        });
                      }}
                      disabled={isLoggedIn}
                    />
                  </div>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Label for="phone" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_Phone}*
                    </Label>
                    <PhoneFormInput<ContactUsFormFields>
                      id="phone"
                      name="phone"
                      control={control}
                      className={`${Styles?.phoneNumber}`}
                      phoneInputRef={phoneInputRef}
                      type={Config?.lbl_Inp_type_num}
                      setError={setError}
                      clearErrors={clearErrors}
                      // size={Config?.lbl_Inp_size_small}
                      value={contactForm?.phone}
                      placeholder={Config?.inp_P_Holder_Login_number}
                      rules={{ required: msg_config?.phone_required }}
                      register={register}
                      // maxLength={50}
                      errors={errors}
                      // onChange={(e: any) => {
                      //   setContactForm((prev) => {
                      //     return { ...prev, phone: e.target.value };
                      //   });
                      // }}
                      required
                      disabled={isLoggedIn}
                    />
                  </div>
                </div>
                {/* Third Row */}
                <div className={`${(Styles?.form_control, Styles?.textArea)}`}>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Label for="msg" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_Msg}*
                    </Label>
                    {/* <FormInput
                    id="msg"
                    name="msg"
                    type={Config?.lbl_Inp_type_text}
                    size={Config?.lbl_Inp_size_large}
                    value={contactForm?.msg}
                    placeholder={Config?.address_placeholder_10}
                    rules={{ required: msg_config?.Msg_required }}
                    register={register}
                    maxLength={150}
                    errors={errors}
                    onInputChangeHandler={(e: any) => {
                      if(e.target.value.trim() !== ""){
                        setContactForm((prev) => {
                          return { ...prev, msg: e.target.value };
                        });
                      }else {
                        setContactForm((prev) => {
                          return { ...prev, msg: "" };
                        });
                      }
                    }}
                    required
                  /> */}
                    <TextArea
                      name="msg"
                      id="msg"
                      placeholder={Config?.address_placeholder_10}
                      value={contactForm?.msg}
                      // onChange={(e: any) => {
                      //   setFormTxt(e.target.value);
                      // }}
                      rules={{ required: msg_config?.Msg_required }}
                      register={register}
                      errors={errors}
                      onChangeHandler={(e: any) => {
                        if (e.target.value.trim() !== "") {
                          setContactForm((prev) => {
                            return { ...prev, msg: e.target.value };
                          });
                          setValue('msg', e.target.value)
                        } else {
                          setContactForm((prev) => {
                            return { ...prev, msg: "" };
                          });
                          setValue('msg', "")
                        }
                      }}
                      required
                      maxLength={500}
                      className={Styles?.contactUsTextArea}

                    />
                  </div>
                </div>
                <div className={`${Styles?.form_control}`}>
                  <div className={`${Styles?.form_inner_control}`}>
                    <Button
                      type={Config?.lbl_Btn_type_primary}
                      htmlType={Config?.lbl_Btn_action_submit}
                      size={Config?.lbl_Btn_size_medium}
                      loading={loading}
                    >
                      {Config?.lbl_Btn_action_submit}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {false && <div className={`${Styles?.mapDiv_container}`}>
            <a
              href="https://goo.gl/maps/coEm39WyxPTZSJZc9"
              target="_blank"
              className={`${Styles?.mapDiv}`}
            ></a>
          </div>}
        </div>
      </div>
      <Footer />
      {isAlert?.istrue ? (
        <Alert type={isAlert?.type} info={isAlert?.msg} />
      ) : (
        ""
      )}
    </div>
  );
}

export default Contact;
