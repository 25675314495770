import React, { useContext } from "react";
import APIContext from "../../store/api-context";
import { UserAuthContext } from "../../store/UserAuthContext";
import { CloseIcon } from "../../utils/appIcons";
import Button from "../UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../config/button";
import Heading from "../UI/Heading";
import Config from "../../config/label";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import Alert from "../UI/Alert";
import { AppAlertContext } from "../../store/alert-context.js";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, setEncryptedID } from "../../utils/utils";
import { IS_INSURANCE, USER_PROFILE } from "../../config/Config";

export type ModalProps = {
  heading?: string;
  setIsInsuranceModalOpen?: any;
  modalType?: string;
  editHeading?: string;
  setIsAlert?: any;
  setOpenHealthInfoCard?: any;
};

const InsuranceModal: React.FC<ModalProps> = (props) => {
  const [context, setContext] = useContext(UserAuthContext);
  const {
    setIsInsuranceModalOpen,
    heading,
    modalType,
    editHeading,
    setIsAlert,
    setOpenHealthInfoCard,
  } = props;
  const [suggestUsInit] = useContext(APIContext);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);

  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };

  const InsuranceClickHandler = (val: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    setIsInsuranceModalOpen(false);

    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.insuranceModal_process_id, {
        p_pnt_id: sessionStorageCopy?.patient_id,
        p_status_flag: val,
      }).then((res) => {
        if (res?.returnCode === true) {
          setEncryptedID(IS_INSURANCE,  "Clicked")
          // localStorage.setItem("isInsurance", "Clicked");
          // setOpenHealthInfoCard(true);
          // setIsAlert({
          //   istrue: true,
          //   type: "success",
          //   msg: "Thank you for sharing insurance details!",
          // });
        }
      });
      CallAPI(config?.update_user_insurance, {
        p_usr_insurance_flag: "Y",
        p_customer_id: sessionStorageCopy?.patient_id,
      }).then((res) => {
        if (res?.returnCode === true) {
        }
      });
    }
  };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setIsInsuranceModalOpen(false);
            setEncryptedID(IS_INSURANCE, "Clicked");
            // setOpenHealthInfoCard(true);
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {modalType === "Edit" ? editHeading : heading}
          </Heading>
        </div>

        <div>
          <p className={Styles["insurance_label"]}>
            {Config?.label_insurance} <br />
            {Config?.sub_label_insurance}
          </p>
          <div className={Styles["button_style"]} style={{ marginTop: "40px" }}>
            <Button
              type={Config?.lbl_Btn_type_primary}
              htmlType={Config?.lbl_Btn_action_submit}
              size="small"
              onClick={() => {
                InsuranceClickHandler("Y");
              }}
              style={{ margin: 0 }}
            >
              {btnConfig?.is_Insurance}
            </Button>

            <Button
              type={Config?.lbl_Btn_type_ghost}
              size="small"
              onClick={() => {
                InsuranceClickHandler("N");
              }}
              style={{ margin: 0 }}
            >
              {btnConfig?.is_not_Insurance}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InsuranceModal;
