import React, { useState } from "react";
import Input from "../../../components/UI/Input";
import PhoneNumber from "../../../components/PhoneNumber";
import Label from "../../../components/UI/Label";
import styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";

const CreateAccount = (props: any) => {
  const {isVendor} = props;
  const [phoneNumber, setPhoneNumber] = useState();
  return (
    <div className={`${styles?.container}`}>
      <div>
        <Heading className={`${styles?.page_heading}`}>
          {Config?.heading_Create_Account}
        </Heading>
        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="firstName">
              {Config?.lbl_First_N}
            </Label>
            <Input
              name="firstName"
              id="firstName"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_First_N}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="lastName">
              {Config?.lbl_Last_N}
            </Label>
            <Input
              name="lastName"
              id="lastName"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_Last_N}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
        </div>
        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="email">
              {Config?.lbl_Email}
            </Label>
            <Input
              name="email"
              id="email"
              type={Config?.lbl_Inp_type_email}
              placeholder={Config?.inp_P_Holder_Email}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="sign-upName">
              {Config?.lbl_Phone}
            </Label>
            <PhoneNumber
              // name="phone"
              // id="phone"
              // type="number"
              placeholder={Config?.inp_P_Holder_Phone}
              phoneNumber={phoneNumber}
              onChange={setPhoneNumber}
              // size={Config?.lbl_Inp_size_medium}
            />
          </div>
        </div>
        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="password">
              {Config?.lbl_Psw}
            </Label>
            <Input
              name="password"
              id="password"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_Psw}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="confirmPassword">
              {Config?.lbl_Cfrm_Pswd}
            </Label>
            <Input
              name="confirmPassword"
              id="confirmPassword"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_Cfrm_Pswd}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
