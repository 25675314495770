import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { get } from "lodash";
import styles from "./index.module.css";
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
} from "react-hook-form";
import { Input, InputProps } from "../Input";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../FormErrorMessage";

export type FormInputProps<TFormValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  // register?: UseFormRegister<TFormValues>;
  errors?: any;
  register?: any;
  inputAutoFocus?: boolean;
  maxLength?: number;
  setNewValue?: any;
  onInputChangeHandler?: any;
  value?: any;
} & Omit<InputProps, "name">;

export const FormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  maxLength,
  inputAutoFocus = false,
  setNewValue,
  onInputChangeHandler,
  value,

  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const inputRef = useRef<any>(null);
  // const [newValue, setNewValue] = useState<any>("");

  useEffect(() => {
    inputAutoFocus && inputRef.current.focus();
  }, []);

  const { ref, onChange, ...rest } = register && register(name, rules);

  // Function to remove spaces form a string
  // const cleanInput = (inp: any) => {
  //   if (inp) {
  //     return inp.replace(/\s/g, "");
  //   }
  //   return "";
  // };

  // // Function to remove special characters,numbers,space from a string
  // const cleanPersonNameInput = (inp: any) => {
  //   if (inp) {
  //     return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
  //   }
  //   return "";
  // };
  // const onInputChangeHandler = (e: any) => {
  //   if (e.target.value.trim() === "") {
  //     setNewValue("");
  //     // setProfileForm((prev: any) => {
  //     //   return { ...prev, lastName: e.target.value };
  //     // });
  //   } else if (name === "password") {
  //     let newString = cleanInput(e.target.value);
  //     setNewValue(newString);
  //   } else if (
  //     name === "firstName" ||
  //     name === "lastName" ||
  //     name === "p_firstName" ||
  //     name === "p_lastName"
  //   ) {
  //     let newString = cleanPersonNameInput(e.target.value);

  //     setNewValue(newString);
  //   } else {
  //     setNewValue(e.target.value);
  //   }
  // };

  // const onInputKeyPressHandler = (e: any) => {
  //   // if (flag !== "editProfile") {
  //   if (e.key == " " && e.target.value.trim() === "") {
  //     setNewValue("");
  //   }
  //   // }
  // };

  return (
    <div
      className={classNames("", className, styles?.inputContainer)}
      aria-live="polite"
    >
      <Input
        name={name}
        aria-invalid={hasError}
        {...rest}
        ref={(e: any) => {
          ref(e);
          inputRef.current = e;
        }}
        onChange={(e: any) => {
          onChange(e); //form onChange
          onInputChangeHandler(e);
        }}
        value={value}
        {...props}
        maxLength={maxLength}
        // value={newValue}
        // onKeyDown={onInputKeyPressHandler}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className={`${styles?.error_msg}`}>
            {message}
          </FormErrorMessage>
        )}
      />
    </div>
  );
};
