import React, { useState, useEffect } from "react";
import Styles from "./index.module.css";
import chat from "../../assets/svg/chat-left-dots.svg";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import messageService from "../MessageService";
import { CallAPI } from "../../suggest-us/callSuggest";
import { ChatModalExport } from "../../twilio-chat/src";
import axios from "axios";
import { siteConfig } from "../../config/site";
import suggestus_client_config from "../../config/suggestus_client_config";
// import './comments/chatmodel.css';
import ChatIcon from "../../assets/svg/Chat.svg"
import { USER_PROFILE } from "../../config/Config";


const FABV2 = () => {
  const [chatToken, setChatToken] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  const [showChatNotifications, setShowChatNotifications] = useState(false);
  const [playSound, setPlaySound] = useState(false);

    // unsubscribe the message service before unmounting the component
    useEffect(() => {
      // subscrib to the message service message when digital signature is done
      const subscription = messageService?.onMessage().subscribe((m) => {
        if (m.senderId === "login" && m.target === "Chat") {
          console.log("Message Service CHAT :", m);
          setUserProfile(m.text);
          getToken(m.text?.usr_id)
        }
        if (m.senderId === "TopHeader" && m.target === 'showChatModal') {
          setShowChatNotifications(m.text)
        }
        if (m.senderId === "TopHeader" && m.target === "totalCount") {
          setTotalUnreadCount(m.text)
        }
        if (m.senderId === "TopHeader" && m.target === "read") {
          setTotalUnreadCount(prev => prev - m.text)
        }
        if (m.senderId === "topHeader" && m.target === "playSound") {
          setPlaySound(m?.text)
        }
      }); 
      // // return unsubscribe method to execute when component unmounts
      return () => {
        if (subscription != null && subscription != undefined) {
          subscription.unsubscribe();
        }
      }
    }, []);

  const getToken = async (id) => {
    try {
      await axios({
        method: "GET",
        url: `${suggestus_client_config.TWILIO_URL}/chat/token?identity=${encodeURIComponent(id)}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        setChatToken(response?.data?.token); // set the token
      })
    } catch (e) {
      setChatToken("");
    }
  }

  useEffect(() => {
    let userP = getDecryptedID(USER_PROFILE);
    getToken(userP?.usr_id);
    setUserProfile(userP);
  }, [sessionStorage.getItem(USER_PROFILE)]);

  return (
    <>
    <div className="ChatTwillioContainer">
      {chatToken && (
          <div
            className={"chatBotTwilio"}
            onClick={(e) => {
              messageService.sendMessage("TopHeader", true, "chat_side_modal")
            }}
          >
            {totalUnreadCount > 0 && !showChatNotifications && <span className="data-count">{totalUnreadCount}</span>}
            {/* <i className="fa fa-comments" aria-hidden="true"></i> */}
            <img src ={ChatIcon} />
          </div>
      )}
      {chatToken && (
        <div className={Styles["modalContainer"]}>
          <ChatModalExport
            token={chatToken}
            twilioUrl={suggestus_client_config.TWILIO_URL}
            userId={userProfile?.usr_id}
            userName={userProfile?.usr_name}
            organisationId={userProfile?.org_id}
            userType={userProfile?.rol_name}
            userEmail={""}
            CallAPI={CallAPI}
            messageService={messageService}
            isGuest={false}
          />
        </div>
      )}
      </div>
    </>
  );
};

export default FABV2;
