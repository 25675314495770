import { config } from "../../config/processId";
import { CallAPI } from "../../suggest-us/callSuggest";
import styles from "./index.module.css";
import React, { useState, useRef, useEffect, memo } from "react";
import { unitPricingDropdownActions } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { objDecrypt, getDecryptedID, JSONCheckerFunc, getID, setEncryptedID } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { mylistPageActions } from "../../pages/MyListPage/slice";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
export type pricingProps = {
  pricingData?: any;
  productQty?: any;
  setProductQty?: any;
  qtyChange?: any;
  cartItemId?: any;
  //pack id if selected
  packId?: any;
  isCartScreen?: any;
  product?: any;
  getProducts?: any;
  packObject?: any;
  onDeleteHandler?: any;
  setPackDetailID?: any;
  packDetailID?: any;
  isProductCard?: any;
  setDiscountedPrice?: any;
  price?: any;
  isMerchant?: any;
  setActualPrice?: any;
};

const UnitPricingDropdown: React.FC<pricingProps> = (props) => {
  const {
    pricingData,
    productQty,
    setProductQty,
    qtyChange,
    cartItemId,
    packId,
    isCartScreen,
    product,
    getProducts,
    packObject,
    onDeleteHandler,
    setPackDetailID,
    packDetailID,
    isProductCard,
    setDiscountedPrice,
    price,
    isMerchant,
    setActualPrice
  }: any = props;

  const ref: any = useRef(null);
  const [pricingModal, setPricingModal] = useState(false);
  const selectedUnit = useSelector(
    (state: any) => state?.unitPricingDropdownReducer[product?.srv_id]
  );
  const [sSelectedUnit, setSelectedUnit] = useState(
    pricingData.length > 0 ? pricingData[0] : {}
  );
  const location = useLocation();
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const dispatch = useDispatch();
  const handleSelectUnitPricing = (e: any) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };



  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);

  //function to update the item in cart
  const handleUploadProductInCart = (selected: any, product: any) => {
    try {
      if (isCartScreen && product) {
        CallAPI(config?.customer_cart_add_product_process_id, {
          p_usr_id: decryptData(getID(SG_USER_ID)),
          p_service_detail_string:
            // product?.srv_id + "~" + selected?.srv_pack_units,
            product?.srv_id + "~" + "1",
          p_flag: product?.service_type,
          p_pack_detail_id: `${selected?.srv_pack_id}`,
          p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        }).then((res) => {
          if (res?.returnCode) {
            if (getProducts) {
              getProducts();
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(()=>{
  //   try {
  //     if(packId){
  //       pricingData.map((items:any)=>{
  //         if(items?.srv_pack_id.toString()  ===packId){
  //           setSelectedUnit(items);
  //         }
  //       })
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },[])

  //Handling out side click of modal
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (
        pricingModal &&
        event.target.id !== product?.srv_id + "dropDownButton"
      ) {
        setPricingModal(false);
      }
    }
  };

  useEffect(() => {
    if (isCartScreen && product) {
      pricingData.map((items: any) => {
        if (items?.srv_pack_id !== undefined && items?.srv_pack_id !== null) {
          if (items?.srv_pack_id.toString() === product?.srv_pack_id) {
            dispatch(
              unitPricingDropdownActions?.SELECT_PRICING({
                srv_id: product.srv_id,
                selected: items,
              })
            );
            setSelectedUnit(items);
          }
        }
      });
    }
  }, []);

  const handleDeleteItem = (product: any, selected: any) => {
    try {
      CallAPI(config?.delete_cart_item_process_id, {
        p_cart_item_id: product?.id,
        p_user_id: userProfileJson?.usr_id,
      }).then((res) => {
        if (res?.returnCode) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          handleUploadProductInCart(selected, product);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  // ADDING EVENT WHEN CLICK OUTSIDE
  useEffect(() => {
    if (pricingModal)
      document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [pricingModal]);

  const handleSelectValueUnit = (selected: any) => {
    try {
      setPricingModal(false);
      setSelectedUnit(selected);
      if (selected?.srv_pack_det_id.toString() !== product?.srv_pack_id) {
        if (location.pathname === "/my-list") {
          dispatch(
            mylistPageActions?.SELECT_PRICING({
              srv_id: product?.srv_id,
              selected: selected,
            })
          );
        }

        dispatch(
          unitPricingDropdownActions?.SELECT_PRICING({
            srv_id: product?.srv_id,
            selected: selected,
          })
        );
        setProductQty({
          productQuantity: "1",
          unitId: selected?.srv_pack_det_id,
        });
        if (isProductCard) {
          setDiscountedPrice(selected?.discounted_price);
          setActualPrice(selected?.srv_pack_units * selected?.unit_price)
        }
        //function to delete the item from the cart so can update the id of unit
        if (isCartScreen) {
          // handleDeleteItem(product, selected);
          handleUploadProductInCart(selected, product);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className={styles["dropdown_main"]}>
        <div
          className={styles["selectedPriceUnit"]}
          id={product?.srv_id + "dropDownButton"}
          onClick={() => {
            setPricingModal(!pricingModal);
          }}
        >
          {isMerchant ? selectedUnit?.srv_pack_name && (
            <h4>{selectedUnit?.srv_pack_name}</h4>
          ) : sSelectedUnit?.srv_pack_name && (
            <h4>{sSelectedUnit?.srv_pack_name}</h4>
          )}
          <div className={styles["priceWithIcon"]}>
            <h4>
              {"Save "}
              <i className="fa fa-inr" aria-hidden="true"></i>
              {isMerchant ? " " + (selectedUnit?.save_price.toFixed(2) || "") : " " + (sSelectedUnit?.save_price.toFixed(2) || "")}
            </h4>
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </div>
        {pricingModal && pricingData.length > 0 && (
          <div className={styles["uniPricingDropdown"]} ref={ref}>
            {pricingData.map((items: any, indx: number) => {
              return (
                <div
                  className={styles["UnitPriceDropdownList"]}
                  onClick={() => {
                    handleSelectValueUnit(items);
                  }}
                  key={indx}
                >
                  <h4>{items?.srv_pack_name || ""}</h4>
                  <div className={styles["priceWithIcon"]}>
                    <h4>
                      {"Save "}
                      <i className="fa fa-inr" aria-hidden="true"></i>
                      {items?.save_price.toFixed(2) || ""}
                    </h4>
                    {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default UnitPricingDropdown;
