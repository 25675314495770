export const color_config = {
  common_bg_color: "#2d4266",
  list_hover_bg_color: "#ddd",
  rx_step1_bg_color: "#fff9db",
  rx_step2_bg_color: "#ecf9ff",
  rx_step3_bg_color: "#fff4f3",
  black_color: "black",
  white_color: "white",

  ui_secondary:"#006699",
};
