import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import Group3 from "../../assets/svg/orders.svg";
import noshow from "../../assets/svg/noshow.svg";
import SideBar from "../../components/ProfileSideBar/SideBar/index";
import IconButton from "../../components/UI/IconButton";
import SelectDropdown from "../../components/UI/SelectDropdown";
import Config from "../../config/label";
import { config } from "../../config/processId";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest";
import { RightDirectionArrow, UploadRx } from "../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, objEncrypt, setEncryptedID } from "../../utils/utils";
import Drawer from "./Drawer";
import Styles from "./index.module.css";
import { color_config } from "../../config/colorConfig";
import { siteConfig } from "../../config/site";
import viewIcon from "../../assets/sf_svg/Eye.svg";
import DrawerRx from "../../pages/MyRxRequest/NewDrawer/Drawer";
import { UserAuthContext } from "../../store/UserAuthContext";
import { ORDER_DETAILS, USER_PROFILE } from "../../config/Config";



const MyOrders = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productdetails, setProductDetails] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [userInfo2, setUserInfo2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userProfileInfo, setUserProfileInfo] = useState([]);
  const [userProfileInfo2, setUserProfileInfo2] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [isNewLoading, setIsNewLoading] = useState(true);
  const [rxDetails, setRxDetails] = useState({});
  const [rxFileContents, setRxFileContents] = useState([]);
  const [myRxContext, setMyRxContext] = useState([]);
  const [isOrderInfoLoading, setIsOrderInfoLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(undefined);
  // const [range, setRange] = useState([moment().subtract(7, "day"), moment()]);
  const [drawerOpen, setDrawerOpen] = useState<any>(false);
  const [drawerOpenRx, setDrawerOpenRx] = useState<any>(false);
  const [details, setDetails] = useState({});
  const [profilePic, setProfilePic] = useState("");
  const [context, setContext] = useContext(UserAuthContext);
  //getting current route
  const location = useLocation();
  const currentRoute = location.pathname;
  const navigate = useNavigate();
  const [suggestUsInit] = useContext(APIContext);

  const localStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  const { control } = useForm();


  const getOrderList = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_order_list_process_id, {
        p_customer_id: localStorageCopy?.customer_id,
        p_min_offset: "",
        p_max_offset: "",
      }).then((res) => {
        if (res?.returnCode === true) {
          setProductList(res?.returnData);
        }
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getOrderList();
  }, [suggestUsInit]);

  useEffect(()=>{
    if(context?.isRxUploaded){
    getOrderList();
    }
  },[context])

  useEffect(() => {
    if (drawerOpen === false) {
      setDetails({});
      setUserInfo([]);
      setUserProfileInfo([]);
      setItemsList([]);
      setOrderDetails({});
      setIsNewLoading(true);
      setIsOrderInfoLoading(true);
    }
  }, [drawerOpen]);

  return (
    <>
      <div
        className={`${Styles?.mainContainer}`}
        onClick={(e) => {
          setModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="outer_container">
          <div className={`${Styles?.grid}`}>
            <div className={`${Styles?.gridLeftItem}`}>
              <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
            <div className={`${Styles?.gridRightItem}`}>
              <div className={`${Styles?.headerContainer}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Group3}
                    alt="orders"
                    style={{ height: "25px", width: "25px", marginTop: "0px" }}
                    draggable="false"
                  />
                  <h2 className={`${Styles?.heading}`}>
                    {Config?.myorders_my_order}
                  </h2>
                </div>

                {/* Please don't remove the below commented code,
               * Temporary Hidden
               *
               */}

                {/* <div style={{ zIndex: 1 }}>
                <SelectDropdown
                  name="timefilter"
                  options={activityTimeList}
                  size="small"
                  placeholder={"Select time frequency"}
                  control={control}
                />
              </div> */}
              </div>
              {isLoading ? (
                <ShimmerTable row={5} col={5} />
              ) : (
                <>
                  {productList?.length === 0 ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        marginTop: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        paddingTop: "100px",
                      }}
                    >
                      <div className={Styles["checkIconWrapper"]}>
                        <img
                          src={noshow}
                          alt="Empty Cart icon"
                          draggable="false"
                        />
                      </div>
                      <b>{Config?.myorders_no_order}</b>
                    </div>
                  ) : (
                    <ul className={`${Styles?.responsiveTable}`}>
                      <li className={`${Styles?.tableHeader}`}>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_1}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_2}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_3}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_4}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_5}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_7}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myorders_table_col_6}
                        </div>
                      </li>
                      {productList?.map((item: any) => {
                        return (
                          <li className={`${Styles?.tableRow}`}>
                            <div
                              className={`${Styles?.col} ${Styles?.col4} ${Styles?.menuLinks}`}
                              data-label={Config?.myorders_table_col_1}
                              onClick={() => {
                                setDrawerOpen(true);
                                setDetails(item);
                              }}
                            >
                              {item?.order_number}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myorders_table_col_2}
                            >
                              {item?.ord_status}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myorders_table_col_3}
                            >
                              {item?.ordered_on}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myorders_table_col_4}
                            >
                              {siteConfig?.site_currency}{item?.bill_amount}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myorders_table_col_5}
                            >
                              {item?.item_count}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4} ${item?.rx_number ? Styles?.menuLinks : ""}`}
                              data-label={Config?.myorders_table_col_7}
                              onClick={() => {
                                if(item?.rx_number){
                                  setDrawerOpenRx(true);
                                  setRxDetails({...item, id : item?.rx_id, request_number : item?.rx_number});
                                }
                              }}
                            >
                              {item?.rx_number || ""}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4} ${Styles?.actionsButton}`}
                              data-label={Config?.myorders_table_col_5}
                            >
                              {Number(item?.rx_id) > 0 ? "" : <IconButton
                                title="Upload Prescription"
                                className={`${Styles?.rightArrow}`}
                                onClick={() => {
                                  //storing data into session to get in upload rx page
                                  setEncryptedID(ORDER_DETAILS, item);
                                  // navigate("/submit-rx/upload-rx");
                                  // sessionStorage.setItem("currentRouteForUploadPrescription", currentRoute);
                                  //setting context to open the rx popup
                                  setContext((prev:any)=>{
                                    return {...prev, isRxUploadActive : true, isNewProcessId : false}
                                  })
                                  // setDrawerOpen(true);
                                  // setDetails(item);
                                }}
                              >
                                <i className="fa fa-upload" aria-hidden="true"></i>
                              </IconButton>}
                              <IconButton
                                title="View more"
                                className={`${Styles?.rightArrow}`}
                                onClick={() => {
                                  setDrawerOpen(true);
                                  setDetails(item);
                                }}
                              >
                                <img src={viewIcon} alt={"View more"} />
                              </IconButton>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {/* {productList?.length > 0 && (
                  <>
                    <div className={`${Styles?.headerWrapper}`}>
                      <div className={`${Styles?.headerItem}`}>Order #</div>
                      <div className={`${Styles?.headerItem}`}>Status</div>
                      <div className={`${Styles?.headerItem}`}>
                        Date purchased
                      </div>
                      <div className={`${Styles?.headerItem}`}>Total</div>
                      <div className={`${Styles?.headerItem}`}>Quantity </div>
                      <div className={`${Styles?.headerItem}`} />
                    </div>
                    <div>
                      {productList?.map((item: any) => (
                        <div
                          className={`${Styles?.contentWrapper}`}
                          // onClick={() => navigate(`${item?.href}/${item?.orderNo}`)}
                        >
                          <p className={`${Styles?.orderId}`}>
                            {item?.order_number}
                          </p>
                          <p className={`${Styles?.status}`}>
                            {item?.order_status}
                          </p>
                          <p className={`${Styles?.date}`}>
                            {item?.ordered_on}
                          </p>
                          <p className={`${Styles?.orderAmount}`}>
                            ${item?.bill_amount}
                          </p>
                          <p className={`${Styles?.orderQuantity}`}>
                            {item?.item_count}
                          </p>
                          <LinkButton
                            className={`${Styles?.rightArrow}`}
                            to="/"
                            draggable='false'
                          >
                            <RightDirectionArrow width="20" height="24" />
                          </LinkButton>
                        </div>
                      ))}
                    </div>
                  </>
                )} */}
                </>
              )}
              <Drawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                setDrawerOpenRx = {setDrawerOpenRx}
                setRxDetails= {setRxDetails}
                name={""}
                details={details}
                headingName={"Order details"}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                userProfileInfo={userProfileInfo}
                setUserProfileInfo={setUserProfileInfo}
                itemsList={itemsList}
                setItemsList={setItemsList}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                isNewLoading={isNewLoading}
                setIsNewLoading={setIsNewLoading}
                isOrderInfoLoading={isOrderInfoLoading}
                setIsOrderInfoLoading={setIsOrderInfoLoading}
                getOrderList={getOrderList}
              />
              <DrawerRx
                drawerOpen={drawerOpenRx}
                setDrawerOpen={setDrawerOpenRx}
                name={""}
                rxDetails={rxDetails}
                headingName={Config?.preescription_request_header}
                userInfo={userInfo2}
                setUserInfo={setUserInfo2}
                profilePic={profilePic}
                setProfilePic={setProfilePic}
                rxFileContents={rxFileContents}
                setRxFileContents={setRxFileContents}
                setMyRxContext={setMyRxContext}
                myRxContext={myRxContext}
                productdetails={productdetails}
                setProductDetails={setProductDetails}
                //@ts-ignore
                orderNumber = {orderDetails?.order_number}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
