import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import Login from "./Login";
import LoginBg from "../../assets/svg/loginBg.webp";
import { Link, useParams, useLocation } from "react-router-dom";
import VerifyOTP from "./Login/VerifyOTP/index";
import EnterPassword from "./Login/EnterPassword";
import CreatePassword from "./Login/CreatePassword";
import EnterPhoneNumber from "./Login/EnterPhoneNumber";
import InsuranceModal from "../../components/InsuranceModal";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import Alert from "../../components/UI/Alert";
import couponImg from "../../assets/sf_svg/coupon.svg";
import checkListImg from "../../assets/sf_svg/checklist.svg";
import discountImg from "../../assets/sf_svg/Discount.svg";
import bulkBuyImg from "../../assets/sf_svg/Bulk Buy.svg";
import reddyimg from "../../assets/reddy_img.png";
import ForgotPassword from "./Login/ForgotPassword";

import AppLogo from "../../assets/appLogo/safe_pharmacy_logo.png";
import proAppLogo from "../../assets/pro_app_logo.png";
import loginimg from "../../assets/login_image_2.png";
import welcomeImage from "../../assets/sf_svg/welcome.svg"
import {
  LOGINS_IN_BY,
  PRO_USER_LOGIN_PAGE_FOOTER_IMAGES,
  SHOW_PRO_USER_LOGIN_PAGE_FOOTER,
} from "../../config/Config";
import ConfirmProUserPopup from "../Landingpage/ConfirmProUserPopup";
import Config from "../../config/label";
import { useDispatch } from "react-redux";
import { signUpVendorActions } from "../SignUpVendor/slice/slice";
import TermModalPopup from "../../components/TermsModalpopup";
import { UserAuthContext } from "../../store/UserAuthContext";
import Privacy from "../Privacy";
import TermsConditions from "../TermsConditions";
import { getDecryptedID, removeKey } from "../../utils/utils";

function LogInPage(props: any) {
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const [verifyPhone, setVerifyPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [enteredPhResponse, setEnteredPhResponse] = useState();
  const [loginScreens, setLoginScreens] = useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [showForgetPassword, setshowForgetPassword] = useState(false);
  const [isAlert, setIsAlert] = useState({ type: "", msg: "" , isTrue:false});
  const [phoneNumber, setPhoneNumber] = useState("");
  const loggingBy = getDecryptedID(LOGINS_IN_BY);
  const [openTermModalPopup, setOpenTermModalPopup] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [context, setContext] = useContext(UserAuthContext);
  dispatch(
    signUpVendorActions?.updateFormField({
      name: "p_user_type",
      value: loggingBy,
    })
  );
  const [isProUser, setIsProUser] = useState({
    isActive: false,
    msg: "",
    isProRegistering: false,
    whoLogging: "",
  });

  const [isAlertChngPsw, setIsAlertChngPsw] = useState({
    istrue: "",
    type: "",
    msg: "",
  });

  useEffect(() => {
    try {
      //@ts-ignore
      setOpenTermModalPopup(context?.termsModal)
    } catch (error) {
      console.error(error);
    }
  }, [context])

  useEffect(() => {
    try {
      if (isAlert?.msg) {
        const timeout = setTimeout(() => {
          setIsAlert((prev) => ({ ...prev, msg: "", type: "" ,isTrue:false }));
        }, 3000);
  
        return () => clearTimeout(timeout);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isAlert]);
  
  const cdf: any = {
    "/verify-Phone": () => (
      <EnterPhoneNumber
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setEnteredPhResponse={setEnteredPhResponse}
        setLoginScreens={setLoginScreens}
        setIsAlert={setIsAlert}

      />
    ),
    "/verify-otp/social-media": () => (
      <VerifyOTP
        enteredPhResponse={enteredPhResponse !== undefined && enteredPhResponse}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setIsAlert={setIsAlert}
        type="social-media"
        setLoginScreens={setLoginScreens}
      />
    ),
    "/verify-otp/phone": () => (
      <VerifyOTP
        enteredPhResponse={enteredPhResponse !== undefined && enteredPhResponse}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setIsAlert={setIsAlert}
        type="phone"
        setLoginScreens={setLoginScreens}
      />
    ),
    "/createPassword": () => (
      <CreatePassword
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setIsAlert={setIsAlert}
        setLoginScreens={setLoginScreens}
      />
    ),
    "/enterPassword": () => (
      <EnterPassword
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setIsAlert={setIsAlert}
        propsshowForgetPassword={setshowForgetPassword}
      />
    ),
    "/log-in": () => (
      <Login
        isPhoneEntered={isPhoneEntered}
        setIsPhoneEntered={setIsPhoneEntered}
        setVerifyPhone={setVerifyPhone}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setIsAlert={setIsAlert}
        setLoginScreens={setLoginScreens}
        setEnteredPhResponse={setEnteredPhResponse}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setIsProUser={setIsProUser}
        propsshowForgetPassword={setshowForgetPassword}
      />
    ),
  };

  

  return loggingBy === "customer" ? (
    // if user is customer
    <div className={`${Styles?.pageContainer}`}>
      {/* {openTermModalPopup && (
        <TermModalPopup
          checked={isChecked}
          setChecked={setIsChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
            setContext((prev:any)=>{
              return {...prev, termsModal: false}
            })
          }}
        />)} */}
      {
        //@ts-ignore
        openTermModalPopup?.isTrue &&
        <div className={Styles['modalpopupBackground']}>
          <div className={Styles['modalpopupContainer']}>

            {
              //@ts-ignore
              openTermModalPopup?.type === "terms" ?
                <TermsConditions isPopup={openTermModalPopup} setOpenTermModalPopup={setOpenTermModalPopup} />
                : <Privacy isPopup={openTermModalPopup} setOpenTermModalPopup={setOpenTermModalPopup} />}
            {/*  */}
          </div>
        </div>

      }


      {showForgetPassword && (
        <ForgotPassword
          //@ts-ignore
          setIsPasswordModalOpen={setshowForgetPassword}
          setIsAlertChngPsw={setIsAlertChngPsw}
        />
      )}
      {isProUser?.isActive && (
        <ConfirmProUserPopup
          isProUser={isProUser}
          setModalOpen={setIsProUser}
        />
      )}
      <div className={Styles["mainContainer"]}>
        {/* <div
          className={Styles["leftContainer"]}>
            <div className={Styles["leftContainer-backgroundcolor"]}>
            <div  className={Styles["image_bg"]}
            style={{  
            background: `url(${LoginBg}) no-repeat center`,
            backgroundSize: "100%",
            height:"100%",
            maxHeight:"86%",
            
          }}>

          </div>
            </div>
           
         
        </div> */}
        <div className={`${Styles?.login_logo_wrapper}`}>
          <Link
            className={`${Styles?.appLogo}`}
            to="/"
            onClick={() => {
              removeKey(LOGINS_IN_BY);
              dispatch(
                signUpVendorActions?.updateFormField({
                  name: "p_user_type",
                  value: "",
                })
              );
            }}
            draggable="false"
          >
            <img
              className={`${Styles?.loginpage_logo}`}
              src={AppLogo}
              alt="app_logo"
              draggable="false"
            />
          </Link>
        </div>
        <div className={Styles["rightContainer"]}>
          <div className={Styles["login_img_wrapper"]}>
            <img src={welcomeImage} />
          </div>

          {/* <Loading spinning={isLoading}> */}
          {cdf[location?.pathname]()}
          {/* </Loading> */}
          {/* {cdf[location?.pathname]()} */}
          {/* {cdf["/verify-otp/phone"]()} */}
          {/* {cdf["/enterPassword"]()} */}
        </div>
        {isAlert?.msg ? <Alert type={isAlert?.type} info={isAlert?.msg} /> : ""}
      </div>
      {/* <Footer /> */}
    </div>
  ) : (
    //if user is pro
    <div className={`${Styles?.pageContainerForProUserr}`}>
      {/* {openTermModalPopup && (
        <TermModalPopup
          checked={isChecked}
          setChecked={setIsChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
            setContext((prev: any) => {
              return { ...prev, termsModal: false }
            })
          }}
        />)} */}
      {showForgetPassword && (
        <ForgotPassword
          //@ts-ignore
          setIsPasswordModalOpen={setshowForgetPassword}
          setIsAlertChngPsw={setIsAlertChngPsw}
        />
      )}
      {isProUser?.isActive && (
        <ConfirmProUserPopup
          isProUser={isProUser}
          setModalOpen={setIsProUser}
        />
      )}

      <div className={Styles["mainContainer"]}>
        {/* <div
          className={Styles["leftContainer"]}>
            <div className={Styles["leftContainer-backgroundcolor"]}>
            <div  className={Styles["image_bg"]}
            style={{  
            background: `url(${LoginBg}) no-repeat center`,
            backgroundSize: "100%",
            height:"100%",
            maxHeight:"86%",
            
          }}>

          </div>
            </div>
           
         
        </div> */}
        <div className={`${Styles?.login_logo_wrapper}`}>
          <Link
            className={`${Styles?.appLogo}`}
            to="/"
            onClick={() => {
              removeKey(LOGINS_IN_BY);
              dispatch(
                signUpVendorActions?.updateFormField({
                  name: "p_user_type",
                  value: "",
                })
              );
            }}
            draggable="false"
          >
            <img
              className={`${Styles?.loginpage_logo}`}
              src={proAppLogo}
              alt="app_logo"
              draggable="false"
            />
          </Link>
        </div>
        <div className={Styles["rightContainer"]}>
          <div className={Styles["login_img_wrapper"]}>
            <img src={welcomeImage} />
          </div>

          {/* <Loading spinning={isLoading}> */}
          {cdf[location?.pathname]()}
          {/* </Loading> */}
          {/* {cdf[location?.pathname]()} */}
          {/* {cdf["/verify-otp/phone"]()} */}
          {/* {cdf["/enterPassword"]()} */}
        </div>
        {isAlert?.msg ? <Alert type={isAlert?.type} info={isAlert?.msg} /> : ""}
      </div>
      <div className={Styles["prouser_login_main"]}>
        <div className={Styles["leftContainerProUser"]}>
          <div className={Styles?.leftFeatures}>
            <ul
              className={`${Styles?.listOfFeatures} ${Styles?.left_listOfFeatures}`}
            >
              <li className={`${Styles?.ListItem} ${Styles?.left_list}`}>
                <img src={couponImg} />
                <div className={Styles?.captions}>
                  <h4>{Config?.lbl_coupenCode}</h4>
                  <p>{Config?.lbl_caption_couponCodes}</p>
                </div>
              </li>
              <li className={Styles?.ListItem}>
                <img src={checkListImg} />
                <div className={Styles?.captions}>
                  <h4>{Config?.lbl_myList}</h4>
                  <p>{Config?.lbl_caption_myList}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={Styles["rightContainerProUser"]}>
          <div className={Styles?.rightFeatures}>
            <ul
              className={`${Styles?.listOfFeatures} ${Styles?.right_listOfFeatures}`}
            >
              <li className={`${Styles?.ListItem} ${Styles?.right_list}`}>
                <img src={bulkBuyImg} />
                <div className={Styles?.captions}>
                  <h4>{Config?.lbl_bulkBuying}</h4>
                  <p>{Config?.lbl_caption_bulkBuying}</p>
                </div>
              </li>
              <li className={`${Styles?.ListItem} ${Styles?.right_list}`}>
                <img src={discountImg} />
                <div className={Styles?.captions}>
                  <h4>{Config?.lbl_discount}</h4>
                  <p>{Config?.lbl_caption_discount}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={Styles["proScreenFooter"]}>
        {/* <div className={Styles['footerImages']} style={{"display": "flex"}}>
          {SHOW_PRO_USER_LOGIN_PAGE_FOOTER &&  PRO_USER_LOGIN_PAGE_FOOTER_IMAGES.map((images)=>{
            return <div className="footerImg" key={images?.id}>
              <img src={images?.image} />
            </div>
          })}
          </div> */}
        <img src={reddyimg} />
      </div>
    </div>
  );
}

export default LogInPage;
