import React, { useEffect, useState } from "react";
import StepsDetail from "./Checkout/StepsDetail";
import style from "./index.module.css";
import Checkout from "./NewCheckout";
import Payment from "./Payment";
import CartScreen from "./CartScreen";
import { useParams } from "react-router-dom";
import { triggerLocalStorage } from "../../utils/utils";

const ProductCartCheckout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { id } = useParams();
  triggerLocalStorage();
  useEffect(() => {
    switch (id) {
      case "checkout":
        setActiveStep(2);
        break;
      case "payment":
        setActiveStep(3);
        break;

      default:
        setActiveStep(0);
        break;
    }
  }, [id]);

  const getActiveScreen = () => {
    switch (id) {
      case "cart":
        return <CartScreen />;
      case "checkout":
        return <Checkout setActiveStep={setActiveStep} />;
      case "payment":
        return <Payment />;

      default:
        return <CartScreen />;
    }
  };

  return (
    <div className={style.checkoutMain}>
      {/* <div className={`${style?.stepWrapper}`}>
        <StepsDetail activeStep={activeStep} />
      </div> */}

      {getActiveScreen()}
    </div>
  );
};

export default ProductCartCheckout;
