import React, { useState } from "react";
import CreateAccount from "./CreateAccount";
import StepsDetail from "./StepsDetail";
import Location from "./Location";
import styles from "./index.module.css";
import ConfirmOTP from "./ConfirmOTP";
import Button from "../../components/UI/Button";
import { ChevronRight } from "../../utils/appIcons";
import InsuranceDetails from "../SignUp/InsuranceDetails";
import Payments from "../SignUp/Payments";
import ConsentForm from "../../components/ConsentForm";
import { useNavigate } from "react-router-dom";
import btnConfig from "../../config/button";
import Config from "../../config/label";

const SignUp = () => {
  const [isConfirmOTP, setIsConfirmOTP] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const onRegisterSubmit = (e: any) => {
    e.preventDefault();
    setIsConfirmOTP(true);
    (isConfirmOTP || activeStep > 1) && handleNext();
  };

  const onBackHandler = (e: any) => {
    e.preventDefault();

    activeStep === 0 && setIsConfirmOTP(false);
    activeStep !== 0 && handleBack();
    if (activeStep === 0 && !isConfirmOTP) {
      navigate("/");
    }
  };

  const getSignUpForm = () => {
    switch (activeStep) {
      case 0:
        return isConfirmOTP ? <ConfirmOTP /> : <CreateAccount />;
      case 1:
        return <Location />;

      case 2:
        return <InsuranceDetails />;
      case 3:
        return <Payments />;

      case 4:
        return (
          <ConsentForm
            headerStyle={{ textAlign: "center" }}
            containerClass={`${styles?.container}`}
          />
        );

      default:
        break;
    }
  };

  const getBackButton = () => {
    switch (activeStep) {
      case 5:
        return null;
      default:
        return (
          <Button
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={onBackHandler}
            className={`${styles?.ghostBtn}`}
          >
            {btnConfig?.signUp_Ghost_Text_Back}
          </Button>
        );
    }
  };

  const getNextButton = () => {
    switch (activeStep) {
      case 0:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isConfirmOTP
                ? `${btnConfig?.signUp_Primary_Text_Submit}`
                : `${btnConfig?.signUp_Primary_Text_V1}`}
              <div style={{ marginTop: 1 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );
      case 1:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_V2}
              <div style={{ marginTop: 2 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );

      case 2:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_V2}
              <div style={{ marginTop: 2 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );
      case 3:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_V2}
              <div style={{ marginTop: 2 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );
      case 4:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            onClick={() => navigate("/")}
            size={Config?.lbl_Btn_size_medium}
          >
            {btnConfig?.signUp_Primary_Text_Finish}
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`${styles?.signUp}`}>
      <div className={`${styles?.signUpLeft}`}>
        <StepsDetail activeStep={activeStep} />
      </div>
      <div className={`${styles?.signUpRight}`}>
        <form onSubmit={onRegisterSubmit}>
          {getSignUpForm()}

          <div className={`${styles?.page_buttons}`}>
            {getBackButton()}
            {getNextButton()}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
