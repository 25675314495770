

// @ts-ignore
import { suggestUsAPICall } from "./suggestApi.js";


export async function CallAPI(type, payload) {
    const response = await suggestUsAPICall(
        type,
        { ...payload },
        "1.0"
    );
    return response;
}

