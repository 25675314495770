import React, { useContext, useEffect, useState } from "react";
import SelectDropdown from "../../../../../../components/UI/SelectDropdown";
import Label from "../../../../../../components/UI/Label";
import Config from "../../../../../../config/label";
import s from "./index.module.css";
import APIContext from "../../../../../../store/api-context";
import { CallAPI } from "../../../../../../suggest-us/callSuggest.js";
import { config } from "../../../../../../config/processId.js";
import { FormInput } from "../../../../../../components/UI/FormInput";
import { msg_config } from "../../../../../../config/messages";
import { Controller } from "react-hook-form";
import { DatePickerInput } from "rc-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../../../../../../components/UI/FormErrorMessage";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../../../../utils/utils";
import AddHealthCard from "./AddHealthCard";
import { USER_PROFILE } from "../../../../../../config/Config";

const PatientDetailsForm = (props: any) => {
  const {
    register,
    errors,
    control,
    setValue,
    watch,
    clearErrors,
    setError,
    setHealthDetails,
    healthDetails,
    setUploadedDocumentIDFront,
    uploadedDocumentIDFront,
    uploadedDocumentIDBack,
    setUploadedDocumentIDBack,
    onSubmitHealthCardHandler,
    dob,
    setdob,
    dobErrorMsg,
    setDobErrorMsg,
  } = props;
  const [suggestUsInit] = useContext<any>(APIContext);
  const [loading, setLoading] = useState(false);
  // const [dob, setdob] = useState<any>(null);
  const [nameValue, setNameValue] = useState({ firstName: "", lastName: "" });
  // const [dobErrorMsg, setDobErrorMsg] = useState(false);

  const [genderList, setGenderList] = useState<any>([]);
  const _user_profile: any = getDecryptedID(USER_PROFILE)  || {
    usr_dob: "",
    usr_name: "",
    usr_gender_code: "",
  };
  // const _user_profile = (user_profile && JSONCheckerFunc(user_profile))

  const name = _user_profile?.usr_name?.trim()?.split(" ") || "";

  useEffect(() => {
    genderList.length > 0 &&
      setValue(
        "gender",
        genderList?.find((i: any) => i.value === _user_profile?.usr_gender_code)
      );
  }, [genderList]);

  // useEffect(() => {
  //   if (nameValue?.firstName) {
  //     clearErrors("firstName");
  //   } else {
  //     setError("firstName", {
  //       type: "required",
  //       message: msg_config?.first_name_required,
  //     });
  //   }
  //   if (nameValue?.lastName) {
  //     clearErrors("lastName");
  //   } else {
  //     setError("lastName", {
  //       type: "required",
  //       message: msg_config?.last_name_required,
  //     });
  //   }
  // }, [nameValue]);

  const setInitialValues = () => {
    // setValue not worked for these two fields because of controlled input fields
    setValue("firstName", (name && name[0]) || "");
    setValue("lastName", (name && name[1]) || "");
    setValue("dob", _user_profile?.usr_dob || "");
    setdob(_user_profile?.usr_dob);
    setNameValue((prev: any) => {
      return { ...prev, firstName: (name && name[0]) || "" };
    });
    setNameValue((prev: any) => {
      return { ...prev, lastName: (name && name[1]) || "" };
    });
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      setLoading(true);
      CallAPI(config?.gender_dropdown_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setGenderList([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.code,
                label: item?.description,
              };
            }),
          ]);
          setLoading(false);
        }
      });
    }

    setInitialValues();
  }, []);

  const dobChangeHandler = (val: any) => {
    setdob(val);
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setNameValue((prev: any) => {
        return { ...prev, [fieldName]: "" };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setNameValue((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setNameValue((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
      if (newString !== "") {
        if (fieldName === "firstName") {
          clearErrors("firstName");
        }

        if (fieldName === "lastName") {
          clearErrors("lastName");
        }
      }
    } else {
      setNameValue((prev: any) => {
        return { ...prev, [fieldName]: e.target.value };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  useEffect(() => {
    if (dob !== "Invalid date") {
      setDobErrorMsg(false);
    }
  }, [dob]);

  return (
    <div className={`${s?.container}`}>
      <div style={{ display: "flex" }}>
        <h2 className={`${s?.heading}`}>
          {Config?.transfer_rx_personal_detail_heading}
        </h2>
      </div>
      <div className={`${s?.form_control}`}>
        <div className={`${s?.form_inner_control}`}>
          <Label className={`${s?.form_label}`} for="firstName">
            {Config?.tRx_Lbl_1}
          </Label>
          <FormInput
            name="firstName"
            id="firstName"
            type={Config?.lbl_Inp_type_text}
            placeholder={Config?.tRx_Inp_P_Holder_1}
            size={Config?.lbl_Inp_size_small}
            disabled={_user_profile?.usr_name?.trim()}
            register={register}
            rules={{ required: msg_config?.first_name_required }}
            errors={errors}
            maxLength={25}
            value={nameValue?.firstName}
            onInputChangeHandler={(e: any) => {
              onInputChangeHandler(e, "firstName");
            }}
          />
        </div>
        <div className={`${s?.form_inner_control}`}>
          <Label className={`${s?.form_label}`} for="lastName">
            {Config?.tRx_Lbl_2}
          </Label>
          <FormInput
            name="lastName"
            id="lastName"
            type={Config?.lbl_Inp_type_text}
            placeholder={Config?.tRx_Inp_P_Holder_2}
            size={Config?.lbl_Inp_size_small}
            disabled={_user_profile?.usr_name?.trim()}
            register={register}
            rules={{ required: msg_config?.last_name_required }}
            errors={errors}
            maxLength={25}
            value={nameValue?.lastName}
            onInputChangeHandler={(e: any) => {
              onInputChangeHandler(e, "lastName");
            }}
          />
        </div>
      </div>

      <div className={`${s?.form_control}`}>
        <div className={`${s?.form_inner_control}`}>
          <Label className={`${s?.form_label}`} for="gender">
            {Config?.tRx_Lbl_3}
          </Label>
          <SelectDropdown
            name="gender"
            options={genderList}
            size="small"
            placeholder={Config?.tRx_Select_P_Holder}
            loading={loading}
            disabled={_user_profile?.usr_gender_code?.trim()}
            control={control}
            rules={{ required: msg_config?.gender_required }}
            errors={errors}
          />
        </div>
        <div className={`${s?.form_inner_control}`}>
          <Label className={`${s?.form_label}`} for="date">
            {Config?.tRx_Lbl_4}
          </Label>

          <Controller
            control={control}
            name="dob"
            rules={{ required: msg_config?.DOB_required }}
            render={({
              field: { name, onChange, onBlur, value, ref },
              fieldState: { error },
            }) => {
              return (
                <div>
                  <DatePickerInput
                    onChange={(e: any) => {
                      onChange(e);
                      dobChangeHandler(e);
                    }}
                    value={dob === "Invalid date" ? "" : dob}
                    placeholder={Config?.tRx_Date_P_Holder}
                    displayFormat={"LL"}
                    disabled={_user_profile?.usr_dob?.trim()}
                    maxDate={new Date()}
                  />
                  {/* <ErrorMessage
                    errors={{ [name]: { ...error } }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    name={name as any}
                    render={({ message }) => {
                      return <FormErrorMessage>{message}</FormErrorMessage>;
                    }}
                  /> */}
                  {dobErrorMsg ? (
                    <FormErrorMessage className={`${s?.error_msg}`}>
                      {msg_config?.new_transfer_rx_msg_dob}
                    </FormErrorMessage>
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <h2 className={`${s?.heading}`}>
          {Config?.transfer_rx_health_info_heading}
        </h2>
      </div>
      <AddHealthCard
        setHealthDetails={setHealthDetails}
        healthDetails={healthDetails}
        register={register}
        errors={errors}
        watch={watch}
        setUploadedDocumentIDFront={setUploadedDocumentIDFront}
        uploadedDocumentIDFront={uploadedDocumentIDFront}
        setUploadedDocumentIDBack={setUploadedDocumentIDBack}
        uploadedDocumentIDBack={uploadedDocumentIDBack}
        onSubmitHealthCardHandler={onSubmitHealthCardHandler}
      />
    </div>
  );
};

export default PatientDetailsForm;
