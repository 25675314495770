import styles from "./index.module.css";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { useContext, useEffect, useState } from "react";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { config } from "../../../config/processId";
import { ShimmerSectionHeader, ShimmerTable, ShimmerTitle, ShimmerText } from "react-shimmer-effects";
import { getDecryptedID, getID } from "../../../utils/utils";
import { SG_PATIENT_ID } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
import Config from "../../../config/label";


const PersonalInfoCard = (props) => {
  const [addresses, setAddresses] = useState({});
  const { billingAddressId, shippingAddressId, isLoading } = props;


  useEffect(() => {
    CallAPI(config?.patient_address_list_process_id, {
      p_pnt_id: decryptData(getID(SG_PATIENT_ID)),
    }).then((res) => {
      let address = {};
      if (res?.returnCode) {
        res?.returnData.map((items) => {
          if (items?.p_patadd_id === billingAddressId) {
            address['billingAddress'] = items
          }
          if (items?.p_patadd_id === shippingAddressId) {
            address['deliveryAddress'] = items
          }
        })
        setAddresses(address);
      } else {
        console.error(res?.msg);
      }
    });
  }, []);
  // let [(!!addresses[1] || addresses[0]), addresses[0]] = addresses;
  const [context, setContext] = useContext(UserAuthContext);
  return (

    <div className={`${styles.personalInfoCardContainer}`}>

      <div className={`${styles.personalInfoCardAddressContainer}`}>
        {isLoading ? <ShimmerTitle line={2} gap={10} variant="primary" /> : <div className={`${styles.personalInfoCardPrimaryAddress}`}>
          <div className={`${styles.personalInfoCardUserName}`}>
            {/* {context?.UserAuth?.usr_name} */}
            {/* Billing Address <br /> */}
            {/* <p> */}
            {addresses?.billingAddress?.patadddet_pnt_name}{" "}
            {addresses?.billingAddress?.patadddet_pnt_last_name}
            {/* </p> */}
          </div>
          <h6>{Config?.lbl_summary_biling_address}</h6>
          <p>
            {context?.UserAuth?.usr_company_name}
            {addresses?.billingAddress?.p_address}{" "}
          </p>
          <p>
            {/* {context?.UserAuth?.usr_company_landline_no} <br /> */}
            {addresses?.billingAddress?.city_desc}, {addresses?.billingAddress?.state_desc}{" "}
            {addresses?.billingAddress?.p_pcode}
          </p>
          {/* <p>{context?.UserAuth?.usr_address}</p> */}
          {/* <p>{context?.UserAuth?.usr_company_postal}</p> */}
        </div>}
      </div>

      <div className={`${styles.personalInfoCardAddressContainer}`}>
        {isLoading ? <ShimmerTitle line={2} gap={10} variant="primary" /> : <div
          className={`${styles.personalInfoCardPrimaryAddress} ${styles.delivery_address}`}
        >
          <div className={`${styles.personalInfoCardUserNameDelivery}`}>
            {/* {context?.UserAuth?.usr_name} */}
            {addresses?.deliveryAddress?.patadddet_pnt_name}{" "}
            {addresses?.deliveryAddress?.patadddet_pnt_last_name}
          </div>
          <h6>{Config?.lbl_summary_delivery_address}</h6>

          <p>{context?.UserAuth?.usr_company_name}</p>
          <p>{addresses?.deliveryAddress?.p_address}</p>
          <p>
            {addresses?.deliveryAddress?.city_desc},{" "}
            {addresses?.deliveryAddress?.state_desc}{" "}
            {addresses?.deliveryAddress?.p_pcode}
          </p>
          {/* <p>{context?.UserAuth?.usr_company_landline_no}</p> */}
        </div>}
      </div>
    </div>
  );
};

export default PersonalInfoCard;
