import React, { useState } from "react";
import { DeleteIcon, EyeIcon, PdfIcon } from "../../../utils/appIcons";

import pdf from "../../../assets/svg/pdf.svg";
import eye from "../../../assets/svg/eye.svg";
import pdf_doc from "../../../assets/file-svg/pdf_doc.svg";
import png_doc from "../../../assets/file-svg/png_doc.svg";
import s from "./index.module.css";
import { niceBytes } from "../../../utils/utils";
import { formatDateTime } from "../../../utils/utils";

const UploadedFileContainer = ({
  content,
  onDeleteContent,
  setDrawerOpen,
  filePreviewHandler,
}: any) => {
  let date = new Date(content?.data?.lastModified);

  return (
    <div className={s["fileContainer"]} key={content?.id}>
      <div className={s["content-img"]}>
        <img
          src={content?.data?.name?.includes("pdf") ? pdf_doc : png_doc}
          alt=""
          draggable="false"
        />
        <div className={s["content-info"]}>
          <p className={s["content-name"]}>{content?.data?.name}</p>
          <p className={s["content-date"]}>
            {formatDateTime(date)} - {niceBytes(content?.data?.size)}
          </p>
        </div>
      </div>
      <div className={s["fileActions"]}>
        <button
          type="button"
          title="View content"
          onClick={() => filePreviewHandler(content)}
        >
          {/* <img src={eye} alt="Eye icon" draggable="false" /> */}
          <i className="fa-regular fa-eye" draggable="false"></i>
        </button>
        <button className={s["delete_upload_img"]}
          type="button"
          title="Delete content"
          onClick={() => onDeleteContent(content?.data?.name)}
        >
          {/* <img src={DeleteIcon} alt="Trash icon" draggable="false" /> */}
          <i className="fa-solid fa-trash-can" draggable="false"></i>
        </button>
      </div>
    </div>
  );
};

export default UploadedFileContainer;
