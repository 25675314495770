import {
  CompletedIcon,
  DisabledIcon,
  ActiveIcon,
} from "../../../../../utils/appIcons";
import styles from "./index.module.css";

export type StepperProps = {
  steps: any;
  activeStep: any;
};

const RxSteps: React.FC<StepperProps> = (props: any) => {
  const { activeStep } = props;
  return (
    <div className={`${styles?.stepperContainer}`}>
      {props?.steps?.map((step: any, index: any) => (
        <div key={step.label} className={`${styles?.stepWrapper}`}>
          <div className={`${styles?.stepIconWrapper}`}>
            <div className={`${styles?.stepIcon}`}>
              {index === activeStep ? (
                <img src={ActiveIcon} alt="" draggable="false" />
              ) : index <= activeStep - 1 ? (
                <img src={CompletedIcon} alt="" draggable="false" />
              ) : (
                <img src={DisabledIcon} alt="" draggable="false" />
              )}
            </div>
            <div className={`${styles?.stepLable}`}>
              <h2 className={`${styles?.stepLable_heading}`}>{step.label}</h2>
              <p className={`${styles?.stepLable_description}`}>
                {step.description}
              </p>
            </div>
          </div>
          <div
            className={
              index <= Number(props?.steps?.length - 2)
                ? `${styles?.stepperBorder}`
                : ""
            }
          />
        </div>
      ))}
    </div>
  );
};

export default RxSteps;
