/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import Stepper from "../../../components/UI/Stepper";
import Config from "../../../config/label";

function RxSteps(props: any) {
  const { activeStep } = props;

  const steps = [
    {
      label: `${Config?.uploadRx_stepper_Heading__1}`,
      description: `${Config?.uploadRx_stepper_detail__1}`,
    },
    /*{
      label: `${Config?.uploadRx_stepper_Heading__2}`,
      description: `${Config?.uploadRx_stepper_detail__2}`,
    },
    { // consent form
      label: `${Config?.uploadRx_stepper_Heading__3}`,
      description: `${Config?.uploadRx_stepper_detail__3}`,
    },*/
    {
      label: `${Config?.uploadRx_stepper_Heading__4}`,
      description: `${Config?.uploadRx_stepper_detail__4}`,
    },
  ];

  return <Stepper steps={steps} activeStep={activeStep} />;
}

export default RxSteps;
