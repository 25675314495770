import React, { useState, useContext, useEffect, useRef } from "react";
import APIContext from "../../../../store/api-context";
import Button from "../../../../components/UI/Button";
import { AppLogo } from "../../../../utils/appIcons";
import Label from "../../../../components/UI/Label";
import Styles from "./index.module.css";
import Config from "../../../../config/label";
import Heading from "../../../../components/UI/Heading";
import PhoneNumber from "../../../../components/PhoneNumber";
import { Link, useNavigate } from "react-router-dom";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { config } from "../../../../config/processId.js";
import { PhoneFormInput } from "../../../../components/UI/PhoneFormInput";
import { useForm } from "react-hook-form";
import { msg_config } from "../../../../config/messages";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { UserAuthContext } from "../../../../store/UserAuthContext.js";
import { JSONCheckerFunc, setEncryptedID } from "../../../../utils/utils";
import { CURRENT_SCREEN, PHONE_NO } from "../../../../config/Config";

export type RegistrationFormFields = {
  phone: string;
};

function EnterPhoneNumber(props: any) {
  const {
    isLoading,
    setIsLoading,
    setEnteredPhResponse,
    setLoginScreens,
    setIsAlert,
    handleNext,
  } = props;

  const userInfo: any = localStorage?.getItem("userInfo");
  const _userInfo = JSONCheckerFunc(userInfo);
  const [context, setContext] = useContext(UserAuthContext);
  const [suggestUsInit] = useContext(APIContext);

  const verify_OTP: any = sessionStorage?.getItem("verify_OTP");
  const _verify_OTP = JSONCheckerFunc(verify_OTP);

  const phoneInputRef = useRef<any>(null);
  useEffect(() => {
    phoneInputRef.current.focus();
  }, []);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });
  const gaEventTracker = useAnalyticsEventTracker("Enter phone number page");
  const setSessionStorage = (res: any) => {
    setEncryptedID(PHONE_NO, res?.returnData[0]?.phone_no);
    setEncryptedID(CURRENT_SCREEN,res?.returnData[0]?.current_screen);
  };
  const phoneVerificationHandler = (data: any) => {
    setIsAlert({ type: "", msg: "" });

    if (suggestUsInit?.returnCode && context?.phLength >= 10) {
      setIsLoading(true);
      CallAPI(config?.verify_phone_process_id, {
        p_usr_id: _verify_OTP?.user_id,
        p_phone: data?.phone,
        p_internal_flag: "",
      }).then((res) => {
        setIsLoading(false);
        if (res?.returnCode === true) {
          // Storing response in session
          if (res?.returnData?.length > 0) {
            setSessionStorage(res);
          }
          setEnteredPhResponse(res?.returnData);
          setLoginScreens(res?.returnData[0]?.current_screen);

          if (res?.returnData[0]?.current_screen === "verify_otp") {
            navigate("/verify-otp/social-media");
            gaEventTracker("User redirected to verify OTP screen");
          }
        } else {
          setIsAlert({ type: "error", msg: res?.msg });
        }
      });
    } else if (context?.phLength < 10) {
      setError("phone", {
        type: "required",
        message: "Invalid phone number",
        
      });
    }
    // if (handleNext) {
    //   handleNext();
    // }
  };

  return (
    <>
      <Link className={`${Styles?.appLogo}`} to="/" draggable="false">
        <img src={AppLogo} alt="app_logo" draggable="false" />
      </Link>
      <div className={Styles["loginContainer"]}>
        <Heading className={`${Styles?.page_heading}`}>
          Hi {_userInfo?.displayName}!
        </Heading>

        <div className={`${Styles?.email_wrapper}`}>
          <p className={`${Styles?.email_initials}`}>
            {_userInfo?.email?.charAt(0)?.toUpperCase()}
          </p>
          <p className={`${Styles?.email_label}`}>{_userInfo?.email}</p>
        </div>

        <span className={`${Styles?.confirmlabel}`}>
          {Config?.lbl_confirm_phone}
        </span>
        <form onSubmit={handleSubmit(phoneVerificationHandler)}>
          <div className={Styles["inputField"]}>
            <div style={{ marginTop: "32px" }}>
              {/*               <Label className={`${Styles?.form_label}`} for="phone">
                {Config?.lbl_Login_number}
              </Label> */}
              <PhoneFormInput<RegistrationFormFields>
                name="phone"
                register={register}
                rules={{ required: msg_config?.login_phone_required }}
                errors={errors}
                control={control}
                placeholder={Config?.inp_P_Holder_Login_number}
                className={`${Styles?.phoneNumber}`}
                phoneInputRef={phoneInputRef}
                maxLength={15}
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>
          </div>

          <div className={Styles["verifyButton"]}>
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              style={{ width: "360px" }}
              loading={isLoading}
            >
              {Config?.lbl_verify_Btn}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EnterPhoneNumber;
