import { useState, useEffect } from "react";
import styles from "./index.module.css";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import ProductCardMerchant from "../../components/ProductCardMerchant";
import check_icon from "../../assets/icons/check_mark.svg";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";
import { mylistPageActions } from "./slice";
import ProductCardCustomer from "../../components/ProductCardCustomer";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import { decryptData } from "../../utils/util_helper";
import { getID } from "../../utils/utils";
import { SG_USER_ID } from "../../config/Config";
import { ShimmerThumbnail } from "react-shimmer-effects";
import noshow from "../../assets/svg/noshow.svg";
import Config from "../../config/label";

const MyListProducts = (props) => {
  const {
    selectedList,
    userProfileJson,
    setSelectedList,
    setFilteredProducts,
    filteredProducts,
    setProducts,
    selectedProducts,
    myLists,
    myListRefresh,
    setmyListRefresh
  } = props;

  const [loading, setLoading] = useState(true);

  const [update, setUpdate] = useState(true);
  const dispatch = useDispatch();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState({
    istrue: false,
    deleteType: "",
  });
  const products = useSelector(
    (state) => state?.myListPageReducer?.filteredProducts
  );
  const getProducts = async () => {
    const res = await CallAPI(config?.get_my_list_products, {
      // sgOrgId: localStorage.getItem("org_id"),
      p_min_offset: 0,
      p_max_offset: 10,
      p_mylist_id: selectedList?.selectId,
      p_cus_id: userProfileJson?.customer_id,
      // sgUserId: localStorage.getItem("sg_userId"),
      p_srv_id: "",
    });
    let returnData = [];
    if (isArray(res?.returnData) && res?.returnData?.length !== 0) {
      returnData = res?.returnData?.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    if (res?.returnCode) {
      dispatch(mylistPageActions?.SET_PRODUCTS({ products: returnData }));
      setLoading(false);
    }
    // setProducts(returnData);
    // setFilteredProducts(returnData);
  };
  useEffect(() => {
    try {
      if (selectedList?.selectId === "") {

      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error(error);
    }
    // if (selectedList !== "0")

    (async () => {
      const res = await getProducts();
      // const { p_list_title, p_list_description } = myLists.find(
      //   (l, idx) => l.p_list_id === selectedList
      // )[0];
    })();
  }, [selectedList, myListRefresh]);
  //   useEffect(() => {}, []);
  return (
    <div className={`${styles?.myListProductsContainer}`}>
      {loading ?
        <>
          <ShimmerThumbnail width={300} height={200} rounded />
          <ShimmerThumbnail width={300} height={200} rounded />
          <ShimmerThumbnail width={300} height={200} rounded />
          <ShimmerThumbnail width={300} height={200} rounded />
          <ShimmerThumbnail width={300} height={200} rounded />
        </>
        :
        products.length > 0 ?
          products.map((product, idx) => (
            <ProductCardCustomer
              idx={idx}
              setSelect={setFilteredProducts}
              product={product}
              type={product?.service_type}
              viewType="Grid"
              productUrl={product?.srv_id}
              imgUrl={product?.srv_image.split("~")[0]}
              imgAlt={product?.srv_name}
              detail={product?.srv_name}
              packagingId={product?.srv_packaging}
              brand={product?.srv_brand}
              price={product?.discounted_amount}
              actualPrice={product?.srv_price}
              discountPercent={product?.discount_percent}
              coPay={product?.copayment_amount}
              ratingCount={
                product?.rating_view_flag === "Y" && product?.rating_star_count
              }
              isWishListAdded={product?.wshlst_status === "Y" ? true : false}
              ribbon={true}
              ribbonType="folded"
              onLikeButtonClick={() => { }}
              onAddtoCartButtonClick={() => { }}
              isSelected={product?.isSelected}
              icon={check_icon}
              onIconClick={(e) => {
                setShowDeleteConfirm({
                  istrue: true,
                  deleteType: "product",
                  p_srv_id: product?.srv_id,
                });
                // (async () => {
                //   let res = await CallAPI(
                //     config?.pro_user_remove_item_my_list_process_id,
                //     {
                //       p_cus_id: userProfileJson.customer_id,
                //       p_usr_id: localStorage.getItem("sg_userId"),
                //       p_mylist_id: selectedList,
                //       p_internal_flag: "",
                //       p_srv_id: product?.srv_id,
                //     }
                //   );
                //   if (res?.returnCode === true) {
                //     res = await CallAPI(config?.pro_user_get_my_list_process_id, {
                //       p_cus_id: userProfileJson?.customer_id,
                //       sgUserId: localStorage?.getItem("sg_userId"),
                //       p_srv_id: "",
                //     });
                //     dispatch(
                //       mylistPageActions?.SET_MY_LISTS({ myLists: res.returnData })
                //     );
                //     res = await getProducts();
                //     // setUpdate(true);
                //     // setProducts((prev)=>prev.filter((i)=>i.))
                //   }
                // })();
              }}
            />
            // <></>
          )) : selectedList?.selectId === "" ? <div className={styles?.noDataFound}>
            <img src={noshow} />
            <p>{Config?.lbl_no_list_is_selected}</p>
          </div> : <div className={styles?.noDataFound}>
            <img src={noshow} />
            <p>{Config?.lbl_no_products_found}</p>
          </div>}




      {showDeleteConfirm.istrue && (
        <DeleteConfirmPopup
          setModalOpen={setShowDeleteConfirm}
          userProfileJson={userProfileJson}
          p_list_id={""}
          setmyListRefresh={setmyListRefresh}
          showDeleteConfirm={showDeleteConfirm}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        // setIsAlert={setIsAlert}
        />
      )}
    </div>
  );
};

export default MyListProducts;
