import styles from "./index.module.css";

export type InputProps = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange?: any;
  style?: any;
  size: string;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  ref?: any;
  value?: any;
  disabled?: any;
  required?: any;
  defaultValue?: any;
  maxLength?: any;
};

const QtyInput: React.FC<InputProps> = (props) => {
  const {
    name,
    id,
    type,
    placeholder,
    onChange,
    className,
    onFocus,
    onBlur,
    ref,
    value,
    disabled,
    required,
    defaultValue,
    maxLength,
  } = props;

  const getInputSize = () => {
    switch (props?.size) {
      case "large":
        return {  fontSize: "20px" };

      case "medium":
        return {  fontSize: "18px" };
      case "small":
        return {  fontSize: "14px" };

      default:
        return {};
    }
  };
  return (
    <input
      name={name}
      id={id}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      className={`${styles.inputStyle} ${className}`}
      style={{ ...props?.style, ...getInputSize() }}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={ref}
      value={value}
      disabled={disabled}
      required={required}
      defaultValue={defaultValue}
      autoFocus
      maxLength={maxLength}
    />
  );
};

export default QtyInput;
