import { msg_config } from "../config/messages";
import { siteConfig } from "../config/site";
import * as CryptoTS from "crypto-ts";
import { CallAPI } from "../suggest-us/callSuggest";
import { config } from "../config/processId";
import { CART_COUNT, DEFAULT_FLAG, HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, INSURANCE_FRONT_ID, IS_INSURANCE, PATIENT_ID, PRODUCT_CARD_IMAGES, PRODUCT_TYPE, PROFILE_IMAGE, PROFILE_IMAGE2, QB_ID, SG_PATIENT_ID, SG_ROLE_ID, SG_USER_ID, SIGN_IN_WITH, USER_ID, USER_LOGGED_IN, USER_PROFILE, USER_ROLE_ID } from "../config/Config";
import { useDispatch } from "react-redux";
import { signUpVendorActions } from "../pages/SignUpVendor/slice/slice";
import { decryptData } from "./util_helper";

export function niceBytes(x: number) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = x || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export const formatDateTime = (date: Date) => {
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "long",
    timeStyle: "short",
  }).format(date);
};

export const getInitials = (name: any) => {
  if (name) {
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  }
  return "";
};

// Use this utility to convert base64 image/png url to blob
export const getImagePngBlob = (imageURL: any, fileName: string) => {
  var file;
  fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      file = new File([blob], fileName, { type: "image/png" });
    });
};
// regex replaced for Email verification
export const emailPattern = {
  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  message: msg_config?.email_valid,
};
// new func for Phone no check
export const phonePattern = {
  value: /^[0-9]+$/,
  message: msg_config?.phone_valid,
};

export const objEncrypt = (data: any) => {
  let cipher = CryptoTS.AES.encrypt(JSON.stringify(data), CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_KEY), {
    iv: CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_IV),
    mode: CryptoTS.mode.CBC,
    padding: CryptoTS.pad.PKCS7
  });

  return cipher.toString();
};

export const objDecrypt = (data: any) => {
  try {
    if (data != null && data != undefined && data != "") {
      let decrypted = CryptoTS.AES.decrypt(data, CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_KEY), {
        iv: CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_IV),
        mode: CryptoTS.mode.CBC,
        padding: CryptoTS.pad.PKCS7
      });
      return JSONCheckerFunc(CryptoTS.enc.Utf8.stringify(decrypted));
    } else {
      return JSONCheckerFunc("null");
    } 
  } catch (error) {
    console.error(":>> data",data, error);
  }
};

export const setEncryptedID = (key: any, value: any) => {
  try {
    const encryptedData = objEncrypt(value);
    sessionStorage.setItem(key, encryptedData);
  } catch (error) {
    console.error(error);
  }
}


export const getDecryptedID = (key: any) => {
  try {
    const data = sessionStorage.getItem(key);
    return objDecrypt(data);
  } catch (error) {
    console.error(error);
  }
}

export const getID = (key:any) => {
  try {
    return  sessionStorage.getItem(key);
  } catch (error) {
    console.error(error);
  }
}

export const setID = (key:any, value:any) => {
  try {
     sessionStorage.setItem(key, value);
  } catch (error) {
    console.error(error);
  }
}

export const strEncrypt = (data: any) => {
  try {
    let cipher = CryptoTS.AES.encrypt(data.toString(), CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_KEY), {
      iv: CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_IV),
      mode: CryptoTS.mode.CBC,
      padding: CryptoTS.pad.PKCS7
    });
    return cipher.toString();
  } catch (error) {
    return '';
  }
}
export const strDecrypt = (data: any) => {
  try {
    let decipher = CryptoTS.AES.decrypt(data, CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_KEY), {
      iv: CryptoTS.enc.Utf8.parse(siteConfig?.SPD_OPENSSL_IV),
      mode: CryptoTS.mode.CBC,
      padding: CryptoTS.pad.PKCS7
    });
    return CryptoTS.enc.Utf8.stringify(decipher);
  } catch (error) {
    return '';
  }
}

export const JSONCheckerFunc = (str: string) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return "{}";
  }
  return JSON.parse(str);
}

export const proAddCartHandler = (
  product: any,
  productQty: any,
  setIsAlert: any,
  gaEventTracker: any,
  suggestUsInit: any,
  setIsCartPopUPOpen: any,
  setIsAdding: any,
  setLoading?: any,
) => {
  setIsAlert({
    istrue: false,
    type: "",
    msg: "",
  });
  if (suggestUsInit?.returnCode === true) {
    // dispatch(cartSliceActions?.ADD_TO_CART({item:{p_usr_id: getID(SG_USER_ID),
    // p_service_detail_string:
    //   product?.srv_id + "~" + productQty?.productQuantity,
    // p_flag: product?.service_type,
    // p_pack_detail_id: `${productQty?.unitId}`,
    // p_patient_id: getID(SG_PATIENT_ID)}}))

    setIsAdding(true);
    setLoading({
      isTrue: true,
      productId: product?.srv_id,
    })
    CallAPI(config?.customer_cart_add_product_process_id, {
      p_usr_id: decryptData (getID(SG_USER_ID)),
      p_service_detail_string: product?.srv_id + "~1",
      p_flag: product?.service_type,
      p_pack_detail_id: `${productQty?.unitId}`,
      p_patient_id: decryptData (getID(SG_PATIENT_ID)),
    }).then((res: any) => {
      if (res?.returnCode) {
        if (res?.returnData[0].cart_count !== undefined) {
          setEncryptedID(CART_COUNT, res?.returnData[0].cart_count);
          gaEventTracker("Items added to cart", res?.returnData[0].cart_count);
          // setContext((prev: any) => ({
          //   ...prev,
          //   cartCount: res?.returnData[0].cart_count,
          // }));
        } else {
          setEncryptedID(CART_COUNT,res?.returnData[0].cart_item_count);
          gaEventTracker(
            "Items added to cart",
            res?.returnData[0].cart_item_count
          );
          // setContext((prev: any) => ({
          //   ...prev,
          //   cartCount: res?.returnData[0].cart_item_count,
          // }));
        }
        setIsCartPopUPOpen(siteConfig?.checkout_popup);
        setIsAdding(false);
        setLoading({
          isTrue: false,
          productId: "",
        })
        setIsAlert({
          istrue: true,
          type: "success",
          msg: res?.msg,
        });
      } else {
        setIsAlert({
          istrue: true,
          type: "error",
          msg: res?.msg,
        });
        setIsAdding(false);
        setLoading({
          isTrue: false,
          productId: "",
        })
      }
    });
  }
};

export const removingUserFromSession = () => {
  try {
    removeKey(USER_PROFILE);
    setEncryptedID(USER_LOGGED_IN, "N");
    removeKey(SG_ROLE_ID);
    removeKey(SG_USER_ID);
    removeKey(SG_PATIENT_ID);
    removeKey(IS_INSURANCE);
    removeKey(PROFILE_IMAGE);
    removeKey(PROFILE_IMAGE2);
    removeKey(USER_ID);
    removeKey(QB_ID);
    removeKey(USER_ROLE_ID);
    removeKey(DEFAULT_FLAG);
    removeKey(PATIENT_ID);
    removeKey(CART_COUNT);
    removeKey(INSURANCE_FRONT_ID);
    removeKey(INSURANCE_BACK_ID);
    removeKey(HEALTH_CARD_FRONT_ID);
    removeKey(HEALTH_BACK_ID);
    removeKey(HEALTH_INFO);
    clearSession();
    setEncryptedID(SIGN_IN_WITH, " ");
  } catch (error) {
    console.error(error);
  }
};

export const removeKey = (key: any) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}

export const clearSession = () => {
  try {
    sessionStorage.clear();
  } catch (error) {
    console.error(error);
  }
}


export const getProductImage = (productType: any, imgUrl: any) => {
  try {
    const lowerTypes = productType?.toLowerCase() || "";
    if (imgUrl === undefined || imgUrl === null || imgUrl === "") {
      if (lowerTypes) {
        switch (lowerTypes.trim()) {
          case PRODUCT_TYPE?.tablet:
            return PRODUCT_CARD_IMAGES?.tablet;
          case PRODUCT_TYPE?.syrup:
            return PRODUCT_CARD_IMAGES?.syrup;
          case PRODUCT_TYPE?.bottle:
            return PRODUCT_CARD_IMAGES?.bottle;
          case PRODUCT_TYPE?.capsule:
            return PRODUCT_CARD_IMAGES?.capsule;
          case PRODUCT_TYPE?.devices:
            return PRODUCT_CARD_IMAGES?.devices;
          case PRODUCT_TYPE?.drops:
            return PRODUCT_CARD_IMAGES?.drops;
          case PRODUCT_TYPE?.injection:
            return PRODUCT_CARD_IMAGES?.injection;
          case PRODUCT_TYPE?.spray:
            return PRODUCT_CARD_IMAGES?.spray;
          case PRODUCT_TYPE?.tube:
            return PRODUCT_CARD_IMAGES?.tube;
          case PRODUCT_TYPE?.lotion:
            return PRODUCT_CARD_IMAGES?.lotion;
          default:
            return PRODUCT_CARD_IMAGES?.defaultImg;
        }
      } else {
        return PRODUCT_CARD_IMAGES?.defaultImg;
      }
    } else {
      return imgUrl;
    }

  } catch (error) {
    console.error(error);
  }
};

export const pricerConverterToDecimal = (price: any) => {
  try {
    return price

  } catch (error) {
    console.error(error);
  }
}

//function to trigger localstorage
export const triggerLocalStorage = ()=>{
  try {    
    if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', 'foobar');
        localStorage.removeItem('getSessionStorage');
      };
  } catch (error) {
    console.error(error);
  }
}


export function extractCountryCodeAndNumber(input:any) {
  try {
  // Remove all non-digit characters from the input
  const digitsOnly = input.replace(/\D/g, '');

  // Extract the country code (first two digits)
  const countryCode = digitsOnly.substr(0, 2);

  // Extract the remaining digits as the phone number
  const phoneNumber = digitsOnly.substr(2);
  return `+${countryCode}${phoneNumber}`
  // return {
  //     countryCode,
  //     phoneNumber
  // };  
  } catch (error) {
    console.error(error);
  }
}