import SummaryTable from "../../components/SummaryTable/SummaryTable";
import Payments from "../../components/Payments/Payments";
import styles from "./index.module.css";
import Config from "../../config/label";
import { useEffect, useState, useContext } from "react";
import { config } from "../../config/processId";
import { CallAPI } from "../../suggest-us/callSuggest";
import { UserAuthContext } from "../../store/UserAuthContext";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt } from "../../utils/utils";
import { LOGGED_IN_USER_ROLE, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
const Summary = () => {
  const [cartTotal, setCartTotal] = useState("");
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [productList, setProductList] = useState([]);
  const [rxProductList, setRxProductList] = useState([]);
  const userProfileJson = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [addresses, setAddresses] = useState({});

  const [context, setContext] = useContext(UserAuthContext);

  const getCartTotal = () => {
    CallAPI(config?.get_cart_total_process_id, {
      p_user_id: userProfileJson?.usr_id,
      p_patient_id: userProfileJson?.patient_id,
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData?.length === 0) {
          setIsCartEmpty(true);
        } else {
          // setBillingAddress(res?.returnData[0]?.billing_address_id);
          // setDeliveryAddress(res?.returnData[0]?.shipping_address_id);
          setCartTotal(res?.returnData[0]);
          // setIsCartEmpty(false);
        }
      }
    });
  };

  const getProducts = () => {
    CallAPI(config?.get_cart_process_id, {
      p_user_id: decryptData(getID(SG_USER_ID)),
      p_patient_id: decryptData( getID(SG_PATIENT_ID)),
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData.length === 0) {
          // setIsCartEmpty(true);
        } else {
          getCartTotal();
          if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
            setProductList(res?.returnData);
          } else {
            setProductList(
              res?.returnData?.filter(
                (val) => val?.item_type !== "rx_not_submitted"
              )
            );
            setRxProductList(
              res?.returnData.filter(
                (val) => val?.item_type === "rx_not_submitted"
              )
            );
          }
        }
      } else {
        // setIsCartEmpty(true);
      }
    });
  };

  useEffect(() => {
    getProducts();
  }, []);



  return (
    <>
      <div className={styles.summary_main}>
        <div className="outer_container">
          <div className={styles.summary_title}>
            <p className={styles?.summaryTableHeading}>
              {Config?.signUp_stepper_Heading__4}
            </p>
          </div>
          <div className={styles.summary_wrapper}>
            <div className={styles.summary_table_main}>
              <SummaryTable
                productList={productList}
                addresses={addresses}
                cartTotal={cartTotal}
              />
            </div>
            <Payments />
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
