import React, { useState } from "react";
import styles from "./index.module.css";

export type DrawerProps = {
  isOpen: boolean;
  setIsOpen: any;
  children?: any;
};

const SideDrawer: React.FC<DrawerProps> = (props) => {
  const { isOpen = false, setIsOpen, children } = props;
  return (
    <>
      <div
        id="side-nav"
        className={`${styles?.sidenav} ${isOpen && styles?.openNav}`}
      >
        <button
          className={`${styles?.closebtn}`}
          onClick={() => setIsOpen(false)}
        >
          &times;
        </button>
        <div id="nav-body">{ children }</div>
      </div>
      {isOpen && (
        <div
          id="side-nav-overlay"
          className={`${styles?.overlay}`}
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
};

export default SideDrawer;
