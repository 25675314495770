import React, { useCallback, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./index.module.css";
import { Link } from "react-router-dom";
import Button from "../UI/Button";
import { useState } from "react";
import DocViewer from "../MyDocuments/DocViewer/DocViewer";
import { UserAuthContext } from "../../store/UserAuthContext";
import { CallAPI } from "../../suggest-us/callSuggest";
import Axios from "axios";
import { siteConfig } from "../../config/site";
import { config } from "../../config/processId";
import Alert from "../UI/Alert";
import Config from "../../config/label";
import Modal from "../UI/VerificationModal";
import calling from "../../assets/svg/calling.svg";
import { ORDER_DETAILS, USER_PROFILE } from "../../config/Config";
import { getDecryptedID, removeKey } from "../../utils/utils";
import APIContext from "../../store/api-context";
import Drawer from "../UI/NewDrawer/Drawer";
import uploadText from "../../assets/svg/uploadText.svg"
import Tickmark from "../../assets/svg/Tickmark.svg"
import fileview_heading from "../../assets/svg/fileview_heading.svg"
import upload_closebtn from "../../assets/svg/upload_closebtn.svg"
import docx_icon from "../../assets/svg/docx_icon.svg"
import doc_icon from "../../assets/svg/doc_icon.svg"
import pdf_icon from "../../assets/svg/pdf_icon.svg"

const Rxpage = (props) => {
  const [uploadedFile, setUploadedFile] = useState([]);
  const [selectedOption, setSelectedOption] = useState("false");
  const [generatedRx, setGeneratedRx] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(null);
  const _user_profile = getDecryptedID(USER_PROFILE);
  const [suggestUsInit] = useContext(APIContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [progressBar, setProgressBar] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clickedPreview, setClickedPreview] = useState({
    uri: "",
    name: "",
    type: "",
  });
  const [processing, setProcessing] = useState(false);
  // const [openDocViewer, setOpenDocViewer] = useState({
  //   isOpen: false,
  //   docData: {},
  // });
  // const [sUploadData, setUploadData] = useState({
  //   selectedDocType: "",
  //   selectedSubDocType: "",
  // });
  const [isAlert, setIsAlert] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });
  const [context, setContext] = useContext(UserAuthContext);
  const isUploadMore = context?.isNewProcessId;
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
    useState(true);
  const isDuplicateFile = (newFiles) => {
    const existingFileNames = uploadedFile.map((file) => file.name);
    return newFiles.some((newFile) => existingFileNames.includes(newFile.name));
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validFormats = [".jpg", ".jpeg", ".png", ".pdf"];

      if (acceptedFiles.length + uploadedFile.length > 5) {
        setIsAlert({
          isTrue: true,
          type: "warning",
          msg: "You can upload a maximum of 5 files.",
        });

        setTimeout(() => {
          setIsAlert((prev) => {
            return { ...prev, isTrue: false };
          });
        }, 5000);

        return;
      }

      if (
        acceptedFiles.every((file) =>
          validFormats.some((format) => file.name.endsWith(format))
        )
      ) {
        if (!isDuplicateFile(acceptedFiles)) {
          setUploadedFile((prev) => {
            return [...prev, ...acceptedFiles];
          });
          // handleUploadDoc(acceptedFiles[0]);
        } else {
          setIsAlert({
            isTrue: true,
            type: "warning",
            msg: "Duplicate files are not allowed.",
          });

          setTimeout(() => {
            setIsAlert((prev) => {
              return { ...prev, isTrue: false };
            });
          }, 5000);
        }
      } else {
        setIsAlert({
          isTrue: true,
          type: "warning",
          msg: "Invalid file format. Allowed formats: .jpg, .png, .pdf",
        });

        setTimeout(() => {
          setIsAlert((prev) => {
            return { ...prev, isTrue: false };
          });
        }, 5000);
      }
    },
    [uploadedFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".jpg, .jpeg, .png, .pdf",
    maxSize: 3 * 1024 * 1024,
    multiple: true,
    maxFiles: 5,
  });
  // const handledocview = () => {
  //   if (uploadedFile.length > 0) {
  //     // Check if uploadedFile is not empty
  //     const docData = {
  //       doc_name: uploadedFile[0].name,
  //       doc_path: `${uploadedFile[0].path}`,
  //       doc_type: uploadedFile[0].type,
  //     };

  //     setOpenDocViewer((prev) => {
  //       return { ...prev, isOpen: true, docData: docData };
  //     });
  //   }
  // };
  const filePreviewHandler = (file) => {
    if (uploadedFile) {
      setClickedPreview({
        uri: URL.createObjectURL(file),
        name: file.name,
        type: file.type,
      });

      setDrawerOpen(true);
    } else {
    }
  };
  const handleContinueClick = () => {
    if (uploadedFile.length > 0) {
      handleUploadDoc(uploadedFile);
      setLoading(true);
    } else {
      console.log("No file is uploaded.");
    }
  };
  const closeFile = (index) => {
    const updatedFiles = [...uploadedFile];
    updatedFiles.splice(index, 1);
    setUploadedFile(updatedFiles);
  };
  const totalSize = uploadedFile.reduce((acc, file) => acc + file.size, 0);
  let loadedSize = 0;
  const uploadFileDataAxios = (data) => {
    return Axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },

      onUploadProgress: function (event) {
        loadedSize += event.loaded;
        const percentCompleted = Math.round((loadedSize / totalSize) * 100);
        setProgressBar(percentCompleted);
      },
    });
  };

  // async function handleUploadDoc(fileBlob) {
  //   const uploadPromises = fileBlob.map(async function (file) {
  //     try {
  //       const formData = new FormData();
  //       formData.append("uploadedFile", file);
  //       const res = await uploadFileDataAxios(formData);

  //       if (res.data.result === "true") {
  //         let imageUrl = res.data.url;
  //         let urlData = [res];
  //         setLoading(false);
  //         callUploadDocuments(urlData);
  //         console.log('object :>> ', urlData);
  //       }
  //     } catch (err) {
  //       console.error("errr", err);
  //     }
  //   });
  const handleUploadDoc = (fileContents) => {
    if (fileContents?.length > 0) {
      setLoading(true);
      let urlData;
      let documentData;
      setProcessing(true);
      Promise.all(
        fileContents?.map((item, i) => {
          let formData = new FormData();
          formData.append("uploadedFile", item);

          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];
          callUploadDocuments(urlData);
          setProcessing(false);
          setTimeout(() => {
            setShowModal(true);
          }, 4000);
        })
        .catch((err) => {
          console.error("errr", err);
          setProcessing(false);
        });
    } else {
      setIsContinueAlert(true);
      setTimeout(() => {
        setIsContinueAlert(false);
      }, 5000);
    }
  };
  async function rxUploadDocument(filename) {
    const p_docsubtype_code = filename?.data?.url?.includes("pdf")
      ? config?.upload_p_docsubtype_code
      : config?.upload_image_p_docsubtype_code;

    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: _user_profile?.patient_id,
        p_usr_id: _user_profile?.usr_id,
        p_doc_name: filename?.data?.url,
        p_doc_remarks: "",
        p_doctype_code: config?.upload_p_doctype_code,
        p_trnt_code: config?.upload_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }
  async function callUploadDocuments(urlData) {
    var uploaDocumentID = await Promise.all(
      urlData.map(async (item) => {
        return await rxUploadDocument(item);
      })
    );
    if (Array?.isArray(uploaDocumentID) && uploaDocumentID?.length > 0) {
      setLoading(false);
      setUploadedDocumentIds(
        uploaDocumentID?.map((item) => item?.returnData[0]?.document_id || null)
      );
      // if (uploadedDocumentIds) {
      // }
      // setTimeout(() => {
      setContext((prev) => {
        return { ...prev, isRxUploadActive: false, isRxUploaded: true };
      });
      // }, 2000);
      var ids = uploaDocumentID?.map(
        (item) => item?.returnData[0]?.document_id
      );
      if (isUploadMore) {
        uploadMoreRx(ids?.join("~"));
      } else {
        // c+sole.log("ids---------- ", ids?.join("~"));

        generateRx("", ids?.join("~"));
      }
      // Navigate the user to Consent Form
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }
  // const UploadDocumentsWithDetails = (imageUrl, uploadingFileName) => {
  //   try {
  //     let params = {};
  //     const customerId = context?.UserAuth?.customer_id;
  //     params.p_doc_name = uploadingFileName;
  //     params.p_doc_path = imageUrl;
  //     params.p_patient_id = customerId;

  //     // Getting file name from the URL
  //     let parts = imageUrl.split("/");
  //     const fileName = parts[parts.length - 1];

  //     // Removing first digits values from the name of the file
  //     const underscoreIndex = fileName.indexOf("_");
  //     const newFileName = fileName.slice(underscoreIndex + 1);
  //     params.p_doc_uploaded_filename = newFileName;
  //     CallAPI(config?.upload_documents_pro_user_process_id, params).then(
  //       (res) => {
  //         if (res?.returnCode) {
  //           // console.log('res  final:>>', res)
  //           // callAllDocuments(res);
  //           // Clear the file input field
  //           // let fileInputField = document.querySelector("#inputFileNameDocsSafe");
  //           // fileInputField.value = "";
  //           // Reset the selectedDocType and selectedSubDocType
  //           // setUploadData({
  //           //   selectedDocType: "",
  //           //   selectedSubDocType: "",
  //           // });
  //           // Clear the dropdown field value
  //           // let dropdownField = document.querySelector("#docTypeSelectionInputField");
  //           // dropdownField.value = "";
  //         } else {
  //           setIsAlert({
  //             isTrue: true,
  //             type: "error",
  //             msg: res?.msg,
  //           });
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  useEffect(() => {
    if (isAlert.isTrue) {
      setTimeout(() => {
        setIsAlert((prev) => {
          return { ...prev, isTrue: false };
        });
      }, 5000);
    }
  }, [isAlert]);

  const uploadMoreRx = (docId) => {
    try {
      const processId = config?.xcelecom_save_trn_rx_request_document_upload;
      const params = {
        p_usr_id: _user_profile?.usr_id,
        p_document_ids: docId,
        p_rx_id: rx_id,
      };
      CallAPI(processId, params).then((res) => {
        if (res?.returnCode) {
          setLoading(false);
          // removeKey(ORDER_DETAILS);
          setDocUploaded({
            isTrue: true,
            msg: msg_config?.document_uploaded,
            type: "success",
          });
        } else {
          setDocUploaded({
            isTrue: true,
            msg: res?.msg,
            type: "error",
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const generateRx = (signatureId, docId) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.generate_rx_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_document_ids: docId, //uploadedDocumentIds?.join("~"),
        p_customer_id: _user_profile?.customer_id,
        p_rx_type: "UP",
        p_rx_patient_id: _user_profile?.patient_id,
        p_resource_id: "",
        p_pharmacy_id: "",
        p_signature_document_id: signatureId,
        p_rx_patient_type: "",
        p_internal_flag: "",
        p_order_id: "",
      }).then((res) => {
        if (res?.returnCode) {
          if (res?.returnData?.length > 0) {
            //removeKey(ORDER_DETAILS);
            setLoading(false);
            setGeneratedRx(res);
            // setIsModalOpen(true);
            setContext((prev) => {
              return {
                ...prev,
                isPrescriptionUploaded: true,
                data: res?.returnData[0]?.rx_number,
              };
            });
          }
        }
      });
    }
  };
  useEffect(() => {
    // Disable the button
    setIsContinueButtonDisabled(uploadedFile.length === 0);
  }, [uploadedFile]);
  return (
    <>
      <div className={Styles?.upload_prescription_main}>
        <div className={`${Styles?.container} outer_container`}>
          <div className={Styles?.upload_heading}>
            <h2>{Config?.lbl_upload_prescription_heading}</h2>
          </div>
          <div className={Styles?.upload_prescript_Content_wrapper}>
            <div className={Styles?.upload_prescription_details}>
              <div className={Styles?.upload_field_wrapper}>
                <div {...getRootProps()} className={Styles?.uploadbox}>
                <img src={uploadText}/>
                  <input {...getInputProps()} />
                  <h5>
                    {Config?.lbl_drag_drop_text} <span>{Config?.lbl_browse_button}</span>
                  </h5>
                  <p>{Config?.lbl_supported_format}</p>
                  <p>{Config?.lbl_max_size}</p>
                </div>

                {/* <div className={Styles?.seprator}>
                  <span className={Styles?.leftseprator}></span>
                  <p>or</p>
                  <span className={Styles?.rightseprator}></span>
                </div>
                <Button className={Styles?.rxsavebtn}>
                  Choose From Saved Rx
                </Button> */}
              </div>
              <span className={Styles?.upload_between_border}></span>
              <div className={Styles?.detailscontainer}>
                <div className={Styles?.upload_prescription_content_details}>
                  <h3>
                    {Config?.lbl_prescription_elements}
                  </h3>
                  <ul>
                    <li>
                      {" "}
                      <img src={Tickmark} />
                      <p>{Config?.lbl_doctor_details}</p>
                    </li>
                    <li>
                    <img src={Tickmark} />
                      <p>{Config?.lbl_prescription_date}</p>
                    </li>
                    <li>
                      {" "}
                      <img src={Tickmark} />
                      <p>{Config?.lbl_patient_details}</p>
                    </li>
                    <li>
                      {" "}
                      <img src={Tickmark} />
                      <p>{Config?.lbl_meds_details}</p>
                    </li>
                  </ul>
                </div>
                <div className={Styles?.upload_prescription_filepreview}>
                  <div
                    className={Styles?.upload_prescription_filereview_heading}
                  >
                    <img src={fileview_heading} />

                    <h3>
                     {Config?.lbl_prescription_processing_text}
                    </h3>
                  </div>
                  <div className={Styles?.prescription_uploaded_doc_wrapper}>
                    {uploadedFile.map((file, index) => (
                      <div key={index} className={Styles?.uploaded_doc_img}>
                        <span
                          onClick={() => closeFile(index)}
                          className={Styles?.uploaded_close_btn}
                        >
                          <img src={upload_closebtn} />
                        </span>
                        {file.name.endsWith(".pdf") ? (
                          <img
                          src={pdf_icon}
                          style={{
                            color: "#FF0000",
                            fontSize: "30px",
                            fontWeight: "200",
                            marginTop: "6px",
                            marginRight:"8px"
                          }}
                          onClick={() => {
                            filePreviewHandler(file);
                            setSelectedImageIndex(file);
                          }}
                        />
                        ) : file.name.endsWith(".jpg") ||
                          file.name.endsWith(".jpeg") ||
                          file.name.endsWith(".png") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt="uploaded doc"
                            onClick={() => {
                              filePreviewHandler(file);
                              setSelectedImageIndex(file);
                            }}
                          />
                        ) : (
                          <p>Unsupported File Type</p>
                        )}
                        {processing && (
                          <div className={Styles?.progressBar}>
                            <progress
                              className="marginRight-2 marginTop-1 paddingTop-2"
                              value={progressBar}
                              max="100"
                            ></progress>
                            {/* <p>{Math.min(progressBar, 100)}%</p> */}
                          </div>
                        )}
                      </div>
                    ))}
                    {isAlert.isTrue && (
                      <Alert type={isAlert?.type} info={isAlert?.msg} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles?.orderinfo}>
              <div className={Styles?.order_info_inner}>
                <h2>{Config?.lbl_order_info_heading}</h2>
                <div className={Styles?.order_info_heading}>
                  <p>
                   {Config?.lbl_order_executive_call}
                  </p>
                </div>
                <div className={Styles?.order_info_image_wrapper}>
                  <img src={calling} alt="Search Meds" />
                </div>
              </div>
              <Button
                className={Styles?.buttoncontainer}
                onClick={handleContinueClick}
                loading={loading}
                disabled={isContinueButtonDisabled}
              >
                {Config?.lbl_continue_Btn}
              </Button>
            </div>
          </div>
        </div>

        {false && (
          <Modal
            setModalOpen={showModal}
            heading={
              Config?.uploadRx_Modal_Heading
              //  +
              // " " +
              // generatedRx?.returnData[0]?.rx_number
            }
            detail={
              Config?.uploadRx_Modal_detail__1 +
              " " +
              generatedRx?.returnData[0]?.rx_number +
              " " +
              Config?.uploadRx_Modal_detail__2
            }
          />
        )}
      </div>

      <Drawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        name={clickedPreview?.name}
        uri={clickedPreview?.uri}
        headingName={clickedPreview?.name}
      />

      {/* {openDocViewer?.isOpen && (
          <DocViewer
            openDocViewer={selectedImageIndex}
            setOpenDocViewer={setOpenDocViewer}
          />
        )} */}
    </>
  );
};
export default Rxpage;
