import React from "react";
import CheckoutProductCard from "../../../components/CheckoutProductCard";
import style from "./index.module.css";

export type productProps = {
  products?: [];
};
const Products: React.FC<productProps> = (props) => {
  const { products } = props;
  return (
    <section className={`${style?.container}`}>
      {products?.map((product: any) => {
        return (
          <CheckoutProductCard
            viewType="LIST"
            productUrl="/"
            imgUrl={product?.imgUrl}
            imgAlt={product?.imgAlt}
            detail={product?.detail}
            price={product?.price}
            actualPrice={product?.actualPrice}
            className={style?.productContainer}
          />
        );
      })}
    </section>
  );
};

export default Products;
