import React, { useContext, useEffect, useState, useRef } from "react";
import Input from "../../../components/UI/Input";
import PhoneNumber from "../../../components/PhoneNumber";
import Label from "../../../components/UI/Label";

// import styles from "../CreateAccount/index.module.css";
import styles from "./index.module.css";

import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";
import { RegistrationFormFields } from "../EnterPhoneNumber";
import { useForm } from "react-hook-form";
import Button from "../../../components/UI/Button";
import btnConfig from "../../../config/button";
import { useDispatch, useSelector } from "react-redux";
import { signUpVendorActions, signUpVendorReducer } from "../slice/slice";
import { FormInput } from "../../../components/UI/FormInput";
import { msg_config } from "../../../config/messages";
import AppLogo from "../../../assets/appLogo/safe_pharmacy_logo.png";
import AppLogoPro from "../../../assets/pro_app_logo.png";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, removeKey, setEncryptedID } from "../../../utils/utils";
import { CART_COUNT, DEFAULT_FLAG, HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, INSURANCE_FRONT_ID, IS_EDITING, IS_INSURANCE, LOGGED_IN_USER_ROLE, PATIENT_ID, PROFILE_IMAGE, PROFILE_IMAGE2, QB_ID, SG_PATIENT_ID, SG_ROLE_ID, SG_USER_ID, SIGN_IN_WITH, USER_ID, USER_LOGGED_IN, USER_PROFILE, USER_ROLE_ID } from "../../../config/Config";


const CompanyProfile = (props: any) => {
  const { isVendor, handleNext, handleBack, setOpenCompanyProfileCard } = props;
  const [phoneNumber, setPhoneNumber] = useState();
  const [context, setContext] = useContext(UserAuthContext);
  const formRef = useRef(null);
  const formData = useSelector((state: any) => state?.signUpVendorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });
  const handleChange = (e: any) => { };
  const isEditing = context?.isEditProProfile; //boolean
  const companyProfileHandler = (data: any) => {
    //@ts-ignore
    let companyName = document.querySelector("#p_legal_company_name")?.value;
    let userAuth = context?.UserAuth;
    userAuth = { ...userAuth, usr_company_name: companyName };
    setContext((prev: any) => {
      return { ...prev, UserAuth: userAuth };
    });
    handleNext();
  };

                
  const handleCloseProProfileEditor = ()=>{
    try {
      //closing popup using state
      // setContext((prev:any)=>{
      //   return {...prev, isEditProProfile : false}
      // })
      //setting default data in the popup using reduex
      setOpenCompanyProfileCard(false);
      setEncryptedID(IS_EDITING, "N");
      const localStorageCopy = getDecryptedID(USER_PROFILE);
      // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };
      dispatch(signUpVendorActions?.restoreFormFields({
        p_first_name : localStorageCopy?.usr_first_name || "",
        p_last_name : localStorageCopy?.usr_last_name || "",
        p_company_email : localStorageCopy?.usr_email || "",
        p_company_alternate_email : localStorageCopy?.usr_company_alternate_email,
        p_company_landline_no : localStorageCopy?.usr_company_landline_no,
        p_company_alternate_phone : localStorageCopy?.usr_company_alternate_phone,
        p_company_size : localStorageCopy?.usr_company_size,
        p_company_gstin_no : localStorageCopy?.usr_company_gstin_no,
        p_legal_company_name : localStorageCopy?.usr_company_name,
        p_company_postal_code : localStorageCopy?.usr_company_postal,
        p_user_type : localStorageCopy?.usr_type,
      }))   
     } catch (error) {
     console.error(error); 
    }
  }
  return (
    <div className={`${styles?.createcontainer}`}>
      <div className={`${styles.companyprofile_logo}`}>
        <img src={AppLogoPro} />
        {getDecryptedID(IS_EDITING) === "Y" ? "" : <p
          className={styles?.logoutLink}
          onClick={() => {
            navigate("/");
            setContext((prev: any) => ({
              ...prev,
              UserAuth: {},
            }));
            // localStorage.clear();
            removeKey(USER_PROFILE);
            removeKey(SG_ROLE_ID);
            removeKey(SG_USER_ID);
            removeKey(SG_PATIENT_ID);
            removeKey(IS_INSURANCE);
            removeKey(PROFILE_IMAGE);
            removeKey(PROFILE_IMAGE2);
            removeKey(USER_ID);
            removeKey(QB_ID);
            removeKey(USER_ROLE_ID);
            removeKey(DEFAULT_FLAG);
            removeKey(PATIENT_ID);
            removeKey(CART_COUNT);
            removeKey(INSURANCE_FRONT_ID);
            removeKey(INSURANCE_BACK_ID);
            removeKey(HEALTH_CARD_FRONT_ID);
            removeKey(HEALTH_BACK_ID);
            removeKey(HEALTH_INFO);
            sessionStorage.clear();
            setEncryptedID(USER_LOGGED_IN, "N");
            setEncryptedID(SIGN_IN_WITH, " ");
            // setGlobalContext((prev) => {
            //   return {
            //     ...prev,
            //     profile_image: "",
            //   };
            // });
            window.location.reload();
          }}
        >
          {Config?.lbl_logout}
        </p>}
      </div>
      <div className={`${styles?.create_main}`}>
        <Heading className={`${styles?.page_heading}`}>
          {Config?.heading_create_company_account}
        </Heading>
        <form onSubmit={handleSubmit(companyProfileHandler)} id="companyForm" ref={formRef}>
          <div className={`${styles?.form_control}`}>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_legal_company_name"
              >
                {Config?.lbl_company_legal_name}
              </Label>
              <FormInput
                name="p_legal_company_name"
                id="p_legal_company_name"
                type={Config?.lbl_Inp_type_text}
                placeholder={"Company Name"}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_legal_company_name}
                rules={{
                  required: msg_config?.trnRx_pharmacy_required,
                }}
                errors={errors}
                maxLength={100}
                onChange={(e: any) => {
                  dispatch(
                    // @ts-ignore
                    signUpVendorActions?.updateFormField({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                  //getting all values from the context
                  let userAuth = context?.UserAuth;
                  //@ts-ignore
                  //updating context object named UserAuth
                  if (isEditing) {
                    userAuth = { ...userAuth, usr_company_name: e.target.value };
                    // setContext((prev : any)=>{
                    //   return {...prev, UserAuth :userAuth}
                    // })
                  }
                }}
              />
            </div>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_company_postal_code"
              >
                {Config?.lbl_Postal_C}
              </Label>
              <FormInput
                name="p_company_postal_code"
                id="p_company_postal_code"
                type={Config?.lbl_Inp_type_text}
                placeholder={"Postal Code"}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_company_postal_code}
                rules={{
                  required: msg_config?.trnRx_pharmacy_postal_code_required,
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Please enter a valid 6 digit postal code.",
                  },
                }}
                errors={errors}
                // minLength={6}
                maxLength={6}
                onChange={(e: any) => {
                  dispatch(
                    // @ts-ignore
                    signUpVendorActions?.updateFormField({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                  let userAuth = context?.UserAuth;
                  if (isEditing) {
                    userAuth = { ...userAuth, usr_company_postal: e.target.value };
                    // setContext((prev : any)=>{
                    //   return {...prev, UserAuth :userAuth}
                    // })
                  }
                }}
              />
              {/* <Input
                name="postalCode"
                id="postalCode"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_Postal_code}
                size={Config?.lbl_Inp_size_medium}
              /> */}
            </div>
          </div>
          <div className={`${styles?.button_wrapper}`}>
            {/* <Button
                type={Config?.lbl_Btn_type_ghost}
                size={Config?.lbl_Btn_size_medium}
                onClick={() => {
                  props?.handleBack();
                }}
                className={`${styles?.ghostBtn} ${styles?.back_btn}`}
              >
                {btnConfig?.signUp_Ghost_Text_Back}
              </Button> */}
            {getDecryptedID(IS_EDITING) === "Y" && <Button
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => {
                handleCloseProProfileEditor()
              }}
              className={`${styles?.ghostBtn} ${styles?.back_btn}`}
            >
              {btnConfig?.signUp_close_button}
            </Button>}

            <Button
              type={Config?.lbl_Btn_type_primary}
              onClick={() => { 
                if(context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE){
                  setContext((prev:any)=>{
                    return {...prev, isEditProProfile : false}
                  })
                }
              }}
              size={Config?.lbl_Btn_size_medium}
            >
              {btnConfig?.signUp_Primary_Text_V2}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyProfile;
