//
// Insruction:
// Object items in array must contain following keys
// 1. 'file_name' (document name)
// 2. 'file_view_path' (document view link)
// 3. 'file_ext' (document extension)
// 4. 'file_download_path' (document download link)
// 
import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify';
import { Doc_Link, doc_zoom_scale } from '../Config/config';
import Button from './Button';
import Styles from "./index.module.css"

const currentFile = { index: null, file: {} }

const fileReducer = (state, action) => {
    try {
        switch (action?.action) {
            case 'set-initial':
                return { ...state, index: action?.index_params, file: action?.params }
                break;
            case 'set-current':
                return { ...state, index: action?.index_params, file: action?.params }
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error)
    }
}

const DocCarousel = (props) => {
    const { files, showFileIndex, src } = props;
    const [currFile, fileDispatcher] = useReducer(fileReducer, currentFile);
    const [loader, setLoader] = useState(false);
    const [err, setErr] = useState(false);
    const imgRef = React.createRef();
    const initialDimension = React.createRef(null);

    const formatChecker = () => {
        let returnData = true;
        ['file_name', 'file_view_path', 'file_ext', 'file_download_path'].map((i) => {
            if (!Object.keys(files[0]).includes(i)) {
                returnData = false;
                toast.error(`Missing key ${i}!`)
            }
        })
        return returnData
    }

    useEffect(() => {
        try {
            if (formatChecker()) {
                fileDispatcher({ action: 'set-initial', index_params: showFileIndex, params: files[showFileIndex] })
            }
            else {
                toast.error('Wrong format of objects in array!');
                setErr(true);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const selectNext = () => {
        try {
            if (currFile?.index === (files?.length - 1)) {
                fileDispatcher({ action: 'set-current', params: files[0], index_params: 0 })
            }
            else {
                fileDispatcher({ action: 'set-current', params: files[currFile?.index + 1], index_params: (currFile?.index + 1) })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const selectPrev = () => {
        try {
            if (currFile?.index === 0) {
                fileDispatcher({ action: 'set-current', params: files[files?.length - 1], index_params: (files?.length - 1) })
            }
            else {
                fileDispatcher({ action: 'set-current', params: files[currFile?.index - 1], index_params: (currFile?.index - 1) })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const dl_URL = (path) => {
        switch (src) {
            case 'Chat':
                return `${path?.file_download_path}`
                break;
            default:
                return `${path?.file_download_path}`
                break;
        }
    }

    const view_URL = (path) => {
        switch (src) {
            case 'Chat':
                return `${path?.file_view_path}`
                break;
            default:
                return `${path?.file_view_path}`
                break;
        }
    }

    const downloadFile = (file) => {
        try {
            // console.log(file, 'file');
            let dl_name = file?.file_name;
            if (src === "FAX") {
                dl_name += ".pdf"
            }
            setLoader(true);
            axios({
                url: dl_URL(file),
                method: "GET",
                responseType: "blob", // important
            })
                .then((res) => {
                    // console.log(res, 'download');
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", dl_name);
                    document.body.appendChild(link);
                    link.click();
                    setLoader(false);
                })
                .catch((err) => console.log("err", err));
        } catch (error) {
            console.log("err", error)
        }
    }

    const ViewFile = (file_props) => {
        try {
            const { filePath, fileExt } = file_props;
            const [viewLoader, setViewLoader] = useState(true);
            const [awaitRender, setAwaitRender] = useState({
                state: true,
                component: null
            });
            const [fileAttr, setFileAttr] = useState({
                type: '',
                path: ''
            });
            const [zoom, setZoom] = useState({
                h: null,
                w: null
            });
            const iFrameStyle = {
                height: '100%',
                width: '100%',
                border: 'none'
            }

            useEffect(() => {
                try {
                    if (!Object.values(files[0]).includes(undefined)) {
                        // console.log(fileExt, 'type');
                        setFileAttr((prev) => {
                            return { ...prev, type: fileExt, path: view_URL(filePath) }
                        })
                        setViewLoader(false);
                    }
                    else {
                        for (const [key, val] of Object.entries(files[0])) {
                            if (val === undefined) {
                                toast.error(`undefined ${key}`)
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }, [currFile?.index]);

            const viewerHTML = (type) => {
                const keyValPair = {
                    'pdf': <iframe style={iFrameStyle} src={fileAttr?.path}></iframe>,
                    'png': <img src={fileAttr?.path} ref={imgRef} height={zoom?.h} width={zoom?.w} />,
                    'svg': <img src={fileAttr?.path} ref={imgRef} height={zoom?.h} width={zoom?.w} />,
                    'jpg': <img src={fileAttr?.path} ref={imgRef} height={zoom?.h} width={zoom?.w} />,
                    'jpeg': <img src={fileAttr?.path} ref={imgRef} height={zoom?.h} width={zoom?.w} />,
                    'gif': <img src={fileAttr?.path} ref={imgRef} height={zoom?.h} width={zoom?.w} />,
                    'docx': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'doc': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'xls': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'xlsx': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'ppt': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'pptx': <iframe style={iFrameStyle} src={`${Doc_Link}${fileAttr?.path}`}></iframe>,
                    'txt': <iframe style={iFrameStyle} src={fileAttr?.path}></iframe>
                }

                const zoomFunction = (action) => {
                    if (action === 'zoom-in') {
                        if (initialDimension.current === null) {
                            initialDimension.current = { w: imgRef?.current?.width }
                            setZoom((prev) => {
                                return { ...prev, w: (imgRef?.current?.width + doc_zoom_scale?.w) }
                            })
                        }
                        else {
                            setZoom((prev) => {
                                return { ...prev, w: (prev?.w + doc_zoom_scale?.w) }
                            })
                        }
                    }
                    else if (action === 'zoom-out') {
                        if (initialDimension.current === null) {
                            initialDimension.current = { w: imgRef?.current?.width }
                            setZoom((prev) => {
                                return { ...prev, w: (imgRef?.current?.width - doc_zoom_scale?.w) }
                            })
                        }
                        else {
                            setZoom((prev) => {
                                return { ...prev, w: (prev?.w - doc_zoom_scale?.w) }
                            })
                        }
                    }
                    else if (action === 'reset') {
                        if (initialDimension !== null) {
                            setZoom((prev) => {
                                return { ...prev, w: initialDimension?.current?.w }
                            })
                        }
                    }
                }

                const typeConverter = () => {
                    setAwaitRender((prev) => {
                        return { ...prev, state: false, component: keyValPair[type] }
                    })
                }

                useEffect(() => {
                    typeConverter()
                }, [awaitRender?.state])

                if (!viewLoader) {
                    if (keyValPair[type]) {
                        return (
                            <div style={{ height: '100%', width: '100%' }} className='ui-flex ui-horizontal-align-center ui-vertical-align-middle ui-flex-column'>
                                {['png', 'svg', 'jpg', 'jpeg', 'gif'].includes(type) ?
                                    <>
                                        <div className={`zoom-control ${Styles['ui-flex']} paddingTopBottom-2 ui-border-top`}>
                                            <Button
                                                className={'ui-button-cancel ui-button-small'}
                                                children={<i className="fa fa-search-plus fa-lg" />}
                                                onClick={() => zoomFunction('zoom-in')}
                                            />
                                            <Button
                                                className={'ui-button-cancel ui-button-small'}
                                                children={<i className="fa fa-search-minus fa-lg" />}
                                                onClick={() => zoomFunction('zoom-out')}
                                            />
                                            <Button
                                                className={'ui-button-cancel ui-button-small'}
                                                children={<i className="fa fa-undo fa-lg" />}
                                                onClick={() => zoomFunction('reset')}
                                            />
                                        </div>
                                        <div className='spd-dialogueImg-outer'>
                                            <div className='spd-dialogueImg'>{keyValPair[type]}</div>
                                        </div>
                                    </>
                                    :
                                    (awaitRender?.state ?
                                        <img src={require('../Assets/gif/loader.gif')} height={'50vw'} width={'50vw'} />
                                        :
                                        awaitRender?.component
                                    )
                                }
                            </div>
                        )
                    }
                    else {
                        return (
                            <pre style={{ border: '0' }}>
                                File format not supported
                            </pre>);
                    }
                }
                else {
                    return <img src={require('../Assets/gif/loader.gif')} height={'50vw'} width={'50vw'} />
                }
            }
            return (
                viewerHTML(fileAttr?.type)
            )
        } catch (error) {
            console.log(error)
        }
    }
    const carouselNav = (ev) => {
        try {
            if (ev?.keyCode === 33) {
                selectPrev()
            }
            else if (ev?.keyCode === 34) {
                selectNext()
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        try {
            window.addEventListener('keydown', carouselNav)
            return (() => {
                window.removeEventListener('keydown', carouselNav)
            })
        } catch (error) {
            console.log(error);
        }
    })
    return (
        <div className='spd-doc-carousel'>
            {files?.length > 0 && !err ?
                <>
                    <div className='ui-flex ui-horizontal-align-center ui-vertical-align-middle carousel-header paddingTopBottom-2 ui-border-bottom'>
                        <div className='carousel-nav ui-flex ui-horizontal-align-between ui-vertical-align-middle'>
                            {files?.length > 1 &&
                                <>
                                    <Button
                                        className={'ui-button-cancel ui-button-small'}
                                        children={<i className="fa-solid fa-caret-left fa-lg" />}
                                        onClick={selectPrev}
                                    />
                                    <div className='carousel-index'>
                                        {currFile?.index === null ? '' : (currFile?.index + 1)}
                                    </div>
                                    <Button
                                        className={'ui-button-cancel ui-button-small'}
                                        children={<i className="fa-solid fa-caret-right fa-lg" />}
                                        onClick={selectNext}
                                    />
                                </>
                            }
                        </div>
                        <div className={`carousel-index-name ${Styles['carouselIndexName']}`}>
                            {!(currFile?.file?.file_name === undefined && currFile?.file?.file_name === null) ? currFile?.file?.file_name : ''}
                        </div>
                        {/* <Button
                            // title='Download'
                            children={<i className="fa fa-download" aria-hidden="true" />}
                            action={"submit primary"}
                            loading={loader}
                            className='ui-button-cancel ui-button-small marginleft-2'
                            onClick={() => { !(currFile?.file?.file_download_path === undefined && currFile?.file?.file_download_path === null && currFile?.file?.file_download_path === '') && downloadFile(currFile?.file) }}
                        /> */}
                    </div>
                    <div className='ui-flex ui-horizontal-align-center ui-vertical-align-middle carousel-body'>
                        <ViewFile
                            filePath={currFile?.file}
                            fileExt={currFile?.file?.file_ext} />
                    </div>
                </>
                :
                <h6>No files to show</h6>
            }
        </div>
    )
}

export default DocCarousel