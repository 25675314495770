import StepperTop from "../../../../components/UI/StepperHorizontal";
import Config from "../../../../config/label";
import HorizontalStepper from "./HorizontalStepper";

const StepsDetail = (props: any) => {
  const { activeStep } = props;
  const steps = [
    {
      label: `New order`,
      description: "",
    },
    // {
    //   label: `Clinical review`,
    //   description: "",
    // },
    {
      label: `Invoiced`,
      description: "",
    },
    {
      label: `Packed`,
      description: "",
    },
    {
      label: `Picked up`,
      description: "",
    },
    {
      label: `In transit`,
      description: "",
    },
    {
      label: `Delivered`,
      description: "",
    },
  ];

  return <HorizontalStepper steps={steps} activeStep={activeStep} />;
};

export default StepsDetail;
