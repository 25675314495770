import React from "react";

export type CheckValidationProps = {
  show?: any;
  fallback?: any;
};
const CheckValidation: React.FC<CheckValidationProps> = ({
  show,
  fallback,
  children,
}) => {
  return (
    <>
      {show && children}
      {!show && fallback}
    </>
  );
};

export default CheckValidation;
