import { async } from "@firebase/util";
import React, { useContext } from "react";
import { UserAuthContext } from "../src/store/UserAuthContext.js";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  OAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import Alert from "./components/UI/Alert";
import { siteConfig } from "./config/site";
// import config from "./config/processId";

const app = initializeApp(siteConfig?.firebase_Config);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
// const [context, setContext] = useContext(UserAuthContext);

//Made custome hook for caaling google sign in function
const SignInWithGoogle = async (setContext) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const userInfo = {
      displayName: res?.user?.displayName,
      accessToken: res?.user?.accessToken,
      email: res?.user?.email,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    setContext((prev) => ({
      ...prev,
      searchDisplayOut: false,
    }));
  }
};

const signInwithApple = async (setContext) => {
  const provider = new OAuthProvider("apple.com");

  try {
    const res = await signInWithPopup(auth, provider);
    const userInfo = {
      displayName: res?.user?.displayName,
      accessToken: res?.user?.accessToken,
      email: res?.user?.email,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    const user = res.user;

    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "apple.com",
        email: user.email,
      });
    }
  } catch (error) {
    setContext((prev) => ({
      ...prev,
      searchDisplayOut: false,
    }));
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  SignInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInwithApple,
};
