import React, { useEffect, useState, useContext, useRef } from "react";
import APIContext from "../../../store/api-context";
import Button from "../../../components/UI/Button";
import ForgotPassword from "./ForgotPassword";
import Label from "../../../components/UI/Label";
import { GoogleIcon, Apple } from "../../../utils/appIcons";
import AppLogo from "../../../assets/appLogo/safe_pharmacy_logo.png";
import { Link, useNavigate } from "react-router-dom";
import Styles from "./index.module.css";
import Config from "../../../config/label";
import { config } from "../../../config/processId.js";
import Heading from "../../../components/UI/Heading";
import PhoneNumber from "../../../components/PhoneNumber";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { userID, patientID, roleID } from "../../../suggest-us/suggestApi.js";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import Alert from "../../../components/UI/Alert";
import { PhoneFormInput } from "../../../components/UI/PhoneFormInput";
import { useForm } from "react-hook-form";
import { msg_config } from "../../../config/messages";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import {
  auth,
  signInWithEmailAndPassword,
  SignInWithGoogle,
  signInwithApple,
  logout,
} from "../../../firebase";
import { getDecryptedID, objEncrypt, setEncryptedID } from "../../../utils/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import TermModalPopup from "../../../components/TermsModalpopup";
import { CURRENT_SCREEN, IS_CHECKED, LOGINS_IN_BY, PASSWORD_MSG, PHONE_NO, SIGN_IN_WITH, USER_ID, USER_LOGGED_IN, USER_PROFILE } from "../../../config/Config";
import { siteConfig } from "../../../config/site";
import { parsePhoneNumber } from "react-phone-number-input";

export type RegistrationFormFields = {
  phone: string;
  setIsProUser: any;
  
};

function Login(props: any) {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [suggestUsInit] = useContext(APIContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [isAlertChngPsw, setIsAlertChngPsw] = useState({
    isTrue: "",
    type: "",
    msg: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [openTermModalPopup, setOpenTermModalPopup] = useState(false);
  const [phoneLength, setPhoneLength] = useState(0);
  const loggingBy = getDecryptedID(LOGINS_IN_BY);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const phoneInputRef = useRef<any>(null);
  useEffect(() => {
    phoneInputRef.current.focus();
  }, []);

  const {
    setVerifyPhone,
    setIsLoading,
    setEnteredPhResponse,
    setIsAlert,
    setLoginScreens,
    isLoading,
    setPhoneNumber,
    phoneNumber,
    // setChecked,
    // checked,
    // setPhForPassword,
    setIsProUser,
    propsshowForgetPassword,
  } = props;

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    defaultValues: { phone: phoneNumber },
  });

  const navigate = useNavigate();

  const setSessionStorage = (res: any) => {
    setEncryptedID(PHONE_NO, res?.returnData[0]?.phone_no);
    setEncryptedID(USER_ID, res?.returnData[0]?.user_id);
    setEncryptedID(CURRENT_SCREEN,res?.returnData[0]?.current_screen);
  };

  const setSuggestUsIDs = (patientID: any, roleID: any, userID: any) => {
    patientID(patientID);
    roleID(roleID);
    userID(userID);
  };

  useEffect(()=>{
    try {
      if(isAlertChngPsw?.isTrue){
        setTimeout(() => {
            setIsAlertChngPsw((prev:any)=>{
              return {...prev, isTrue:false}
            })
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  },[isAlertChngPsw])

  const gaEventTracker = useAnalyticsEventTracker("Log in screen");

  useEffect(() => {
    window.scrollTo(0, 0);
    let userData: any = user;

    if (user) {
      if (suggestUsInit?.returnCode === true) {
        //Api call for sending user info and redirecting to verify phone number page
        let newUser: any = user;
        let authentication: any = newUser?.auth;
        let configuration: any = authentication?.config;

        //Splitting full name
        var fullName: any = user?.displayName;
        var details = [];
        details = fullName.split(" ");

        //converting user data into JSON string
        const JSONString = JSON.stringify(user);

        //Sending data to backend according to google
        if (
          userData?.providerData?.find(
            (item: any) => item?.providerId === "google.com"
          )?.providerId === "google.com"
        ) {
          CallAPI(config?.gmail_process_id, {
            p_org_id: suggestUsInit?.org_id,
            p_usr_name: user?.displayName,
            p_usr_phone: user?.phoneNumber,
            p_gender: "",
            p_email: user?.email,
            p_usr_first_name: details[0],
            p_usr_last_name: details[1],
            p_signup_type: "gmail",
            p_data_json_recieved: JSONString,
            p_social_media_key: user?.uid,
          }).then((res) => {
            //Logging out firebase user every time after sending firebase users data to backend
            logout();
            // Storing response in session
            if (res?.returnData?.length > 0) {
              setSessionStorage(res);
            }

            if (res?.returnCode) {
              if (res?.returnData?.length > 0) {
                setVerifyPhone(res?.returnData);

                userID(res?.returnData[0]?.user_id);

                setEncryptedID(SIGN_IN_WITH, "social-media");
                if (res?.returnData[0]?.current_screen === "home_screen") {
                  //There is no redirection of the screen becoz protected route handling it in case of user already registered
                  navigate("/");
                  gaEventTracker("Account created with google");

                  //Setting local storage of browser
                  setEncryptedID(USER_PROFILE,res?.returnData[0]);
                  setEncryptedID(USER_LOGGED_IN, "Y");
                  // setSuggestUsIDs(
                  //   res?.returnData[0]?.patient_id,
                  //   res?.returnData[0]?.rol_id,
                  //   res?.returnData[0]?.usr_id
                  // );
                  setContext((prev: any) => ({
                    ...prev,
                    UserAuth: res?.returnData[0],
                  }));
                } else if (
                  res?.returnData[0]?.current_screen === "save_phone"
                ) {
                  navigate("/verify-Phone");
                  gaEventTracker("Enter phone screen");
                } else if (
                  res?.returnData[0]?.current_screen === "verify_otp"
                ) {
                  setEnteredPhResponse(res?.returnData);
                  navigate("/verify-otp/social-media");
                  gaEventTracker("Verify OTP screen");
                }
              }
            } else {
              if (res?.returnData?.length > 0) {
                setVerifyPhone(res?.returnData);
                userID(res?.returnData[0]?.user_id);
                setEncryptedID(SIGN_IN_WITH, "social-media");

                if (res?.returnData[0]?.current_screen === "save_phone") {
                  navigate("/verify-Phone");
                  gaEventTracker("Enter phone screen");
                } else if (
                  res?.returnData[0]?.current_screen === "verify_otp"
                ) {
                  setEnteredPhResponse(res?.returnData);
                  setLoginScreens("/verify-otp/social-media");
                  navigate("/verify-otp/social-media");
                  gaEventTracker("Verify OTP screen");
                }
              }
            }
          });
        }

        //Sending data to backend according to apple
        if (
          userData?.providerData?.find(
            (item: any) => item?.providerId === "apple.com"
          )?.providerId === "apple.com"
        ) {
          // setIsLoading(true);
          CallAPI(config?.gmail_process_id, {
            p_org_id: suggestUsInit?.org_id,
            p_usr_name: user?.displayName,
            p_usr_phone: user?.phoneNumber,
            p_gender: "",
            p_email: user?.email,
            p_usr_first_name: details[0],
            p_usr_last_name: details[1],
            p_signup_type: "appleid",
            p_data_json_recieved: JSONString,
            p_social_media_key: user?.uid,
          }).then((res) => {
            //Logging out firebase user every time after sending firebase users data to backend
            logout();
            // Storing response in session
            if (res?.returnData?.length > 0) {
              setSessionStorage(res);
            }

            if (res?.returnCode) {
              if (res?.returnData?.length > 0) {
                setVerifyPhone(res?.returnData);

                userID(res?.returnData[0]?.user_id);
                setEncryptedID(SIGN_IN_WITH, "social-media");

                if (res?.returnData[0]?.current_screen === "home_screen") {
                  //There is no redirection of the screen becoz protected route handling it in case of use already registered
                  navigate("/");
                  gaEventTracker("Account created with apple");

                  //Setting local storage of browser
                  setEncryptedID(USER_PROFILE,res?.returnData[0]);
                  setEncryptedID(USER_LOGGED_IN, "Y");
                  // setSuggestUsIDs(
                  //   res?.returnData[0]?.patient_id,
                  //   res?.returnData[0]?.rol_id,
                  //   res?.returnData[0]?.usr_id
                  // );

                  setContext((prev: any) => ({
                    ...prev,
                    UserAuth: res?.returnData[0],
                  }));
                } else if (
                  res?.returnData[0]?.current_screen === "save_phone"
                ) {
                  navigate("/verify-Phone");
                  gaEventTracker("Enter phone screen");
                } else if (
                  res?.returnData[0]?.current_screen === "verify_otp"
                ) {
                  setEnteredPhResponse(res?.returnData);
                  navigate("/verify-otp/social-media");
                  gaEventTracker("Verify OTP screen");
                }
              }
            } else {
              if (res?.returnData?.length > 0) {
                setVerifyPhone(res?.returnData);
                userID(res?.returnData[0]?.user_id);
                setEncryptedID(SIGN_IN_WITH, "social-media");

                if (res?.returnData[0]?.current_screen === "save_phone") {
                  navigate("/verify-Phone");
                  gaEventTracker("Enter phone screen");
                } else if (
                  res?.returnData[0]?.current_screen === "verify_otp"
                ) {
                  setEnteredPhResponse(res?.returnData);
                  navigate("/verify-otp/social-media");
                  gaEventTracker("Verify OTP screen");
                }
              }
            }
          });
        }
      }
    }
  }, [user]);

  const GoogleSignIn = () => {
    setContext((prev: any) => ({
      ...prev,
      searchDisplayOut: false,
    }));
    SignInWithGoogle(setContext);
  };

  const verifyPhoneHandler = (data: any) => {
    setIsAlert({ type: "", msg: "" });
    // setIsAlertChngPsw({
    //   //@ts-ignore
    //   isTrue : false,
    //   type : '',
    //   msg :"",
    // })
    // console.log('data :>> ', data);
    // console.log('parsePhoneNumber :>> ', parsePhoneNumber(`${data?.phone}`));
    const fullMobileNumber = parsePhoneNumber(`${data?.phone}`)
    const fullMobileNumberUpdated =  fullMobileNumber?.country ? `${fullMobileNumber?.country} +${fullMobileNumber?.countryCallingCode} ${fullMobileNumber?.nationalNumber}` : "";
    if(fullMobileNumberUpdated === ""){
      setIsAlertChngPsw({
        //@ts-ignore
        isTrue : true,
        type : 'error',
        msg :msg_config?.phone_valid,
      })
    }else {
      if (suggestUsInit?.returnCode === true && context?.phLength >= 10) {
        setIsLoading(true);
        const userType =  getDecryptedID(LOGINS_IN_BY) === "pro" ? "pro" : "customer";
        CallAPI(config?.gmail_process_id, {
          p_org_id: suggestUsInit?.org_id,
          p_usr_phone: fullMobileNumberUpdated,
          p_signup_type: "phone",
          p_user_type: userType,
        }).then((res) => {
          if(res?.returnCode === false){
            setIsAlertChngPsw({
              //@ts-ignore
              isTrue : true,
              type : 'error',
              msg :res?.msg,
            })
          }
          setIsLoading(false);
          if (res?.returnData?.length > 0) {
            setSessionStorage(res);
            setPhoneNumber(data?.phone);
            setEncryptedID(PASSWORD_MSG, res?.returnData[0]?.login_message);
            if (loggingBy === "customer") {
              if (res?.returnData[0].user_type == "pro") {
                setIsProUser({
                  isActive: true,
                  msg: "pro",
                  whoLogging: "pro",
                });
              } else {
                if (res?.returnData[0]?.current_screen === "verify_password") {
                  navigate("/enterPassword");
                }
                if (res?.returnData[0]?.current_screen === "verify_otp") {
                  navigate("/verify-otp/phone");
                }
              }
            } else if (loggingBy === "pro") {
              setContext((prev: any) => ({
                ...prev,
                filledPhoneNumber: data?.phone,
              })); 
              if (res?.returnData[0]?.current_screen === "verify_otp") {
                navigate("/verify-otp/phone");
                setContext((prev: any) => ({
                  ...prev,
                  isProRegistering: true,
                }));
              } else {
                if (
                  res?.returnData[0].user_type == null ||
                  res?.returnData[0].user_type == "" ||
                  res?.returnData[0].user_type == "customer"
                ) {
                  setIsProUser({
                    isActive: true,
                    msg: "customer",
                    whoLogging : 'customer',
                  });
                  setContext((prev: any) => ({
                    ...prev,
                    isProRegistering: false,
                  }));
                } else {
                  if (res?.returnData[0]?.current_screen === "verify_password") {
                    navigate("/enterPassword");
                    setContext((prev: any) => ({
                      ...prev,
                      isProRegistering: false,
                    }));
                  }
                }
              }
            }
            // if(res?.returnData[0]?.user_type === "pro"){
            //   setIsProUser(true);
            // }else {
            //   setIsProUser(false);
            //   if(res?.returnData[0]?.current_screen === "verify_password"){
            //     navigate("/enterPassword");
            //   }
            // }
          }
  
          if (isChecked) {
            setEncryptedID(IS_CHECKED,  "Y");
          } else {
            setEncryptedID(IS_CHECKED,  "N");
          }
          if (res?.returnCode) {
            if (res?.returnData?.length > 0) {
              // setIsAlert(res?.msg);
              setLoginScreens(res?.returnData[0]?.user_id?.current_screen);
  
              //Deciding using the if checks whether the user is not registered then move to verify OTP screen if yes then move to verify password screen
              if (res?.returnData[0]?.current_screen === "verify_otp") {
                navigate("/verify-otp/phone");
                // navigate("/enterPassword");
              } else if (
                res?.returnData[0]?.current_screen === "verify_password"
              ) {
                navigate("/enterPassword");
              }
            }
            // else {
            //   setIsAlert({ type: "alert", msg: res?.msg });
            // }
            // setIsAlert({ type: "success", msg: res?.msg });
          } else {
            if (res?.returnData[0]?.current_screen === "verify_password") {
              // navigate("/enterPassword");
            } else if (res?.returnData[0]?.current_screen === "verify_otp") {
              navigate("/verify-otp/phone");
              setEnteredPhResponse(res?.returnData);
              setLoginScreens("/verify-otp/phone");
              navigate("/verify-otp/phone");
            } else if (res?.returnData[0]?.current_screen === "create_password") {
              navigate("/createPassword");
            }
            // setIsAlert({ type: "alert/", msg: res?.msg });
          }
        });
      } else if (context?.phLength < 10) {
        setError("phone", {
          type: "required",
          message: "Invalid phone number",
        });
        setIsAlert({
          type: "error",
          msg: "Phone number should be at least 10 characters",
          istrue:true,
  
        });
      }
    }
  };

  return (
    // <Loading spinning={isLoading}>
    <>
      <div className={Styles["loginContainer"]}>
        {/* <Link className={`${Styles?.appLogo}`} to="/" draggable="false">
          <img
            className={`${Styles?.loginpage_logo}`}
            src={AppLogo}
            alt="app_logo"
            draggable="false"
          />
        </Link> */}
        <Heading className={`${Styles?.page_heading}`}>
          {Config?.heading_login}
        </Heading>
        {/* <div className={Styles["SignInWrapper"]}>
          <div className={Styles["googleSign"]} onClick={() => GoogleSignIn()}>
            <img
              src={GoogleIcon}
              alt="Google icon"
              style={{ width: "19px", height: "19px" }}
              draggable="false"
            />
            <p>{Config?.lbl_Google}</p>
          </div>
          <div
            className={Styles["appleSign"]}
            onClick={() => {
              setContext((prev: any) => ({
                ...prev,
                searchDisplayOut: false,
              }));
              signInwithApple(setContext);
            }}
          >
            <Apple height="22" width="22" style={{ color: "#FFFFFF" }} />
            <p>{Config?.lbl_Apple}</p>
          </div>
        </div> */}
        <div className={Styles["otherSign"]}>
          <span>{Config?.lbl_Divider}</span>
        </div>
        <form onSubmit={handleSubmit(verifyPhoneHandler)} autoComplete={siteConfig?.form_auto_complete_flag}>
          <div className={Styles["inputField"]}>
            <div>
              <Label className={`${Styles?.form_label}`} for="phone">
                {Config?.lbl_Login_number}
              </Label>
              <PhoneFormInput<RegistrationFormFields>
                name="phone"
                register={register}
                rules={{
                  required: msg_config?.login_phone_required,
                }}
                errors={errors}
                control={control}
                placeholder={Config?.inp_P_Holder_Login_number}
                className={`${Styles?.phoneNumber}`}
                phoneInputRef={phoneInputRef}
                setError={setError}
                clearErrors={clearErrors}
                autoComplete={siteConfig?.form_auto_complete_flag}
              />
            </div>
          </div>

          <div className={Styles["forgotPassword"]}>
            {/* Temporary Commented
             *   keep me signed in option
             */}

            {/* <div className={Styles["new"]}>
              <input
                type="checkbox"
                className={Styles["new"]}
                id="signin"
                name="signin"
                value={Config?.lbl_Chk_Box}
                checked={isChecked}
                onChange={handleOnChange}
              />
              <p className={Styles["label"]}>{Config?.lbl_Chk_Box}</p>
            </div> */}
            <div
              className={Styles["Link"]}
              onClick={() => propsshowForgetPassword(true)
                // setIsPasswordModalOpen(true)
              }
            >
              <p
                style={{
                  color: "#2f97e7",
                  cursor: "pointer",
                  fontWeight: "500",
                  float: "right",
                  fontSize: "14px",
                }}
              >
                {Config?.lbl_Forgot}
              </p>
            </div>
            {/* <Link className={Styles["Link"]} to="/forgot-password" draggable='false'>
          <p style={{ color: "#1565D8", cursor: "pointer" }}>
            {Config?.lbl_Forgot}
          </p>
        </Link>   //uncomment if modal doesn't work
         */}
          </div>

          <div className={Styles["verifyButton"]}>
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              style={{ width: "360px" }}
              loading={isLoading}
            >
              {Config?.lbl_verify_Btn}
            </Button>
          </div >
          <div className={Styles["termscondtions"]}>
            <p className={Styles["termscondtionstext"]} >By continuing, you agree to Safe Pharmacy's <span className={Styles["termscondtionshighlights"]} onClick={() => {
              setContext((prev:any)=>{
                return {...prev, termsModal: {
                  isTrue : true,
                  type : "terms"
                }}
              })
            }}><u>Terms of Use</u></span> and <span className={Styles['termscondtionshighlights']}
            onClick={()=>{
              setContext((prev:any)=>{
                return {...prev, termsModal: {
                  isTrue : true,
                  type : "privacy"
                }}
              })
            }}
            ><u>Privacy Policy</u></span></p>
          </div>
        </form>
      </div>
      {/* {isPasswordModalOpen && (
        <ForgotPassword
          setIsPasswordModalOpen={setIsPasswordModalOpen}
          setIsAlertChngPsw={setIsAlertChngPsw}
        />
      )} */}
      {/* {context?.searchDisplayOut ? (
        <Alert type="error" info={msg_config?.loginpage_window_error} />
      ) : (
        ""
      )} */}
      {/* {openTermModalPopup && (
        <TermModalPopup
          checked={isChecked}
          setChecked={setIsChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
          }}
        />)} */}
      {isAlertChngPsw?.isTrue ? (
        <Alert type={isAlertChngPsw?.type} info={isAlertChngPsw?.msg} />
      ) : (
        ""
      )}
    </>
    // </Loading>
  );
}

export default Login;
