import { AnyMxRecord } from "dns";
import { useRef } from "react";
import { FormErrorMessage } from "../FormErrorMessage";
import styles from "./index.module.css";

export type DigitProps = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange?: any;
  style?: any;
  onKeyPress?: any;
  myref?: any;
  value?: any;
  autoFocus?: boolean;
};

const DigitInput: React.FC<DigitProps> = (props) => {
  const {
    name,
    id,
    type,
    placeholder,
    onChange,
    onKeyPress,
    myref,
    value,
    autoFocus,
  } = props;

  const getInputSize = () => {
    return { width: "2.8rem", height: "3.3rem" };
  };

  return (
    <div className={`${styles.container}`}>
      <input
        ref={myref}
        name={name}
        value={value}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className={`${styles.inputStyle}`}
        style={{ ...props?.style, ...getInputSize() }}
        maxLength={1}
        onKeyPress={onKeyPress}
      />
      {!value && (
        <FormErrorMessage className={`${styles?.error_msg}`}>
          {"*"}
        </FormErrorMessage>
      )}
    </div>
  );
};

export default DigitInput;
