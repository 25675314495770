import { createSlice } from "@reduxjs/toolkit";
import { searchJSONArray } from "./utils";

const MyListPageSlice = createSlice({
  name: "MyListPage",
  initialState: {
    products: [],
    filteredProducts: [],
    selectedPricing: {},
    selectedCount: 0,
    myLists: [],
    showAddToMyList: false,
    selectedProduct: {},
    selectedProductQty: {},
    selectedList: {},
    showAddNewMyList: false,
    selectAll: false,
  },
  reducers: {
    SET_PRODUCTS: (state, action) => {
      state.products = action.payload.products;
      state.filteredProducts = action.payload.products;
    },
    SELECT_PRODUCT: (state, action) => {
      const selected = state.filteredProducts[action.payload.idx].isSelected;
      state.filteredProducts[action.payload.idx].isSelected = !selected;
      state.products[action.payload.idx].isSelected = !selected;
      if (selected === true) state.selectedCount--;
      else state.selectedCount++;

      if (state.selectedCount !== state.filteredProducts.length) {
        state.selectAll = false;
      } else state.selectAll = true;
    },
    SEARCH_PRODUCT: (state, action) => {
      const { input } = action.payload;
      if (input === "") {
        state.filteredProducts = state.products;
      } else {
        const results = searchJSONArray(state.products, input);
        state.filteredProducts = results;
      }
    },
    SELECT_QTY: (state, action) => {
      const idx = state.filteredProducts.findIndex(
        (i) => i.srv_id === action.payload.srv_id
      );
      state.filteredProducts[idx].qty = action.payload.qty;
    },
    SELECT_PRICING: (state, action) => {
      state.selectedPricing[action.payload.srv_id] = action.payload.selected;
    },
    SET_MY_LISTS: (state, action) => {
      state.myLists = action.payload?.myLists;
    },
    TOGGLE_MY_LIST: (state, action) => {
      state.showAddToMyList = action.payload?.showAddToMyList;
      state.selectedProduct = action.payload?.product;
      state.selectedProductQty = action.payload?.productQty;
    },
    TOGGLE_CREATE_LIST: (state, action) => {
      state.showAddNewMyList = action.payload?.showAddNewMyList;
    },
    SET_MY_LISTS: (state, action) => {
      state.myLists = action.payload?.myLists;
    },
    ADD_NEW_LIST: (state, action) => {
      state.myLists.push(action.payload?.newList);
    },
    EDIT_LIST: (state, action) => {
      state.showAddNewMyList = action.payload?.showAddNewMyList;
      const { p_list_id } = action.payload;
      state.selectedList = { p_list_id };
    },
    UPDATE_LIST: (state, action) => {
      state.myLists = state.myLists.map((i) => {
        if (i?.p_list_id === action.payload.p_list_id)
          return action.payload?.newList;
        else return i;
      });
    },
    REMOVE_LIST: (state, action) => {
      state.myLists = state.myLists.filter(
        (i) => i.p_list_id !== action.payload.p_list_id
      );
    },
    CLEAR_SELECTION: (state, action) => {
      state.selectedList = null;
    },
    SET_SELECT_ALL: (state, action) => {
      if (action.payload?.value === false) state.selectedCount = 0;
      state.selectAll = action.payload?.value;
      state.filteredProducts = state.filteredProducts.map((product) => {
        const dPrice = parseFloat(product?.discounted_amount);
        const pPrice = parseFloat(product?.srv_price);
        return {
          ...product,
          isSelected: dPrice === 0 && pPrice === 0 ? false : action.payload.value
        }
      });
      state.products = state.products.map((product) => ({
        ...product,
        isSelected:parseFloat(product?.discounted_amount) === 0 && parseFloat(product?.srv_price) === 0 ? false : action.payload.value,
      }));
      if (action.payload.value)
        state.selectedCount = state.filteredProducts.length;
    },
  },
});

export const myListPageReducer = MyListPageSlice?.reducer;
export const mylistPageActions = MyListPageSlice?.actions;
