import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import LoginPopup from "./../../components/ProductDetails/LoginPopup";
import LogoutPopup from "../../pages/Landingpage/LogoutPopup";
import CompanyProfile from "../../pages/SignUpVendor/CompanyProfile/CompanyProfile";
import CreateAccount from "../../pages/SignUpVendor/CreateAccount";
import { registerMerchant } from "../../pages/Home-Page/utils";
import { UserAuthContext } from "../../store/UserAuthContext";
import MyDocuments from "../../components/MyDocuments";
import TermModalPopup from "../../components/TermsModalpopup";
import DocViewer from "../../components/MyDocuments/DocViewer/DocViewer";
import UploadRxPage from "../../pages/SubmitRxPage/UploadRxPage";
import { FIRST_TIME_LOGIN, IS_DOCS_PENDING, IS_EDITING, LOGINS_IN_BY } from "../../config/Config";
import { getDecryptedID, getID, triggerLocalStorage } from "../../utils/utils";
import { decryptData } from "../../utils/util_helper";

import { useSelector } from "react-redux";
import SignUpVendorContainer from "../../pages/SignUpVendor/SignUpVendorContainer/SignUpVendorContainer";
import MyList from "../../components/MyList";
import Alert from "../../components/UI/Alert";
import messageService from "../../components/MessageService";
import ChatDocPopUp from "../../twilio-chat/src/components/ChatModal/AttachmentViewer";
const BasicLayout = (props: any) => {
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [isLogoutPopup, setIsLogoutPopup] = useState(false);
  const [openProfileCard, setOpenCompanyProfileCard] = useState(false);
  const [openCreateCompanyCard, setOpenCreateCompanyCard] = useState(false);
  const [openMyDocuments, setOpenMyDocuments] = useState(false);
  const [context, setContext] = useContext(UserAuthContext);
  const [loginPopup, setloginPopup] = useState(false);
  triggerLocalStorage();
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });
  const [openTermModalPopup, setOpenTermModalPopup] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openDocViewer, setOpenDocViewer] = useState({
    isOpen: false,
    docData: {},
  });
  const [openUploadRx, setOpenUploadRx] = useState(false);
  const showAddToMyList = useSelector(
    (state: any) => state?.myListPageReducer?.showAddToMyList
  );
  const [chatDocPopup, setChatDocPopup] = useState({
    popUpOpen: false,
    files: null,
    showFileIndex: null,
    src: null
  });

  useEffect(() => {

    if (getDecryptedID(LOGINS_IN_BY) === "pro") {
      setOpenCompanyProfileCard(getDecryptedID(FIRST_TIME_LOGIN) === "Y" ? true : false)
    }
    // setOpenCompanyProfileCard(true) 
  }, [getDecryptedID(FIRST_TIME_LOGIN)])

  // useEffect(()=>{
  //   if(getDecryptedID(LOGINS_IN_BY) === "pro"){
  //   setOpenCreateCompanyCard (sessionStorage.getItem("isEditing") === "Y" ? true : false) }
  // },[sessionStorage.getItem("isEditing")])

  useEffect(() => {
    try {
      // console.log('context', context)
      if (context?.isRxUploadActive) {
        setOpenUploadRx(context?.isRxUploadActive)
      } else {
        setOpenUploadRx(false)
      }
      setloginPopup(context?.loginPopup ? true : false);
    } catch (error) {
      console.error(error);
    }
  }, [context])


  useEffect(() => {
    if (!loginPopup) {
      setContext((prev: any) => {
        return { ...prev, loginPopup: false }
      })
    }
  }, [loginPopup])




  useEffect(() => {
    try {
      if (getDecryptedID(IS_DOCS_PENDING) === "Y") {
        setOpenMyDocuments(true)
      } else {
        setOpenMyDocuments(false)
      }
    } catch (error) {
      console.error(error);
    }
  }, [getDecryptedID(IS_DOCS_PENDING)])

  ///receiving message service from chat 
  useEffect(() => {
    messageService?.onMessage().subscribe((m) => {
      if (m.senderId === "TwilioDOC" && m?.target === "chat-doc-pop-up") {
        setChatDocPopup((prev) => {
          return { ...prev, popUpOpen: m?.text?.params?.state, files: m?.text?.params?.files, showFileIndex: m?.text?.params?.index, src: m?.text?.params?.src }
        })
      }
    })
  }
    , [])



  return (
    <>
      {isAlert.istrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}

      <div>
        <Header setIsLogoutPopup={setIsLogoutPopup} setOpenCreateCompanyCard={setOpenCreateCompanyCard} />
        <main className={styles.main}>{props?.children}</main>
        {/* <FAB setIsLoginPopup={setIsLoginPopup} /> */}
        <Footer />
      </div>
      {isLoginPopup && <LoginPopup setModalOpen={setIsLoginPopup} />}
      {isLogoutPopup && <LogoutPopup setModalOpen={setIsLogoutPopup} />}
      {openProfileCard && <div className={`${styles?.modalBackground}`}>
        <div className={`${styles?.modalContainer}`}>

          <CompanyProfile
            setOpenCompanyProfileCard={setOpenCompanyProfileCard}
            handleNext={() => {
              setOpenCompanyProfileCard(false);
              setOpenCreateCompanyCard(true);
              // setOpenSelectType(false);
            }}
            handleBack={() => {
              // setOpenCompanyProfileCard(false);
              // setOpenSelectType(true);
            }}
          />
        </div></div>}
      {openCreateCompanyCard && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <CreateAccount
              setIsAlert={setIsAlert}
              setOpenCreateCompanyCard={setOpenCreateCompanyCard}
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              handleNext={() => {
                registerMerchant();
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);
                // setOpenMyDocuments(false);

                // if(!isEditing){
                //   setOpenMyDocuments(false);
                // }else {
                if (getDecryptedID(IS_EDITING) === "Y") {
                  setOpenMyDocuments(false);
                } else {
                  setOpenMyDocuments(true);
                }
                // }
              }}

              handleBack={() => {
                //@ts-ignore
                let userAuth = context?.UserAuth;
                userAuth = { ...userAuth, usr_company_name: null };
                // setContext((prev) => {
                //   return { ...prev, UserAuth: userAuth };
                // });  
                setOpenCompanyProfileCard(true);
                setOpenCreateCompanyCard(false);
              }}
            />
          </div>
        </div>
      )}

      {openMyDocuments && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <MyDocuments
              setOpenMyDocuments={setOpenMyDocuments}
              setOpenDocViewer={setOpenDocViewer}
              setOpenTermModalPopup={setOpenTermModalPopup}
              handleNext={() => {
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);
                // setOpenMyDocuments(false);
                // setIsAlert({
                //   msg: msg_config?.msg_profile_submitted_for_approval,
                //   type: "success",
                //   istrue: true,
                // });
                // TODO ONBOARDING STATUS - HARRY
              }}
              checked={checked}
              handleBack={() => {
                setOpenMyDocuments(false);
                setOpenTermModalPopup(false);
                setOpenCreateCompanyCard(true);
              }}
            />
          </div>
        </div>
      )}

      {/* Step 5 Terms and conditions */}
      {openTermModalPopup && (
        // <div className={`${styles?.modalBackground}`}>
        //   <div className={`${styles?.modalContainer}`}>
        <TermModalPopup
          checked={checked}
          setChecked={setChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
            setOpenMyDocuments(true);
            // navigate("/product-list");
            // setOpenMyDocuments(true);
          }}
        />
        //   </div>
        // </div>
      )}

      {/* chat attachment viewer component */}
      {chatDocPopup?.popUpOpen && <ChatDocPopUp
        files={chatDocPopup?.files}
        showFileIndex={chatDocPopup?.showFileIndex}
        src={chatDocPopup?.src}
      />}

      {openDocViewer?.isOpen && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <DocViewer
              openDocViewer={openDocViewer}
              setOpenDocViewer={setOpenDocViewer}
            />
          </div>
        </div>
      )}

      {/* upload rx popup */}
      {openUploadRx &&
        <div className={`${styles?.modalBackground} ${styles?.openrx_model}`} style={{ zIndex: "10009" }}>
          <div className={`${styles?.modalContainer}`}>
            <UploadRxPage isPopup={true} />
          </div>
        </div>
      }
      {showAddToMyList && (
        <SignUpVendorContainer maxWidth={"700x"}>
          <MyList setIsAlert={setIsAlert} />
        </SignUpVendorContainer>
      )}

      {loginPopup &&
        <LoginPopup setModalOpen={setloginPopup} />}
    </>
  );
};

export default BasicLayout;
