import React from "react";
import style from "./index.module.css";
import paymentimg from "../../../assets/cod_img.png";

export type paymentRadioButtonProps = {
  name: string;
  id: string;
  value?: any;
  className?: string;
  onChange?: any;
  title?: string;
  type?: string;
  size?: string;
  checked?: any;
  disabled?: any;
};

const PaymentRadioButton: React.FC<paymentRadioButtonProps> = (props) => {
  const {
    name,
    id,
    value,
    className,
    onChange,
    title,
    children,
    type,
    size,
    checked,
    disabled,
  } = props;

  return (
    <label htmlFor={id} className={`${style.radio} ${className}`}>
      <div className={`${style.payment_wrapper}`}>
        <h3 className={`${style.payment_title}`}>{title || children}</h3>
      </div>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        className={`${style.radio_input} ${style?.[`${type}`]}`}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <div
        className={`${style.radio_radio} ${style?.[`${type}`]}`}
        style={{ width: `${size}`, height: `${size}` }}
      >
        {/* virtual element */}
      </div>
    </label>
  );
};

export default PaymentRadioButton;
