import React, { useContext, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useParams } from "react-router-dom";
import myList from "../../assets/mylist.svg";
import {
  ShimmerBadge,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import Config from "../../config/label";
import { config } from "../../config/processId.js";
import { siteConfig } from "../../config/site";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { AppAlertContext } from "../../store/alert-context.js";
import APIContext from "../../store/api-context";
import { UserAuthContext } from "../../store/UserAuthContext.js";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { Cart3, ChevronLeft, ChevronRight, Heart, HeartFill } from "../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, getID, getProductImage, objDecrypt, proAddCartHandler, setEncryptedID, triggerLocalStorage } from "../../utils/utils";
import StarRating from "../StarRating";
import Alert from "../UI/Alert";
import TabPane from "../UI/AppTabs/Tabs-pane/tab-pane";
import Tabs from "../UI/AppTabs/Tabs/tab";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import AddtocartPopup from "./AddtocartPopup";
import styles from "./index.module.css";
import LoginPopup from "./LoginPopup";
import QtyInput from "./QtyInput";
import RelatedProducts from "./RelatedProducts";
import dummyImage from "../../assets/800391.png";
import UnitPricingDropdown from "../UnitPricingDropdown";
import { CART_COUNT, LOGGED_IN_USER_ROLE, SG_PATIENT_ID, SG_USER_ID, SUGGEST_US_INIT, USER_PROFILE } from "../../config/Config";
import { cartSliceActions } from "./slice/slice";
import { useDispatch } from "react-redux";
export type productDetailProps = {
  leftCount?: number;
  heading?: string;
  onFile?: any;
  productImage?: any;
};
import Slider from "react-slick";
import { color_config } from "../../config/colorConfig";
import { decryptData } from "../../utils/util_helper";
import { mylistPageActions } from "../../pages/MyListPage/slice";

const popupStyles = {
  content: {
    zIndex: "999999",
  },
}; 

const ProductDetails: React.FC<productDetailProps> = () => {
  const [productdetails, setProductDetails] = useState<any>("");
  const [selctedImg, setSelctedImg] = useState("");
  const [liked, setLiked] = useState(false);
  const [activeTab, setActiveTab] = useState("Description");
  const [suggestUsInit] = useContext(APIContext);
  const [imageSlides, setImageSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRelatedProductLoading, setIsRelatedProductLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [productQty, setProductQty] = useState({
    productQuantity: "1",
    unitId: "",
  });
  const [productList, setProductList] = useState<any>([]);
  const { prodID } = useParams();
  // const [context, setContext] = useContext(UserAuthContext);
  const [isPopUpOpen, setIsPopUPOpen] = useState(false);
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [addTCLoading, setAddToCLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPriceAvailabel, setIsPriceAvailabel] = useState(false);
  // const [context, setContext] = useState({
  //   UserAuth: {},
  //   searchDisplayOut: false,
  //   type: "",
  //   msg: "",
  //   isUserLoggedIn: isUserLoggedIn,
  //   isLoginPopUP: false,
  //   cartCount: "",
  // });
  const [popupIdx, setPopupIdx] = useState(0);
  const [isImagePopup, setIsImagePopup] = useState(false);
  const [unitPrices, setUnitPrices] = useState([]);

  const dispatch = useDispatch();

  // const [ribon, setRibon] = useState("0");

  const sessionStorageCopy: any =getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  //getting user role
  const userRole = sessionStorageCopy?.usr_role?.split("~")[1] || "";

  let isUserLoggedIn = sessionStorageCopy &&  Object.keys(sessionStorageCopy)?.length > 0 ? true : false;

  const gaEventTracker = useAnalyticsEventTracker("Product detail page");

  const initValues: any = getDecryptedID(SUGGEST_US_INIT);

  const getProductDetails = () => { 
    try {
      window.scrollTo(0, 0);
    setContext((prev: any) => ({
      ...prev,
      searchBox: "",
    }));
    if (suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      CallAPI(config?.xcelecomconfig_get_mst_ecom_product_detailV2, {
        // p_cus_id: sessionStorageCopy?.customer_id || "",
        p_srv_id: "",
        p_slug : prodID ||"",
      }).then((res) => {
        if (res?.returnCode) {
          // callProductsUnitPricingTable(res?.returnData[0]);
          setProductDetails(res?.returnData[0]);
          const productPrice = parseFloat(res?.returnData[0]?.srv_price);
          const disPrice =  parseFloat(res?.returnData[0]?.discounted_amount)
          setIsPriceAvailabel(productPrice === 0 && disPrice === 0)
          setSelctedImg(res?.returnData[0].srv_image.split("~")[0]);
          const mulipleImages = res?.returnData[0]?.srv_image.split("~");
          mulipleImages[0] === "" ? setImageSlides([]) : setImageSlides(res?.returnData[0]?.srv_image.split("~") || "");

          if (
            res?.returnData[0]?.packsize_json !== undefined &&
            res?.returnData[0]?.packsize_json !== null
          ) {
            const pack_size_json = res?.returnData[0]?.packsize_json;
            let unitPrices = JSONCheckerFunc(pack_size_json)?.map((item: any) => ({
              srv_pack_units: item?.srv_pack_units,
              srv_pack_det_id: item?.srv_pack_det_id,
              srv_pack_name: item?.srv_pack_name,
              save_price: item?.save_price,
            }));
            setUnitPrices(unitPrices);
            if (context.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
              setProductQty((prev) => {
                return {
                  ...prev,
                  unitId: unitPrices[0].srv_pack_det_id,
                  productQuantity: unitPrices[0].srv_pack_units,
                };
              });
            }
          }

          if (res?.returnData[0]?.wshlst_status === "Y") {
            setLiked(true);
          } else {
            setLiked(false);
          }
        }
        setIsLoading(false);
      });
    }
    } catch (error) {
      console.error(":>>", error);
    }
    
  };

  // const callProductsUnitPricingTable = (productDetails: any) => {
  //   let processId = config?.xcelecomconfig_get_mst_ecom_product_list_packsize;
  //   CallAPI(processId, {
  //     p_srv_id: productDetails?.srv_id,
  //     sgUserId: localStorage?.getItem(SG_USER_ID),
  //     sgOrgId: localStorage?.getItem("org_id"),
  //   }).then((res) => {
  //     if (res?.returnCode) {
  //       setUnitPrices(res?.returnData)
  //     }
  //   })

  // }

  useEffect(() => {
    getProductDetails();
    triggerLocalStorage();
  }, [prodID, suggestUsInit]);

    const addCartHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      // dispatch(cartSliceActions?.ADD_TO_CART({item:{p_usr_id: localStorage?.getItem(SG_USER_ID),
      // p_service_detail_string:
      //   product?.srv_id + "~" + productQty?.productQuantity,
      // p_flag: product?.service_type,
      // p_pack_detail_id: `${productQty?.unitId}`,
      // p_patient_id: localStorage?.getItem(SG_PATIENT_ID)}}))
      setIsAdding(true);
      const productQuantity = productQty?.productQuantity ? productQty?.productQuantity :  "1" ;
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id:decryptData(getID(SG_USER_ID)),
        p_service_detail_string:
          product?.srv_id + "~" + productQuantity,
        p_flag: product?.service_type,
        p_pack_detail_id: `${productQty?.unitId}`,
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
      }).then((res: any) => {
        if (res?.returnCode) {
          if (res?.returnData[0].cart_count !== undefined) {
            setEncryptedID(CART_COUNT, res?.returnData[0].cart_count);
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_count
            );
            // setContext((prev: any) => ({
            //   ...prev,
            //   cartCount: res?.returnData[0].cart_count,
            // }));
            setIsAdding(false);
          } else {
            setEncryptedID(CART_COUNT,res?.returnData[0].cart_item_count);
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_item_count
            );
            // setContext((prev: any) => ({
            //   ...prev,
            //   cartCount: res?.returnData[0].cart_item_count,
            // }));
            setIsAdding(false);
          }
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          setIsAlert({
            istrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
          setIsAdding(false);
        }
      });
    }
  };

  function getStarRating(flag: string, ratings: number) {
    if (flag === "Y") {
      return (
        <StarRating
          count={ratings}
          className={`gap-2 ${styles?.starRating}`}
          starClassName={"text-6xl"}
        />
      );
    }
  }
  function currencyFormatter(num: any) {
    var numstring = parseFloat(num).toFixed(2);
    return `${siteConfig?.site_currency}${num}`;
  }

  function getProductPrice(
    price: string,
    dprice: string,
    percent: string,
    coPay: any
  ) {
    const discountedPrice = dprice === "0.00" ? false : dprice === price ? false : true;
    if (!discountedPrice) {
      return (
        <div className={`${styles?.priceWrapper}`}>
          <p className={`${styles?.currency}`}>{siteConfig?.site_currency}{price}</p>
        </div>
      );
    } else {
      return (
        <div>
          <div className={`${styles?.pricesWrapper}`}>
            <p className={`${styles?.currency}`}>{siteConfig?.site_currency}{dprice}</p>
            <div className={`${styles?.originalPriceWrapper}`}>
              <p className={`${styles?.originalPrice}`}>
                {currencyFormatter(price)}
              </p>
              {/* <p className={`${styles?.coPay}`}>Co-Pay</p> */}
            </div>
          </div>
          {/* {(Number(coPay) > 0 && (
            <p className={`${styles?.coPay}`}>
              {Config?.estimated_cost_pay_text} {currencyFormatter(coPay)}
            </p>
          )) ||
            ""} */}
        </div>
      );
    }
  }

  function getTabs(flag: string) {
    if (flag === "Y") {
      return (
        <div className={`${styles?.tabsContainer}`}>
          <Tabs
            active={activeTab}
            setActive={setActiveTab}
            ulStyle={{
              width: "100%",
              paddingLeft: "25px",
            }}
            bottomBorderStyle={{
              width: "100%",
              height: "1px",
              opacity: 1,
              backgroundColor: "#E1E3E5",
              marginTop: "-11px",
            }}
            // anchorStyle={{ fontSize: "20px" }}
            anchorClassName={`${styles?.anchorClassName}`}
            ulClassName={`${styles?.tabs}`}
          >
            <TabPane name={Config?.product_tabPane1_heading} key="Description">
              <div className={`${styles?.descriptionPara}`} dangerouslySetInnerHTML={{ __html: productdetails?.srv_detail_description }}>
                {/* {productdetails?.srv_detail_description} */}
              </div>
            </TabPane>
            <TabPane
              name={
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>{Config?.product_tabPane2_heading}</p>
                  <p className={`${styles?.badge}`}>
                    {Config?.product_tabPane2_heading_count}
                  </p>
                </div>
              }
              key="Reviews"
            ></TabPane>
            <TabPane
              name={
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>{Config?.product_tabPane3_heading}</p>
                  <p className={`${styles?.badge}`}>
                    {Config?.product_tabPane3_heading_count}
                  </p>
                </div>
              }
              key="Questions"
            ></TabPane>
          </Tabs>
        </div>
      );
    } else {
      return (
        <div className={`${styles?.tabsContainer}`}>
          <Tabs
            active={activeTab}
            setActive={setActiveTab}
            ulStyle={{
              width: "100%",
              // paddingLeft: "25px",
            }}
            bottomBorderStyle={{
              width: "100%",
              height: "1px",
              opacity: 1,
              backgroundColor: "#E1E3E5",
              marginTop: "-11px",
            }}
            // anchorStyle={{ fontSize: "20px" }}
            anchorClassName={`${styles?.anchorClassName}`}
            ulClassName={`${styles?.tabs}`}
          >
            <TabPane name={Config?.product_tabPane1_heading} key="Description">
              <div className={`${styles?.descriptionPara}`} dangerouslySetInnerHTML={{ __html: productdetails?.srv_detail_description }}>
                {/* {productdetails?.srv_detail_description} */}
              </div>
            </TabPane>
          </Tabs>
        </div>
      );
    }
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.prev_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.next_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function getSubImages() {
    if (imageSlides.length !== 1) {
      const items = [];
      for (let i = 0; i < imageSlides.length; i++) {
        items.push(
          <img
            src={imageSlides[i]}
            alt={"product image " + (i + 1)}
            className={`${styles?.carouselStyle}`}
            onClick={() => setSelctedImg(imageSlides[i])}
            draggable="false"
          />
        );
      }
      return <div className={`${styles?.subImgSection}`}>{items}</div>;
    }
  }
  //states to control the slider of images ==========>
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  function getSubImagesWithSlider() {
    if (imageSlides.length > 1) {
      const items = [];
      return <>
        <Slider  className={`${styles?.product_main_image_slider}`}
          asNavFor={nav2}
          ref={(slider1: any) => setNav1(slider1)}
          arrows ={false}
        >
          {imageSlides.map((images: any, idx: any) => {
            return <img src={images}
            key={`${idx}_product`}
              alt={"product Image " + imageSlides[idx]}
              onClick={() => {
                // setSelctedImg(images);
                setIsImagePopup(true)
              }}
              onError={(e:any)=>{
                e.target.src = getProductImage(productdetails?.salt_composition, "")
              }}
              draggable="false"
              className={`${styles?.carouselStyle}`}
            />
          })}
        </Slider>
        <Slider className={`${styles?.product_slider_main}`}
          asNavFor={nav1}
          ref={(slider2: any) => setNav2(slider2)}
          slidesToShow={2}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows ={true}
          nextArrow ={<SampleNextArrow />}
          prevArrow = {<SamplePrevArrow />}
        >
          {imageSlides.map((images: any, idx: any) => {
            return <img src={images}
            key={`${idx}_product`}
              alt={"product Image " + imageSlides[idx]}
              onClick={() => {
                // setSelctedImg(images);
              }}
              onError={(e:any)=>{
                e.target.src = getProductImage(productdetails?.salt_composition, "")
              }}
              draggable="false"
              className={`${styles?.carouselStyle}`}
            />
          })}
        </Slider>
      </>
    }else if (imageSlides.length === 1) {
      return <img src={imageSlides[0]}
      alt={"product Image " + imageSlides[0]}
      onClick={() => {
        // setSelctedImg(images);
        setIsImagePopup(true)

      }}
      onError={(e:any)=>{
        e.target.src = getProductImage(productdetails?.salt_composition, "")
      }}
      draggable="false"
      className={`${styles?.carouselStyle}`}
    />
    }
  }

  const getRelatedProducts = () => {
    if (suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      CallAPI(config?.related_product_list_process_id, {
        p_srv_id: "",
        p_cus_id: sessionStorageCopy?.customer_id || "",
        p_max_offset: "12",
        p_slug:prodID  || ""
      }).then((res) => {
        if (res?.returnCode) {
          setProductList(res?.returnData);
        }
        setIsRelatedProductLoading(false);
      });
    }
  };

  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: sessionStorageCopy?.usr_id,
        p_wshlst_id: product?.wshlst_id,
      }).then((res) => {
        if (res?.returnCode) {
          setProductList(
            productList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N", wshlst_id: null };
              }
            })
          );
          if (productdetails?.srv_id === product?.srv_id) {
            setProductDetails((prev: any) => {
              return { ...prev, wshlst_status: "N", wshlst_id: null };
            });
          }
          if (res?.returnData[0]?.wshlst_status === "Y") {
            setLiked(true);
          } else {
            setLiked(false);
          }
          gaEventTracker("Items removed from wishlist");
        }
      });
    }
  };

  const wishListAddHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id: sessionStorageCopy?.usr_id,
        p_cus_id: sessionStorageCopy?.customer_id,
        p_srv_id: product?.srv_id,
      }).then((res) => {
        if (res?.returnCode) {
          setProductList(
            productList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );
          if (productdetails?.srv_id === product?.srv_id) {
            setProductDetails((prev: any) => {
              return {
                ...prev,
                wshlst_status: "Y",
                wshlst_id: res?.returnData[0]?.wshlst_id,
              };
            });
          }
          if (res?.returnData[0]?.wshlst_id) {
            setLiked(true);
          } else {
            setLiked(false);
          }
          gaEventTracker("Items added to wishlist");
        }
      });
    }
  };

  function wishlistHandler(product: any) {
    switch (liked) {
      case true:
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
    // setLiked(!liked);
  }

  function getservice() {
    if (productdetails?.service_type === "OTC") {
      return (
        <div className={`${styles?.serviceInfo}`}>Over The Counter Drug</div>
      );
    } else {
      return (
        <div className={`${styles?.serviceInfo}`}>
          <p>Prescription Drug</p>
        </div>
      );
    }
  }

  const getRibbon = (ribbonType: any) => {
    switch (ribbonType) {
      case "corner-left":
        return `${styles?.ribbon1} ${styles?.left}`;
      case "corner-right":
        return `${styles?.ribbon1} ${styles?.right}`;
      case "folded":
        return `${styles?.ribbon2}`;

      default:
        return `${styles?.ribbon1} ${styles?.left}`;
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\D/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setProductQty((prev) => {
        return { ...prev, productQuantity: "" };
      });
    } else if (e.target.value === "0") {
      e.target.value = 1;
      // setProductQty(e.target.value);
      setProductQty((prev) => {
        return { ...prev, productQuantity: e.target.value };
      });
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);
      // setProductQty(newString);
      setProductQty((prev) => {
        return { ...prev, productQuantity: newString };
      });
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "Qty"
    ) {
      let newString = cleanPersonNameInput(e.target.value);
      setProductQty((prev) => {
        return { ...prev, productQuantity: newString };
      });
      // setProductQty(newString);
    } else {
      // setProductQty(e.target.value);
      setProductQty((prev) => {
        return { ...prev, productQuantity: e.target.value };
      });
    }
  };

  useEffect(() => {
    if (productdetails?.wshlst_status === "Y") {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [productdetails]);

  return (
    <>
      <div className="outer_container">

        <div className={`${styles?.productContainer}`}>
          <div
            className={`${styles?.productImageWrapper} ${styles?.overflowHidden}`}
          >
            {isLoading ? (
              <ShimmerThumbnail width={260} height={260} rounded />
            ) : (
              <>
                {/* {productdetails?.discount_percent !== "0" &&
                  productdetails?.discount_percent !== null && (
                    <p className={`${styles?.ribbon} ${getRibbon("folded")}`}>
                      {productdetails?.discount_percent}%
                    </p>
                  )} */}
                <div
                  className={`${styles?.imageBox} ${imageSlides.length === 1  ? styles?.onlyImage : ""} ${imageSlides.length > 0 ? "" : styles?.onlyImage}`}
                  onClick={() => {
                    // setIsImagePopup(true)
                  }
                  }
                >
                  {imageSlides.length > 0 ? 
                  // getSubImages()
                  getSubImagesWithSlider()
                   :
                    <img
                      alt="mainProductImg"
                      src={getProductImage(productdetails?.salt_composition, isImageLoaded ? "" : productdetails?.srv_image)}
                      draggable="false"
                      onError={(e:any) => {
                        e.target.src = getProductImage(productdetails?.salt_composition,"");
                      }}
                    />
                  }
                </div>
                <div className={`${styles?.likeButton}`}>
                  <IconButton
                    onClick={() => {
                      if (isUserLoggedIn) {
                        wishlistHandler(productdetails);
                      } else {
                        setIsPopUPOpen(true);
                      }
                    }}
                    title={liked ? "Remove from wishlist" : "Add to wishlist"}
                    caption={liked ? "Remove from wishlist" : "Add to wishlist"}
                  >
                    
                    {liked ? (
                      <HeartFill
                        fillColor="#EC0038"
                        width="30"
                        height="30"
                        style={{ marginTop: "4px" }}
                        className={`${styles?.heartFill}`}
                      />
                    ) : (
                      <Heart
                        fillColor="#EC0038"
                        width="30"
                        height="30"
                        style={{ marginTop: "4px" }}
                        className={`${styles?.heart}`}
                      />
                    )}
                  </IconButton>
                  <div className={`${styles?.likeButton}`}>
                    <IconButton
                          title={Config?.lbl_myList}
                          onClick={() => {
                            if(isUserLoggedIn){
                              dispatch(
                                mylistPageActions?.TOGGLE_MY_LIST({
                                  showAddToMyList: true,
                                  productdetails,
                                  productQty,
                                })
                              );
                            }else {
                              setContext((prev:any)=>{
                                return {...prev, loginPopup : true}
                              })
                            }
                          }}
                          className={`${styles?.iconButton} ${unitPrices.length === 0 ?  styles?.["disabledMyList"] : ""}`}
                        >
                          <img src={myList} alt="My List" draggable="false" />
                     </IconButton>
                    </div>
                </div>
              </>
            )}

            {isLoading ? (
              <div className={`${styles?.subImgSection}`}>
                {" "}
                <ShimmerThumbnail width={100} height={100} rounded />{" "}
                <ShimmerThumbnail width={100} height={100} rounded />{" "}
                <ShimmerThumbnail width={100} height={100} rounded />{" "}
              </div>
            ) : (
              // getSubImages()
              // getSubImagesWithSlider()
              ""
            )}
          </div>
          <div className={`${styles?.productInfoWrapper}`}>
            {isLoading ? (
              <ShimmerTitle line={2} gap={10} variant="primary" />
            ) : (
              <div className={`${styles?.productTitle}`}>
                <h2 className={`${styles?.productHeading}`}>
                  {productdetails?.srv_name + productdetails?.srv_packaging}
                  {/* {getservice()} */}
                  <div className={styles?.productBrandBelowName}>
                    {productdetails?.srv_brand || ""}
                  </div>
                  {/* <div className={`${styles?.by_pharma}`}>
                    {productdetails?.srv_brand
                      ? `By ${productdetails?.srv_brand}`
                      : ""}
                  </div> */}
                </h2>
              </div>
            )}
            {/* <hr></hr> */}

            {isLoading ? (
              <div className={`${styles?.productBrand}`}>
                <ShimmerBadge width={150} />
                <ShimmerBadge width={150} />
              </div>
            ) : (
              // <>
              //   <p className={`${styles?.productBrand}`}>
              //     {productdetails?.srv_brand}
              //   </p>

              //   <p className={`${styles?.productQuantity}`}>
              //     {productdetails?.salt_composition}
              //   </p>
              // </>
              <>
                {/* <div className={`${styles?.minDetails}`}>
                  <div className={`${styles?.minExpiry}`}>
                    <p className="expiryTitle">
                      {"Min. Expiry"}
                    </p>
                    <p className="expiryValue">
                      {"6 Months"}
                    </p>
                  </div>
                  <div className={`${styles?.minExpiry}`}>
                    <p className="expiryTitle">
                      {"Min. Expiry"}
                    </p>
                    <p className="expiryValue">
                      {"6 Months"}
                    </p>
                  </div>
                  <div className={`${styles?.minExpiry}`}>
                    <p className="expiryTitle">
                      {"Min. Expiry"}
                    </p>
                    <p className="expiryValue">
                      {"6 Months"}
                    </p>
                  </div>
                </div> */}

                {/* 
                <hr></hr>
                <div className={`${styles?.minDetails}`}>
                  <div className={`${styles?.minExpiry}`}>
                    <p className="expiryTitle">
                      {"Min. Expiry"}
                    </p>
                    <p className="expiryValue">
                      {"6 Months"}
                    </p>
                  </div>
                </div> */}
                {/* <hr></hr> */}
              </>
            )}
            {/* {getStarRating(
              productdetails?.rating_view_flag,
              productdetails?.rating_star_count
            )} */}
            {isLoading ? (
              <ShimmerTitle line={6} gap={10} variant="secondary" />
            ) : (
              <>
                {productdetails?.srv_description ? <>
                  <h3 className={`${styles?.overview}`}>
                    {Config?.lbl_overview}
                  </h3>
                  <p className={`${styles?.detailReview}`}>
                    {productdetails?.srv_description}
                  </p>
                </> : ""}
              </>
            )}
          </div>
          <div className={`${styles?.productPriceCartInfo}`}>
            {isLoading ? (
              <ShimmerThumbnail width={400} height={100} rounded />
            ) : (
              <>
                <div className={`${styles?.priceSectionWrapper}`}>
                  <div style={{ flexBasis: "80%" }}>
                    {/*<div className="flex items-center justify-between gap-1 flex-wrap">
                      <div className={`flex items-center`}>
                        <p className={`${styles?.insuredFrom}`}>Insured from</p>
                        <img src={canadaLife} alt="logo" />
                      </div>
                      <div className={`flex items-center`}>
                        <p className={`${styles?.coverage}`}>Coverage</p>
                        <p className={`${styles?.coverageAmount}`}>70%</p>
                      </div>
                      </div>*/}
                    {/* OLD PRICING & QUAINTITY SECTION */}
                    <div
                      className={`${context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE
                        ? styles?.priceSectionMain
                        : styles?.priceSectionMainIsCustomer
                        }`}
                    >
                      {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? (
                        // MAY UNCOMMENT IN FUTURE
                        unitPrices.length > 0 ? (
                          <UnitPricingDropdown
                            pricingData={unitPrices}
                            setProductQty={setProductQty}
                            productQty={productQty}
                            product={productdetails}
                          />
                        ) : (
                          "No unit prices available!"
                        )
                      ) : (
                        <>
                          {getProductPrice(
                            productdetails?.srv_price,
                            productdetails?.discounted_amount,
                            productdetails?.discount_percent,
                            productdetails?.copayment_amount
                          )}
                          <div className={`${styles?.quantity}`}>
                            <QtyInput
                              name="productqty"
                              id={productdetails?.srv_id}
                              className={`${styles?.qtyInp}`}
                              type={Config?.lbl_Inp_type_text}
                              // defaultValue="1"
                              placeholder="1"
                              size="small"
                              value={productQty?.productQuantity}
                              onChange={(e: any) =>
                                onInputChangeHandler(e, "Qty")
                              }
                              maxLength={3}
                            />
                            {
                              <span
                                className={`${styles?.quatitySeparator}`}
                              ></span>
                            }
                            <p>Pcs</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/*<div className={`${styles?.rxVerified}`}>
                    <p className={`${styles?.verifiedHeading}`}>Rx verified from</p>
                    <div className={`${styles.verifiedImg}`}>
                      <img src={RxVerified} alt="rxVerifiedLogo" />
                    </div>
                  </div>*/}
                </div>
                {/* {isAdding ? (
                  <Button type={Config?.lbl_Btn_type_primary} className={`${styles?.buttonStyle}`}>
                    <ReactLoading
                      type={"spin"}
                      color={"#ffffff"}
                      height={"27px"}
                      width={"27px"}
                    />
                  </Button>
                ) : ( */}
                <Button
                  loading={isAdding}
                  type={Config?.lbl_Btn_type_primary}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if(!isPriceAvailabel){
                      if (isUserLoggedIn) {
                        if (context?.UserAuth?.usr_type === "pro")
                          proAddCartHandler(
                            productdetails,
                            productQty,
                            setIsAlert,
                            gaEventTracker,
                            suggestUsInit,
                            setIsCartPopUPOpen,
                            setIsAdding,
                            setLoading
                          );
                        else addCartHandler(productdetails);
                      } else {
                        setIsPopUPOpen(true);
                      }
                    }
                  }}
                  className={`${styles?.buttonStyle} ${isPriceAvailabel ? styles?.addToCartDisabled : ""}`}
                >
                  {/* <Cart3 width="27" height="27" style={{ marginTop: "-4px" }} /> */}
                  <p style={{ fontSize: "16px" }}>{Config?.lbl_add_to_cart}</p>
                </Button>
                {/* )} */}
              </>
            )}
          </div>
        </div>

        {/* Container for tabs styling */}

        {isLoading ? (
          <ShimmerTitle line={8} gap={10} variant="secondary" />
        ) : (
          getTabs(productdetails?.rating_view_flag)
        )}
        {/* Related products container */}
      </div>
      <div className={`${styles?.product_details_slider_wrapper}`}>
        <div className="outer_container">
          <RelatedProducts
            setIsPopUPOpen={setIsPopUPOpen}
            setIsAlert={setIsAlert}
            getRelatedProducts={getRelatedProducts}
            getProductDetails={getProductDetails}
            isRelatedProductLoading={isRelatedProductLoading}
            setIsRelatedProductLoading={setIsRelatedProductLoading}
            productList={productList}
            setProductList={setProductList}
            mainProductdetails={productdetails}
            setMainProductDetails={setProductDetails}
          />


          {/* large img pop up*/}
          {isImagePopup && (
            <Lightbox
              //@ts-ignore
              // mainSrc={getProductImage(productdetails?.salt_composition, isImageLoaded ? "" : productdetails?.srv_image)}
              mainSrc={imageSlides.length > 0 ? imageSlides[popupIdx] : getProductImage(productdetails?.salt_composition, isImageLoaded ? "" : productdetails?.srv_image)}
              nextSrc={imageSlides[(popupIdx + 1) % imageSlides.length]}
              prevSrc={
                imageSlides[
                (popupIdx + imageSlides.length - 1) % imageSlides.length
                ]
              }
              onCloseRequest={() => {
                setIsImagePopup(false); /*;window.location.reload()*/
              }}
              onMovePrevRequest={() =>
                setPopupIdx(
                  (popupIdx + imageSlides.length - 1) % imageSlides.length
                )
              }
              onMoveNextRequest={() =>
                setPopupIdx(
                  (popupIdx + imageSlides.length + 1) % imageSlides.length
                )
              }
              reactModalStyle={popupStyles}
            />
          )}

          {/* login pop up */}
          {isPopUpOpen && <LoginPopup setModalOpen={setIsPopUPOpen} />}
          {/* Added to cart pop up */}
          {isCartPopUpOpen && (
            <AddtocartPopup
              img={selctedImg}
              title={productdetails?.srv_name + productdetails?.srv_packaging}
              setModalOpen={setIsCartPopUPOpen}
            />
          )}
          {/* alert */}
          {isAlert?.istrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
