import React from "react";
import Label from "../../../components/UI/Label";
import LocationInput from "../locationInput";
import Styles from "./index.module.css";
import GoogleMap from "../../../components/UI/GoogleMap";
import Config from "../../../config/label";

function Location() {
  return (
    <div className={Styles["locationContainer"]}>
      <div className={Styles["mapContainer"]}>
        <GoogleMap height="373px" />
      </div>
      <div className={Styles["inputContainer"]}>
        <Label for={"location"} className={`${Styles?.form_label}`}>
          {Config?.lbl_Location}
        </Label>
        <LocationInput
          name={"location"}
          id={"location"}
          type={"text"}
          placeholder={Config?.inp_P_Holder_Location}
          size={"large"}
        />
      </div>
    </div>
  );
}

export default Location;
