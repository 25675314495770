import CompletedIcon from "../assets/svg/blue_tick.svg";
import ActiveIcon from "../assets/svg/circle.svg";
import DisabledIcon from "../assets/svg/DisabledIcon.svg";
import AppLogo from "../assets/appLogo/safe_pharmacy_logo.png";
import MyLocation from "../assets/svg/myLocation.svg";
import GoogleIcon from "../assets/svg/googleIcon.svg";
import LoginBg from "../assets/svg/loginBg.webp";
import RightArrow from "../assets/svg/rightArrow.svg";
import RxVector from "../assets/svg/RxVector.svg";
import UploadRx from "../assets/icons/uploadRx.png";
import TransferRx from "../assets/icons/transferRx.png";
import NewRx from "../assets/icons/newRx.png";
import PdfIcon from "../assets/svg/pdf.svg";
import DeleteIcon from "../assets/svg/delete.svg";
import EyeIcon from "../assets/svg/eye.svg";
import AddIcon from "../assets/svg/Add.svg";
import DoneIcon from "../assets/svg/blue_tick.svg";
import CloseIcon from "../assets/svg/close.svg";
import plusIcon from "../assets/svg/plusIcon.svg";
import FileDelete from "../assets/svg/FileDelete.svg";
import Mask from "../assets/svg/Mask.svg";
import googleMap from "../assets/svg/googleMap.svg";
import MagnifyingGlass from "../assets/svg/MagnifyingGlass.svg";
import OffersIcon from "../assets/svg/OffersIcon.svg";
import CartIcon from "../assets/svg/cartIcon.svg";
import MyRX from "../assets/svg/myRXIcon.svg";
import DropDownIcon from "../assets/svg/arrow_drop_down.svg";
import FacebookIcon from "../assets/svg/Facebook.svg";
import InstagramIcon from "../assets/svg/Instagram.svg";
import TwitterIcon from "../assets/svg/Twitter.svg";
import YoutubeIcon from "../assets/svg/Youtube.svg";
import SettingsIcon from "../assets/svg/settings.svg";
import { color_config } from "../config/colorConfig";
import heartFill from "../assets/sf_svg/Red Heart.svg"
import heart from "../assets/sf_svg/Grey Heart.svg"
import usersvg from "../assets/svg/My_profile.svg";
import ManageAddress from "../assets/svg/manage_address.svg";
import bookmarksfill from "../assets/svg/save.svg";
import bagheartfill from "../assets/svg/wishlist.svg";

export type iconProps = {
  color?: string;
  font?: string;
  width?: string;
  height?: string;
  fillColor?: string;
  className?: string;
  style?: object;
  handleOnClick?: any;
  size?: string;
};

export const ArrowUpCircleFill: any = (color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      className="bi bi-arrow-up-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
    </svg>
  );
};

export const Grid3x3GapFill: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-grid-3x3-gap-fill ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
      />
    </svg>
  );
};

export const ListUL: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-list-ul ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        stroke={color}
        strokeWidth={font}
        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};

export const Heart: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <img src={heart} width={width} height={height} className={className} />

  );
};

export const HeartFill: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
  style,
  handleOnClick,
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className={`bi bi-heart-fill ${className}`}
    //   viewBox="0 0 16 16"
    //   style={{ ...style }}
    //   onClick={() => handleOnClick()}
    // >
    //   <path
    //     fillRule="evenodd"
    //     stroke={color}
    //     strokeWidth={font}
    //     d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
    //   />
    // </svg>
    <img src={heartFill} width={width} height={height} className={className} />
  );
};

export const PlusSquareFill: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className="bi bi-plus-square-fill"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"
      />
    </svg>
  );
};

export const Filter: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-filter ${className}`}
      viewBox="0 0 16 16"
      onClick={() => handleOnClick &&  handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  );
};

export const PlusSquare: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-plus-square ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
      />
      <path
        stroke={color}
        strokeWidth={font}
        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
      />
    </svg>
  );
};

export const Apple: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className="bi bi-apple"
      viewBox="0 0 16 16"
      style={style}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"
      />
      <path
        stroke={color}
        strokeWidth={font}
        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"
      />
    </svg>
  );
};

export const HamburgerIcon: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeWidth={font}
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  );
};

export const Cart3: React.FC<iconProps> = ({
  color,
  font,
  style,
  width = "16",
  height = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className="bi bi-cart3"
      viewBox="0 0 16 16"
      style={style}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      />
    </svg>
  );
};

export const Location: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className="bi bi-geo-alt-fill"
    //   viewBox="0 0 16 16"
    //   style={style}
    // >
    //   <path
    //     stroke={color}
    //     strokeWidth={font}
    //     d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
    //   />
    // </svg>
    <img src={ManageAddress} />
    
  );
};

export const PenFill: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-pen-fill ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"
      />
    </svg>
  );
};

export const TrashIcon: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-trash-fill ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
      />
    </svg>
  );
};
export const EditProfileIcon: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  className,
  handleOnClick,
}) => {
  return (
    <svg
      fill={fillColor}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      style={style}
      onClick={() => handleOnClick()}
      className={`bi bi-person-fill ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M3 17.75C3 19.5449 4.45507 21 6.25 21H11.1651L11.5209 19.5768C11.6829 18.9288 12.018 18.3371 12.4903 17.8648L18.3927 11.9624C19.1066 11.2485 20.0672 10.9318 21 11.0122V6.25C21 4.45507 19.5449 3 17.75 3H11V7.75C11 9.54493 9.54493 11 7.75 11H3V17.75ZM9.5 3.44L3.44 9.5H7.75C8.7165 9.5 9.5 8.7165 9.5 7.75V3.44ZM19.0999 12.6695L13.1974 18.5719C12.8533 18.916 12.6092 19.3472 12.4911 19.8194L12.0334 21.6501C11.8344 22.4462 12.5556 23.1674 13.3517 22.9683L15.1824 22.5106C15.6545 22.3926 16.0857 22.1485 16.4299 21.8043L22.3323 15.9019C23.2249 15.0093 23.2249 13.5621 22.3323 12.6695C21.4397 11.7768 19.9925 11.7768 19.0999 12.6695Z"
        fill="#2d4266"
      />
    </svg>
  );
};
export const PersonFill: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className={`bi bi-person-fill ${className}`}
    //   viewBox="0 0 16 16"
    //   style={style}
    //   onClick={() => handleOnClick()}
    // >
    //   <path
    //     stroke={color}
    //     strokeWidth={font}
    //     d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
    //   />
    // </svg>
    <img src={usersvg} />
  );
};

export const PersonCircle: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className={`bi bi-person-circle ${className}`}
    //   viewBox="0 0 16 16"
    //   style={style}
    //   onClick={() => handleOnClick()}
    // >
    //   <path
    //     d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
    //     stroke={color}
    //     strokeWidth={font}
    //   />
    //   <path
    //     fillRule="evenodd"
    //     d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
    //   />
    // </svg>
    <h3>xdvfads</h3>
  );
};

export const RightDirectionArrow: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-arrow-right ${className}`}
      viewBox="0 0 16 16"
      style={style}
      // onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
    // <h3>dagg</h3>
  );
};

export const Bagheart: React.FC<iconProps> = ({
  color,
  font,
  width = "14",
  height = "14",
  fillColor = "#32c5f4",
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className="bi bi-bag-heart-fill"
    //   viewBox="0 0 16 16"
    // >
    //   <path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
    // </svg>
    <img src={bagheartfill} />
    
  );
};

export const Bookmarks: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "#32c5f4",
}) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   fill={fillColor}
    //   className="bi bi-bookmarks-fill"
    //   viewBox="0 0 16 16"
    // >
    //   <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4z" />
    //   <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1H4.268z" />
    // </svg>
    <img src={bookmarksfill} />
    
  );
};

export const ChevronDown: React.FC<iconProps> = ({
  color,
  font,
  width = "16",
  height = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className="bi bi-chevron-down"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export const Delivery: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-10gnm35-MuiSvgIcon-root ${className}`}
      width={width}
      height={height}
      fill={fillColor}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 32 32"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path d="M7.40692 11.3465L20.5779 4.304L16.0004 2L2.41992 8.836L7.40692 11.3465Z"></path>
      <path d="M24.5773 6.3175L11.4062 13.36L15.9998 15.6725L29.5803 8.83601L24.5773 6.3175Z"></path>
      <path d="M15.625 16.3275L11 13.9995V19.1145L9 17.101H7V11.986L2 9.46948V23.1415L15.625 30V16.3275Z"></path>
      <path d="M16.375 16.3275V30L30 23.1415V9.46948L16.375 16.3275Z"></path>
    </svg>
  );
};

export const Correct: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-17e890r-MuiSvgIcon-root ${className}`}
      width={width}
      height={height}
      fill={fillColor}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="DoneIcon"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
      ></path>
    </svg>
  );
};

export const TruckFilled: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-10gnm35-MuiSvgIcon-root ${className}`}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 36 36"
      width={width}
      height={height}
      fill={fillColor}
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        stroke={color}
        strokeWidth={font}
        d="M5.1302 7.5H22.4118C22.8992 7.5 23.2941 7.89504 23.2941 8.38236V10.1471H28.322C28.8735 10.1471 29.3583 10.5127 29.5098 11.043L31.0223 16.3368C31.1997 16.368 31.3647 16.4529 31.4937 16.5819L32.7416 17.8298C32.907 17.9952 33 18.2198 33 18.4538V24.2648C33 24.752 32.6049 25.1471 32.1177 25.1471H29.4707C29.4707 27.0963 27.8904 28.6764 25.9412 28.6764C23.9919 28.6764 22.4118 27.0963 22.4118 25.1471H13.5882C13.5882 27.0963 12.0081 28.6764 10.0588 28.6764C8.10959 28.6764 6.52941 27.0963 6.52941 25.1471H3.88236C3.39504 25.1471 3 24.752 3 24.2648V9.63019C3 9.39618 3.09296 9.17175 3.25844 9.00627L4.50627 7.75843C4.67175 7.59295 4.89618 7.5 5.1302 7.5ZM29.1833 16.3236L27.9227 11.9118H23.2941V15.4412C23.2941 15.9285 23.6892 16.3236 24.1764 16.3236H29.1833ZM25.9412 23.3823C26.9159 23.3823 27.7059 24.1725 27.7059 25.1471C27.7059 26.1218 26.9159 26.9118 25.9412 26.9118C24.9666 26.9118 24.1764 26.1218 24.1764 25.1471C24.1764 24.1725 24.9666 23.3823 25.9412 23.3823ZM8.29412 25.1471C8.29412 26.1218 9.08421 26.9118 10.0588 26.9118C11.0334 26.9118 11.8235 26.1218 11.8235 25.1471C11.8235 24.1725 11.0334 23.3823 10.0588 23.3823C9.08421 23.3823 8.29412 24.1725 8.29412 25.1471Z"
      ></path>
    </svg>
  );
};

export const PackageBox: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-10gnm35-MuiSvgIcon-root ${className}`}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={fillColor}
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M24.0531 1.69349L23.7031 2.33374C23.5704 2.57647 23.3708 2.77603 23.1281 2.90874L22.4878 3.25874C22.4584 3.27482 22.4339 3.29852 22.4167 3.32735C22.3996 3.35619 22.3906 3.38909 22.3906 3.42262C22.3906 3.45614 22.3996 3.48905 22.4167 3.51788C22.4339 3.54671 22.4584 3.57041 22.4878 3.58649L23.1281 3.93649C23.3708 4.0692 23.5704 4.26876 23.7031 4.51149L24.0531 5.15174C24.0691 5.18122 24.0928 5.20583 24.1217 5.22297C24.1506 5.24011 24.1835 5.24916 24.2171 5.24916C24.2506 5.24916 24.2836 5.24011 24.3124 5.22297C24.3413 5.20583 24.365 5.18122 24.3811 5.15174L24.7311 4.51149C24.8639 4.26884 25.0634 4.0693 25.3061 3.93649L25.9463 3.58649C25.9757 3.57041 26.0003 3.54671 26.0174 3.51788C26.0345 3.48905 26.0435 3.45614 26.0435 3.42262C26.0435 3.38909 26.0345 3.35619 26.0174 3.32735C26.0003 3.29852 25.9757 3.27482 25.9463 3.25874L25.3061 2.90874C25.0634 2.77593 24.8639 2.5764 24.7311 2.33374L24.3811 1.69349C24.365 1.66401 24.3413 1.63941 24.3124 1.62226C24.2836 1.60512 24.2506 1.59607 24.2171 1.59607C24.1835 1.59607 24.1506 1.60512 24.1217 1.62226C24.0928 1.63941 24.0691 1.66401 24.0531 1.69349Z"
      ></path>
      <path
        stroke={color}
        strokeWidth={font}
        d="M27.4868 6.42496L27.2408 6.87496C27.1475 7.04555 27.0072 7.18577 26.8366 7.27896L26.3866 7.52496C26.3659 7.53626 26.3487 7.55292 26.3367 7.57317C26.3247 7.59343 26.3184 7.61654 26.3184 7.64008C26.3184 7.66363 26.3247 7.68674 26.3367 7.70699C26.3487 7.72725 26.3659 7.7439 26.3866 7.75521L26.8366 8.00121C27.0072 8.0944 27.1475 8.23462 27.2408 8.40521L27.4868 8.85521C27.4981 8.87593 27.5147 8.89323 27.535 8.90528C27.5552 8.91733 27.5784 8.9237 27.602 8.9237C27.6255 8.9237 27.6487 8.91733 27.6689 8.90528C27.6892 8.89323 27.7058 8.87593 27.7171 8.85521L27.9631 8.40521C28.0562 8.23422 28.1964 8.09357 28.3671 7.99996L28.8171 7.75396C28.8377 7.74265 28.855 7.726 28.867 7.70574C28.879 7.68549 28.8853 7.66238 28.8853 7.63883C28.8853 7.61529 28.879 7.59218 28.867 7.57192C28.855 7.55167 28.8377 7.53501 28.8171 7.52371L28.3671 7.27771C28.1966 7.18443 28.0564 7.04423 27.9631 6.87371L27.7171 6.42371C27.7057 6.40305 27.689 6.38584 27.6687 6.37389C27.6483 6.36195 27.6252 6.35571 27.6016 6.35584C27.578 6.35596 27.5549 6.36245 27.5347 6.37462C27.5145 6.38678 27.498 6.40417 27.4868 6.42496Z"
      ></path>
      <path
        stroke={color}
        strokeWidth={font}
        d="M22.72 27.117C22.2155 26.7778 21.395 26.9463 20.616 27.144C20.3484 27.2145 20.0852 27.3007 19.8278 27.402C19.3339 27.6067 18.806 27.717 18.2715 27.727C17.6673 27.717 16.961 27.5575 16.5123 27.8078C16.4668 27.8331 16.4165 27.8487 16.3647 27.8536C16.3129 27.8584 16.2606 27.8524 16.2112 27.8359C16.1618 27.8195 16.1164 27.7929 16.0779 27.7579C16.0393 27.723 16.0084 27.6803 15.9873 27.6328C15.951 27.541 15.9505 27.439 15.9859 27.3469C16.0214 27.2548 16.0901 27.1794 16.1785 27.1355C16.8433 26.7855 17.6623 27.0173 18.3395 27.1148C18.742 27.1795 19.1513 27.1907 19.5568 27.1483C19.6799 27.1304 19.7915 27.066 19.8685 26.9684C19.9456 26.8707 19.9823 26.7472 19.971 26.6233C19.9497 26.5028 19.8999 26.3892 19.8257 26.2919C19.7515 26.1946 19.6551 26.1165 19.5445 26.064C19.1475 25.8553 17.709 25.464 16.7735 25.273C15.285 24.9739 13.739 25.1859 12.386 25.8748C11.6408 26.2278 9.50876 27.6795 8.98101 28.504C8.90689 28.6092 8.8747 28.7382 8.89076 28.8658C8.94426 29.3465 10.3875 30.7326 10.8303 30.8761C10.9111 30.9062 10.9981 30.9159 11.0836 30.9044C11.1692 30.8929 11.2505 30.8605 11.3205 30.8101C11.5873 30.6386 12.174 30.0625 12.765 29.855C13.8595 29.47 15.6748 29.8025 16.5585 29.7815C17.8585 29.7503 22.201 28.6373 22.8718 28.0315C23.1388 27.7913 23.0415 27.333 22.72 27.117Z"
      ></path>
      <path
        stroke={color}
        strokeWidth={font}
        d="M13.1345 8.16596L13.8048 5.48721H17.1298L17.799 8.16596H24.5925L21.679 5.69996C21.5161 5.56197 21.3095 5.48623 21.096 5.48621H9.8383C9.62479 5.48623 9.41822 5.56197 9.2553 5.69996L6.3418 8.16596H13.1345Z"
      ></path>
      <path
        stroke={color}
        strokeWidth={font}
        d="M17.8989 8.91602V14.55C17.899 14.6265 17.8796 14.7017 17.8426 14.7686C17.8056 14.8355 17.7523 14.8919 17.6875 14.9325C17.6228 14.9731 17.5488 14.9967 17.4725 15.0009C17.3961 15.0051 17.32 14.9898 17.2512 14.9565L15.7702 14.13C15.6883 14.0597 15.584 14.0211 15.476 14.0211C15.3681 14.0211 15.2638 14.0597 15.1819 14.13L13.6854 14.9598C13.6166 14.9926 13.5406 15.0075 13.4645 15.003C13.3884 14.9986 13.3146 14.9749 13.2501 14.9342C13.1856 14.8936 13.1325 14.8373 13.0956 14.7705C13.0588 14.7038 13.0395 14.6288 13.0394 14.5525V8.91602H5.95117V22.7145C5.95117 22.9143 6.03052 23.1059 6.17178 23.2472C6.31303 23.3885 6.50462 23.468 6.70442 23.468H24.2347C24.4345 23.468 24.6262 23.3886 24.7675 23.2473C24.9088 23.106 24.9882 22.9144 24.9882 22.7145V8.91602H17.8989Z"
      ></path>
    </svg>
  );
};

export const Download: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  size,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-exclamation-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
  );
};

export const ExclamationCircle: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-exclamation-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
  );
};

export const CancelIcon: React.FC<iconProps> = ({
  color,
  font,
  style,
  width,
  height,
  fillColor = "currentColor",
  handleOnClick,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      className={`bi bi-x ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export const ExclamationTriangle: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-exclamation-triangle"
      viewBox="0 0 16 16"
    >
      <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
    </svg>
  );
};

export const CheckCircle: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-check-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg>
  );
};

export const X: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-x"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};

export const CartPlus: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-cart-plus ${className}`}
      viewBox="0 0 16 16"
    >
      <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </svg>
  );
};

export const ChevronLeft: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-chevron-left ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeWidth={font}
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  );
};

export const ChevronRight: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-chevron-right ${className}`}
      viewBox="0 0 16 16"
      style={{ marginBottom: "0.1rem" }}
    >
      <path
        stroke={color}
        strokeWidth={font}
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export const BoxArrowUpLeft: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-box-arrow-up-left ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"
      />
      <path
        fillRule="evenodd"
        d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"
      />
    </svg>
  );
};

export const CarretDownFill: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-caret-down-fill ${className}`}
      viewBox="0 0 16 16"
      style={style}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
      />
    </svg>
  );
};

export const CarretUpFill: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-caret-up-fill ${className}`}
      viewBox="0 0 16 16"
      style={style}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
      />
    </svg>
  );
};

export const Chat: React.FC<iconProps> = ({
  color,
  font,
  size = "30",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-chat-left-dot ${className}`}
      viewBox="0 0 16 16"
      style={style}
    >
      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </svg>
  );
};

export const SubmitRX: React.FC<iconProps> = ({
  color,
  font,
  size = "24",
  fillColor = "currentColor",
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-clipboard2-pulse ${className}`}
      viewBox="0 0 16 16"
    >
      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
    </svg>
  );
};

export const EyeOpen: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-eye ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        stroke={color}
        strokeWidth={font}
        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
      />
      <path
        stroke={color}
        strokeWidth={font}
        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
      />
    </svg>
  );
};

export const EyeSlash: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-eye-slash ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
    </svg>
  );
};

export const CancelOrder: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = color_config?.common_bg_color,
  className,
  style,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-cart-x-fill ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7.354 5.646 8.5 6.793l1.146-1.147a.5.5 0 0 1 .708.708L9.207 7.5l1.147 1.146a.5.5 0 0 1-.708.708L8.5 8.207 7.354 9.354a.5.5 0 1 1-.708-.708L7.793 7.5 6.646 6.354a.5.5 0 1 1 .708-.708z" />
    </svg>
  );
};

export const PdfDrawer: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-filetype-pdf ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        fillRule="evenodd"
        d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
      />
    </svg>
  );
};

export const PngDrawer: React.FC<iconProps> = ({
  color,
  font,
  size = "16",
  fillColor = "currentColor",
  className,
  style,
  handleOnClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className={`bi bi-filetype-png ${className}`}
      viewBox="0 0 16 16"
      style={style}
      onClick={() => handleOnClick()}
    >
      <path
        fillRule="evenodd"
        d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-3.76 8.132c.076.153.123.317.14.492h-.776a.797.797 0 0 0-.097-.249.689.689 0 0 0-.17-.19.707.707 0 0 0-.237-.126.96.96 0 0 0-.299-.044c-.285 0-.506.1-.665.302-.156.201-.234.484-.234.85v.498c0 .234.032.439.097.615a.881.881 0 0 0 .304.413.87.87 0 0 0 .519.146.967.967 0 0 0 .457-.096.67.67 0 0 0 .272-.264c.06-.11.091-.23.091-.363v-.255H8.82v-.59h1.576v.798c0 .193-.032.377-.097.55a1.29 1.29 0 0 1-.293.458 1.37 1.37 0 0 1-.495.313c-.197.074-.43.111-.697.111a1.98 1.98 0 0 1-.753-.132 1.447 1.447 0 0 1-.533-.377 1.58 1.58 0 0 1-.32-.58 2.482 2.482 0 0 1-.105-.745v-.506c0-.362.067-.678.2-.95.134-.271.328-.482.582-.633.256-.152.565-.228.926-.228.238 0 .45.033.636.1.187.066.348.158.48.275.133.117.238.253.314.407Zm-8.64-.706H0v4h.791v-1.343h.803c.287 0 .531-.057.732-.172.203-.118.358-.276.463-.475a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.475-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.381.574.574 0 0 1-.238.24.794.794 0 0 1-.375.082H.788v-1.406h.66c.218 0 .389.06.512.182.123.12.185.295.185.521Zm1.964 2.666V13.25h.032l1.761 2.675h.656v-3.999h-.75v2.66h-.032l-1.752-2.66h-.662v4h.747Z"
      />
    </svg>
  );
};
export {
  CompletedIcon,
  ActiveIcon,
  DisabledIcon,
  AppLogo,
  MyLocation,
  GoogleIcon,
  LoginBg,
  RightArrow,
  RxVector,
  UploadRx,
  TransferRx,
  NewRx,
  PdfIcon,
  EyeIcon,
  DeleteIcon,
  AddIcon,
  DoneIcon,
  CloseIcon,
  FileDelete,
  plusIcon,
  Mask,
  googleMap,
  MagnifyingGlass,
  OffersIcon,
  CartIcon,
  MyRX,
  DropDownIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
  SettingsIcon,
};
