import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import userSkeleton from "../../../assets/svg/user_1.svg";
import Button from "../../../components/UI/Button";
import DatePicker from "../../../components/UI/DatePicker";
import FileUpload from "../../../components/UI/FileUpload";
import { FormInput } from "../../../components/UI/FormInput";
import Heading from "../../../components/UI/Heading";
import { PhoneFormInput } from "../../../components/UI/PhoneFormInput";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import btnConfig from "../../../config/button";
import Config from "../../../config/label";
import { msg_config } from "../../../config/messages";
import { config } from "../../../config/processId";
import { siteConfig } from "../../../config/site";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { CloseIcon } from "../../../utils/appIcons";
import { JSONCheckerFunc, emailPattern, extractCountryCodeAndNumber, getDecryptedID, objDecrypt, objEncrypt, setEncryptedID } from "../../../utils/utils";
import Styles from "./index.module.css";
import IconButton from "../../../components/UI/IconButton";
import Delete from "../../../assets/icons/Delete_icon.svg";
import { FormErrorMessage } from "../../../components/UI/FormErrorMessage";
import { UserAuthContext } from "../../../store/UserAuthContext";
import ImageEditor from "../../../components/ImageEditor";
import { USER_PROFILE } from "../../../config/Config";
import { parsePhoneNumber } from "react-phone-number-input";

// import Delete from "../../../assets/icons/Delete_icon.svg";

export type ModalProps = {
  setIsEditModalOpen?: any;
  editHeading?: string;
  userInfoHandler?: any;
  userInfo?: any;
  profileForm?: any;
  setProfileForm?: any;
  setModalOpen?: any;
};

export type RegistrationFormFields = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
  setModalOpen?: any;
};

const EditProfile: React.FC<ModalProps> = (props) => {
  const {
    setIsEditModalOpen,
    editHeading,
    userInfoHandler,
    userInfo,
    profileForm,
    setProfileForm,
    setModalOpen,
  } = props;
  const [genderList, setGenderList] = useState<any>([]);
  const [fileContents, setFileContents] = useState<any>([]);
  const [context, setContext] = useContext(UserAuthContext);
  const [uploadedDocumentId, setUploadedDocumentId] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [suggestUsInit] = useContext(APIContext);
  const [dobErrorMsg, setDobErrorMsg] = useState(false);
  // const [dobSetKeyDown, setDobSetKeyDown] = useState(false);

  const [checkFields, setCheckFields] = useState({
    isPhDisable: false,
    isEmailDisable: false,
  });

  const phoneInputRef = useRef<any>(null);
  let userProfileJson: any = getDecryptedID(USER_PROFILE);
  // let userProfileJson = JSONCheckerFunc(userProfile);
  const chkFieldDisable = userProfileJson?.usr_signup_type === "phone";
  const [editedImage, setEditedImage] = useState("");
  const [isImageEditor, setIsImageEditor] = useState(false);

  useEffect(() => {
    if (chkFieldDisable === true) {
      setCheckFields({ isPhDisable: true, isEmailDisable: false });
    } else {
      setCheckFields({ isPhDisable: false, isEmailDisable: true });
    }
  }, [chkFieldDisable]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const uploadFileDataAxios = (data: any) => {
    return axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const deleteDocument = () => {
    setEditedImage("");
    setFileContents([]);
    setUploadedDocumentId([]);
    setProfileForm((prev: any) => {
      return { ...prev, patient_photo: userSkeleton, document_id: "" };
    });
  };

  async function rxUploadDocument(filename: any) {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: filename,
        p_doc_remarks: "",
        p_doctype_code: siteConfig?.profile_image_p_doctype_code,
        p_trnt_code: siteConfig?.profile_image_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: siteConfig?.profile_image_p_docsubtype_code,
        p_doc_uploaded_filename: filename,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }

  async function callUploadDocuments(urlData: any) {
    Promise.resolve(rxUploadDocument(urlData)).then((res) => {
      if (res?.returnCode) {
        setUploadedDocumentId(res?.returnData[0]?.document_id);
        //generateRx(res?.returnData[0]?.document_id);
      }
    });
    setLoading(false);
  }

  const handleImageEdit = (imgData: any) => {
    const imageExtension = imgData?.extension;
    const imageURL = imgData?.imageBase64;
    setEditedImage(imageURL);
    fetch(imageURL)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const file = new File([blob], imageExtension, {
          type: `image/${imageExtension}`,
        });
        let formData = new FormData();
        formData.append("uploadedFile", file);

        Promise.resolve(uploadFileDataAxios(formData)).then((res) => {
          if (res?.data?.result === "true") {
            callUploadDocuments(res?.data?.url);
          }
        });
      });
    setIsImageEditor(false);
  };

  const handleClose = () => {
    setFileContents([]);
    setIsImageEditor(false);
    setLoading(false);
  };

  useEffect(() => {
    if (fileContents.length > 0) {
      setLoading(true);
      setIsImageEditor(true);
    }
  }, [fileContents]);

  const onProfileSubmitHandler = (data: any) => {
    if (
      profileForm?.dob === "Invalid date" ||
      profileForm?.dob === "" ||
      profileForm?.dob === undefined
    ) {
      setDobErrorMsg(true);
    } else if (suggestUsInit?.returnCode === true && context?.phLength >= 10) {
      setDobErrorMsg(false);
      setLoading(true);
      const fullMobileNumber = parsePhoneNumber(`${data?.phone}`)
      const fullMobileNumberUpdated =  fullMobileNumber?.country ? `${fullMobileNumber?.country} +${fullMobileNumber?.countryCallingCode} ${fullMobileNumber?.nationalNumber}` : "";
      const dobUTC = moment(profileForm?.dob).format("YYYY-MM-DD");
      if(fullMobileNumberUpdated === ""){
        alert(msg_config?.phone_valid)
      }else {
        CallAPI(config?.update_my_profile_process_id, {
          p_customer_id: userProfileJson?.customer_id,
          p_customer_dob:profileForm?.dob === "Invalid date" ? "" : dobUTC,
          p_customer_first_name: data?.firstName,
          p_customer_last_name: data?.lastName,
          p_customer_email: data?.email,
          p_customer_phone_no: fullMobileNumberUpdated,
          p_patient_gender: data?.gender.value,
          p_customer_img_document_id:
            (uploadedDocumentId?.length > 0 && uploadedDocumentId[0]) ||
            profileForm?.document_id ||
            "",
        }).then((res) => {
          if (res?.returnCode === true) {
            userProfileJson = res?.returnData[0];
            setEncryptedID(USER_PROFILE, res?.returnData[0]);
            //store in session storage
            userInfoHandler();
            setIsEditModalOpen(false);
            if (setModalOpen) {
              setModalOpen(false);
            }
            setLoading(false);
            setContext((prev: any) => {
              return { ...prev, UserAuth: res?.returnData[0] };
            });
          } else {
            setLoading(false);
            console.error("error", res?.error);
          }
        });
      }
    } else if (context?.phLength < 10) {
      setError("phone", {
        type: "required",
        message: "Invalid phone number",
      });
    }
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      setLoading(true);
      CallAPI(config?.gender_dropdown_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setGenderList([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.code,
                label: item?.description,
              };
            }),
          ]);
          var list: any = [
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.code,
                label: item?.description,
              };
            }),
          ];
          setValue(
            "gender",
            list.find((i: any) => i?.value === profileForm?.gender)
          );
          setLoading(false);
        }
      });
    }
  }, [suggestUsInit]);

  const cleanPhoneInput = (inp: any) => {
    if (inp) {
      if (inp.includes("+")) {
        if (inp.includes("+1")) {
          let newNum = inp
            .replace("+1", "")
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9 ]/g, "");
          return newNum;
        } else if (inp.includes("+91")) {
          let newNum = inp
            .replace("+91", "")
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9 ]/g, "");
          return newNum;
        } else {
          return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
        }
      } else {
        return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
      }
    }

    // if (inp) {
    //   return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
    // }
    return "";
  };

  useEffect(() => {
    try {
      setValue("firstName", profileForm?.firstName);
    setValue("lastName", profileForm?.lastName);
    let phoneNumber = extractCountryCodeAndNumber(profileForm?.phone);
    //@ts-ignore
    setValue("phone", phoneNumber);
    setValue("email", profileForm?.email);

    let newNumber = cleanPhoneInput(profileForm?.phone);
    setContext((prev: any) => ({
      ...prev,
      phLength: newNumber?.length,
    }));
    // const cleanPersonNameInput = (inp: any) => {
    //   if (inp) {
    //     return inp
    //       .replace(/\s/g, "")
    //       .replace(/[^a-zA-Z0-9 ]/g, "")
    //       .replace(/^0+/, "");
    //   }
    //   return "";
    // };

    // const phVal = phoneInputRef?.current?.value;

    // //@ts-ignore
    // var myNumber = document.getElementById("phone").value;
    // let value = cleanPersonNameInput(myNumber);
    // //@ts-ignore
    // //@ts-ignore
    // document.getElementById("phone").innerHTML = value;
    // phoneInputRef.current = value;

    // // clearErrors("phone");
    // setContext((prev: any) => ({
    //   ...prev,
    //   phLength: value?.length,
    // }));
    } catch (error) {
      console.error(error);
    }
  }, [profileForm]);

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: "" };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: e.target.value };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };
  // onkeydown = (ev) => {
  //   if (dobSetKeyDown) {
  //     ev.preventDefault();
  //     setDobSetKeyDown(false);
  //   }
  // };
  useEffect(() => {
    if (profileForm?.dob !== "Invalid date") {
      setDobErrorMsg(false);
    }
  }, [profileForm]);

  return (
    <>
      <div className={Styles["modalBackground"]}>
        <div className={Styles["modalContainer"]}>
          <div
            className={Styles["titleCloseBtn"]}
            title="Close button"
            draggable="false"
            onClick={() => {
              setIsEditModalOpen(false);
              setModalOpen && setModalOpen(false);
              /*setProfileForm({
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                dob: "",
                patient_photo: "",
              });*/
            }}
          >
            <img src={CloseIcon} alt="Cross icon" draggable="false" />
          </div>
          <div className={Styles["title"]}>
            <Heading className={`${Styles?.page_heading}`}>
              {editHeading}
            </Heading>
          </div>

          <form onSubmit={handleSubmit(onProfileSubmitHandler)}>
            {/* First row */}
            <div className={`${Styles?.imageContainer}`}>
              <div className={`${Styles?.profileImage}`}>
                <img
                  src={
                    editedImage ||
                    fileContents[0]?.uri ||
                    profileForm?.patient_photo 
                    // userSkeleton
                  }
                  alt="User Profile"
                  draggable="false"
                />
                <div className={`${Styles?.uploadControl}`}>
                  <FileUpload
                    type="icon"
                    name="profile_photo"
                    id="profile_photo"
                    setFileContents={setFileContents}
                    fileContents={fileContents}
                    loading={loading}
                  />
                </div>
                {(profileForm?.patient_photo?.includes("user_128.png") ===
                  false &&
                  profileForm?.patient_photo !== "" && (
                    <div
                      className={`${Styles?.deleteIconWrapper}`}
                      onClick={() => deleteDocument()}
                    >
                      {/* <img
                    src={Delete}
                    alt="delete"
                    style={{ width: "25px", height: "25px" }}
                    draggable="false"
                  /> */}
                      <i
                        className="fa-solid fa-trash-can"
                        draggable="false"
                      ></i>
                    </div>
                  )) ||
                  ""}
                {fileContents?.length > 0 ? (
                  <div
                    className={`${Styles?.deleteIconWrapper}`}
                    onClick={() => deleteDocument()}
                  >
                    {/* <img
                  src={Delete}
                  alt="delete"
                  style={{ width: "25px", height: "25px" }}
                  draggable="false"
                /> */}
                    <i className="fa-solid fa-trash-can" draggable="false"></i>
                  </div>
                ) : (
                  profileForm?.patient_photo?.includes("user_128.png") ===
                  false &&
                  profileForm?.patient_photo === "" &&
                  ""
                )}
              </div>
            </div>
            {/* user_128.png is the default skeleton image sended by backend if no profile pic present */}

            {/* <div
              className={`${Styles?.deleteIconWrapper}`}
              onClick={() => deleteDocument()}
            >
              <img
                src={Delete}
                alt="delete"
                style={{ width: "25px", height: "25px" }}
                draggable="false"
              />
            </div> */}

            <div className={`${Styles?.form_control}`}>
              <div className={`${Styles?.form_inner_control}`}>
                <FormInput<RegistrationFormFields>
                  name="firstName"
                  id="firstName"
                  type={Config?.lbl_Inp_type_text}
                  maxLength={25}
                  placeholder={Config?.myprofile_placeholder_1}
                  size={Config?.lbl_Inp_size_small}
                  register={register}
                  rules={{ required: msg_config?.first_name_required }}
                  errors={errors}
                  value={profileForm?.firstName}
                  onInputChangeHandler={(e: any) => {
                    onInputChangeHandler(e, "firstName");
                    // setProfileForm((prev: any) => {
                    //   return { ...prev, firstName: e.target.value };
                    // });
                  }}
                // onKeyDown={(val: any) =>
                //   onInputKeyPressHandler(val, "firstName")
                // }
                />
              </div>
              <div className={`${Styles?.form_inner_control}`}>
                <FormInput<RegistrationFormFields>
                  name="lastName"
                  id="lastName"
                  maxLength={25}
                  type={Config?.lbl_Inp_type_text}
                  placeholder={Config?.myprofile_placeholder_2}
                  size={Config?.lbl_Inp_size_small}
                  register={register}
                  rules={{ required: msg_config?.last_name_required }}
                  errors={errors}
                  value={profileForm?.lastName}
                  onInputChangeHandler={(e: any) => {
                    onInputChangeHandler(e, "lastName");
                    // setProfileForm((prev: any) => {
                    //   return { ...prev, lastName: e.target.value };
                    // });
                  }}
                // onKeyDown={(val: any) =>
                //   onInputKeyPressHandler(val, "lastName")
                // }
                />
              </div>
            </div>
            {/* Second line */}
            <div className={`${Styles?.form_control}`}>
              <div className={`${Styles?.form_inner_control}`}>
                <PhoneFormInput<RegistrationFormFields>
                  name="phone"
                  placeholder={Config?.myprofile_placeholder_3}
                  disabled={userProfileJson?.usr_signup_type === "phone"}
                  register={register}
                  rules={{ required: msg_config?.phone_required }}
                  errors={errors}
                  phoneInputRef={phoneInputRef}
                  control={control}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </div>

              <div className={`${Styles?.form_inner_control}`}>
                <SelectDropdown
                  name="gender"
                  options={genderList}
                  size="small"
                  placeholder={Config?.myprofile_placeholder_6}
                  loading={loading}
                  control={control}
                  rules={{ required: msg_config?.gender_required }}
                  errors={errors}
                />
              </div>
            </div>

            {/* Third line */}
            <div style={{ marginTop: "0px", marginBottom: "20px" }}>
              <DatePicker
                placeholder={Config?.myprofile_placeholder_4}
                onChange={(val: any) => {
                  // setDobSetKeyDown(true);
                  // onkeydown;
                  if (val instanceof Date) {
                    const selectedDate = new Date(val); // Create a new Date object from the selected date
                    selectedDate.setDate(selectedDate.getDate());
                    setProfileForm((prev: any) => {
                      return { ...prev, dob: selectedDate.toISOString() }; // Convert DOB to ISO string format
                    });
                  }
                }}
                value={profileForm?.dob ? new Date(profileForm.dob) : ""}
                dateFormat={"LL"}
                maxDate={new Date()}
                className={Styles?.customDatepicker}
              />



              {dobErrorMsg ? (
                <FormErrorMessage className={`${Styles?.error_msg}`}>
                  You must select your D.O.B
                </FormErrorMessage>
              ) : (
                ""
              )}
            </div>

            {/* Fourth line */}
            <div style={{ marginTop: "0px", marginBottom: "5px" }}>
              <FormInput<RegistrationFormFields>
                name="email"
                id="email"
                maxLength={50}
                type="text"
                placeholder={Config?.myprofile_placeholder_5}
                size={Config?.lbl_Inp_size_small}
                disabled={userProfileJson?.usr_signup_type !== "phone"}
                register={register}
                rules={{
                  required: msg_config?.email_required,
                  pattern: emailPattern,
                }}
                errors={errors}
                value={profileForm?.email}
                onInputChangeHandler={(e: any) => {
                  onInputChangeHandler(e, "email");
                }}
              />
            </div>

            <div
              className={Styles["button_style"]}
              style={{ marginTop: "40px" }}
            >
              <Button
                className={Styles["edit_profile_cancelbtn"]}
                type={Config?.lbl_Btn_type_ghost}
                size={Config?.lbl_Btn_size_medium}
                onClick={() => {
                  setIsEditModalOpen(false);
                  if (setModalOpen) {
                    setModalOpen(false);
                  }
                  /*setProfileForm({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    dob: "",
                    patient_photo: "",
                  });*/
                }}
                style={{ margin: 0 }}
              >
                {btnConfig?.pharmacy_modal_cancel}
              </Button>
              <Button
                type={Config?.lbl_Btn_type_primary}
                htmlType={Config?.lbl_Btn_action_submit}
                size={Config?.lbl_Btn_size_medium}
                style={{ margin: 0 }}
                loading={loading}
              >
                {Config?.myprofile_upd}
              </Button>
            </div>
          </form>
        </div>
      </div>

      {isImageEditor && (
        <ImageEditor
          imgSrc={fileContents[0]?.uri}
          handleClose={handleClose}
          handleSave={handleImageEdit}
        />
      )}
    </>
  );
};
export default EditProfile;
