import styles from "./index.module.css";

export type LabelProps = {
  for: string;
  style?: object;
  className?: string;
  onClick?:any;
};

const Label: React.FC<LabelProps> = (props) => {
  return (
    <label
      htmlFor={props?.for}
      className={props?.className}
      style={{ ...props?.style }}
      onClick={props?.onClick}
    >
      {props?.children}
    </label>
  );
};

export default Label;
