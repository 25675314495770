import "./SlideDrawer.css";
import { color_config } from "../../../../../../config/colorConfig";

import { PdfDrawer, PngDrawer } from "../../../../../../utils/appIcons";

const SlideDrawer = ({ show, uri, setDrawerOpen, headingName, name }) => {
  let drawerClassessz = "side-drawerssz";
  if (show) {
    drawerClassessz = "side-drawerssz dfsddsd open";
  }

  const getHeaderIcon = () => {
    if (headingName?.includes("pdf")) {
      return (
        <PdfDrawer
          style={{
            color: color_config?.white_color,
            fontSize: "25",
            fontWeight: 200,
            marginTop: "3px",
          }}
          size="20"
        />
      );
    } else {
      return (
        <PngDrawer
          style={{
            color: color_config?.white_color,
            fontSize: "25",
            fontWeight: 200,
            marginTop: "3px",
          }}
          size="20"
        />
      );
    }
  };

  return (
    <div className={drawerClassessz} style={{ width: "50%" }}>
      <div
        // style={{
        //   backgroundColor: color_config?.common_bg_color,
        //   display: "flex",
        //   justifyContent: "space-between",
        //   padding: "20px 20px 20px 20px",
        // }}
        className="model_open_container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {getHeaderIcon()}
          <p
            style={{
              color: color_config?.white_color,
              fontSize: "35",
              marginLeft: "15px",
            }}
          >
            {headingName}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-lg pointer"
          viewBox="0 0 16 16"
          style={{
            color: color_config?.white_color,
            fontSize: "25",
            fontWeight: 200,
            marginTop: "3px",
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
      </div>

      {name?.includes(".pdf") ? (
        <iframe
          title="Document Preview"
          src={uri}
          style={{ height: "100vh", width: "100%", textAlign: "center" }}
          frameBorder="0"
          // allowFullScreen
        />
      ) : (
        <img src={uri} style={{ width: "100%" }} alt={name} draggable="false" />
        
      )}
    </div>
  );
};

export default SlideDrawer;
