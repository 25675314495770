import React, { useState, useContext, useEffect } from "react";
import TabPane from "../../../components/UI/AppTabs/Tabs-pane/tab-pane";
import Tabs from "../../../components/UI/AppTabs/Tabs/tab";
import Styles from "./index.module.css";
import Config from "../../../config/label";
import Slider from "react-slick";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ChevronLeft, ChevronRight } from "../../../utils/appIcons";
import RelatedProductCards from "../../../components/RelatedProductCards";
import { objDecrypt, getDecryptedID, JSONCheckerFunc, getID, setEncryptedID } from "../../../utils/utils";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { config } from "../../../config/processId.js";
import AddtocartPopup from "../../../components/ProductDetails/AddtocartPopup";
import { siteConfig } from "../../../config/site";
import { color_config } from "../../../config/colorConfig";
import ProductCard from "../../../components/ProductCard";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";

const OurProducts = ({ setIsLoginPopup, setIsAlert, widget }: any) => {
  const [activeTab, setActiveTab] = useState("Recommended");
  const [recommendedList, setRecommendeds] = useState<any>([]);
  const [latestList, setLatests] = useState<any>([]);
  const [productdetails, setProductDetails] = useState<any>("");
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [loading, setLoading] = useState({
    isTrue: false,
    productId: "",
  });

  const [suggestUsInit] = useContext(APIContext);
  const gaEventTracker = useAnalyticsEventTracker(Config?.lbl_land_page_etrck);

  const localStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };
  let isUserLoggedIn =localStorageCopy&&  Object.keys(localStorageCopy)?.length > 0 ? true : false;

  const getRecommendedProducts = () => {
    CallAPI(config?.process_specific_product_list_id, {
      p_cus_id: localStorageCopy?.customer_id,
      p_max_offset: "12",
      p_process_flag: "RECOMM",
    }).then((res) => {
      if (res?.returnCode) {
        setRecommendeds(res?.returnData);
      }
    });
  };

  const getLatestProducts = () => {
    CallAPI(config?.process_specific_product_list_id, {
      p_cus_id: localStorageCopy?.customer_id,
      p_max_offset: "12",
      p_process_flag: "LATST",
    }).then((res) => {
      if (res?.returnCode) {
        setLatests(res?.returnData);
      }
    });
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      latestList?.length === 0 && getLatestProducts();

      recommendedList?.length === 0 && getRecommendedProducts();
    }
  }, [suggestUsInit]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${Styles?.next_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${Styles?.prev_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        // p_cus_id: localStorageCopy?.customer_id,
        p_wshlst_id: product?.wshlst_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker(Config?.lbl_land_page_etrck_wishlist_removed);
          // getRecommendedProducts();
          // getLatestProducts();
          // setLatests(
          //   items: productList?.items?.map((item: any) => {
          //     if (item?.srv_id !== product?.srv_id) {
          //       return { ...item };
          //     } else {
          //       return { ...item, wshlst_status: "N" };
          //     }
          //   }),
          // );
          setRecommendeds(
            recommendedList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );

          setLatests(
            latestList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );
        }
      });
    }
  };
  const wishListAddHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id:decryptData(getID(SG_USER_ID)),
        p_cus_id:decryptData(getID(SG_PATIENT_ID)),
        p_srv_id: product?.srv_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker(Config?.lbl_land_page_etrck_wishlist_added);
          // getRecommendedProducts();
          // getLatestProducts();

          setRecommendeds(
            recommendedList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );

          setLatests(
            latestList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );
        }
      });
    }
  };

  const likeButtonHandler = (product: any) => {
    switch (product?.wshlst_status) {
      case "Y":
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
  };

  const addCartHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      setLoading({
        isTrue: true,
        productId: product?.srv_id,
      });
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id:decryptData( getID(SG_USER_ID)),
        p_patient_id:  decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker(
            Config?.lbl_land_page_etrck_cart,
            res?.returnData[0].cart_item_count
          );
          setEncryptedID(CART_COUNT,res?.returnData[0].cart_item_count);
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          setLoading({
            isTrue: false,
            productId: "",
          });
          setIsAlert({
            istrue: true,
            type: "success",
            msg:res?.msg,
          });
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
          setLoading({
            isTrue: false,
            productId: "",
          });
        }
      });
    }
  };

  return (
    <div
      className={Styles["bodyOurProducts"]}
      style={{ order: `${widget?.sequence}` }}
    >
      <div className="outer_container">
        <div className="our_product_main">
          <h1>{Config?.header_op_title}</h1>
          <div className={Styles["ourproductsTabs"]}>
            <Tabs
              active={activeTab}
              setActive={setActiveTab}
              ulStyle={{
                width: "100%",
                justifyContent: "start",
              }}
              bottomBorderStyle={{
                width: "100%",
                height: "1px",
                opacity: 1,
                backgroundColor: "#E1E3E5",
                marginTop: "-12px",
                marginLeft: "8px",
              }}
              anchorClassName={Styles["tableTabs"]}
            >
              <TabPane name="Recommended" key="Recommended">
                <div className={Styles["productList"]}>
                  <Slider {...settings}>
                    {recommendedList.length === 0
                      ? [1, 2, 3, 4]?.map((items: any, idx: any) => {
                        return (
                          <div className={`${Styles?.shim_wrap}`} key={idx}>
                            <ShimmerThumbnail height={250} rounded />
                          </div>
                        );
                      })
                      : Array?.isArray(recommendedList) &&
                      recommendedList?.map((product: any, idx: any) => (
                        <ProductCard
                          pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                          product={product}
                          productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                          key={idx}
                          type={product?.service_type}
                          productUrl={product?.srv_id}
                          imgUrl={product?.srv_image.split("~")[0]}
                          imgAlt={product?.srv_name}
                          detail={product?.srv_name}
                          packagingId={product?.srv_packaging}
                          brand={product?.srv_brand}
                          price={product?.discounted_amount}
                          actualPrice={product?.srv_price}
                          discountPercent={product?.discount_percent}
                          coPay={product?.copayment_amount}
                          loading={loading}
                          ratingCount={
                            product?.rating_view_flag === "Y" &&
                            product?.rating_star_count
                          }
                          isWishListAdded={
                            product?.wshlst_status === "Y" ? true : false
                          }
                          ribbon={true}
                          ribbonType="folded"
                          onLikeButtonClick={() => {
                            isUserLoggedIn
                              ? likeButtonHandler(product)
                              : setIsLoginPopup(true);
                          }}
                          onAddtoCartButtonClick={() => {
                            isUserLoggedIn
                              ? addCartHandler(product)
                              : setIsLoginPopup(true);
                          }}
                        />
                      ))}
                  </Slider>
                </div>
              </TabPane>
              <TabPane name="Latest" key="Latest">
                <div className={Styles["productList"]}>
                  <Slider {...settings}>
                    {latestList?.map((product: any, idx: any) => (
                      <ProductCard
                          pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                          productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                        product={product}
                        key={idx}
                        type={product?.service_type}
                        productUrl={product?.srv_id}
                        imgUrl={product?.srv_image.split("~")[0]}
                        imgAlt={product?.srv_name}
                        detail={product?.srv_name}
                        packagingId={product?.srv_packaging}
                        brand={product?.srv_brand}
                        price={product?.discounted_amount}
                        actualPrice={product?.srv_price}
                        discountPercent={product?.discount_percent}
                        coPay={product?.copayment_amount}
                        loading={loading}
                        ratingCount={
                          product?.rating_view_flag === "Y" &&
                          product?.rating_star_count
                        }
                        isWishListAdded={
                          product?.wshlst_status === "Y" ? true : false
                        }
                        ribbon={true}
                        ribbonType="folded"
                        onLikeButtonClick={() => {
                          isUserLoggedIn
                            ? likeButtonHandler(product)
                            : setIsLoginPopup(true);
                        }}
                        onAddtoCartButtonClick={() => {
                          isUserLoggedIn
                            ? addCartHandler(product)
                            : setIsLoginPopup(true);
                        }}
                      />
                    ))}
                  </Slider>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>

        {/* Added to cart pop up */}
        {isCartPopUpOpen && (
          <AddtocartPopup
            img={productdetails?.srv_image.split("~")[0]}
            title={productdetails?.srv_name + productdetails?.srv_packaging}
            setModalOpen={setIsCartPopUPOpen}
          />
        )}
      </div>
    </div>
  );
};

export default OurProducts;
