import React, { useEffect, useState } from "react";
import en from "react-phone-number-input/locale/en.json";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styles from "./index.module.css";

export type PhoneInputProps = {
  name?: string;
  id?: string;
  readOnly?: boolean;
  placeholder: string;
  className?: string;
  phoneNumber?: any;
  onChange?: any;
  rules?: any;
  control?: any;
  defaultValue?: any;
  defaultCountry?: any;
  disabled?: any;
  onKeyPress?: any;
  autoFocus?: boolean;
};

const PhoneNumber: React.FC<PhoneInputProps> = (props) => {
  const {
    placeholder,
    className,
    phoneNumber,
    onChange,
    rules,
    control,
    name,
    defaultValue,
    readOnly,
    defaultCountry = "IN",
    disabled,
    onKeyPress,
    autoFocus,
  } = props;

  const whiteListedCountries = ["IN", "US", "CA"];

  return (
    // {/* Read me file https://gitlab.com/catamphetamine/react-phone-number-input#readme */}

    <PhoneInput
      international
      labels={en}
      name={name}
      country={whiteListedCountries}
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      value={phoneNumber}
      onChange={onChange}
      className={`${styles.inputStyle} ${className}`}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      readOnly={readOnly}
      disabled={disabled}
      onKeyPress={onKeyPress}
      autoFocus={autoFocus}
    />
  );
};
export default PhoneNumber;
