import React, { useState, useContext, useEffect } from "react";
import style from "./index.module.css";
import { H2, H3 } from "../../../components/UI/Typography";
import Config from "../../../config/label";
import FlexBox from "../../../components/UI/FlexBox";
import BillingDetails from "../BillingDetails";
import Button from "../../../components/UI/Button";
import btnConfig from "../../../config/button";
import { Link } from "react-router-dom";
import LinkButton from "../../../components/UI/LinkButton";
import PurchaseConfirm from "./PurchaseConfirm";
import Card from "../../../components/UI/Card";
import APIContext from "../../../store/api-context";
import { config } from "../../../config/processId.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { useNavigate } from "react-router-dom";
import AddressPopup from "./AddressPopup";
import OrderCompletion from "../../../components/OrderCompletion";
import Alert from "../../../components/UI/Alert";
import Selectaddress_icon from "../../../assets/icons/Selectaddress_icon.svg";
import AddPaymentsCard from "./AddPaymentsCard";
import SaveCardModal from "./SaveCardModal";
import { ShimmerTitle, ShimmerText } from "react-shimmer-effects";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import IconButton from "../../../components/UI/IconButton";
import ProfilePopup from "../../Landingpage/ProfilePopup";
import { siteConfig } from "../../../config/site";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt, setEncryptedID } from "../../../utils/utils";
import selectedAddressImg from "../../../assets/sf_svg/Radio active.svg";
import notSelectedAddressImg from "../../../assets/sf_svg/Radio.svg";
import { UserAuthContext } from "../../../store/UserAuthContext";
import Slider from "react-slick";
import AddNewAddress from "../../MyAddress/AddNewAddress"
import { CART_COUNT, NO_ADDRESS_IMAGE, ORG_ID, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
import AddressV2 from "../../../components/AddressV2";
import AddressList from "../../../components/AddressV2/AddressList";
// import Alert from "../../../../components/UI/Alert";

export type checkoutProps = {
  products?: any;
  setActiveStep?: any;
};

const Checkout: React.FC<checkoutProps> = (props) => {
  const { setActiveStep } = props;
  const [suggestUsInit] = useContext(APIContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [address, setAddress] = useState<any>("");
  const [permaSelect, setPermaSetSelect] = useState<any>({
    delivery: {},
    billing: {},
  });
  const [alert2, setAlert2] = useState({
    isTrue: false,
    msg: "",
    type: "",
  })
  const [sAllUserAddress, setAllUserAddress] = useState({
    deliveryAddress: [],
    billingAddress: [],
  });
  const [sSelectedAddress, setSelectedAddresses] = useState({
    deliveryAddress: {},
    billingAddress: {},
  });
  const[SelectedAddress,setSelectedAddress]=useState({})
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [cartTotal, setCartTotal] = useState<any>("");
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [isAddressPopup, setIsAddressPopup] = useState(false);
  const [isAddAddressPopup, setIsAddAddressPopup] = useState({
    isTrue: false,
    addressType: "",
    addressData: {},
  });
  const [isOrderCompletion, setIsOrderCompletion] = useState(false);
  const [addressID, setAddressID] = useState("");
  const [paymentID, setPaymentID] = useState<any>("");
  const [isLoading, setLoading] = useState(true);
  const [alert, setIsPaymentAlert] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [cardItems, setCardItems] = useState({});
  const [isSaveCardModal, setIsSaveCardModal] = useState(false);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [isBasicDetails, setIsBasicDetails] = useState({
    isSelected: false,
    type: "",
    msg: "",
  });
  const [showAddressList, setShowAddressList] = useState({
    isTrue: false,
    selectedAddress: {},
    addressType: "",
  })
  const [isAddPaymentCardModalOpen, setIsAddPaymentCardModalOpen] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const navigate = useNavigate();

  const gaEventTracker = useAnalyticsEventTracker("Product list page");

  const localStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };

  const getDefaultAddress = () => {
    CallAPI(config?.get_default_address_process_id, {
      p_patient_id: decryptData(getID(SG_PATIENT_ID)),
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData.length === 0) {
          setIsAddressEmpty(true);
        } else {
          // setAddress(res?.returnData[0]);
          setIsAddressEmpty(false);
          setAddressID(res?.returnData?.[0]?.patadddet_id);
        }
      }
    });
  };


  useEffect(() => {
    try {
      //@ts-ignore
      setIsAddressAvailable(sSelectedAddress?.deliveryAddress?.p_patadd_id ? true : false);
      //@ts-ignore
      setIsSameAddress(sSelectedAddress?.billingAddress?.p_patadd_id === sSelectedAddress?.deliveryAddress?.p_patadd_id);
    } catch (error) {
      console.error(error);
    }
  }, [sSelectedAddress])



  useEffect(() => {
    window.scrollTo(0, 0);
    // @ts-ignore
    // document.body.style.zoom = "90%";
    if (suggestUsInit?.returnCode === true) {
      getDefaultAddress();
      getAddress();
      CallAPI(config?.get_cart_total_process_id, {
        p_user_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          setCartTotal(res?.returnData[0]);
          //setting default billing & delivery address
          // setSelectedAddresses({
          //   billingAddress: res?.returnData[0]?.billing_address_id,
          //   deliveryAddress: res?.returnData[0]?.shipping_address_id,
          // });
        }
      });
      CallAPI(config?.get_default_payment_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setPaymentList(res?.returnData);

          //Auto populate payments option as square
          // if (res?.returnData?.length > 1) {
          //   let findIsSquare = res?.returnData?.find(
          //     (item: any) => item?.pm_code === "SQUARE"
          //   )?.pm_code;

          //   if (findIsSquare === "SQUARE") {
          //     setPaymentID(findIsSquare);
          //   }
          // }

          //Auto populate that payment option when there is only one option present
          if (res?.returnData?.length === 1) {
            setPaymentID(res?.returnData[0]?.pm_code);
          }
        }
        setLoading(false);
      });
    }
  }, [suggestUsInit]);

  function formatPhoneNumber(num: string) {
    var cleaned = ("" + num).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const handleAddressSelect = (address: any) => {
    if (address !== "") {
      // setAddress(address);
      setIsAddressEmpty(false);
      // setAddressID(address?.p_patadd_id);
    }
  };

  const getAddress = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.patient_address_list_process_id, {
        p_pnt_id: decryptData(getID(SG_PATIENT_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          setAddresses(res?.returnData);
        } else {
          console.error(res?.msg);
        }
      });
    }
  };
  useEffect(() => {
    try {
      let defaultAddress = {};
      if (addresses.length > 0) {
        if (cartTotal?.billing_address_id === null || cartTotal?.shipping_address_id === null ||
          cartTotal?.billing_address_id === "" || cartTotal?.shipping_address_id === ""
        ) {
          addresses.map((items: any) => {
            if (items?.default_address_flag === "Y") {
              defaultAddress = items;
            }
          })
          if (JSON.stringify(defaultAddress) === "{}") {
            defaultAddress = addresses[0];
          }
          setSelectedAddresses((prev) => {
            return { ...prev, deliveryAddress: defaultAddress, billingAddress: defaultAddress }
          })
        } else {
          addresses.map((items: any, idx: any) => {
            if (cartTotal?.shipping_address_id === items?.p_patadd_id) {
              setSelectedAddresses((prev) => {
                return { ...prev, deliveryAddress: items }
              })
            } else {
              if (idx === 0) {
                setSelectedAddresses((prev) => {
                  return { ...prev, deliveryAddress: items }
                })
              }
            }
            if (cartTotal?.billing_address_id === items?.p_patadd_id) {
              setSelectedAddresses((prev) => {
                return { ...prev, billingAddress: items }
              })
            } else {
              if (idx === 0) {
                setSelectedAddresses((prev) => {
                  return { ...prev, billingAddress: items }
                })
              }
            }
          })
        }
      } else {
        setSelectedAddresses((prev) => {
          return { ...prev, billingAddress: {}, deliveryAddress: {} }
        })
      }

    } catch (error) {
      console.error(error);
    }
  }, [addresses, cartTotal])

  // useEffect(() => {
  //   //TODO BY HARRY
  //   try {
  //     if (addresses.length > 0 && sSelectedAddress) {
  //       const updatedWithIsSelectedForDelivery = addresses.map((items: any) => {
  //         return {
  //           ...items,
  //           isSelected:
  //             sSelectedAddress?.deliveryAddress === items?.patadddet_id,
  //         };
  //       });
  //       const updatedWithIsSelectedForBilling = addresses.map((items: any) => {
  //         return {
  //           ...items,
  //           isSelected:
  //             sSelectedAddress?.billingAddress === items?.patadddet_id,
  //         };
  //       });
  //       setAllUserAddress((prev: any) => {
  //         let index = updatedWithIsSelectedForDelivery.findIndex(
  //           (item) => item.isSelected === true
  //         );
  //         if (index !== -1) {
  //           const removedElement = updatedWithIsSelectedForDelivery.splice(
  //             index,
  //             1
  //           )[0]; // Remove the element from its current position
  //           updatedWithIsSelectedForDelivery.unshift(removedElement); // Add the element to the start of the array
  //         }
  //         index = updatedWithIsSelectedForBilling.findIndex(
  //           (item) => item.isSelected === true
  //         );
  //         if (index !== -1) {
  //           const removedElement = updatedWithIsSelectedForBilling.splice(
  //             index,
  //             1
  //           )[0]; // Remove the element from its current position
  //           updatedWithIsSelectedForBilling.unshift(removedElement); // Add the element to the start of the array
  //         }
  //         return {
  //           ...prev,
  //           deliveryAddress: updatedWithIsSelectedForDelivery,
  //           billingAddress: updatedWithIsSelectedForBilling,
  //         };
  //       });
  //     } else {
  //       setAllUserAddress((prev) => {
  //         return {
  //           ...prev, deliveryAddress: [],
  //           billingAddress: []
  //         }
  //       })
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [sSelectedAddress, addresses]);

  // useEffect(()=>{
  //   try {
  //     if(cartTotal?.billing_address_id ===undefined || cartTotal?.billing_address_id ===null ){
  //       let billingAddressId = sAllUserAddress?.billingAddress[0];
  //       setSelectedAddresses((prev:any)=>{
  //         // @ts-ignore
  //           return {...prev, billingAddress :billingAddressId?.p_patadd_id }
  //         })
  //       }
  //       if(cartTotal?.shipping_address_id ===undefined || cartTotal?.shipping_address_id ===null ){
  //         setSelectedAddresses((prev:any)=>{
  //         // @ts-ignore
  //           return {...prev, deliveryAddress :sAllUserAddress?.deliveryAddress[0].p_patadd_id }
  //         })
  //       }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },[cartTotal, sAllUserAddress])

  const handleInputChange = (code: any) => {
    setPaymentID(code);
  };

  const handlePayment = (
    gatewayType: any,
    nonce: any,
    noncetype: any,
    tokenNonce: any
  ) => {
    setIsButtonLoading(true);
    // setIsAddPaymentCardModalOpen(false);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.save_address_payment_process_id, {
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_user_id: decryptData(getID(SG_USER_ID)),
        // p_address_id: addressID,
        p_payment_method: paymentID,
        p_billing_address_id: sSelectedAddress?.billingAddress,
        p_shipping_address_id: sSelectedAddress?.deliveryAddress,
      }).then((res) => {
        const getGatewayType = () => {
          switch (gatewayType) {
            case "square_up":
              return "square_up";

            default:
              return " ";
          }
        };

        const getNonce = () => {
          switch (nonce) {
            case "cardId":
              return cardItems;
            case "nonce":
              return "nonce";
            case "tokenNonce":
              return tokenNonce;

            case "form nonce":
              return tokenNonce;

            default:
              return " ";
          }
        };

        const getNonceType = () => {
          switch (noncetype) {
            case "cardSelected":
              return "card";

            case "nonce":
              return "nonce";

            case "card":
              return "card";

            default:
              return " ";
          }
        };
        if (res?.returnCode) {
          CallAPI(config?.place_order_process_id, {
            p_patient_id: decryptData(getID(SG_PATIENT_ID)),
            // case of sqare payment else blank
            p_gateway_type: getGatewayType(),
            p_card_nonce: getNonce(),
            p_card_nonce_type: getNonceType(),
          }).then((res) => {
            setIsButtonLoading(false);
            if (res?.returnCode) {
              setIsAddPaymentCardModalOpen(false);
              setEncryptedID(CART_COUNT, "0");
              setOrderNumber(res?.returnData[0]?.order_number);
              setIsOrderCompletion(true);
              setActiveStep(4);
              if (tokenNonce === "") {
                gaEventTracker("Order placed with COD");
              }
              if (noncetype === "cardSelected") {
                gaEventTracker("Order placed by square payment");
              }
            } else {
              setIsBasicDetails({
                isSelected: true,
                type: "error",
                msg: res?.msg,
              });
              setIsAddPaymentCardModalOpen(false);
            }
          });
        }
      });
    }
  };

  const checkBtnConfig = () => {
    if (
      sSelectedAddress?.deliveryAddress === "" ||
      sSelectedAddress?.billingAddress === "" ||
      sSelectedAddress?.deliveryAddress === "0" ||
      sSelectedAddress?.billingAddress === "0" ||
      sSelectedAddress?.deliveryAddress === null ||
      sSelectedAddress?.billingAddress === null ||
      JSON.stringify(sSelectedAddress?.billingAddress) === "{}" ||
      JSON.stringify(sSelectedAddress?.deliveryAddress) === "{}"
    ) {
      setIsBasicDetails({
        isSelected: true,
        type: "error",
        msg: "Please select your  delivery & billing address.",
      });
    }
    // else if (address === "") {
    //   setIsBasicDetails({
    //     isSelected: true,
    //     type: "error",
    //     msg: "Please add your address",
    //   });
    // }
    else {
      handleSaveBothAddreses();
      navigate("/summary");
      // if (paymentID === "") {
      //   handleSaveBothAddreses();
      //   navigate("/summary");
      //   // if (context?.UserAuth?.usr_type === "pro") {
      //   // } else {
      //   //   setIsBasicDetails({
      //   //     isSelected: true,
      //   //     type: "error",
      //   //     msg: "Please select mode of payment",
      //   //   });
      //   // }
      // } else if (paymentID === "COD") {
      //   let gatewayType = " ";
      //   let nonce = " ";
      //   let noncetype = " ";
      //   //function to update both addresses in process id
      //   // handlePayment(gatewayType, nonce, noncetype, "");
      // } else {
      //   // handleSaveBothAddreses();
      //   // setIsPaymentAlert(false);
      //   // if (context?.UserAuth?.usr_type === "pro") navigate("/summary");
      //   // setIsAddPaymentCardModalOpen(true);
    }
  };

  const handleSaveBothAddreses = () => {
    try {
      CallAPI(config?.xcelecomconfig_update_trn_cart_address_id, {
        p_org_id: getDecryptedID(ORG_ID),
        p_user_id: decryptData(getID(SG_USER_ID)),
        //@ts-ignore
        p_billing_address_id: sSelectedAddress?.billingAddress?.p_patadd_id,
        //@ts-ignore
        p_shipping_address_id: sSelectedAddress?.deliveryAddress?.p_patadd_id,
        //  p_internal_flag : ""
      }).then((res) => {
        if (res?.returnCode) {
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isBasicDetails?.isSelected === true) {
      setTimeout(
        () =>
          setIsBasicDetails({
            isSelected: false,
            type: "",
            msg: "",
          }),
        3000
      );
    }
  }, [isBasicDetails]);

  const handleSelectDeliveryAddress = (selectedAddress: any) => {
    try {
      let updateSelectedAddress = sAllUserAddress?.deliveryAddress?.map(
        (items: any) => {
          if (selectedAddress?.patadddet_id === items?.patadddet_id) {
            return { ...items, isSelected: true };
          }
          return { ...items, isSelected: false };
        }
      );
      setAllUserAddress((prev: any) => {
        return { ...prev, deliveryAddress: updateSelectedAddress };
      });
      setSelectedAddresses((prev: any) => {
        return { ...prev, deliveryAddress: selectedAddress?.patadddet_id };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectBillingAddress = (selectedAddress: any) => {
    try {
      let updateSelectedAddress = sAllUserAddress?.billingAddress?.map(
        (items: any) => {
          if (selectedAddress?.patadddet_id === items?.patadddet_id) {
            return { ...items, isSelected: true };
          }
          return { ...items, isSelected: false };
        }
      );
      setAllUserAddress((prev: any) => {
        return { ...prev, billingAddress: updateSelectedAddress };
      });
      setSelectedAddresses((prev: any) => {
        return { ...prev, billingAddress: selectedAddress?.patadddet_id };
      });
    } catch (error) {
      console.error(error);
    }
  };

  //configuration for slider
  const thumb_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    padding: 20,
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const handleOpenAddressManage = (addressType: any, addressData: any) => {
    try {
      setIsAddAddressPopup({
        isTrue: true,
        addressType: addressType,
        addressData: addressData,
      });

    } catch (error) {
      console.error(error);
    }
  }

  const handleSelectSameAsDeliveryAddress = (e: any) => {
    try {
      if (e.target.checked) {
        setSelectedAddresses((prev: any) => {
          return { ...prev, billingAddress: prev?.deliveryAddress }
        })
      } else {
        setIsSameAddress(false);
        // setSelectedAddresses((prev:any)=>{
        //   return {...prev, billingAddress : {} }
        // })
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="outer_container">
      <div className={style.pageHading}>
        <h2 className={`${style?.pageTitle}`}>{Config?.checkoutPage_Title}</h2>
      </div>

      {/* <H3 className={`${style?.pageTitle2}`}>{Config?.checkoutPage_Title_2}</H3> */}

      <FlexBox className={`${style?.innerContainer}`}>
        <div className={`${style?.leftContainer}`}>
          <H3 className={`${style?.addressTitle}`}>
            {Config?.lbl_deliveryAddress}
            {/* {sSelectedAddress?.deliveryAddress === null || JSON.stringify(sSelectedAddress?.deliveryAddress) === "{}" ? "" :  */}
            {false && <span
              className={style?.editIcons}
              onClick={() => {
                // handleOpenAddressManage('DELIVERY');
              }}> <i className="fa fa-pencil-square-o" aria-hidden="true"></i></span>}
            {/* } */}
          </H3>
          <Card className="mb-7" style={{ borderRadius: "8px" }}>
            {showAddressList?.isTrue && showAddressList?.addressType === "DELIVERY" &&
              <AddressList
                address={addresses}
                setShowAddressList={setShowAddressList}
                handleOpenAddressManage={handleOpenAddressManage}
                selectedAddress={sSelectedAddress}
                showAddressList={showAddressList}
                setSelectedAddresses={setSelectedAddresses}
              />}
            <div className=" items-center justify-between">
              <div className={`${style?.listOfAddresses} manage_address_slider`}>
                {isLoading ? (
                  <ShimmerTitle line={2} gap={10} variant="primary" />
                ) : (
                  <>

                    {//@ts-ignore
                      sSelectedAddress?.deliveryAddress === undefined || sSelectedAddress?.deliveryAddress === null || JSON.stringify(sSelectedAddress?.deliveryAddress) === "{}" ?
                        <div className={`${style?.noAddressFound}`}>
                          <img src={NO_ADDRESS_IMAGE} alt={Config?.myaddress_no_address} />
                          <p>{Config?.myaddress_no_address}</p>
                        </div> :

                        <div
                          className={`${style["userAddress"]}`}
                          onClick={() => {
                            // handleSelectDeliveryAddress(items);
                          }}
                        >
                          <div className={`${style?.user_address_wraper}`}>
                            <div className={`${style?.selectionDiv}`}>
                              {
                                //@ts-ignore
                                true ? (
                                  <img src={selectedAddressImg} />
                                ) : (
                                  <img src={notSelectedAddressImg} />
                                )}
                            </div>
                            <div className={`${style?.detailsPannel}`}>
                              <h1>
                                {
                                  //@ts-ignore
                                  sSelectedAddress?.deliveryAddress?.patadddet_pnt_name +
                                  " " +

                                  //@ts-ignore
                                  sSelectedAddress?.deliveryAddress?.patadddet_pnt_last_name}
                              </h1>
                              {/* Name */}
                              <p> {
                                //@ts-ignore
                                sSelectedAddress?.deliveryAddress?.p_address}</p>
                              {/* Address line 1 */}
                              <p>
                                {
                                  //@ts-ignore
                                  sSelectedAddress?.deliveryAddress?.city_desc + ", " + sSelectedAddress?.deliveryAddress?.state_desc}
                              </p>
                              {/* City, Province*/}
                              <p>

                                {//@ts-ignore
                                  sSelectedAddress?.deliveryAddress?.coutry_desc + ", " + sSelectedAddress?.deliveryAddress?.p_pcode}
                              </p>
                              {/* Country, PO box*/}
                              <p>
                                {formatPhoneNumber(//@ts-ignore
                                  sSelectedAddress?.deliveryAddress?.patadddet_pnt_phone
                                )}
                              </p>
                              {/* Phone no*/}
                              {/* Default Address badge */}

                              {
                                //@ts-ignore
                                addressID
                                  //@ts-ignore
                                  ? addressID === sSelectedAddress?.deliveryAddress?.patadddet_id && (
                                    <p className={`${style?.defaultBadge}`}>
                                      Default
                                    </p>
                                  )
                                  : ""}
                            </div>
                          </div>
                          <div className={style?.actions}>
                            <Button
                              type={Config?.lbl_Btn_type_ghost}
                              size={Config?.lbl_Btn_size_medium}
                              htmlType={"button"}
                              className={style?.actionButton}
                              onClick={() => {
                                setShowAddressList((prev: any) => {
                                  return { ...prev, isTrue: true, addressType: "DELIVERY" }
                                })
                              }}
                            >
                              {Config?.lbl_change}
                            </Button>
                          </div>
                        </div>}

                    {/* {sAllUserAddress?.deliveryAddress.length > 0 ?
                      sAllUserAddress?.deliveryAddress.map(
                        (items: any, index: number) => {
                          return (
                            <></>
                          );
                        }
                      )
                      :
                      <div className={`${style?.noAddressFound}`}>
                        {Config?.myaddress_no_address}
                      </div>} */}
                  </>
                )}
              </div>
              {/* <IconButton
                title="Select address"
                className={`${style?.linkButton}`}
                onClick={() => {
                  setIsAddressPopup(true);
                }}
              >
                <img
                  src={Selectaddress_icon}
                  alt="Select Address"
                  draggable="false"
                />
                {btnConfig?.checkout_Address_Button}
              </IconButton> */}
            </div>
          </Card>
          <div className={style?.billingHeaderWrapper}>
            <H3 className={`${style?.addressTitle}`}>
              {Config?.lbl_billingAddress}

              {/* { sSelectedAddress?.billingAddress === null || JSON.stringify(sSelectedAddress?.billingAddress) === "{}"? "" :  */}
              {false && <span
                className={style?.editIcons}
                onClick={() => {
                  // handleOpenAddressManage('BILLING');
                }}
              > <i className="fa fa-pencil-square-o" aria-hidden="true"></i></span>}
              {/* } */}
            </H3>
            {isAddressAvailable &&
              <label className={style?.billingActionWrapper}>
                <input
                  type="checkbox"
                  checked={isSameAddress}
                  onChange={(e: any) => {
                    handleSelectSameAsDeliveryAddress(e)
                  }}
                />
                <H3 className={`${style?.addressTitleSide}`} >{Config?.lbl_same_as_delivery}</H3>
              </label>}
          </div>
          <Card className="mb-4" style={{ borderRadius: "8px" }}>
            {showAddressList?.isTrue && showAddressList?.addressType === "BILLING" &&
              <AddressList
                address={addresses}
                setShowAddressList={setShowAddressList}
                handleOpenAddressManage={handleOpenAddressManage}
                selectedAddress={sSelectedAddress}
                showAddressList={showAddressList}
                setSelectedAddresses={setSelectedAddresses}
              />}
            <div className="flex items-center justify-between">


              {/* <IconButton
                title="Select address"
                className={`${style?.linkButton}`}
                onClick={() => {
                  setIsAddressPopup(true);
                }}
              >
                <img
                  src={Selectaddress_icon}
                  alt="Select Address"
                  draggable="false"
                />
                {btnConfig?.checkout_Address_Button}
              </IconButton> */}
            </div>
            <div className={`${style?.listOfAddresses} manage_address_slider`}>
              {isLoading ? (
                <ShimmerTitle line={2} gap={10} variant="primary" />
              ) : (
                <>
                  {//@ts-ignore
                    sSelectedAddress?.billingAddress === undefined || sSelectedAddress?.billingAddress === null || JSON.stringify(sSelectedAddress?.billingAddress) === "{}" ?
                      <div className={`${style?.noAddressFound}`}>
                        <img src={NO_ADDRESS_IMAGE} alt={Config?.myaddress_no_address} />
                        <p>{Config?.myaddress_no_address}</p>
                      </div> :

                      <div
                        className={`${style["userAddress"]}`}
                        onClick={() => {
                          // handleSelectbillingAddress(items);
                        }}
                      >
                        <div className={`${style?.user_address_wraper}`}>
                          <div className={`${style?.selectionDiv}`}>
                            {
                              //@ts-ignore
                              true ? (
                                <img src={selectedAddressImg} />
                              ) : (
                                <img src={notSelectedAddressImg} />
                              )}
                          </div>
                          <div className={`${style?.detailsPannel}`}>
                            <h1>
                              {
                                //@ts-ignore
                                sSelectedAddress?.billingAddress?.patadddet_pnt_name +
                                " " +

                                //@ts-ignore
                                sSelectedAddress?.billingAddress?.patadddet_pnt_last_name}
                            </h1>
                            {/* Name */}
                            <p> {
                              //@ts-ignore
                              sSelectedAddress?.billingAddress?.p_address}</p>
                            {/* Address line 1 */}
                            <p>
                              {
                                //@ts-ignore
                                sSelectedAddress?.billingAddress?.city_desc + ", " + sSelectedAddress?.billingAddress?.state_desc}
                            </p>
                            {/* City, Province*/}
                            <p>

                              {//@ts-ignore
                                sSelectedAddress?.billingAddress?.coutry_desc + ", " + sSelectedAddress?.billingAddress?.p_pcode}
                            </p>
                            {/* Country, PO box*/}
                            <p>
                              {formatPhoneNumber(//@ts-ignore
                                sSelectedAddress?.billingAddress?.patadddet_pnt_phone
                              )}
                            </p>
                            {/* Phone no*/}
                            {/* Default Address badge */}

                            {
                              //@ts-ignore
                              addressID
                                //@ts-ignore
                                ? addressID === sSelectedAddress?.billingAddress?.patadddet_id && (
                                  <p className={`${style?.defaultBadge}`}>
                                    Default
                                  </p>
                                )
                                : ""}
                          </div>
                        </div>
                        <div className={style?.actions}>
                          <Button
                            type={Config?.lbl_Btn_type_ghost}
                            size={Config?.lbl_Btn_size_medium}
                            htmlType={"button"}
                            className={style?.actionButton}
                            onClick={() => {
                              setShowAddressList((prev: any) => {
                                return { ...prev, isTrue: true, addressType: "BILLING" }
                              })
                            }}
                          >
                            {Config?.lbl_change}
                          </Button>
                        </div>
                      </div>}


                </>
              )}
            </div>
          </Card>
          {<div className={`${style?.manage_address_label} ${!showAddressList?.isTrue ? "" : style?.active_add_new_address}`}>
            <p
              onClick={() => {
                handleOpenAddressManage("NEW", {});
              }}
            >
              {Config?.lbl_manageAddress}
            </p>
          </div>}
          {/* <Card className="mb-4" style={{ borderRadius: "8px" }}>
            {isLoading ? (
              <ShimmerTitle line={3} gap={10} variant="primary" />
            ) : (
              <PurchaseConfirm
                methods={paymentList}
                onSelect={handleInputChange}
                paymentID={paymentID}
              />
            )}
          </Card> */}
        </div>
        <FlexBox
          className={`${style?.rightContainer}`}
          style={{ flexDirection: "column" }}
        >
          {/* Billing Details */}
          <Card className="mb-4" style={{ borderRadius: "8px" }}>
            {isLoading ? (
              <ShimmerText line={6} gap={10} />
            ) : (
              <BillingDetails
                subTotal={cartTotal?.cart_subtotal}
                discount={cartTotal?.cart_discount}
                tax={cartTotal?.cart_tax}
                shipping={cartTotal?.cart_shipping}
                netTotal={cartTotal?.cart_net_total}
                total={cartTotal?.cart_net_payable}
                delivery_charges={cartTotal?.delivery_charges}
              />
            )}
          </Card>
          <div className={`${style?.shippButtons}`}>
            <div className="">
              <div>
                <Button
                  loading={paymentID === "COD" && isButtonLoading}
                  onClick={() => {
                    // navigate('/')
                    if (context?.UserAuth?.usr_type !== "pro") {
                      if (siteConfig?.check_Profile === "Y") {
                        if (
                          localStorageCopy?.usr_email === "" ||
                          localStorageCopy?.usr_name === "" ||
                          localStorageCopy?.usr_phone === "" ||
                          localStorageCopy?.usr_dob === ""
                        ) {
                          setIsProfilePopup(true);
                        } else {
                          checkBtnConfig();
                        }
                      } else {
                        checkBtnConfig();
                      }
                    }
                    checkBtnConfig();
                  }}
                  size="link"
                  className={style["checkoutButton_filled"]}
                >
                  {paymentID === "COD"
                    ? "Place order"
                    : btnConfig?.payment_Button_2}
                </Button>
              </div>
              <div className={style.continue_button}>
                <LinkButton
                  to="/"
                  type="ghost-secondary"
                  className={style["checkoutButton"]}
                  draggable="false"
                >
                  {btnConfig?.checkout_Button_1}
                </LinkButton>
              </div>

            </div>
          </div>
        </FlexBox>
      </FlexBox>
      {/* {isAddressPopup && (
        <AddressPopup
          setModalOpen={setIsAddressPopup}
          onselect={handleAddressSelect}
          setIsAddressEmpty={setIsAddressEmpty}
          setAddress={setAddress}
          address={address}
          getDefaultAddress={getDefaultAddress}
          propsGetAddresss={getAddress}
        />
      )} */}

      {isAddAddressPopup?.isTrue && (

        // <AddNewAddress
        //   setModalOpen={setIsAddAddressPopup}
        //   containerClassBackGroundClass="modal_deliveryaddress_Background"
        //   mainContainerClass="modal_deliveryaddress_Container"
        //   rowContainerClass="modal_deliveryaddress_rowContainer"
        //   showCancelButton={true}
        //   isAddAddressPopup={isAddAddressPopup}
        //   // onselect={handleAddressSelect}
        //   // setIsAddressEmpty={setIsAddressEmpty}
        //   // setAddress={setAddress}
        //   // address={address}
        //   propsGetDeafultAddress={getDefaultAddress}
        //   // propsGetAddresss={getAddress}
        //   propGetAllAddress={getAddress}
        //   propsSelectedAddress={sSelectedAddress}
        //   propSetSelectedAddresses={setSelectedAddresses}
        //   isAddressPage={false}
        //   setPermaSetSelect={setPermaSetSelect}
        // />
        <AddressV2
          setModalOpen={setIsAddAddressPopup}
          pAddresses={addresses}
          propsSelectedAddress={sSelectedAddress}
          //this props store data which selected to edit
          isAddAddressPopup={isAddAddressPopup}
          setAlert2={setAlert2}
          alert2={alert2}
          getAddress={getAddress}
          getDefaultAddress={getDefaultAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {isOrderCompletion && (
        <OrderCompletion
          setModalOpen={setIsOrderCompletion}
          heading={Config?.orderCompletion_Modal_Heading}
          subHeading={Config?.orderCompletion_subHeading}
          orderNumber={orderNumber}
          detail={Config?.orderCompletion_Modal_detail__1}
        />
      )}

      {isAddPaymentCardModalOpen ? (
        <AddPaymentsCard
          setIsAddPaymentCardModalOpen={setIsAddPaymentCardModalOpen}
          handlePayment={handlePayment}
          setIsPaymentAlert={setIsPaymentAlert}
          isButtonLoading={isButtonLoading}
          setIsButtonLoading={setIsButtonLoading}
          total={cartTotal?.cart_net_payable}
          cardItems={cardItems}
          setCardItems={setCardItems}
          setIsSaveCardModal={setIsSaveCardModal}
          isSaveCardModal={isSaveCardModal}
          setIsBasicDetails={setIsBasicDetails}
        />
      ) : (
        ""
      )}
      {alert && <Alert type="error" info="Payment cancelled by user" />}

      {isBasicDetails?.isSelected && (
        <Alert type={isBasicDetails?.type} info={isBasicDetails?.msg} />
      )}
      {alert2?.isTrue &&
        <Alert type={alert2?.type} info={alert2?.msg} />}

      {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}
    </div>
  );
};

export default Checkout;
