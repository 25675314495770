import React, { Fragment, useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import WidgetCard from "../../../components/UI/WidgetCard";
import Button from "../../../components/UI/Button";
import Config from "../../../config/label";
import { config } from "../../../config/processId";
import { CallAPI } from "../../../suggest-us/callSuggest";
import APIContext from "../../../store/api-context";
import { useNavigate } from "react-router-dom";
import noshow from "../../../assets/svg/noshow.svg";
import Slider from "react-slick";
import AddtocartPopup from "../../../components/ProductDetails/AddtocartPopup";
import { ChevronLeft, ChevronRight } from "../../../utils/appIcons";
import { ShimmerThumbnail } from "react-shimmer-effects";
import ProductCard from "../../../components/ProductCard";
import { H3 } from "../../../components/UI/Typography";
import LoginPopup from "../LoginPopup";
import RxProductCard from "../../../components/RxProductCard/index";
import { Link } from "react-router-dom";
import btnConfig from "../../../config/button";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { msg_config } from "../../../config/messages";
import { objDecrypt, getDecryptedID, JSONCheckerFunc, setEncryptedID, getID } from "../../../utils/utils";
import { siteConfig } from "../../../config/site";
import ProfilePopup from "../../Landingpage/ProfilePopup";
import { AppAlertContext } from "../../../store/alert-context.js";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import SubmitRx from "./submitRx";
import { color_config } from "../../../config/colorConfig";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
// import HealthInfoCards from "../../../components/HealthInfoCards";

export type categoryProps = {
  widget: any;
  setIsAlert: any;
};

const MyRXWidgetNew: React.FC<categoryProps> = (props) => {
  const { widget, setIsAlert } = props;
  const navigate = useNavigate();
  const [suggestUsInit] = useContext(APIContext);
  const [recommendedList, setRecommendedList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [Cartloading, setcartLoading] = useState({
    isTrue: false,
    productId: "",
  });
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [myRxContext, setMyRxContext] = useState<any>([]);
  const [productdetails, setProductDetails] = useState<any>("");
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const [context, setContext] = useContext(UserAuthContext);

  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };

  let isUserLoggedIn = sessionStorageCopy &&  Object.keys(sessionStorageCopy)?.length > 0 ? true : false;

  const gaEventTracker = useAnalyticsEventTracker("Home page");

  const getProductDetails = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.process_specific_product_list_id, {
        p_cus_id: sessionStorageCopy?.customer_id,
        p_max_offset: "10",
        p_process_flag: "RECOMM",
      }).then((res) => {
        if (res?.returnCode === true) {
          setRecommendedList(res?.returnData);
        }
      });
      CallAPI(config?.myRx_list_process_id, {
        p_customer_id:
          sessionStorageCopy?.customer_id !== null
            ? sessionStorageCopy?.customer_id
            : "",
        p_min_offset: "0",
        p_max_offset: "10",
      }).then((res: any) => {
        if (res?.returnCode) {
          res?.returnData.length > 0 && setMyRxContext(res?.returnData);
          setLoading(true);
        }
      });
    }
  }; // Added closing parenthesis here


  useEffect(() => {
    getProductDetails();
  }, [suggestUsInit]);

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${Styles?.arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${Styles?.arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    // slidesToShow: myRxContext?.length < 3 ? myRxContext?.length : 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        // p_cus_id: sessionStorageCopy?.customer_id,
        p_wshlst_id: product?.wshlst_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          // getProductDetails();
          setRecommendedList(
            recommendedList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );

          setMyRxContext(
            myRxContext?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );
        }
      });
    }
  };
  const wishListAddHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
        p_srv_id: product?.srv_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          // getProductDetails();
          //
          setRecommendedList(
            recommendedList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );

          setMyRxContext(
            myRxContext?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );
        }
      });
    }
  };

  const likeButtonHandler = (product: any) => {
    switch (product?.wshlst_status) {
      case "Y":
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
  };
  const addCartHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      setcartLoading({
        isTrue: true,
        productId: product?.srv_id,
      });
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res: any) => {
        if (res?.returnCode) {
          setEncryptedID(CART_COUNT, res?.returnData[0].cart_item_count);
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          setcartLoading({
            isTrue: false,
            productId: "",
          });
          gaEventTracker(
            "Items added to cart",
            res?.returnData[0].cart_item_count
          );
          setAlertContext({
            status: false,
            type: "success",
            info: res?.msg,
            cartCount: res?.returnData[0].cart_count,
          });
          setIsAlert({
            istrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
          setcartLoading({
            isTrue: false,
            productId: "",
          });
        }
      });
    }
  };

  // function gtag() {
  //   dataLayer.push(arguments);
  // }

  // gtag('event', 'page_view', {
  //   page_title: '<Page Title>',
  //   page_location: '<Page Location>',
  //   page_path: '<Page Path>',
  //   send_to: '<GA_MEASUREMENT_ID>'
  // })

  // Filling array with empty values for slider duplicacy handling
  if (myRxContext?.length > 0) {
    for (let i = 0; i <= 2; i++) {
      if (myRxContext?.length < 3) {
        let diff = 3 - myRxContext?.length;

        let newArr = [];
        newArr = [...myRxContext];
        newArr.length = myRxContext?.length + diff;

        if (newArr[newArr.length - 1] === undefined) {
          newArr[newArr.length - 1] = { id: undefined };
        }

        if (newArr[newArr.length - 2] === undefined) {
          newArr[newArr.length - 2] = { id: undefined };
        }
        // newArr[newArr.length - 1] = { id: undefined };
        // console.log("newArr", newArr);
        setMyRxContext(newArr);
      }
    }
  }

  return (
    <>
      <Fragment>
        <WidgetCard
          className={`${Styles?.container}`}
          style={{ order: `${widget?.sequence}` }}
        >
          <div className={`${Styles?.widgetContainer}`}>
            <div className={`${Styles?.rightContainer}`}>
              {loading === false ? (
                <Slider {...settings} className={`${Styles?.custom_slider}`}>
                  {[1, 2, 3, 4, 5]?.map((item: any, idx: any) => {
                    return (
                      <div
                        className={`${Styles?.horizontal_wrapper}`}
                        key={idx}
                      >
                        <ShimmerThumbnail height={250} rounded />
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <>
                  {myRxContext.length === 0 ? (
                    <>
                      <H3 className={`${Styles?.title}`} style={{ color: `` }}>
                        {msg_config?.myrxnew_recom}
                      </H3>
                      <Slider {...settings}>
                        {recommendedList?.map((product: any) => {
                          return (
                            <div
                              key={product?.id}
                              className={`${Styles?.widget_item_wrapper}`}
                            >
                              <ProductCard
                                pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                                product={product}
                                type={product?.service_type}
                                viewType="Grid"
                                productUrl={product?.srv_id}
                                imgUrl={product?.srv_image.split("~")[0]}
                                imgAlt={product?.srv_name}
                                detail={product?.srv_name}
                                packagingId={product?.srv_packaging}
                                brand={product?.srv_brand}
                                price={product?.discounted_amount}
                                actualPrice={product?.srv_price}
                                discountPercent={product?.discount_percent}
                                coPay={product?.copayment_amount}
                                loading={Cartloading}
                                ratingCount={
                                  product?.rating_view_flag === "Y" &&
                                  product?.rating_star_count
                                }
                                isWishListAdded={
                                  product?.wshlst_status === "Y" ? true : false
                                }
                                ribbon={true}
                                ribbonType="folded"
                                onLikeButtonClick={() => {
                                  if (isUserLoggedIn) {
                                    likeButtonHandler(product);
                                  } else {
                                    setContext((prev: any) => ({
                                      ...prev,
                                      isLoginPopUP: true,
                                    }));
                                  }
                                }}
                                onAddtoCartButtonClick={() => {
                                  if (isUserLoggedIn) {
                                    addCartHandler(product);
                                  } else {
                                    setContext((prev: any) => ({
                                      ...prev,
                                      isLoginPopUP: true,
                                    }));
                                  }
                                }}
                                productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE

                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </>
                  ) : (
                    <>
                      {myRxContext?.length > 0 ? (
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <H3
                              className={`${Styles?.title}`}
                              style={{ color: `` }}
                            >
                              {widget?.html_description}
                            </H3>
                          </div>

                          <Link
                            to="/my-rx"
                            className={`${Styles?.linkButton}`}
                            draggable="false"
                          >
                            {btnConfig?.widget_link_button}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <Slider {...settings}>
                        {/* when myRxContent is empty the loader will be initiated otherwise the contents are displayed */}

                        {myRxContext?.map((product: any) => (
                          <div
                            key={product?.id}
                            className={`${Styles?.widget_item_wrapper}`}
                          >
                            {product?.id !== undefined && (
                              <RxProductCard
                                productImage={product?.single_image}
                                onFile={product?.refill_on_text}
                                heading={product?.srv_name}
                                containerCount={product?.srv_packaging}
                                product_CountLeft={product?.refill_text}
                                leftCount={product?.balance_qty}
                                product_address={product?.srv_source_location}
                                addedToCart={
                                  product?.cart_status === "N" ? false : true
                                }
                                onClick={() => {
                                  if (isUserLoggedIn) {
                                    addCartHandler(product);
                                  } else {
                                    setContext((prev: any) => ({
                                      ...prev,
                                      isLoginPopUP: true,
                                    }));
                                  }
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </Slider>
                    </>
                  )}
                </>
              )}
            </div>
            <div className={`${Styles?.leftContainer}`}>
              <SubmitRx setIsProfilePopup={setIsProfilePopup} />
            </div>

            {/* <div className={`${Styles?.leftContainer}`}>
              <div className={`${Styles?.noshow}`}>
                <img src={noshow} alt="noshow" draggable="false" />
              </div>
              <div className={`${Styles?.not_1}`}>
                {msg_config?.myrxnew_no_show}
              </div>
              <div className={`${Styles?.not_2}`}>
                {msg_config?.myrxnew_show}
              </div>
              <div className={`${Styles?.not_3}`}>
                <Button
                  type={Config?.lbl_Btn_type_primary}
                  size={Config?.lbl_Btn_size_medium}
                  onClick={() => {
                    if (isUserLoggedIn === true) {
                      if (
                        sessionStorageCopy?.usr_email === "" ||
                        sessionStorageCopy?.usr_name === "" ||
                        sessionStorageCopy?.usr_phone === "" ||
                        sessionStorageCopy?.usr_dob === ""
                      ) {
                        setContext((prev: any) => ({
                          ...prev,
                          isLoginPopUP: true,
                        }));
                      } else {
                        navigate("/submit-rx");
                      }
                    } else {
                      setContext((prev: any) => ({
                        ...prev,
                        isLoginPopUP: true,
                      }));
                    }
                  }}
                  className={`${Styles?.btnStyle}`}
                >
                  {Config?.header_button_text}
                </Button>
              </div>
            </div> */}
          </div>
          {isLoginPopup && <LoginPopup setModalOpen={setIsLoginPopup} />}
        </WidgetCard>
      </Fragment>
      {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}

      {isCartPopUpOpen && (
        <AddtocartPopup
          img={productdetails?.srv_image.split("~")[0]}
          title={productdetails?.srv_name + productdetails?.srv_packaging}
          setModalOpen={setIsCartPopUPOpen}
        />
      )}
    </>
  );
};

export default MyRXWidgetNew;
