import React, { useEffect, useState, useContext } from "react";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import { Link } from "react-router-dom";
import editicon from "../../../assets/sf_svg/Edit.svg";
import usersvg from "../../../assets/sf_svg/user.svg";
import Group3 from "../../../assets/sf_svg/my_list_v2.svg";
import Invoices from "../../../assets/svg/Invoices.svg";
import Payments from "../../../assets/svg/Payments.svg";
import ManageAddress from "../../../assets/svg/ManageAddress.svg";
import userSkeleton from "../../../assets/svg/user_1.svg";
import logoutsvg from "../../../assets/sf_svg/logout.svg";
import Kart from "../../../assets/sf_svg/kart.svg";
import bagheartfill from "../../../assets/svg/bagheartfill.svg";
import bookmarksfill from "../../../assets/svg/bookmarks-fill.svg";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import PopConfirm from "../../../components/UI/PopConfirm";
import { AppAlertContext } from "../../../store/alert-context.js";
import settings from "../../../assets/sf_svg/settings.svg";
import HealthCard from "../../../assets/png/HealthCard.png";
import HealthInfo from "../../../assets/png/HealthInfo.png";
import Insurance from "../../../assets/png/Insurance.png";
import { GlobalContext } from "../../../store/global-context.js";
import { JSONCheckerFunc, extractCountryCodeAndNumber, objDecrypt, removeKey } from "../../../utils/utils";
import IconButton from "../IconButton";
import EditProfile from "../../../pages/MyProfile/EditProfile";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { config } from "../../../config/processId";
import { CART_COUNT, DEFAULT_FLAG, HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, INSURANCE_FRONT_ID, IS_EDITING, IS_INSURANCE, LOGGED_IN_USER_ROLE, MY_PROFILE_MENU_IMAGES, PATIENT_ID, PROFILE_IMAGE, PROFILE_IMAGE2, QB_ID, SG_PATIENT_ID, SG_ROLE_ID, SG_USER_ID, SIGN_IN_WITH, USER_ID, USER_LOGGED_IN, USER_PROFILE, USER_ROLE_ID } from "../../../config/Config";
import profileImg from "../../../assets/proile_img.png";
import CompanyProfile from "../../../pages/SignUpVendor/CompanyProfile/CompanyProfile";
import { getDecryptedID } from "../../../utils/utils";
import { clearSession } from "../../../utils/utils";
import { setEncryptedID } from "../../../utils/utils";
import RxImage from "../../../assets/svg/rx.svg"
// export type ProfileModalProps = {
//   heading?: string,
//   detail?: string,
//   setModalOpen?: any,
// };

const ProfileModal = (props) => {
  const user_id = getDecryptedID(USER_PROFILE)?.usr_id || "";
  const [context, setContext] = useContext(UserAuthContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [profileForm, setProfileForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",   
    patient_photo: "",
    document_id: "",
    gender: "",
    completeness_my_address: null,
    completeness_my_health_cards: null,
    completeness_my_health_info: null,
    completeness_my_insurance_cards: null,
    completeness_my_profile: null,
  });

  const navigate = useNavigate();
  const { setModalOpen, setOpenCompanyProfileCard, setOpenCreateCompanyCard } = props;
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [suggestUsInit] = useContext(APIContext);
  const [sProfileList, setProfileList] = useState([]);

  const handleOnClick = (val) => {
    switch (val) {
      case "Manage Address":
        navigate("/add-address");
        setModalOpen(false);
        break;

      case "Prescriptions":
        navigate("/my-rx-request");
        setModalOpen(false);
        break;

      case "My Profile":
        navigate("/my-profile");
        setModalOpen(false);
        break;

      case "Cart":
        navigate("/purchase");
        setModalOpen(false);
        break;

      case "Orders":
        navigate("/my-orders");
        setModalOpen(false);
        break;

      case "My Wishlist":
        navigate("/wishlist");
        setModalOpen(false);
        break;
      case "Manage Payments":
        navigate("/manage-payments");
        setModalOpen(false);
        break;
      case "Change Password":
        navigate("/change-password");
        window.scrollTo(0, 0);
        setModalOpen(false);
        break;
      case "Insurance":
        navigate("/my-insurance");
        window.scrollTo(0, 0);
        setModalOpen(false);
        break;
      case "Health Information":
        navigate("/my-health");
        window.scrollTo(0, 0);
        setModalOpen(false);
        break;
      case "Health Card":
        navigate("/my-health-card");
        window.scrollTo(0, 0);
        setModalOpen(false);
        break;
      case "Saved Items":
        navigate("/saved");
        setModalOpen(false);
        break;
      case "My List":
        navigate("/my-list");
        setModalOpen(false);

      default:
        break;
    }
  };

  const details = [
    {
      icon: <img src={MY_PROFILE_MENU_IMAGES?.myProfile} alt="user" draggable="false" />,
      value: "My Profile",
      type: "IP",
    },
    {
      icon: <img src={Group3} alt="orders" draggable="false" />,
      value: "My List",
      type: "IP",
    },
    {
      icon: <img src={MY_PROFILE_MENU_IMAGES?.myWishlist} alt="orders" draggable="false" />,
      value: "My Wishlist",
      type: "I",
    },
    {
      icon: (
        <img
          src={MY_PROFILE_MENU_IMAGES?.cart}
          alt="cart"
          style={{ marginLeft: "0" }}
          draggable="false"
        />
      ),
      value: "Cart",
      type: "IP",
    },
    // {
    //   icon: <img src={ManageAddress} alt="address" draggable="false"/>,
    //   value: "Manage Address",
    // },
    // {
    //   icon: <img src={bagheartfill} alt="wishlist" draggable="false"/>,
    //   value: "My Wishlist",
    // },
    {
      icon: <img src={MY_PROFILE_MENU_IMAGES?.orders} alt="orders" draggable="false" />,
      value: "Orders",
      type: "IP",
    },
    {
      icon: <img src={RxImage} alt="address" draggable="false" style={{"width": "100%"}}/>,
      value: "Prescriptions",
    },
    // {
    //   icon: <img src={Payments} alt="Payments" draggable="false"/>,
    //   value: "Manage Payments",
    // },
    // {
    //   icon: <img src={Insurance} alt="My Insurance" style={{height: "0.9rem", width: "0.9rem"}} draggable="false"/>,
    //   value: "Insurance",
    // },
    // {
    //   icon: <img src={HealthInfo} alt="My Health" style={{height: "0.9rem", width: "0.9rem"}} draggable="false"/>,
    //   value: "Health Information",
    // },
    // {
    //   icon: <img src={HealthCard} alt="My Health Card" style={{height: "0.9rem", width: "0.9rem"}} draggable="false"/>,
    //   value: "Health Card",
    // },
    {
      icon: <img src={MY_PROFILE_MENU_IMAGES?.changePassword} alt="Change Password" draggable="false" />,
      value: "Change Password",
      type: "IP",
    },
    // {
    //   icon: <img src={Payments} alt="Payments" draggable="false"/>,
    //   value: "Manage Payments",
    // },
    // {
    //   icon: <img src={bookmarksfill} alt="wishlist" draggable="false"/>,
    //   value: "Saved Items",
    // },
  ];

  const localStorageCopy = getDecryptedID(USER_PROFILE);
  const profilePhoto = localStorageCopy?.patient_photo;

  const newArr = details?.filter((item) => {
    const user_type = context?.UserAuth?.usr_type;
    if (user_type === "pro") {
      return item?.type === "IP" || item?.type === "P" ? true : false;
    } else {
      if (item?.type === "P") return false;
    }
    return true;
  });

  // const newArr = details?.filter((item) => {
  //   if (
  //     localStorageCopy?.usr_signup_type === "gmail" ||
  //     localStorageCopy?.usr_signup_type === "apple" ||
  //     localStorageCopy?.usr_signup_type === "Gmail" ||
  //     localStorageCopy?.usr_signup_type === "Apple"
  //   ) {
  //     return item?.value !== "Change Password";
  //   } else {
  //     return item;
  //   }
  // });

  //removing object if user is pro
  const newArray20 = newArr.filter(
    (items) => items?.userType === context?.UserAuth?.usr_type
  );

  // useEffect(()=>{
  //     try {
  //       if(context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE){
  //         setContext((prev)=>{
  //           return {...prev, isEditProProfile : isEditModalOpen}
  //         })
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  // },[isEditModalOpen])


  const userInfodetails = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.my_profile_process_id, {
        p_user_id: user_id,
      }).then((res) => {
        setGlobalContext((prev) => {
          return {
            ...prev,
            profile_image: res?.returnData[0]?.patient_img_path,
          };
        });
        let phoneNumber = extractCountryCodeAndNumber(res?.returnData[0]?.usr_phone)
        setProfileForm((prev) => {
          return {
            ...prev,
            firstName:
              res?.returnData[0]?.first_name === ""
                ? ""
                : res?.returnData[0]?.first_name,
            lastName:
              res?.returnData[0]?.last_name === ""
                ? ""
                : res?.returnData[0]?.last_name,
            phone:phoneNumber || "",
            email:
              res?.returnData[0]?.usr_email === ""
                ? ""
                : res?.returnData[0]?.usr_email,
            dob:
              res?.returnData[0]?.usr_dob === null
                ? undefined
                : res?.returnData[0]?.usr_dob,
            patient_photo: res?.returnData[0]?.patient_img_path,
            document_id:
              res?.returnData[0]?.customer_profile_picture_document_id,
            gender:
              res?.returnData[0]?.usr_gender_code === null
                ? undefined
                : res?.returnData[0]?.usr_gender_code,
            completeness_my_address:
              res?.returnData[0]?.completeness_my_address,
            completeness_my_profile:
              res?.returnData[0]?.completeness_my_profile,
            completeness_my_health_cards:
              res?.returnData[0]?.completeness_my_health_cards,
            completeness_my_health_info:
              res?.returnData[0]?.completeness_my_health_info,
            completeness_my_insurance_cards:
              res?.returnData[0]?.completeness_my_insurance_cards,
          };
        });
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    userInfodetails();
  }, [isEditModalOpen]);

  return (
    // <div className={Styles["modalBackground"]}>
    <div className={Styles["modalContainer"]} id="profileModal">
      <div className={Styles["title"]}>
        <div className={Styles["profile_user_details"]}>
          <div className={Styles["checkImgWrapper"]}>
            <img
              src={globalContext?.profile_image || userSkeleton}
              alt="ProfileImg"
              draggable="false"
            />
            {/* <img src={profileImg} /> */}
          </div>
          <div className={Styles["user_details"]}>
            <Heading className={`${Styles?.page_heading}`}>
              {localStorageCopy?.usr_type === "customer"
                ? localStorageCopy?.usr_name !== ""
                  ? localStorageCopy?.usr_name
                  : ""
                : ""}

              {(localStorageCopy?.usr_type === LOGGED_IN_USER_ROLE &&
                localStorageCopy?.usr_company_name) ||
                ""}
            </Heading>
            <p className={`${Styles?.location}`}>
              {localStorageCopy?.usr_phone !== null
                ? localStorageCopy?.usr_phone
                : ""}
            </p>
          </div>
        </div>

        <div className={Styles["editicon_profile"]}>
          <IconButton
            title="Edit profile"
            className={`${Styles?.editicon}`}
            onClick={() => {
              if(context?.UserAuth?.usr_type === "customer" || context?.UserAuth?.usr_type !== LOGGED_IN_USER_ROLE && context?.UserAuth?.usr_type === null){
                setIsEditModalOpen(true);
                // setModalOpen(false);

              }else {
                setModalOpen(false);
                setEncryptedID(IS_EDITING, "Y");
                setOpenCreateCompanyCard(true)
                // setOpenCompanyProfileCard(true)
              }
            }}
          >
            <img src={editicon} alt="Edit btn" draggable="false" />
          </IconButton>
          {/* <img
            src={editicon}
            alt="editicon"
            draggable="false"
          /> */}
        </div>
      </div>
      <div className={Styles["subDetails"]}>
        {newArr?.map((item) => {
          return (
            <div
              className={Styles["dynamicDetails"]}
              onClick={() => handleOnClick(item?.value)}
            >
              <div
                className={`${Styles["profile_link_icon"]} ${Styles?.linkStyling}`}
                style={{ marginTop: "0px" }}
              >
                {item?.icon}
              </div>
              {/* <Link
                title=""
                to=""
                className={`${Styles?.linkStyling}`}
                draggable="false"
              > */}
                <p>{item?.value}</p>
              {/* </Link> */}
            </div>
          );
        })}
        {/* Pop confirm removed as mentioned in the QA issue list */}
        {/* <PopConfirm
          title="Are you sure that you want to logout?"
          onConfirm={() => {}}
          style={{ left: 0 }}
        > */}
        <div
          className={Styles["dynamicDetails"]}
          onClick={() => {
            logout();
            navigate("/");
            setContext((prev) => ({
              ...prev,
              UserAuth: {},
            }));
            // localStorage.clear();
            removeKey(USER_PROFILE);
            removeKey(SG_ROLE_ID);
            removeKey(SG_USER_ID);
            removeKey(SG_PATIENT_ID);
            removeKey(IS_INSURANCE);
            removeKey(PROFILE_IMAGE);
            removeKey(PROFILE_IMAGE2);
            removeKey(USER_ID);
            removeKey(QB_ID);
            removeKey(USER_ROLE_ID);
            removeKey(DEFAULT_FLAG);
            removeKey(PATIENT_ID);
            removeKey(CART_COUNT);
            removeKey(INSURANCE_FRONT_ID);
            removeKey(INSURANCE_BACK_ID);
            removeKey(HEALTH_CARD_FRONT_ID);
            removeKey(HEALTH_BACK_ID);
            removeKey(HEALTH_INFO);
            clearSession();
            setEncryptedID(USER_LOGGED_IN, "N");
            setEncryptedID(SIGN_IN_WITH, " ");
            setModalOpen(false);
            // TODO HARRY
            // logoutUserFromQB();
            setGlobalContext((prev) => {
              return {
                ...prev,
                profile_image: "",
              };
            });
            window.location.reload();
            // onLogout = () => {
              window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString())
              window.localStorage.removeItem('CREDENTIALS_FLUSH')
            // }
          }}
        >
          <div
            className={Styles["profile_logout_btn"]}
            style={{ marginTop: "0px" }}
          >
            <img src={logoutsvg} alt="help" draggable="false" />
          </div>

          <p className={`${Styles?.linkStyling}`}>Log out</p>
        </div>
        {/* </PopConfirm> */}
      </div>
      {isEditModalOpen && (
        <EditProfile
          setIsEditModalOpen={setIsEditModalOpen}
          editHeading={"Edit Profile"}
          setProfileForm={setProfileForm}
          profileForm={profileForm}
          userInfoHandler={userInfodetails}
          //PROFILE MODAL STATE
          setModalOpen = {setModalOpen}
        />
      )}
    </div>
  );
};
export default ProfileModal;
