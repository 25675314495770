import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import noshow from "../../assets/svg/noshow.svg";
import LoadingCircle from "../../components/LoadingCircle";
import SideBar from "../../components/ProfileSideBar/SideBar";
import WishlistCard from "../../components/WishlistCard";
import Config from "../../config/label";
import { config } from "../../config/processId.js";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { Bookmarks } from "../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, setEncryptedID } from "../../utils/utils";
import styles from "./index.module.css";
import { color_config } from "../../config/colorConfig";
import Alert from "../../components/UI/Alert";
import { CART_COUNT, USER_PROFILE } from "../../config/Config";

const SaveforlaterScreen = () => {
  const [productList, setProductList] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestUsInit] = useContext(APIContext);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [offSet, setOffset] = useState(0);
  const [loading, setLoading] = useState({
    wishlistId: "",
    delete: "",
  })
  const [alert, setAlert] = useState({
    isTrue: false,
    msg: "",
    type: "",
  });


  useEffect(() => {
    if (alert?.isTrue) {
      setTimeout(() => {
        setAlert({
          isTrue: false,
          msg: "",
          type: "",
        })
      }, 2000);
    }
  }, [alert])
  const fetchMoreData = async () => {
    if (suggestUsInit?.returnCode === true) {
      let newOffSet = 0;
      newOffSet = offSet + 9;
      setOffset(newOffSet);
      const res = await CallAPI(config?.get_save_for_later_items_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_min_offset: newOffSet,
        p_max_offset: 9,
      }).then((res) => res);
      if (res?.returnCode) {
        // setIsLoading(false);
        setProductList((prev: any) => [...prev, ...res?.returnData]);
        return true;
      }
    }
  };

  const FetchWishList = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_save_for_later_items_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_min_offset: 0,
        p_max_offset: 9,
      }).then((res) => {
        if (res?.returnCode) {
          setProductList(res?.returnData);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    FetchWishList();
  }, [suggestUsInit]);

  const moveToCartHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      setLoading((prev: any) => {
        return { ...prev, wishlistId: product?.saveforlater_id }
      })
      CallAPI(config?.move_saved_items_to_cart_process_id, {
        p_org_id: userProfileJson?.org_id,
        p_usr_id: userProfileJson?.usr_id,
        p_saveforlater_id: product?.saveforlater_id,
        p_cus_id: userProfileJson?.customer_id,
        p_internal_flag: "",
      }).then((res) => {
        if (res?.returnCode) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) + 1).toString());
          FetchWishList();
          setLoading((prev: any) => {
            return { ...prev, wishlistId: "" }
          })
          setAlert({
            isTrue: true,
            msg: res?.msg,
            type: "success",
          })
        } else {
          setAlert({
            isTrue: true,
            msg: res?.msg,
            type: "error",
          })
          setLoading((prev: any) => {
            return { ...prev, wishlistId: "" }
          })
        }
      });
    }
  };

  const onDeleteHandler = (product: any/* , setIsConfirmPopup: any */) => {
    //setIsConfirmPopup(false);
    if (suggestUsInit?.returnCode === true) {
      setLoading((prev: any) => {
        return { ...prev, delete: product?.saveforlater_id }
      })
      CallAPI(config?.delete_saved_items_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_srv_id: product?.srv_id,
        p_sfl_id: product?.saveforlater_id,
      }).then((res) => {
        if (res?.returnCode) {
          FetchWishList();
          setLoading((prev: any) => {
            return { ...prev, delete: "" }
          })
        }
      });
    }
  };

  return (
    <div
      className={`${styles?.mainContainer}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="outer_container">
        <div className={`${styles?.grid}`}>
          <div className={`${styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
          <div className={`${styles?.gridRightItem}`}>
            <div className={`${styles?.headerContainer}`}>
              <div style={{ display: "flex" }}>
                <Bookmarks
                  width="25"
                  height="25"
                  style={{ marginTop: "7px", color: "#32C5F4" }}
                />
                <h2 className={`${styles?.heading}`}>{Config?.svl_heading}</h2>
              </div>
            </div>
            {isLoading ? (
              <>
                <br />
                <br />
                <br />
                <ShimmerSimpleGallery row={2} col={3} imageHeight={150} />
              </>
            ) : (
              <div>
                <InfiniteScroll
                  style={{ overflowX: "hidden" }}
                  dataLength={productList?.length || 0}
                  next={fetchMoreData}
                  hasMore={productList?.length < productList[0]?.total_count}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <LoadingCircle
                        loadingColor={color_config?.common_bg_color}
                      />
                    </div>
                  }
                  // height={"620px"}
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        marginTop: "30px",
                      }}
                    >
                      <b>
                        {productList?.length === 0 ? (
                          <>
                            <div className={styles["checkIconWrapper"]}>
                              <img
                                src={noshow}
                                alt="Empty Cart icon"
                                draggable="false"
                              />
                            </div>
                            {Config?.mywish_nodata}
                          </>
                        ) : (
                          ""
                        )}
                      </b>
                    </p>
                  }
                >
                  <div className={`${styles?.addressGrid}`}>
                    {productList?.map((item: any) => (
                      <WishlistCard
                        productId={item?.saveforlater_id}
                        type={item?.service_type}
                        productUrl={"/product-detail/" + item?.srv_id}
                        imgUrl={item?.srv_image.split("~")[0]}
                        loading={loading}
                        imgAlt={item?.srv_name}
                        detail={item?.srv_name}
                        packagingId={item?.srv_packaging}
                        brand={item?.srv_brand}
                        price={item?.discounted_amount}
                        actualPrice={item?.srv_price}
                        discountPercent={item?.discount_percent}
                        coPay={item?.copayment_amount}
                        ribbon={true}
                        ribbonType="folded"
                        ratingCount={
                          item?.rating_view_flag === "Y" &&
                          item?.rating_star_count
                        }
                        onaddbuttonclick={() => {
                          moveToCartHandler(item);
                        }}
                        ondeletebuttonclick={(setIsConfirmPopup: any) => {
                          onDeleteHandler(item/* , setIsConfirmPopup */);
                        }}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      </div>
      {alert?.isTrue && (
        <Alert type={alert?.type} info={alert?.msg} />
      )}
    </div>
  );
};

export default SaveforlaterScreen;
