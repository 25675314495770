import React from "react";
import Slider from "react-slick";
import styles from "./index.module.css";
import { ShimmerThumbnail } from "react-shimmer-effects";

const DefaultWidget = (props:any) => {
  const thumb_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...thumb_settings} key={props?.index}>
      {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any, idx:any) => (
        <div className={`${styles?.widget_item_wrapper}`} key={idx}>
          <ShimmerThumbnail height={250} rounded />
        </div>
      ))}
    </Slider>
  );
};

export default DefaultWidget;
