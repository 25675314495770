import React from "react";
import { DoneIcon, CloseIcon } from "../../../../../utils/appIcons";
import Button from "../../../../../components/UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../../../../config/button";
import Heading from "../../../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Config from "../../../../../config/label";

export type ModalProps = {
  heading?: string;
  detail?: string;
  setModalOpen?: any;
  setIsAlertChngPsw?: any;
  setIsPasswordModalOpen?: any;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    setModalOpen,
    heading,
    detail,
    setIsAlertChngPsw,
    setIsPasswordModalOpen,
  } = props;
  let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setModalOpen(false);
            navigate("/", { replace: true });
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={DoneIcon} alt="Right check icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>{heading}</Heading>

          <p>{detail}</p>
        </div>

        <Button
          type={Config?.lbl_Btn_type_primary}
          htmlType={Config?.lbl_Btn_action_submit}
          size={Config?.lbl_Btn_size_medium}
          onClick={(e: any) => {
            e.preventDefault();
            setIsPasswordModalOpen(false);
            setModalOpen(false);
            // navigate("/", { replace: true });
            navigate("/log-in");
            setIsAlertChngPsw({
              istrue: "Y",
              type: "success",
              msg: "Please login to continue!",
            });
          }}
        >
          {btnConfig?.verification_Complete}
        </Button>
      </div>
    </div>
  );
};
export default Modal;
