import React, { useEffect, useState } from "react";
import ConversationView from "./ConversationView";
import SearchList from "./SearchList";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
var spd_user_id = ""
var spd_user_name = ""
const ConversationList = ({
  conversations,
  onConversationClick,
  selectedConversationSid,
  id,
  name,
  userType,
  messageService,
  twilioUrl,
  CallAPI,
  availableUsers,
  client,
  setSearchToAdd,
  searchToAdd,
  setAvailableUsers,
  searchLoading,
  setSearchLoading,
  clientLoading,
  particUpdate,
  state,
  setState,
  searchActive,
  setNewConversation,
  setTotalUnreadCount,
  customId,
  tabs,
  setHideTabs,
  activeUsers,
  setQueueCount,
  setTabs,
  chatServiceSid
}) => {
  const [messasge, setMessage] = useState([]);
  const [isTyping, setIstyping] = useState(null);
  const [addLoading, setAddLoading] = useState({
    sid: '',
    loading: false
  });
  const [finalConvList, setFinalConvList] = useState({
    joined: [],
    unjoined: []
  })
  spd_user_id = id
  spd_user_name = name
  /************************** CHECK MY MESSAGE START *******************************/

  function isMyMessage(messages) {
    if (messages === undefined || messages === null || messages.length === 0) {
      return false;
    }
    return messages[messages.length - 1].author === id
      ? messages[messages.length - 1]
      : false;
  }
  /************************** CHECK MY MESSAGE END *******************************/

  const results = []; //search results
  const myUsersList = []; //participants identity
  let partId = []; //participants sid
  var queueResults = [];
  var chatConversations = []
  var queueConversations = []
  const data = conversations?.map((item) => {
    if (item?.attributes?.joined_user_id?.includes(spd_user_id)) {
      chatConversations.push(item)
    }
    else if (!item?.attributes?.joined_user_id?.includes(spd_user_id) && item?.attributes?.cg_id) {
      queueConversations.push(item)
    }
    return item
  })
  const finalQueue = queueConversations?.filter((c) => !customId?.includes(c?.sid) && c?.createdBy !== spd_user_id)
  const queueConversationId = finalQueue?.map(v => v?.sid)

  const finalChat = data?.concat(chatConversations)?.filter(v => !queueConversationId?.includes(v?.sid));

  // const queueConversation = conversations?.filter(c => { return c?.attributes?.cg_id && !c?.attributes?.joined_user_id?.includes(spd_user_id) })
  /************************** GET PARTICIPETENT IDENTITY START *******************************/
  [...conversations]
    ?.filter((v) => v?.attributes?.type === "single")
    .map((v) =>
      v?._participants?.forEach((participant) =>
        myUsersList.push(participant?.identity)
      )
    );
  /************************** GET PARTICIPETENT IDENTITY END *******************************/

  /************************** GET LAST MESSAGE START *******************************/
  function getLastMessage(value) {

    const newValue = messasge?.filter((v) => v?.conversation?.sid === value)

    if (newValue === undefined || newValue === null) {
      return { message: "Loading..." };
    }
    if (newValue.length === 0) {
      return "No value";
    }
    if (newValue[newValue.length - 1]?.media?.sid) {
      return {
        message: "Media message",
        date: newValue[newValue.length - 1]?.dateCreated,
        author: newValue[newValue.length - 1]?.author,
      };
    }
    return {
      message: ["queue"]?.includes(newValue[newValue.length - 1]?.attributes?.type) && !newValue[newValue.length - 1]?.attributes?.staffs?.includes(spd_user_id) ? "" : newValue[newValue.length - 1]?.body,
      date: newValue[newValue.length - 1]?.dateCreated,
      author: newValue[newValue.length - 1]?.author,
    };
  }
  /************************** GET LAST MESSAGE END *******************************/



  /************************** HANDLE TYPING STATE START *******************************/
  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((m) => {
      if (m.senderId === "typingStarted" && m.target === "twilioChat") {
        setIstyping(m.text);
      }
      if (m.senderId === "typingEnded" && m.target === "twilioChat") {
        setIstyping(m.text);
      }
    });
    return () => {
      if (subscription != null && subscription != undefined) {
        subscription.unsubscribe();
      }
    }
  }, [])
  /************************** HANDLE TYPING STATE END *******************************/

  /************************** GET UNREAD MESSAGES COUNT START  *******************************/
  finalQueue?.filter((v) => v?.attributes?.type === "group" || v?.attributes?.type === "queue")?.filter(
    (v) =>
      searchToAdd !== ""
        ? v?.friendlyName?.toLowerCase()?.match(searchToAdd) && queueResults.push(v)
        : queueResults.push(v)
  );



  [...new Set(finalChat?.map(p => p))]?.filter((v) => v?.attributes?.type === "group" || v?.attributes?.type === "queue")?.filter(
    (v) =>
      searchToAdd !== ""
        ? v?.friendlyName?.toLowerCase()?.match(searchToAdd) && results.push(v)
        : results.push(v)
  );

  [...new Set(finalChat?.map(p => p))]?.filter((v) => v?.attributes?.type === "single")?.filter(
    (v) =>
      searchToAdd !== ""
        ? v?._participants?.forEach(
          (p) =>
            p?.attributes?.userName?.toLowerCase()?.match(searchToAdd) &&
            p?.attributes?.userID !== spd_user_id &&
            results.push(v)
        )
        : results.push(v)
  );
  /************************** GET UNREAD MESSAGES COUNT END  *******************************/
  const getUnReadMessages = (val) => {
    var count = 0;
    var firstCount = 0
    const res = messasge?.filter((v) => v?.conversation?.sid === val?.sid);
    // if (res?.length === 1 && val?.lastReadMessageIndex === null) { firstCount = 1 }
    if (res[res.length - 1]?.index > val?.lastReadMessageIndex) { count = res[res.length - 1]?.index - val?.lastReadMessageIndex }
    return count + firstCount
  };
  /************************** GET UNREAD MESSAGES COUNT END  *******************************/

  /************************** GET ALL PARTICIPENTS SID START  *******************************/
  // conversations?.map((v) =>
  //   v?._participants?.forEach(
  //     (p) =>
  //       p?.identity === spd_user_id &&
  //       partId.push(`${v?.sid}~${p?.sid}`)
  //   )
  // );
  // const availableUsersId = availableUsers?.map((v) => v?.identity)

  /************************** GET ALL PARTICIPENTS SID END  *******************************/

  /************************** ADDING THE SELECTED USER TO CONVERSATION START  *******************************/
  const addAndUpdateAttributes = async (convo, v) => {
    try {
      await convo.add(v?.usr_id);
      const attributes = {

        users: [
          { usr_id: spd_user_id, usr_name: spd_user_name },
          { usr_id: v?.usr_id, usr_name: v?.usr_name },
        ],

        type: "single",
      };
      await convo.updateAttributes(attributes); //setting the attributes
      // const data = await convo.getParticipants();
      // const partSid = data?.find(
      //   (p) => p?.identity !== spd_user_id
      // );


      const includingMe = [{ value: v?.usr_id, label: v?.usr_name }, { value: spd_user_id, label: spd_user_name }];
      //update participantsData



      includingMe?.forEach(async (v) => {
        convo?._participants?.forEach(async (val) => {
          if (val?.identity === v?.value) {
            await axios({
              method: "POST",
              data: {
                partiSid: val?.sid,
                convoSid: convo?.sid,
                attributes: {
                  userID: v?.value,
                  userName: v?.label,
                },
              },
              url: `${twilioUrl}/chat/updateParti`,
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        })
      })

      // await axios({
      //   method: "POST",
      //   data: {
      //     partiSid: partSid?.sid,
      //     convoSid: partSid?.conversation?.sid,
      //     attributes: {
      //       userID: v?.usr_id,
      //       userName: v?.usr_name,
      //     },
      //   },
      //   url: `${twilioUrl}/chat/updateSingleParti`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      //update userData
      await axios({
        method: "POST",
        url: `${twilioUrl}/chat/updateUser/${v?.usr_id}`,
        data: {
          friendlyName: v?.usr_name,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAddLoading({
        sid: '',
        loading: false
      });
      // toast.success("User added successfully");
      await onConversationClick(convo?.sid)
      setHideTabs(true)
      setSearchToAdd("")
    }
    catch (error) {
      setAddLoading({
        sid: '',
        loading: false
      });
      console.log("Error: ", error.message);
    }
  }

  const addNewUser = async (v) => {
    try {
      const convo = await client?.createConversation({
        friendlyName: " ", // for single type convo set to  " "
      });
      await convo.join();
      await convo.getParticipants();
      await axios({
        method: "GET",
        url: `${twilioUrl}/chat/users`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        const availableUsersId = res?.data?.users?.map((val) => val?.identity)
        setAvailableUsers(res?.data?.users);
        if (availableUsersId?.includes(v?.usr_id)) {
          addAndUpdateAttributes(convo, v)
        }
        else {
          setAddLoading({
            sid: '',
            loading: false
          });
          toast.error("User not found")
        }

      });


    } catch (error) {
      setAddLoading({
        sid: '',
        loading: false
      });
      console.log("Error: ", error.message);
    }
  };



  /************************** ADDING THE SELECTED USER TO CONVERSATION END  *******************************/

  /************************** HANDLE ADD NEW USER START  *******************************/
  const handleAdd = async (value) => {
    try {
      setAddLoading({
        sid: value?.usr_id,
        loading: true
      });
      // CREATING TOKEN FOR THE SELECTED USER TO ACTIVATE ACCOUNT
      await axios({
        method: "GET",
        url: `${twilioUrl}/chat/token?identity=${encodeURIComponent(
          value?.usr_id
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response?.data?.token) {
          // ADDING THE USER TO CONVERATION
          addNewUser(value);
        }
      });
    } catch (error) {
      setAddLoading({
        sid: '',
        loading: false
      });
      console.log("Error: ", error.message);
    }
  };

  const getTotalCount = async () => {
    await Promise.all(results?.map(async (v) => {
      if (v?.attributes?.type === "single") {
        await v?.updateLastReadMessageIndex(v?.attributes?.lastMessageReadIndex)
      }
      const value = getUnReadMessages(v)
      return value
    })).then(res => {
      messageService.sendMessage("TopHeader", res?.reduce((p, c) => p + c, 0), "totalCount");
    })
  }
  useEffect(() => {
    getTotalCount()
    let joinedGrp = []
    let unjoinedGrp = []
    results?.map((i) => {
      if (i?.identity === null) {
        unjoinedGrp.push(i)
      }
      else {
        joinedGrp.push(i)
      }
    })
    // setFinalConvList((prev) => {
    //   return { ...prev, joined: joinedGrp, unjoined: unjoinedGrp }
    // })
    if (userType === 'patient') {
      results?.map((i) => {
        if (i?.status) {
          if (i?.status === "joined" && i?.attributes?.type === "queue" && !state?.isQueueJoined) {
            setState((prev) => {
              return { ...prev, isQueueJoined: true, joinedQueueSid: i?.sid }
            })
          }
        }
      })
    }
  }, [results])


  useEffect(() => {
    if (finalQueue?.length > 0) {
      messageService.sendMessage("queueCount", finalQueue?.length, "conversationList")
      messageService.sendMessage(
        "ChatModal",
        {
          sound: true
        },
        "playSound"
      );
    }
    else {
      messageService.sendMessage("queueCount", 0, "conversationList")
      messageService.sendMessage(
        "ChatModal",
        {
          sound: false
        },
        "playSound"
      );
    }
    return () => {
      messageService.sendMessage(
        "ChatModal",
        {
          sound: false
        },
        "playSound"
      );
    }
  }, [finalQueue])

  /************************** HANDLE ADD NEW USER END  *******************************/
  try {
    return (
      <div className="conversationList marginTop-1" style={{ height: searchActive ? 'calc(100% - var(--ui-2-60))' : "calc(100% - var(--ui-0-42))", minHeight: "380px" }}>

        {
          clientLoading !== "success" && results?.length > 0 ?
            <> {/* <div className="conversationList-heading">CHATS</div> */}
              {results?.length > 0 && tabs === "CHAT"
                ? results
                  ?.sort((a, b) => {
                    const adateCreated =
                      a.lastMessage !== undefined
                        ? moment(a.lastMessage?.dateCreated)
                        : moment(a?.dateCreated);
                    const bdateCreated =
                      b.lastMessage !== undefined
                        ? moment(b.lastMessage?.dateCreated)
                        : moment(b?.dateCreated);;
                    if (adateCreated < bdateCreated) return -1;
                    if (adateCreated > bdateCreated) return 1;
                    return 0;
                  })
                  .reverse()
                  ?.map((conv) => (
                    <ConversationView
                      chatServiceSid={chatServiceSid}
                      activeUsers={activeUsers}
                      key={conv?.sid}
                      setTotalUnreadCount={setTotalUnreadCount}
                      conv={conv}
                      client={client}
                      setTabs={setTabs}
                      setSearchToAdd={setSearchToAdd}
                      id={id}
                      name={name}
                      twilioUrl={twilioUrl}
                      messageService={messageService}
                      tabs={tabs}
                      setHideTabs={setHideTabs}
                      setNewConversation={setNewConversation}
                      availableUsers={availableUsers}
                      setAvailableUsers={setAvailableUsers}
                      unReadMessagesLength={getUnReadMessages(conv) || 0}
                      setMessage={setMessage}
                      lastMessage={getLastMessage(conv?.sid)}
                      typingData={isTyping?.convoSid === conv?.sid && isTyping}
                      myMessage={isMyMessage(messasge)}
                      participants={conv?._participants}
                      handleJoin={conv?.attributes?.cg_id && conv?.createdBy === null}
                      onConversationClick={onConversationClick}
                      selectedConversationSid={selectedConversationSid}
                    />
                  ))
                : null}
              {tabs === "QUEUE" && queueResults?.length > 0
                ? queueResults
                  ?.sort((a, b) => {
                    const adateCreated =
                      a.lastMessage !== undefined
                        ? moment(a.lastMessage?.dateCreated)
                        : moment(a?.dateCreated);
                    const bdateCreated =
                      b.lastMessage !== undefined
                        ? moment(b.lastMessage?.dateCreated)
                        : moment(b?.dateCreated);;
                    if (adateCreated < bdateCreated) return -1;
                    if (adateCreated > bdateCreated) return 1;
                    return 0;
                  })
                  .reverse()
                  ?.map((conv) => (
                    <ConversationView
                      key={conv?.sid}
                      chatServiceSid={chatServiceSid}
                      setTotalUnreadCount={setTotalUnreadCount}
                      conv={conv}
                      client={client}
                      setQueueCount={setQueueCount}
                      setHideTabs={setHideTabs}
                      setSearchToAdd={setSearchToAdd}
                      id={id}
                      name={name}
                      messageService={messageService}
                      tabs={tabs}
                      setNewConversation={setNewConversation}
                      availableUsers={availableUsers}
                      setAvailableUsers={setAvailableUsers}
                      unReadMessagesLength={getUnReadMessages(conv) || 0}
                      setMessage={setMessage}
                      lastMessage={getLastMessage(conv?.sid)}
                      typingData={isTyping?.convoSid === conv?.sid && isTyping}
                      myMessage={isMyMessage(messasge)}
                      participants={conv?._participants}
                      handleJoin={conv?.attributes?.cg_id && !conv?.attributes?.joined_user_id?.includes(spd_user_id)}
                      onConversationClick={onConversationClick}
                      selectedConversationSid={selectedConversationSid}
                    />
                  ))
                : tabs === "QUEUE" && queueResults?.length === 0 ? "No Queue Found" : ""}

            </> :
            
            <div className="loading_effect_chatbox">
              <span>
                <div class="pulse-effect">
                <i class="fa fa-comments" aria-hidden="true"></i>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                {state?.loadingMessage}
                </span>
            </div>
            
            
        }

        {
          searchToAdd !== "" && tabs === "CHAT" && (
            <SearchList
              CallAPI={CallAPI}
              searchToAdd={searchToAdd}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              client={client}
              handleAdd={handleAdd}
              addLoading={addLoading}
              availableUsers={[...new Set(myUsersList?.map((v) => v))]}
            />
          )
        }
      </div >
    );
  } catch (e) {
    console.log(e?.message, "ERROR :");
  }
};

export default React.memo(ConversationList);
