import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { H2, H6, Paragraph } from "../../../components/UI/Typography";
import StarRating from "../../../components/StarRating";
import MultiRangeSlider from "../../../components/multiRangeSlider/MultiRangeSlider";
import { Collapse } from "react-collapse";
import { CarretDownFill, CarretUpFill } from "../../../utils/appIcons";
import { JSONCheckerFunc } from "../../../utils/utils";

export type sectionProps = {
  section?: any;
  listCheckBoxItems: any;
  setListCheckBoxItems: any;
  resetPriceRange: any;
  setResetPriceRange: any;
  brandsItems: any;
  setBrandsItems: any;
  minValue: any;
  maxValue: any;
  setMinValue: any;
  setMaxValue: any;
  reff?: any;
  sideBarLength?: any;
  idx?: number;
  productTypeItems?:any;
  setProductTypeItems?:any;
  setIsFilterEnable?:any;
  setFetchMore?:any;
};

const SideFilter: React.FC<sectionProps> = (props: any) => {
  const {
    section,
    listCheckBoxItems,
    setListCheckBoxItems,
    resetPriceRange,
    setResetPriceRange,
    brandsItems,
    setBrandsItems,
    minValue,
    maxValue,
    setMinValue,
    setMaxValue,
    reff: ref,
    sideBarLength,
    idx,
    setProductTypeItems,
    productTypeItems,
    setIsFilterEnable,setFetchMore
  }: any = props;
  const [priceRange, setPriceRange] = useState([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const renderList = (item: any) => {
    <li key={item?.id} className={`${styles?.sectionListItem}`}>
      {item?.child_title}
    </li>;
  };


  const renderChkbox = (item: any) => {
    <label htmlFor={item?.id} className={`${styles.chkBoxContainer}`}>
      <span className={`${styles?.chkBxBackground}`}>
        <input
          type="checkbox"
          name={item?.child_value}
          id={item?.id}
          value={item?.child_value}
        />
      </span>

      <span className="mb-1">{item?.child_title}</span>
    </label>;
  };

  const renderColors = (item: any) => { };
  const renderRating = (item: any) => { };

  const getSectionType = (type: any, item: any) => {
    switch (type) {
      case "list":
        return renderList(item);
      case "ckeckbox":
        return renderChkbox(item);
      case "rating":
        return renderRating(item);
      case "color":
        return renderColors(item);

      default:
        break;
    }
  };
  // const values = section?.values;
  const values = JSONCheckerFunc(section?.values) || {};
  useEffect(() => {
    if (resetPriceRange) {
      if (section?.type === "slider") {
        setPriceRange(values);
        // setMinValue(values?.map((item: any) => item?.min_price));
        // setMaxValue(values?.map((item: any) => ?.max_price));
      }
    }
  }, [resetPriceRange]);

  return (
    <>
      {values !== null && values?.length > 0 && (
        <section className={`${styles?.filter_dropdown_wrapper}`}>
          <H6
            className={`${styles?.sectionHeading} ${isCollapseOpen ? styles?.active_filter : ""}`}
            onClick={() => {
              if (section?.isCollapsable === "Y") {
                // setIsCollapseOpen(!isCollapseOpen);
              }
            }}
          >
            <p>{section?.title}</p>

            {/* {section?.isCollapsable === "Y" && (
              <div style={{ paddingBottom: "4px" }}>
                {isCollapseOpen ? (
                  <CarretUpFill size="16" />
                ) : (
                  <CarretDownFill size="16" />
                )}
              </div>
            )} */}
          </H6>
          <div className={`${styles?.price_range_filter}`}
          style={{height : `${section?.type === "slider" ? "56px" : ""}`}}
          >
            <Collapse
              isOpened={section?.isCollapsable === "Y" ? true : true}
            // initialStyle={
            //   isCollapseOpen
            //     ? { height: "auto", overflow: "initial" }
            //     : { height: "0px", overflow: "hidden" }
            // }
            >
              {section?.type === "list" ? (
                <ul className={`${styles?.filter_main_wrapper}`}>
                  {values !== null &&
                    values?.length > 0 &&
                    values?.map((item: any) => (
                      <label
                        htmlFor={item?.manuf_id}
                        className={`${styles.chkBoxContainer}`}
                        key={item?.sg_id}
                      >
                        <div className={`${styles?.label_main}`}>
                          <span className={`${styles?.chkBxBackground}`}>
                            <input
                              // checked={
                              //   listCheckBoxItems?.length > 0
                              //     ? listCheckBoxItems?.find(
                              //         (items: any) =>
                              //           items?.[section?.title] === item?.ssg_id
                              //       )?.[section?.title] !== undefined
                              //       ? true
                              //       : false
                              //     : false
                              // }
                              type="checkbox"
                              name={section?.title}
                              id={item?.ssg_id}
                              value={item?.ssg_id}
                              onChange={(e) => {
                                setIsFilterEnable(true)
                                setFetchMore(true)
                                if (e.target.checked) {
                                setListCheckBoxItems((prev:any)=>{
                                  return [...prev, {[section?.title] :  e.target.value}]
                                })
                                } else {
                                  const filteredList = listCheckBoxItems?.filter(
                                    (item: any) =>
                                      item?.[section?.title] !== e.target.value
                                  );
                                  setListCheckBoxItems(filteredList);
                                }
                              }}
                            />
                            <span className={`${styles?.checkboxx}`}></span>
                          </span>
                          <span className={`${styles?.label_disc}`}>
                            {item?.ssg_description}
                          </span>
                        </div>
                        {/* <div className={`${styles?.category_number}`}>
                          <h5>(160)</h5>
                        </div> */}
                      </label>
                    ))}
                </ul>
              ) : (
                ""
              )}

              {section?.type === "checkbox" ? (
                <ul className={`${styles?.filter_main_wrapper}`}>
                  {Array.isArray(values) &&
                    values?.map((item: any) => (
                      <>
                        <label
                          htmlFor={item?.manuf_id}
                          className={`${styles.chkBoxContainer}`}
                        >
                          <span className={`${styles?.chkBxBackground}`}>
                            <input
                              type="checkbox"
                              checked={
                                brandsItems?.length > 0 &&
                                  brandsItems?.find(
                                    (items: any) =>
                                      items?.[section?.title] === item?.manuf_id
                                  )?.[section?.title] !== undefined
                                  ? true
                                  : false
                              }
                              name={item?.manuf_description}
                              id={item?.manuf_id}
                              value={item?.manuf_id}
                              onChange={(e) => {
                                // e.stopPropagation();
                                setFetchMore(true)
                                setIsFilterEnable(true)
                                if (e.target.checked) {
                                  setBrandsItems((prev: any) => [
                                    ...prev,
                                    { [section?.title]: e.target.value },
                                  ]);
                                } else {
                                  const filteredList = brandsItems?.filter(
                                    (item: any) =>
                                      item?.[section?.title] !== e.target.value
                                  );
                                  setBrandsItems(filteredList);
                                }
                              }}
                            />
                          <span className={`${styles?.checkboxx}`}></span>
                          </span>
                          <span className={`${styles?.brand_disc}`}>
                            {item?.manuf_description}
                          </span>
                        </label>
                      </>
                    ))}
                </ul>
              ) : (
                ""
              )}

              {section?.type === "type_checkbox" ? (
                <ul className={`${styles?.filter_main_wrapper}`}>
                  {Array.isArray(values) &&
                    values?.map((item: any) => (
                      <>
                        <label
                          htmlFor={item?.manuf_id}
                          className={`${styles.chkBoxContainer}`}
                        >
                          <span className={`${styles?.chkBxBackground}`}>
                            <input
                              type="checkbox"
                              // checked={
                              //   productTypeItems?.length > 0 &&
                              //     productTypeItems?.find(
                              //       (items: any) =>
                              //         items?.[section?.title] === item?.manuf_id
                              //     )?.[section?.title] !== undefined
                              //     ? true
                              //     : false
                              // }
                              name={item?.product_type_description}
                              id={item?.product_type_id + "##" + item?.product_type_description}
                              value={item?.product_type_description}
                              onChange={(e) => {
                                // e.stopPropagation();
                                setFetchMore(true)
                                setIsFilterEnable(true)
                                if (e.target.checked) {
                                  setProductTypeItems((prev: any) => [
                                    ...prev,
                                    { [section?.title.replace(" ", "")]: e.target.value },
                                  ]);
                                } else {
                                  const filteredList = productTypeItems?.filter(
                                    (item: any) =>
                                      item?.[section?.title.replace(" ", "")] !== e.target.value
                                  );
                                  setProductTypeItems(filteredList);
                                }
                              }}
                            />
                          <span className={`${styles?.checkboxx}`}></span>
                          </span>
                          <span className={`${styles?.brand_disc}`}>
                            {item?.product_type_description}
                          </span>
                        </label>
                      </>
                    ))}
                </ul>
              ) : (
                ""
              )}

              {section?.type === "slider"
                ? section?.isCollapsable === "Y"
                  ? isCollapseOpen &&
                  Array.isArray(values) &&
                  values?.map((item: any, index) => (
                    <div className={`${styles.chkBoxContainer}`}>
                      <MultiRangeSlider
                        min={item?.min_price}
                        key={index}
                        max={item?.max_price}
                        resetPriceRange={resetPriceRange}
                        setResetPriceRange={setResetPriceRange}
                        minValue={minValue}
                        maxValue={maxValue}
                        setMinValue={setMinValue}
                        setMaxValue={setMaxValue}
                        ref={ref}
                        onChange={({ min, max }: any) => { }}
                        setIsFilterEnable={setIsFilterEnable}
                        onChangeCapture={()=>{
                        }}

                      />
                    </div>
                  ))
                  : Array.isArray(values) &&
                  values?.map((item: any, index) => (
                    <div className={`${styles.chkBoxContainer}`}>
                      <MultiRangeSlider
                        min={item?.min_price}
                        key={index}
                        idx={idx}
                        max={item?.max_price}
                        resetPriceRange={resetPriceRange}
                        setResetPriceRange={setResetPriceRange}
                        minValue={minValue}
                        maxValue={maxValue}
                        setMinValue={setMinValue}
                        setMaxValue={setMaxValue}
                        ref={ref}
                        onChange={({ min, max }: any) => { }}
                        setIsFilterEnable={setIsFilterEnable}
                        onChangeCapture={()=>{
                        }}
                        
                      />
                    </div>
                  ))
                : ""}

              {idx < sideBarLength - 1 && (
                <hr className={`${styles?.sectionDivider}`} />
              )}
              {idx === sideBarLength - 1 && (
                <hr className={`${styles?.sectionDividerDummy}`} />
              )}
            </Collapse>
          </div>
        </section>
      )}
    </>
  );
};

export default SideFilter;
