import { useState } from "react";
import styles from "./index.module.css";

export type InputProps = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange?: any;
  style?: any;
  size: string;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  ref?: any;
  // value?: any;
  disabled?: any;
  required?: any;
  defaultValue?: any;
  maxLength?: any;
  qtyChange?: any;
};

const QtyInput: React.FC<InputProps> = (props) => {
  const {
    name,
    id,
    type,
    placeholder,
    onChange,
    className,
    onFocus,
    onBlur,
    ref,
    // value,
    disabled,
    required,
    defaultValue,
    maxLength,
    qtyChange,
  } = props;
  const [productQty, setProductQty] = useState(defaultValue);
  const getInputSize = () => {
    switch (props?.size) {
      case "large":
        return { height: "61px", fontSize: "20px" };

      case "medium":
        return { height: "56px", fontSize: "18px" };
      case "small":
        return { height: "38px", fontSize: "16px" };

      default:
        return {};
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\D/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setProductQty("");
    } else if (e.target.value === "0") {
      e.target.value = 1;

      setProductQty(e.target.value);
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setProductQty(newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "Qty"
    ) {
      let newString = cleanPersonNameInput(e.target.value);
      setProductQty(newString);
    } else {
      setProductQty(e.target.value);
    }
  };

  const onBlurChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      e.target.value = 1
      qtyChange(e.target.id, e.target.value);
    } else if (e.target.value === "0") {
      e.target.value = 1;

      qtyChange(e.target.id, e.target.value);
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      qtyChange(e.target.id, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "Qty"
    ) {
      let newString = cleanPersonNameInput(e.target.value);
      qtyChange(e.target.id, newString);
    } else {
      qtyChange(e.target.id, e.target.value);
    }
  };

  const handleEnter = (e: any) => {
    if (e?.code === "Enter"  ||e?.code === "NumpadEnter") {
      qtyChange(e.target.id, e.target.value === "" || e.target.value === "0" ? "1" : e.target.value );
    }
  };
  return (
    <input
      name={name}
      id={id}
      type={type}
      placeholder={placeholder}
      // onChange={onChange}
      className={`${styles.inputStyle} ${className}`}
      style={{ ...props?.style, ...getInputSize() }}
      onFocus={onFocus}
      // onBlur={onBlur}
      onChange={(e: any) => onInputChangeHandler(e, "Qty")}
      onBlur={(e: any) => onBlurChangeHandler(e, "Qty")}
      ref={ref}
      // value={value}
      value={productQty}
      disabled={disabled}
      required={required}
      defaultValue={defaultValue}
      maxLength={maxLength}
      onKeyDown={(e: any) => handleEnter(e)}
    />
  );
};

export default QtyInput;
