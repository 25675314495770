import React, { useState, useContext, useEffect } from "react";
import Styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar/index";

import LinkButton from "../../components/UI/LinkButton";
import { RightDirectionArrow } from "../../utils/appIcons";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdown from "../../components/UI/SelectDropdown";
import moment from "moment";
import Group3 from "../../assets/svg/orders.svg";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import { ShimmerTable } from "react-shimmer-effects";
import { useForm } from "react-hook-form";
import Drawer from "./NewDrawer/Drawer";
import Config from "../../config/label";
import noshow from "../../assets/svg/noshow.svg";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import { color_config } from "../../config/colorConfig";
import { USER_PROFILE } from "../../config/Config";
import DrawerOrder from "../MyOrders/Drawer"
import { UserAuthContext } from "../../store/UserAuthContext";

const MyRxRequest = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [productList, setProductList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState<any>(false);
  const [drawerOpenOrder, setDrawerOpenOrder] = useState<any>(false);
  const [rxDetails, setRxDetails] = useState({});
  const [userInfo, setUserInfo] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [rxFileContents, setRxFileContents] = useState([]);
  const [myRxContext, setMyRxContext] = useState([]);
  const [productdetails, setProductDetails] = useState("");
  const [details, setDetails] = useState({});
  const [userProfileInfo, setUserProfileInfo] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [isNewLoading, setIsNewLoading] = useState(true);
  const [isOrderInfoLoading, setIsOrderInfoLoading] = useState(true);
  const [context, setContext] = useContext(UserAuthContext);

  // const [selectedDate, setSelectedDate] = useState(undefined);
  // const [range, setRange] = useState([moment().subtract(7, "day"), moment()]);
  // const apiContext = useContext(APIContext);
  const [suggestUsInit] = useContext(APIContext);
  const navigate = useNavigate();
  const [drawerOpenRx, setDrawerOpenRx] = useState<any>(false);

  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);

  const getMyRxRequest = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_rx_request_list, {
        p_patient_id: userProfileJson?.patient_id,
        // p_patient_id: 106,
      }).then((res: any) => {
        if (res?.returnCode) {
          setProductList(res?.returnData);
        }
        setIsLoading(false);
      });
    }
  };

  useEffect(()=>{
    if(context?.isRxUploaded){
      getMyRxRequest();
    }
  },[context])

  const getOrderList = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_order_list_process_id, {
        p_customer_id: userProfileJson?.customer_id || "",
        p_min_offset: "",
        p_max_offset: "",
      }).then((res) => {
        if (res?.returnCode === true) {
          setProductList(res?.returnData);
        }
        setIsLoading(false);
      });
    }
  };
  const { control } = useForm();

  useEffect(() => {
    getMyRxRequest();
  }, [suggestUsInit]);

  useEffect(() => {
    if (drawerOpen === false) {
      setDetails({});
      setUserInfo([]);
      setProfilePic("");
      setRxFileContents([]);
      setMyRxContext([]);
      setProductDetails("");
      setUserProfileInfo([]);
      setItemsList([]);
      setOrderDetails({});
      setIsNewLoading(true);
      setIsOrderInfoLoading(true);

    }
  }, [drawerOpen]);

  return (
    <div
      className={`${Styles?.mainContainer}`}
      onClick={(e) => {
        setModalOpen(false);
        e.stopPropagation();
      }}
    >
      <div className="outer_container">
        <div className={`${Styles?.grid}`}>
          <div className={`${Styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
          <div className={`${Styles?.gridRightItem}`}>
            <div className={`${Styles?.headerContainer}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Group3}
                  alt="orders"
                  style={{ height: "25px", width: "25px", marginTop: "0px" }}
                  draggable="false"
                />
                <h2 className={`${Styles?.heading}`}>
                  {Config?.myrx_req_heading}
                </h2>
              </div>
              {/* Please don't remove the below commented code,
             * Temporary Hidden
             *
             */}

              {/* <div style={{ zIndex: 1 }}>
              <SelectDropdown
                name="time"
                options={activityTimeList}
                size="small"
                placeholder={"Select time frequency"}
                control={control}
              />
            </div> */}
            </div>

            {isLoading ? (
              <ShimmerTable row={5} col={5} />
            ) : (
              <div>
                {productList?.length > 0 ? (
                  <>
                    <ul className={`${Styles?.responsiveTable}`}>
                      <li className={`${Styles?.tableHeader}`}>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_1}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_2}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_3}
                        </div>
                        {/* <div className={`${Styles?.col} ${Styles?.col4}`}>
                        {Config?.myrx_req_col_4}
                      </div> */}
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_5}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_6}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_8}
                        </div>
                        <div className={`${Styles?.col} ${Styles?.col4}`}>
                          {Config?.myrx_req_col_7}
                        </div>
                      </li>
                      {productList?.map((item: any) => {
                        return (
                          <li className={`${Styles?.tableRow}`}>
                            <div
                              className={`${Styles?.col} ${Styles?.col4} ${Styles?.menuLinks}`}
                              data-label={Config?.myrx_req_col_1}
                              onClick={() => {
                                setDrawerOpen(true);
                                setRxDetails(
                                  productList?.find(
                                    (val: any) => val?.id === item?.id
                                  )
                                );
                                setContext((prev:any)=>{
                                  return {...prev, rx_id : item?.id}
                                })
                              }}
                            >
                              {item?.request_number}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myrx_req_col_2}
                            >
                              {item?.rx_type}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myrx_req_col_3}
                            >
                              {item?.stage}
                            </div>
                            {/* <div
                            className={`${Styles?.col} ${Styles?.col4}`}
                            data-label={Config?.myrx_req_col_4}
                          >
                            {item?.rx_patient_type || "n/a"}
                          </div> */}
                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myrx_req_col_5}
                            >
                              {item?.rx_source || ""}
                            </div>

                            <div
                              className={`${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myrx_req_col_6}
                            >
                              {item?.rx_source_location || ""}
                            </div>
                            <div
                              className={`${Styles?.col} ${Styles?.col4} ${Styles?.menuLinks}`}
                              data-label={Config?.myrx_req_col_6}
                              onClick={() => {
                                setDrawerOpenOrder(true)
                                setDetails(item);
                              }}
                            >
                              {item?.order_number && item?.order_number || ""}
                            </div>

                            <div
                              className={`${Styles?.rightArrow} ${Styles?.col} ${Styles?.col4}`}
                              data-label={Config?.myrx_req_col_7}
                              onClick={() => {
                                setDrawerOpen(true);
                                setRxDetails(
                                  productList?.find(
                                    (val: any) => val?.id === item?.id
                                  )
                                );
                              }}
                            >
                              <RightDirectionArrow width="20" />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: color_config?.common_bg_color,
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      paddingTop: "100px",
                    }}
                  >
                    <div className={Styles["checkIconWrapper"]}>
                      <img src={noshow} alt="Empty Cart icon" draggable="false" />
                    </div>
                    <b>{Config?.myrx_req_notFo}</b>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Drawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        name={""}
        rxDetails={rxDetails}
        headingName={Config?.preescription_request_header}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        rxFileContents={rxFileContents}
        setRxFileContents={setRxFileContents}
        myRxContext={myRxContext}
        setMyRxContext={setMyRxContext}
        productdetails={productdetails}
        setProductDetails={setProductDetails}
        orderNumber={""}
      />

      <DrawerOrder
        drawerOpen={drawerOpenOrder}
        setDrawerOpen={setDrawerOpenOrder}
        setDrawerOpenRx={setDrawerOpenRx}
        setRxDetails={setRxDetails}
        name={""}
        details={details}
        headingName={"Order details"}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        userProfileInfo={userProfileInfo}
        setUserProfileInfo={setUserProfileInfo}
        itemsList={itemsList}
        setItemsList={setItemsList}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        isNewLoading={isNewLoading}
        setIsNewLoading={setIsNewLoading}
        isOrderInfoLoading={isOrderInfoLoading}
        setIsOrderInfoLoading={setIsOrderInfoLoading}
        getOrderList={getOrderList}
      />
    </div>
  );
};

export default MyRxRequest;
