import React, { useEffect, useRef } from "react";
import Cross from './Assets/Cross.png';



const SearchUser = ({ searchToAdd, setSearchToAdd, searchLoading, searchActive }) => {
  const timer = useRef(null)
  const inputRef = useRef(null);
  useEffect(() => {
    if (searchActive) {
      inputRef?.current.focus();
    }
  }, [searchActive]);
  const handleChange = (event) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setSearchToAdd(inputRef.current.value);
    }, 500);
  };



  return (
    <div className="padding-1 searchContainer">
      <div className="patient-search-modal-inner ui-flex ui-vertical-align-middle">
        <div className="ui-flex searchbar-patient" style={{ maxWidth: "none" }}>
          {searchActive &&
            <div className="search-input" style={{ width: "100%" }} >
              <input
                className="form-control"
                id="patient-search-input-field"
                type="search"
                autoFocus={true}
                autoComplete='off'
                name="search_user"
                ref={inputRef}
                placeholder={'Search'}
                onChange={handleChange}
              />
            </div>
          }
          {searchLoading &&
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          }
          {searchToAdd !== "" &&
            <span className='search-close'>
              <img src={Cross} onClick={() => {
                inputRef.current.value = ""
                setSearchToAdd('');
              }} />
            </span>}
        </div>
      </div >
    </div>
  );
};

export default SearchUser;
