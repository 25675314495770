import React, { useState, useContext, useEffect, useRef, memo } from "react";
import APIContext from "../../store/api-context";
import { config } from "../../config/processId.js";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import styles from "./index.module.css";
import ProductCard from "../../components/ProductCard";
import SideFilter from "./SideFilter";
import TopFilter from "./TopFilter";
import Loading from "../../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SideDrawer from "../../components/UI/SideDrawer";
import LoadingCircle from "../../components/LoadingCircle";
import LoginPopup from "./LoginPopup";
import { UserAuthContext } from "../../store/UserAuthContext.js";
import Button from "../../components/UI/Button";
import AddtocartPopup from "../../components/ProductDetails/AddtocartPopup";
import ClearAllIcon from "../../assets/png/ClearAllIcon.png";
import ApplyIcon from "../../assets/png/ApplyIcon.png";
import { AppAlertContext } from "../../store/alert-context.js";
import Config from "../../config/label.js";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import {
  ShimmerTitle,
  ShimmerThumbnail,
  ShimmerBadge,
} from "react-shimmer-effects";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt, setEncryptedID, triggerLocalStorage } from "../../utils/utils";
import Alert from "../../components/UI/Alert";
import { siteConfig } from "../../config/site";
import { color_config } from "../../config/colorConfig";
import ProductCardMerchant from "../../components/ProductCardMerchant";
import { useSelector } from "react-redux";
import MyList from "../../components/MyList";
import SignUpVendorContainer from "../SignUpVendor/SignUpVendorContainer/SignUpVendorContainer";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
export interface SetSideBarFiltersType {
  title: string;
  type: string;
  values: string;
}

var mVarOff = 0;
const maxOffSetValue = 24;

const ProductListScreen = () => {
  const [productList, setProductList] = useState<any>({
    items: [],
  });


  // Getting manfID for when we select brands from shop screen
  const { type_id, manfID  } = useParams();
  let typeID = type_id || "";
  const showAddToMyList = useSelector(
    (state: any) => state?.myListPageReducer?.showAddToMyList
  );
  const [activeFilter, setActiveFilter] = useState(true);

  const ref: any = useRef({ minValue: 0, maxValue: 0 });
  const [suggestUsInit] = useContext(APIContext);
  const [productViewType, setProductViewType] = useState("GRID");
  const [isSideFilter, setIsSideFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sideBarLoading, setSideBarLoading] = useState(false);
  const [loading, setLoading] = useState({
    isTrue: false,
    productId: "",
  });
  const [offSet, setOffset] = useState(0);

  const [sideBarFilters, setSideBarFilters] = useState<SetSideBarFiltersType[]>(
    []
  );
  const [isFilterEnable, setIsFilterEnable] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [fetchMore, setFetchMore] = useState(true);
  const { height, width } = useWindowDimensions();
  const [categoryName, setCategoryName] = useState("");
  const [numElement, setnumElement] = useState(0);
  const [listCheckBoxItems, setListCheckBoxItems] = useState<any>([]);
  const [brandsItems, setBrandsItems] = useState<any>([]);
  const [productTypeItems, setProductTypeItems] = useState<any>([]);
  const [resetPriceRange, setResetPriceRange] = useState(true);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [minValScroll, setMinValScroll] = useState(undefined);
  const [maxValScroll, setMaxValScroll] = useState(undefined);
  const [sortByScroll, setSortByScroll] = useState(undefined);
  const [context, setContext] = useContext(UserAuthContext);
  const [isPopUpOpen, setIsPopUPOpen] = useState(false);
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [productdetails, setProductDetails] = useState<any>("");
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const [isAlert, setIsAlert] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });
  const [moreLoading, setMoreLoading] = useState(false);

  const localStorageCopy: any = getDecryptedID(USER_PROFILE);

  const gaEventTracker = useAnalyticsEventTracker("Product list page");
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };//

  let isUserLoggedIn = localStorageCopy && Object.keys(localStorageCopy)?.length > 0 ? true : false;

  let sideBarLength = sideBarFilters?.length;

  let selectedCategoryLength = listCheckBoxItems && Object.keys(listCheckBoxItems).length;
  const fetchMoreData = () => {
    mVarOff = mVarOff + maxOffSetValue;

    const sortBy = sortByScroll === undefined ? "" : sortByScroll;
    const brands = brandsItems.length === 0 ? "" : brandsItems?.map((item: any) => item?.Brands)?.join(",") || "";
    const catIds = listCheckBoxItems.length === 0 ? "" : listCheckBoxItems?.map((item: any) => item?.Category)?.join(",") || "";
    const productTypes = productTypeItems.length === 0 ? "" : productTypeItems?.map((item: any) => item?.ProductType)?.join(",");
    const minimumValue = minValue !== undefined ? Array.isArray(minValue) ? minValue[0] : `${minValue}` : "";
    const maximumValue = maxValue !== undefined ? Array.isArray(maxValue) ? maxValue[0] : `${maxValue}` : "";

    CallAPI(config?.product_list_process_id, {
      p_cus_id: localStorageCopy?.customer_id || "",
      p_sg_id:  selectedCategoryLength > 0 ? "" : typeID,
      p_offset: mVarOff,
      p_max_offset: maxOffSetValue,
      p_ssg_id: catIds,
      p_manuf_id: isFilterEnable ? brands : manfID ,
      p_min_price: minimumValue,
      p_max_price: maximumValue,
      p_sort_by_flag: sortBy,
      p_srv_type: productTypes
      // p_cus_id: localStorageCopy?.customer_id || "",
      // p_sg_id:
      //   manfID !== undefined ? "" : selectedCategoryLength > 0 ? "" : typeID,
      // p_offset: mVarOff,
      // p_max_offset: 12,
      // p_ssg_id: listCheckBoxItems.length === 0 ? "" : listCheckBoxItems?.map((item: any) => item?.Category)?.join(",") || "",
      // p_manuf_id:
      //   manfID !== undefined
      //     ? manfID
      //     : brandsItems?.length !== 0
      //       ? brandsItems?.map((item: any) => item?.Brands)?.join(",")
      //       : "",
      // p_min_price:
      //   minValScroll !== undefined
      //     ? Array.isArray(minValScroll)
      //       ? minValScroll[0]
      //       : minValScroll
      //     : "",
      // p_max_price:
      //   maxValScroll !== undefined
      //     ? Array.isArray(maxValScroll)
      //       ? maxValScroll[0]
      //       : maxValScroll
      //     : "",
      // p_sort_by_flag: sortByScroll !== undefined ? sortByScroll : "",
      // p_srv_type: productTypeItems.length === 0 ? "" : productTypeItems?.map((item: any) => item?.ProductType)?.join(",")
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData.length === 0) {
          setFetchMore(false);
        } else {
          setProductList({
            items: [...productList?.items, ...res?.returnData],
          });
        }
      } else {

      }
      setTimeout(() => {
        setMoreLoading(false);
      }, 1000);
    });
  };


  useEffect(() => {
    try {
      if (isFilterEnable) {
        setFetchMore(true)
        const sortBy = sortByScroll === undefined ? "" : sortByScroll;
        // const catIds = listCheckBoxItems?.Category || ""
        const brands = brandsItems.length === 0 ? "" : brandsItems?.map((item: any) => item?.Brands)?.join(",") || "";
        const catIds = listCheckBoxItems.length === 0 ? "" : listCheckBoxItems?.map((item: any) => item?.Category)?.join(",") || "";
        const productTypes = productTypeItems.length === 0 ? "" : productTypeItems?.map((item: any) => item?.ProductType)?.join(",");
        const minimumValue = minValue !== undefined ? Array.isArray(minValue) ? minValue[0] : `${minValue}` : "";
        const maximumValue = maxValue !== undefined ? Array.isArray(maxValue) ? maxValue[0] : `${maxValue}` : "";

        mVarOff = 0;
        FetchProductListWithFilter(catIds, brands, productTypes, minimumValue, maximumValue, sortBy)
      } else {
        // FetchProductListWithFilter ('', '', '', '', '', sortBy)
      }
    } catch (error) {
      console.error(error);
    }

  }, [brandsItems, listCheckBoxItems, productTypeItems, minValue, maxValue, isFilterEnable, sortByScroll])


  const FetchProductListWithFilter = (catId: any,
    brands: any,
    productTypes: any,
    minimumValue: any,
    maximumValue: any,
    sortBy: any = "") => {
    try {
      const params = {
        p_cus_id: localStorageCopy?.customer_id || "",
        p_sg_id: selectedCategoryLength > 0 ? "" : typeID,
        p_offset: 0,
        p_max_offset: maxOffSetValue,
        p_ssg_id: catId,
        p_manuf_id: isFilterEnable ? brands : manfID,
        p_min_price: minimumValue,
        p_max_price: maximumValue,
        p_sort_by_flag: `${sortBy}`,
        p_srv_type: productTypes
      }
      // return false;
      setIsLoading(true);
      CallAPI(config?.product_list_process_id, params)
        .then((res) => {
          if (res?.returnCode) {
            setIsLoading(false);
            setProductList({
              items: res?.returnData,
            });
          } else {
            setIsLoading(false);
          }
        })

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      if (productList?.items.length > 0) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [productList]);

  const FetchProductList = (
    minValues: any,
    maxValues: any,
    sortByFilter: any
  ) => {
    // return false;
    window.scroll(0, 0);
    // setOffset(0);
    mVarOff = 0;
    setMinValScroll(minValues);
    setMaxValScroll(maxValues);
    setSortByScroll(sortByFilter);
    setMinValue(minValues);
    setMaxValue(maxValues);
    if (suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      //Api call for product list
      const productTypes = productTypeItems.length === 0 ? "" : productTypeItems?.map((item: any) => item?.ProductType)?.join(",");

      CallAPI(config?.product_list_process_id, {
        p_cus_id: localStorageCopy?.customer_id || "",
        p_sg_id:
          selectedCategoryLength > 0 ? "" : typeID,
        p_offset: 0,
        p_max_offset: maxOffSetValue,
        p_ssg_id: listCheckBoxItems.length === 0 ? "" : listCheckBoxItems?.map((item: any) => item?.Category)?.join(",") || "",
        p_manuf_id:manfID? manfID: brandsItems?.length !== 0
              ? brandsItems?.map((item: any) => item?.Brands)?.join(",")
              : "",
        p_min_price:
          minValues !== undefined
            ? Array.isArray(minValues)
              ? minValues[0]
              : minValues
            : "",
        p_max_price:
          maxValues !== undefined
            ? Array.isArray(maxValues)
              ? maxValues[0]
              : maxValues
            : "",
        p_sort_by_flag: sortByFilter !== undefined ? `${sortByFilter}` : "",
        p_srv_type: productTypes
      }).then((res) => {
        if (res?.returnCode) {
          setProductList({
            items: res?.returnData,
            // items: [],
          });

          setFetchMore(true);

          if (manfID !== undefined) {
            let brand = res?.returnData?.find(
              (item: any) => item?.manuf_id === manfID
            )?.srv_brand;
            setCategoryName(brand);
          }
          setnumElement(res?.returnData[0]?.filter_count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  //Clear handler
  const ClearProductList = (
    minValue: any,
    maxValue: any,
    sortByFilter: any
  ) => {
    mVarOff = 0;
    setOffset(0);
    setMinValScroll(minValue);
    setMaxValScroll(maxValue);
    setSortByScroll(sortByFilter);
    setMinValue(minValue);
    setMaxValue(maxValue);
    getSideBar();
    if (suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      //Api call for product list
      const productTypes = productTypeItems.length === 0 ? "" : productTypeItems?.map((item: any) => item?.ProductType)?.join(",");

      CallAPI(config?.product_list_process_id, {
        p_cus_id: localStorageCopy?.customer_id || "",
        p_sg_id: typeID,
        p_offset: 0,
        p_max_offset: maxOffSetValue,
        p_ssg_id: "",
        p_manuf_id: "",
        p_min_price:
          minValue !== undefined
            ? Array.isArray(minValue)
              ? minValue[0]
              : minValue
            : "",
        p_max_price:
          maxValue !== undefined
            ? Array.isArray(maxValue)
              ? maxValue[0]
              : maxValue
            : "",
        p_sort_by_flag: sortByFilter !== undefined ? `${sortByFilter}` : "",
        p_srv_type: productTypes
      }).then((res) => {
        if (res?.returnCode) {
          setIsLoading(false);
          setProductList({
            items: res?.returnData,
          });

          setnumElement(res?.returnData[0]?.filter_count);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const getSideBar = () => {
    if (suggestUsInit?.returnCode === true) {
      setSideBarLoading(true);
      //Api call for side bar filters
      CallAPI(config?.side_bar_product_list_process_id, {
        p_sg_id: typeID,
        p_manf_id : manfID
      }).then((res) => {
        if (res?.returnCode) {
          setSideBarLoading(false);
                    setSideBarFilters(res?.returnData);
        } else {
          setSideBarLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    // setIsLoading(true)
    setListCheckBoxItems([]);
    setBrandsItems([]);
    // setProductList({
    //   items: [],
    // });
    // setOffset(0);
    setSideBarFilters([]);

    //Api call for product list
    FetchProductList(undefined, undefined, sortByScroll);

    getSideBar();
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.category_list_process_id, { p_max_offset: "10" }).then(
        (res) => {
          if (res?.returnCode) {
            // setIsLoading(false);
            var category = res?.returnData.filter(
              (item: any) => item.sg_id === typeID
            );
            // setCategoryName(category[0].sg_description);
          }
        }
      );
    }
  }, [suggestUsInit, typeID, manfID]);

  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_wshlst_id: product?.wshlst_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker("Items removed from wishlist");

          setProductList({
            items: productList?.items?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            }),
          });
        }
      });
    }
  };

  const wishListAddHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
        p_srv_id: product?.srv_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker("Items added to wishlist");
          // FetchProductList(undefined, undefined, sortByScroll);
          setProductList({
            items: productList?.items?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            }),
          });
        }
      });
    }
  };

  const likeButtonHandler = (product: any) => {
    switch (product?.wshlst_status) {
      case "Y":
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
  };

  const addCartHandler = (product: any) => {
    setIsAlert({
      isTrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      setLoading({
        isTrue: true,
        productId: product?.srv_id,
      });
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res: any) => {
        if (res?.returnCode) {
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          if (res?.returnData[0].cart_count !== undefined) {
            setEncryptedID(CART_COUNT, res?.returnData[0].cart_count);
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_count
            );
          } else {
            setEncryptedID(CART_COUNT, res?.returnData[0].cart_item_count);
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_item_count
            );
          }
          setLoading({
            isTrue: false,
            productId: "",
          });
          setIsAlert({
            isTrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setIsAlert({
            isTrue: true,
            type: "error",
            msg: res?.msg,
          });
          setLoading({
            isTrue: false,
            productId: "",
          });
        }
      });
    }
  };

  const handleScroll = () => {

    if ((window.innerHeight + document.documentElement.scrollTop + 300) < document.documentElement.offsetHeight || isLoading) {
      return;
    }

    if (fetchMore && moreLoading === false) {
      setMoreLoading(true);
      // fetchMoreData();
    }
  };

  useEffect(() => {
    try {
      if (window.innerWidth <= 768) {
        setActiveFilter(false);
      } else {
        setActiveFilter(true);

      }
    } catch (error) {
      console.error(error);
    }
  }, [window?.innerWidth])

  useEffect(() => {
    if (fetchMore && moreLoading === true) {
      fetchMoreData();
    }
  }, [moreLoading]);

  useEffect(() => {
    triggerLocalStorage();
    return () => {
      mVarOff = 0
    };
  }, []);

  useEffect(() => {
    // setFetchMore(true);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, fetchMore, brandsItems, listCheckBoxItems, productTypeItems, minValScroll, maxValScroll, isFilterEnable]);

  return (
    <div className={`${styles?.product_main}`}>
      <TopFilter
        setProductViewType={setProductViewType}
        productViewType={productViewType}
        setIsSideFilter={setIsSideFilter}
        categoryName={categoryName}
        numElement={numElement}
        FetchProductList={FetchProductList}
        manfID={manfID}
        reff={ref}
        setIsFilterEnable={setIsFilterEnable}
        setActiveFilter={setActiveFilter}
        activeFilter={activeFilter}
      />
      <div className="outer_container">
        <div>
          <div className={`${styles?.productListContainer}`}>
            <div
              className={`${styles?.grid}`}
              style={{
                // paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              {activeFilter ? (
                <div className={`${styles?.gridLeftItem}`}>
                  {sideBarLoading ? (
                    <div className={`${styles?.sidebar_loader_main}`}>
                      <ShimmerBadge width={200} />
                      <ShimmerBadge width={200} />
                      <ShimmerBadge width={200} />
                      <ShimmerBadge width={200} />
                    </div>
                  ) : (
                    <div>
                      <div className={`${styles?.filter_btn_title}`}>
                        <div className={`${styles?.filter_title}`}>
                          <h4>Filters</h4>
                        </div>
                        <div
                          className={`${styles?.filter_reset}`}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setListCheckBoxItems([]);
                            setBrandsItems([]);
                            ref?.current?.handleClear &&
                              ref?.current?.handleClear();
                            ClearProductList(
                              undefined,
                              undefined,
                              sortByScroll
                            );
                          }}
                        >
                          <a
                            draggable="false"
                            onClick={(e: any) => {
                              setIsFilterEnable(false);
                              e.stopPropagation();
                              setListCheckBoxItems([]);
                              setBrandsItems([]);
                              setProductTypeItems([]);
                              setSortByScroll(undefined);
                              ref?.current?.handleClear &&
                                ref?.current?.handleClear();
                              ClearProductList(
                                undefined,
                                undefined,
                                sortByScroll
                              );
                              setFetchMore(true)
                            }}
                            className={`${styles?.reset_all_btn}`}
                          >
                            Reset all
                          </a>

                          {false && <div className={`${styles?.filterBtnWrapper}`}>

                            <i draggable="false"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setListCheckBoxItems([]);
                                setBrandsItems([]);
                                ref?.current?.handleClear && ref?.current?.handleClear();
                                ClearProductList(
                                  undefined,
                                  undefined,
                                  sortByScroll
                                );
                              }} className="fa-solid fa-xmark"></i>

                            <i draggable="false"
                              onClick={(e: any) => {
                                FetchProductList(
                                  ref?.current?.minVal,
                                  ref?.current?.maxVal,
                                  sortByScroll
                                );
                              }} className="fa-solid fa-check"></i>

                          </div>}
                        </div>
                      </div>

                      {/* {Config?.product_Filter_SideBar?.map((section, idx, arr) => ( */}
                      {sideBarFilters?.map((section, idx, arr) => (
                        <SideFilter
                          key={section?.title + "__" + idx}
                          section={section}
                          listCheckBoxItems={listCheckBoxItems}
                          setListCheckBoxItems={setListCheckBoxItems}
                          resetPriceRange={resetPriceRange}
                          setResetPriceRange={setResetPriceRange}
                          reff={ref}
                          brandsItems={brandsItems}
                          setBrandsItems={setBrandsItems}
                          minValue={minValue}
                          maxValue={maxValue}
                          setMinValue={setMinValue}
                          setMaxValue={setMaxValue}
                          sideBarLength={sideBarLength}
                          idx={idx}
                          setProductTypeItems={setProductTypeItems}
                          productTypeItems={productTypeItems}
                          setIsFilterEnable={setIsFilterEnable}
                          setFetchMore={setFetchMore}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className={`${styles?.gridrightItem_wrapper}`}>
                {isLoading ? (
                  <div
                    className={`${styles?.gridRightItem} ${productViewType === "LIST" && styles?.listView
                      }`}
                  >
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                    <ShimmerThumbnail width={250} height={250} rounded />
                  </div>
                ) : productList.items?.length > 0 ? (
                  <>
                    <InfiniteScroll
                      style={{ overflow: "hidden" }}
                      dataLength={productList?.items?.length}
                      next={() => {

                      }}
                      hasMore={
                        productList?.items?.length <
                        productList?.items[0]?.filter_count
                      }
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          {/* <LoadingCircle
                          loadingColor={color_config?.common_bg_color}
                        /> */}
                          {/* <div className={styles?.moreProductsLoading}>
                        <i className="fa fa-spinner fa-spin"></i>
                      </div> */}
                        </div>
                      }
                      // height={"620px"}
                      endMessage={
                        <p
                          style={{
                            textAlign: "center",
                            color: color_config?.common_bg_color,
                            marginTop: "30px",
                          }}
                        >
                          <b>
                            {

                              //@ts-ignore
                              isLoading === false &&
                                productList?.items?.length === 0 ? (
                                <div style={{ height: "100vh" }}>No data found</div>
                              ) : (
                                ""
                              )}
                          </b>
                        </p>
                      }
                    >
                      <div
                        className={`${styles?.gridRightItem} ${styles?.gridRight_space} ${context?.UserAuth?.usr_type === "pro" ? styles?.progridRightItem : ""} ${productViewType === "LIST" && styles?.listView
                          }
                      
                      `}
                        // style={{
                        //   paddingTop: manfID !== undefined ? "30px" : "",
                        //   paddingLeft: manfID !== undefined ? "30px" : "",
                        //   paddingRight: manfID !== undefined ? "30px" : "",
                        // }}
                      >
                        {productList?.items?.map(
                          (product: any, i: React.Key | null | undefined) =>
                            context?.UserAuth?.usr_type === "pro" ? (
                              <ProductCardMerchant
                                key={i}
                                product={product}
                                type={product?.service_type}
                                viewType={productViewType}
                                productUrl={"/product-detail/" + product?.srv_id}
                                imgUrl={product?.srv_image.split("~")[0]}
                                imgAlt={product?.srv_name}
                                detail={product?.srv_name}
                                packagingId={product?.srv_packaging}
                                brand={product?.srv_brand}
                                price={product?.discounted_amount}
                                actualPrice={product?.srv_price}
                                discountPercent={product?.discount_percent}
                                coPay={product?.copayment_amount}
                                ratingCount={
                                  product?.rating_view_flag === "Y" &&
                                  product?.rating_star_count
                                }
                                isWishListAdded={
                                  product?.wshlst_status === "Y" ? true : false
                                }
                                packsize_json={product?.packsize_json}
                                ribbon={true}
                                ribbonType="folded"
                                onLikeButtonClick={() => {
                                  // if (isUserLoggedIn) {
                                  //   likeButtonHandler(product);
                                  // } else {
                                  //   setIsPopUPOpen(true);
                                  // }
                                }}
                                onAddtoCartButtonClick={() => {
                                  // if (isUserLoggedIn) {
                                  //   addCartHandler(product);
                                  // } else {
                                  //   setIsPopUPOpen(true);
                                  // }
                                }}
                                setIsAdding={setIsAdding}
                                setIsAlert={setIsAlert}
                                gaEventTracker={gaEventTracker}
                                suggestusInit={suggestUsInit}
                                setIsCardPopUPOpen={(input: any) => { }}
                              />
                            ) : (
                              <div
                                style={{
                                  width:
                                    productViewType === "LIST" ? "100%" : "",
                                }}
                                className={`${styles?.gridRightItem} ${productViewType === "LIST" && styles?.listView
                                  } ${styles?.customergridRightItem}`}
                              >
                                <ProductCard
                                  pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                                  product={product}
                                  productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                                  key={i}
                                  type={product?.service_type}
                                  viewType={productViewType}
                                  productUrl={product?.srv_id}
                                  imgUrl={product?.srv_image.split("~")[0]}
                                  imgAlt={product?.srv_name}
                                  detail={product?.srv_name}
                                  packagingId={product?.srv_packaging}
                                  brand={product?.srv_brand}
                                  price={product?.discounted_amount}
                                  actualPrice={product?.srv_price}
                                  discountPercent={product?.discount_percent}
                                  coPay={product?.copayment_amount}
                                  loading={loading}
                                  ratingCount={
                                    product?.rating_view_flag === "Y" &&
                                    product?.rating_star_count
                                  }
                                  isWishListAdded={
                                    product?.wshlst_status === "Y" ? true : false
                                  }
                                  ribbon={true}
                                  ribbonType="folded"
                                  onLikeButtonClick={() => {
                                    if (isUserLoggedIn) {
                                      likeButtonHandler(product);
                                    } else {
                                      setIsPopUPOpen(true);
                                    }
                                  }}
                                  onAddtoCartButtonClick={() => {
                                    if (isUserLoggedIn) {
                                      addCartHandler(product);
                                    } else {
                                      setIsPopUPOpen(true);
                                    }
                                  }}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </InfiniteScroll>
                    {moreLoading &&
                      <div className={styles?.moreProductsLoading}>
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    }
                  </>

                ) : (
                  <div className={styles?.noDatafound}>No data found</div>
                )}
              </div>
            </div>

            {(
              <SideDrawer setIsOpen={setIsSideFilter} isOpen={isSideFilter}>
                <div className={`${styles?.filterBtnWrapper}`}>
                  {/* Clear icon */}
                  <img
                    src={ClearAllIcon}
                    draggable="false"
                    alt="clear"
                    className={`${styles?.applyClearIconBtn}`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setListCheckBoxItems([]);
                      setBrandsItems([]);
                      ref?.current?.handleClear && ref?.current?.handleClear();
                      ClearProductList(undefined, undefined, undefined);
                    }}
                  />
                  {/* Apply icon */}
                  <img
                    src={ApplyIcon}
                    draggable="false"
                    alt="apply"
                    className={`${styles?.applyClearIconBtn}`}
                    onClick={(e: any) => {
                      // e.stopPropagation();
                      FetchProductList(
                        ref?.current?.minVal,
                        ref?.current?.maxVal,
                        undefined
                      );
                    }}
                  />
                </div>
                {sideBarFilters?.map((section, idx, arr) => (
                  <SideFilter
                    key={section?.title + "_" + idx}
                    section={section}
                    listCheckBoxItems={listCheckBoxItems}
                    setListCheckBoxItems={setListCheckBoxItems}
                    resetPriceRange={resetPriceRange}
                    setResetPriceRange={setResetPriceRange}
                    reff={ref}
                    brandsItems={brandsItems}
                    setBrandsItems={setBrandsItems}
                    minValue={minValue}
                    maxValue={maxValue}
                    setMinValue={setMinValue}
                    setMaxValue={setMaxValue}
                    sideBarLength={sideBarLength}
                    idx={idx}
                  />
                ))}
              </SideDrawer>
            )}
          </div>
          {/* </Loading> */}
          {isPopUpOpen && <LoginPopup setModalOpen={setIsPopUPOpen} />}
          {/* Added to cart pop up */}
          {isCartPopUpOpen && (
            <AddtocartPopup
              img={productdetails?.srv_image.split("~")[0]}
              title={productdetails?.srv_name + productdetails?.srv_packaging}
              setModalOpen={setIsCartPopUPOpen}
            />
          )}

          {isAlert?.isTrue && (
            <Alert type={isAlert?.type} info={isAlert?.msg} />
          )}
          {false && (
            <SignUpVendorContainer maxWidth={"700x"}>
              <MyList setIsAlert={setIsAlert} isAlert={isAlert} />
            </SignUpVendorContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductListScreen);
