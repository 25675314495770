import s from "./index.module.css";
import Rx from "./Rx";
import ConsentForm from "../../../../components/ConsentForm";
// import PatientDetails from "./PatientDetails";
import PatientDetails from "../TransferRxForm/PatientDetails";

const NewRxForm = (props: any) => {
  const {
    activeStep,
    practitionerType,
    setPractitionerType,
    setPractitionerId,
    setAddPractitionerAddress,
    addPractitionerAddress,
    setIsAddPractitioner,
    queryStatus,
    setNewActiveStep,
    setPatientDetails,
    setIsSigned,
    setImageURL,
    setPractitioner,
    practitioner,
    setConsentFullName,
    consentFullName,
    practitionerList,
    addPractitioner,
    register,
    control,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
    healthDetails,
    setHealthDetails,
    setUploadedDocumentIDFront,
    uploadedDocumentIDFront,
    uploadedDocumentIDBack,
    setUploadedDocumentIDBack,
    onSubmitHealthCardHandler,
    dob,
    setdob,
    dobErrorMsg,
    setDobErrorMsg,
    rxContext,
    setRxContext,
  } = props;

  const getRxForm = () => {
    switch (queryStatus) {
      case "submit":
        setNewActiveStep(0);
        return (
          <Rx
            activeStep={activeStep}
            practitionerType={practitionerType}
            setPractitionerType={setPractitionerType}
            setPractitionerId={setPractitionerId}
            setAddPractitionerAddress={setAddPractitionerAddress}
            addPractitionerAddress={addPractitionerAddress}
            practitionerList={practitionerList}
            setIsAddPractitioner={setIsAddPractitioner}
            setPractitioner={setPractitioner}
            practitioner={practitioner}
            addPractitioner={addPractitioner}
            rxContext={rxContext}
            setRxContext={setRxContext}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
          />
        );
      case "patient-details":
        setNewActiveStep(1);
        return (
          // <PatientDetails
          //   register={register}
          //   control={control}
          //   errors={errors}
          //   setValue={setValue}
          //   watch={watch}
          //   clearErrors={clearErrors}
          //   setError={setError}
          //   setPatientDetails={setPatientDetails}
          // />
          <PatientDetails
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setHealthDetails={setHealthDetails}
            healthDetails={healthDetails}
            setUploadedDocumentIDFront={setUploadedDocumentIDFront}
            uploadedDocumentIDFront={uploadedDocumentIDFront}
            setUploadedDocumentIDBack={setUploadedDocumentIDBack}
            uploadedDocumentIDBack={uploadedDocumentIDBack}
            onSubmitHealthCardHandler={onSubmitHealthCardHandler}
            dob={dob}
            setdob={setdob}
            dobErrorMsg={dobErrorMsg}
            setDobErrorMsg={setDobErrorMsg}
          />
        );

      case "consent":
        setNewActiveStep(2);
        return (
          <ConsentForm
            headerStyle={{ textAlign: "left" }}
            setIsSigned={setIsSigned}
            setImageURL={setImageURL}
            setConsentFullName={setConsentFullName}
            consentFullName={consentFullName}
          />
        );

      default:
        break;
    }
  };
  return <div className={s?.pageContainer}>{getRxForm()}</div>;
};

export default NewRxForm;
