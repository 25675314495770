import React, { useState, useEffect } from "react";
import Styles from "./index.module.css";
import SideBar from "../../../components/ProfileSideBar/SideBar";
import Button from "../../../components/UI/Button";
import Group3 from "../../../assets/svg/Group3.svg";
import {
  Delivery,
  Correct,
  TruckFilled,
  PackageBox,
} from "../../../utils/appIcons";
import { useParams } from "react-router-dom";
import Config from "../../../config/label";

const OrderDetail = (props: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { orderID } = useParams();
  const stepIconList = [Delivery, TruckFilled, PackageBox];
  const orderDetails = [
    {
      src: "https://images7.alphacoders.com/513/thumb-1920-513904.jpg",
      alt: "firstProduct",
      productName: "Magnum after burner",
      productPrice: "$50",
      productQuantity: "5pcs",
    },
    {
      src: "https://images.pexels.com/photos/208512/pexels-photo-208512.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      alt: "secondProduct",
      productName: "Carbamite forte biomide",
      productPrice: "$70",
      productQuantity: "2pcs",
    },
    {
      src: "https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/rm373batch15-217-01.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=d8bbc66e02e81095950de55fcc9347f5",
      productName: "Jardiance",
      productPrice: "$80",
      productQuantity: "8pcs",
      alt: "thirdProduct",
    },
  ];
  return (
    <div className={`${Styles?.mainContainer}`}>
      <div className={`${Styles?.grid}`}>
        <div className={`${Styles?.gridLeftItem}`}>
          <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
        <div className={`${Styles?.gridRightItem}`}>
          <div className={`${Styles?.headerContainer}`}>
            <div style={{ display: "flex" }}>
              <img
                src={Group3}
                alt="orders"
                style={{ height: "25px", width: "25px", marginTop: "7px" }}
                draggable="false"
              />
              <h2 className={`${Styles?.heading}`}>
                {Config?.orderdetails_heading}
              </h2>
            </div>

            <div className={`${Styles?.actionButtonWrapper}`}>
              <Button
                type={Config?.lbl_Btn_type_primary}
                className={`${Styles?.orderAgain}`}
                //   onClick={() => {
                //     setIsAddressModalOpen(true);
                //     setModalType("Add");
                //   }}
              >
                {Config?.orderdetails_dnld_inv}
              </Button>
              <Button
                type={Config?.lbl_Btn_type_primary}
                className={`${Styles?.orderAgain}`}
                //   onClick={() => {
                //     setIsAddressModalOpen(true);
                //     setModalType("Add");
                //   }}
              >
                {Config?.orderdetails_cnc_ord}
              </Button>

              <Button
                type={Config?.lbl_Btn_type_primary}
                className={`${Styles?.orderAgain}`}
                //   onClick={() => {
                //     setIsAddressModalOpen(true);
                //     setModalType("Add");
                //   }}
              >
                {Config?.orderdetails_ord_again}
              </Button>
            </div>
          </div>

          <div className={`${Styles?.trackerWrapper}`}>
            {stepIconList?.map((Icon, ind) => (
              <div
                id="BOX"
                style={{
                  position: "relative",
                  justifyContent: "space-evenly",
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <div
                  id="Avatar"
                  //   color: ind <= statusIndex ? 'grey.white' : 'primary.main'
                  style={{
                    backgroundColor: ind <= 1 ? "red" : "grey",
                    color: ind <= 1 ? "grey" : "red",
                    // height: 64,
                    // width: 64,
                  }}
                >
                  <Icon color="inherit" width="32" height="32" />
                </div>

                {ind < 2 && (
                  <div
                    style={{ position: "absolute", right: 0, top: 0 }}
                    id="BOX"
                  >
                    <div
                      style={{
                        height: 22,
                        width: 22,
                        backgroundColor: "grey",
                        color: "green",
                      }}
                    >
                      <Correct color="inherit" style={{ fontSize: "1rem" }} />
                    </div>
                  </div>
                )}
                {ind < 1 && (
                  <div
                    style={{
                      backgroundColor: ind <= 2 ? "red" : "grey",
                      height: 2,
                      width: "100%",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>

          <div className={`${Styles?.contentCard}`}>
            <div className={`${Styles?.contentHeader}`}>
              <p className={`${Styles?.orderWrapper}`}>
                {Config?.orderdetails_ord_id}
                <p className={`${Styles?.orderId}`}>6546545497897</p>
              </p>
              <p className={`${Styles?.orderWrapper}`}>
                {Config?.orderdetails_ord_plc}
                <p className={`${Styles?.orderId}`}>23 march,2022</p>
              </p>
              <p className={`${Styles?.orderWrapper}`}>
                {Config?.orderdetails_ord_del}{" "}
                <p className={`${Styles?.orderId}`}>24 march,2022</p>
              </p>
            </div>

            {orderDetails?.map((item) => (
              <div
                className={`${Styles?.contentWrapper}`}
                // onClick={() => navigate(item?.href)}
              >
                <div className={`${Styles?.identityWrapper}`}>
                  <img
                    src={item?.src}
                    alt={item?.alt}
                    className={`${Styles?.productImg}`}
                    draggable="false"
                  />

                  <p
                    className={`${Styles?.productName}`}
                    style={{
                      width: "180px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.productName}
                  </p>
                </div>

                <p className={`${Styles?.orderAmount}`}>{item?.productPrice}</p>
                <p className={`${Styles?.orderQuantity}`}>
                  {item?.productQuantity}
                </p>
              </div>
            ))}
          </div>

          <div className={`${Styles?.DeliveryWrapper}`}>
            <div className={`${Styles?.addressWrapper}`}>
              <h3 className={`${Styles?.addressHeader}`}>
                {Config?.orderdetails_adrs}
              </h3>
              <p className={`${Styles?.address}`}>
                Kelly Williams 777 Brockton Avenue, Abington MA 2351
              </p>
            </div>

            <div className={`${Styles?.total}`}>
              <h4 className={`${Styles?.addressHeader}`}>
                {Config?.orderdetails_sum}
              </h4>

              <div className={`${Styles?.calculationContent}`}>
                <div className={`${Styles?.calculationWrapper}`}>
                  <p className={`${Styles?.amountLabel}`}>
                    {Config?.orderdetails_stot}
                  </p>
                  <p className={`${Styles?.amount}`}>$6546</p>
                </div>
                <div className={`${Styles?.calculationWrapper}`}>
                  <p className={`${Styles?.amountLabel}`}>
                    {Config?.orderdetails_fee}
                  </p>
                  <p className={`${Styles?.amount}`}>$46</p>
                </div>
                <div className={`${Styles?.calculationWrapper}`}>
                  <p className={`${Styles?.amountLabel}`}>
                    {Config?.orderdetails_disc}
                  </p>
                  <p className={`${Styles?.amount}`}>-$60</p>
                </div>
              </div>

              <div className={`${Styles?.totalAmountWrapper}`}>
                <div className={`${Styles?.calculationWrapper}`}>
                  <p className={`${Styles?.totalAmountLabel}`}>
                    {Config?.orderdetails_tot}
                  </p>
                  <p className={`${Styles?.amount}`}>$365</p>
                </div>
                <p className={`${Styles?.paymentMode}`}>
                  {Config?.orderdetails_paymeth}
                </p>
              </div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
