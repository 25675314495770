import { useDispatch, useSelector } from "react-redux";
import { config } from "../../config/processId";
import { CallAPI } from "../../suggest-us/callSuggest";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, setEncryptedID } from "../../utils/utils";
import SideBar from "./Sidebar";
import AddNewList from "../../components/MyList/AddNewLIst";
import MyListProducts from "./MyListProducts";
import editicon from "../../assets/sf_svg/Edit.svg";
import IconButton from "../../components/UI/IconButton";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Alert from "../../components/UI/Alert";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import Input from "../../components/UI/Input";
import { searchJSONArray } from "./utils";
import SelectAll from "./SelectAll";
import { mylistPageActions } from "./slice";
import Button from "../../components/UI/Button";
import Config from "../../config/label";
import { CART_COUNT, USER_PROFILE } from "../../config/Config";

const MyListPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [proceedButtonLoading, setProceedButtonLoading] = useState(false);
  const userProfileJson = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const dispatch = useDispatch();
  const [myListRefresh, setmyListRefresh] = useState(true)
  const myLists = useSelector((state) => state?.myListPageReducer?.myLists);
  const showAddNewMyList = useSelector(
    (state) => state?.myListPageReducer?.showAddNewMyList
  );
  const [isAlert, setIsAlert] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });
  const [selectedList, setSelectedList] = useState({
    selectId : "",
    optId : "",
  });
  const [update, setUpdate] = useState(false);
  const selectAll = useSelector((state) => state.myListPageReducer.selectAll);
  const selectedCount = useSelector(
    (state) => state.myListPageReducer.selectedCount
  );
  const filteredProducts = useSelector(
    (state) => state.myListPageReducer.filteredProducts || []
  );
  const selectedPricing = useSelector(
    (state) => state?.unitPricingDropdownReducer
  );
  const [editMode, setEditMode] = useState(false);
  const products = useSelector(
    (state) => state?.myListPageReducer?.filteredProducts
  );

  useEffect(() => {
    (async () => {
      const res = await CallAPI(config?.pro_user_get_my_list_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        // sgUserId:  getID(SG_USER_ID),
        p_srv_id: "",
      });
      // setSelectedList(res?.returnData[0]?.p_list_id);
      setSelectedList((prev)=>{
          return {...prev,selectId : res?.returnData[0]?.p_list_id}
      })
      dispatch(mylistPageActions?.SET_MY_LISTS({ myLists: res.returnData }));
    })();

    setLoading(false);
  }, []);

  return (
    <div className="outer_container">
      <div className={`${styles?.myListPage}`}>
        {showAddNewMyList && (
          <AddNewList
            userProfileJson={userProfileJson}
            editMode={editMode}
            setUpdate={setUpdate}
            setIsAlert={setIsAlert}
            onClose={(newItem) => {}}
            isAlert={isAlert}
          />
        )}
        <div className={`${styles?.myListLeftContainer}`}>
          <SideBar
            setSelectedList={setSelectedList}
            myLists={myLists}
            selectedList={selectedList}
            userProfileJson={userProfileJson}
            setIsAlert={setIsAlert}
            setEditMode={setEditMode}
            editMode={editMode}
            setmyListRefresh={setmyListRefresh}
          />
        </div>
        <div className={`${styles?.myListRightContainer}`}>
          <div className={`${styles?.mylist_main_title}`}>
            {
              myLists?.find((list) => list.p_list_id === selectedList?.selectId)
                ?.p_list_title
            }
          </div>
          {products?.length > 0 && <div className={`${styles?.mylist_search_wrapper}`}>
            <Input
              placeholder="Search"
              checked={selectAll}
              onChange={(e) => {
                const input = e.target.value;
                dispatch(mylistPageActions?.SEARCH_PRODUCT({ input }));
              }}
            />
            <SelectAll selectAll={selectAll} />
          </div>}

          {/* <IconButton
            title="Edit profile"
            className={styles.editIcon}
            onClick={() => {
              dispatch(
                mylistPageActions?.EDIT_LIST({
                  showAddToMyList: true,
                  p_list_id: selectedList,
                })
              );
            }}
          >
            <img src={editicon} alt="Edit btn" draggable="false" />
          </IconButton>{" "} */}
          {loading ? (
            <ShimmerThumbnail width={750} height={300} rounded />
          ) : (
            <MyListProducts
              selectedList={selectedList}
              userProfileJson={userProfileJson}
              setSelectedList={setSelectedList}
              myLists={myLists}
              setmyListRefresh={setmyListRefresh}
              myListRefresh={myListRefresh}
            />
          )}
          { selectedList.selectId !== "" ?  products?.length > 0 && <div className={`${styles?.ProceedContainer}`}>
            <p>
              Add to cart{" "}
              {filteredProducts.filter((i) => i.isSelected === true)?.length}{" "}
              item
              {filteredProducts.filter((i) => i.isSelected === true)?.length <=
              1
                ? " "
                : "s "}{" "}
              selected from{" "}
              {
                myLists?.find((list) => list.p_list_id === selectedList?.selectId)
                  ?.p_list_title
              }
            </p>
            {filteredProducts.length > 0 && (
            <Button
              loading={proceedButtonLoading}
              onClick={() => {
                setProceedButtonLoading(true);
                (async () => {
                  const selectedProducts = filteredProducts.filter(
                    (i) => i.isSelected === true
                  );
                  if (selectedProducts.length === 0) {
                    setIsAlert({
                      isTrue: true,
                      type: "error",
                      msg: "No items selected!",
                    });
              
                    setProceedButtonLoading(false);
                    return;
                  }
              
                  const p_srv_pack_json = selectedProducts?.map((item) => {
                    return {
                      p_srv_id: item?.srv_id,
                      p_packdet_id:"",
                        // selectedPricing[item.srv_id]?.srv_pack_det_id,
                    };
                  });
                  const res = await CallAPI(
                    config.pro_user_save_items_from_my_list_process_id,
                    
                    {
                      // sgUserId: getID(SG_USER_ID),
                      // sgOrgId: localStorage.getItem("org_id"),
                      p_cus_id: userProfileJson?.customer_id,
                      p_mylist_id: selectedList?.selectId,
                      p_srv_pack_json,
                      p_internal_flag: "",
                    }
                  );
                  setProceedButtonLoading(false);
                  if (res?.returnCode === true) {
                    setIsAlert({
                      isTrue: true,
                      type: "success",
                      msg: "Item(s) added to cart!",
                    });
                    setEncryptedID(
                      CART_COUNT,
                      res?.returnData[0]?.cart_item_count
                    );
                  } else {
                    setIsAlert({
                      isTrue: true,
                      type: "error",
                      msg: "Could not save the item(s) to cart!",
                    });
                  }
                })();
              }}
            >
              {Config?.lbl_proceed}
            </Button>
             )}
          </div> : ""}
        </div>

        {/* {isAlert.isTrue && <Alert type={isAlert?.type} info={isAlert?.msg} />} */}
      </div>
    </div>
  );
};

export default MyListPage;
