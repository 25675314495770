import React, { useEffect, useState, useContext } from "react";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest";
import Styles from "./index.module.css";
import AddInsuranceHealthCard from "./AddInsuranceHealthCard";
import AddHealthCard from "./AddHealthCard";
import AddHealthInformation from "./AddHealthInformation";
import UpdateProfile from "./UpdateProfile";
import StepsDetail from "./StepDetail";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/UI/Button";
import btnConfig from "../../config/button";
import Alert from "../../components/UI/Alert";
import { config } from "../../config/processId";
import { useForm } from "react-hook-form";
import { UserAuthContext } from "../../store/UserAuthContext";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import Config from "../../config/label";
import { JSONCheckerFunc, extractCountryCodeAndNumber, getDecryptedID, getID, objDecrypt, objEncrypt, setEncryptedID } from "../../utils/utils";
import moment from "moment";
import { GlobalContext } from "../../store/global-context.js";
import { useLocation } from "react-router-dom";
import { HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, IS_PROFILE_UPDATED, PROFILE_IMAGE2, USER_PROFILE, sgUserId } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
import { parsePhoneNumber } from "react-phone-number-input";
import { msg_config } from "../../config/messages";

export type ModalProps = {
  setIsPasswordModalOpen?: any;
  setIsAlertChngPsw?: any;
  changePasswordHandler?: any;
  screenType?: any;
};

export type RegistrationFormFields = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
};

const HealthInfoCards: React.FC<ModalProps> = (props) => {
  // Edit profile states
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const [profileForm, setProfileForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    patient_photo: "",
    document_id: "",
    gender: "",
    completeness_my_address: null,
    completeness_my_health_cards: null,
    completeness_my_health_info: null,
    completeness_my_insurance_cards: null,
    completeness_my_profile: null,
  });
  const [dobErrorMsg, setDobErrorMsg] = useState(false);
  const [uploadedDocumentId, setUploadedDocumentId] = useState<any>(null);
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [profileLoading, setProfileLoading] = useState(false);
  const [genderList, setGenderList] = useState<any>([]);
  // const user_id = getID(SGUserId);
  const user_id = decryptData(getID(sgUserId));
  const [activeStep, setActiveStep] = useState(3);
  const [alert, setAlert] = useState<any>({
    active: false,
    type: "",
    msg: "",
  });

  // Insurance card states
  const [insuranceForm, setInsuranceForm] = useState({
    insurance: "",
    startDate: "",
    endDate: "",
    cardNumber: "",
    coverage: "",
    insuranceCardIDFront: "",
    insuranceCardIDBack: "",
    sequence: "",
  });


  const [uploadedFrontDocumentId, setUploadedFrontDocumentId] =
    useState<any>(null);
  const [uploadedBackDocumentId, setUploadedBackDocumentId] =
    useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [frontImage, setFrontImage] = useState<any>([]);
  const [backImage, setBackImage] = useState<any>([]);
  const [isDefault, setIsDefault] = useState<any>("1");
  const [isPrimaryCheck, setIsPrimaryCheck] = useState<any>("0");
  const [stepsLoading, setStepsLoading] = useState({
    insuranceCardLoading: false,
    healthCardLoading: false,
    healthInfoLoading: false,
  });

  // Add health card states
  const [details, setDetails] = useState<any>({
    card_num: "",
    issue_date: "",
    exp_date: "",
    front_url: "",
    front_img_id: "",
    back_url: "",
    back_img_id: "",
    healthcard_ref_id: "",
  });
  const [uploadedDocumentIDFront, setUploadedDocumentIDFront] =
    useState<any>(null);
  const [uploadedDocumentIDBack, setUploadedDocumentIDBack] =
    useState<any>(null);
  const [isEdit, setIsEdit] = useState(true);
  const [getLoading, setGetLoading] = useState(false);

  // Health info states
  const location = useLocation();
  const [formRadio, setFormRadio] = useState("");
  const [healthPageInfo, setHealthPageInfo] = useState({
    allTxt: "",
    info: "",
  });
  const [change, setChange] = useState(false);
  const [update, setUpdate] = useState(true);
  const [textAreaError, setTextAreaError] = useState(false);
  const [healthInfoData, setHealthInfoData] = useState({
    allergies_flag: "",
    allergies_medications: "",
    counter_supplements: "",
    last_modify_timestamp: "",
  });
  const [healthInfoLoading, setHealthInfoLoading] = useState(true);
  const [incompleteSteps, setIncompleteSteps] = useState<any>([]);

  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  let newUserProfileJson = userProfileJson;
  const [isAlert, setIsAlert] = useState<any>({
    istrue: false,
    type: "",
    msg: "",
  });

  const { setIsPasswordModalOpen, screenType } = props;

  const navigate = useNavigate();
  const [context, setContext] = useContext(UserAuthContext);

  const [suggestUsInit] = useContext(APIContext);
  const gaEventTracker = useAnalyticsEventTracker("Forgot password screen");

  const handleNext = () => {

    setIsPasswordModalOpen(false);
  };

  useEffect(() => {
    if (alert?.active === true) {
      setTimeout(
        () =>
          setAlert((prev: any) => {
            return {
              ...prev,
              active: false,
              type: "",
              msg: "",
            };
          }),
        2000
      );
    }
  }, [alert]);


  const onBackHandler = (e: any) => {
    e.preventDefault();
    // setIncompleteSteps((prev: any) => [...prev, activeStep]);
    // checkSteps();
    if (activeStep === 3) {
      setIsPasswordModalOpen(false);
      setEncryptedID(IS_PROFILE_UPDATED, "Y");
    } else {
      setActiveStep(activeStep + 1);
    }

    // activeStep !== 0 && handleBack();
    // if (activeStep === 0) {
    //   setIsPasswordModalOpen(false);
    //   navigate("/log-in");
    // }
  };

  const resetAlert = () => {
    setAlert((prev: any) => {
      return {
        ...prev,
        active: false,
        type: "",
        msg: "",
      };
    });
  };

  const getInsurance = () => {
    if (suggestUsInit?.returnCode === true) {
      setStepsLoading((prev) => {
        return { ...prev, insuranceCardLoading: true };
      });
      CallAPI(config?.get_customer_insurance_process_id, {
        p_insurance_ref_id: "",
        p_patient_id: userProfileJson?.patient_id,
        p_org_id: userProfileJson?.org_id,
      }).then((res) => {
        if (res?.returnCode) {
          setStepsLoading((prev) => {
            return { ...prev, insuranceCardLoading: false };
          });
          let filterPrimaryCard: any = [];
          filterPrimaryCard = res?.returnData?.filter(
            (val: any) => val?.sequence === "1"
          );

          if (filterPrimaryCard?.length > 0) {
            setEncryptedID(
              INSURANCE_BACK_ID,
              filterPrimaryCard?.[0]?.insurance_front_id || ""
            );
            setEncryptedID(
              INSURANCE_BACK_ID,
              filterPrimaryCard?.[0]?.insurance_back_id || ""
            );
            setInsuranceForm((prev: any) => {
              return {
                ...prev,
                insuranceCardIDFront:
                  filterPrimaryCard?.[0]?.insurance_front_id,
                insuranceCardIDBack: filterPrimaryCard?.[0]?.insurance_back_id,
                sequence: filterPrimaryCard?.[0]?.sequence,
              };
            });
            if (filterPrimaryCard?.[0]?.insurance_front_id !== "") {
              setUploadedFrontDocumentId(
                filterPrimaryCard?.map((item: any) => item?.insurance_front_id)
              );
            } else {
              setUploadedFrontDocumentId(null);
            }

            if (filterPrimaryCard?.[0]?.insurance_back_id !== "") {
              setUploadedBackDocumentId(
                filterPrimaryCard?.map((item: any) => item?.insurance_back_id)
              );
            } else {
              setUploadedBackDocumentId(null);
            }
            setFrontImage(filterPrimaryCard?.[0]?.insurance_front);
            setBackImage(filterPrimaryCard?.[0]?.insurance_back);
            if (filterPrimaryCard?.[0]?.primary_card_holder === "Y") {
              setIsPrimaryCheck("1");
            } else {
              setIsPrimaryCheck("0");
            }
          } else {
            setInsuranceForm({
              insurance: "",
              startDate: "",
              endDate: "",
              cardNumber: "",
              coverage: "",
              insuranceCardIDFront: "",
              insuranceCardIDBack: "",
              sequence: "",
            });
            setUploadedFrontDocumentId(null);
            setUploadedBackDocumentId(null);
            setIsPrimaryCheck("0");
          }
        }
        // setIsLoading(false);
      });
    }
  };

  const onInsuranceSubmitHandler = () => {
    // resetAlert();
    if (
      insuranceForm?.insuranceCardIDFront === "" &&
      uploadedFrontDocumentId === null
    ) {
      // setAlert(true)
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload front side image of insurance card!",
        };
      });
    } else if (
      uploadedFrontDocumentId === null &&
      insuranceForm?.insuranceCardIDFront === ""
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload front side image of insurance card!",
        };
      });
    } else if (
      insuranceForm?.insuranceCardIDBack === "" &&
      uploadedBackDocumentId === null
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload back side image of insurance card!",
        };
      });
    } else if (
      uploadedBackDocumentId === null &&
      insuranceForm?.insuranceCardIDBack === ""
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload back side image of insurance card!",
        };
      });
    } else if (suggestUsInit?.returnCode) {
      setButtonLoading(true);
      CallAPI(config?.save_insurance_process_id, {
        // p_ptmpln_id: modalType === "Edit" ? newArry?.insurance_ref_id : "",
        p_ptmpln_id: "",
        p_patient_id: userProfileJson?.patient_id,
        p_insurance_id: "",
        p_ppln_id: "",
        p_org_id: userProfileJson?.org_id,
        p_ptmpln_sequence: isDefault,
        p_primary_card_holder: isPrimaryCheck,
        p_ptmpln_status: "Y",
        p_ptmpln_membership_number: "",
        // p_ptmpln_date_from:
        //   data?.startDate === "undefined"
        //     ? newArry?.ptmpln_date_from
        //     : data?.startDate,
        p_ptmpln_date_from: "",
        // p_ptmpln_date_to:
        //   data?.endDate === "undefined"
        //     ? newArry?.ptmpln_date_to
        //     : data?.endDate,
        p_ptmpln_date_to: "",
        p_insurance_front_image_document_id:
          insuranceForm?.insuranceCardIDFront || uploadedFrontDocumentId?.[0],
        p_insurance_back_image_document_id:
          insuranceForm?.insuranceCardIDBack || uploadedBackDocumentId?.[0],
      }).then((res) => {
        if (res?.returnCode) {
          handleNext();
          setButtonLoading(false);
          //   setIsInsuranceModalOpen(false);
          getInsurance();
          setIsDefault("1");
        } else {
          setButtonLoading(false);
          console.error(res?.error);
        }
      });
    }
  };

  const getHealthCardInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      setStepsLoading((prev) => {
        return { ...prev, healthCardLoading: true };
      });
      setGetLoading(true);
      CallAPI(config?.get_health_card_process_id, {
        p_patient_id: userProfileJson?.patient_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          // setValue("card_num", `${res?.returnData[0]?.healthcard_no || ""}`);
          // setValue(
          //   "issue_date",
          //   `${res?.returnData[0]?.healthcard_issue_date || ""}`
          // );
          // setValue(
          //   "exp_date",
          //   `${res?.returnData[0]?.healthcard_expiry_date || ""}`
          // );
          setEncryptedID(
            HEALTH_CARD_FRONT_ID,
            res?.returnData[0]?.healthcard_front_id || ""
          );
          setEncryptedID(
            HEALTH_BACK_ID,
            res?.returnData[0]?.healthcard_back_id || ""
          );
          setDetails((prev: any) => {
            return {
              ...prev,
              issue_date: `${res?.returnData[0]?.healthcard_issue_date || ""}`,
              exp_date: `${res?.returnData[0]?.healthcard_expiry_date || ""}`,
              card_num: `${res?.returnData[0]?.healthcard_no || ""}`,
              front_url: `${res?.returnData[0]?.healthcard_front || ""}`,
              front_img_id: `${res?.returnData[0]?.healthcard_front_id || ""}`,
              back_url: `${res?.returnData[0]?.healthcard_back || ""}`,
              back_img_id: `${res?.returnData[0]?.healthcard_back_id || ""}`,
              healthcard_ref_id: `${res?.returnData[0]?.healthcard_ref_id || ""
                }`,
            };
          });
          setGetLoading(false);
          setStepsLoading((prev) => {
            return { ...prev, healthCardLoading: false };
          });
          setIsEdit(true);
        }
      });
    }
  };

  const onSubmitHealthCardHandler = (data: any) => {
    if (
      details?.front_img_id === "" &&
      uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id === undefined
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload front side image of health card!",
        };
      });
    } else if (
      details?.back_img_id === "" &&
      uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id === undefined
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload back side image of health card!",
        };
      });
    } else if (suggestUsInit?.returnCode === true) {
      setButtonLoading(true);
      CallAPI(config?.add_health_card_process_id, {
        p_id:
          details?.healthcard_ref_id !== "" ? details?.healthcard_ref_id : "",
        p_user_id: userProfileJson?.usr_id,
        p_customer_id: userProfileJson?.customer_id,
        p_healthcard_no: data?.card_num || "",
        // p_healthcard_issue_date:
        //   data?.issue_date !== undefined
        //     ? moment(data?.issue_date).format("YYYY-MM-DD")
        //     : "",
        p_healthcard_issue_date: "",
        // p_healthcard_expiry_date:
        //   data?.exp_date !== undefined
        //     ? moment(data?.exp_date).format("YYYY-MM-DD")
        //     : "",
        p_healthcard_expiry_date: "",
        p_healthcard_front_image_document_id:
          details?.front_img_id ||
          uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id,
        p_healthcard_back_image_document_id:
          details?.back_img_id ||
          uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id,
        p_internal_flag: "",
      }).then((res) => {
        setIsEdit(false);
        setButtonLoading(false);
        if (res?.returnCode) {
          handleNext();
          // reset();
          getHealthCardInfo();
          // setSaveLoading(false);
        } else {
          setAlert((prev: any) => {
            return {
              ...prev,
              active: true,
              type: "error",
              msg: "Health card is not updated successfully!",
            };
          });
        }
      });
    }
  };

  const getHealthInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      setStepsLoading((prev) => {
        return { ...prev, healthInfoLoading: true };
      });
      CallAPI(config?.get_health_info_process_id, {
        p_user_id: userProfileJson?.usr_id,
        p_customer_id: userProfileJson?.customer_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          setHealthInfoData({
            allergies_flag: res?.returnData?.[0]?.allergies_flag,
            allergies_medications: res?.returnData?.[0]?.allergies_medications,
            counter_supplements: res?.returnData?.[0]?.counter_supplements,
            last_modify_timestamp: res?.returnData?.[0]?.last_modify_timestamp,
          });
          setFormRadio(res?.returnData?.[0]?.allergies_flag);
          // setAllTxt(res?.returnData?.[0]?.allergies_medications);
          // setFormTxt(res?.returnData?.[0]?.counter_supplements);
          setHealthPageInfo((prev: any) => {
            return {
              allTxt: res?.returnData?.[0]?.allergies_medications || "",
              info: res?.returnData?.[0]?.counter_supplements || "",
            };
          });

          setEncryptedID(
            HEALTH_INFO,
            res?.returnData?.[0]?.counter_supplements || ""
          );
          // setChange(true);
          setHealthInfoLoading(false);
          setStepsLoading((prev) => {
            return { ...prev, healthInfoLoading: false };
          });
        }
      });
    }
  };

  const checkArr = [
    insuranceForm?.insuranceCardIDFront === ""
      ? null
      : insuranceForm?.insuranceCardIDFront,
    insuranceForm?.insuranceCardIDBack === ""
      ? null
      : insuranceForm?.insuranceCardIDBack,
    uploadedFrontDocumentId,
    uploadedBackDocumentId,
  ];

  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };

  const userProfileIncomplete = [
    sessionStorageCopy?.usr_email,
    sessionStorageCopy?.usr_name,
    sessionStorageCopy?.usr_phone,
    sessionStorageCopy?.usr_dob,
  ];


  const steps: any = [
    {
      label: `${Config?.Edit_profile_head_text}`,
      description: `${Config?.Edit_Profile_detail_text}`,
      checkStep:
        userProfileIncomplete?.includes("") ||
          userProfileIncomplete?.includes(null)
          ? "Incomplete"
          : "Complete",
    },
    // {
    //   label: `${Config?.Insurance_card_head_text}`,
    //   description: `${Config?.Insurance_card_head_detail_text}`,
    //   checkStep: checkArr?.includes(null) ? "Incomplete" : "Complete",
    // },
    // {
    //   label: `${Config?.Health_card_head_text}`,
    //   description: `${Config?.Health_card_head_detail_text}`,
    //   checkStep: [
    //     details?.back_img_id === undefined ? "" : details?.back_img_id,
    //     details?.front_img_id === undefined ? "" : details?.front_img_id,
    //   ]?.includes("")
    //     ? "Incomplete"
    //     : "Complete",
    // },

    // {
    //   label: `${Config?.Health_info_head_text}`,
    //   description: `${Config?.Health_info_head_detail_text}`,
    //   checkStep: healthPageInfo?.info === "" ? "Incomplete" : "Complete",
    // },
  ];

  const HealthInfoSubmitHandler = (e: any) => {
    let checkIncomplete = steps?.find(
      (item: any) => item?.checkStep === "Incomplete"
    );

    //Start
    // if (healthPageInfo?.info === "") {
    //   setTextAreaError(true);
    // } else 
    if (formRadio === "Y") {
      if (suggestUsInit?.returnCode === true) {
        setButtonLoading(true);
        setTextAreaError(false);
        CallAPI(config?.add_update_health_info_process_id, {
          p_user_id: userProfileJson?.usr_id,
          p_customer_id: userProfileJson?.customer_id,
          p_allergies_flag: formRadio,
          p_allergies_medications: healthPageInfo?.allTxt,
          p_counter_supplements: healthPageInfo?.info,
        }).then((res) => {
          setButtonLoading(false);
          if (res?.returnCode === true) {
            getHealthInfo();
            setUpdate(false);
            setIsPasswordModalOpen(false);
            setEncryptedID(IS_PROFILE_UPDATED, "Y");
            //Check screenType to navigate to next screen after finish and check for incomplete steps
            if (screenType === "Cart") {
              if (checkIncomplete !== undefined) {
                navigate(location.pathname + location.search);
              } else {
                navigate("/purchase/checkout");
                gaEventTracker("Checkout clicks");
              }
            } else {
              navigate(location.pathname + location.search);
            }

            setHealthPageInfo((prev) => {
              return {
                allTxt: "",
                info: "",
              };
            });
            setFormRadio("");
          }
        });
      }
    } else {
      if (suggestUsInit?.returnCode === true) {
        setButtonLoading(true);
        setTextAreaError(false);
        CallAPI(config?.add_update_health_info_process_id, {
          p_user_id: userProfileJson?.usr_id,
          p_customer_id: userProfileJson?.customer_id,
          p_allergies_flag: formRadio,
          p_allergies_medications: "",
          p_counter_supplements: healthPageInfo?.info,
        }).then((res) => {
          setTextAreaError(false);
          if (res?.returnCode === true) {
            getHealthInfo();
            setUpdate(false);
            setIsPasswordModalOpen(false);
            setEncryptedID(IS_PROFILE_UPDATED, "Y");

            //Check screenType to navigate to next screen after finish and check for incomplete steps
            if (screenType === "Cart") {
              if (checkIncomplete !== undefined) {
                navigate(location.pathname + location.search);
              } else {
                navigate("/purchase/checkout");
                gaEventTracker("Checkout clicks");
              }
            } else {
              navigate(location.pathname + location.search);
            }

            setHealthPageInfo((prev) => {
              return {
                allTxt: "",
                info: "",
              };
            });
            setFormRadio("");
          }
        });
      }
    }
  };

  const submitRecoverPassword = (data: any) => {
    switch (activeStep) {
      case 1:
        onInsuranceSubmitHandler();
        break;

      case 2:
        onSubmitHealthCardHandler("");
        break;

      case 3:
        HealthInfoSubmitHandler("");
        break;

      default:
        break;
    }
  };

  const getCardsComponent = () => {
    // UpdateProfile
    switch (activeStep) {
      // case 0:
      //   return <UpdateProfile editHeading={Config?.Edit_profile_head_text} />;

      case 1:
        return (
          <AddInsuranceHealthCard
            insuranceForm={insuranceForm}
            setInsuranceForm={setInsuranceForm}
            uploadedFrontDocumentId={uploadedFrontDocumentId}
            setUploadedFrontDocumentId={setUploadedFrontDocumentId}
            uploadedBackDocumentId={uploadedBackDocumentId}
            setUploadedBackDocumentId={setUploadedBackDocumentId}
            loading={loading}
            setLoading={setLoading}
            isDefault={isDefault}
            setIsDefault={setIsDefault}
            frontImage={frontImage}
            setFrontImage={setFrontImage}
            backImage={backImage}
            setBackImage={setBackImage}
            getInsurance={getInsurance}
            isPrimaryCheck={isPrimaryCheck}
            setIsPrimaryCheck={setIsPrimaryCheck}
          />
        );

      case 2:
        return (
          <AddHealthCard
            details={details}
            setDetails={setDetails}
            uploadedDocumentIDFront={uploadedDocumentIDFront}
            setUploadedDocumentIDFront={setUploadedDocumentIDFront}
            uploadedDocumentIDBack={uploadedDocumentIDBack}
            setUploadedDocumentIDBack={setUploadedDocumentIDBack}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            getHealthCardInfo={getHealthCardInfo}
            getLoading={getLoading}
            setGetLoading={setGetLoading}
          />
        );

      case 3:
        return (
          <AddHealthInformation
            formRadio={formRadio}
            setFormRadio={setFormRadio}
            healthPageInfo={healthPageInfo}
            setHealthPageInfo={setHealthPageInfo}
            change={change}
            setChange={setChange}
            update={update}
            setUpdate={setUpdate}
            textAreaError={textAreaError}
            setTextAreaError={setTextAreaError}
            getHealthInfo={getHealthInfo}
            healthInfoData={healthInfoData}
            setHealthInfoData={setHealthInfoData}
            loading={healthInfoLoading}
            setLoading={setHealthInfoLoading}
          />
        );

      default:
        break;
    }
  };

  const getSkipButton = () => {
    return (
      <Button
        type={Config?.lbl_Btn_type_ghost}
        size={Config?.lbl_Btn_size_medium}
        onClick={onBackHandler}
        className={`${Styles?.skipBtnClr}`}
      >
        {/* {btnConfig?.signUp_Ghost_Text_Back} */}
        Skip
      </Button>
    );
  };

  const getNextButton = () => {
    return (
      <Button
        type={Config?.lbl_Btn_type_primary}
        size={Config?.lbl_Btn_size_medium}
        loading={buttonLoading}
        onClick={() => {
          resetAlert();
          submitRecoverPassword("");
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: `${activeStep !== 3 ? "space-around" : "center"}`,
            alignItems: "center",
            width: "100%",
            padding: "0 .5rem",
          }}
        >
          {activeStep !== 3 ? "Next" : "Finish"}
        </div>
      </Button>
    );
  };

  const userInfohandler = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.my_profile_process_id, {
        p_user_id: user_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          let phoneNumber = extractCountryCodeAndNumber(res?.returnData[0]?.usr_phone || "")
          setProfileForm((prev: any) => {
            return {
              ...prev,
              firstName:
                res?.returnData[0]?.first_name === ""
                  ? ""
                  : res?.returnData[0]?.first_name,
              lastName:
                res?.returnData[0]?.last_name === ""
                  ? ""
                  : res?.returnData[0]?.last_name,
              phone: phoneNumber,
              email:
                res?.returnData[0]?.usr_email === ""
                  ? ""
                  : res?.returnData[0]?.usr_email,
              dob:
                res?.returnData[0]?.usr_dob === null
                  ? undefined
                  : res?.returnData[0]?.usr_dob,
              patient_photo: res?.returnData[0]?.patient_img_path,
              document_id:
                res?.returnData[0]?.customer_profile_picture_document_id,
              gender:
                res?.returnData[0]?.usr_gender_code === null
                  ? undefined
                  : res?.returnData[0]?.usr_gender_code,
              completeness_my_address:
                res?.returnData[0]?.completeness_my_address,
              completeness_my_profile:
                res?.returnData[0]?.completeness_my_profile,
              completeness_my_health_cards:
                res?.returnData[0]?.completeness_my_health_cards,
              completeness_my_health_info:
                res?.returnData[0]?.completeness_my_health_info,
              completeness_my_insurance_cards:
                res?.returnData[0]?.completeness_my_insurance_cards,
            };
          });

          setEncryptedID(PROFILE_IMAGE2, res?.returnData[0]?.patient_img_path || "");
          setLoading(false);
          setChange(false);
          setGlobalContext((prev: any) => {
            return {
              ...prev,
              profile_image: res?.returnData[0]?.patient_img_path,
            };
          });
        } else console.error("error", res?.error);
      });
    }
  };

  const getGenderList = () => {
    if (suggestUsInit?.returnCode === true) {
      setProfileLoading(true);
      CallAPI(config?.gender_dropdown_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setGenderList([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.code,
                label: item?.description,
              };
            }),
          ]);
          var list: any = [
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.code,
                label: item?.description,
              };
            }),
          ];

          // setValue(
          //   "gender",
          //   list.find((i: any) => i?.value === profileForm?.gender)
          // );
          setProfileLoading(false);
        }
      });
    }
  };

  const onProfileSubmitHandler = (data: any) => {
    if (
      profileForm?.dob === "Invalid date" ||
      profileForm?.dob === "" ||
      profileForm?.dob === undefined
    ) {
      setDobErrorMsg(true);
    } else if (suggestUsInit?.returnCode === true && context?.phLength >= 10) {
      setDobErrorMsg(false);
      setLoading(true);
      const fullMobileNumber = parsePhoneNumber(`${data?.phone}`)
      const fullMobileNumberUpdated = fullMobileNumber?.country ? `${fullMobileNumber?.country} +${fullMobileNumber?.countryCallingCode} ${fullMobileNumber?.nationalNumber}` : "";
      if(fullMobileNumberUpdated === ""){
        alert(msg_config?.phone_valid)
      }else {
        CallAPI(config?.update_my_profile_process_id, {
          p_customer_id: newUserProfileJson?.customer_id,
          p_customer_dob:
            profileForm?.dob === "Invalid date"
              ? ""
              : moment(profileForm?.dob).format("YYYY-MM-DD"),
          p_customer_first_name: data?.firstName,
          p_customer_last_name: data?.lastName,
          p_customer_email: data?.email,
          p_customer_phone_no: fullMobileNumberUpdated,
          p_patient_gender: data?.gender.value,
          p_customer_img_document_id:
            (uploadedDocumentId?.length > 0 && uploadedDocumentId[0]) ||
            profileForm?.document_id ||
            "",
        }).then((res) => {
          if (res?.returnCode === true) {
            newUserProfileJson = res?.returnData[0];
            setEncryptedID(USER_PROFILE, res?.returnData[0]);
            setEncryptedID(IS_PROFILE_UPDATED, "Y");
            userInfohandler();
            getGenderList();
            // setIsEditModalOpen(false);
            // setModalOpen(false);
            setLoading(false);
            handleNext();
          } else {
            setLoading(false);
            console.error("error", res?.error);
          }
        });
      }
    } else if (context?.phLength < 10) {
      setError("phone", {
        type: "required",
        message: "Invalid phone number",
      });
    }
  };

  useEffect(() => {
    getInsurance();
    getHealthCardInfo();
    getHealthInfo();
    userInfohandler();
    getGenderList();
    // checkFirstRenderSteps();
  }, [suggestUsInit]);

  useEffect(() => {
    if (activeStep === 2) {
      getInsurance();
    }
    if (activeStep === 3) {
      getHealthCardInfo();
    }
  }, [activeStep]);

  return (
    <div className={`${Styles?.modalBackground}`}>
      <div className={`${Styles?.modalContainer}`}>
        {/* <button
          className={`${Styles?.titleCLoseBtn} ${Styles["menuLinks"]}`}
          draggable="false"
          onClick={() => {
            setIsPasswordModalOpen(false)
            setEncryptedID(IS_PROFILE_UPDATED, "Y");
          }
          }
        >
          {Config?.skip_all_steps_text}
          <p className={`${Styles?.underlineGhost}`} />
        </button> */}

        <h3 className={`${Styles?.forgotPasswordHeading}`}>
          {Config?.profile_setup_modal_label}
        </h3>
        {/* <form onSubmit={handleSubmit(submitRecoverPassword)}> */}
        <div className={`${Styles?.forgetPassword}`}>
          {/* <div className={`${Styles?.forgetPasswordTop}`}> */}
          {/* <StepsDetail
              steps={steps}
              activeStep={activeStep}
              incompleteSteps={incompleteSteps}
              setActiveStep={setActiveStep}
              details={details}
              healthPageInfo={healthPageInfo}
              insuranceForm={insuranceForm}
              uploadedFrontDocumentId={uploadedFrontDocumentId}
              uploadedBackDocumentId={uploadedBackDocumentId}
              stepsLoading={stepsLoading}
              setStepsLoading={setStepsLoading}
            // setStepsCheck={setStepsCheck}
            /> */}
          {/* </div> */}
          {activeStep === 3 ? (
            <div className={`${Styles?.forgetPasswordBottom}`}>
              {/* {getCardsComponent()} */}
              <form onSubmit={handleSubmit(onProfileSubmitHandler)}>
                <UpdateProfile
                  editHeading={Config?.Edit_profile_head_text}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  profileForm={profileForm}
                  setProfileForm={setProfileForm}
                  dobErrorMsg={dobErrorMsg}
                  setDobErrorMsg={setDobErrorMsg}
                  uploadedDocumentId={uploadedDocumentId}
                  setUploadedDocumentId={setUploadedDocumentId}
                  genderList={genderList}
                  setGenderList={setGenderList}
                  getGenderList={getGenderList}
                  loading={profileLoading}
                  setLoading={setProfileLoading}
                />
              </form>
              <div className={`${Styles?.page_buttons}`}>
                <Button
                  type={Config?.lbl_Btn_type_ghost}
                  size={Config?.lbl_Btn_size_medium}
                  onClick={onBackHandler}
                  className={`${Styles?.skipBtnClr}`}
                // className={`${Styles?.skipFormButton}`}
                >
                  {/* {btnConfig?.signUp_Ghost_Text_Back} */}
                  Skip
                </Button>
                <Button
                  type={Config?.lbl_Btn_type_primary}
                  htmlType={Config?.lbl_Btn_action_submit}
                  size={Config?.lbl_Btn_size_medium}
                  // style={{ marginLeft: "250px", marginBottom: "-3px" }}
                  loading={loading}
                  // className={`${Styles?.nextFormButton}`}
                  onClick={handleSubmit(onProfileSubmitHandler)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: `${activeStep < 3 ? "space-around" : "center"
                        }`,
                      alignItems: "center",
                      width: "100%",
                      padding: "0 .5rem",
                    }}
                  >
                    {activeStep < 3 ? "Next" : "Finish"}
                  </div>
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className={`${Styles?.forgetPasswordBottom}`}>
                {/* {getCardsComponent()} */}
              </div>
              <div className={`${Styles?.page_buttons}`}>
                {" "}
                {getSkipButton()}
                {getNextButton()}
              </div>
            </>
          )}
        </div>
        {/* </form> */}
      </div>

      {alert?.active && <Alert type={alert?.type} info={alert?.msg} />}
    </div>
  );
};

export default HealthInfoCards;
