import React, { useContext } from 'react'
import Footer from '../../components/Footer';
import HomeLayout from '../../layouts/HomeLayout';
import Styles from "./index.module.css";
import { UserAuthContext } from '../../store/UserAuthContext';
import Header from '../../components/Header';
import { triggerLocalStorage } from '../../utils/utils';

function Privacy(props:any) {
    const {isPopup, setOpenTermModalPopup} = props;
    const [context, setContext] = useContext(UserAuthContext);
    
    triggerLocalStorage();

  return (
    <div className={`${isPopup?.isTrue ? Styles?.pageContainerIfPopup  :Styles?.pageContainer }`}>
     {isPopup ? "" :  <Header/>}
      <div className="outer_container">
        <div className={`${Styles?.detailsContainer}`}>
          <div className={`${Styles?.detailsContainer_1}`}>
            <div className={`${Styles?.det_title}`}>
            {isPopup?.isTrue ? 
            <>
            <p>Privacy Policy</p>
            <span
            onClick={()=>{
              setContext((prev:any)=>{
                return {...prev, termsModal  : {
                  isTrue: false,
                  type : "privacy"
                }}
              })
            }}
            ><i className="fa-solid fa-xmark"></i></span>
            </>
            : "Privacy Policy"}
            </div>
            <div className={`${Styles?.det_prv}`}>
              <div className={`${Styles?.det_prv_title} ${Styles?.dev_prv_overview}`}>
              OVERVIEW
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                {/* todo */}
              At <a href="www.safepharmacy.in">www.safepharmacy.in</a> we respect your privacy and take great care in protecting your personal information. We are committed to compliance with privacy legislation and protecting personal information that we collect, use and disclose when you use our services. We, at <a href="www.safepharmacy.in">www.safepharmacy.in</a>, have based our privacy policy on the Canadian Standard Association’s Model Code for the Protection of Personal Information and incompliance with British Columbia’s Personal Information Protection Act (PIPA)which came into effect on January 1, 2004. All information collected will also adhere to the terms established in the Personal Information Protection and Electronic Documents Act of Canada. Information obtained will be collected as required or permitted by applicable law by fair and lawful means.
              </div>
              <div className={`${Styles?.det_prv_title}`}>
              ACCEPTANCE OF THIS PRIVACY POLICY AND CHANGES TO IT
              </div>
              <div className={`${Styles?.det_prv_det}`}>
              By submitting information to us, including your personal information, you consent to the collection and use of your information in accordance with this Privacy Notice. If you do not want to disclose your information in the ways described in this Privacy Notice, you should not use our Services. 
              </div>
              <div className={`${Styles?.det_prv_det}`}>
              Our Privacy Officer is accountable for maintaining our privacy program to ensure it complies with applicable legislation. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of your personal information and allowing you to request access to, and correction of, your personal information.
              </div>
              <div className={`${Styles?.det_prv_title}`}>
              DEFINITIONS
              </div>
              <div className={`${Styles?.det_prv_det}`}>
              For the purposes of this Privacy Policy:
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Account" </span>
                means a unique account created for You to access our Service or parts of our Service.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Company" </span>
                (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Guildford Drugs Ltd. or “www.safepharmacy.in”, Unit # 200 – 15135 101 Ave, Surrey, BC V3R7Z1.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Cookies" </span>
                are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website, along with other information.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Country" </span>
                refers to Canada.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Device" </span>
                means any device that can access the Service, such as a computer, a cell phone or a digital tablet.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Personal Data" </span>
                is any information that relates to an identified or identifiable individual.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Service" </span>
                                                                  {/* todo */}
                refers to Guildford Drugs Ltd, accessible from <a href="www.safepharmacy.in">www.safepharmacy.in</a>.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Service Provider" </span>
                means any natural or legal person who processes Personal Data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Third-party Social Media Service" </span>
                refers to any website or any social network website through which a user can log in or create an account to use the Service.
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"Usage Data" </span>
                refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </div>
              <div className={`${Styles?.det_prv_det}`}>
                <span className={`${Styles?.det_prv_det_span}`}>"You" </span>
                means the individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </div>
              <div className={`${Styles?.det_prv_list}`}>
                <ol type="1">
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>COLLECTING AND USING YOUR PERSONAL DATA</div>
                    <div className={`${Styles?.det_prv_det}`}>Types of Data Collected</div>
                    <ol style={{listStyle: "none"}}>
                      <li>
                        <div className={`${Styles?.det_prv_det}`}>
                          (i)<span className={`${Styles?.det_prv_det_span}`}> Personal Data </span>
                          - While using the Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                        </div>
                        <ul style={{listStyle: "disc"}}>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            Name, email address, residential address, date of birth, personal health number, telephone number, geo-location, health history, medication and/or prescription information, gender, payment card information, name and contact info of your primary care physician, age, physical and mental health information, drug allergies, medications requested, insurance information, occupation or employment status. Email address;
                            </div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            Usage Data; and
                            </div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            Other information that is necessary for Us to provide You with the Service and products as described on the Service.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        (ii)<span className={`${Styles?.det_prv_det_span}`}> Usage Data </span>
                        - Usage Data is collected automatically when You use the Service.  Usage Data may include information such as:
                        </div>
                        <ul style={{listStyle: "disc"}}>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            Your Device's Internet Protocol address (e.g. IP address), browser type, browser version;
                            </div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            The pages of the Service that You visit, the time and date of Your visit, the time spent on those pages; and
                            </div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>
                            Unique Device identifiers and other diagnostic data.
                            </div>
                          </li>
                        </ul>
                        <div className={`${Styles?.det_prv_det}`}>
                        When You access the Service by or through a mobile Device, We may collect certain information automatically, including, but not limited to, the type of mobile Device You use, Your mobile Device unique ID, the IP address of Your mobile Device, Your mobile operating system, the type of mobile internet browser You use, unique Device identifiers and other diagnostic data.
                        </div>
                        <div className={`${Styles?.det_prv_det}`}>
                        We may also collect information that Your browser sends whenever You use the Service or when You access the Service by or through a mobile Device.
                        </div>
                      </li>
                      <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        (iii)<span className={`${Styles?.det_prv_det_span}`}> We may use the personal information we collect from you in order to fulfill the following purposes: </span>
                        </div>
                        <ul style={{listStyle: "disc"}}>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>Account creation</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To contact or connect you with a pharmacist as necessary</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To facilitate assessment by a pharmacist</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To enable a pharmacist to contact you as necessary</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To provide technical assistance or respond to inquiries</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To provide updates and notifications relating to our services</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To provide updates and notification regarding your prescription</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To obtain additional information as needed</div>
                          </li>
                          <li>
                            <div className={`${Styles?.det_prv_det}`}>To verify your identity and eligibility</div>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>CONSENT</div>
                    <div className={`${Styles?.det_prv_det}`}>
                    Your knowledge and consent are required to collect, use, or disclose your personal information, except as required or permitted by applicable law.
                    </div>
                    <ol style={{listStyle: "none"}}>
                      <li>
                      <div className={`${Styles?.det_prv_det}`}>
                      (i) We will obtain your consent to collect, use or disclose personal information (except where, as noted below, we are authorized to do so without consent).
                      </div>
                      </li>
                      <li>
                      <div className={`${Styles?.det_prv_det}`}>
                      (ii) Consent can be provided orally or electronically or it can be implied where the purpose for collecting, using or disclosing the personal information would be considered obvious and you voluntarily provide personal information for that purpose.
                      </div>
                      </li>
                      <li>
                      <div className={`${Styles?.det_prv_det}`}>
                      (iii) Consent may also be implied where you are given notice and a reasonable opportunity to opt-out of  your personal information being used for the marketing of new services or products and you do not opt-out.
                      </div>
                      </li>
                      <li>
                      <div className={`${Styles?.det_prv_det}`}>
                      (iv) Subject to certain exceptions (e.g., the personal information is necessary to provide the service or product, or the withdrawal of consent would frustrate the performance of a legal obligation),you can withhold or withdraw your consent for <a href="www.safepharmacy.in">www.safepharmacy.in</a> to use your personal information in certain ways. Your decision to withhold or withdraw your consent to certain uses of personal information may restrict our ability to provide a particular service or product. If so, we will explain the situation to assist you in making the decision.
                      </div>
                      </li>
                      <li>
                      <div className={`${Styles?.det_prv_det}`}>
                      (v) We may collect, use or disclose personal information without your knowledge or consent in the following limited circumstances:
                      </div>
                      </li>
                      <ul style={{listStyle: "disc"}}>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        When the collection, use or disclosure of personal information is permitted or required by law;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        In an emergency that threatens an individual's life, health, or personal security;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        When the personal information is available from a public source;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        When we require legal advice from a lawyer;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        For the purposes of collecting a debt;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        To protect ourselves from fraud;
                        </div>
                        </li>
                        <li>
                        <div className={`${Styles?.det_prv_det}`}>
                        To investigate an anticipated breach of an agreement or a contravention of law
                        </div>
                        </li>
                      </ul>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>USING AND DISCLOSING PERSONAL INFORMATION</div>
                    <ol style={{listStyle:"none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) We will only use or disclose personal information where necessary to fulfill the purposes identified at the time of collection.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) We will not transfer your personal information to third parties, except for affiliates and partners that provide services on our behalf. These include (but are not limited to) payment processors, healthcare practitioners, pharmacies, pharmacists, order delivery providers, provincial or private health plan coverage, web hosting services, provided that these third parties agree to comply with legally required privacy standards and use the information only for the purposes disclosed at the time of collection.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iii) We will not use or disclose your personal information for any additional purpose unless we obtain consent to do so.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iv) We will not sell lists or personal information to other parties <em>[unless we have consent to do so].</em>
                        </div></li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>RETAINING PERSONAL INFORMATION</div>
                    <ol style={{listStyle: "none"}}>
                      {/* todo */}
                      <li><div className={`${Styles?.det_prv_det}`}>                                                                                                                                                                                                                                                            
                      (i) If we use personal information to make a decision that directly affects you, we will retain that personal information for as long as remains necessary for the identified purpose or as required bylaw. This may extend beyond the termination of your relationship with <a href="www.safepharmacy.in">www.safepharmacy.in</a>. Personal information may be retained as necessary to prevent fraud, future abuse or for business purposes such as analysis of non-personal data, account recovery, or if required by law.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) Subject to Section 4(i), we will retain personal information only as long as necessary to fulfill the identified purposes or a legal or business purpose.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iii) Your consent can be withdrawn at any time, except in limited circumstances, including legal or regulatory requirements or as a result of your contractual obligations with us.
                        </div></li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>ENSURING ACCURACY OF PERSONAL INFORMATION</div>
                    <ol style={{listStyle: "none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) Personal information is maintained as accurate, complete, and up-to-date as is necessary for the purposes for which it is used.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) You may request correction to your personal information in order to ensure its accuracy and completeness. A request to correct personal information can be made either electronically or in writing. You must provide sufficient detail to identify the personal information and the correction being sought.
                        </div></li>
                    </ol>
                    <div className={`${Styles?.det_prv_det}`}>A request to correct personal information can be made to:</div>
                    <div className={`${Styles?.det_prv_det}`}>
                    Privacy Officer<br/>200- 15135 101 Avenue<br/>Surrey, BC<br/>V3R 7Z1<br/><a href="www.safepharmacy.in">privacy@safepharmacy.in</a>
                    </div>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>SECURING PERSONAL INFORMATION</div>
                    <ol style={{listStyle: "none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) We are committed to ensuring the security of your personal information in order to protect it from unauthorized access, collection, use, disclosure, copying, modification or disposal or similar risks.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) www.safepharmacy.in follows accepted industry standards to prevent unauthorized access and has multiple safeguards in place to correctly use and maintain data security of personal information. While administrative, technical, contractual and physical procedures are in place to protect your personal information, no method of information transfer over the internet or data storage is entirely secure. As a result, we cannot guarantee the confidentiality or security of any email communications or information sent by you to us via our website or applications. If you wish to send us confidential information, please notify us first to make arrangements.
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iii) We will store your personal information for as long as necessary to fulfill the purposes for which it was collected, except where otherwise required or permitted by law (e.g., healthcare providers must retain patient records in accordance with their statutory retention obligations). Once no longer required, your personal information will be securely destroyed or anonymized (so the information no longer identifies you).We will use appropriate security measures when destroying personal information as required.
                        </div></li>
                        <div className={`${Styles?.det_prv_det}`}>
                        Please note that if your personal information is collected by a third party, it will be retained in accordance with the privacy policies and records retention requirements of that third party.
                        </div>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iv) We will continually review and update our security policies and controls as technology changes to ensure ongoing personal information security.
                        </div></li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>PROVIDING ACCESS TO PERSONAL INFORMATION</div>
                    <ol style={{listStyle: "none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) You have a right to access your personal information collected by us.</div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) A request to access personal information must be made in writing at the contact address provided below.</div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iii) Upon request, within a reasonable time, we will tell you what personal information belonging to you is stored by us, what it is being used for and who possesses access to it.</div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (iv) You may notify us in writing at our contact address of the accuracy and completeness of the information and have it amended as appropriate.</div></li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>TRACKING TECHNOLOGIES AND COOKIES</div>
                    <ol style={{listStyle: "none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) “Cookies”.  In connection with the foregoing collection of information, we may also use “cookies” or similar technologies (small amounts of data that are stored on your computer’s hard drive, mobile device or media system platform when you use or access our Site and/or Services that identify your computer, mobile device or media system platform and may store information about you such as behavioural data).  Should you choose to submit Personal Information to us, we may link cookie information to such Personal Information.  If you do not wish to accept cookies, you have the option of blocking or disabling cookies.  However, please be aware that some of our Site, Content and/or services will not function properly if you do so and may lose access to Services you purchased.
                        </div></li>
                        {/* todo */}
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) Third Party Advertising Partners. In addition, certain advertising partners may also make use of cookies in connection with advertising on behalf of the website “<a href="www.safepharmacy.in">www.safepharmacy.in</a>”. Such cookies may collect non-personal information which those partners may use to serve you targeted ads both within and outside of our Site and/or Services.
                        </div></li>
                    </ol>
                  </li>
                  <li>
                    <div className={`${Styles?.det_prv_title}`}>QUESTIONS AND COMPLAINTS: THE ROLE OF THE PRIVACY OFFICER OR DESIGNATED INDIVIDUAL</div>
                    <ol style={{listStyle: "none"}}>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (i) The Privacy Officer is responsible for ensuring all personal information collected by www.safepharmacy.in remains compliant with this policy, the <em>Personal Information Protection Act and Personal Information Protection and Electronic Documents Act of Canada.</em>
                        </div></li>
                      <li><div className={`${Styles?.det_prv_det}`}>
                      (ii) You may direct any complaints, concerns or questions regarding www.safepharmacy.in compliance in writing to the Privacy Officer. If the Privacy Officer is unable to resolve the concern, you may also write to the Information and Privacy Commissioner of British Columbia.
                        </div></li>
                    </ol>
                  </li>
                  {/* todo */}
                  <div className={`${Styles?.det_prv_det}`}>Contact information for <a href="www.safepharmacy.in">www.safepharmacy.in</a> Privacy Officer:</div>
                  <div className={`${Styles?.det_prv_det}`}>
                    Privacy Officer<br/>200- 15135 101 Avenue<br/>Surrey, BC<br/>V3R 7Z1<br/><a href="www.safepharmacy.in">privacy@safepharmacy.in</a>
                    </div>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopup ? "" : <Footer/> }
    </div>
  )
}

export default Privacy;