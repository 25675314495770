import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import Heading from "../../components/UI/Heading";
import Input from "../../components/UI/Input";
import Label from "../../components/UI/Label";
import Config from "../../config/label";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import LinkButton from "../UI/LinkButton";
import Styles from "./index.module.css";
import { USER_PROFILE } from "../../config/Config";

export type ConsentProps = {
  setFormValue?: any;
  formValue?: any;
  headerStyle?: object;
  containerClass?: string;
  setIsSigned?: any;
  setImageURL?: any;
  setConsentFullName?: any;
  consentFullName?: any;
  watch?: any;
};

const ConsentForm: React.FC<ConsentProps> = (props) => {
  const {
    setFormValue,
    formValue,
    headerStyle,
    containerClass,
    setIsSigned,
    setImageURL,
    setConsentFullName,
    consentFullName,
    watch,
  } = props;

  const location = useLocation();
  const patientWatch = watch("patient");
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);

  useEffect(() => {
    if (_user_profile) {
      _user_profile?.usr_name.trim() &&
        setConsentFullName(_user_profile?.usr_name.trim());
    } else if (patientWatch === "self") {
      setConsentFullName(firstName + " " + lastName);
    } else {
      setConsentFullName("");
    }
  }, [_user_profile]);

  const sigCanvas = useRef<any>({});

  const handleChange = (e: any) => {
    e.preventDefault();
    setConsentFullName(e.target.value);
    const value = e.target.value;
    setFormValue({ ...formValue, [e.target.name]: value });
  };

  return (
    <div className={`${containerClass}`}>
      <Heading style={{ ...headerStyle }} className={Styles["form_heading"]}>
        {Config?.heading_Consent}
      </Heading>
      <Label for="fullName" className={`${Styles?.form_label}`}>
        {Config?.lbl_Name}
      </Label>
      <Input
        name="fullName"
        id="fullName"
        type={"text"}
        placeholder={Config?.inp_P_Holder_Name}
        size={"medium"}
        value={consentFullName}
        disabled={
          _user_profile?.usr_name.trim() ||
          (patientWatch === "self" && firstName)
        }
        onChange={handleChange}
        className={Styles["form_input"]}
        maxLength={100}
      />
      <p className={Styles["content"]}>{Config?.txt_Consent_1}</p>
      <div className="inline-flex flex-col">
        <div
          className="flex justify-between px-1 "
          style={{ marginTop: "50px" }}
        >
          <Label for="digitalSign" className={`${Styles?.form_label}`}>
            {Config?.lbl_Sign}
          </Label>

          <LinkButton
            to={location?.pathname + location?.search}
            onClick={() => {
              setIsSigned(false);
              setImageURL(null);
              sigCanvas?.current?.clear();
            }}
            draggable="false"
          >
            Clear
          </LinkButton>
        </div>
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{
            width: 300,
            height: 100,
            className: `${Styles?.sigCanvas}`,
          }}
          onEnd={() => {
            setIsSigned(true);
            setImageURL(
              sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
            );
          }}
        />
      </div>
    </div>
  );
};

export default ConsentForm;
