import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar";
import  SettingsIcon from "../../assets/svg/password.svg";
import Config from "../../config/label";
import Button from "../../components/UI/Button";
import Alert from "../../components/UI/Alert";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import { useNavigate } from "react-router-dom";
import Label from "../../components/UI/Label";
import { FormInput } from "../../components/UI/FormInput";
import { useForm } from "react-hook-form";
import { msg_config } from "../../config/messages";
import PopConfirm from "../LogInPage/Login/CreatePassword/PopConfirm";
import { ShimmerTitle } from "react-shimmer-effects";

var md5 = require("md5");

export type RegistrationFormFields = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isAlert, setIsAlert] = useState<any>({
    istrue: false,
    type: "",
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [suggestUsInit] = useContext(APIContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const changePasswordHandler = (data: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (data?.newPassword === data?.confirmPassword) {
      if (suggestUsInit?.returnCode === true) {
        setLoading(true);
        CallAPI(config?.change_password_process_id, {
          p_old_password: md5(data?.oldPassword),
          p_new_password: md5(data?.confirmPassword),
        }).then((res) => {
          if (res?.returnCode === true) {
            setLoading(false);
            reset();
            setValue("oldPassword", "");
            setValue("newPassword", "");
            setValue("confirmPassword", "");
            setPasswordValues({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            setIsAlert({
              istrue: true,
              type: "success",
              msg: msg_config?.alert_success_change,
            });
          } else {
            setLoading(false);
            reset();
            setIsAlert({
              istrue: true,
              type: "error",
              msg: "Please enter a valid password",
            });
            setTimeout(() => {
              // resetting alert message
              setIsAlert({ istrue: false, type: "error", msg: "" });
            }, 5000);
          }
        });
      }
      // End
    } else {
      setIsAlert({
        istrue: true,
        type: "warning",
        msg: msg_config?.alert_warning_confirm,
      });
    }
  };

  useEffect(()=>{
  try {
      if(isAlert?.istrue){
          setTimeout(() => {
              setIsAlert((prev:any)=>{
                return {...prev, istrue : false, type : "", msg : ""} 
              })
          }, 5000);
      }
  } catch (error) {
    console.error(error);
  }
  },[isAlert])

  const keyPresshandler = (e: any) => {
    // if (e.which === 13) {
    //   changePasswordHandler;
    // }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onPasswordChange = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setPasswordValues((prev) => {
        return { ...prev, [fieldName]: "" };
      });
    } else if (
      fieldName === "confirmPassword" ||
      fieldName === "newPassword" ||
      fieldName === "oldPassword"
    ) {
      let newString = cleanInput(e.target.value);

      setPasswordValues((prev) => {
        return { ...prev, [fieldName]: newString };
      });
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setPasswordValues((prev) => {
        return { ...prev, [fieldName]: newString };
      });
    } else {
      setPasswordValues((prev) => {
        return { ...prev, [fieldName]: e.target.value };
      });
    }
  };

  return (
    <div className={`${Styles?.mainContainer}`}>
      <div className="outer_container">
      <div className={`${Styles?.grid}`}>
        <div className={`${Styles?.gridLeftItem}`}>
          <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
        <div className={`${Styles?.gridRightItem}`}>
          <div className={`${Styles?.headerContainer}`}>
            <div style={{ display: "flex", alignItems:"center" }}>
              <img
                src={SettingsIcon}
                alt="Change Password"
                width="25"
                height="25"
                style={{ marginTop: "0px", color: "#32C5F4" }}
                draggable="false"
              />
              <h2 className={`${Styles?.heading}`}>
                {Config?.forgotPassword_stepper_Heading__3}
              </h2>
            </div>
          </div>
          {loading ? (
            <ShimmerTitle line={3} gap={15} variant="primary" />
          ) : (
            <div className={`${Styles?.passwordGrid}`}>
              <form onSubmit={handleSubmit(changePasswordHandler)}>
                <div className={`${Styles?.form_control}`}>
                  <div className={`${Styles?.form_inner_control}`}>
                    {/* Old Password */}
                    <Label for="oldPassword" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_old_password}
                    </Label>
                    <FormInput<RegistrationFormFields>
                      name="oldPassword"
                      id="oldPassword"
                      type={Config?.lbl_Inp_type_pass}
                      placeholder={""}
                      size="small"
                      register={register}
                      rules={{ required: msg_config?.old_psw_required }}
                      errors={errors}
                      inputAutoFocus={true}
                      maxLength={20}
                      value={passwordValues?.oldPassword}
                      onInputChangeHandler={(e: any) =>
                        onPasswordChange(e, "oldPassword")
                      }
                    />
                  </div>

                  <div className={`${Styles?.form_inner_control}`}>
                    {/* New Password */}

                    <Label for="newPassword" className={`${Styles?.formLabel}`}>
                      {Config?.lbl_new_password}
                    </Label>
                    {/* <PopConfirm title="Password must be atleast 8 characters and contain uppercase, lowercase, number and special character"> */}
                      <FormInput<RegistrationFormFields>
                        name="newPassword"
                        id="newPassword"
                        type={Config?.lbl_Inp_type_pass}
                        placeholder={""}
                        size="small"
                        register={register}
                        value={passwordValues?.newPassword}
                        rules={{
                          required: msg_config?.new_psw_required,
                          pattern: {
                            value:
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/gm,
                            message:
                              "Password must be at least 8 characters and contain uppercase, lowercase, number and special character",
                          },
                        }}
                        errors={errors}
                        maxLength={20}
                        onInputChangeHandler={(e: any) =>
                          onPasswordChange(e, "newPassword")
                        }
                      />
                    {/* </PopConfirm> */}
                  </div>

                  <div className={`${Styles?.form_inner_control}`}>
                    {/* Confirm Password */}
                    <Label
                      for="confirmPassword"
                      className={`${Styles?.formLabel}`}
                    >
                      {Config?.lbl_confirm_password}
                    </Label>
                    <FormInput<RegistrationFormFields>
                      name="confirmPassword"
                      id="confirmPassword"
                      type={Config?.lbl_Inp_type_pass}
                      placeholder={""}
                      size="small"
                      // onKeyPress={keyPresshandler}
                      register={register}
                      value={passwordValues?.confirmPassword}
                      rules={{ required: msg_config?.confirm_psw_required }}
                      errors={errors}
                      maxLength={20}
                      onInputChangeHandler={(e: any) =>
                        onPasswordChange(e, "confirmPassword")
                      }
                    />
                  </div>
                  <div className={`${Styles?.submit_btn}`}>
                    {/* Submit Button */}
                    <Button
                      type={Config?.lbl_Btn_type_primary}
                      htmlType={Config?.lbl_Btn_action_submit}
                      size={Config?.lbl_Btn_size_medium}
                      style={{ margin: 0 }}
                      loading={loading}
                    >
                      {Config?.forgotPassword_stepper_Heading__3}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
        {isAlert?.istrue === true ? (
          <Alert type={isAlert?.type} info={isAlert?.msg} />
        ) : (
          ""
        )}
      </div>
      </div>
    </div>
  );
};

export default ChangePassword;
