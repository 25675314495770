import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { siteConfig } from '../../../../../config/site';
import { CallAPI } from '../../../../../suggest-us/callSuggest';
import messageService from '../../../../../components/MessageService';
import ChatModal from '../ChatModal';
import audioUrl from "../Assets/alert.wav"
// import "./index.css"
// import "../index.css"
// import "../branding.css"
import '../chatmodel.css';
import { getDecryptedID, strDecrypt } from '../../../../../utils/utils';
import suggestus_client_config from '../../../../../config/suggestus_client_config';
import ChatIcon  from "../../../../../assets/svg/Chat.svg";
import chat_logo_img from "../../../../../assets/appLogo/safe_pharmacy_logo.png";
import { ORG_ID } from '../../../../../config/Config';


const ChatBot = () => {
    const [showForm, setShowForm] = useState(false)
    const [guestData, setGuestData] = useState(null)
    const [chatToken, setChatToken] = useState("")
    const [totalUnreadCount, setTotalUnreadCount] = useState(0)
    const [showChatNotifications, setShowChatNotifications] = useState(false);
    const [playSound, setPlaySound] = useState(false);
    const [loader, setLoader] = useState(false)

    const inputRef = useRef(null)
    const getToken = async () => {
        setLoader(true)
        try {
            setLoader(true)
            const id = Math.random().toString().slice(2, 8); //guest user ID
            setGuestData(prev => ({ ...prev, id }))
            await axios({
                method: "GET",
                url: `${suggestus_client_config.TWILIO_URL}/chat/token?identity=${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                setLoader(false)
                setChatToken(response?.data?.token); // set the token
            })
        } catch (e) {
            console.log(e?.message, "ERROR :");
        }
    }

    const handleSubmit = (e) => {
        e?.preventDefault()
        getToken(e?.target?.name?.value, e?.target?.email?.value)
    }

    useEffect(() => {
        if (showForm) {
            inputRef?.current?.focus();
        }
    }, [showForm]);

    useEffect(() => {
        if (chatToken !== '') {
            setShowForm(false)
            messageService.sendMessage("TopHeader", true, "chat_side_modal");
        }
    }, [chatToken])

    useEffect(() => {
        const subscription = messageService.onMessage().subscribe((m) => {
            if (m.senderId === "TopHeader" && m.target === 'showChatModal') {
                setShowChatNotifications(m.text)
            }
            if (m.senderId === "TopHeader" && m.target === "totalCount") {
                setTotalUnreadCount(m.text)
            }
            if (m.senderId === "TopHeader" && m.target === "read") {
                setTotalUnreadCount(prev => prev - m.text)
            }
            if (m.senderId === "topHeader" && m.target === "playSound") {
                setPlaySound(m?.text)
            }
        })
        return () => {
            if (subscription != null && subscription != undefined) {
                subscription.unsubscribe();
            }
        }
    }, [])

    useEffect(() => {
        if (totalUnreadCount > 0 && !showChatNotifications && playSound) {
            const audio = new Audio(audioUrl)
            audio.play()
        }
    }, [totalUnreadCount, showChatNotifications, playSound])


    return (
        <>
            {chatToken &&
                <div className="voip-modal">
                    <ChatModal
                        token={chatToken}
                        twilioUrl={suggestus_client_config.TWILIO_URL}
                        id={guestData?.id}
                        name={guestData?.name + "(Guest)"}
                        organisationId={getDecryptedID(ORG_ID)}
                        userType={"PATIENT"}
                        email={guestData?.email}
                        CallAPI={CallAPI}
                        messageService={messageService}
                        isGuest={true}
                        guestData={guestData}
                    />
                </div>
            }
            {showForm ? <div className='chatBotTwilioContainer'>
                <div className='title'>
                    <span><i className="fa fa-comments" aria-hidden="true"></i> Chats</span>
                    <span><i className="fa" onClick={() => {
                        setShowForm(!showForm)
                    }}>&#x2715;</i></span>
                </div>
                <div className='chatBotTwilioContainerSection'>
                    <div className='chat_bot_logo_wrapper'>
                        <img src={chat_logo_img} />
                    </div>
                    
                    <p>Could we have your name and email?</p>
                    <form onSubmit={handleSubmit} className='container'>
                        <div className="field">
                            <div className="user-phone-select">
                                <input
                                    className="form-control "
                                    id="conversation-name-input"
                                    type="text"
                                    ref={inputRef}
                                    autoFocus={true}
                                    required={true}
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Name"
                                    onChange={(e) => setGuestData(prev => ({ ...prev, [e?.target.name]: e?.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="user-phone-select">
                                <input
                                    className="form-control "
                                    id="conversation-name-input"
                                    type="email"
                                    autoFocus={true}
                                    required={true}
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e) => setGuestData(prev => ({ ...prev, [e?.target.name]: e?.target.value }))}
                                />
                            </div>
                        </div>


                        <button
                            title="Chat"
                            className="ui-button-default btn-vhelp chats_btn"
                            type={"submit"}
                        >{loader ? <span className="spinner-border spinner-border-sm loadingSpinner" role="status" aria-hidden="true"></span> : 'Start Chat'}</button>
                    </form>
                </div>
            </div> : ""}
            <div className='chatBotTwilio' onClick={() => {
                if (chatToken !== "") {
                    messageService.sendMessage("TopHeader", true, "chat_side_modal");
                }
                else {
                    setShowForm(!showForm)
                }
            }}>
                {totalUnreadCount > 0 && !showChatNotifications && <span className="data-count">{totalUnreadCount}</span>}
                {showForm ? <img src ={ChatIcon} />: <img src ={ChatIcon} />}
            </div >
        </>
    )
}

export default ChatBot