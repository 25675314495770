import { useDispatch } from "react-redux";
import RadioButton from "../../components/UI/RadioButton";
import { mylistPageActions } from "./slice";
import ContextMenu from "../../components/ContextMenu/ContextMenu";
import styles from "./index.module.css";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import { useState } from "react";
import SidebarContextMenu from "./SidebarContextMenu";
const SideBar = (props) => {
  const {
    myLists,
    setSelectedList,
    selectedList,
    userProfileJson,
    setIsAlert,
    setEditMode,
    editMode,
    setmyListRefresh
  } = props;
  const dispatch = useDispatch();

  return (
    <div>
      <div className={`${styles?.add_new_list_title}`}>
        <p>My Preferred lists</p>
      </div>
      {myLists?.map((list, idx) => (
        <div className={`${styles?.myList}`} key={"my_list_" + idx}>
          <RadioButton
            name={"MyListSelector"}
            value={list?.p_list_id}
            checked={list?.p_list_id === selectedList?.selectId ? true : false}
            onChange={(e) => {
              // setSelectedList(e.target.value);
              setSelectedList((prev)=>{
                return {...prev, selectId : e.target.value || ""}
              })

              dispatch(
                mylistPageActions?.SET_SELECT_ALL({
                  value: false,
                })
              );

              //   setSelectedMode(e?.target?.value);
            }}
            //   disabled={i?.pm_status === "A" ? false : true}
            title={`${list?.list_title || list?.p_list_title} (${
              list.p_count
            })`}
            key={"myListSelectorRadio_" + list?.p_list_id}
            className={`${styles?.myListSelectorRadio}`}
            //   className={`${Styl}`}
          />
          <SidebarContextMenu
            p_list_id={list?.p_list_id}
            userProfileJson={userProfileJson}
            setEditMode={setEditMode}
            setmyListRefresh={setmyListRefresh}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
          />
        </div>
      ))}
      <div
        className={`${styles?.add_new_list_text}`}
        onClick={() => {
          setEditMode(false);
          dispatch(
            mylistPageActions?.TOGGLE_CREATE_LIST({
              showAddNewMyList: true,
              mode: "add",
            })
          );
        }}
      >
        + Add new List{" "}
      </div>
    </div>
  );
};

export default SideBar;
