import React, { useContext, useEffect, useState } from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Delete_icon from "../../assets/icons/Delete_icon.svg";
import noshow from "../../assets/svg/noshow.svg";
import SideBar from "../../components/ProfileSideBar/SideBar/index";
import Button from "../../components/UI/Button";
import IconButton from "../../components/UI/IconButton";
import PopConfirm from "../../components/UI/PopConfirm";
import Config from "../../config/label";
import { config } from "../../config/processId.js";
import { siteConfig } from "../../config/site";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { Location } from "../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import AddCard from "./AddCard";
import styles from "./index.module.css";
import { color_config } from "../../config/colorConfig";
import Alert from "../../components/UI/Alert";
import NameProfilePopup from "./NameProfilePopup";
import { USER_PROFILE } from "../../config/Config";

// const Url = "https://phill-sgultra.speedum.tech/";
const APPLICATION_ID = siteConfig?.Payments_APPLICATION_ID;
const LOCATION_ID = siteConfig?.Payments_LOCATION_ID;
const userProfileJson: any = getDecryptedID(USER_PROFILE);
// const userProfileJson = JSONCheckerFunc(userProfile);
const paymentRequestMock = {
  countryCode: "CA",
  currencyCode: userProfileJson?.curreny_sign,
  intent: "STORE",
  requestBillingContact: true,
  requestShippingContact: true,
  billingContact: {
    familyName: userProfileJson?.usr_name, // First name
    givenName: userProfileJson?.usr_name, //Last name
  },
};

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card).
export async function tokenizePaymentMethod(paymentMethod: any) {
  const tokenResult = await paymentMethod.tokenize();
  // A list of token statuses can be found here:
  // https://developer.squareup.com/reference/sdks/web/payments/enums/TokenStatus
  if (tokenResult.status === "OK") {
    return tokenResult;
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
}

export async function verifytokenizePaymentMethod(
  token: any,
  paymentMethod: any
) {
  const verificationResults = await paymentMethod.verifyBuyer(
    token,
    paymentRequestMock
  );

  if (verificationResults.token) {
    return verificationResults.token;
  }
  {
    let errorMessage = `Verify token failed-status: ${verificationResults}`;
    throw new Error(errorMessage);
  }
}

const ManagePayments = () => {
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [modalType, setModalType] = useState("Add");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [squarePayments, setSquarePayments] = useState<any>(undefined);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [squareCard, setSquareCard] = useState<any>(undefined);
  const [squareCardlist, setSquareCardlist] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [suggestUsInit] = useContext(APIContext);
  const [userAlert, setUserAlert] = useState({
    isSelected: false,
    type: "",
    msg: "",
  });

  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  let localStorageCopy = userProfileJson;
  const gaEventTracker = useAnalyticsEventTracker("Manage payments page");

  const userId = userProfileJson?.usr_id || 0;
  const patientId = userProfileJson?.patient_id || 0;

  // const userId = 127;
  // const patientId = 3;

  const [isSubmitting, setSubmitting] = useState(false);
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false,
  });
  const isCardFieldsValid = Object.values(validFields).every((v) => v);

  // Add Square script to the page
  useEffect(() => {
    const existingScript = document.getElementById("webPayment");

    if (existingScript) {
      setLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = siteConfig?.Square_script;
      script.id = siteConfig?.Square_script_id;
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, []);

  // Instantiate Square payments and store the object in state
  useEffect(() => {
    if (loaded && !squarePayments) {
      let windows: any = window;
      if (!windows?.Square) {
        console.error("Square.js failed to load properly");
        return;
      }
      setSquarePayments(windows.Square?.payments(APPLICATION_ID, LOCATION_ID));
    }
  }, [loaded, squarePayments]);

  const fetchAddedCard = async () => {
    setIsLoading(true);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.fetch_added_cards, {
        p_cardholder_name: paymentRequestMock.billingContact.familyName,
        environment: Config?.manpay_env,
        p_gateway_type: Config?.manpay_gateway_type,
      })
        .then((response) => {
          if (
            response?.returnCode === true ||
            response?.returnCode === "true"
          ) {
            setIsLoading(false);
            let result = response?.returnData[0] ? response?.returnData[0] : [];
            setSquareCardlist(result);
          } else {
            setIsLoading(false);
            setSquareCardlist(response?.returnData);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const addPayment = async (
    noonce: any,
    tokenResult: any,
    verifytoken: any
  ) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.add_payments, {
        p_cardholder_name: paymentRequestMock.billingContact.familyName,
        p_verification_token: verifytoken,
        p_card_nonce: noonce,
        environment: Config?.manpay_env,
        p_gateway_type: Config?.manpay_gateway_type,
      })
        .then((response) => {
          if (response.returnCode === true || response.returnCode === "true") {
            gaEventTracker("Payments card added");
            fetchAddedCard();
            setIsAddCardModalOpen(false);
            setIsButtonLoading(false);
            if (squareCard) {
              squareCard.destroy();
              setSquareCard(undefined);
            }
          } else {
            setIsAddCardModalOpen(false);
            setIsButtonLoading(false);
            setUserAlert({
              isSelected: true,
              type: "error",
              msg: response?.msg,
            });
          }
        })
        .catch((error: any) => {
          throw error;
        });
    }
  };

  // Check customer id exists or not
  const checkCustomer = async () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.check_customer, {
        environment: Config?.manpay_env,
        p_gateway_type: Config?.manpay_gateway_type,
      })
        .then((response) => {
          if (response.returnCode === true || response.returnCode === "true") {
            fetchAddedCard();
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const initializeSquareCard = async () => {
    // const card = await squarePayments.card();
    // setSquareCard(card);
    // attachCard(card);

    checkCustomer();
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (squarePayments) {
      if (!squareCard) initializeSquareCard();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
    }
  }, [squarePayments]);

  //Delete Added card
  const deleteAddedCard = async (rowData: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.delete_customer_card, {
        p_card_id: rowData.card_id,
        environment: Config?.manpay_env,
        p_gateway_type: Config?.manpay_gateway_type,
      })
        .then((response) => {
          // if (response.returnCode === true || response.returnCode === "true") {
          fetchAddedCard();
          gaEventTracker("Payments card deleted");
          // }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    if (
      localStorageCopy?.usr_name === " " ||
      localStorageCopy?.usr_name === null ||
      localStorageCopy?.usr_name === ""
    ) {
      setIsProfilePopup(true);
    } else {
      setIsProfilePopup(false);
    }
  }, [localStorageCopy]);

  return (
    <>
      <div
        className={`${styles?.mainContainer}`}
        onClick={(e) => {
          setModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="outer_container">
        <div className={`${styles?.grid}`}>
          <div className={`${styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
          <div className={`${styles?.gridRightItem}`}>
            <div className={`${styles?.headerContainer}`}>
              <div style={{ display: "flex", alignItems:"center" }}>
                <Location
                  width="25"
                  height="25"
                  style={{ marginTop: "0px", color: "#32C5F4" }}
                />
                <h2 className={`${styles?.heading}`}>
                  {Config?.manpay_heading}
                </h2>
              </div>

              <Button
                className={`${styles?.customAddButton}`}
                onClick={() => {
                  setIsAddCardModalOpen(true);
                  setModalType("Add");
                }}
              >
                {" "}
                <span className={`${styles?.addToolTip}`}>
                  {" "}
                  Add New Card{" "}
                </span>{" "}
                +
              </Button>
            </div>
            {isLoading ? (
              <>
                <br />
                <br />
                <br />
                <ShimmerSimpleGallery row={2} col={3} imageHeight={150} />
              </>
            ) : (
              <>
                {squareCardlist?.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      color: color_config?.common_bg_color,
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      paddingTop: "100px",
                    }}
                  >
                    <div className={styles["checkIconWrapper"]}>
                      <img
                        src={noshow}
                        alt="Empty Cart icon"
                        draggable="false"
                      />
                    </div>
                    <b>{Config?.manpay_nocard}</b>
                  </div>
                )}
                <div className={`${styles?.addressGrid}`}>
                  {squareCardlist?.length > 0 &&
                    squareCardlist?.map((item: any) => (
                      <div
                        className={
                          item?.default_address_flag === "Y"
                            ? `${styles?.defaultAddressRow}`
                            : `${styles?.addressRow}`
                        }
                      >
                        <div className={`${styles?.actionButtons}`}>
                          <IconButton
                            onClick={() => {
                              deleteAddedCard(item);
                            }}
                            title="Delete"
                          >
                            <div className={`${styles?.penFill}`}>
                              <img
                                src={Delete_icon}
                                alt="Delete btn"
                                draggable="false"
                              />
                            </div>
                          </IconButton>
                          {/* <PopConfirm
                            title="Are you sure that you want to delete?"
                            onConfirm={(setIsConfirmPopup: any) => {
                              deleteAddedCard(item);
                              setIsConfirmPopup(false);
                            }}
                          >
                            <div className={`${styles?.penFill}`}>
                              <img
                                src={Delete_icon}
                                alt="Delete btn"
                                draggable="false"
                              />
                            </div>
                          </PopConfirm> */}
                        </div>

                        <div className={`${styles?.basicDetailsWrapper}`}>
                          <p className={`${styles?.clientName}`}>
                            {item?.cardholder_name}{" "}
                          </p>
                          <p className={`${styles?.clientBasicDetails}`}>
                            ************{item?.card_last_4}
                          </p>
                          <p className={`${styles?.clientBasicDetails}`}>
                            Card type - {item?.card_brand}
                          </p>
                          <p className={`${styles?.clientBasicDetails}`}>
                            Card expiry -{item?.card_exp_month}
                            {", "} {item?.card_exp_year}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
        </div>
      </div>

      {isAddCardModalOpen && (
        <AddCard
          isAddCardModalOpen={isAddCardModalOpen}
          setIsAddCardModalOpen={setIsAddCardModalOpen}
          isCardFieldsValid={isCardFieldsValid}
          isButtonLoading={isButtonLoading}
          setIsButtonLoading={setIsButtonLoading}
          // isSubmitting={isSubmitting}
          squareCard={squareCard}
          // handlePaymentMethodSubmission={handlePaymentMethodSubmission}
          loaded={loaded}
          squarePayments={squarePayments}
          setSquarePayments={setSquarePayments}
          validFields={validFields}
          setValidFields={setValidFields}
          setSquareCard={setSquareCard}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          tokenizePaymentMethod={tokenizePaymentMethod}
          verifytokenizePaymentMethod={verifytokenizePaymentMethod}
          addPayment={addPayment}
          APPLICATION_ID={APPLICATION_ID}
          LOCATION_ID={LOCATION_ID}
          heading={"Add New Card"}
          modalType={modalType}
          editHeading={"Edit Address"}
          // getAddress={getAddress}
        />
      )}

      {userAlert?.isSelected && (
        <Alert type={userAlert?.type} info={userAlert?.msg} />
      )}
      {isProfilePopup && <NameProfilePopup setModalOpen={setIsProfilePopup} />}
    </>
  );
};

export default ManagePayments;
