
import React, { useState, useEffect } from "react";
import { spd_processId_config } from "./Config/processID";


const SearchList = ({ availableUsers, searchToAdd, addLoading, handleAdd,
  searchLoading, setSearchLoading, CallAPI }) => {
  const [otherContact, setOtherContact] = useState([]);

  const getPatientList = (val) => {
    try {
      setSearchLoading(true);
      CallAPI(
        spd_processId_config.sgconf_get_mst_organisation_contact_group_for_user_search,
        {
          p_contact_group_id: "1",
          p_search_criteria: val,
        }
      )
        .then((res) => {
          if (res?.returnCode === true) {
            setOtherContact(res?.returnData);
            setSearchLoading(false);
          }
        })
        .catch((err) => {
          setSearchLoading(false);
        });
    } catch (err) {
      setSearchLoading(false);
      console.log("Error: ", err?.message);
    }
  }

  useEffect(() => {
    try {

      getPatientList(searchToAdd);

    } catch (error) {
      console.log("Error: ", error.message);
    }
  }, [searchToAdd]);



  const notMeUsers = [...otherContact]?.filter(
    (v) => !availableUsers?.includes(v?.usr_id)
  );

  try {
    return (
      <>
        <div className="conversationList-heading marginTop-1">
          OTHER CONTACTS
        </div>

        {!searchLoading ? (
          <>
            {notMeUsers?.length > 0
              ? notMeUsers?.map((user) => (
                <div
                  key={user?.usr_id}
                  className="filter-files "
                  onClick={() => {
                    if (!addLoading?.loading) {
                      handleAdd(user);
                    }
                  }}
                >
                  <div className="wapper">
                    <div className="wapper-left">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </div>

                    <div className="wapper-right">
                      <div className="message">
                        <h1 className="author">{user?.usr_name}</h1>
                        <div style={{ fontWeight: "400" }}></div>
                      </div>
                    </div>
                    <div className="wapper-add">
                      {addLoading?.sid === user?.usr_id ? (
                        <span
                          className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                      )}
                    </div>
                  </div>
                </div>
              ))
              : "No Contacts Found"}
          </>
        ) : (
          <div className="messageLoading">
            <span
              className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}
      </>
    );
  } catch (error) {
    console.log("Error: ", error.message);
  }
};

export default SearchList;
