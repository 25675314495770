/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import Stepper from "../../../../components/UI/Stepper";

export type RxStepsProps = {
  steps: any;
  activeStep: any;
};

const RxSteps: React.FC<RxStepsProps> = (props) => {
  const { activeStep, steps } = props;
  return <Stepper steps={steps} activeStep={activeStep} />;
};

export default RxSteps;
