import React, { useEffect, useState } from "react";
import Styles from "./index.module.css";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../FormErrorMessage";
import { msg_config } from "../../../config/messages";

const FormDigitInput = (props: any) => {
  const { register, errors, clearErrors, setFocus, setValue } = props;

  const rules = { required: msg_config?.OTP_required };

  const { name: name1, ref: ref1, ...rest1 } = register("otp.val1", rules);
  const { name: name2, ref: ref2, ...rest2 } = register("otp.val2", rules);
  const { name: name3, ref: ref3, ...rest3 } = register("otp.val3", rules);
  const { name: name4, ref: ref4, ...rest4 } = register("otp.val4", rules);
  const { name: name5, ref: ref5, ...rest5 } = register("otp.val5", rules);
  const { name: name6, ref: ref6, ...rest6 } = register("otp.val6", rules);

  const pin1Ref = React.useRef(null);
  const pin2Ref = React.useRef();
  const pin3Ref = React.useRef();
  const pin4Ref = React.useRef();
  const pin5Ref = React.useRef();
  const pin6Ref = React.useRef();

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");

  useEffect(() => {
    setFocus(name1, { shouldSelect: true });
  }, []);

  useEffect(() => {
    if (pin1 && pin2 && pin3 && pin4 && pin5 && pin6) {
      clearErrors("otp");
    }

    if (pin6) {
      setValue(name6, pin6);
    }
  }, [pin1, pin2, pin3, pin4, pin5, pin6]);

  const getInputSize = () => {
    return { width: "3.3rem", height: "3.3rem" };
  };

  const handlePinChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) {
      switch (index) {
        case 1:
          setPin1(value);
          if (value.length > 0) {
            const { current }: any = pin2Ref;
            current.focus();
          }
          break;
        case 2:
          setPin2(value);
          if (value.length > 0) {
            const { current }: any = pin3Ref;
            current.focus();
          } else {
            const { current }: any = pin1Ref;
            current.focus();
          }
          break;
        case 3:
          setPin3(value);
          if (value.length > 0) {
            const { current }: any = pin4Ref;
            current.focus();
          } else {
            const { current }: any = pin2Ref;
            current.focus();
          }
          break;
        case 4:
          setPin4(value);
          if (value.length > 0) {
            const { current }: any = pin5Ref;
            current.focus();
          } else {
            const { current }: any = pin3Ref;
            current.focus();
          }
          break;
        case 5:
          setPin5(value);
          if (value.length > 0) {
            const { current }: any = pin6Ref;
            current.focus();
          } else {
            const { current }: any = pin4Ref;
            current.focus();
          }
          break;
        case 6:
          setPin6(value);
          if (value.length === 0) {
            const { current }: any = pin5Ref;
            current.focus();
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={`${Styles?.container}`}>
      <ul id="otp">
        <li>
          <input
            maxLength={1}
            name={name1}
            ref={(e: any) => {
              ref1(e);
              pin1Ref.current = e;
            }}
            {...rest1}
            value={pin1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(1, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
        <li>
          <input
            maxLength={1}
            name={name2}
            ref={(e: any) => {
              ref2(e);
              pin2Ref.current = e;
            }}
            {...rest2}
            value={pin2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(2, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
        <li>
          <input
            maxLength={1}
            name={name3}
            ref={(e: any) => {
              ref3(e);
              pin3Ref.current = e;
            }}
            {...rest3}
            value={pin3}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(3, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
        <li>
          <input
            maxLength={1}
            name={name4}
            ref={(e: any) => {
              ref4(e);
              pin4Ref.current = e;
            }}
            {...rest4}
            value={pin4}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(4, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
        <li>
          <input
            maxLength={1}
            name={name5}
            ref={(e: any) => {
              ref5(e);
              pin5Ref.current = e;
            }}
            {...rest5}
            value={pin5}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(5, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
        <li>
          <input
            maxLength={1}
            name={name6}
            ref={(e: any) => {
              ref6(e);
              pin6Ref.current = e;
            }}
            {...rest6}
            value={pin6}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePinChange(6, e.target.value)
            }
            className={`${Styles.inputStyle}`}
            style={{ ...props?.style, ...getInputSize() }}
          />
        </li>
      </ul>

      {Object.keys(errors).length > 0 && (
        <FormErrorMessage className={`${Styles?.req_error_msg}`}>
          {msg_config?.OTP_required}
        </FormErrorMessage>
      )}
    </div>
  );
};

export default FormDigitInput;
