import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import NotificationBadge from "react-notification-badge";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import userSkeleton from "../../assets/svg/user_1.svg";
import DropDownIcon from "../../assets/sf_svg/Account.svg";
import CartIcon from "../../assets/sf_svg/Cart.svg";
import ProUserIcon from "../../assets/sf_svg/Pro User.svg";
import UserIcon from "../../assets/icons/User.svg";

import MagnifyingGlass from "../../assets/svg/MagnifyingGlass.svg";
import MyRX from "../../assets/svg/myRXIcon.svg";
import SideDrawer from "../../components/UI/SideDrawer";
import Config from "../../config/label";
import { config } from "../../config/processId.js";
import { auth, logout } from "../../firebase";
import { AppAlertContext } from "../../store/alert-context.js";
import APIContext from "../../store/api-context";
import { GlobalContext } from "../../store/global-context.js";
import { UserAuthContext } from "../../store/UserAuthContext.js";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { AppLogo, HamburgerIcon } from "../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt, objEncrypt, removingUserFromSession, setEncryptedID } from "../../utils/utils";
import Alert from "../UI/Alert";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import ProfileModal from "../UI/ProfileModal";
import Styles from "./index.module.css";
import Input from "./Input";
import LoginPopup from "./LoginPopup";
import LoadingCircle from "../../components/LoadingCircle";
import appLogo from "../../assets/appLogo/safe_pharmacy_logo.png";
import applogoV2 from "../../assets/sf_svg/safe_pharmacy.svg"
import appLogoPro from "../../assets/pro_app_logo.png";
import { CART_COUNT, LOGGED_IN_USER_ROLE, LOGINS_IN_BY, SG_PATIENT_ID, USER_LOGGED_IN, USER_PROFILE } from "../../config/Config";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from "../UI/VerificationModal";
import { decryptData } from "../../utils/util_helper";
import Medx_white from "../../assets/svg/Medx_white.svg"

const HeaderMenu = [
  { id: "0", value: "Sexual Health" },
  { id: "1", value: "Anxiety" },
  { id: "2", value: "Personal Care" },
  { id: "3", value: "Medicine & Health" },
  { id: "4", value: "Beauty" },
  { id: "5", value: "Pets" },
];

function Header(props: any) {
  const { setOpenCompanyProfileCard, setOpenCreateCompanyCard } = props;
  const [isNavMenu, setIsNavMenu] = React.useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [suggestUsInit] = useContext(APIContext);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const [categoryContext, setCategoryContext] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>([]);
  const { typeID } = useParams();
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [displayOut, setDisplayOut] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartCount, setcartCount] = useState<any>();
  const [globalContext] = useContext(GlobalContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryAlpha, setCategoryAlpha] = useState([]);
  // categoryAlpha loading
  const [catLoading, setLoading] = useState(true);
  const [SearchValue, setSearchValue] = useState<string>('');
  const searchRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isRxPopup, setIsRxPage] = useState({
    isActive: false,
    data: ""
  })
  const navigate = useNavigate();
  const timer = useRef();
  //getting current route
  const location = useLocation();
  const currentRoute = location.pathname;
  const isCategoryPage = currentRoute === "/categories"
  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  const isUserLoggedIn = sessionStorageCopy && Object.keys(sessionStorageCopy)?.length > 0 ? true : false;
  const ref: any = useRef(null);
  const refSearch: any = useRef(null);
  const [selectedAlpha, setSelectedAlpha] = useState("");
  const [ sIsMobileScreen, setIsMobileScreen] = useState(false);
  var intervalID;
  if (isLoggedIn) {
    intervalID = setInterval(myCallback, 1000);
  }


  function myCallback() {
    if (getDecryptedID(CART_COUNT) !== undefined) {
      setcartCount(Number(getDecryptedID(CART_COUNT)));
    }
  }


  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, searchText.length - 1)
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedIndex !== -1) {
        navigate(`/product-detail/${searchText[selectedIndex]?.srv_id}`);
        setDisplayOut(false);
        setSelectedIndex(-1);
      }
    }
  };



  useEffect(() => {
    setSelectedIndex(-1);
  }, [displayOut])


  // API call for ensuring the cart count when first render or to resolve home page cart count issues
  useEffect(() => {

    if (suggestUsInit?.returnCode === true) {

      const UserDetailsJson = getDecryptedID(USER_PROFILE);
      // const UserDetailsJson = JSONCheckerFunc(userDetails);
      const customerId = UserDetailsJson?.patient_id;
      CallAPI(config?.cart_count_process_id, {
        p_customer_id: customerId,
      }).then((res) => {
        if (res?.returnCode) {
          setEncryptedID(CART_COUNT, res?.returnData[0]?.cart_item_count);
          setcartCount(Number(res?.returnData[0]?.cart_item_count));
        }
      });
    }
    // }
  }, [getDecryptedID(CART_COUNT)]);

  useEffect(() => {
    setIsLoggedIn(isUserLoggedIn);
  }, [getDecryptedID(USER_LOGGED_IN), isUserLoggedIn]);

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      const userLoggedIn = getDecryptedID(USER_PROFILE);
      let p_max_offset;
      const userType = sessionStorageCopy?.usr_type || "";
      if (!userLoggedIn) {
        p_max_offset = "10";
      } else
        if (userType === "pro") {
          p_max_offset = "7";
        } else if (userType === "customer") {
          p_max_offset = "7";
        } else {
          p_max_offset = "10";
        }
      CallAPI(config?.category_list_process_id, {
        p_max_offset: p_max_offset,
        p_fav_flag: "Y"
      }).then(
        (res) => {
          if (res?.returnCode) {
            res?.returnData?.length > 0 && setCategoryContext(res?.returnData);
            //   // //getting alpha values of categories ======>
            //   let cateAlphaArray = res?.returnData?.map((items: any) => {
            //     // return items?.sg_description[0] || "";
            //     return items?.sg_description[0] || "";
            // })
            // const uniqueArray = cateAlphaArray?.filter((value: any, index: any, self: any) => {
            //     return self.indexOf(value) === index;
            // });
            // let i;
            // let abcdArray: string[] = [];
            // for (i = 65; i <= 90; i++) {
            //     abcdArray.push(String.fromCharCode(i));
            // }
            // let updatedArray: any[]  = []
            // abcdArray?.map((abcdItems, idx)=>{
            //     updatedArray.push( {
            //         alpha_id: `${idx + 1}`,
            //         alpha_char: `${abcdItems}`,
            //         isSelected: false,
            //         isAvailable : uniqueArray.includes(abcdItems)
            //     })
            // })
            // //@ts-ignore
            // setCategoryAlpha(updatedArray);
            // const encryptedAlphas = objEncrypt(updatedArray);
            // sessionStorage.setItem("allCategories", encryptedAlphas);
          }
        }
      );
    }
  }, [suggestUsInit, getID(USER_PROFILE)]);


  // getting all categories from the session 
  useEffect(() => {
    try {
      if (isCategoryPage) {
        // setCategoryAlpha(objDecrypt(sessionStorage.getItem("allCategories"))) 
        if (context?.allCategories) {
          let encryptedCategories = objDecrypt(context?.allCategories)
          setCategoryAlpha(encryptedCategories);
          setLoading(false);
        }
      }
      setIsRxPage((prev: any) => {
        return { ...prev, isActive: context?.isPrescriptionUploaded, data: context?.data }
      })
    } catch (error) {
      console.error(error);
    }
  }, [context])

  //Handling out side click of modal
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (profileModalOpen && event.target.id !== "usernameButtonId") {
        setProfileModalOpen(false);
      }
    }
  };
  useEffect(() => {
    if (profileModalOpen)
      document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [profileModalOpen]);

  const handleClickSearchOutside = (event: any) => {
    if (refSearch.current && !refSearch.current.contains(event.target)) {
      if (event.target.id === "searchbar") {
        setDisplayOut(true);
      } else {
        setDisplayOut(false);
      }
    }
  };
  useEffect(() => {
    if (displayOut)
      document.addEventListener("click", handleClickSearchOutside, true);
    return () => {
      document.removeEventListener("click", handleClickSearchOutside, true);
    };
  }, [displayOut]);

  const action = (val: any) => {
    if (val !== "") {
      setIsLoading(true);
      setSearchText([]);
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.xcelecomconfig_get_mst_ecom_product_list_for_search, {
          p_search_text: val,
          p_max_offset: "10",
        }).then((res) => {
          if (res?.returnCode) {
            if (res?.returnData.length > 0) {
              let updated = res?.returnData.map((items: any) => {
                return { ...items, isActive: false }
              })
              setSearchText([...updated]);
              setIsLoading(false);
            } else {
              setSearchText([]);
              setIsLoading(false);
            }
          } else {
            setSearchText([]);
            setIsLoading(false);
          }
        });
      }
    }
  };
  const debounceSearch = debounce(action, 300);

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (context?.searchBox === "") {
      // setSearchText([]);
    }
  }, [context?.searchBox]);

  useEffect(()=>{
      try {
          const windowSize = window.innerWidth;
          if(windowSize <= 768){
            setIsMobileScreen(true);
          }else {
            setIsMobileScreen(false);
          }
      } catch (error) {
        console.error(error);
      }
  },[window.innerWidth])



  // useEffect(() => {
  //   const delay = 1000; // Adjust the delay (in milliseconds) as needed
  //   let timeoutId = "";

  //   const handleTypingStopped = () => {
  //     // Perform the desired action when typing is stopped
  //     // setSearchText([]);
  //     setIsLoading(true);
  //     debounceSearch(context?.searchBox);
  //   };

  //   if (context?.searchBox) {
  //     //@ts-ignore
  //     timeoutId = setTimeout(handleTypingStopped, delay);
  //   }
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [context?.searchBox]);

  const onInputChangeHandler = (e: any, fieldName: any) => {

    if (e.target.value.trim() === "") {
      setContext((prev: any) => ({
        ...prev,
        searchBox: "",
      }));
    } else {
      setContext((prev: any) => ({
        ...prev,
        searchBox: e?.target?.value,
      }));
    }
  };

  const handleClickOnAlpha = (alphaObject: any) => {
    try {
      let updatedData = categoryAlpha?.map((items: any) => {
        if (items?.alpha_id === alphaObject?.alpha_id) {
          return { ...items, isSelected: true }
        }
        return { ...items, isSelected: false }
      })
      //@ts-ignore
      setCategoryAlpha(updatedData);
      // sessionStorage.setItem("searchCategoryObject", alphaObject);
      setContext((prev: any) => {
        return { ...prev, categorySearchObject: alphaObject || {} }
      })
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    try {
      if (JSON.stringify(context?.categorySearchObject) === "{}") {
        setSelectedAlpha("")
      } else {
        setSelectedAlpha(context?.categorySearchObject?.alpha_id);
      }
    } catch (error) {
      console.error(error);
    }
  }, [context])

  const handleClearAlphaFilter = () => {
    let updatedData = categoryAlpha?.map((items: any) => {
      return { ...items, isSelected: false }
    })
    //@ts-ignore
    setCategoryAlpha(updatedData);
    setContext((prev: any) => {
      return { ...prev, categorySearchObject: {} }
    })
  }
  const handleInputChange = (e: any) => {
    if (timer) {
      clearTimeout(timer.current);
    }

    if (e.target.value !== '') {
      setSearchValue(e.target.value)
      setDisplayOut(true);
      setIsLoading(true);
      // @ts-ignore
      timer.current = setTimeout(() => {
        debounceSearch(e.target.value);
      }, 1000);
    } else {
      setDisplayOut(false);
      setSearchValue("")
    }

  };

  // useEffect(() => {
  //   // clearTimeout(debounceTimer.current);
  //   timer.current = setTimeout(() => {
  //     if (SearchValue !== '') {
  //       // setIsLoading(true);
  //       // debounceSearch(SearchValue);
  //     }
  //   }, 2000);
  // }, [SearchValue]);
  // const handleTypingStopped = () => {
  //   // Perform the desired action when typing is stopped
  //   // setSearchText([]);
  //   // setIsLoading(true);
  //   // debounceSearch(e.target.value);
  // };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={Styles.header}>
        <div className="outer_container">
          <div className={Styles.main_header}>
            <div className={Styles["navLeft2"]}>
              <div className={Styles["headerNavmenu"]}>
                <IconButton
                  title="Open menu"
                  onClick={() => setIsNavMenu(true)}
                >
                  <HamburgerIcon
                    width="36"
                    height="36"
                    className={Styles["navMenuIcon"]}
                  />
                </IconButton>
              </div>
              <div className={`${Styles["headerLogo"]} ${Styles["desktop_logo"]}`}>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  draggable="false"
                // onClick={()=> navigate("/")}
                >
                  <img src={context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? appLogoPro : applogoV2} alt="app_logo" draggable="false" />
                </Link>
              </div>
            </div>
            <div className={`${Styles["headerLogo"]} ${Styles["mobile_logo"]}`}>
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                draggable="false"
                onClick={() => navigate("/")}
              >
                <img src={context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? appLogoPro : applogoV2} alt="app_logo" draggable="false" />
              </Link>
            </div>
            <div className={Styles["navRight2"]}>
              <div className={Styles["bottomMenu"]}>
                { !sIsMobileScreen &&  <div className={Styles["headerSearchbox"]}>
                  <div className={Styles["headerSearchbar"]}>
                    <Input
                      ref={searchRef}
                      name="searchbar"
                      id="searchbar"
                      type="searchbar"
                      placeholder={Config?.header_searchbar_placeholder}
                      size="medium"
                      // value={SearchValue}
                      maxLength={50}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    // onChange={(e: any) => {

                    // }}
                    // onChange={(e: any) => {
                    //   onInputChangeHandler(e, "");
                    // }}
                    // onFocus={() => {
                    //   // setDisplayOut(true);
                    // }}
                    />
                    {displayOut && (
                      <div ref={refSearch}>
                        <div className={`${Styles?.searchDropDown} `}>
                          <ul>
                            <li
                              className={`${Styles?.searchDropDownItem} ${Styles?.disabled}`}
                            >
                              <div></div>
                            </li>
                            {isLoading ? <div
                              className={Styles['loadingSpinner']}
                            >
                              <i className="fa fa-spinner fa-spin"></i>

                            </div> :
                              searchText?.length > 0 ?
                                searchText?.map((item: any, idx: any) => (
                                  <li
                                    key={item?.srv_id}
                                    className={`${Styles?.searchDropDownItem} ${idx === selectedIndex ? Styles.ArrowActive : ""
                                      }`}

                                    onClick={() => {
                                      navigate(`/product-detail/${item?.srv_id}`);
                                      setDisplayOut(false);
                                    }}
                                  >
                                    <div>{item?.srv_name}</div>
                                  </li>
                                )) :

                                <div
                                  style={{ textAlign: "center" }}
                                  className={`${Styles?.noProductsFound}`}
                                >
                                  No products found
                                </div>

                            }


                            {/* {searchText?.length > 0 ? (
                              
                            ) : isLoading ? (
                              <div
                                className={Styles['loadingSpinner']}
                              >
                                <i className="fa fa-spinner fa-spin"></i>

                              </div>
                            ) : (
                              <div
                                style={{ textAlign: "center" }}
                                className={`${Styles?.noProductsFound}`}
                              >
                                No products found
                              </div>
                            )} */}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  <img
                    draggable="false"
                    src={MagnifyingGlass}
                    alt="Magnifying icon"
                    className={Styles["searchImgButton"]}

                    onClick={() => {
                      // if (SearchValue !== "") {
                      //   action(SearchValue);
                      //   setDisplayOut(true);
                      //   // setIsLoading(true);
                      // }
                      if (SearchValue === "") {

                      } else {
                        action(SearchValue);
                        setDisplayOut(true);
                      }
                    }}
                  />
                </div>}
                <div className={Styles["medxLogo"]}>
                  <Link to="/medex"
                   style={{ textDecoration: "none" }}
                   onClick={scrollToTop}>
                  <img src={Medx_white} draggable="false"/>
                  </Link>
                </div>
                <div className={Styles["cartButton"]}>
                {context?.UserAuth?.usr_type === "customer" ? <div
                  // title={Config?.header_rightmenu_6}
                  // to={`${Object.keys(sessionStorageCopy)?.length > 0 ? "/submit-rx/upload-rx" : ""
                  //   }`}
                  style={{ textDecoration: "none" }}
                  className={Styles["bottomMenuButton"]}
                  onClick={() => {
                    sessionStorage.removeItem("orderDetails");
                    
                    // if (Object.keys(sessionStorageCopy)?.length > 0) {
                    //   setIsUserLogin(false);
                    // } else {
                    //   setIsUserLogin(true);
                    // }
                    // setContext((prev:any)=>{
                    //     return {...prev, isRxUploadActive : true}
                    // })
                    navigate("/rx-page")
                  }}
                  draggable="false"
                >
                  {/* <img src={MyRX} alt="MyRX icon" draggable="false" /> */}
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.75 0H7.75C2.32 0 0 2.32 0 7.75V13.75C0 19.18 2.32 21.5 7.75 21.5H13.25C13.66 21.5 14 21.16 14 20.75C14 20.34 13.66 20 13.25 20H7.75C3.14 20 1.5 18.36 1.5 13.75V7.75C1.5 3.14 3.14 1.5 7.75 1.5H13.75C18.36 1.5 20 3.14 20 7.75V11C20 11.41 20.34 11.75 20.75 11.75C21.16 11.75 21.5 11.41 21.5 11V7.75C21.5 2.32 19.18 0 13.75 0Z" fill="#006699"/>
<path d="M18.3578 21.4007C17.8888 21.4007 17.5 20.9924 17.5 20.5V13.8936C17.5 13.5333 17.7059 13.1971 18.0261 13.0649C18.3463 12.9328 18.7123 13.0049 18.9639 13.2571L21.2513 15.659C21.5829 16.0073 21.5829 16.5837 21.2513 16.932C20.9196 17.2803 20.3706 17.2803 20.039 16.932L19.2155 16.0673V20.5C19.2155 20.9924 18.8267 21.4007 18.3578 21.4007Z" fill="#006699"/>
<path d="M15.8555 17C15.6381 17 15.4206 16.9199 15.2489 16.7484C14.917 16.4167 14.917 15.8678 15.2489 15.5361L17.5379 13.2487C17.8698 12.9171 18.4192 12.9171 18.7511 13.2487C19.083 13.5804 19.083 14.1294 18.7511 14.461L16.4621 16.7484C16.2904 16.9199 16.073 17 15.8555 17Z" fill="#006699"/>
<path d="M11.9705 9.07692L10.8995 10.5628L9.7052 8.85354C10.6448 8.45585 11.3 7.56823 11.3 6.53846C11.3 5.13892 10.0886 4 8.6 4H6.8C5.8073 4 5 4.759 5 5.69231V10.7692H6.8V9.07692H7.8179L9.8789 11.9784L7.7 15H9.6746L10.8662 13.3686L12.0254 15H14L11.885 11.9733L14 9.07692H11.9705ZM6.8 5.69231H8.6C9.0959 5.69231 9.5 6.07223 9.5 6.53846C9.5 7.00469 9.0959 7.38462 8.6 7.38462H6.8V5.69231Z" fill="#006699"/>
</svg>
                  <p className={Styles["bottomMenuText"]}>
                    {Config?.header_rightmenu_6}
                  </p>
                </div> : ""}
                  {!isUserLoggedIn ?
                    <>
                      {/* <Link
                        to="/log-in"
                        style={{ textDecoration: "none" }}
                        draggable="false"
                        onClick={() => {
                          setEncryptedID(LOGINS_IN_BY, "pro")
                          navigate("/log-in")
                        }}
                      >
                        <Button type={Config?.lbl_Btn_type_primary} size="small">
                          <img
                            src={ProUserIcon}
                            alt={Config?.header_rightmenu_5}
                            className={Styles["userIcon"]}
                            draggable="false"
                          />
                          <span className={Styles["cart_item_name"]}>
                            {Config?.header_rightmenu_5}
                          </span>
                        </Button>
                      </Link> */}

                      <Link
                        to="/log-in"
                        style={{ textDecoration: "none" }}
                        draggable="false"
                        onClick={() => {
                          setEncryptedID(LOGINS_IN_BY, "customer")
                          navigate("/log-in")
                        }}
                      >
                        <Button type={Config?.lbl_Btn_type_primary} size="small">
                          {/* <img
                            src={UserIcon}
                            alt={Config?.lbl_login_txt}
                            className={Styles["userIcon"]}
                            draggable="false"
                          /> */}
                          <i className="fa-regular fa-user" draggable="false"></i>

                          <span className={Styles["cart_item_name"]}>
                            {Config?.lbl_login_txt}
                          </span>
                        </Button>
                      </Link>
                    </>
                    :
                    context?.UserAuth?.usr_type === "pro" ?
                      "" :
                      ""
                    // <Button type={Config?.lbl_Btn_type_primary} size="small"
                    //   onClick={() => {
                    //     setEncryptedID(LOGINS_IN_BY, "pro")
                    //     if (context?.UserAuth?.usr_type !== "pro") {
                    //       const { setIsLogoutPopup } = props;
                    //       setIsLogoutPopup(true);
                    //     } 
                    //   }}>
                    //   <img
                    //     src={ProUserIcon}
                    //     alt={Config?.header_rightmenu_5}
                    //     className={Styles["userIcon"]}
                    //     draggable="false"
                    //   />
                    //   {Config?.header_rightmenu_5}
                    // </Button>
                  }
                  {/* {context?.UserAuth?.usr_type !== "pro" && (
                    <Button
                      className={Styles["cartBtn"]}
                      type="ghost"
                      size="small"
                      onClick={() => {
                        if (JSON.stringify(context?.UserAuth) === "{}") {
                          navigate("/log-in");
                        } else if (context?.UserAuth?.usr_type !== "pro") {
                          const { setIsLogoutPopup } = props;
                          setIsLogoutPopup(true);
                        }
                      }}
                    >
                    </Button>
                  )} */}

                </div>
              </div>
            </div>
          </div>
        {sIsMobileScreen && <div className={Styles.main_header_for_mobile}>
          <div className={Styles["navRight2Mobile"]}>
            <div className={Styles["bottomMenuMobile"]}>
              <div className={Styles["headerSearchboxMobile"]}>
                <div className={Styles["headerSearchbarMobile"]}>
                  <Input
                    ref={searchRef}
                    name="searchbar"
                    id="searchbar"
                    type="searchbar"
                    placeholder={Config?.header_searchbar_placeholder}
                    size="medium"
                    // value={SearchValue}
                    maxLength={50}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  // onChange={(e: any) => {

                  // }}
                  // onChange={(e: any) => {
                  //   onInputChangeHandler(e, "");
                  // }}
                  // onFocus={() => {
                  //   // setDisplayOut(true);
                  // }}
                  />
                  {displayOut && (
                    <div ref={refSearch}>
                      <div className={`${Styles?.searchDropDown}  ${isUserLoggedIn ? isCategoryPage  ? Styles?.topForty :  Styles?.topSixty : isCategoryPage  ? Styles?.topFortyThree :  Styles?.topHundered}`}>
                        <ul>
                          <li
                            className={`${Styles?.searchDropDownItem} ${Styles?.disabled}`}
                          >
                            <div></div>
                          </li>
                          {isLoading ? <div
                            className={Styles['loadingSpinner']}
                          >
                            <i className="fa fa-spinner fa-spin"></i>

                          </div> :
                            searchText?.length > 0 ?
                              searchText?.map((item: any, idx: any) => (
                                <li
                                  key={item?.srv_id}
                                  className={`${Styles?.searchDropDownItem} ${idx === selectedIndex ? Styles.ArrowActive : ""
                                    }`}

                                  onClick={() => {
                                    navigate(`/product-detail/${item?.srv_id}`);
                                    setDisplayOut(false);
                                  }}
                                >
                                  <div>{item?.srv_name}</div>
                                </li>
                              )) :

                              <div
                                style={{ textAlign: "center" }}
                                className={`${Styles?.noProductsFound}`}
                              >
                                No products found
                              </div>

                          }


                          {/* {searchText?.length > 0 ? (
                            
                          ) : isLoading ? (
                            <div
                              className={Styles['loadingSpinner']}
                            >
                              <i className="fa fa-spinner fa-spin"></i>

                            </div>
                          ) : (
                            <div
                              style={{ textAlign: "center" }}
                              className={`${Styles?.noProductsFound}`}
                            >
                              No products found
                            </div>
                          )} */}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <img
                  draggable="false"
                  src={MagnifyingGlass}
                  alt="Magnifying icon"
                  className={Styles["searchImgButton"]}

                  onClick={() => {
                    // if (SearchValue !== "") {
                    //   action(SearchValue);
                    //   setDisplayOut(true);
                    //   // setIsLoading(true);
                    // }
                    if (SearchValue === "") {

                    } else {
                      action(SearchValue);
                      setDisplayOut(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>}
        </div>
        <div className={Styles["top_menu_wrapper"]}>
          <div className="outer_container">
            <div className={`${Styles["topMenu"]} ${isUserLoggedIn ? "" : Styles["ifNotLoggedIn"]}`}>
              {categoryContext.length !== 0 && (
                <div className={Styles["navMenu2"]}>
                  {categoryContext?.map((item: any) => {
                    return (
                      <Link
                        to={`/product-list/${item?.sg_id}`}
                        style={{}}//, borderRight: "1px solid"//textDecoration: "none"
                        className={`${Styles["menuLinks"]} ${item?.sg_id === typeID ? Styles["menuLinksActive"] : ""}`}
                        key={item?.sg_id}
                        draggable="false"
                      >
                        {item.sg_description}
                      </Link>
                    );
                  })}
                  <Link
                    to="/categories"
                    style={{ textDecoration: "none" }}
                    className={`${Styles["menuLinks"]} ${isCategoryPage ? Styles["menuLinksActive"] : ""}`}
                    draggable="false"
                  >
                    {Config?.tmpnav_more}
                  </Link>
                </div>
              )}
              {isUserLoggedIn ? (
                <div className={Styles["userNavMenu"]}>
                  <div
                    // title={Config?.header_rightmenu_2}
                    // to="/purchase"
                    style={{ textDecoration: "none" }}
                    className={Styles["bottomMenuButton"]}
                    onClick={() => {
                      // if (isUserLoggedIn) {
                      //   setIsUserLogin(false);
                      navigate("/purchase");
                      // } else {
                      //   setIsUserLogin(true);
                      // }
                    }}
                  // draggable="false"
                  >
                    {/* <Button
                        className={Styles["cartBtn"]}
                        type="ghost"
                        size="small"
                      > */}
                    <img
                      src={CartIcon}
                      alt={Config?.header_rightmenu_5}
                      className={Styles["proUseicon"]}
                    // draggable="false"
                    />
                    <p className={Styles["bottomMenuText"]}>
                      {Config?.header_rightmenu_2}
                    </p>
                    {cartCount !== 0 && (
                      <NotificationBadge
                        count={cartCount}
                        style={{
                          backgroundColor: "#f2971f",
                          top: "-15px",
                          left: "-8px",
                          right: "",
                        }}
                      />
                    )}
                    {/* </Button> */}
                  </div>
                  {/* {context?.UserAuth?.usr_type === "customer" ? <div
                    // title={Config?.header_rightmenu_6}
                    // to={`${Object.keys(sessionStorageCopy)?.length > 0 ? "/submit-rx/upload-rx" : ""
                    //   }`}
                    style={{ textDecoration: "none" }}
                    className={Styles["bottomMenuButton"]}
                    onClick={() => {
                      sessionStorage.removeItem("orderDetails");
                      
                      // if (Object.keys(sessionStorageCopy)?.length > 0) {
                      //   setIsUserLogin(false);
                      // } else {
                      //   setIsUserLogin(true);
                      // }
                      // setContext((prev:any)=>{
                      //     return {...prev, isRxUploadActive : true}
                      // })
                      navigate("/rx-page")
                    }}
                    draggable="false"
                  >
                    <img src={MyRX} alt="MyRX icon" draggable="false" />
                    <p className={Styles["bottomMenuText"]}>
                      {Config?.header_rightmenu_6}
                    </p>
                  </div> : ""} */}
                  <div className={`${Styles?.filterButton}`}>
                    <div
                      id="usernameButtonId"
                      style={{ textDecoration: "none" }}
                      className={Styles["usernameButton"]}
                      onClick={(e) => {
                        if (isUserLoggedIn) {
                          setProfileModalOpen(!profileModalOpen);
                        } else {
                          setIsUserLogin(true);
                        }
                      }}
                    >
                      {/* <div className={Styles["userImage"]}>
                          <img
                            src={globalContext?.profile_image || userSkeleton}
                            alt="ProfileImg"
                            draggable="false"
                          />
                        </div> */}

                      <img src={DropDownIcon} alt="Profile" draggable="false" />
                      <p className={Styles["bottomMenuText"]}>
                        {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ?
                          // ""
                          // username
                          Config?.header_rightmenu_7
                          :

                          context?.UserAuth?.usr_name?.trim("") === ""
                            ? Config?.header_rightmenu_7
                            : context?.UserAuth?.usr_name}
                      </p>
                      {/* {
                          sessionStorageCopy?.usr_name === "" ? <p className={Styles["bottomMenuText"]}>{Config?.header_rightmenu_7}</p> 

                            : <p className={Styles["bottomMenuText"]}>{sessionStorageCopy?.usr_name}</p> 
                            } */}
                    </div>
                    {profileModalOpen && (
                      <div ref={ref} id="">
                        <ProfileModal setModalOpen={setProfileModalOpen}
                          setOpenCompanyProfileCard={setOpenCompanyProfileCard}
                          setOpenCreateCompanyCard={setOpenCreateCompanyCard} />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* HEADER div */}
          </div>
        </div>
        {isCategoryPage ?
          categoryAlpha.length > 0 &&
          <div className={Styles?.categoryAlpha}>
            <div className="outer_container">
              <div className={Styles?.category_inner}>

                <h4>Category:</h4><span className={Styles?.cateAlphaList}>
                  {categoryAlpha?.map((items: any, idx: any) => {
                    return <p className={`${items?.alpha_id === selectedAlpha ? Styles?.alphaActive : ""} ${items?.isAvailable ? "" : Styles?.alphaIsAvailable}`} key={items + "##" + idx}
                      onClick={() => {
                        items?.isAvailable && handleClickOnAlpha(items);
                      }}
                    >{items?.alpha_char}</p>
                  })}
                  {selectedAlpha === "" ? "" : <p
                    onClick={handleClearAlphaFilter}
                    className={`${Styles?.clear_category}`}
                  >Clear</p>}

                </span>
              </div>
            </div>
          </div>
          : ""}
      </div>
      <SideDrawer setIsOpen={setIsNavMenu} isOpen={isNavMenu}>
        {categoryContext?.map((item: any, idx: number) => (
          <div key={item?.sg_code}>
            <Link
              onClick={() => setIsNavMenu(false)}
              to={`/product-list/${item?.sg_id}`}
              style={{ textDecoration: "none" }}
              className={Styles["drawerMenuLinks"]}
              draggable="false"
            >
              {item.sg_description}
            </Link>
          </div>
        ))}
        <div>
          <Link
            onClick={() => setIsNavMenu(false)}
            to={`/categories`}
            style={{ textDecoration: "none" }}
            className={Styles["drawerMenuLinks"]}
            draggable="false"
          >
            {Config?.tmpnav_more.toLowerCase()}
          </Link>


        </div>
      </SideDrawer>
      {isUserLogin && <LoginPopup setModalOpen={setIsUserLogin} />}
      {isRxPopup?.isActive &&
        <Modal
          detail={Config?.uploadRx_Modal_detail__1 + " " + isRxPopup?.data}
          heading={Config?.uploadRx_Modal_Heading}
        />}
    </>
  );
}

export default Header;
