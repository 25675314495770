import React, { useContext, useEffect, useState, Fragment } from "react";

import Slider from "react-slick";
import styles from "./index.module.css";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import ProductCard from "../../../components/ProductCard";
import WidgetCard from "../../../components/UI/WidgetCard";
import { H3 } from "../../../components/UI/Typography";
import { ChevronLeft, ChevronRight } from "../../../utils/appIcons";
import Alert from "../../../components/UI/Alert";
import { Link } from "react-router-dom";
import btnConfig from "../../../config/button";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import AddtocartPopup from "../../../components/ProductDetails/AddtocartPopup";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { AppAlertContext } from "../../../store/alert-context.js";
import ReactGA from "react-ga";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { objDecrypt, getDecryptedID, JSONCheckerFunc, getID, setEncryptedID } from "../../../utils/utils";
import { siteConfig } from "../../../config/site";
import { color_config } from "../../../config/colorConfig";
import { CART_COUNT, LOGGED_IN_USER_ROLE, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import { json } from "stream/consumers";
import { decryptData } from "../../../utils/util_helper";

export type productsProps = {
  widget: any;
  setIsAlert: any;
  isAlert: any;
};

const ProductWidget: React.FC<productsProps> = (props) => {
  const [productContext, setProductContext] = useState<any>([]);
  // const [isAlert, setIsAlert] = useState({ type: "", msg: "" });
  const [context, setContext] = useContext(UserAuthContext);
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [productdetails, setProductDetails] = useState<any>("");
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const [loading, setLoading] = useState({
    isTrue: false,
    productId: "",
  });

  const gaEventTracker = useAnalyticsEventTracker("Home page");
  const [suggestUsInit] = useContext(APIContext);
  const { widget, setIsAlert, isAlert } = props;

  const { widget_type_reference_details_view_method: widgetType } = widget || {
    widget_type_reference_details_view_method: "",
  };

  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  let isUserLoggedIn = sessionStorageCopy && Object.keys(sessionStorageCopy)?.length > 0 ? true : false;

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.next_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  useEffect(() => {
    if (isAlert) {
      setTimeout(() => {
        setIsAlert({
          istrue: false,
          type: "",
          msg: "",
        });
      }, 5000);
    }
  }, []);

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.prev_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  const horizontal_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const thumb_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_wshlst_id: product?.wshlst_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker("Items removed from wishlist");
          setProductContext(
            productContext?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );
        }
      });
    }
  };

  const wishListAddHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
        p_srv_id: product?.srv_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          gaEventTracker("Items added to wishlist");
          // getProductsDetails();

          setProductContext(
            productContext?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );
        }
      });
    }
  };

  const likeButtonHandler = (product: any) => {
    switch (product?.wshlst_status) {
      case "Y":
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
  };

  const addCartHandler = (product: any, unitInfoObject: any) => {
    let params = {};
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      if (JSON.stringify(unitInfoObject) === "{}") {
        //@ts-ignore
        params.p_service_detail_string = product?.srv_id + "~" + "1";
        //@ts-ignore
        params.p_pack_detail_id = "";
      } else {
        //@ts-ignore
        // params.p_service_detail_string = product?.srv_id + "~" + unitInfoObject?.productQuantity;
        params.p_service_detail_string = product?.srv_id + "~" + "1";
        //@ts-ignore
        params.p_pack_detail_id = `${unitInfoObject?.unitId}`;
      }
      //@ts-ignore
      params.p_usr_id = decryptData(getID(SG_USER_ID));
      //@ts-ignore
      params.p_flag = "OTC";
      //@ts-ignore
      params.p_patient_id = decryptData( getID(SG_PATIENT_ID));
      setLoading({
        isTrue: true,
        productId: product?.srv_id,
      });
      CallAPI(config?.customer_cart_add_product_process_id, params).then(
        (res: any) => {
          if (res?.returnCode) {
            if (res?.returnData[0].cart_count !== undefined) {
              setEncryptedID(CART_COUNT,res?.returnData[0].cart_count);
              gaEventTracker(
                "Items added to cart",
                res?.returnData[0].cart_count
              );
              setAlertContext({
                status: false,
                type: "success",
                info: res?.msg,
                cartCount: res?.returnData[0].cart_count,
              });
            } else {
              setEncryptedID(CART_COUNT,res?.returnData[0].cart_item_count);
              gaEventTracker(
                "Items added to cart",
                res?.returnData[0].cart_item_count
              );
              setAlertContext({
                status: false,
                type: "success",
                info: res?.msg,
                cartCount: res?.returnData[0].cart_item_count,
              });
            }
            setProductDetails(product);
            setIsCartPopUPOpen(siteConfig?.checkout_popup);
            setLoading({
              isTrue: false,
              productId: "",
            });
            setIsAlert({
              istrue: true,
              type: "success",
              msg: res?.msg,
            });
          } else {
            setIsAlert({
              istrue: true,
              type: "error",
              msg: res?.msg,
            });
            setLoading({
              isTrue: false,
              productId: "",
            });
          }
        }
      );
    }
  };

  const HorizontalScroll = () => {
    return (
      <Fragment>
        <div className={`best_seller_main  ${styles?.best_seller_section}`}>
          <div className="outer_container">
            <div
              className={`flex items-center gap-2 justify-between  ${styles["best_seller_title"]}`}
            >
              <H3 className={`${styles?.title}`} style={{ color: `` }}>
                {widget?.html_description}
              </H3>
              <Link
                to={`${"/product-list/"}${widget?.widget_type_reference_details
                  }`}
                className={`${styles?.linkButton}`}
                draggable="false"
              >
                {btnConfig?.widget_link_button}
              </Link>
            </div>

            <Slider {...horizontal_settings}>
              {productContext.length === 0
                ? [1, 2, 3, 4, 5]?.map((item: any, idx: any) => {
                  return (
                    <div
                      className={`${styles?.horizontal_wrapper}`}
                      key={idx}
                    >
                      <ShimmerThumbnail height={250} rounded />
                    </div>
                  );
                })
                : Array?.isArray(productContext) &&
                productContext?.map((product: any, idx: any) => {
                  const packSizeJson =
                    JSONCheckerFunc(product?.packsize_json) || [];
                  return (
                    <div
                      className={`${styles?.horizontal_wrapper}`}
                      key={idx}
                    >
                      <ProductCard
                        product={product}
                        pricingData={packSizeJson}
                        type={product?.service_type}
                        viewType="Grid"
                        productUrl={product?.srv_id}
                        imgUrl={product?.srv_image.split("~")[0]}
                        imgAlt={product?.srv_name}
                        detail={product?.srv_name}
                        packagingId={product?.srv_packaging}
                        brand={product?.srv_brand}
                        price={product?.discounted_amount}
                        actualPrice={product?.srv_price}
                        discountPercent={product?.discount_percent}
                        coPay={product?.copayment_amount}
                        loading={loading}
                        ratingCount={
                          product?.rating_view_flag === "Y" &&
                          product?.rating_star_count
                        }
                        isWishListAdded={
                          product?.wshlst_status === "Y" ? true : false
                        }
                        ribbon={true}
                        ribbonType="folded"
                        productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                        onLikeButtonClick={() => {
                          if (isUserLoggedIn) {
                            likeButtonHandler(product);
                          } else {
                            setContext((prev: any) => ({
                              ...prev,
                              isLoginPopUP: true,
                            }));
                          }
                        }}
                        onAddtoCartButtonClick={(productQtyObject: any) => {
                          if (isUserLoggedIn) {
                            if (
                              productQtyObject?.productQuantity === "" &&
                              productQtyObject?.unitId === ""
                            ) {
                              setIsAlert({
                                istrue: true,
                                type: "error",
                                msg: "Please select unit price",
                              });
                              setTimeout(() => {
                                setIsAlert({
                                  istrue: false,
                                  type: "",
                                  msg: "",
                                });
                              }, 3000);
                            } else {
                              if (
                                context?.UserAuth?.usr_type ===
                                LOGGED_IN_USER_ROLE
                              ) {
                                addCartHandler(product, productQtyObject);
                              }
                            }
                            if (
                              context?.UserAuth?.usr_type !==
                              LOGGED_IN_USER_ROLE
                            ) {
                              addCartHandler(product, {});
                            }
                          } else {
                            setContext((prev: any) => ({
                              ...prev,
                              isLoginPopUP: true,
                            }));
                          }
                        }}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </Fragment>
    );
  };

  const ThumbnailView = () => {
    return (
      <Fragment>
        <div className={`${styles?.thumbnaiViewProductWidget}`}>
          <div className={`widget_card outer_container `}>
            <div className="flex items-center gap-2 justify-between">
              <H3 className={`${styles?.title}`} style={{ color: `` }}>
                {widget?.html_description}
              </H3>
              <Link
                to={`${"/product-list/"}${widget?.widget_type_reference_details}`}
                className={`${styles?.linkButton}`}
                draggable="false"
              >
                {btnConfig?.widget_link_button}
              </Link>
            </div>
            <div className={`${styles?.vertical_scroll}`}>
              {productContext.length === 0
                ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any, idx: any) => {
                  return (
                    <div className={`${styles?.horizontal_wrapper}`} key={idx}>
                      <ShimmerThumbnail height={250} rounded />
                    </div>
                  );
                })
                : Array?.isArray(productContext) &&
                productContext?.map((product: any, idx: any) => {
                  const packSizeJson =
                    JSONCheckerFunc(product?.packsize_json) || [];
                  return (
                    <div className={`${styles?.horizontal_wrapper}`} key={idx}>
                      <ProductCard
                        product={product}
                        pricingData={packSizeJson}
                        productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                        type={product?.service_type}
                        viewType="Grid"
                        productUrl={product?.srv_id}
                        imgUrl={product?.srv_image.split("~")[0]}
                        imgAlt={product?.srv_name}
                        detail={product?.srv_name}
                        packagingId={product?.srv_packaging}
                        brand={product?.srv_brand}
                        price={product?.discounted_amount}
                        actualPrice={product?.srv_price}
                        discountPercent={product?.discount_percent}
                        coPay={product?.copayment_amount}
                        ratingCount={
                          product?.rating_view_flag === "Y" &&
                          product?.rating_star_count
                        }
                        loading={loading}

                        isWishListAdded={
                          product?.wshlst_status === "Y" ? true : false
                        }
                        ribbon={true}
                        ribbonType="folded"
                        onLikeButtonClick={() => {
                          if (isUserLoggedIn) {
                            likeButtonHandler(product);
                          } else {
                            setContext((prev: any) => ({
                              ...prev,
                              isLoginPopUP: true,
                            }));
                          }
                        }}
                        onAddtoCartButtonClick={(productQtyObject: any) => {
                          if (isUserLoggedIn) {
                            if (
                              productQtyObject?.productQuantity === "" &&
                              productQtyObject?.unitId === ""
                            ) {
                              setIsAlert({
                                istrue: true,
                                type: "error",
                                msg: "Please select unit price",
                              });
                              setTimeout(() => {
                                setIsAlert({
                                  istrue: false,
                                  type: "",
                                  msg: "",
                                });
                              }, 3000);
                            } else {
                              if (
                                context?.UserAuth?.usr_type ===
                                LOGGED_IN_USER_ROLE
                              ) {
                                addCartHandler(product, productQtyObject);
                              }
                            }
                            if (
                              context?.UserAuth?.usr_type !==
                              LOGGED_IN_USER_ROLE
                            ) {
                              addCartHandler(product, {});
                            }
                          } else {
                            setContext((prev: any) => ({
                              ...prev,
                              isLoginPopUP: true,
                            }));
                          }
                        }}
                      />
                    </div>
                  );
                })}
            </div>
            {/* <Slider {...thumb_settings}>
          {productContext.length === 0
            ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any) => {
                return (
                  <div className={`${styles?.horizontal_wrapper}`}>
                    <ShimmerThumbnail height={250} rounded />
                  </div>
                );
              })
            : Array?.isArray(productContext) &&
              productContext?.map((product: any) => {
                return (
                  <div className={`${styles?.horizontal_wrapper}`}>
                    <ProductCard
                      type={product?.service_type}
                      viewType="Grid"
                      productUrl={`/product-detail/${product?.srv_id}`}
                      imgUrl={product?.srv_image.split("~")[0]}
                      imgAlt={product?.srv_name}
                      detail={product?.srv_name}
                      packagingId={product?.srv_packaging}
                      brand={product?.srv_brand}
                      price={product?.discounted_amount}
                      actualPrice={product?.srv_price}
                      discountPercent={product?.discount_percent}
                      coPay={product?.copayment_amount}
                      ratingCount={
                        product?.rating_view_flag === "Y" &&
                        product?.rating_star_count
                      }
                      isWishListAdded={
                        product?.wshlst_status === "Y" ? true : false
                      }
                      ribbon={true}
                      ribbonType="folded"
                      onLikeButtonClick={() => {
                        if (isUserLoggedIn) {
                          likeButtonHandler(product);
                        } else {
                          setContext((prev: any) => ({
                            ...prev,
                            isLoginPopUP: true,
                          }));
                        }
                      }}
                      onAddtoCartButtonClick={() => {
                        if (isUserLoggedIn) {
                          addCartHandler(product);
                        } else {
                          setContext((prev: any) => ({
                            ...prev,
                            isLoginPopUP: true,
                          }));
                        }
                      }}
                    />
                  </div>
                );
              })}
        </Slider> */}
          </div>
        </div>
      </Fragment>
    );
  };

  const VerticalView = () => {
    return (
      <Fragment>
        <div className={`${styles?.thumbnaiViewProductWidget}`}>
          <div className={`widget_card outer_container`}>
            <div className="flex items-center gap-2 justify-between">
              <H3 className={`${styles?.title}`} style={{ color: `` }}>
                {widget?.html_description}
              </H3>
              <Link
                to={`${"/product-list/"}${widget?.widget_type_reference_details}`}
                className={`${styles?.linkButton}`}
                draggable="false"
              >
                {btnConfig?.widget_link_button}
              </Link>
            </div>
            <div className={`${styles?.vertical_scroll}`}>
              {productContext.length === 0
                ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any) => {
                  return <ShimmerThumbnail height={250} rounded key={item} />;
                })
                : productContext?.map((product: any, idx: any) => (
                  <ProductCard
                    product={product}
                    pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                    productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                    key={idx}
                    type={product?.service_type}
                    viewType="Grid"
                    productUrl={product?.srv_id}
                    imgUrl={product?.srv_image.split("~")[0]}
                    imgAlt={product?.srv_name}
                    detail={product?.srv_name}
                    packagingId={product?.srv_packaging}
                    brand={product?.srv_brand}
                    price={product?.discounted_amount}
                    actualPrice={product?.srv_price}
                    discountPercent={product?.discount_percent}
                    coPay={product?.copayment_amount}
                    ratingCount={
                      product?.rating_view_flag === "Y" &&
                      product?.rating_star_count
                    }
                    isWishListAdded={
                      product?.wshlst_status === "Y" ? true : false
                    }
                    ribbon={true}
                    loading={loading}
                    ribbonType="folded"
                    onLikeButtonClick={() => {
                      if (isUserLoggedIn) {
                        likeButtonHandler(product);
                      } else {
                        setContext((prev: any) => ({
                          ...prev,
                          isLoginPopUP: true,
                        }));
                      }
                    }}
                    onAddtoCartButtonClick={(productQtyObject: any) => {
                      if (isUserLoggedIn) {
                        if (
                          productQtyObject?.productQuantity === "" &&
                          productQtyObject?.unitId === ""
                        ) {
                          setIsAlert({
                            istrue: true,
                            type: "error",
                            msg: "Please select unit price",
                          });
                          setTimeout(() => {
                            setIsAlert({
                              istrue: false,
                              type: "",
                              msg: "",
                            });
                          }, 3000);
                        } else {
                          if (
                            context?.UserAuth?.usr_type ===
                            LOGGED_IN_USER_ROLE
                          ) {
                            addCartHandler(product, productQtyObject);
                          }
                        }
                        if (
                          context?.UserAuth?.usr_type !==
                          LOGGED_IN_USER_ROLE
                        ) {
                          addCartHandler(product, {});
                        }
                      } else {
                        setContext((prev: any) => ({
                          ...prev,
                          isLoginPopUP: true,
                        }));
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const getWidget = () => {
    switch (widgetType) {
      case "horizontal_scroll":
        return <HorizontalScroll />;
      case "thumbnail_view":
        return <ThumbnailView />;
      case "vertical_scroll":
        return <VerticalView />;
      default:
        break;
    }
  };

  const getMaxOffset = () => {
    switch (widgetType) {
      case "horizontal_scroll":
        return { p_max_offset: "15" };
      case "thumbnail_view":
        return { p_max_offset: "8" };
      case "vertical_scroll":
        return { p_max_offset: "50" };
      default:
        break;
    }
  };

  const getProductsDetails = () => {
    if (suggestUsInit?.returnCode === true) {
      const patId = decryptData( getID(SG_PATIENT_ID)) === "undefined" ? "" : decryptData(getID(SG_PATIENT_ID))
      CallAPI(config?.product_list_process_id, {
        p_cus_id: patId,
        p_sg_id: widget?.widget_type_reference_details,
        ...getMaxOffset(),
      }).then((res: any) => {
        if (res?.returnCode) {
          res?.returnData.length > 0 && setProductContext(res?.returnData);
        }
      });
    }
  };

  useEffect(() => {
    getProductsDetails();
  }, [suggestUsInit]);

  // Google analytics page tracker

  return (
    <>
      <WidgetCard
        className={`${styles?.container}`}
        style={{ order: `${widget?.sequence}` }}
      >
        {getWidget()}
      </WidgetCard>
      {/* Added to cart pop up */}
      {isCartPopUpOpen && (
        <AddtocartPopup
          img={productdetails?.srv_image.split("~")[0]}
          title={productdetails?.srv_name + productdetails?.srv_packaging}
          setModalOpen={setIsCartPopUPOpen}
        />
      )}
    </>
  );
};

export default ProductWidget;
