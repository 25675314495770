import { createSlice } from "@reduxjs/toolkit";

const unitPricingDropdownSlice = createSlice({
  name: "unitPricingDropdown",
  initialState: {},
  reducers: {
    SELECT_PRICING: (state, action) => {
      state[action.payload.srv_id] = action.payload.selected;
    },
  },
});
export const unitPricingDropdownActions = unitPricingDropdownSlice.actions;
export const unitPricingDropdownReducer = unitPricingDropdownSlice.reducer;
