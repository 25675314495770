import React, { useContext, useEffect, useState } from "react";
import Style from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar";
import Config from "../../config/label";
import Label from "../../components/UI/Label";
import RadioButton from "../../components/UI/RadioButton";
import Button from "../../components/UI/Button";
import TextArea from "../../components/UI/TextArea";
import HealthInfoImage from "../../assets/svg/health_info.svg";
import Input from "../../components/UI/Input";
import { CallAPI } from "../../suggest-us/callSuggest";
import APIContext from "../../store/api-context";
import { config } from "../../config/processId";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { msg_config } from "../../config/messages";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import IconButton from "../../components/UI/IconButton";
import Editaddress_icon from "../../assets/png/icons-edit.png";
import { USER_PROFILE } from "../../config/Config";

const HealthPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formRadio, setFormRadio] = useState("");
  // const [allTxt, setAllTxt] = useState("");
  // const [formTxt, setFormTxt] = useState("");
  const [healthInfoData, setHealthInfoData] = useState({
    allergies_flag: "",
    allergies_medications: "",
    counter_supplements: "",
    last_modify_timestamp: "",
  });
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [update, setUpdate] = useState(false);
  const [healthPageInfo, setHealthPageInfo] = useState({
    allTxt: "",
    info: "",
  });
  const opt = [Config?.health_Info_Rad_1, Config?.health_Info_Rad_2];

  const apiContext = useContext(APIContext);
  const [suggestUsInit] = apiContext;
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // let userProfileJson = JSONCheckerFunc(userProfile);

  const getHealthInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_health_info_process_id, {
        p_user_id: userProfileJson?.usr_id,
        p_customer_id: userProfileJson?.customer_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          setHealthInfoData({
            allergies_flag: res?.returnData?.[0]?.allergies_flag,
            allergies_medications: res?.returnData?.[0]?.allergies_medications,
            counter_supplements: res?.returnData?.[0]?.counter_supplements,
            last_modify_timestamp: res?.returnData?.[0]?.last_modify_timestamp,
          });
          setFormRadio(res?.returnData?.[0]?.allergies_flag);
          // setAllTxt(res?.returnData?.[0]?.allergies_medications);
          // setFormTxt(res?.returnData?.[0]?.counter_supplements);
          setHealthPageInfo((prev) => {
            return {
              allTxt: res?.returnData?.[0]?.allergies_medications,
              info: res?.returnData?.[0]?.counter_supplements,
            };
          });
          setChange(true);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getHealthInfo();
  }, [suggestUsInit, change]);

  const formSubmitHandler = (e: any) => {
    setLoading(true);
    e.preventDefault();
    //Start
    if (formRadio === "Y") {
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.add_update_health_info_process_id, {
          p_user_id: userProfileJson?.usr_id,
          p_customer_id: userProfileJson?.customer_id,
          p_allergies_flag: formRadio,
          p_allergies_medications: healthPageInfo?.allTxt,
          p_counter_supplements: healthPageInfo?.info,
        }).then((res) => {
          if (res?.returnCode === true) {
            setChange(false);
            setUpdate(false);
          }
        });
      }
    } else {
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.add_update_health_info_process_id, {
          p_user_id: userProfileJson?.usr_id,
          p_customer_id: userProfileJson?.customer_id,
          p_allergies_flag: formRadio,
          p_allergies_medications: "",
          p_counter_supplements: healthPageInfo?.info,
        }).then((res) => {
          if (res?.returnCode === true) {
            setChange(false);
            setUpdate(false);
          }
        });
      }
    }
    //End
    // setFormTxt("");
    // setAllTxt("");
    setHealthPageInfo((prev) => {
      return {
        allTxt: "",
        info: "",
      };
    });
    setFormRadio("");
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setHealthPageInfo((prev) => {
        return { ...prev, [fieldName]: "" };
      });
    } else if (fieldName === "email" || fieldName === "password") {
      let newString = cleanInput(e.target.value);

      setHealthPageInfo((prev) => {
        return { ...prev, [fieldName]: newString };
      });
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);
      setHealthPageInfo((prev) => {
        return { ...prev, [fieldName]: newString };
      });
    } else {
      setHealthPageInfo((prev) => {
        return { ...prev, [fieldName]: e.target.value };
      });
    }
  };

  return (
    <div className={`${Style?.maincontainer}`}>
      <div className="outer_container">

      <div className={`${Style?.grid}`}>
        <div className={`${Style?.gridLeftItem}`}>
          <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
        <div className={`${Style?.gridRightItem}`}>
          <div className={`${Style?.headerContainer}`}>
            <div style={{ display: "flex", alignItems:"center" }}>
              <img
                src={HealthInfoImage}
                alt="Icon"
                width="30"
                height="25"
                style={{ marginTop: "0px", color: "#32C5F4" }}
                draggable="false"
              />
              <h2 className={`${Style?.heading}`}>
                {Config?.myHealth_Page_Heading}
              </h2>
            </div>
            {update === false &&
              healthInfoData?.last_modify_timestamp !== undefined && (
                <IconButton
                  title="Edit Health Card"
                  className={`${Style?.editIcon}`}
                  onClick={() => setUpdate(true)}
                  htmlType="button"
                >
                  {/* <img
                    src={Editaddress_icon}
                    alt="Edit btn"
                    draggable="false"
                  /> */}
                  <i className="fa-regular fa-pen-to-square"></i>
                </IconButton>
              )}
          </div>
          <form className={`${Style?.infoGrid}`} onSubmit={formSubmitHandler}>
            {loading === true ? (
              <>
                <ShimmerThumbnail width={790} height={360} rounded />
              </>
            ) : (
              <>
                <div className={`${Style?.infoGrid_radioBtn}`}>
                  <Label
                    for="radio"
                    className={`${Style?.infoGrid_radioBtn_title}`}
                  >
                    {Config?.health_Info_Qry_1}
                  </Label>
                  {healthInfoData?.last_modify_timestamp === undefined ||
                  update === true ? (
                    <div className={`${Style?.infoGrid_radioBtn_Btn}`}>
                      <div className={`${Style?.infoGrid_radioBtn_Btn_Grid}`}>
                        {opt.map((item: any) => {
                          return (
                            <>
                              <RadioButton
                                type="radio"
                                name={`radio ${item}`}
                                id={`radio ${item}`}
                                title={item}
                                value={item.split("")[0].toString()}
                                size="1.2rem"
                                className={`${Style?.infoGrid_radioBtn_Btn_rad}`}
                                onChange={(e: any) => {
                                  setFormRadio(e.target.value);
                                }}
                                checked={
                                  formRadio === item.split("")[0].toString() &&
                                  true
                                }
                              />
                            </>
                          );
                        })}
                      </div>
                      {formRadio === "Y" && (
                        <div className={`${Style?.infoGrid_radioBtn_Btn_txt}`}>
                          <Input
                            name="allTxt"
                            id="allTxt"
                            placeholder={msg_config?.health_info_plc_1}
                            maxLength={250}
                            value={healthPageInfo?.allTxt}
                            size={Config?.lbl_Inp_size_small}
                            type={Config?.lbl_Inp_type_text}
                            onChange={(e: any) => {
                              onInputChangeHandler(e, "allTxt");
                            }}
                            required
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={`${Style?.infoGrid_radioBtn_Btn_1}`}>
                      <div className={`${Style?.infoGrid_radioBtn_Btn_Grid}`}>
                        {opt.map((item: any) => {
                          return (
                            <>
                              <RadioButton
                                type="radio"
                                name={`radio ${item}`}
                                id={`radio ${item}`}
                                title={item}
                                value={item.split("")[0].toString()}
                                size="1.2rem"
                                className={`${Style?.infoGrid_radioBtn_Btn_rad}`}
                                onChange={(e: any) => {
                                  setFormRadio(e.target.value);
                                }}
                                checked={
                                  healthInfoData?.allergies_flag ===
                                    item.split("")[0].toString() && true
                                }
                                disabled
                              />
                            </>
                          );
                        })}
                      </div>
                      {healthInfoData?.allergies_flag === "Y" && (
                        <div
                          className={`${Style?.infoGrid_radioBtn_Btn_txt_1}`}
                        >
                          <Input
                            name="allTxt"
                            id="allTxt"
                            placeholder=""
                            value={healthInfoData?.allergies_medications}
                            size={Config?.lbl_Inp_size_small}
                            type={Config?.lbl_Inp_type_text}
                            disabled
                            maxLength={250}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={`${Style?.infoGrid_txtMsg}`}>
                  <Label
                    for="text"
                    className={`${Style?.infoGrid_txtMsg_title}`}
                  >
                    {Config?.health_Info_Qry_2}
                  </Label>
                  {healthInfoData?.last_modify_timestamp === undefined ||
                  update === true ? (
                    <>
                      <TextArea
                        name="info"
                        id="info"
                        placeholder={msg_config?.health_info_plc_2}
                        value={healthPageInfo?.info}
                        // onChange={(e: any) => {
                        //   setFormTxt(e.target.value);
                        // }}
                        onChangeHandler={(e: any) => {
                          onInputChangeHandler(e, "info");
                        }}
                        required
                        maxLength={250}
                      />
                    </>
                  ) : (
                    <>
                      <TextArea
                        name="info"
                        id="info"
                        placeholder=""
                        value={healthInfoData?.counter_supplements}
                        disabled
                        maxLength={250}
                      />
                    </>
                  )}
                </div>
                <div className={`${Style?.infoGrid_SubmitBtn}`}>
                  {healthInfoData?.last_modify_timestamp === undefined && (
                    <Button
                      size={Config?.lbl_Btn_size_small}
                      type={Config?.lbl_Btn_type_primary}
                      htmlType={"submit"}
                    >
                      {Config?.health_Info_Save}
                    </Button>
                  )}
                  {update === true && (
                    <Button
                      size={Config?.lbl_Btn_size_medium}
                      type={Config?.lbl_Btn_type_primary}
                      htmlType={"submit"}
                    >
                      {Config?.health_Info_Update}
                    </Button>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HealthPage;

// Soumya Banerjee
