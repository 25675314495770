import React from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const ManageParticipents = ({
  id,
  participants,
  handleValue,
  chatType,
  setSelectedUsers,
  selectedUser,
  availableUsers,
}) => {
  var userPotions = [];
  /**************************  REGISTERD USER START *******************************/
  availableUsers?.forEach((v) => {
    userPotions.push({ value: v?.identity, label: v?.friendlyName });
  });

  /**************************  REGISTERD USER END *******************************/

  const notMeUsers = userPotions?.filter(
    (v) => { return v?.value !== id && v?.label !== null }//
  );
  return (
    <div className="input-wrapper">
      {participants === "sms" && (
        <>
          <div className="field">
            <p>Phone number</p>
            <input
              id="conversation-name-input"
              type={'text'}
              className="form-control"
              autoFocus
              required={true}
              placeholder="eg. +1882448444"
              onChange={handleValue}
            />
            The phone number of the participant.
          </div>
        </>
      )}
      {participants === "whatsapp" && (
        <>
          <div className="field">
            <p>WhatsApp number</p>
            <input
              id="conversation-name-input"
              type={'text'}
              className="form-control"
              autoFocus
              required={true}
              placeholder="eg. +1882448444"
              onChange={handleValue}
            />
            The WhatsApp phone number of the participant.
          </div>
        </>
      )}
      {participants === "chat" && (
        <div className="field">
          <p>Select user</p>
          <div className="user-add-select">
            <ReactMultiSelectCheckboxes
              name={"users"}
              id={"users"}
              hideSearch={true}
              value={selectedUser}
              ff_parent_field_id={"users"}
              isSearchable={true}
              options={notMeUsers}
              isMulti={chatType && selectedUser}
              onChange={setSelectedUsers}
            />
          </div>
          {/* The identity used by the participant in Conversations. */}
        </div>
      )}
    </div>
  );
};

export default ManageParticipents;
