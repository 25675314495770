import React from "react";

export type TabPaneProps = {
  style?: any;
  key?: any;
  childern?: any;
  name?: any;
};

const TabPane: React.FC<TabPaneProps> = (props) => {
  const { key, style } = props;

  return (
    <div key={key} style={style}>
      {props.childern}
    </div>
  );
};

export default TabPane;
