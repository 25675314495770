import React, { useRef, useState } from "react";
import Label from "../../../../../components/UI/Label";
import Styles from "./index.module.css";
import Heading from "../../../../../components/UI/Heading";
import Config from "../../../../../config/label";
import { PhoneFormInput } from "../../../../../components/UI/PhoneFormInput";
import { msg_config } from "../../../../../config/messages";

const EnterPhoneNumber = (props: any) => {
  const { register, control, errors, setError, clearErrors } = props;

  const phoneInputRef = useRef<any>(null);

  return (
    <div className={`${Styles?.container}`}>
      <div>
        {/* <Heading className={`${Styles?.page_heading}`}>
          {Config?.forgetPassword_heading_Create_Account_1}
        </Heading> */}
        <div className={`${Styles?.form_control}`}>
          <div className={`${Styles?.form_inner_control}`}>
            <Label className={`${Styles?.form_label}`} for="number">
              {Config?.lbl_Login_number}
            </Label>
            
            <div className={`${Styles?.phoneNumberBox}`}>
              <PhoneFormInput
                name="phone"
                register={register}
                rules={{
                  required: msg_config?.forgot_required,
                }}
                errors={errors}
                control={control}
                placeholder={Config?.inp_P_Holder_Login_number}
                maxLength={15}
                phoneInputRef={phoneInputRef}
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterPhoneNumber;
