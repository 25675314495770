import React, { useContext, useEffect, useState } from "react";
import Button from "../../Landingpage/Button";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import EditProfile from "../../MyProfile/EditProfile";
import Config from "../../../config/label";
import { CallAPI } from "../../../suggest-us/callSuggest";
import APIContext from "../../../store/api-context";
import { config } from "../../../config/processId";
import { useNavigate } from "react-router-dom";
import { SG_USER_ID } from "../../../config/Config";
import { getDecryptedID, getID } from "../../../utils/utils";
import { decryptData } from "../../../utils/util_helper";
export type ModalProps = {
  setModalOpen?: any;
};

const NameProfilePopup: React.FC<ModalProps> = (props) => {
  const { setModalOpen } = props;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<any>([]);
  const [profileForm, setProfileForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    patient_photo: "",
    gender: "",
  });
  const [suggestUsInit] = useContext(APIContext);
  const navigate = useNavigate();
  const userInfohandler = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.my_profile_process_id, {
        p_user_id: decryptData(getID(SG_USER_ID)),
      }).then((res) => {
        if (res?.returnCode === true) {
          setUserInfo([
            {
              label: Config?.lbl_First_N,
              value:
                res?.returnData[0]?.first_name === ""
                  ? "NA"
                  : res?.returnData[0]?.first_name,
            },
            {
              label: Config?.lbl_Last_N,
              value:
                res?.returnData[0]?.last_name === ""
                  ? "NA"
                  : res?.returnData[0]?.last_name,
            },
            {
              label: Config?.lbl_Phone,
              value:
                res?.returnData[0]?.usr_phone === ""
                  ? "NA"
                  : res?.returnData[0]?.usr_phone,
            },
            {
              label: Config?.lbl_Email,
              value:
                res?.returnData[0]?.usr_email === ""
                  ? "NA"
                  : res?.returnData[0]?.usr_email,
            },
            {
              label: Config?.lbl_BirthDay,
              value:
                res?.returnData[0]?.usr_dob === null
                  ? "NA"
                  : res?.returnData[0]?.usr_dob,
            },
            {
              label: Config?.lbl_Gender,
              value:
                res?.returnData[0]?.usr_gender_code === null
                  ? "NA"
                  : res?.returnData[0]?.usr_gender_code,
            },
          ]);
          setProfileForm((prev: any) => {
            return {
              ...prev,
              firstName:
                res?.returnData[0]?.first_name === ""
                  ? ""
                  : res?.returnData[0]?.first_name,
              lastName:
                res?.returnData[0]?.last_name === ""
                  ? ""
                  : res?.returnData[0]?.last_name,
              phone:
                res?.returnData[0]?.usr_phone === ""
                  ? ""
                  : res?.returnData[0]?.usr_phone,
              email:
                res?.returnData[0]?.usr_email === ""
                  ? ""
                  : res?.returnData[0]?.usr_email,
              dob:
                res?.returnData[0]?.usr_dob === null
                  ? undefined
                  : res?.returnData[0]?.usr_dob,
              patient_photo: res?.returnData[0]?.patient_img_path,
              gender:
                res?.returnData[0]?.usr_gender_code === null
                  ? undefined
                  : res?.returnData[0]?.usr_gender_code,
            };
          });
        } else console.error("error", res?.error);
      });
    }
  };

  useEffect(() => {
    userInfohandler();
  }, [suggestUsInit]);

  return (
    <>
      <div className={Styles["modalBackground"]}>
        <div className={Styles["modalContainer"]}>
          <div
            className={Styles["titleCloseBtn"]}
            title="Close button"
            onClick={() => {
              setModalOpen(false);
              navigate("/");
            }}
          >
            <img src={CloseIcon} alt="Cross icon" draggable="false" />
          </div>
          <div className={Styles["title"]}>
            <div className={Styles["checkIconWrapper"]}>
              <img src={Doctorclipart} alt="Cart icon" draggable="false" />
            </div>
            <Heading className={`${Styles?.page_heading}`}>
              User name is not added!
            </Heading>
            <p>
              Please add your name in the profile without name you can't add
              cards!
            </p>
          </div>
          <div className={Styles["buttons"]}>
            <Button className={Styles["username_canel_btn"]}
              type="ghost"
              size="medium"
              onClick={() => {
                setModalOpen(false);
                navigate("/");
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              size="medium"
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <EditProfile
          setIsEditModalOpen={setIsEditModalOpen}
          setModalOpen={setModalOpen}
          editHeading={"Edit Profile"}
          userInfoHandler={userInfohandler}
          userInfo={userInfo}
          profileForm={profileForm}
          setProfileForm={setProfileForm}
        />
      )}
    </>
  );
};
export default NameProfilePopup;
