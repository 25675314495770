import React from "react";
import Card from "../../../components/UI/Card";
import FlexBox from "../../../components/UI/FlexBox";
import Select from "react-select";
import { Grid3x3GapFill, ListUL, Filter } from "../../../utils/appIcons";
import IconButton from "../../../components/UI/IconButton";
import styles from "./index.module.css";
import { H2, Paragraph } from "../../../components/UI/Typography";
import Config from "../../../config/label";

const TopFilter = ({
  setProductViewType,
  setIsSideFilter,
  categoryName,
  numElement,
  FetchProductList,
  reff,
  manfID,
  productViewType,
  setIsFilterEnable,
  setActiveFilter,
  activeFilter
}: any) => {
  const options = [...Config?.Filter_Sort_Options];

  const selectStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "1px dotted #ddd",
      fontSize: "14px",
      lineHeight: "1.5px",
      fontWeight: "400",
      padding: "1rem",
    }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: "4px",
      minWidth: "150px",
      cursor: "pointer",
      marginRight: "1.75rem",
    }),
  };


  const handleActiveFilter = ()=>{
    try {
      setActiveFilter(!activeFilter)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={`${styles?.top_filter_main}`}>
      <div className="outer_container">
        <div className={`${styles?.container}`}>

          <div className={`${styles?.searchFilter}`}>
            {/* <H2> {categoryName ? `Searching for "${categoryName}"` : ""}</H2>
        <Paragraph>{numElement || 0} results found</Paragraph> */}
          </div>

          <FlexBox
            className={`${styles?.sortFilter}`}
            style={{
              alignItems: "center",
              flexWrap: "wrap",
              margin: "0.5rem 0",
            }}
          >
            { (
              <FlexBox style={{ alignItems: "center", flex: "1 1 0" }} className={`${styles?.sort_by_filter_main}`}>
                <Paragraph style={{ marginRight: "16px" }}>Sort by:</Paragraph>
                <Select
                  defaultValue={{ value: 'relevance', label: 'Relevance' }}
                  //@ts-ignore
                  options={options}
                  styles={selectStyles}
                  onChange={(selectedOption) => {
                    setIsFilterEnable(true);
                    FetchProductList(
                      reff?.current?.minVal,
                      reff?.current?.maxVal,
                      selectedOption?.value
                    );
                  }}
                />
                {/* <Select
              defaultInputValue={"Relevance"}
              options={options}
              styles={selectStyles}
              onChange={(e) => {
                FetchProductList(
                  reff?.current?.minVal,
                  reff?.current?.maxVal,
                  e?.value
                );
              }}
            /> */}
              </FlexBox>
            )}
            <FlexBox
              style={{
                alignItems: "center",
                margin: "0.25rem 0",
                gap: "0.5rem",
              }} 
            >
              {<IconButton
                onClick={() => handleActiveFilter()}
                title="Grid view"
            className={`${styles?.filter_icon_wrapper} ${activeFilter ? styles?.filter_active : ""}`}
              // productViewType
              >
                <div className={`${styles?.filter_wrapper}`}>
                  <p>{"Filter"}</p>
                  <i className="fa-solid fa-filter"></i>
                </div>
              </IconButton>}
              <Paragraph style={{ marginRight: "8px" }}>View:</Paragraph>
              <IconButton
                onClick={() => setProductViewType("GRID")}
                title="Grid view"
            className={`${
              productViewType === "GRID"
                    ? styles?.newIconButton
                    : styles?.iconButton
                  }`}
              // productViewType
              >
                <Grid3x3GapFill
                  width="18"
                  height="18"
              className={`${
                productViewType === "GRID" ? styles?.newIcon : styles?.icon
                    }`}
                />
              </IconButton>
              <IconButton
                onClick={() => setProductViewType("LIST")}
                title="List view"
            className={`${
              productViewType === "LIST"
                    ? styles?.newIconButton
                    : styles?.iconButton
                  }`}
              >
                <ListUL
                  font="1"
                  width="18"
                  height="18"
              className={`${
                productViewType === "LIST" ? styles?.newIcon : styles?.icon
                    }`}
                />
              </IconButton>

              {/* {manfID === undefined ? (
            <IconButton
              onClick={() => setIsSideFilter(true)}
              className={`${styles?.iconButton} ${styles?.filterIcon}`}
              title="Side filter"
            >
              <Filter width="18" height="18" className={`${styles?.icon}`} />
            </IconButton>
          ) : (
            ""
          )} */}
            </FlexBox>
          </FlexBox>
        </div>
      </div>
    </div>
  );
};

export default TopFilter;
