import React from "react";
import styles from "./index.module.css";

const Payments = () => {
  return (
    <div className={`${styles?.container}`}>
      <div>
        <h2 className={`${styles?.page_heading}`}>Add your card</h2>
      </div>
    </div>
  );
};

export default Payments;
