import React, { useEffect, useState, useContext, useRef } from "react";
import { CloseIcon } from "../../../utils/appIcons";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../../config/button";
import Heading from "../../../components/UI/Heading";
import Checkbox from "../../../components/UI/Checkbox";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../components/UI/FormInput";
import { PhoneFormInput } from "../../../components/UI/PhoneFormInput";
import { msg_config } from "../../../config/messages";
import { JSONCheckerFunc, emailPattern, getDecryptedID, objDecrypt } from "../../../utils/utils";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import LocationSearchInput from "../../../components/LocationSearchInput";
import Config from "../../../config/label";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import Alert from "../../../components/UI/Alert";
import { USER_PROFILE } from "../../../config/Config";

export type ModalProps = {
  heading?: string;
  setIsAddressModalOpen?: any;
  modalType?: string;
  editHeading?: string;
  getAddress?: any;
  editAddressProfile?: any;
  getDefaultAddress?: any;
  setModalOpen?: any;
  propsGetAddresss? : any;
};

export type RegistrationFormFields = {
  firstName: string;
  lastName: string;
  addressLine1: string;
  country: object;
  province: string;
  city: string;
  zipCode: string;
  phone: string;
  email: string;
};

const AddAddress: React.FC<ModalProps> = (props) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [country, setCountry] = useState<any>([]);
  const [province, setProvince] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [isDefault, setIsDefault] = useState("N");

  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [loadingProvince, setLoadingProvince] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [addressLine1, setaddressLine1] = useState({ address: "" });
  const [addressDetails, setaddressDetails] = useState([]);
  const [context, setContext] = useContext(UserAuthContext);

  // //@ts-ignore
  // const map = new google.maps.Map(document.getElementById("map"));
  // //@ts-ignore
  // const googleInstance = new google.maps.places.AutocompleteService();
  // //@ts-ignore
  // const placesService = new google.maps.places.PlacesService(map);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
    clearErrors,
    setError,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const _country = watch<any>("country");
  const _province = watch<any>("province");

  useEffect(() => {
    resetField("province", { defaultValue: "" });

    if (_country) {
      clearErrors("country");
      onCountrySelectHandler(_country);
    } else {
      setProvince([]);
      resetField("city", { defaultValue: "" });
      setCities([]);
    }
  }, [_country]);

  useEffect(() => {
    resetField("city", { defaultValue: "" });
    if (_province) {
      onProvinceSelectHandler(_province);
    } else {
      setCities([]);
    }
  }, [_province]);

  const {
    setIsAddressModalOpen,
    heading,
    modalType,
    editHeading,
    getAddress,
    editAddressProfile,
    getDefaultAddress,
    setModalOpen,
    propsGetAddresss
  } = props;




  const [suggestUsInit] = useContext<any>(APIContext);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [isAlert, setIsAlert] = useState({ type: "", msg: "" });
  const [profileForm, setProfileForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    zipCode: "",
    email: "",
    dob: "",
    patient_photo: "",
    gender: "",
    completeness_my_address: null,
    completeness_my_health_cards: null,
    completeness_my_health_info: null,
    completeness_my_insurance_cards: null,
    completeness_my_profile: null,
  });
  let newArry = editAddressProfile[0];

  const handleChange = () => {
    if (checked) {
      setIsDefault("N");
    } else {
      setIsDefault("Y");
    }
    setChecked(!checked);
  };



  const getModalCloseConfig = () => {
    if (isDefault === "Y") {
      if(getDefaultAddress){
        getDefaultAddress();
      }
      if(setModalOpen)setModalOpen(false);
      propsGetAddresss ?  propsGetAddresss() : "";
      getAddress? getAddress() : "";
    } else {
      getAddress ? getAddress() : "";
      if(getDefaultAddress){
        getDefaultAddress();
      }
      propsGetAddresss ? propsGetAddresss() : "";
    }
  };

  const onAddressSubmitHandler = (data: any) => {
    setIsAlert({ type: "", msg: "" });
    if (suggestUsInit?.returnCode && context?.phLength >= 10) {
      setLoading(true);
      CallAPI(config?.patient_address_add_update_process_id, {
        p_pnt_id: userProfileJson?.patient_id,
        p_address_id: modalType === "Edit" ? newArry?.p_patadd_id : "",
        p_patadddet_pnt_name: data?.firstName,
        p_patadddet_pnt_last_name: data?.lastName,
        p_patadd_line_1: data?.addressLine1,
        p_patadd_country_id: data?.country?.value,
        p_patadd_state_id: data?.province?.value,
        p_patadd_city_id: data?.city?.value,
        p_patadd_zipcode: data?.zipCode,
        p_patadddet_pnt_phone: data?.phone,
        p_patadddet_pnt_email: data?.email,
        p_default_address_flag: isDefault,
      }).then((res) => {
        if (res?.returnCode) {
          setLoading(false);
          // Closes the model
          getAddress();
          setIsAddressModalOpen(false);
          getModalCloseConfig();
        } else {
          setLoading(false);
          console.error(res?.error);
        }
        // window.location.reload();
      });
    } else if (context?.phLength < 10) {
      setError("phone", {
        type: "required",
        message: "Invalid phone number",
      });
    }
  };

  const onCountrySelectHandler = (e: any) => {
    setProvince([]);
    if (suggestUsInit?.returnCode) {
      setLoadingProvince(true);
      CallAPI(config?.province_process_id, { cnt_id: e?.value }).then((res) => {
        if (res?.returnCode) {
          setProvince([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.stt_id,
                label: item?.stt_description,
              };
            }),
          ]);
          setLoadingProvince(false);
        }
      });
    }
  };

  const onProvinceSelectHandler = (e: any) => {
    setCities([]);
    if (suggestUsInit?.returnCode) {
      setLoadingCities(true);
      CallAPI(config?.city_process_id, { p_stt_id: e?.value }).then((res) => {
        if (res?.returnCode) {
          setCities([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.cit_id,
                label: item?.cit_description,
              };
            }),
          ]);
          setLoadingCities(false);
        }
      });
    }
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode) {
      setLoadingCountry(true);
      CallAPI(config?.country_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setCountry([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.cnt_id,
                label: item?.cnt_description,
              };
            }),
          ]);

          setLoadingCountry(false);
        }
      });
    }
  }, [suggestUsInit]);

  useEffect(() => {
    if (country?.length > 0 && modalType === "Edit") {
      country &&
        setValue(
          "country",
          country.find((i: any) => i)
        );
    }
    if (country?.length > 0 && addressDetails?.length > 0) {
      const selectedCountry: any = addressDetails?.find(
        (i: any) => i?.types === "country"
      );

      setValue(
        "country",
        country.find(
          (i: any) =>
            i?.label?.trim()?.toLowerCase() ===
            selectedCountry?.long_name?.trim()?.toLowerCase()
        )
      );
    }
  }, [country, addressDetails]);

  useEffect(() => {
    if (province?.length > 0 && modalType === "Edit") {
      province &&
        setValue(
          "province",
          province.find((i: any) => i.value === newArry?.p_province)
        );
    }

    if (province?.length > 0 && addressDetails?.length > 0) {
      const selectedProvince: any = addressDetails?.find(
        (i: any) => i?.types === "administrative_area_level_1"
      );

      setValue(
        "province",
        province.find(
          (i: any) =>
            i?.label?.trim()?.toLowerCase() ===
            selectedProvince?.long_name?.trim()?.toLowerCase()
        )
      );
    }
  }, [province, addressDetails]);

  useEffect(() => {
    if (cities?.length > 0 && modalType === "Edit") {
      cities &&
        setValue(
          "city",
          cities.find((i: any) => i.value === newArry?.p_city)
        );
    }

    if (cities?.length > 0 && addressDetails?.length > 0) {
      const selectedCity: any = addressDetails?.find(
        (i: any) => i?.types === "locality"
      );

      setValue(
        "city",
        cities.find(
          (i: any) =>
            i?.label?.trim()?.toLowerCase() ===
            selectedCity?.long_name?.trim()?.toLowerCase()
        )
      );

      const postalCode: any = addressDetails?.find(
        (i: any) => i?.types === "postal_code"
      );
      setValue(
        "zipCode",
        `${postalCode?.long_name || postalCode?.short_name || ""}`
      );
    }
  }, [cities, addressDetails]);

  useEffect(() => {
    if (modalType === "Edit") {
      if (editAddressProfile?.length > 0) {
        setPhoneNumber(newArry?.patadddet_pnt_phone);
        setChecked(newArry?.default_address_flag === "Y" ? true : false);
        setaddressLine1({ address: newArry?.p_address });
        setValue("firstName", `${newArry?.patadddet_pnt_name}`);
        setValue("lastName", `${newArry?.patadddet_pnt_last_name}`);
        setValue("addressLine1", `${newArry?.p_address}`);
        setValue("zipCode", `${newArry?.p_pcode}`);
        setValue("phone", `${newArry?.patadddet_pnt_phone}`);
        setValue("email", `${newArry?.patadddet_pnt_email}`);
        setIsDefault(newArry?.default_address_flag);
        setContext((prev: any) => ({
          ...prev,
          phLength: newArry?.patadddet_pnt_phone,
        }));
        setProfileForm((prev: any) => {
          return {
            ...prev,
            firstName: newArry?.patadddet_pnt_name || "",
            lastName: newArry?.patadddet_pnt_last_name || "",
            zipCode: newArry?.p_pcode || "",
            email: newArry?.patadddet_pnt_email || "",
          };
        });
      }
    }
  }, [modalType]);

  const phoneInputRef = useRef<any>(null);

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: "" };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: e.target.value };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => setIsAddressModalOpen(false)}
          draggable="false"
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {modalType === "Edit" ? editHeading : heading}
          </Heading>
        </div>

        <form onSubmit={handleSubmit(onAddressSubmitHandler)}>
          {/* First row */}

          <div className={`${Styles?.form_control}`}>
            <div className={`${Styles?.form_inner_control}`}>
              <FormInput<RegistrationFormFields>
                name="firstName"
                id="firstName"
                type="text"
                placeholder={Config?.address_placeholder_1}
                size="small"
                register={register}
                rules={{ required: msg_config?.first_name_required }}
                value={profileForm?.firstName}
                onInputChangeHandler={(e: any) => {
                  onInputChangeHandler(e, "firstName");
                }}
                errors={errors}
                maxLength={25}
              />
            </div>
            <div className={`${Styles?.form_inner_control}`}>
              <FormInput<RegistrationFormFields>
                name="lastName"
                id="lastName"
                type="text"
                placeholder={Config?.address_placeholder_2}
                size="small"
                register={register}
                rules={{ required: msg_config?.last_name_required }}
                errors={errors}
                maxLength={25}
                value={profileForm?.lastName}
                onInputChangeHandler={(e: any) => {
                  onInputChangeHandler(e, "lastName");
                }}
              />
            </div>
          </div>

          {/* Second line */}
          {/* <div className={`${Styles?.form_control}`}>
            <div className={`${Styles?.form_control_fw}`}>
              <div className={`${Styles?.form_inner_control}`}>
                <LocationSearchInput
                  name="addressLine1"
                  placeholder={Config?.address_placeholder_3}
                  size="small"
                  setState={setaddressLine1}
                  state={addressLine1}
                  setaddressDetails={setaddressDetails}
                  rules={{ required: msg_config?.address_required }}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  clearErrors={clearErrors}
                  errors={errors}
                  maxLength={100}
                />
              </div>
            </div>
          </div> */}

          {/* Third line */}
          <div className={`${Styles?.form_control}`}>
            <div className={`${Styles?.form_inner_control}`}>
              <SelectDropdown
                name="country"
                options={country}
                size="small"
                placeholder={Config?.address_placeholder_4}
                isClearable={true}
                loading={loadingCountry}
                control={control}
                rules={{ required: msg_config?.country_required }}
                errors={errors}
                op_style={{
                  fontSize: "16px",
                }}
                sg_style={{
                  fontSize: "16px",
                }}
              />
            </div>
            <div className={`${Styles?.form_inner_control}`}>
              <SelectDropdown
                name="province"
                options={province}
                size="small"
                placeholder={Config?.address_placeholder_5}
                isClearable={true}
                loading={loadingProvince}
                control={control}
                disabled={!_country}
                rules={{ required: msg_config?.province_required }}
              />
            </div>
          </div>

          {/* Fourth line */}
          <div className={`${Styles?.form_control}`}>
            <div className={`${Styles?.form_inner_control}`}>
              <SelectDropdown
                name="city"
                options={cities}
                size="small"
                placeholder={Config?.address_placeholder_6}
                isClearable={true}
                loading={loadingCities}
                control={control}
                disabled={!_province}
                rules={{ required: msg_config?.city_required }}
              />
            </div>
            <div className={`${Styles?.form_inner_control}`}>
              <FormInput<RegistrationFormFields>
                name="zipCode"
                id="zipCode"
                type="text"
                placeholder={Config?.address_placeholder_7}
                size="small"
                register={register}
                rules={{ required: msg_config?.zip_code_required }}
                errors={errors}
                maxLength={10}
                onInputChangeHandler={(e: any) => {
                  onInputChangeHandler(e, "zipCode");
                }}
              />
            </div>
          </div>

          {/* Fifth line */}
          <div style={{ marginTop: "20px" }}>
            <PhoneFormInput<RegistrationFormFields>
              name="phone"
              placeholder={Config?.address_placeholder_8}
              register={register}
              rules={{ required: msg_config?.phone_required }}
              errors={errors}
              control={control}
              phoneInputRef={phoneInputRef}
              clearErrors={clearErrors}
              setError={setError}
            />
          </div>

          {/* Sixth line */}
          <div style={{ marginTop: "20px" }}>
            <FormInput<RegistrationFormFields>
              name="email"
              id="email"
              type="text"
              placeholder={Config?.address_placeholder_9}
              size="small"
              register={register}
              maxLength={50}
              value={profileForm?.email}
              onInputChangeHandler={(e: any) => {
                onInputChangeHandler(e, "email");
              }}
              rules={{
                required: msg_config?.email_required,
                pattern: emailPattern,
              }}
              errors={errors}
            />
          </div>

          {/* Seventh line */}
          <div style={{ marginTop: "35px", marginBottom: "35px" }}>
            <Checkbox
              name="Set as default address"
              value={checked}
              onChange={handleChange}
            />
          </div>

          <div className={Styles["button_style"]} style={{ marginTop: "40px" }}>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              style={{ margin: 0 }}
              loading={loading}
            >
              {modalType === "Edit" ? "Update" : "Add Address"}
            </Button>
          </div>
        </form>
        <Button
          type="ghost"
          size="small"
          onClick={() => {
            setIsAddressModalOpen(false);
          }}
          style={{ margin: 0 }}
        >
          {btnConfig?.pharmacy_modal_cancel}
        </Button>
      </div>
      {isAlert?.msg ? <Alert type={isAlert?.type} info={isAlert?.msg} /> : ""}
    </div>
  );
};
export default AddAddress;
