import React from "react";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../../config/label";
import { config } from "../../../config/processId.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { useEffect,useState } from "react";
import {JSONCheckerFunc, getDecryptedID, objDecrypt, setEncryptedID } from "../../../utils/utils";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { CART_COUNT, CONFIRM_DELETE_POPUP_IMAGE, USER_PROFILE } from "../../../config/Config";
const userProfileJson: any = getDecryptedID(USER_PROFILE);
// const userProfileJson = JSONCheckerFunc(userProfile);

export type ModalProps = {
  setModalOpen?: any;
  isConfirmPopup?:any;
  getProducts?:any;
  getCartTotal?:any;
  setProductList?:any;
  productList?:any;


};

const DeleteConfirmation: React.FC<ModalProps> = (props) => {
  
  const { setModalOpen,isConfirmPopup,getProducts,getCartTotal,setProductList,productList} = props;
  const [isLoading, setLoading] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Cart page");

  const onConfirmDeleteHandler = () => {
    setLoading(true);
    CallAPI(config?.delete_cart_item_process_id, {
          p_cart_item_id: isConfirmPopup?.id,
          p_user_id: userProfileJson?.usr_id,
        }).then((res) => {
          if (res?.returnCode) {
            gaEventTracker("Items deleted from cart");
            var count: any = getDecryptedID(CART_COUNT);
            setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
            //window.location.reload();
            getProducts();
            getCartTotal();
            if (!isConfirmPopup?.isUnitChanging) {
              let updateProductList = productList.filter((items: any) => {
                if (items?.id === isConfirmPopup?.id) {
                  return false;
                }
                return true;
              });
              setProductList(updateProductList);
            }
              setTimeout(() => {
                setModalOpen(false);
                setLoading(false);
              }, 1000);
            
            
          }
          else{
            setLoading(false);
          }
          // 
        });
      }
  

//   let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={CONFIRM_DELETE_POPUP_IMAGE} alt="Cart icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            {Config?.lbl_delete_req_txt}
          </Heading>
          <p></p>
        </div>
        <div className={Styles["buttons"]}>
          <Button className={Styles["login_cancel_btn"]}
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={() => setModalOpen(false)}
          >
            {Config?.lbl_cancel_txt}
          </Button>

          {/* <Link
            to="/log-in"
            style={{ textDecoration: "none" }}
            draggable="false"
          > */}
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              onClick= {onConfirmDeleteHandler}
              loading={isLoading}

            >
              {Config?.lbl_delete_txt}
            </Button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmation;
