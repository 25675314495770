import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
// import "./App.css";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { store } from "./store/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
import { Provider } from "react-redux";

root.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>
);
