import { createSlice } from "@reduxjs/toolkit";

const CartSlice = createSlice({
  name: "Cart",
  initialState: { cartItems: [] },
  reducers: {
    ADD_TO_CART: (state, action) => {
      state.cartItems.push(action.payload.item);
    },
  },
});

export const cartSliceActions = CartSlice.actions;
export const cartSliceReducer = CartSlice?.reducer;
