var btnConfig = {
  // ******************************************
  // Configuration Settings for Sign Up Screens
  // ******************************************

  signUp_Primary_Text: "",
  signUp_Primary_Text_V1: "Next",
  signUp_Primary_Text_V2: "Next",
  signUp_Primary_Text_Finish: "Finish",
  signUp_Ghost_Text_Back: "Back",
  signUp_Ghost_Text_Resend: "Resend verification code",
  signUp_Primary_Text_Submit: "Submit",
  signUp_Primary_Text_Onboard: "Submit for Approval",
  signUp_Primary_Text_Continue: "Continue",
  signUp_Primary_Text_Update: "Update",
  signUp_close_button : "Close",

  // ******************************************
  // Configuration Settings for Upload Rx Screens
  // ******************************************

  uploadRx_Primary_Text: "",
  uploadRx_Primary_Text_V1: "Continue",
  uploadRx_Primary_Text_V2: "",
  uploadRx_Ghost_Text_Back: "Back",

  uploadRx_Add: "Add",
  uploadRx_Add_Another: "Add another prescription",

  // ******************************************
  // Configuration Settings for Upload Verification Successful Modal
  // ******************************************

  verification_Complete: "Finish",

  // ******************************************
  // Configuration Settings for New Rx Screens
  // ******************************************

  newRx_Primary_Text: "",
  newRx_Primary_Text_V1: "Continue",
  newRx_Primary_Text_V2: "",
  newRx_Ghost_Text_V1: "Cancel",
  newRx_Ghost_Text_V2: "Back",

  // ******************************************
  // Configuration Settings for New Rx Screens
  // ******************************************

  transferRx_Primary_Text: "",
  transferRx_Primary_Text_V1: "Continue",
  transferRx_Primary_Text_V2: "",
  transferRx_Ghost_Text_V1: "Back",
  transferRx_Ghost_Text_V2: "Cancel",

  // ******************************************
  // Configuration Settings for Add Practitioner Modal
  // ******************************************

  prac_modal_cancel: "Cancel",

  Add_prac_modal_button: "Add practitioner",

  // ******************************************
  // Configuration Settings for Add Practitioner Modal
  // ******************************************

  pharmacy_modal_cancel: "Cancel",

  Add_pharmacy_modal_button: "Add Pharmacy",

  // ******************************************
  // Configuration Settings for Order Completion Modal
  // ******************************************

  order_Completion: "Continue",

  // ******************************************
  // Configuration Settings for Product Button
  // ******************************************

  product_Button_1: "Add to cart",
  product_Button_2: "Added to cart",

  // ******************************************
  // Configuration Settings for Cart Buttons
  // ******************************************

  cart_Button_1: "Apply",
  cart_Button_2: "Checkout",
  cart_Button_3: "Continue",
  cart_Button_4: "Proceed",
  cart_link_Button: "Save for later",

  // ******************************************
  // Configuration Settings for Checkout Buttons
  // ******************************************

  checkout_Button_1: "Back to shopping",
  checkout_Button_2: "Continue",
  checkout_Address_Button: "select address",

  // ******************************************
  // Configuration Settings for Payment Buttons
  // ******************************************

  payment_Button_1: "Back to shopping",
  payment_Button_2: "Payment",

  // ******************************************
  // Configuration Settings for insurance modal Buttons
  // ******************************************

  is_Insurance: "Yes",
  is_not_Insurance: "No",

  // ******************************************
  // Configuration Settings for Widget Link Button
  // ******************************************

  widget_link_button: "See more",

  // ******************************************
  // Configuration Settings for Widget Homebanner Button
  // ******************************************
  homebanner_Button_1: "Hey there!",
  homebanner_Button_2: "Get started",
  homebanner_Button_3: "Hey there22222!",

  // My List
  mylist_create_list: "Create List",
  mylist_cancel_list: "Cancel",
  mylist_save_list: "Save List",

  btn_add_more : "Add more",
  btn_add : 'Add',

};

export default btnConfig;
