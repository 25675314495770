import React, { useState } from "react";
import Input from "../../../components/UI/Input";
import Label from "../../../components/UI/Label";
import styles from "./index.module.css";
import canadaLife from "../../../assets/svg/canadaLife.svg";
import unionBenefits from "../../../assets/svg/unionBenefits.svg";
import townley from "../../../assets/svg/townley.svg";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import FileUpload from "../../../components/UI/FileUpload";
import DatePicker from "../../../components/UI/DatePicker";
import Config from "../../../config/label";
import Heading from "../../../components/UI/Heading";
// import "./index.css";

const InsuranceDetails = () => {
  const companyOptions = [
    {
      value: "Canada Life",
      label: (
        <div className={styles?.option}>
          <img src={canadaLife} draggable="false" />
          Canada Life
        </div>
      ),
    },
    {
      value: "Union Benefits",
      label: (
        <div className={styles?.option}>
          <img src={unionBenefits} draggable="false" />
          Union Benefits
        </div>
      ),
    },
    {
      value: "D.A. Townley",
      label: (
        <div className={styles?.option}>
          <img src={townley} draggable="false" />
          D.A. Townley
        </div>
      ),
    },
  ];

  const coverageOptions = [
    {
      value: "Canada Life",
      label: <div>70%</div>,
    },
  ];

  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const onStartDateChange = (val: any) => {
    setSelectedStartDate(val);
  };

  const onEndDateChange = (val: any) => {
    setSelectedEndDate(val);
  };

  return (
    <div className={`${styles?.container}`}>
      <div>
        <Heading className={`${styles?.page_heading}`}>
          {Config?.heading_Location}
        </Heading>

        <div className={`${styles?.form_control_}`}>
          <Label className={`${styles?.form_label}`} for="name">
            {Config?.lbl_Comp_N}
          </Label>
          <SelectDropdown
            options={companyOptions}
            size="medium"
            placeholder={Config?.inp_P_Holder_Comp_N}
          />
        </div>
        <div className={`${styles?.form_control_}`}>
          <div>
            <Label className={`${styles?.form_label}`} for="insurance">
              {Config?.lbl_Insurance}
            </Label>
            <Input
              name="insurance"
              id="insurance"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_Insurance}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
        </div>
        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="startDate">
              {Config?.lbl_Date_S}
            </Label>

            <DatePicker
              onChange={(val: any) => onStartDateChange(val)}
              value={selectedStartDate}
              placeholder={Config?.inp_P_Holder_Date_S}
              dateFormat={"LL"}
            />
          </div>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="endDate">
              {Config?.lbl_Date_E}
            </Label>

            <DatePicker
              onChange={(val: any) => onEndDateChange(val)}
              value={selectedEndDate}
              placeholder={Config?.inp_P_Holder_Date_E}
              small={false}
              dateFormat={"LL"}
            />
          </div>
        </div>
        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="healthCard">
              {Config?.lbl_Upload}
            </Label>
            <FileUpload
              size="medium"
              name=""
              id=""
              title="Upload health card"
              type="button"
            />
          </div>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="insuranceCoverage">
              {Config?.lbl_Coverage}
            </Label>
            <SelectDropdown
              options={coverageOptions}
              size="medium"
              placeholder={Config?.inp_P_Holder_Coverage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDetails;
