export const SPD_ALL_BUTTON = 'buttonMenu_Data';
export const SPD_MENU_BUTTON_CODE = {
    hv_twilio_chat: 'BTN440',
    hv_password: 'HV_BTN_HEADER_CHANGE_PASSWORD',
    hv_twilio: 'HV_BTN_HEADER_TWILLIO',
    HV_BTN_TWILIO_CHAT_SEARCH_USER: 'HV_BTN_TWILIO_CHAT_SEARCH_USER',
    HV_BTN_TWILIO_CHAT_CREATE_GROUP: 'HV_BTN_TWILIO_CHAT_CREATE_GROUP',
}
export const SPD_OPENSSL_CYPHER_METHOD = "aes-256-cbc";

export const doc_zoom_scale = { h: 40, w: 40 }
export const Doc_Link = 'https://view.officeapps.live.com/op/embed.aspx?src=';

export const btn_submit_type_primary= 'submit primary';
export const btn_submit_type_secondary= 'submit secondary';