import React from "react";
import classNames from "classnames";
import { get } from "lodash";
import styles from "./index.module.css";
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Phone, InputProps } from "../Phone";
import { FormErrorMessage } from "../FormErrorMessage";

export type FormInputProps<TFormValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: any;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  setError?: any;
  clearErrors?: any;
  disabled?: any;
} & Omit<InputProps, "name">;

export const PhoneFormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  setError,
  clearErrors,
  disabled,
  defaultValue,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const ref = props?.phoneInputRef
    ? { phoneInputRef: props?.phoneInputRef }
    : {};
  return (
    <div className={classNames("", className)} aria-live="polite">
      <Phone
        name={name}
        {...props}
        {...(register && register(name, rules))}
        setError={setError}
        clearErrors={clearErrors}
        disabled={disabled}
        {...ref}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className={`${styles?.error_msg}`}>
            {message}
          </FormErrorMessage>
        )}
      />
    </div>
  );
};
