import { useForm } from "react-hook-form";
import btnConfig from "../../config/button";
import Config from "../../config/label";
import Button from "../UI/Button";
import styles from "./index.module.css";
import { CallAPI } from "../../suggest-us/callSuggest";
import placeHolderUploadImage from "../../assets/sf_svg/Upload.svg";
import deleteIcon from "../../assets/sf_svg/Bin.svg";
import viewIcon from "../../assets/sf_svg/Eye.svg";
import { config } from "../../config/processId";
import { useEffect } from "react";
import {
  CART_COUNT,
  DEFAULT_FLAG,
  DOCUMENT_FILE_UPLOAD_ALLOWED,
  DOC_UPDATE,
  FIRST_TIME_LOGIN,
  HEALTH_BACK_ID,
  HEALTH_CARD_FRONT_ID,
  HEALTH_INFO,
  INSURANCE_BACK_ID,
  INSURANCE_FRONT_ID,
  IS_DOCS_PENDING,
  IS_INSURANCE,
  LOGGED_IN_USER_ROLE,
  ON_BOARDING_STATUS_UPDATE_FOR_PENDING,
  ORG_ID,
  PARAM_CODE_TO_GET_DOCTYPES,
  PATIENT_ID,
  PROFILE_IMAGE,
  PROFILE_IMAGE2,
  QB_ID,
  SG_PATIENT_ID,
  SG_ROLE_ID,
  SG_USER_ID,
  USER_ID,
  USER_ROLE_ID,
} from "../../config/Config";
import React, { useState, useContext } from "react";
import { siteConfig } from "../../config/site";
import axios from "axios";
import { UserAuthContext } from "../../store/UserAuthContext";
import { ShimmerTable } from "react-shimmer-effects";
import Alert from "../UI/Alert";
import { msg_config } from "../../config/messages";
import AppLogoPro from "../../assets/pro_app_logo.png";
import { useNavigate } from "react-router-dom";
import { clearSession, getDecryptedID, getID, removeKey, setEncryptedID } from "../../utils/utils";
import { decryptData } from "../../utils/util_helper";

const MyDocuments = (props) => {
  const { setOpenMyDocuments, setOpenDocViewer, setOpenTermModalPopup } = props;
  const [sDocumentsTypes, setDocumentsTypes] = useState({
    listAllDocs: [],
    allDocTypes: [],
    uploadedDocTypes: [],
  });
  const [sUploadData, setUploadData] = useState({
    selectedDocType: "",
    selectedSubDocType: "",
  });
  const [progressBar, setProgressBar] = useState(0);
  const [allDocsList, setAllDocsList] = useState([]);
  const [context, setContext] = useContext(UserAuthContext);
  const [loading, setLoading] = useState(false);
  const [sloading, setSubmitLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });
  let navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const userType = context?.UserAuth?.usr_type;


  const onDocumentsSubmit = (data) => {
    // setOpenMyDocuments(false);
    if (props?.handleNext) props?.handleNext();
  };

  useEffect(() => {
    if (isAlert.isTrue) {
      setTimeout(() => {
        setIsAlert((prev) => {
          return { ...prev, isTrue: false };
        });
      }, 5000);
    }
  }, [isAlert]);

  //Axios POST API requestConfig
  const uploadFileDataAxios = (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (event) {
          var percentCompleted = Math.round((event.loaded * 100) / event.total);
          setProgressBar(percentCompleted);
        },
      };
      return axios.post(siteConfig?.upload_multiple_files, data, config);
    } catch (e) {
      console.log(e?.message, "ERROR :");
    }
  };

  useEffect(() => {
    let onboardSTatus = context?.UserAuth?.usr_onboarding_status;
    if (userType === LOGGED_IN_USER_ROLE && onboardSTatus === ON_BOARDING_STATUS_UPDATE_FOR_PENDING) {
      setTermsAccepted(true);
    }
  }, [context])



  //calling all documents types
  const getDocumentsType = () => {
    try {
      let orgId = getDecryptedID(ORG_ID);
      CallAPI(config?.pro_user_get_documents_type_process_id, {
        p_doct_code: PARAM_CODE_TO_GET_DOCTYPES,
        sgOrgId: orgId,
      }).then((res) => {
        if (res?.returnCode) {
          setDocumentsTypes((prev) => {
            return {
              ...prev, allDocTypes: res?.returnData.map((items) => {
                return items?.p_doc_sub_id
              }), listAllDocs: res?.returnData
            }
          });
        } else {
          console.error(res?.msg);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //calling all document types by process_id
    getDocumentsType();
    //calling all documents by process_id
    callAllDocuments(true);
  }, []);

  //function hit when dragging field get the file
  const handleUploadDoc = (e) => {
    try {
      let formData = new FormData();
      const file = e.target.files[0];

      if (
        sUploadData.selectedDocType === "" &&
        sUploadData.selectedSubDocType === ""
      ) {
        setIsAlert({
          isTrue: true,
          type: "error",
          msg: msg_config?.msg_select_document_type,
        });
        let fileInputField = document.querySelector("#inputFileNameDocsSafe");
        fileInputField.value = "";
      } else {
        let fileName = file?.name;
        let fileExtension = file?.name.split(".")[1];
        let isValidFileType =
          DOCUMENT_FILE_UPLOAD_ALLOWED.includes(fileExtension);
        if (isValidFileType) {
          //logic to check if document type is already uploaded
          let IsDocTypeAlreadyUploaded = sDocumentsTypes?.uploadedDocTypes.includes(sUploadData.selectedSubDocType);
          let selectedFullObject = sDocumentsTypes?.listAllDocs.find(items => items?.p_doc_sub_id === sUploadData.selectedSubDocType);

          if (IsDocTypeAlreadyUploaded) {
            setIsAlert({
              isTrue: true,
              type: "error",
              msg: `The ${selectedFullObject?.p_doc_sub_description} is already uploaded.`,
            });
          } else {
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: function (event) {
                var percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgressBar(percentCompleted);
              },
            };
            formData.append("uploadedFile", file);
            axios
              .post(siteConfig?.upload_by_parts, formData, config)
              .then((res) => {
                if (res?.data?.result === "true") {
                  let imageUrl = res?.data?.url;
                  //call function to update the in process all Data ===>
                  UploadDocumentsWithDetails(imageUrl, fileName);
                  setIsAlert({
                    isTrue: true,
                    type: "success",
                    msg: res?.data?.msg,
                  });
                } else {
                  setIsAlert({
                    isTrue: true,
                    type: "error",
                    msg: res?.data?.msg,
                  });
                }
              });
          }




        } else {
          setIsAlert({
            isTrue: true,
            type: "error",
            msg: `Only ${DOCUMENT_FILE_UPLOAD_ALLOWED.join(
              ","
            )} file types is allowed!`,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UploadDocumentsWithDetails = (imageUrl, uploadingFileName) => {
    try {
      let params = {};
      const customerId = context?.UserAuth?.customer_id;
      params.p_doctype_id = sUploadData?.selectedDocType;
      params.p_docst_id = sUploadData?.selectedSubDocType;
      params.p_doc_name = uploadingFileName;
      params.p_doc_path = imageUrl;
      params.p_trans_type_code = "";
      params.p_trans_type_reference_id = "";
      params.document_id = "";
      params.p_doc_remarks = "";
      params.p_doc_id = "";
      params.p_patient_id = customerId;
      //getting file name from the url
      let parts = imageUrl.split("/");
      const fileName = parts[parts.length - 1];
      //removing first digits values from the name of file=====>
      // Find the index of the underscore character
      const underscoreIndex = fileName.indexOf("_");

      // Remove the first digits and underscore
      const newFileName = fileName.slice(underscoreIndex + 1);
      params.p_doc_uploaded_filename = newFileName;
      // return false;
      CallAPI(config?.upload_documents_pro_user_process_id, params).then(
        (res) => {
          if (res?.returnCode) {
            callAllDocuments();
            let fileInputField = document.querySelector(
              "#inputFileNameDocsSafe"
            );
            fileInputField.value = "";
            setUploadData({
              selectedDocType: "",
              selectedSubDocType: "",
            });
            let dropdownField = document.querySelector(
              "#docTypeSelectionInputField"
            );
            dropdownField.value = "";
          } else {
            setIsAlert({
              isTrue: true,
              type: "error",
              msg: res?.msg,
            });
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };



  const callAllDocuments = (isLoading) => {
    try {
      let params = {};
      const customerId = context?.UserAuth?.customer_id;
      const processId = config?.get_upload_documents_process_id;
      params.p_patient_id = customerId;
      if (isLoading) {
        setLoading(true);
      }
      CallAPI(processId, params).then((res) => {
        if (res?.returnCode) {
          setAllDocsList(res?.returnData);
          if (isLoading) {
            setLoading(false);
          }
          //getting all doc types in array
          const uploadedDocTypes = res?.returnData.map((items) => {
            return items?.docst_id;
          })
          setDocumentsTypes((prev) => {
            return { ...prev, uploadedDocTypes: uploadedDocTypes }
          })
        } else {
          setIsAlert({
            isTrue: true,
            type: "error",
            msg: res?.msg,
          });
          if (isLoading) {
            setLoading(false);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectDocType = (e) => {
    try {
      let docIdDetails = e.target.value.split("~");
      const docId = docIdDetails[0];
      const docSubId = docIdDetails[1] || "";
      setUploadData((prev) => {
        return {
          ...prev,
          selectedDocType: docId,
          selectedSubDocType: docSubId,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  //function to call all the upload documents
  const callAllUplaodedFunctions = () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSingleDoc = (docId) => {
    try {
      let params = {};
      const processId = config?.delete_single_document_process_id;
      params.p_doc_id = docId;
      CallAPI(processId, params).then((res) => {
        if (res?.returnCode) {
          //call all docs again if doc is deleted successfully
          callAllDocuments(false);
        } else {
          console.error(res?.msg);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDocumentViewer = (docObject) => {
    try {
      setOpenDocViewer((prev) => {
        return { ...prev, isOpen: true, docData: docObject };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateOnBoardingStatus = () => {
    try {
      // setIsAlert({
      //   isTrue : true,
      //   type:"success",
      //   msg: "Your application is gone to approval"
      // })
      const missingDocTypes = sDocumentsTypes?.listAllDocs.filter(docType => !sDocumentsTypes?.uploadedDocTypes.includes(docType?.p_doc_sub_id));
      let missingDocumentsListNamesArray = missingDocTypes.map((items) => {
        return items?.p_doc_sub_description
      })
      if (missingDocumentsListNamesArray.length > 0) {
        const commaSeparateMissedList = missingDocumentsListNamesArray.join(",");
        setIsAlert({
          isTrue: true,
          type: "error",
          msg: `Please upload that documents (${commaSeparateMissedList})`
        })
      } else {
        //checking if terms & conditions accepted : termsAccepted true or false
        if (termsAccepted) {
          ///calling api
          let params = {};
          params.p_status = ON_BOARDING_STATUS_UPDATE_FOR_PENDING;
          params.p_user_id = context?.UserAuth?.usr_id;
          params.p_org_id = context?.UserAuth?.org_id;
          //getting all to to call process_id
          const processId = config?.xcelecomconfig_update_pro_user_onboarding_status;
          setSubmitLoading(true);
          CallAPI(processId, params)
            .then((res) => {
              if (res?.returnCode) {
                setEncryptedID(FIRST_TIME_LOGIN, "N");
                setEncryptedID(IS_DOCS_PENDING, "N");
                setOpenMyDocuments(false)
                setContext((prev) => ({
                  ...prev,
                  UserAuth: res?.returnData[0],
                }));
                setIsAlert({
                  isTrue: true,
                  type: "success",
                  msg: msg_config?.msg_account_is_in_verification
                })
                setSubmitLoading(false);
              } else {
                setIsAlert({
                  isTrue: true,
                  type: "error",
                  msg: res?.msg
                })
                setSubmitLoading(false);
              }
            })
        } else {
          setIsAlert({
            isTrue: true,
            type: "error",
            msg: msg_config?.msg_please_accept_terms_and_conditions
          })
        }
      }

    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className={`${styles?.myDocumentsContainer}`}>
      {/* <Alert type={isAlert?.type} info={isAlert?.msg} /> */}
      {isAlert.isTrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}
      <div className={styles.createaccount_logo}>
        <img src={AppLogoPro} />
        {decryptData(getID(DOC_UPDATE)) === "Y" ? <p
          className={styles?.logoutLink}onClick={() => {setOpenMyDocuments(false)}}>{ Config?.lbl_Close}</p> :<p
          className={styles?.logoutLink}
          
          onClick={() => {
            // let firstloggedin = sessionStorage?.getItem(FIRST_TIME_LOGIN);
            // if(firstloggedin==="Y"){
              navigate("/");
              setContext((prev) => ({
                ...prev,
                UserAuth: {},
              }));
               // localStorage.clear();
            removeKey(USER_PROFILE);
            removeKey(SG_ROLE_ID);
            removeKey(SG_USER_ID);
            removeKey(SG_PATIENT_ID);
            removeKey(IS_INSURANCE);
            removeKey(PROFILE_IMAGE);
            removeKey(PROFILE_IMAGE2);
            removeKey(USER_ID);
            removeKey(QB_ID);
            removeKey(USER_ROLE_ID);
            removeKey(DEFAULT_FLAG);
            removeKey(PATIENT_ID);
            removeKey(CART_COUNT);
            removeKey(INSURANCE_FRONT_ID);
            removeKey(INSURANCE_BACK_ID);
            removeKey(HEALTH_CARD_FRONT_ID);
            removeKey(HEALTH_BACK_ID);
            removeKey(HEALTH_INFO);
            removeKey(IS_DOCS_PENDING);
            clearSession();
            setEncryptedID(SIGN_IN_WITH, " ");
            setEncryptedID(USER_LOGGED_IN, "N");
            // setGlobalContext((prev) => {
            //   return {
            //     ...prev,
            //     profile_image: "",
            //   };
            // });
            setOpenMyDocuments(false);
            window.location.reload();
          //   }
          //  else{
          //   setOpenMyDocuments(false);
          //  }
           
          }}
        >
          {/* {sessionStorage?.getItem("document_Update"==="Y")? Config?.lbl_Close : Config?.lbl_logout} */}
          {Config?.lbl_logout}
        </p>}
      </div>
      <form onSubmit={handleSubmit(onDocumentsSubmit)}>
        <div className={`${styles?.myDocumentHeader}`}>
          <h3>{Config?.lbl_document_uploadHeading}</h3>
          <p>{Config?.lbl_document_uploadCaption}</p>
        </div>
        <div className={`${styles?.myDocumentBody}`}>
          <div className={`${styles?.selectDocDropdown}`}>
            <select
              name="selectDoc"
              onChange={handleSelectDocType}
              id="docTypeSelectionInputField"
            >
              <option value={""}>{"Select document type"}</option>
              {sDocumentsTypes.allDocTypes.length > 0
                ? sDocumentsTypes?.listAllDocs.map((items, idx) => {
                  return (
                    <option
                      key={idx}
                      value={`${items?.p_doct_id}~${items?.p_doc_sub_id}`}
                    >
                      {items?.p_doc_sub_description}
                    </option>
                  );
                })
                : ""}
            </select>
          </div>
          <div className={`${styles?.uploadDocArea}`}>
            <div className={`${styles?.dragAndDropArea}`}>
              <div className={`${styles?.dragAndDropLabels}`}>
                <img
                  src={placeHolderUploadImage}
                  className={`${styles?.placeHolderUploadImage}`}
                />
                <p>{Config?.lbl_drop_files}</p>
                <p className={`${styles?.browse_text}`}>{Config?.lbl_browse}</p>
              </div>
              <input
                type="file"
                onChange={handleUploadDoc}
                id="inputFileNameDocsSafe"
              ></input>
            </div>

            {progressBar > 0 && progressBar < 100 && (
              <div className={styles?.progressBar}>
                <progress
                  className="marginRight-2 marginTop-1 paddingTop-2"
                  value={progressBar}
                  style={{
                    color: "green",
                    position: "relative",
                    top: "0px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  max="100"
                ></progress>
                {/* <p>{progressBar}%</p> */}
              </div>
            )}
          </div>

          <div className={`${styles?.uploadedDocList}`}>
            <div className={`${styles?.uploadedDocHeading}`}>
              <h3>{Config?.lbl_upload_document}</h3>
            </div>
            <div className={`${styles?.list_of_docs}`}>
              {/* MAPPING ALL THE DOCS IN HTML */}
              {loading ? (
                <div style={{ marginTop: "5px" }}>
                  <ShimmerTable row={0} col={1} />
                </div>
              ) : // <shimmerTAble line={8} gap={10} variant="secondary" />

                allDocsList.length > 0 ?

                  allDocsList.map((items, indx) => {
                    const docPath = siteConfig?.site_url + items?.doc_path;

                    return <div className={`${styles?.docItem}`}>

                      <div className={`${styles?.docType}`}>
                        {items?.docst_description}
                      </div>
                      <div className={`${styles?.docActions}`}>
                        {/* <p>{items?.doc_uploaded_filename}</p> */}

                        {/* <p
                          onClick={() => {
                            handleOpenDocumentViewer(items);
                          }}
                        >
                          <img
                            src={docPath}
                            width={50}
                            height={50}
                            alt={items?.doc_uploaded_filename}
                          /></p> */}
                      </div>
                      <div className={`${styles?.docActions}`}>
                        <p>{items?.doc_uploaded_filename}</p>
                        {/* <p> <img src={viewIcon} className={`${styles?.viewIcon}`} /></p> */}
                        <p
                          className={`${styles?.docImage}`}
                          onClick={() => {
                            handleOpenDocumentViewer(items);
                          }}
                        >
                          <img
                            src={viewIcon}
                            width={50}
                            height={50}
                            alt={items?.doc_uploaded_filename}
                          />
                        </p>
                        <p
                          onClick={() => {
                            handleDeleteSingleDoc(items?.p_doc_id);
                          }}
                        >
                          <img
                            src={deleteIcon}
                            className={`${styles?.deleteIcon}`}
                          />
                        </p>
                      </div>

                    </div>

                  })

                  :

                  <div className={`${styles?.no_docs_found}`}>
                    {Config?.lbl_no_documents_found}
                  </div>}


              {/* <div className={`${styles?.docItem}`}>
              <div className={`${styles?.docType}`}>
                Pan Card
              </div>
              <div className={`${styles?.docActions}`}>
                <p>filename filename.ext</p>
                <p><img src={viewIcon} className={`${styles?.viewIcon}`} /></p>
                <p><img src={deleteIcon} className={`${styles?.deleteIcon}`} /></p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className={`${styles?.myDocumentFooter}`}>
          <div
            className={`${styles?.termsConditionsLink}`}

          >
            <input type="checkbox" checked={termsAccepted}
              // disabled={context?.UserAuth?.usr_onboarding_status !== ON_BOARDING_STATUS_UPDATE_FOR_PENDING ? "disabled" : ""} 
              disabled={context?.UserAuth?.usr_onboarding_status === ON_BOARDING_STATUS_UPDATE_FOR_PENDING ? "disabled" : ""}
              onChange={(e) => {
                if (e.target.checked) {
                  setTermsAccepted(true);
                } else {
                  setTermsAccepted(false);
                }
              }}
              id={"termsConditions"}
            />
            <p>
              <span
                onClick={() => {
                  if (context?.UserAuth?.usr_onboarding_status !== ON_BOARDING_STATUS_UPDATE_FOR_PENDING) {
                    if (termsAccepted) {
                      setTermsAccepted(false);
                    } else {
                      setTermsAccepted(true);
                    }
                  }
                }}
                style={{ cursor: "pointer" }}
              > I accept all the</span> <span className={styles.termName} style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenTermModalPopup(true);
                }}

              >terms & conditions</span>
            </p>

          </div>
          <div className={`${styles?.footer_buttons}`}>
            {/* <Button
            className={styles.backBtn}
              type={Config?.lbl_Btn_type_ghost}
              size="small"
              onClick={() => {
                props?.handleBack();
              }}
              
              htmlType={"button"}
            >
              {btnConfig?.signUp_Ghost_Text_Back}
            </Button> */}
            {/* <div className={styles?.onboardStatusMsg}>
              {msg_config?.msg_account_is_in_verification}
            </div> */}
            <div className={styles.footerBtn}>
              <Button
                className={styles.saveBtn}
                type={Config?.lbl_Btn_type_primary}
                size="small"
                onClick={() => {
                  handleUpdateOnBoardingStatus();
                  //   if (props?.handleNext) props?.handleNext();
                  //   if (props?.checked === true) {
                  // }
                }}
                htmlType={"submit"}
                loading={sloading}
              >
                {btnConfig?.signUp_Primary_Text_Onboard}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyDocuments;
