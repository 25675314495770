import React, { useContext } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/svg/Facebook.svg";
import InstagramIcon from "../../assets/svg/Instagram.svg";
import TwitterIcon from "../../assets/svg/Twitter.svg";
import YoutubeIcon from "../../assets/svg/Youtube.svg";
// import { AppLogo } from "../../assets/appLogo/safe_pharmacy_logo.png";
import AppLogo from "../../assets/sf_svg/safe_pharmacy_white_footer.svg";
import Label from "../UI/Label";
import Styles from "./index.module.css";
import Config from "../../config/label";
import { UserAuthContext } from "../../store/UserAuthContext";
import footerLogoPro from "../../assets/png/pro_user_logo_footer.png"
import { LOGGED_IN_USER_ROLE } from "../../config/Config";
import Medx_blue from "../../assets/svg/Medx_blue.svg"

function Footer(props: any) {
  const [context] = useContext(UserAuthContext);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className={Styles["footerContainer"]}>
        <div className="inner_container">
          <div className={Styles["footer_main"]}>
            <div className={Styles["footerStart"]}>
              <div className={Styles["footerStart-inner"]}>
                <div className={Styles["footerLogo"]}>
                  <img className={Styles["footer_safe_pharmacy_logo"]} src={context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? footerLogoPro : AppLogo} alt="app_logo" draggable="false" />
                  {/* <span className={Styles["logo_Seperator"]}></span>
                  <Link to="/medex">
                  <img className={Styles["footer_medex_logo"]} src={Medx_blue} alt="medex_logo" draggable="false" />
                  </Link> */}
                </div>
                <div className={Styles["footerLogo"]}>
                  <Link to="/medex"  
                  style={{ textDecoration: "none" }}
                   onClick={scrollToTop}>
                  <img className={Styles["footer_medex_logo"]} src={Medx_blue} alt="medex_logo" draggable="false" />
                  </Link>
                </div>
                {/* <p className={Styles["footertext"]}>
                  {Config?.footer_serviceline_Text}
                </p> */}
                {/* <div className={Styles["buttonLinks"]}>
              <Link to="" className={Styles["linkButton"]} draggable='false'>
                <img src={FacebookIcon} alt="Facebook" />
              </Link>
              <Link to="" className={Styles["linkButton"]} draggable='false'>
                <img src={InstagramIcon} alt="Instagram" />
              </Link>
              <Link to="" className={Styles["linkButton"]} draggable='false'>
                <img src={TwitterIcon} alt="Twitter" />
              </Link>
              <Link to="" className={Styles["linkButton"]} draggable='false'>
                <img src={YoutubeIcon} alt="Youtube" />
              </Link>
            </div> */}
              </div>
            </div>
            <div className={Styles["linkmenus"]}>
              <div className={Styles["linksMenu"]}>
                <h3 className={Styles["menuTitle"]}>
                  {Config?.footer_linkmenu_Title}
                </h3>
                <ul>
                  <li>
                    <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      className={Styles["linkText"]}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      draggable="false"
                    >
                      {Config?.footer_linkmenu_Option1}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                      style={{ textDecoration: "none" }}
                      className={Styles["linkText"]}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      draggable="false"
                    >
                      {Config?.footer_linkmenu_Option2}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      style={{ textDecoration: "none" }}
                      className={Styles["linkText"]}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      draggable="false"
                    >
                      {Config?.footer_linkmenu_Option6}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to=""
                      style={{ textDecoration: "none" }}
                      className={Styles["linkText"]}
                      // onClick={() => {
                      //   window.scrollTo(0, 0);
                      // }}
                      draggable="false"
                    >
                      {Config?.footer_linkmenu_Option7}
                    </Link>
                  </li> */}
                  {/* <li>
                <Link
                  to="/home-page"
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  draggable='false'
                >
                  {Config?.footer_linkmenu_Option3}
                </Link>
              </li> */}
                  {/* <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_linkmenu_Option4}
                </Link>
              </li> */}
                  {/* <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_linkmenu_Option5}
                </Link>
              </li> */}
                </ul>
              </div>
              {/* <div className={Styles["AboutMenu"]}>
            <h3 className={Styles["menuTitle"]}>
              {Config?.footer_aboutmenu_Title}
            </h3>
            <ul>
              <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_aboutmenu_Option1}
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_aboutmenu_Option2}
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_aboutmenu_Option3}
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  style={{ textDecoration: "none" }}
                  className={Styles["linkText"]}
                  draggable='false'
                >
                  {Config?.footer_aboutmenu_Option4}
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div className={Styles["Addresscontainer"]}>
          <h3 className={Styles["addressTitle"]}>
            {Config?.footer_address_Title}
          </h3>
          <br />
          <address>
            <p className={Styles["addressContent"]}>
              {Config?.footer_address_Line1}
            </p>
            <p className={Styles["addressContent"]}>
              {Config?.footer_address_Line3}
            </p>
            <p className={Styles["addressContent"]}>
              {Config?.footer_address_Line4}
            </p>
            {/* <p className={Styles["addressContent"]}>
              {Config?.footer_address_Line5}
            </p> */}
            <p className={Styles["addressContent"]}>
              {Config?.footer_address_Line2}
            </p>
          </address>
        </div>
        </div>
        </div>
      </div>
      <div className={Styles["footerContainer2"]}>
        <hr className={Styles["footerDivider"]} />
        <div className={Styles["bottomTextContainer"]}>
          <div className="inner_container">
            <div className={Styles["copyright_footer"]}>
              <div className={Styles["copyright_footer_img"]}>
                <img className={Styles["footer_safe_pharmacy_logo"]} src={context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? footerLogoPro : AppLogo} alt="app_logo" draggable="false" />
                {/* <Link to="/medex"  
                  style={{ textDecoration: "none" }}
                   onClick={scrollToTop}> */}
                  <img className={Styles["footer_medex_logo"]} src={Medx_blue} alt="medex_logo" draggable="false" />
                {/* </Link> */}
              </div>
              <div className={Styles["copyright_footer_links"]}>
                <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      className={Styles["bottomText"]}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      draggable="false"
                    >
                      {Config?.footer_linkmenu_Option1}
                </Link>
                <Link
                  to="/about-us"
                  style={{ textDecoration: "none" }}
                  className={Styles["bottomText"]}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  draggable="false"
                >
                  {Config?.footer_linkmenu_Option2}
                </Link>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none" }}
                  className={Styles["bottomText"]}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  draggable="false"
                >
                  {Config?.footer_linkmenu_Option6}
                </Link>

                <Link
                  to="/privacy"
                  style={{ textDecoration: "none" }}
                  className={Styles["bottomText"]}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  draggable="false"
                >
                  {Config?.footer_bottom_text1}
                </Link>
                <Link
                  to="/terms-and-conditions"
                  style={{ textDecoration: "none" }}
                  className={Styles["bottomText"]}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  draggable="false"
                >
                  {Config?.footer_bottom_text3}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
