import React, { useState, useContext, useEffect, useRef } from "react";
import Styles from "./index.module.css";
import canadaLife from "../../../assets/svg/canadaLife.svg";
import Dropdown from "./Dropdown";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import Tabs from "../../../components/UI/AppTabs/Tabs/tab";
import TabPane from "../../../components/UI/AppTabs/Tabs-pane/tab-pane";
import deleteIcon from "../../../assets/svg/FileDelete.svg";
import ProductCard from "./ProductCard";
import LinkButton from "../../../components/UI/LinkButton";
import btnConfig from "../../../config/button";
import APIContext from "../../../store/api-context";
import { config } from "../../../config/processId.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import IconButton from "../../../components/UI/IconButton";
import PopConfirm from "../../../components/UI/PopConfirm";
import EmptyCartPopup from "./EmptyCartPopup";
import { msg_config } from "../../../config/messages";
import {
  ShimmerTable,
  ShimmerText,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import Heading from "../../../components/UI/Heading";
import noshow from "../../../assets/svg/noshow.svg";
import Delete from "../../../assets/icons/Delete_icon.svg";
import Saveforlater_icon from "../../../assets/icons/Saveforlater_icon.svg";
import QtyInput from "./QtyInput";
import { Link, useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import Config from "../../../config/label";
import { useForm } from "react-hook-form";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import AddInsurance from "../../MyInsurance/AddInsurance";
import { Heart, SubmitRX } from "../../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, getID, getProductImage, objDecrypt, setEncryptedID, triggerLocalStorage } from "../../../utils/utils";
import CountUp from "react-countup";
import HealthInfo from "../../../assets/png/HealthInfo.png";
import HealthCard from "../../../assets/png/HealthCard.png";
import Insurance from "../../../assets/png/Insurance.png";
import profileCheck from "../../../config/profileCheck";
import HealthInfoCards from "../../../components/HealthInfoCards";
import ProfilePopup from "../../Landingpage/ProfilePopup";
import dummyImgage from "../../../assets/800391.png";
import tabImg from "../../../assets/cart/tablet.png";
import syrupImg from "../../../assets/cart/syrup.png";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { ACTIVE_PRODUCT_ID, CART_COUNT, HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, LOGGED_IN_USER_ROLE, RX_CART_FLAG, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import UnitPricingDropdown from "../../../components/UnitPricingDropdown";
import { useSelector } from "react-redux";
import ProductList from "../../ProductList";
import { siteConfig } from "../../../config/site";
import Alert from "../../../components/UI/Alert";
import DeleteConfirmPopup from "../../MyListPage/DeleteConfirmPopup";
import DeleteConfirmation from "../../ProductListScreen/ConfirmPopup";
import { decryptData } from "../../../utils/util_helper";

function CartScreen() {
  const [activeTab, setActiveTab] = useState("All");
  const [suggestUsInit] = useContext(APIContext);
  const [productList, setProductList] = useState([]);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [isConfirmPopup, setIsConfirmPopup] = useState({
    isTrue: false,
    id: "",
    isUnitChanging: false,
  });
  const [rxProductList, setRxProductList] = useState([]);
  const [cartTotal, setCartTotal] = useState<any>("");
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [loadingCoverage, setLoadingCoverage] = useState(false);
  const [profileData, setProfileData] = useState<any>([]);
  const [primaryInsurance, setPrimaryInsurance] = useState<any>("");
  const [changeCoverageLoading, setChangeCoverageLoading] = useState(false);
  const [coverageList, setCoverageList] = useState<any>([]);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [isDefault, setIsDefault] = useState<any>("1");
  const navigate = useNavigate();
  const user_id = decryptData(getID(SG_USER_ID));
  const [rxCheckLoading, setRxCheckLoading] = useState(true);
  const [isRxTypeChecked, setIsRxTypeChecked] = useState(false);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  const [openHealthInfoCard, setOpenHealthInfoCard] = useState(false);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [context, setContext] = useContext(UserAuthContext);
  const [productQty, setProductQty] = useState({
    productQuantity: "1",
    unitId: "",
  });
  const [alertConfig, setIsAlert] = useState({
    isTrue: false,
    info: "",
    type: "",
  })
  const [isImageError, setImageError] = useState("");
  triggerLocalStorage();

  let localStorageCopy = { ...userProfileJson };
  // const [productQty, setProductQty] = useState("");
  const gaEventTracker = useAnalyticsEventTracker("Cart page");
  const [voucherDetails, setVoucherDetails] = useState({
    couponCode: "",
  })
  const [voucherLoading, setVLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useForm();

  const getProducts = () => {
    CallAPI(config?.get_cart_process_id, {
      p_user_id: decryptData(getID(SG_USER_ID)),
      p_patient_id: decryptData(getID(SG_PATIENT_ID)),
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData.length === 0) {
          // setIsCartEmpty(true);
          // setProductList([]);
          setRxProductList([])
          setLoading(false);
        } else {
          getCartTotal();
          if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
            setProductList(res?.returnData);
          } else {
            setProductList(
              res?.returnData?.filter(
                (val: any) => val?.item_type !== "rx_not_submitted"
              )
            );
            setRxProductList(
              res?.returnData.filter(
                (val: any) => val?.item_type === "rx_not_submitted"
              )
            );
          }
        }
      } else {
        // setIsCartEmpty(true);
      }
    });
  };

  const getCartTotal = () => {
    CallAPI(config?.get_cart_total_process_id, {
      p_user_id: userProfileJson?.usr_id,
      p_patient_id: userProfileJson?.patient_id,
    }).then((res) => {
      if (res?.returnCode) {
        if (res?.returnData?.length === 0) {
          // setIsCartEmpty(true);
          // setCartTotal({})
        } else {
          setCartTotal(res?.returnData[0]);
          // setIsCartEmpty(false);
        }
      }
    });
  };

  useEffect(() => {
    try {
      if (productList.length > 0) {
        setLoading(false);
      }
      if (rxProductList.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [productList])

  const getInsurance = () => {
    CallAPI(config?.get_customer_insurance_process_id, {
      p_insurance_ref_id: "",
      p_patient_id: userProfileJson?.patient_id,
      p_org_id: userProfileJson?.org_id,
    }).then((res) => {
      if (res?.returnCode) {
        // setLoadingCoverage(true);
        // CallAPI(config?.get_insurances_coverage_list_process_id, {
        //   p_insurance_id: res?.returnData?.filter(
        //     (val: any) => val?.sequence === "1"
        //   )[0]?.insurance_id,
        // }).then((res) => {
        //   if (res?.returnCode) {
        //     setCoverageList([
        //       ...res?.returnData?.map((item: any) => {
        //         return {
        //           value: item?.id,
        //           label: item?.description,
        //         };
        //       }),
        //     ]);
        //     setLoadingCoverage(false);
        //   }
        // });
        setPrimaryInsurance(
          res?.returnData.filter((val: any) => val?.sequence === "1")[0]
        );
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      getProducts();
      // getCartTotal();
      getInsurance();
      // setLoading(false);
    }
    profileCheck(suggestUsInit);
    // userInfohandler();
  }, [suggestUsInit]);

  useEffect(() => {
    if (openHealthInfoCard === false) {
      // getInsurance();
    }
  }, [openHealthInfoCard, suggestUsInit]);

  // isInsuranceModalOpen

  useEffect(() => {
    if (!isInsuranceModalOpen) {
      // setLoading(true);
      // getInsurance();
      // setLoading(false);
    }
  }, [isInsuranceModalOpen]);

  // useEffect(() => {
  //   if (primaryInsurance !== "" && coverageList?.length > 0) {
  //     setLoadingCoverage(true);
  //     coverageList &&
  //       setValue(
  //         "coverage",
  //         coverageList.find(
  //           (i: any) => i.value === primaryInsurance?.coverage_id
  //         )
  //       );
  //     setLoadingCoverage(false);
  //   }
  // }, [primaryInsurance, coverageList]);

  const qtyChange = (p_id: string, p_qty: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.update_cart_item_qty_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_qty: p_qty,
        p_cart_item_id: p_id,
      }).then((res) => {
        if (res?.returnCode) {
          //window.location.reload();
          getProducts();
          getCartTotal();
        }
      });
    }
  };

  function handleChange(event: any) {
    if (event.target.value === "") {
    } else if (event.target.value === "0") {
      event.target.value = 1;
      qtyChange(event.target.id, event.target.value);
    } else {
      qtyChange(event.target.id, event.target.value);
    }
  }

  const onDeleteHandler = (id: string, isUnitChanging: boolean) => {
    setIsConfirmPopup({ isTrue: true, id: id, isUnitChanging: isUnitChanging });
    // if (suggestUsInit?.returnCode === true) {
    //   // return false;
    //   setLoading(true);
    //   CallAPI(config?.delete_cart_item_process_id, {
    //     p_cart_item_id: id,
    //     p_user_id: userProfileJson?.usr_id,
    //   }).then((res) => {
    //     if (res?.returnCode) {
    //       gaEventTracker("Items deleted from cart");
    //       var count: any = localStorage?.getItem("cart_count");
    //       localStorage?.setItem("cart_count", (parseInt(count) - 1).toString());
    //       //window.location.reload();
    //       getProducts();
    //       getCartTotal();
    //       if (!isUnitChanging) {
    //         let updateProductList = productList.filter((items: any) => {
    //           if (items?.id === id) {
    //             return false;
    //           }
    //           return true;
    //         });
    //         setProductList(updateProductList);
    //       }
    //       setLoading(false);
    //     }
    //     setLoading(false);
    //   });
    // }
  };

  const onAddSFLHandler = (cart_id: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.move_cart_item_to_saveforlater_process_id, {
        p_cart_item_id: cart_id,
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          gaEventTracker("Items saved for later");
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          // setLoading(true);
          getProducts();
          getCartTotal();
          //removing item from the list
          let updatedProductList = productList.filter((items: any) => {
            if (cart_id == items?.id) {
              return false;
            }
            return true;
          });
          setProductList(updatedProductList);
          // setLoading(false);
          //window.location.reload();
          setIsAlert({
            isTrue: true,
            type: "success",
            info: res?.msg,
          })
        } else {
          setIsAlert({
            isTrue: true,
            type: "error",
            info: res?.msg,
          })
        }
        if (
          res?.returnCode === false &&
          res?.msg === "Product already exists in the cart."
        ) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          // setLoading(true);
          getProducts();
          getCartTotal();
          // setLoading(false);
          //window.location.reload();
        }
      });
    }
  };

  const moveToWishList = (cart_id: any) => {
    try {
      if (suggestUsInit?.returnCode === true) {
        const processId = config?.move_to_wishlist_process_id;
        const params = {
          p_cart_item_id: cart_id,
          p_cus_id: decryptData(getID(SG_PATIENT_ID)),
          p_internal_flag: ""
        }
        CallAPI(processId, params)
          .then((res) => {
            if (res?.returnCode) {
              var count: any = getDecryptedID(CART_COUNT);
              setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
              getProducts();
              getCartTotal();
              let updatedProductList = productList.filter((items: any) => {
                if (cart_id == items?.id) {
                  return false;
                }
                return true;
              });
              setProductList(updatedProductList);
              setIsAlert({
                isTrue: true,
                type: "success",
                info: res?.msg,
              })
            } else {
              setIsAlert({
                isTrue: true,
                type: "error",
                info: res?.msg,
              })
            }
          })
      }

    } catch (error) {
      console.error(error);
    }
  }

  const tabLabels = [
    {
      value: "all",
      label: "All",
    },
    // {
    //   value: "rx",
    //   label: "Prescription drugs",
    // },
    // {
    //   value: "OTC",
    //   label: "OTC products",
    // },
  ];

  const getCardList = (label: string) => {
    if (label !== "all") {
      // filter products based on their types
      let res = productList?.filter(
        (product: any) => product?.item_type === label
      );
      return (
        <div className={Styles["cartItemList"]}>
          {res?.map((product: any, idx: any) => (
            <ProductCard
              // @ts-ignore
              pricingData={JSONCheckerFunc(product?.packsize_json) || []}
              productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
              product={product}
              id={product?.id}
              type={product?.item_type_name}
              imgUrl={product?.srv_image.split("~")[0]}
              imgAlt={product?.srv_name}
              name={product?.srv_name}
              detail={product?.description}
              price={product?.cart_item_applicable_price.toString()}
              qty={product?.item_quantity}
              setLoading={setLoading}
              getProducts={getProducts}
              getCartTotal={getCartTotal}
              key={idx}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className={Styles["cartItemList"]}>
          {productList?.map((product: any, idx: any) => (
            <ProductCard
              // @ts-ignore
              productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
              pricingData={JSONCheckerFunc(product?.packsize_json) || []}
              product={product}
              id={product?.id}
              type={product?.item_type_name}
              imgUrl={product?.srv_image.split("~")[0]}
              imgAlt={product?.srv_name}
              name={product?.srv_name}
              detail={product?.description}
              price={product?.cart_item_applicable_price.toString()}
              qty={product?.item_quantity}
              setLoading={setLoading}
              getProducts={getProducts}
              getCartTotal={getCartTotal}
              key={idx}
            />
          ))}
        </div>
      );
    }
  };

  // const checkRxType: any = productList?.find(
  //   (item: any) =>
  //     item?.item_type === "rx_approved" || item?.item_type === "rx_pending"
  // );

  // if (
  //   (checkRxType !== undefined && checkRxType?.item_type === "rx_pending") ||
  //   checkRxType?.item_type === "rx_approved"
  // ) {
  //   setIsRxTypeChecked(true);
  // }


  const getTable = (label: string) => {
    if (label != "all") {
      // filter products based on their types
      let res = productList?.filter((item: any) =>
        item?.item_type.includes(label)
      );

      return (
        <div className={Styles["cartItemTable"]}>
          {res?.length === 0 ? (
            <div className={Styles["title"]}>
              <div className={Styles["checkIconWrapper"]}>
                <img src={noshow} alt="Empty Cart icon" draggable="false" />
              </div>
              <Heading className={`${Styles?.page_heading}`}>
                {Config?.lbl_no_products}
              </Heading>
            </div>
          ) : (
            <table>
              {/* <tr className={Styles["cartItemColumn"]}>
                <th className={Styles["itemColumnProduct"]}>Product</th>
                <th className={Styles["itemColumnPrice"]}>Price</th>
                <th className={Styles["itemColumnQty"]}>QTY</th>
                <th className={Styles["itemColumnTotal"]}>Total</th>
                <th className={Styles["itemColumnAction"]}></th>
              </tr> */}
              {res?.map((item: any) => {
                return (
                  <tr key={item?.id}>
                    <td>
                      <div className={Styles["cartItemProduct"]}>
                        {/* {getProductstatus(item?.item_type)} */}
                        <Link
                          to={`/product-detail/${item?.slug}`}
                          draggable="false"
                        >
                          <div className={Styles["productImage"]}>
                            <img
                              src={dummyImgage}
                              // src={item?.srv_image.split("~")[0]}
                              alt={item?.srv_name}
                              draggable="false"
                            />
                          </div>
                        </Link>
                        <div className={Styles["productTitle"]}>
                          <Link
                            to={`/product-detail/${item?.slug}`}
                            style={{ textDecoration: "none" }}
                            className={Styles["productLink"]}
                            draggable="false"
                          >
                            <h2 className={Styles["productName"]}>
                              {item?.srv_name + " " + item?.srv_packaging}
                            </h2>
                            <p className={Styles["productdescription"]}>
                              {item?.description}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      {/* {getPrice(item?.single_item_discounted_price)} */}
                      {getPrice(
                        Number(item?.cart_item_applicable_price) === 0 ? item?.cart_item_price : item?.cart_item_applicable_price,
                        item?.cart_item_price
                      )}
                    </td>
                    <td>
                      <div className={Styles["qtyContainer"]}>
                        {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? (
                          "<UnitPricingDropdown />"
                        ) : (
                          <QtyInput
                            name="productqty"
                            id={item?.id}
                            className={`${Styles?.qtyInp}`}
                            type={Config?.lbl_Inp_type_text}
                            defaultValue={item?.item_quantity}
                            placeholder={item?.item_quantity}
                            size="small"
                            qtyChange={qtyChange}
                            maxLength={3}
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      {getTotal(
                        item?.cart_item_grand_total,
                        item?.cart_item_copayment,
                        item?.cart_item_copayment_applicable
                      )}
                    </td>
                    <td>
                      <div className={Styles["cartItemActions"]}>
                        {/* <IconButton
                          title="Save for later"
                          onClick={() => onAddSFLHandler(item?.id)}
                        >
                          <img
                            src={Saveforlater_icon}
                            alt="save for later"
                            draggable="false"
                          />
                        </IconButton> */}
                        {/* <PopConfirm
                          title="Are you sure ?"
                          onConfirm={() => onDeleteHandler(item?.id)}
                        > */}
                        <IconButton
                          title="Delete"
                          onClick={() => onDeleteHandler(item?.id, false)}
                        >
                          {/* <img src={Delete} alt="delete" draggable="false" /> */}
                          <i className="fa-solid fa-trash-can" draggable="false"></i>
                        </IconButton>
                        {/* </PopConfirm> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className={Styles["cartItemTable"]}>
          {productList?.length === 0 ? (
            <div className={Styles["Empty_title"]}>
              <div className={Styles["checkIconWrapper"]}>
                <img src={noshow} alt="Empty Cart icon" draggable="false" />
              </div>
              <Heading className={`${Styles?.page_heading}`}>
                {Config?.lbl_no_products_in_cart}
              </Heading>
            </div>
          ) : (
            <table className={`${Styles.cartScreen}`}>
              {/* <tr className={Styles["cartItemColumn"]}>
                <th className={Styles["itemColumnProduct"]}>Product</th>
                <th className={Styles["itemColumnPrice"]}>Price</th>
                <th className={Styles["itemColumnQty"]}>QTY</th>
                <th className={Styles["itemColumnTotal"]}>Total</th>
                <th className={Styles["itemColumnAction"]}></th>
              </tr> */}
              {productList?.map((item: any) => {
                const productId = item?.srv_id;
                const productImage = item?.srv_image.split("~")[0];
                let packSizeJson = JSONCheckerFunc(item?.packsize_json);
                return (
                  <tr key={item?.id}>
                    <td style={{ width: "40%" }} className={Styles["cart_item_name"]}>
                      <div className={Styles["cartItemProduct"]}>
                        {/* {getProductstatus(item?.item_type)} */}
                        <Link
                          to={`/product-detail/${item?.slug}`}
                          draggable="false"
                        >
                          <div className={Styles["productImage"]}>
                            <img
                              src={getProductImage(item?.salt_composition, productImage)}
                              // src={item?.srv_image.split("~")[0]}
                              alt={item?.srv_name}
                              draggable="false"
                              onError={(e: any) => {
                                e.target.src = getProductImage(item?.salt_composition, "")
                              }}
                            />
                          </div>
                        </Link>
                        <div className={Styles["productTitle"]}>
                          <Link
                            to={`/product-detail/${item?.slug}`}
                            style={{ textDecoration: "none" }}
                            className={Styles["productLink"]}
                            draggable="false"
                          >
                            <h2 className={Styles["productName"]}>
                              {item?.srv_name + " " + item?.srv_packaging}
                            </h2>
                            <p className={Styles["productdescription"]}>
                              {item?.description}
                              {/* {"On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will"} */}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: '14%' }} className={Styles["cart_item_price"]}>
                      {getPrice(
                        Number(item?.cart_item_applicable_price) === 0 ? item?.cart_item_price : item?.cart_item_applicable_price,
                        item?.cart_item_price
                      )}
                      {/* <del>{item?.cart_item_price}</del> */}
                    </td>
                    <td style={{ width: "20%" }} className={Styles["cart_item_quantity"]}>
                      <div className={Styles["qtyContainer"]}>
                        {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? (
                          <UnitPricingDropdown
                            product={item}
                            pricingData={packSizeJson}
                            setProductQty={setProductQty}
                            productQty={productQty}
                            qtyChange={qtyChange}
                            cartItemId={item?.id}
                            isCartScreen={true}
                            getProducts={getProducts}
                            onDeleteHandler={onDeleteHandler}
                          />
                        ) : (
                          <QtyInput
                            name="productqty"
                            id={item?.id}
                            className={`${Styles?.qtyInp}`}
                            type={Config?.lbl_Inp_type_text}
                            defaultValue={item?.item_quantity}
                            placeholder={item?.item_quantity}
                            size="small"
                            qtyChange={qtyChange}
                            maxLength={3}
                          />
                        )}
                      </div>
                    </td>
                    <td style={{ width: "12%" }} className={Styles["cart_item_acutal_price"]}>
                      {getTotal(
                        item?.cart_item_grand_total,
                        item?.cart_item_copayment,
                        item?.cart_item_copayment_applicable
                      )}
                    </td>
                    <td style={{ width: '12%' }} className={Styles["cart_item_delete_item"]}>
                      <div className={Styles["cartItemActions"]}>
                        {context?.UserAuth?.usr_type !==
                          LOGGED_IN_USER_ROLE && (
                            <p
                              onClick={() => {
                                moveToWishList(item?.id);
                              }}
                            >
                              {Config?.lbl_move_to_wishlist}
                            </p>
                          )}
                        {/* <IconButton
                          title="Save for later"
                          onClick={() => onAddSFLHandler(item?.id)}
                        >
                          <img
                            src={Saveforlater_icon}
                            alt="save for later"
                            draggable="false"
                          />
                        </IconButton> */}
                        {/* <PopConfirm
                          title="Are you sure ?"
                          onConfirm={() => onDeleteHandler(item?.id)}
                        > */}
                        <IconButton
                          title="Delete"
                          onClick={() => onDeleteHandler(item?.id, false)}
                        >
                          {/* <img src={Delete} alt="delete" draggable="false" /> */}
                          <i className="fa-solid fa-trash-can" draggable="false"></i>
                        </IconButton>
                        {/* </PopConfirm> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      );
    }
  };
  const getTableForMobile = (label: string) => {
    if (label == "all") {
      // filter products based on their types
      let res = productList?.filter((item: any) =>
        item?.item_type.includes(label)
      );

      return (
        <div className={Styles["cartItemTableForMobile"]}>
          {productList?.length === 0 ? (
            <div className={Styles["title"]}>
              <div className={Styles["checkIconWrapper"]}>
                <img src={noshow} alt="Empty Cart icon" draggable="false" />
              </div>
              <Heading className={`${Styles?.page_heading}`}>
                {Config?.lbl_no_products}
              </Heading>
            </div>
          ) : (
            <div>
              {productList?.map((item: any, idx: any) => {
                const productId = item?.srv_id;
                const actualPrice = `${siteConfig?.site_currency + item?.cart_item_price}`
                const grandTotal = `${siteConfig?.site_currency + item?.cart_item_grand_total}`
                const packSizeJson = JSONCheckerFunc(item?.packsize_json);
                const productImage = item?.srv_image.split("~")[0];
                return (
                  <div
                    key={idx}
                    className={Styles['cartItemWrapper']}>
                    <div className={Styles['TopArea']}>
                      <Link
                        //@ts-ignore
                        to={`/product-detail/${item?.slug}`}
                        draggable="false">
                        <div className={Styles['product_title_main']}>
                          <div className={Styles['productImg']}>
                            <img
                              //@ts-ignore
                              src={getProductImage(item?.salt_composition, productImage)}
                              //@ts-ignore
                              alt={item?.srv_name}
                              draggable="false"
                              onError={(e: any) => {
                                e.target.src = getProductImage(item?.salt_composition, "")
                              }}
                            />
                          </div>
                          <div className={Styles['productName']}>
                            <h2 className={Styles["productNameHeading"]}>
                              {
                                //@ts-ignore
                                item?.srv_name + " " + item?.srv_packaging}
                            </h2>
                          </div>
                        </div>
                      </Link>
                      <div className={Styles['productDelete']}>
                        <IconButton
                          title={Config?.lbl_move_to_wishlist}
                          //@ts-ignore
                          onClick={() => moveToWishList(item?.id)} className={Styles['save_for_later']}
                        >
                          {/* <img src={Delete} alt="delete" draggable="false" /> */}
                          {/* <i className="fa-solid fa-trash-can" draggable="false"></i> */}
                          <Heart
                            fillColor="#EC0038"
                            width="30"
                            height="30"
                            style={{ marginTop: "4px" }}
                            className={`${Styles?.heart}`}
                          />
                        </IconButton>
                        <IconButton
                          title="Delete"
                          //@ts-ignore
                          onClick={() => onDeleteHandler(item?.id, false)}
                        >
                          {/* <img src={Delete} alt="delete" draggable="false" /> */}
                          <i className="fa-solid fa-trash-can" draggable="false"></i>
                        </IconButton>
                      </div>
                    </div>
                    <div className={Styles['FooterArea']}>
                      <div className={Styles['productPrice']}>
                        <h5>{Config?.lbl_cart_Unit_price}</h5>
                        <p>{actualPrice}</p>
                      </div>
                      <div className={Styles['productQuantity']}>
                        {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? (
                          <UnitPricingDropdown
                            product={item}
                            pricingData={packSizeJson}
                            setProductQty={setProductQty}
                            productQty={productQty}
                            qtyChange={qtyChange}
                            //@ts-ignore
                            cartItemId={item?.id}
                            isCartScreen={true}
                            getProducts={getProducts}
                            onDeleteHandler={onDeleteHandler}
                          />
                        ) : (
                          <QtyInput
                            name="productqty"
                            //@ts-ignore
                            id={item?.id}
                            className={`${Styles?.qtyInp}`}
                            type={Config?.lbl_Inp_type_text}
                            //@ts-ignore
                            defaultValue={item?.item_quantity}
                            //@ts-ignore
                            placeholder={item?.item_quantity}
                            size="small"
                            qtyChange={qtyChange}
                            maxLength={3}
                          />
                        )}

                      </div>
                      <div className={Styles['productTotal']}>
                        <h5>{Config?.lbl_cart_total}</h5>
                        <p>{grandTotal}</p>
                      </div>
                    </div>

                  </div>
                )
              })}
            </div>
          )}
        </div>
      );
    }
  };


  const getProductstatus = (type: string) => {
    if (type === "rx_approved") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["greenCircle"]} />
          <p>Approved</p>
          <p>{Config?.Prescription_text}</p>
        </div>
      );
    } else if (type === "rx_pending") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["orangeCircle"]} />
          <p>{Config?.Prescription_text}</p>
          <p>Pending</p>
        </div>
      );
    } else {
      return (
        <div className={Styles["drugStatus"]}>
          <p>OTC</p>
          <p>products</p>
        </div>
      );
    }
  };

  const getPrice = (price: string, actualPrice: any) => {
    const price_label = `${siteConfig?.site_currency}${Number(price).toFixed(2)}`;
    const actual_price_label = `${siteConfig?.site_currency}${Number(actualPrice).toFixed(2)}`;
    if (Number(price) === Number(actualPrice)) {
      return (
        <div className={Styles["priceWrapper"]}>
          <h2 className={Styles["itemPrice"]}>{price_label}</h2>
        </div>
      );
    } else {
      return (
        <div className={Styles["priceWrapper"]}>
          <h2 className={Styles["itemPrice"]}>
            {" "}
            <del style={{ marginRight: "10px", fontSize: "12px" }}>
              {actual_price_label}
            </del>
            {price_label}
          </h2>
        </div>
      );
    }
  };

  const getTotal = (total: any, coPay: any, isCoApplicable: any) => {
    if (isCoApplicable === "Y") {
      // const total_copay = (total * 0.3).toFixed(2);
      const total_label = `${siteConfig?.site_currency}${Number(total).toFixed(2)}`;
      const total_copay_label = `${siteConfig?.site_currency}${coPay}`;
      return (
        <div className={Styles["productTotal"]}>
          <h2>{total_label}</h2>
          {/* <div>
            <h2>{total_copay_label}</h2>
            <p>{Config?.estimated_cost_pay_text}</p>
          </div> */}
        </div>
      );
    } else {
      const total_label = `${siteConfig?.site_currency}${Number(total).toFixed(2)}`;
      return (
        <div className={Styles["productTotal"]}>
          <h2>{total_label}</h2>
        </div>
      );
    }
  };

  const onCoverageChangeHandler = (data: any) => {
    // setLoadingCoverage(true);
    setChangeCoverageLoading(true);
    if (suggestUsInit?.returnCode) {
      // setLoading(true);
      // setLoadingCoverage(true);
      CallAPI(config?.save_insurance_process_id, {
        p_ptmpln_id: primaryInsurance?.insurance_ref_id,
        p_patient_id: userProfileJson?.patient_id,
        p_insurance_id: primaryInsurance?.insurance_id,
        p_ppln_id: data?.value,
        p_org_id: userProfileJson?.org_id,
        p_ptmpln_sequence: primaryInsurance?.sequence || "",
        p_ptmpln_status: "Y",
        p_ptmpln_membership_number: primaryInsurance?.ptmpln_membership_number,
        p_ptmpln_date_from: primaryInsurance?.ptmpln_date_from,
        p_ptmpln_date_to: primaryInsurance?.ptmpln_date_to,
        p_insurance_front_image_document_id:
          primaryInsurance?.insurance_front_id,
        p_insurance_back_image_document_id: primaryInsurance?.insurance_back_id,
      }).then((res) => {
        if (res?.returnCode) {
          setChangeCoverageLoading(false);
          // window.location.reload();
          getProducts();
          getInsurance();
          getCartTotal();
          // setLoading(false);
        } else {
          // setLoading(false);
          console.error(res?.error);
        }
      });
    }
  };

  const userInfohandler = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.my_profile_process_id, {
        p_user_id: user_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          setProfileData(res?.returnData[0]);

          setRxCheckLoading(false);
        } else console.error("error", res?.error);
      });
    }
  };

  const checkProfile = () => {
    // Profile check
    const userProfileIncomplete = [
      userProfileJson?.usr_email,
      userProfileJson?.usr_name,
      userProfileJson?.usr_phone,
      userProfileJson?.usr_dob,
    ];

    let isProfileInfo =
      userProfileIncomplete?.includes("") ||
        userProfileIncomplete?.includes(null) ||
        userProfileIncomplete?.includes(undefined)
        ? "Incomplete"
        : "Complete";

    // Insurance check
    const insuranceIncompleteCheck = [
      primaryInsurance?.insurance_front_id,
      primaryInsurance?.insurance_back_id,
    ];

    let isInsuranceInfo =
      insuranceIncompleteCheck?.includes("") ||
        insuranceIncompleteCheck?.includes(null) ||
        insuranceIncompleteCheck?.includes(undefined)
        ? "Incomplete"
        : "Complete";

    // Health card check
    const healthCardIncompleteCheck: any = [
      getDecryptedID(HEALTH_CARD_FRONT_ID),
      getDecryptedID(HEALTH_BACK_ID),
    ];

    let isHealthCardInfo =
      healthCardIncompleteCheck?.includes("") ||
        healthCardIncompleteCheck?.includes(null) ||
        healthCardIncompleteCheck?.includes(undefined)
        ? "Incomplete"
        : "Complete";

    // Health info check
    let isHealthInformation =
      getDecryptedID(HEALTH_INFO) === "" ||
        getDecryptedID(HEALTH_INFO) === null ||
        getDecryptedID(HEALTH_INFO) === undefined
        ? "Incomplete"
        : "Complete";

    // Return result
    let isProfileSetupInfo = [
      isProfileInfo,
      isInsuranceInfo,
      isHealthCardInfo,
      isHealthInformation,
    ];

    let isProfileSetupCompleted = isProfileSetupInfo?.includes("Incomplete")
      ? true
      : false;

    return isProfileSetupCompleted;
  };

  const userProfileIncomplete = [
    localStorageCopy?.usr_email,
    localStorageCopy?.usr_name,
    localStorageCopy?.usr_phone,
    localStorageCopy?.usr_dob,
  ];
  const handleButtonVoucherClick = () => {
    if (voucherDetails?.couponCode === "") {
      setIsAlert({
        isTrue: true,
        type: "error",
        info: Config?.error_voucher_details,
      });
    } else {
      handleSubmitVoucherCode();
    }
  };
  const handleSubmitVoucherCode = () => {
    try {
      let params = {};
      const processId = config?.xcelecom_save_trn_customer_cart_coupon;
      //@ts-ignore
      params.p_coupon_code = voucherDetails?.couponCode;
      //@ts-ignore
      params.p_patient_id = context?.UserAuth?.patient_id;
      setVLoading(true);
      CallAPI(processId, params)
        .then((res) => {
          if (res?.returnCode) {
            // setIsAlert({
            //   isTrue: true,
            //   type: "success",
            //   info: Config?.success_voucher_applied,
            // })
            getProducts();
            // getCartTotal();
            setVLoading(false);
          } else {
            setIsAlert({
              isTrue: true,
              type: "error",
              info: res?.msg
            })
            setVLoading(false);
          }
        })

    } catch (error) {
      console.error(error);
    }
  }
  const handleFillVoucherCode = (e: any) => {
    try {
      const couponValue = e.target.value.toUpperCase()
      setVoucherDetails((prev: any) => {
        return { ...prev, couponCode: couponValue }
      })
    } catch (error) {
      console.error(error);
    }
  }

  const handleRemoveVoucherCode = () => {
    try {
      let params = {};
      const processId = config?.xcelecom_update_trn_customer_cart_coupon_delete
      //@ts-ignore
      params.p_patient_id = context?.UserAuth?.patient_id
      setVLoading(true)
      CallAPI(processId, params)
        .then((res) => {
          if (res?.returnCode) {
            getProducts()
            // getCartTotal();
            setVLoading(false)
            //@ts-ignore
            setVoucherDetails({ couponCode: "" })
          } else {
            setIsAlert({
              isTrue: true,
              type: "error",
              info: res?.msg,
            })
            setVLoading(false)
          }
        })
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (alertConfig?.isTrue) {
      setTimeout(() => {
        setIsAlert((prev: any) => {
          return { ...prev, isTrue: false }
        })
      }, 5000);
    }
  }, [alertConfig])

  return (
    <div className={Styles["mainContainer"]}>
      {rxProductList?.length > 0 && (
        <div className="outer_container">
          <div className={Styles["rx_prescription_wrapper"]}>
            <div className={Styles["rxHeading"]}>
              <div className={Styles["rx_prescription_main"]}>
                <div className={Styles["rxInfo"]}>
                  <p>i</p>
                </div>
                <div className={Styles["rxprescriptionInfo"]}>
                  {rxProductList?.length === 1
                    ? "This drug needs prescription verification"
                    : "These drugs need prescription verification"}
                </div>
              </div>
              <IconButton
                title="Submit prescription"
                className={`${Styles?.iconButton}`}
                onClick={() => {
                  setEncryptedID(RX_CART_FLAG, "Y");
                  navigate("/rx-page");
                }}

              >
                <i className="fa-solid fa-arrow-up-from-bracket"></i>
                <SubmitRX />
              </IconButton>
            </div>
            <div className={Styles["rxProducts2"]}>

              <div className={Styles["rxbody"]}>
                {rxProductList?.map((item: any, index: number) => (
                  <div
                    className={
                      index % 2 === 0
                        ? `${Styles?.prodBlue} ${Styles?.rx_product_list}`
                        : `${Styles?.prodwhite} ${Styles?.rx_product_list}`
                    }
                    key={index}
                  >
                    <Link
                      to={`/product-detail/${item?.slug}`}
                      style={{ textDecoration: "none" }}
                      className={Styles["productLink"]}
                      draggable="false"
                    >
                      <h2 className={Styles["rx_product_name"]}>{item?.srv_name + " " + item?.srv_packaging}</h2>
                    </Link>

                    <div className={Styles["rx_product_icon"]}>
                      {/* <IconButton
                        title="Save for later"
                        onClick={() => onAddSFLHandler(item?.id)}
                      >
                        <img
                          src={Saveforlater_icon}
                          alt="save for later"
                          draggable="false"
                        />
                      </IconButton> */}

                      <IconButton
                        title="Delete"
                        onClick={() => onDeleteHandler(item?.id, false)}
                      >
                        <img src={Delete} alt="delete" draggable="false" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={Styles["cartMenuMain"]}>
        <div className="outer_container">
          <div className={Styles["cartHeading"]}>
            <h2 className={Styles["cartHeadingTitle"]}>{Config?.lbl_cart}</h2>
            {/* {isRxTypeChecked && (
            <div className={`${Styles?.profileGrid_1}`}>
              <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-health");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={HealthInfo}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div>{Config?.myprofile_tab_4}</div>
                </div>
                {rxCheckLoading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_health_info === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div>
                        
                        <div>
                          <CountUp
                            end={profileData?.completeness_my_health_info}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div>
                      
                        <div>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-health-card");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={HealthCard}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div>{Config?.myprofile_tab_5}</div>
                </div>
                {rxCheckLoading === null ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_health_cards === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div>
                      
                        <div>
                          <CountUp
                            end={profileData?.completeness_my_health_cards}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div>
                        
                        <div>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-insurance");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={Insurance}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div>{Config?.myprofile_tab_6}</div>
                </div>
                {rxCheckLoading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_insurance_cards === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div>
                      
                        <div>
                          <CountUp
                            end={profileData?.completeness_my_insurance_cards}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div>
                        
                        <div>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            )} */}
            {/* <div className={Styles["cartHeadingRightMenu"]}>
              {changeCoverageLoading ? (
                <ShimmerThumbnail width={400} height={20} rounded />
              ) : primaryInsurance !== "" ? (
                productList?.length > 0 && (
                  <>
                    <div className={Styles["insuredfrom"]}>
                      <p>Insured from</p>
                      <h3>{primaryInsurance?.description}</h3>
                    
                    </div>

                    <div className={Styles["coverageDropdown"]}>
                      <p>Coverage</p>
                      <SelectDropdown
                        name="coverage"
                        options={coverageList}
                        size="small"
                        placeholder={primaryInsurance?.coverage_desc}
                        control={control}
                        loading={loadingCoverage}
                        onSelectChange={onCoverageChangeHandler}
                      />
                    </div>
                  </>
                )
              ) : (
                productList?.length > 0 && (
                  <Button
                    type={Config?.lbl_Btn_type_primary}
                    className={`${Styles?.addInsurance}`}
                    onClick={() => {
                      setIsInsuranceModalOpen(true);
                    }}
                  >
                    Add Primary insurance
                  </Button>
                )
              )}
            </div> */}
          </div>
          <div className={Styles["cartTabs"]}>
            {/* <Tabs
              active={activeTab}
              setActive={setActiveTab}
              ulStyle={{
                width: "100%",
                justifyContent: "start",
                paddingLeft: "25px",
              }}
              bottomBorderStyle={{
                width: "100%",
                height: "1px",
                opacity: 1,
                backgroundColor: "#E1E3E5",
                marginTop: "-12px",
              }}
              anchorClassName={Styles["tableTabs"]}
            > */}
            {tabLabels?.map((item, idx) => (
              <div key={idx}>
                {/* <TabPane name={item.label} key={item.label}> */}
                {isLoading ? (
                  <ShimmerTable row={5} col={5} />
                ) : (
                  getTable(item.value)
                )}
                {isLoading ? "" : getCardList(item.value)}
                {/* </TabPane> */}
              </div>
            ))}
            {/* </Tabs> */}
          </div>
          <div className={Styles["cartTabsMobileView"]}>
            {/* <Tabs
              active={activeTab}
              setActive={setActiveTab}
              ulStyle={{
                width: "100%",
                justifyContent: "start",
                paddingLeft: "25px",
              }}
              bottomBorderStyle={{
                width: "100%",
                height: "1px",
                opacity: 1,
                backgroundColor: "#E1E3E5",
                marginTop: "-12px",
              }}
              anchorClassName={Styles["tableTabs"]}
            > */}
            {tabLabels?.map((item, idx) => (
              <div key={idx}>
                {/* <TabPane name={item.label} key={item.label}> */}
                {isLoading ? (
                  <ShimmerTable row={5} col={5} />
                ) : (
                  getTableForMobile(item.value)
                )}
                {/* {isLoading ? "" : getCardList(item.value)} */}
                {/* </TabPane> */}
              </div>
            ))}
            {/* </Tabs> */}
          </div>
        </div>
      </div>

      <div className={Styles["voucherMenuMain"]}>
        <div className="outer_container">
          <div className={Styles["voucherMenu"]}>
            <div className={Styles["leftMenu"]}>
              {cartTotal?.cart_coupon_code ?
                <>
                  <div className={Styles['appliedVoucherZone']}>

                    <div className={Styles['voucherButton']}>
                      <p>{cartTotal?.cart_coupon_code}</p>
                      {/* <img src={Delete} 
                 
                  /> */}
                      {voucherLoading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa-solid fa-trash-can" onClick={handleRemoveVoucherCode}></i>}

                    </div>
                    <div
                      className={Styles['voucher_text_wrapper']}
                      dangerouslySetInnerHTML={{ __html: cartTotal?.cart_coupon_disclaimer_html }}
                    />
                  </div>
                </>
                :
                <div className={Styles['voucherZone']}>
                  <div className={Styles["leftMenuTilte"]}>
                    <h1 className={Styles["leftMenuHeading"]}>
                      {Config?.lbl_voucher}
                    </h1>
                    <p className={Styles["leftMenutext"]}>
                      {Config?.lbl_enter_voucher_code}
                    </p>
                  </div>
                  <div className={Styles["voucherInput"]}>
                    <Input
                      name="voucher_code"
                      id="voucher_code"
                      type="voucher_code"
                      placeholder="Voucher code"
                      size={""}
                      required
                      value={voucherDetails?.couponCode}
                      onChange={handleFillVoucherCode}
                    />
                  </div>
                  {productList.length > 0 && <Button
                    type={Config?.lbl_Btn_type_primary}
                    size={Config?.lbl_Btn_size_medium}
                    className={Styles["MenuButton"]}
                    onClick={handleButtonVoucherClick}
                    loading={voucherLoading}
                  >
                    {btnConfig?.cart_Button_1}
                  </Button>}
                </div>
              }



            </div>
            <div className={Styles["rightMenu"]}>
              <div className={Styles["rightMenuCart"]}>
                <div
                  className={`${Styles["rightMenuAlign"]} ${Styles["rightMenuAlignItems"]}`}
                >
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={`${Styles["menuHeading"]}`}>Item(s)</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>0</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${Number(productList?.length) > 0
                          ? productList?.length
                          : 0.0
                          } qty.`}</h1>
                      )}
                    </>
                  )}
                </div>
                <hr className={Styles["checkoutPriceDivider"]} />

                <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>{Config?.checkout_billing_heading_1}</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}00.00</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_subtotal) > 0
                          ? Number(cartTotal?.cart_subtotal).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>{Config?.checkkout_billing_title_4}</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}00.00</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_discount) > 0
                          ? Number(cartTotal?.cart_discount).toFixed(2)
                          : (0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
                {/* <div className={Styles["rightMenuAlign"]}>
                {isLoading ? (
                  <ShimmerText line={1} gap={10} />
                ) : 
                (
                  <>
                    <h1 className={Styles["menuHeading"]}>Discount</h1>
                    {isCartEmpty ? (
                      <h1 className={Styles["menuPrice"]}>$00.00</h1>
                    ) : (
                      <h1 className={Styles["menuPrice"]}>{`$${Number(cartTotal?.cart_discount) > 0
                        ? cartTotal?.cart_discount
                        : 0.0
                        }`}</h1>
                    )}
                  </>
                )
                }
              </div> */}
                {/* <div className={Styles["rightMenuAlign"]}>
                {isLoading ? (
                  <ShimmerText line={1} gap={10} />
                ) : (
                  <>
                    <h1 className={Styles["menuHeading"]}>Tax</h1>
                    {isCartEmpty ? (
                      <h1 className={Styles["menuPrice"]}>$00.00</h1>
                    ) : (
                      <h1 className={Styles["menuPrice"]}>{`$${Number(cartTotal?.cart_tax) > 0 ? cartTotal?.cart_tax : 0.0
                        }`}</h1>
                    )}
                  </>
                )}
              </div> */}

                {/* <div className={Styles["rightMenuAlign"]}>
                {isLoading ? (
                  <ShimmerText line={1} gap={10} />
                ) : (
                  <>
                    <h1 className={Styles["menuHeading"]}>Shipping</h1>
                    {isCartEmpty ? (
                      <h1 className={Styles["menuPrice"]}>$00.00</h1>
                    ) : (
                      <h1 className={Styles["menuPrice"]}>{`$${Number(cartTotal?.cart_shipping) > 0
                        ? cartTotal?.cart_shipping
                        : 0.0
                        }`}</h1>
                    )}
                  </>
                )}
              </div> */}
                <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>{Config?.checkkout_billing_title_5}</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}{Number(0).toFixed(2)}</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_tax / 2) > 0
                          ? Number(cartTotal?.cart_tax / 2).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>{Config?.checkkout_billing_title_6}</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}{Number(0).toFixed(2)}</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_tax / 2) > 0
                          ? Number(cartTotal?.cart_tax / 2).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>{Config?.lbl_delivery_charges}</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}{Number(0).toFixed(2)}</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.delivery_charges) > 0
                          ? Number(cartTotal?.delivery_charges).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
                {/* {cartTotal?.cart_coupon_code && <div className={Styles["rightMenuAlign"]}>
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["menuHeading"]}>Coupon Discount</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}00.00</h1>
                      ) : (
                        <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_tax / 2) > 0
                          ? cartTotal?.cart_tax / 2
                          : 0.0
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>} */}
                {/* {cartTotal?.cart_coupon_code &&
                <div className={Styles["rightMenuAlign"]}>
                {isLoading ? (
                  <ShimmerText line={1} gap={10} />
                ) : (
                  <>
                    <h1 className={Styles["menuHeading"]}>Coupon Code</h1>
                    <h1 className={Styles['menuHeading']}>{cartTotal?.cart_coupon_code}</h1>
                    {isCartEmpty ? (
                      <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}00.00</h1>
                    ) : (
                      <h1 className={Styles["menuPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_tax / 2) > 0
                          ? cartTotal?.cart_tax / 2
                          : 0.0
                        }`}</h1>
                    )}
                  </>
                )}
              </div>
                } */}
                {/* <div className={Styles["rightMenuAlign"]}>
                {isLoading ? (
                  <ShimmerText line={1} gap={10} />
                ) : (
                  <>
                    <h1 className={Styles["menuHeading"]}>Net total</h1>
                    {isCartEmpty ? (
                      <h1 className={Styles["menuPrice"]}>$00.00</h1>
                    ) : (
                      <h1 className={Styles["menuPrice"]}>{`$${Number(cartTotal?.cart_net_total) > 0
                        ? cartTotal?.cart_net_total
                        : 0.0
                        }`}</h1>
                    )}
                  </>
                )}
              </div> */}
                <hr className={Styles["checkoutPriceDivider"]} />
                <div
                  className={`${Styles["rightMenuAlign"]} ${Styles["rightMenuAlignTotal"]}`}
                >
                  {isLoading ? (
                    <ShimmerText line={1} gap={10} />
                  ) : (
                    <>
                      <h1 className={Styles["totalHeading"]}>Total</h1>
                      {isCartEmpty ? (
                        <h1 className={Styles["menuPrice"]}>{siteConfig?.site_currency}{Number(0).toFixed(2)}</h1>
                      ) : (
                        <h1 className={Styles["totalPrice"]}>{`${siteConfig?.site_currency}${Number(cartTotal?.cart_net_payable) > 0
                          ? Number(cartTotal?.cart_net_payable).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</h1>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* cartTotal?.cart_subtotal */}
              {rxProductList.length > 0 ? "" :
                context?.UserAuth?.usr_type === "customer" && productList.length > 0 ?
                  (
                    <Button
                      // to={cartTotal?.cart_subtotal === "0.00" ? setIsCartEmpty(true) :navigate("/purchase/checkout")}
                      type="secondary"
                      className={`${Styles["checkoutButton"]}`}
                      onClick={() => {
                        if (context?.UserAuth?.usr_type === "pro")
                          navigate("/purchase/checkout");
                        let checkRxProduct = productList?.find(
                          (item: any) => item?.item_type !== "OTC"
                        );


                        if (cartTotal !== "") {
                          if (cartTotal?.cart_subtotal === "0.00") {
                            setIsCartEmpty(true);
                            // check when there is rx products
                          } else if (checkRxProduct !== undefined) {
                            if (checkProfile()) {
                              if (context?.UserAuth?.usr_type !== "pro")
                                // setOpenHealthInfoCard(true);
                                navigate("/purchase/checkout");
                              gaEventTracker("Checkout clicks");
                              setOpenHealthInfoCard(false);
                            } else {
                              navigate("/purchase/checkout");
                              gaEventTracker("Checkout clicks");
                              setOpenHealthInfoCard(false);
                            }
                            // check when there is no rx products check for simple user profile if completed
                          } else if (
                            userProfileIncomplete?.includes("") ||
                            userProfileIncomplete?.includes(null) ||
                            userProfileIncomplete?.includes(" ")
                          ) {
                            setIsProfilePopup(true);
                          } else {
                            navigate("/purchase/checkout");
                            gaEventTracker("Checkout clicks");
                          }
                        } else {
                          setIsCartEmpty(true);
                          setOpenHealthInfoCard(false);
                        }

                        // else if (checkProfile()) {
                        //   setOpenHealthInfoCard(true);
                        // }
                      }}
                    // draggable="false"
                    >
                      {btnConfig?.cart_Button_3}
                    </Button>
                  )
                  :
                  ""}
              {context?.UserAuth?.usr_type === "pro" && productList.length > 0 ? (
                <Button
                  // to={cartTotal?.cart_subtotal === "0.00" ? setIsCartEmpty(true) :navigate("/purchase/checkout")}
                  type="secondary"
                  className={`${Styles["checkoutButton"]}`}
                  onClick={() => {
                    if (context?.UserAuth?.usr_type === "pro")
                      navigate("/purchase/checkout");
                    let checkRxProduct = productList?.find(
                      (item: any) => item?.item_type !== "OTC"
                    );


                    if (cartTotal !== "") {
                      if (cartTotal?.cart_subtotal === "0.00") {
                        setIsCartEmpty(true);
                        // check when there is rx products
                      } else if (checkRxProduct !== undefined) {
                        if (checkProfile()) {
                          if (context?.UserAuth?.usr_type !== "pro")
                            setOpenHealthInfoCard(true);
                        } else {
                          navigate("/purchase/checkout");
                          gaEventTracker("Checkout clicks");
                          setOpenHealthInfoCard(false);
                        }
                        // check when there is no rx products check for simple user profile if completed
                      } else if (
                        userProfileIncomplete?.includes("") ||
                        userProfileIncomplete?.includes(null) ||
                        userProfileIncomplete?.includes(" ")
                      ) {
                        setIsProfilePopup(true);
                      } else {
                        navigate("/purchase/checkout");
                        gaEventTracker("Checkout clicks");
                      }
                    } else {
                      setIsCartEmpty(true);
                      setOpenHealthInfoCard(false);
                    }

                    // else if (checkProfile()) {
                    //   setOpenHealthInfoCard(true);
                    // }
                  }}
                // draggable="false"
                >
                  {btnConfig?.cart_Button_3}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {isCartEmpty && <EmptyCartPopup setModalOpen={setIsCartEmpty} />}
      {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}
      {isInsuranceModalOpen && (
        <AddInsurance
          setIsInsuranceModalOpen={setIsInsuranceModalOpen}
          heading={"Add New Insurance"}
          modalType={"Add"}
          editHeading={"Edit insurance"}
          getInsurance={getInsurance}
          editInsuranceProfile={""}
          setIsDefault={setIsDefault}
          isDefault={isDefault}
        />
      )}
      {isConfirmPopup?.isTrue &&
        <DeleteConfirmation
          setModalOpen={setIsConfirmPopup}
          isConfirmPopup={isConfirmPopup}
          getProducts={getProducts}
          getCartTotal={getCartTotal}
          setProductList={setProductList}
          productList={productList} />

      }
      {openHealthInfoCard && (
        <HealthInfoCards
          setIsPasswordModalOpen={setOpenHealthInfoCard}
          screenType="Cart"
        />
      )}
      {alertConfig?.isTrue && <Alert type={alertConfig?.type} info={alertConfig?.info} />}
    </div>
  );
}

export default CartScreen;
