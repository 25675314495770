import { CallAPI } from "../suggest-us/callSuggest";
import { config } from "../config/processId";
import { JSONCheckerFunc, objDecrypt, objEncrypt, setEncryptedID } from "../utils/utils";
import { getDecryptedID } from "../utils/utils";
import { HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, USER_PROFILE } from "./Config";

const userProfileJson = getDecryptedID(USER_PROFILE);
// const userProfileJson = JSONCheckerFunc(userProfile);
const profileCheck = (suggestUsInit) => {
  if (suggestUsInit?.returnCode === true) {
    // Profile check for insurance card
    CallAPI(config?.get_customer_insurance_process_id, {
      p_insurance_ref_id: "",
      p_patient_id: userProfileJson?.patient_id,
      p_org_id: userProfileJson?.org_id,
    }).then((res) => {
      if (res?.returnCode) {
        let filterPrimaryCard = [];
        filterPrimaryCard = res?.returnData?.filter(
          (val) => val?.sequence === "1"
        );



        if (filterPrimaryCard?.length > 0) {
          if (
            filterPrimaryCard?.[0]?.insurance_front_id !== "" ||
            filterPrimaryCard?.[0]?.insurance_front_id !== null
          ) {
            setEncryptedID(
              INSURANCE_FRONT_ID,
              filterPrimaryCard?.[0]?.insurance_front_id
            );
          } else {
            setEncryptedID(INSURANCE_FRONT_ID, "");
          }

          if (filterPrimaryCard?.[0]?.insurance_back_id !== "") {
            setEncryptedID(
              INSURANCE_BACK_ID,
              filterPrimaryCard?.[0]?.insurance_back_id
            );
          } else {
            setEncryptedID(INSURANCE_BACK_ID, "");
          }
        }
      }
      // setIsLoading(false);
    });

    // Profile check for health card
    CallAPI(config?.get_health_card_process_id, {
      p_patient_id: userProfileJson?.patient_id,
    }).then((res) => {
      if (res?.returnCode === true) {
        setEncryptedID(
          HEALTH_CARD_FRONT_ID,
          res?.returnData[0]?.healthcard_front_id || ""
        );

        setEncryptedID(
          HEALTH_BACK_ID,
          res?.returnData[0]?.healthcard_back_id || ""
        );
      }
    });

    // Profile check for health information
    CallAPI(config?.get_health_info_process_id, {
      p_user_id: userProfileJson?.usr_id,
      p_customer_id: userProfileJson?.customer_id,
    }).then((res) => {
      if (res?.returnCode === true) {
        setEncryptedID(
          HEALTH_INFO,
          res?.returnData?.[0]?.counter_supplements || ""
        );
      }
    });
  }
};

export default profileCheck;
