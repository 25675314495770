import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import LocationMarker from "./LocationMarker";
import { siteConfig } from "../../../config/site";
// import { debounce } from "lodash-es";
let long = "";
let lat = "";
const GoogleMap = ({ data, height, center, width, first, setfirst }: any) => {
  const [zoomLevel, setZoomLevel] = useState(12);

  // const action = (val: any) => setZoomLevel(val);
  // const [first, setfirst] = useState({});
  // const debounceSetZoom = debounce(action, 1000);
  const API_KEY = siteConfig?.Google_map_key;

  const renderMarkers = (map: any, maps: any) => {
    setfirst && setfirst({ lat: center?.lat, lng: center?.lng });
  };

  function showPosition(position: any) {
    long = position?.coords?.longitude;
    lat = position?.coords?.latitude;
    setfirst && setfirst({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
    // setCenter({
    //   lat: position?.coords?.latitude,
    //   lng: position?.coords?.longitude,
    // });
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }, [navigator.geolocation]);

  return (
    <div
      className="shadow"
      style={{ height, position: "relative", width: "auto" }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY, libraries: ["visualization"] }}
        defaultCenter={center}
        center={first}
        defaultZoom={zoomLevel}
        // options={createMapOptions}
        layerTypes={["TransitLayer"]}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        onClick={(val: any) => {
          setfirst&&  setfirst(val);
        }}
        options={{ zoomControl: false, fullscreenControl: false }}
      >
        {first && <LocationMarker {...first} />}
      </GoogleMapReact>
    </div>
  );
};
GoogleMap.defaultProps = {
  // center: {
  //   lat: 49.25866,
  //   lng: -123.10145,
  // },
  zoomLevel: 12,
  // height: "100vh",
};
export default GoogleMap;
