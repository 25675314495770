import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { mylistPageActions } from "./slice";
const SelectAll = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.myListPageReducer?.selectAll);
  return (
    <label key={"selectAll"} className={`${styles?.mylist_label}`}>
      <input
        type="checkbox"
        checked={selected}
        onClick={(e) => {
          dispatch(
            mylistPageActions?.SET_SELECT_ALL({ value: e.target.checked })
          );
        }}
      />
      <span className={`${styles?.label_title}`}>Select All</span>
      <span className={`${styles?.custom_checkbox}`}></span>
    </label>
  );
};

export default SelectAll;
