import React, { useEffect, useState, useContext } from "react";
import { CloseIcon } from "../../../utils/appIcons";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../../config/button";
import Heading from "../../../components/UI/Heading";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import Checkbox from "../../../components/UI/Checkbox";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import { Controller, useForm } from "react-hook-form";
import { FormInput } from "../../../components/UI/FormInput";
import FileUpload from "../../../components/UI/FileUpload";
import { msg_config } from "../../../config/messages";
import { FormErrorMessage } from "../../../components/UI/FormErrorMessage";
import { DatePickerInput } from "rc-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { siteConfig } from "../../../config/site";
import { DeleteIcon } from "../../../utils/appIcons";
import Config from "../../../config/label";
import Label from "../../../components/UI/Label";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../utils/utils";
import Alert from "../../../components/UI/Alert";
import frontImgg from "../../../assets/cart/syrup.png";
import { USER_PROFILE } from "../../../config/Config";

export type ModalProps = {
  heading?: string;
  setIsInsuranceModalOpen?: any;
  modalType?: string;
  editHeading?: string;
  getInsurance?: any;
  editInsuranceProfile?: any;
  setIsDefault: any;
  isDefault: any;
};

export type RegistrationFormFields = {
  insurance: string;
  startDate: string;
  endDate: string;
  cardNumber: string;
  coverage: number;
  insuranceCardIDFront: number;
  insuranceCardIDBack: number;
};

const AddInsurance: React.FC<ModalProps> = (props) => {
  const { isDefault = "0", setIsDefault } = props;

  const [insuranceList, setInsuranceList] = useState<any>([]);
  const [coverageCount, setCoverageCount] = useState<any>([]);
  const [frontImage, setFrontImage] = useState<any>([]);
  const [backImage, setBackImage] = useState<any>([]);
  const [uploadedFrontDocumentId, setUploadedFrontDocumentId] =
    useState<any>(null);
  const [isPrimaryCheck, setIsPrimaryCheck] = useState<any>("0");
  const [uploadedBackDocumentId, setUploadedBackDocumentId] =
    useState<any>(null);
  const [insuranceForm, setInsuranceForm] = useState({
    insurance: "",
    startDate: "",
    endDate: "",
    cardNumber: "",
    coverage: "",
    insuranceCardIDFront: "",
    insuranceCardIDBack: "",
    sequence: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingInsurance, setLoadingInsurance] = useState(false);
  const [loadingCoverage, setLoadingCoverage] = useState(false);
  const [loadingfront, setLoadingFront] = useState(false);
  const [loadingback, setLoadingBack] = useState(false);

  const {
    setIsInsuranceModalOpen,
    heading,
    modalType,
    editHeading,
    getInsurance,
    editInsuranceProfile,
  } = props;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const _insurance = watch<any>("insurance");

  const [suggestUsInit] = useContext<any>(APIContext);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [alert, setAlert] = useState<any>({
    active: false,
    type: "success",
    msg: "",
  });

  let newArry = editInsuranceProfile[0];

  const handleChange = () => {
    switch (isDefault) {
      case "0":
        setIsDefault("1");
        break;
      default:
        setIsDefault("0");
        break;
    }
  };

  const handlePrimaryCardCheckChange = () => {
    switch (isPrimaryCheck) {
      case "0":
        setIsPrimaryCheck("1");
        break;
      default:
        setIsPrimaryCheck("0");
        break;
    }
  };

  useEffect(() => {
    resetField("coverage");
    if (_insurance) {
      onInsuranceSelectHandler(_insurance);
    } else {
      setCoverageCount([]);
    }
  }, [_insurance]);

  useEffect(() => {
    if (suggestUsInit?.returnCode) {
      setLoadingInsurance(true);
      CallAPI(config?.get_insurances_list_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setInsuranceList([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.id,
                label: item?.description,
              };
            }),
          ]);
          setLoadingInsurance(false);
        }
      });
    }
  }, [suggestUsInit]);

  const uploadFileDataAxios = (data: any) => {
    return axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  async function frontImgUploadDocument(filename: any) {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: frontImage[0]?.data?.name?.split(".")[0],
        p_doc_remarks: "",
        p_doctype_code: siteConfig?.insurance_front_p_doctype_code,
        p_trnt_code: siteConfig?.insurance_front_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: siteConfig?.insurance_front_p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }

  async function backImgUploadDocument(filename: any) {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: backImage[0]?.data?.name?.split(".")[0],
        p_doc_remarks: "",
        p_doctype_code: siteConfig?.insurance_back_p_doctype_code,
        p_trnt_code: siteConfig?.insurance_back_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: siteConfig?.insurance_back_p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }

  async function callUploadfrontDocuments(urlData: any) {
    var uploaDocumentID: number[] = await Promise.all(
      urlData.map(async (item: any): Promise<number> => {
        return await frontImgUploadDocument(item);
      })
    );

    if (Array?.isArray(uploaDocumentID) && uploaDocumentID?.length > 0) {
      setUploadedFrontDocumentId(
        uploaDocumentID?.map((item: any) => item?.returnData[0]?.document_id)
      );
    }
    setLoadingFront(false);
  }

  async function callUploadbackDocuments(urlData: any) {
    var uploaDocumentID: number[] = await Promise.all(
      urlData.map(async (item: any): Promise<number> => {
        return await backImgUploadDocument(item);
      })
    );

    if (Array?.isArray(uploaDocumentID) && uploaDocumentID?.length > 0) {
      setUploadedBackDocumentId(
        uploaDocumentID?.map((item: any) => item?.returnData[0]?.document_id)
      );
    }
    setLoadingBack(false);
  }

  useEffect(() => {
    if (frontImage.length === 1) {
      setLoadingFront(true);
      let urlData: any;
      Promise.all(
        frontImage?.map((items: any) => {
          let formData = new FormData();
          formData.append("uploadedFile", items?.data);
          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];
          setLoadingFront(false);
          callUploadfrontDocuments(urlData);
        })
        .catch((err) => {
          setLoadingFront(false);
        });
    }
  }, [frontImage]);

  useEffect(() => {
    if (backImage.length === 1) {
      setLoadingBack(true);
      let urlData: any;
      Promise.all(
        backImage?.map((items: any) => {
          let formData = new FormData();
          formData.append("uploadedFile", items?.data);
          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];
          callUploadbackDocuments(urlData);
        })
        .catch((err) => {
          setLoadingBack(false);
          console.error("ErrorBack", err);
        });
    }
  }, [backImage]);

  const editFrontCardHandler = () => {
    setInsuranceForm((prev) => {
      return { ...prev, insuranceCardIDFront: "" };
    });
    setFrontImage([]);
    setUploadedFrontDocumentId(null);
  };
  const editBackCardHandler = () => {
    setInsuranceForm((prev) => {
      return { ...prev, insuranceCardIDBack: "" };
    });
    setBackImage([]);
    setUploadedBackDocumentId(null);
  };

  const onInsuranceSelectHandler = (e: any) => {
    setInsuranceForm((prev) => {
      return { ...prev, insurance: e?.value };
    });
    resetField("coverage");
    setValue("coverage", 0);
    setCoverageCount([]);
    if (suggestUsInit?.returnCode) {
      setLoadingCoverage(true);
      CallAPI(config?.get_insurances_coverage_list_process_id, {
        p_insurance_id: e?.value,
      }).then((res) => {
        if (res?.returnCode) {
          setCoverageCount([
            ...res?.returnData?.map((item: any) => {
              return {
                value: item?.id,
                label: item?.description,
              };
            }),
          ]);
          setLoadingCoverage(false);
        }
      });
    }
  };

  useEffect(() => {
    if (modalType === "Edit") {
      setInsuranceForm((prev) => {
        return {
          ...prev,
          insurance: newArry?.description,
          startDate: newArry?.ptmpln_date_from,
          endDate: newArry?.ptmpln_date_to,
          cardNumber: newArry?.ptmpln_membership_number,
          coverage: newArry?.description,
          insuranceCardIDFront: newArry?.insurance_front_id,
          insuranceCardIDBack: newArry?.insurance_back_id,
          sequence: newArry?.sequence,
        };
      });
      // setChecked(newArry?.sequence === "1" ? true : false);
      setIsDefault(newArry?.sequence);
      setIsPrimaryCheck(newArry?.primary_card_holder === "Y" ? "1" : "0");
      setValue("startDate", `${newArry?.patadddet_pnt_last_name}`);
      setValue("endDate", `${newArry?.p_address}`);
      setValue("cardNumber", `${newArry?.ptmpln_membership_number}`);
      setFrontImage(newArry?.insurance_front);
      setBackImage(newArry?.insurance_back);
    }
  }, [modalType]);

  useEffect(() => {
    if (insuranceList?.length > 0 && modalType === "Edit") {
      insuranceList &&
        setValue(
          "insurance",
          insuranceList.find((i: any) => i.value === newArry?.insurance_id)
        );
    }
  }, [insuranceList]);

  useEffect(() => {
    if (coverageCount?.length > 0 && modalType === "Edit") {
      coverageCount &&
        setValue(
          "coverage",
          coverageCount.find((i: any) => i.value === newArry?.coverage_id)
        );
    }
  }, [coverageCount]);

  const onInsuranceSubmitHandler = (data: any) => {
    setAlert((prev: any) => {
      return {
        ...prev,
        active: false,
        type: "",
        msg: "",
      };
    });
    if (
      insuranceForm?.insuranceCardIDFront === "" &&
      uploadedFrontDocumentId === null
    ) {
      // setAlert(true)
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload front side image of insurance card!",
        };
      });
    } else if (
      uploadedFrontDocumentId === null &&
      insuranceForm?.insuranceCardIDFront === ""
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: false,
          type: "error",
          msg: "Please upload front side image of insurance card!",
        };
      });
    } else if (
      insuranceForm?.insuranceCardIDBack === "" &&
      uploadedBackDocumentId === null
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload back side image of insurance card!",
        };
      });
    } else if (
      uploadedBackDocumentId === null &&
      insuranceForm?.insuranceCardIDBack === ""
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload back side image of insurance card!",
        };
      });
    } else if (suggestUsInit?.returnCode) {
      setLoading(true);
      CallAPI(config?.save_insurance_process_id, {
        p_ptmpln_id: modalType === "Edit" ? newArry?.insurance_ref_id : "",
        p_patient_id: userProfileJson?.patient_id,
        p_insurance_id: data?.insurance?.value || "",
        p_ppln_id: data?.coverage?.value || "",
        p_org_id: userProfileJson?.org_id,
        p_ptmpln_sequence: isDefault,
        p_primary_card_holder: isPrimaryCheck,
        p_ptmpln_status: "Y",
        p_ptmpln_membership_number: data?.cardNumber || "",
        p_ptmpln_date_from:
          data?.startDate === "undefined"
            ? newArry?.ptmpln_date_from
            : data?.startDate,
        p_ptmpln_date_to:
          data?.endDate === "undefined"
            ? newArry?.ptmpln_date_to
            : data?.endDate,
        p_insurance_front_image_document_id:
          insuranceForm?.insuranceCardIDFront || uploadedFrontDocumentId?.[0],
        p_insurance_back_image_document_id:
          insuranceForm?.insuranceCardIDBack || uploadedBackDocumentId?.[0],
      }).then((res) => {
        if (res?.returnCode) {
          setLoading(false);
          setIsInsuranceModalOpen(false);
          getInsurance();
          setIsDefault("0");
          setIsPrimaryCheck("0");
        } else {
          setLoading(false);
          console.error(res?.error);
        }
      });
    }
  };

  const endDateHandler = (val: any) => {
    setInsuranceForm((prev: any) => {
      return { ...prev, endDate: val };
    });
  };

  const startDateHandler = (val: any) => {
    setInsuranceForm((prev: any) => {
      return { ...prev, startDate: val };
    });
  };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setIsInsuranceModalOpen(false);
            setIsDefault("0");
            setIsPrimaryCheck("0");
          }}
          draggable="false"
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {modalType === "Edit" ? editHeading : heading}
          </Heading>
        </div>

        <form onSubmit={handleSubmit(onInsuranceSubmitHandler)}>
          <div className={`${Styles?.form_control}`}>
            <div className={`${Styles?.form_inner_control}`}>
              {/* First Row */}
              {/* <div className={`${Styles?.form_dropdown_1}`}>
                <Label className={`${Styles?.form_label}`} for="insurance">
                  {Config?.insurance_label}
                </Label>
                <SelectDropdown
                  name="insurance"
                  options={insuranceList}
                  size="small"
                  placeholder={"Insurances"}
                  control={control}
                  errors={errors}
                  loading={loadingInsurance}
                  isClearable={true}
                  rules={{ required: msg_config?.insurance_name_required }}
                />
              </div> */}
              {/* Second Row */}
              {/* <div className={`${Styles?.form_Data_2}`}>
                <Label className={`${Styles?.form_label}`} for="startDate">
                  {Config?.insurance_start_date_label}
                </Label>
                <Controller
                  control={control}
                  name="startDate"
                  rules={{ required: msg_config?.insurance_startdate_required }}
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <DatePickerInput
                          className={`${Styles?.datePicker}`}
                          placeholder="Start Date"
                          displayFormat={"LL"}
                          value={insuranceForm?.startDate}
                          onChange={(e: any) => {
                            onChange(e);
                            startDateHandler(e);
                          }}
                          maxDate={new Date()}
                        />
                        <ErrorMessage
                          errors={{ [name]: { ...error } }}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          name={name as any}
                          render={({ message }) => {
                            return (
                              <FormErrorMessage>{message}</FormErrorMessage>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div> */}
              {/* <div className={`${Styles?.form_Data_2}`}>
                <Label className={`${Styles?.form_label}`} for="endDate">
                  {Config?.insurance_end_date_label}
                </Label>
                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: msg_config?.insurance_enddate_required }}
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <DatePickerInput
                          placeholder="End Date"
                          displayFormat={"LL"}
                          value={insuranceForm?.endDate}
                          onChange={(e: any) => {
                            onChange(e);
                            endDateHandler(e);
                          }}
                          className={`${Styles?.form_Data}`}
                          minDate={insuranceForm?.startDate}
                        />
                        <ErrorMessage
                          errors={{ [name]: { ...error } }}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          name={name as any}
                          render={({ message }) => {
                            return (
                              <FormErrorMessage>{message}</FormErrorMessage>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div> */}
            </div>
            {/* <div className={`${Styles?.form_inner_control_1}`}>
             
              <div className={`${Styles?.form_Data_1}`}>
                <Label className={`${Styles?.form_label}`} for="cardNumber">
                  {Config?.insurance_card_no_label}
                </Label>
                <FormInput<RegistrationFormFields>
                  name="cardNumber"
                  id="cardNumber"
                  type="text"
                  placeholder="Insurance Card Number"
                  size="small"
                  register={register}
                  errors={errors}
                  rules={{ required: msg_config?.insurance_number_required }}
                  maxLength={20}
                />
              </div>
              <div className={`${Styles?.form_Data_2}`}>
                <div>
                  <Label className={`${Styles?.form_label}`} for="coverage">
                    {Config?.insurance_coverage_label}
                  </Label>
                  <SelectDropdown
                    name="coverage"
                    options={coverageCount}
                    size="small"
                    placeholder={"Coverage Percentage"}
                    control={control}
                    errors={errors}
                    loading={loadingCoverage}
                    disabled={!_insurance}
                    isClearable={true}
                    onSelectChange={(e: any) =>
                      setInsuranceForm((prev) => {
                        return { ...prev, coverage: e?.value };
                      })
                    }
                    rules={{
                      required: msg_config?.insurance_coverage_required,
                    }}
                  />
                </div>
              </div>
            </div> */}
            {/* Fourth Row */}
            <div className={`${Styles?.form_inner_control_2}`}>
              <div className={`${Styles?.form_Data_3} ${Styles?.front_form}`}>
                {frontImage?.length === 0 ? (
                  <FileUpload
                    type="update"
                    name="insurance-card-front"
                    id="insurance-card-front"
                    setFileContents={setFrontImage}
                    fileContents={frontImage}
                  />
                ) : (
                  // <img
                  //   src={
                  //     frontImage.length === 1 ? frontImage[0]?.uri : frontImage
                  //   }
                  //   alt="front img"
                  //   draggable="false"
                  // />
                  <img src={frontImgg} />
                )}
                <div className={`${Styles?.form_Data_3_txt} `}>Front</div>
                {loadingfront && (
                  <div className={Styles?.loader}>
                    <div className={Styles?.loaderspin} />
                  </div>
                )}
                {!loadingfront && !(frontImage?.length === 0) && (
                  <div className={Styles?.editCard}>
                    {/* <img
                      src={DeleteIcon}
                      alt="update"
                      onClick={editFrontCardHandler}
                      draggable="false"
                    /> */}
                    <i onClick={editFrontCardHandler}
                      draggable="false" className="fa-solid fa-trash-can"></i>
                  </div>
                )}
              </div>
              <div className={`${Styles?.form_Data_3}`}>
                {backImage?.length === 0 ? (
                  <FileUpload
                    type="update"
                    name="insurance-card-back"
                    id="insurance-card-back"
                    setFileContents={setBackImage}
                    fileContents={backImage}
                  />
                ) : (
                  // <img
                  //   src={backImage.length === 1 ? backImage[0]?.uri : backImage}
                  //   alt="back img"
                  //   draggable="false"
                  // />
                  <img src={frontImgg} />
                )}
                <div className={`${Styles?.form_Data_3_txt}`}>Back</div>
                {loadingback && (
                  <div className={Styles?.loader}>
                    <div className={Styles?.loaderspin} />
                  </div>
                )}
                {!loadingback && !(backImage?.length === 0) && (
                  <div className={Styles?.editCard}>
                    {/* <img
                      src={DeleteIcon}
                      alt="delete"
                      onClick={editBackCardHandler}
                      draggable="false"
                    /> */}
                    <i onClick={editBackCardHandler}
                      draggable="false" className="fa-solid fa-trash-can"></i>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: "70px" }}>
              <Checkbox
                name="Set as primary insurance"
                value={isDefault === "1" ? true : false}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <Checkbox
              name="Are you the primary card holder?"
              value={isPrimaryCheck === "1" ? true : false}
              onChange={handlePrimaryCardCheckChange}
            />
          </div>
          <div className={Styles["button_style"]} style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              htmlType="submit"
              size="medium"
              style={{ margin: 0 }}
              loading={loading}
              disabled={loadingfront || loadingback}
            >
              {modalType === "Edit" ? "Update" : "Add Insurance"}
            </Button>
          </div>
        </form>
        {/* Dont add cancel button in the form */}
        {/* <Button
          type="ghost"
          size="small"
          onClick={() => {
            setIsInsuranceModalOpen(false);
            setIsDefault("0");
            setIsPrimaryCheck("0");
          }}
          style={{ margin: 0 }}
        >
          {btnConfig?.pharmacy_modal_cancel}
        </Button> */}
      </div>
      {alert?.active && <Alert type={alert?.type} info={alert?.msg} />}
    </div>
  );
};
export default AddInsurance;
