import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";
var percentDifference:Number = 0;
const MultiRangeSlider: any = React.forwardRef(
  (
    {
      min,
      max,
      onChange,
      resetPriceRange,
      setResetPriceRange,
      minValue,
      maxValue,
      setMinValue,
      setMaxValue,
      idx,
      setIsFilterEnable,
      onChangeCapture
    }: // minVal,
      // setMinVal,
      // maxVal,
      // setMaxVal,
      // setMinValue,
      // setMaxValue,
      any, // minVal,
    // setMinVal,
    // maxVal,
    // setMaxVal,

    ref
  ) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    //getting 7 percent of max value
    const maxValue2 = (max * 7) / 100;
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range: any = useRef(null);
    const timer = useRef();

    // Convert to percentage
    // const getPercent = useCallback(
    //   (value) => Math.round(((value - min) / (max - min)) * 100),
    //   [min, max]
    // );
    const handleClear = () => {
      setMaxVal(max);
      setMinVal(min);
      handleWidthChange(min, max);
    };

    React.useImperativeHandle(ref, () => ({ handleClear, minVal, maxVal }));

    const handleWidthChange = (mVal: any, mxVal: any) => {
      const maxPercent = Math.round(((mxVal - min) / (max - min)) * 100);
      const minPercent = Math.round(((mVal - min) / (max - min)) * 100);
      range.current.style.left = minPercent + "%";
      range.current.style.right = maxPercent + "%";
      range.current.style.width = `${maxPercent - minPercent}%`;
    };

    // Get min and max values when their state changes
    useEffect(() => {
      onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal, onChange]);

    useEffect(() => {
      if (minValue !== undefined && minValue !== min) {
        setMinVal(minValue);
      } else {
        setMinVal(min);
      }
      if (maxValue !== undefined && maxValue !== max) {
        setMaxVal(maxValue);
      } else {
        setMaxVal(max);
      }
      percentDifference = (max * 7) / 100;
    }, [minValue, maxValue, resetPriceRange]);

    const getMinMaxValue = (max: any, min: any, percentage: any) => {
      try {
        return max - min >= percentage;
      } catch (error) {
        console.error(error);
      }
    }


    const handleOnChangeMinValue = (event: any) => {
      try {
        const value = Math.min(Number(event.target.value), Number(maxVal) - Number(10));
        let isValid = getMinMaxValue(maxVal, value, percentDifference);
      if(isValid){
          handleWidthChange(value, maxVal);
          minValRef.current = value;
          setMinVal(value);
          if (timer) {
            clearTimeout(timer.current);
          }
          setIsFilterEnable && setIsFilterEnable(true)
          // @ts-ignore
          timer.current = setTimeout(() => {
            setMinValue(value);
          }, 500);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const handleOnChangeMaxValue = (event: any) => {
      const value = Math.max(
        Number(event.target.value),
        Number(minVal) + Number(10)
      );
      let isValid = getMinMaxValue(value, minVal, percentDifference);
      if(isValid){
        handleWidthChange(minVal, value);
        maxValRef.current = value;
        setMaxVal(value);

        if (timer) {
          clearTimeout(timer.current);
        }
        setIsFilterEnable && setIsFilterEnable(true)
        //@ts-ignore
        timer.current = setTimeout(() => {
          setMaxValue(value);
        }, 500);
      }
    }


    return (
      <div>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={handleOnChangeMinValue}
          className="thumbNew thumb--left"
          style={{ zIndex: minVal > max - 100 ? "5" : "5" }}
          onChangeCapture={onChangeCapture}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={handleOnChangeMaxValue}
          className="thumbNew thumb--right"
          onChangeCapture={onChangeCapture}
        />

        <div className="sliderNew">
          <div className="slider__track" />
          <div
            ref={range}
            style={{ width: "100%" }}
            className="slider__range"
          />
          <div className="slider__left-value">{minVal}</div>
          <div className="slider__right-value">{maxVal}</div>
        </div>
      </div>
    );
  }
);

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
