import {
  initializeSuggestus,
  setMenuId,
  setVisitId,
  setPatientId,
  setUserId,
  setRoleId,
  setOrgId,
  callSuggestusAPI,
} from "../Suggestus_client_lib/suggestusClient.js";




async function suggestUsInitialize() {
  try {
    var result = await initializeSuggestus();
    return result;
  } catch (e) {
    console.log("e-- **", e);
  }
}

export async function suggestUsAPICall(
  process_id,
  request_array,
  application_ver
) {
  try {
    var result = await callSuggestusAPI(
      process_id,
      request_array,
      application_ver
    );
    return result;
  } catch (e) {
    console.log("e-- **", e);
  }
}

export function orgID(id) {
  try {
    if(id!=undefined){
      setOrgId(id);
    }else{
      setOrgId('');
    }
  } catch (e) {
    console.log("e-- **", e);
  }
}

export function menuID(id) {
  try {
    if(id!=undefined){
      setMenuId(id);
    }else{
      setMenuId('');
    }
    
  } catch (e) {
    console.log("e-- **", e);
  }
}
export function visitID(id) {
  try {
    if(id!=undefined){
      setVisitId(id);
    }else{
      setVisitId('');
    }
  } catch (e) {
    console.log("e-- **", e);
  }
}
export function patientID(id) {
  try {
    if(id!=undefined){
      setPatientId(id);
    }else{
      setPatientId('');
    }
    
  } catch (e) {
    console.log("e-- **", e);
  }
}

export function userID(id) {
  try {
    if(id!=undefined){
      setUserId(id);
    }else{
      setUserId('');
    }
  } catch (e) {
    console.log("e-- **", e);
  }
}

export function roleID(id) {
  try {
    if(id!=undefined){
      setRoleId(id);
    }else{
      setRoleId('');
    }
   
  } catch (e) {
    console.log("e-- **", e);
  }
}

export default suggestUsInitialize;
