import React from "react";
import Styles from "./index.module.css";
import FilerobotImageEditor, { TABS } from "react-filerobot-image-editor";
import { color_config } from "../../config/colorConfig";
// import FilerobotImageEditor from "react-filerobot-image-editor";

export type ModalProps = {
  imgSrc?: any;
  handleClose?: any;
  handleSave?: any;
};

const ImageEditor: React.FC<ModalProps> = (props) => {
  const { imgSrc, handleClose, handleSave } = props;

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <FilerobotImageEditor
          source={imgSrc}
          // @ts-ignore
          theme={{
            palette: {
              "txt-primary": `${color_config?.common_bg_color}`,
              "accent-primary": `${color_config?.common_bg_color}`,
              "borders-primary": `${color_config?.common_bg_color}`,
            },
            typography: {
              fontFamily: '"Montserrat", sans-serif',
            },
          }}
          onBeforeSave={(imageFileInfo : any) => {
            return false;
          }}
          onSave={(editedImageObject : any, designState : any) => {
            handleSave(editedImageObject);
          }}
          onClose={() => {
            handleClose();
          }}
          tabsIds={[TABS.ADJUST]}
          defaultTabId={TABS.ADJUST}
          Crop={{
            ratio: "custom",
            ratioTitleKey: "custom",
            noPresets: true,
            autoResize: true,
          }}
          savingPixelRatio={6}
          previewPixelRatio={6}
        /> 
      </div>
    </div>
  );
};

export default ImageEditor;
