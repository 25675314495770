import React from "react";
import SlideDrawer from "./SlideDrawer/SlideDrawer.js";
import Backdrop from "./SlideDrawer/Backdrop.js";

const Drawer = ({
  drawerOpen,
  setDrawerOpen,
  details,
  headingName,
  name,
  userInfo,
  setUserInfo,
  userProfileInfo,
  setUserProfileInfo,
  itemsList,
  setItemsList,
  orderDetails,
  setOrderDetails,
  isNewLoading,
  setIsNewLoading,
  isOrderInfoLoading,
  setIsOrderInfoLoading,
  getOrderList,
  setDrawerOpenRx,
  setRxDetails
}) => {
  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop setDrawerOpen={setDrawerOpen} />;
  }
  return (
    <div>
      <SlideDrawer
        show={drawerOpen}
        details={details}
        name={name}
        setDrawerOpen={setDrawerOpen}
        headingName={headingName}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        userProfileInfo={userProfileInfo}
        setUserProfileInfo={setUserProfileInfo}
        itemsList={itemsList}
        setItemsList={setItemsList}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        isLoading={isNewLoading}
        setIsLoading={setIsNewLoading}
        isOrderInfoLoading={isOrderInfoLoading}
        setIsOrderInfoLoading={setIsOrderInfoLoading}
        getOrderList={getOrderList}
        setRxDetails = {setRxDetails && setRxDetails}
        setDrawerOpenRx = {setDrawerOpenRx && setDrawerOpenRx}
      />
      {backdrop}
    </div>
  );
};

export default Drawer;
