import { siteConfig } from "../../../config/site";
import Style from "./index.module.css";
const DocViewer = (props) => {
    const {openDocViewer, setOpenDocViewer } = props;
    const  docData = openDocViewer?.docData;
    const docPath = siteConfig?.site_url+docData?.doc_path;
    const doclocalpath = URL.createObjectURL(openDocViewer);
    const docType= docData?.doct_description;
    const docName= docData?.doc_name;

    return <div className={Style['doc_view_container']}>
        <div className={Style['header']}>
        <div className={Style['headerContent']}>
            <h3>{docType}</h3>
            <h4>{docName}</h4>
        </div>
        <div className={Style['closeViewer']} onClick={()=>{
            setOpenDocViewer((prev)=>{
                return {...prev, isOpen :false, docData : {}}
            })
        }}>
            <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        </div>
        <div className={Style['viewerBody']}>
            <img src={docPath }/>

        </div>
    </div>
}

export default DocViewer;