import React, { useEffect, useState } from "react";
import Styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../assets/png/depression_dummy.jpg"

export type productProps = {
  imgUrl?: any;
  imgAlt?: string;
  productUrl?: string;
  viewType?: string;
  brand?: string;
};

const BrandProductCard: React.FC<productProps> = (props) => {
  const { imgUrl, imgAlt, productUrl, viewType, brand } = props;
  const navigate = useNavigate();
  // const [imageUrl, setImageUrl] = useState("");



  // useEffect(()=>{
  //   setImageUrl(imgUrl);
  // },[props])

  return (
    <div
      className={`${Styles?.container} ${
        viewType === "LIST" && Styles?.listView
      }`}
    >
      <div
        className={`${Styles?.imageBox} ${
          viewType === "LIST" && Styles?.listView
        }`}
        onClick={() => productUrl && navigate(`${productUrl}`)}
      >
        <img src={imgUrl} alt={imgAlt} draggable="false" 
        onError={()=>{
          // setImageUrl(dummyImage);
        }}
        />
      </div>

      <div className={`${Styles?.brandWrapper}`}>
        <p className={`${Styles?.brand}`}>{brand}</p>
      </div>
    </div>
  );
};

export default BrandProductCard;
