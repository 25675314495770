import React, { useState } from "react";
import { color_config } from "../../../../../config/colorConfig";
import styles from "./index.module.css";

export type confirmProps = {
  style?: object;
  onConfirm?: any;
  className?: string;
  title?: string;
};

const PopConfirm: React.FC<confirmProps> = (props) => {
  const { children, onConfirm, className, style, title } = props;
  const [isConfirmPopup, setIsConfirmPopup] = useState(true);

  const myTimeout = setTimeout(() => setIsConfirmPopup(false), 5000);

  return (
    <div className={`${styles?.popup}  ${className}`} style={{ ...style }}>
      <span
        className={`${styles?.popuptext} ${isConfirmPopup && styles?.show}`}
        id="app-confirm-popup"
      >
        <div
          style={{
            display: "flex",
            alignContent: "end",
            justifyContent: "end",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-lg pointer"
            viewBox="0 0 16 16"
            style={{
              color: color_config?.black_color,
              fontSize: "25",
              fontWeight: 200,
              marginTop: "3px",
            }}
            onClick={() => setIsConfirmPopup(false)}
          >
            <path
              fill-rule="evenodd"
              d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
            <path
              fill-rule="evenodd"
              d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
            />
          </svg>
        </div>
        <p>{title}</p>
        <div className="flex items-center justify-center gap-4">
          {/* <button
            className={styles?.btn}
            type="button"
            onClick={() => setIsConfirmPopup(false)}
          >
            No
          </button> */}
          {/* <button
            className={`${styles?.btn} ${styles?.btn_primary}`}
            type="button"
            onClick={() => onConfirm(setIsConfirmPopup)}
          >
            Yes
          </button> */}
        </div>
      </span>
      <span
      //  onClick={() => setIsConfirmPopup(true)}
      >
        {children}
      </span>
    </div>
  );
};

export default PopConfirm;
