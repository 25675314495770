import Styles from "./index.module.css";
import Footer from "../../components/Footer";
import { msg_config } from "../../config/messages";
import Header from "../../components/Header";
import Config from "../../config/label";
import Banner from "../../assets/sf_banner_16.webp"
import cutomercentricityicon from "../../assets/icons/customer_centricity.svg"
import accessilbilityicon from "../../assets/icons/accessibility_convenience.svg"
import qualityicons from "../../assets/icons/quality_authenticity.svg"
import innovationicons from "../../assets/icons/innovation_improvement.svg"
import servicesimages from "../../assets/png/our_services.png"
import tickicon from "../../assets/icons/tick.svg"
import { triggerLocalStorage } from "../../utils/utils";



const Medex = () => {
  triggerLocalStorage();
    return (
      <div className={`${Styles?.pageContainer}`}>
        <Header/>
        <div className="outer_container">
        <div className={`${Styles?.section_aboutus_Container}`}>
          <div className={`${Styles?.section_aboutus_image}`}>
            <img className={`${Styles?.aboutus_image}`} src={Banner} />
          </div>
        </div>
      </div>
      <div className={`${Styles?.section_ourvision_Container}`}>
        <div className="outer_container">
          <div className={`${Styles?.section_ourvision_Title}`}>
            <h3 className={`${Styles?.section_ourvision_heading}`}>
              {Config?.lbl_about_us_medex_heading_1}
            </h3>
            <p className={`${Styles?.section_ourvision_content}`}>
              {Config?.about_us_medex_content_ourvision_values}
            </p>
          </div>

          <div className={`${Styles?.section_values}`}>
            <div className={`${Styles?.section_values_row}`}>
              <div className={`${Styles?.section_values_details}`}>
                <div className={`${Styles?.section_values_images}`}>
                  <img
                    className={`${Styles?.values_images}`}
                    src={cutomercentricityicon}
                  />
                </div>
                <div className={`${Styles?.section_values_content}`}>
                  <h4 className={`${Styles?.section_values_heading}`}>
                    {Config?.lbl_about_us_medex_values_heading1}
                  </h4>

                  <p className={`${Styles?.section_values_paragraph}`}>
                    {Config?.lbl_about_us_medex_values_content1}
                  </p>
                </div>
              </div>

              <div className={`${Styles?.section_values_details}`}>
                <div className={`${Styles?.section_values_images}`}>
                  <img
                    className={`${Styles?.values_images}`}
                    src={accessilbilityicon}
                  />
                </div>
                <div className={`${Styles?.section_values_content}`}>
                  <h4 className={`${Styles?.section_values_heading}`}>
                    {Config?.lbl_about_us_medex_values_heading2}
                  </h4>

                  <p className={`${Styles?.section_values_paragraph}`}>
                    {Config?.lbl_about_us_medex_values_content2}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${Styles?.section_values_row}`}>
              <div className={`${Styles?.section_values_details}`}>
                <div className={`${Styles?.section_values_images}`}>
                  <img
                    className={`${Styles?.values_images}`}
                    src={qualityicons}
                  />
                </div>
                <div className={`${Styles?.section_values_content}`}>
                  <h4 className={`${Styles?.section_values_heading}`}>
                    {Config?.lbl_about_us_medex_values_heading3}
                  </h4>

                  <p className={`${Styles?.section_values_paragraph}`}>
                    {Config?.lbl_about_us_medex_values_content3}
                  </p>
                </div>
              </div>

              <div className={`${Styles?.section_values_details}`}>
                <div className={`${Styles?.section_values_images}`}>
                  <img
                    className={`${Styles?.values_images}`}
                    src={innovationicons}
                  />
                </div>
                <div className={`${Styles?.section_values_content}`}>
                  <h4 className={`${Styles?.section_values_heading}`}>
                    {Config?.lbl_about_us_medex_values_heading4}
                  </h4>

                  <p className={`${Styles?.section_values_paragraph}`}>
                    {Config?.lbl_about_us_medex_values_content4}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${Styles?.section_ourservices_main_Container}`}>
        <div className="outer_container">
          <div className={`${Styles?.section_ourservices_Container}`}>
            <div className={`${Styles?.section_ourservices_images}`}>
              <img
                className={`${Styles?.ourservices_images}`}
                src={servicesimages}
              />
            </div>
            <div className={`${Styles?.section_ourservices_content}`}>
              <div className={`${Styles?.section_ourservices_title}`}>
                <h3 className={`${Styles?.section_ourservices_heading}`}>
                  {Config?.lbl_about_us_medex_heading_2}
                </h3>
                <p className={`${Styles?.section_ourservices_txt}`}>
                  {Config?.content_ourservices}
                </p>
              </div>
              <div className={`${Styles?.section_ourservices_features}`}>
                <div className={`${Styles?.section_ourservices_column}`}>
                  <div
                    className={`${Styles?.section_ourservices_features_container}`}
                  >
                    <div
                      className={`${Styles?.section_ourservices_features_image}`}
                    >
                      <img src={tickicon} />
                    </div>
                    <div
                      className={`${Styles?.section_ourservices_features_content}`}
                    >
                      <p
                        className={`${Styles?.section_ourservices_features_text}`}
                      >
                        {Config?.lbl_about_us_medex_services_feature1}
                      </p>
                    </div>
                  </div>

                  <div
                    className={`${Styles?.section_ourservices_features_container}`}
                  >
                    <div
                      className={`${Styles?.section_ourservices_features_image}`}
                    >
                      <img src={tickicon} />
                    </div>
                    <div
                      className={`${Styles?.section_ourservices_features_content}`}
                    >
                      <p
                        className={`${Styles?.section_ourservices_features_text}`}
                      >
                        {Config?.lbl_about_us_medex_services_feature2}
                      </p>
                    </div>
                  </div>
                </div>

                <div className={`${Styles?.section_ourservices_column}`}>
                  <div
                    className={`${Styles?.section_ourservices_features_container}`}
                  >
                    <div
                      className={`${Styles?.section_ourservices_features_image}`}
                    >
                      <img src={tickicon} />
                    </div>
                    <div
                      className={`${Styles?.section_ourservices_features_content}`}
                    >
                      <p
                        className={`${Styles?.section_ourservices_features_text}`}
                      >
                        {Config?.lbl_about_us_medex_services_feature3}
                      </p>
                    </div>
                  </div>

                  <div
                    className={`${Styles?.section_ourservices_features_container}`}
                  >
                    <div
                      className={`${Styles?.section_ourservices_features_image}`}
                    >
                      <img src={tickicon} />
                    </div>
                    <div
                      className={`${Styles?.section_ourservices_features_content}`}
                    >
                      <p
                        className={`${Styles?.section_ourservices_features_text}`}
                      >
                        {Config?.lbl_about_us_medex_services_feature4}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Medex;
