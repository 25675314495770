import { useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import AppLink from "../UI/AppLink";
import BrandProductCard from "../BrandProductCard";
import { ShimmerSimpleGallery, ShimmerThumbnail } from "react-shimmer-effects";
import { LOADING_ARRAY_CONFIG } from "../../config/Config";
import { objEncrypt } from "../../utils/utils";
import { UserAuthContext } from "../../store/UserAuthContext";
import { useLocation } from "react-router-dom";



const Categories = () => {
    const [categoryContext, setCategoryContext] = useState<any>({
        allCategories: [],
        searchedCategories: []
    });
    const [loading, setLoading] = useState(true);
    const [context, setContext] = useContext(UserAuthContext);
    const location = useLocation();

    //function to call all the categories
    const getCategories = () => {
        try {
            CallAPI(config?.category_list_process_id, {}).then(
                (res) => {
                    if (res?.returnCode) {
                        res?.returnData?.length > 0 ?
                            setCategoryContext((prev: any) => {
                                return { ...prev, allCategories: res?.returnData }
                            })
                            : setCategoryContext((prev: any) => {
                                return { ...prev, allCategories: [] }
                            });
                        // //getting alpha values of categories ======>
                        let cateAlphaArray = res?.returnData?.map((items: any) => {
                            // return items?.sg_description[0] || "";
                            return items?.sg_description[0] || "";
                        })
                        const uniqueArray = cateAlphaArray?.filter((value: any, index: any, self: any) => {
                            return self.indexOf(value) === index;
                        });
                        let i;
                        let abcdArray: string[] = [];
                        for (i = 65; i <= 90; i++) {
                            abcdArray.push(String.fromCharCode(i));
                        }
                        let updatedArray: any[]  = []
                        abcdArray?.map((abcdItems, idx)=>{
                            updatedArray.push( {
                                alpha_id: `${idx + 1}`,
                                alpha_char: `${abcdItems}`,
                                isSelected: false,
                                isAvailable : uniqueArray.includes(abcdItems)
                            })
                        })
                        const encryptedAlphas = objEncrypt(updatedArray);
                        setContext((prev:any)=>{
                            return {...prev, allCategories :encryptedAlphas }
                        })
                        // sessionStorage.setItem("allCategories", encryptedAlphas);
                        setLoading(false);
                    } else {
                        setCategoryContext((prev: any) => {
                            return { ...prev, allCategories: [] }
                        });
                        setLoading(false);
                    }
                }
            );
        } catch (error) {
            console.error(error);
        }
    }
    //useEffects ====>
    useEffect(() => {
        try {
            setContext((prev:any)=>{
                return {...prev, categorySearchObject : {}}
              })
        } catch (error) {
            console.error(error);
        }
    }, [])

    useEffect(()=>{
            getCategories();
    },[])


    useEffect(() => {
        try {
            if (context?.categorySearchObject) {
                let updateData: any[] = [];
                categoryContext?.allCategories?.map((items: any, idx: any) => {
                    if (context?.categorySearchObject?.alpha_char === items?.sg_description[0]) {
                        updateData.push(items);
                    }
                })
                setCategoryContext((prev: any) => {
                    return { ...prev, searchedCategories: updateData }
                });
            }else {
                setCategoryContext((prev: any) => {
                    return { ...prev, searchedCategories: [] }
                });
            }

        } catch (error) {
            console.error(error);
        }
    }, [context])




    return <div className={`${Styles?.catContainer} outer_container`} >
        {  loading ?

            LOADING_ARRAY_CONFIG?.map((items: any, idx: any) => {
                return <ShimmerThumbnail key={items + "##" + idx} height={210} width={253} rounded />
            })


            :  categoryContext?.searchedCategories.length === 0 && categoryContext?.allCategories.length > 0 ?
                categoryContext?.allCategories?.map((items: any, idx: any) => {
                    return <div className={Styles?.categoryWrapper} key={idx}>
                        <AppLink to={`/product-list/${items?.sg_id}`} type="">
                            <BrandProductCard
                                viewType={"Grid"}
                                // productUrl=""
                                imgUrl={items?.srv_cat_image}
                                imgAlt={items?.sg_img_name}
                                brand={items?.sg_description}
                            />
                        </AppLink>
                    </div>
                })


                :  categoryContext?.searchedCategories.length === 0 && <div className={Styles?.no_data_found}>
                    No data found!
                </div>}
                {categoryContext?.searchedCategories.length > 0 ? 
                
                categoryContext?.searchedCategories?.map((items: any, idx: any) => {
                    return <div className={Styles?.categoryWrapper} key={idx}>
                        <AppLink to={`/product-list/${items?.sg_id}`} type="">
                            <BrandProductCard
                                viewType={"Grid"}
                                // productUrl=""
                                imgUrl={items?.srv_cat_image}
                                imgAlt={items?.sg_img_name}
                                brand={items?.sg_description}
                            />
                        </AppLink>
                    </div>
                })
                : ""}
    </div>

}

export default Categories;

