import React, { useEffect, useState, useContext, useRef } from "react";
import { CloseIcon } from "../../../../utils/appIcons";
import Button from "../../../../components/UI/Button";
import styles from "./index.module.css";
import btnConfig from "../../../../config/button";
import Heading from "../../../../components/UI/Heading";
import APIContext from "../../../../store/api-context";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { config } from "../../../../config/processId.js";
import Config from "../../../../config/label.js";
import { ShimmerSimpleGallery, ShimmerBadge } from "react-shimmer-effects";
import PopConfirm from "../../../../components/UI/PopConfirm";
import Delete_icon from "../../../../assets/icons/Delete_icon.svg";
import Api from "../../../../config/axiosApi";
import RadioButton from "../../../../components/UI/RadioButton";
import noshow from "../../../../assets/svg/noshow.svg";
import Alert from "../../../../components/UI/Alert";
import FlexBox from "../../../../components/UI/FlexBox";
import SaveCardModal from "../SaveCardModal";
import AddCard from "../../../ManagePayments/AddCard";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { siteConfig } from "../../../../config/site";
import { Link } from "react-router-dom";
import Input from "../../../../components/UI/Input";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../../utils/utils";
import { color_config } from "../../../../config/colorConfig";
import { USER_PROFILE } from "../../../../config/Config";

const APPLICATION_ID = siteConfig?.Payments_APPLICATION_ID;
const LOCATION_ID = siteConfig?.Payments_LOCATION_ID;
// const Url = "https://phill-sgultra.speedum.tech/";
// const isSafari = "Safari";
const userProfileJson: any = getDecryptedID(USER_PROFILE);
// const userProfileJson = JSONCheckerFunc(userProfile);
const paymentRequestMock = {
  countryCode: "CA",
  currencyCode: userProfileJson?.curreny_sign,
  intent: "STORE",
  requestBillingContact: true,
  requestShippingContact: true,
  billingContact: {
    familyName: userProfileJson?.usr_name, // First name
    givenName: userProfileJson?.usr_name, //Last name
  },
};

// This function tokenizes a payment method.
// The ‘error’ thrown from this async function denotes a failed tokenization,
// which is due to buyer error (such as an expired card).
export async function tokenizePaymentMethod(paymentMethod: any) {
  const tokenResult = await paymentMethod.tokenize();
  // A list of token statuses can be found here:
  // https://developer.squareup.com/reference/sdks/web/payments/enums/TokenStatus
  if (tokenResult.status === "OK") {
    return tokenResult;
  }
  let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
}

export async function verifytokenizePaymentMethod(
  token: any,
  paymentMethod: any
) {
  const verificationResults = await paymentMethod.verifyBuyer(
    token,
    paymentRequestMock
  );

  if (verificationResults.token) {
    return verificationResults.token;
  }
  {
    let errorMessage = `Verify token failed-status: ${verificationResults}`;
    throw new Error(errorMessage);
  }
}

export type ModalProps = {
  setIsAddPaymentCardModalOpen?: any;
  handlePayment?: any;
  alert?: any;
  setIsPaymentAlert?: any;
  isButtonLoading?: any;
  setIsButtonLoading?: any;
  total?: any;
  cardItems?: any;
  setCardItems?: any;
  isSaveCardModal?: any;
  setIsSaveCardModal?: any;
  setIsBasicDetails?: any;
};

const AddPaymentsCard: React.FC<ModalProps> = (props) => {
  const {
    setIsAddPaymentCardModalOpen,
    handlePayment,
    alert,
    setIsPaymentAlert,
    isButtonLoading,
    setIsButtonLoading,
    total,
    cardItems,
    setCardItems,
    isSaveCardModal,
    setIsSaveCardModal,
    setIsBasicDetails,
  } = props;

  const [suggestUsInit] = useContext(APIContext);
  const [isAddCardLoading, setIsAddCardLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [squarePayments, setSquarePayments] = useState<any>(undefined);
  const [squareCardlist, setSquareCardlist] = useState([]);
  const [squareCard, setSquareCard] = useState<any>(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalEmpty, setIsModalEmpty] = useState(false);

  const [isFetchCard, setIsFetchCard] = useState(true);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [filteredCardList, setFilteredCardList] = useState([]);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false,
  });


  const gaEventTracker = useAnalyticsEventTracker("Square payment modal");

  const isCardFieldsValid = Object.values(validFields).every((v) => v);

  // Add Square script to the page
  useEffect(() => {
    const existingScript = document.getElementById("webPayment");

    if (existingScript) {
      setLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = siteConfig?.Square_script;
      script.id = siteConfig?.Square_script_id;
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, []);

  // Instantiate Square payments and store the object in state
  useEffect(() => {
    if (loaded && !squarePayments) {
      let windows: any = window;
      if (!windows?.Square) {
        console.error("Square.js failed to load properly");
        return;
      }
      setSquarePayments(windows.Square?.payments(APPLICATION_ID, LOCATION_ID));
    }
  }, [loaded, squarePayments]);

  const addPayment = async (
    noonce: any,
    tokenResult: any,
    verifytoken: any,
    type: any
  ) => {
    if (suggestUsInit?.returnCode === true) {
      if (type === "saveCard") {
        CallAPI(config?.add_payments, {
          p_cardholder_name: paymentRequestMock.billingContact.familyName,
          p_verification_token: verifytoken,
          p_card_nonce: noonce,
          environment: "TELE",
          p_gateway_type: "square_up",
        })
          .then((response) => {
            if (
              response.returnCode === true ||
              response.returnCode === "true"
            ) {
              gaEventTracker("New payment card added");
              setIsButtonLoading(false);
              setIsAddCardLoading(false);
              setFilteredCardList([]);
              fetchAddedCard();
              setIsAddCardModalOpen(false);
              setValidFields({
                cardNumber: false,
                cvv: false,
                expirationDate: false,
                postalCode: false,
              });
              if (squareCard) {
                squareCard.destroy();
                setSquareCard(undefined);
              }
            } else {
              setIsButtonLoading(false);
              setIsAddCardLoading(false);
              setIsAddCardModalOpen(false);
              setIsBasicDetails({
                isSelected: true,
                type: "error",
                msg: response?.error,
              });
            }
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  };

  //Check customer id exists or not
  const checkCustomer = async () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.check_customer, {
        environment: "TELE",
        p_gateway_type: "square_up",
      })
        .then((response) => {
          if (response.returnCode === true || response.returnCode === "true") {
            fetchAddedCard();
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const initializeSquareCard = async () => {
    // const card = await squarePayments.card();
    // setSquareCard(card);
    // attachCard(card);
    checkCustomer();
  };

  const destroyCard = async () => {
    const card = await squarePayments.card();
    card.clear();
    await squareCard.clear();

    const cardOptions = {
      postalCode: " ",

      // style: {
      //   input: {
      //     color: "red",
      //   },

      //   // You can use media queries with valid selectors:

      //   "@media screen and (max-width: 600px)": {
      //     input: {
      //       fontSize: "12px",
      //     },
      //   },
      // },
    };

    await squareCard.configure(cardOptions);
  };

  const fetchAddedCard = async () => {
    setIsFetchCard(true);

    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.fetch_added_cards, {
        p_cardholder_name: paymentRequestMock.billingContact.familyName,
        environment: "TELE",
        p_gateway_type: "square_up",
      })
        .then((response) => {
          if (response.returnCode === true || response.returnCode === "true") {
            setIsFetchCard(false);
            let result = response.returnData[0] ? response.returnData[0] : [];

            setSquareCardlist(result);
          } else {
            setIsFetchCard(false);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (squarePayments) {
      if (!squareCard) initializeSquareCard();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
    }
  }, [squarePayments]);

  useEffect(() => {
    if (isModalEmpty === true) {
      setTimeout(() => setIsModalEmpty(false), 3000);
    }
  }, [isModalEmpty]);

  // squareCardlist

  useEffect(() => {
    if (squareCardlist?.length > 0) {
      for (let i = 0; i <= 1; i++) {
        const element = squareCardlist[i];
        if (element !== undefined) {
          setFilteredCardList((prev) => [...prev, element]);
        }
      }
    }
  }, [squareCardlist]);

  // const cleanInput = (inp: any) => {
  //   if (inp) {
  //     return inp.replace(/(?!-)[^0-9.]/g, "").replace("-", "");
  //   }
  //   return "";
  // };

  return (
    <div className={styles["modalBackground"]}>
      <div className={styles["modalContainer"]}>
        <div
          className={styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setIsPaymentAlert(true);
            setIsAddPaymentCardModalOpen(false);
            if (squareCard) {
              squareCard.destroy();
              setSquareCard(undefined);
            }
            setCardItems({});
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={styles["title"]}>
          <Heading className={`${styles?.page_heading}`}>
            {Config?.heading_select_card}
          </Heading>
        </div>

        <div>
          <div style={{ marginBottom: 24 }}></div>
          <form id="payment-form" onSubmit={(e) => e.preventDefault()}>
            {isFetchCard ? (
              <>
                <br />
                <br />
                <br />
                <ShimmerSimpleGallery row={3} col={1} imageHeight={150} />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent:
                      squareCardlist?.length > 0 ? "space-between" : "end",
                    // right: 0,
                    // justifyItems: "self-end",
                    // width: "100%",
                  }}
                >
                  {squareCardlist?.length > 0 && (
                    <p className={`${styles?.addedCardLabel}`}>
                      Choose from already added cards
                    </p>
                  )}

                  <div className={`${styles?.btnWrapper}`}>
                    <Button
                      type={Config?.lbl_Btn_type_primary}
                      size="small"
                      className={`${styles?.addAddress}`}
                      onClick={() => {
                        setIsAddCardModalOpen(true);
                        // setModalType("Add");
                      }}
                    >
                      Add New Card
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    height:
                      isSeeMore && squareCardlist?.length > 3 ? "500px" : "",
                    overflowY:
                      isSeeMore && squareCardlist?.length > 3
                        ? "scroll"
                        : "unset",
                    overflowX: "hidden",
                  }}
                // className={`${styles?.addAddress}`}
                >
                  {squareCardlist?.length === 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        marginTop: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        paddingTop: "100px",
                      }}
                    >
                      <div className={styles["checkIconWrapper"]}>
                        <img
                          src={noshow}
                          alt="Empty Cart icon"
                          draggable="false"
                        />
                      </div>
                      <b> No card details found</b>
                    </div>
                  )}

                  <div className={`${styles?.addressGrid}`}>
                    {filteredCardList?.length > 0 && isSeeMore
                      ? squareCardlist?.map((item: any) => (
                        <div
                          className={
                            item?.default_address_flag === "Y"
                              ? `${styles?.defaultAddressRow}`
                              : `${styles?.addressRow}`
                          }
                        >
                          <div className={`${styles?.cardInfoWrapper}`}>
                            <RadioButton
                              type="radio1px"
                              name="paymentCard"
                              size="1.8rem"
                              id={item?.card_id}
                              value={item?.card_id}
                              checked={
                                cardItems === item?.card_id ? true : false
                              }
                              // className={`${styles?.inputStyle}`}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setCardItems(e.target.value);
                                  destroyCard();
                                }
                              }}
                            />
                            <div className={`${styles?.basicDetailsWrapper}`}>
                              <p className={`${styles?.cardHeading}`}>
                                <p className={`${styles?.cardBrand}`}>
                                  {item?.card_brand}
                                </p>
                                {`************${item?.card_last_4}`}
                              </p>
                              <p className={`${styles?.clientName}`}>
                                {item?.cardholder_name}{" "}
                              </p>
                              <p className={`${styles?.clientBasicDetails}`}>
                                Expires {item?.card_exp_month}
                                {"/"} {item?.card_exp_year}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                      : filteredCardList?.map((item: any) => (
                        <div
                          className={
                            item?.default_address_flag === "Y"
                              ? `${styles?.defaultAddressRow}`
                              : `${styles?.addressRow}`
                          }
                        >
                          <div className={`${styles?.cardInfoWrapper}`}>
                            <RadioButton
                              type="radio1px"
                              name="paymentCard"
                              size="1.8rem"
                              id={item?.card_id}
                              value={item?.card_id}
                              checked={
                                cardItems === item?.card_id ? true : false
                              }
                              // className={`${styles?.inputStyle}`}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setCardItems(e.target.value);
                                  destroyCard();
                                }
                              }}
                            />
                            <div className={`${styles?.basicDetailsWrapper}`}>
                              <p className={`${styles?.cardHeading}`}>
                                <p className={`${styles?.cardBrand}`}>
                                  {item?.card_brand}
                                </p>
                                {`************${item?.card_last_4}`}
                              </p>
                              <p className={`${styles?.clientName}`}>
                                {item?.cardholder_name}{" "}
                              </p>
                              <p className={`${styles?.clientBasicDetails}`}>
                                Expires {item?.card_exp_month}
                                {"/"} {item?.card_exp_year}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {squareCardlist?.length > 2 && isSeeMore === false ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        // setFilteredCardList(squareCardlist);
                        setIsSeeMore(true);
                      }}
                    >
                      <div className={`${styles?.linkButton}`}>
                        {btnConfig?.widget_link_button}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}

            <div
              className={styles["button_style"]}
              style={{ marginTop: "40px" }}
            >
              <FlexBox className={`${styles?.wrapper}`} style={{ border: "0" }}>
                <p className={`${styles?.title_3}`}>
                  {Config?.checkout_billing_title_3}
                </p>
                <span className={`${styles?.price_3}`}>{`$${total}`}</span>
              </FlexBox>
              <Button
                type={Config?.lbl_Btn_type_primary}
                htmlType={Config?.lbl_Btn_action_submit}
                size={Config?.lbl_Btn_size_medium}
                style={{ margin: 0 }}
                onClick={(e: any) => {
                  if (!isCardFieldsValid || isSubmitting) {
                    if (Object.keys(cardItems)?.length > 0) {
                      let gatewayType = "square_up";
                      let nonce = "cardId";
                      let noncetype = "cardSelected";

                      handlePayment(gatewayType, nonce, noncetype, "null");
                    } else {
                      // set alert
                      setIsModalEmpty(true);
                    }
                  } else {
                    setIsSaveCardModal(true);
                  }
                }}
                loading={isButtonLoading}
              >
                Pay Now
              </Button>
            </div>
          </form>
        </div>
      </div>
      {isModalEmpty ? <Alert type="error" info="Please select card!" /> : ""}

      {/* {squareCardlist?.length === 0 ? (
        <Alert type="error" info="Add card details!" />
      ) : (
        ""
      )} */}

      {isAddCardModalOpen && (
        <AddCard
          isAddCardModalOpen={isAddCardModalOpen}
          setIsAddCardModalOpen={setIsAddCardModalOpen}
          isCardFieldsValid={isCardFieldsValid}
          isButtonLoading={isAddCardLoading}
          setIsButtonLoading={setIsAddCardLoading}
          // isSubmitting={isSubmitting}
          squareCard={squareCard}
          // handlePaymentMethodSubmission={handlePaymentMethodSubmission}
          loaded={loaded}
          squarePayments={squarePayments}
          setSquarePayments={setSquarePayments}
          validFields={validFields}
          setValidFields={setValidFields}
          setSquareCard={setSquareCard}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          tokenizePaymentMethod={tokenizePaymentMethod}
          verifytokenizePaymentMethod={verifytokenizePaymentMethod}
          addPayment={addPayment}
          APPLICATION_ID={APPLICATION_ID}
          LOCATION_ID={LOCATION_ID}
          heading={"Add New Card"}
          modalType={modalType}
          editHeading={"Edit Address"}
        />
      )}
    </div>
  );
};
export default AddPaymentsCard;
function handleSelect(key: string, item: any): void {
  throw new Error("Function not implemented.");
}
