import StepperHorizontal from "../../../../../components/UI/StepperHorizontal";
import Config from "../../../../../config/label";

const StepsDetail = (props: any) => {
    const { activeStep } = props;
    const steps = [
      {
        label: `${Config?.forgotPassword_stepper_Heading__1_1}`,
        description: `${Config?.forgotPassword_stepper_detail__1_1}`,
      },
      {
        label: `${Config?.forgotPassword_stepper_Heading__2}`,
        description: `${Config?.forgotPassword_stepper_detail__2}`,
      },
  
      {
        label: `${Config?.forgotPassword_stepper_Heading__3}`,
        description: `${Config?.forgotPassword_stepper_detail__3}`,
      },
    ];
  
    return <StepperHorizontal steps={steps} activeStep={activeStep} />;
  };
  
  export default StepsDetail;