import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

export type linkProps = {
  to: string;
  className?: string;
  other?: any;
  type?: string;
};

/**
 * Please use type while using AppLink component, default type is "link"
 * Use 2 Types mentioned below
 * 1. router
 * 2. link
 
 */
const AppLink: React.FC<linkProps> = (props) => {
  const { type = "link", children, to, className, other } = props;
  if (type === "link") {
    return (
      <a
        href={to}
        target="_blank"
        {...other}
        rel="noreferrer"
        className={`${styles?.linkstyle} ${className}`}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={to}
      {...other}
      className={`${styles?.linkstyle} ${className}`}
      draggable="false"
    >
      {children}
    </Link>
  );
};

export default AppLink;
