import React, { useEffect } from "react";
import PatientDetailsForm from "./PatientDetailsForm";
import OtherPatientDetails from "./OtherPatientDetails";
import s from "./index.module.css";
import CheckValidation from "../../../../../components/CheckValidation";
import AddHealthCard from "./PatientDetailsForm/AddHealthCard";
import Config from "../../../../../config/label";

const PatientDetails = (props: any) => {
  const {
    register,
    control,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
    setHealthDetails,
    healthDetails,
    setUploadedDocumentIDFront,
    uploadedDocumentIDFront,
    uploadedDocumentIDBack,
    setUploadedDocumentIDBack,
    onSubmitHealthCardHandler,
    dob,
    setdob,
    dobErrorMsg,
    setDobErrorMsg,
  } = props;

  const patientWatch = watch("patient");

  useEffect(() => {
    setValue("patient", "self");
  }, []);

  return (
    <div className={`${s?.Container}`}>
      {/* Don't delete temporary hidden */}
      {/* <div className={s?.radioGroup}>
        <label htmlFor="selfRadio" className={`${s.radio}`}>
          <input
            type="radio"
            name="patient"
            id="selfRadio"
            value="self"
            className={`${s.radio_input}`}
            {...register("patient")}
          />
          <div className={`${s.radio_radio}`}></div>
          Self
        </label>
        <label htmlFor="otherRadio" className={`${s.radio}`}>
          <input
            type="radio"
            name="patient"
            id="otherRadio"
            className={`${s.radio_input}`}
            value="other"
            {...register("patient")}
          />
          <div className={`${s.radio_radio}`}></div>
          Other
        </label>
      </div> */}

      {/* <CheckValidation
        show={patientWatch === "self"}
        fallback={
          <OtherPatientDetails
            register={register}
            control={control}
            errors={errors}
          />
        }
      > */}
      <PatientDetailsForm
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
        clearErrors={clearErrors}
        setError={setError}
        setHealthDetails={setHealthDetails}
        healthDetails={healthDetails}
        setUploadedDocumentIDFront={setUploadedDocumentIDFront}
        uploadedDocumentIDFront={uploadedDocumentIDFront}
        setUploadedDocumentIDBack={setUploadedDocumentIDBack}
        uploadedDocumentIDBack={uploadedDocumentIDBack}
        onSubmitHealthCardHandler={onSubmitHealthCardHandler}
        dob={dob}
        setdob={setdob}
        dobErrorMsg={dobErrorMsg}
        setDobErrorMsg={setDobErrorMsg}
      />

      {/* </CheckValidation> */}
    </div>
  );
};

export default PatientDetails;
