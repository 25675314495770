import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/UI/Button";
import { FormInput } from "../../../../components/UI/FormInput";
import Heading from "../../../../components/UI/Heading";
import Label from "../../../../components/UI/Label";
import Config from "../../../../config/label";
import { msg_config } from "../../../../config/messages";
import { config } from "../../../../config/processId.js";
import APIContext from "../../../../store/api-context";
import { UserAuthContext } from "../../../../store/UserAuthContext.js";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { AppLogo } from "../../../../utils/appIcons";
import Styles from "./index.module.css";
import PopConfirm from "./PopConfirm";
import { USER_LOGGED_IN, LOGGED_IN_USER_ROLE , FIRST_TIME_LOGIN , IS_DOCS_PENDING,USER_PROFILE, SIGN_IN_WITH, PHONE_NO, USER_ID, CURRENT_SCREEN} from "../../../../config/Config";
import { getDecryptedID, setEncryptedID } from "../../../../utils/utils";
var md5 = require("md5");
export type RegistrationFormFields = {
  password: string;
  changePassword: string;
};

function CreatePassword(props: any) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passWordStrength, setPassWordStrength] = useState("");
  const [context, setContext] = useContext(UserAuthContext);
  const [alert, setAlert] = useState(false);
  const { setIsLoading, setIsAlert, setLoginScreens, isLoading } = props;
  const [value, setValue] = useState("");
  const [suggestUsInit] = useContext(APIContext);

  const phone_no = getDecryptedID(PHONE_NO);
  const user_id = getDecryptedID(USER_ID);
  const current_screen = getDecryptedID(CURRENT_SCREEN);

  const setSuggestUsIDs = (patientID: any, roleID: any, userID: any) => {
    patientID(patientID);
    roleID(roleID);
    userID(userID);
  };

  const pswRef = React.useRef<any>();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });


  const createPasswordHandler = () => {
    setIsAlert({ type: "", msg: "" });
    // !watch("password") &&
    //   setError("password", {
    //     type: "required",
    //     message: msg_config?.psw_required,
    //   });

    // alert &&
    //   setError("password", {
    //     type: "caseChecking",
    //     message:
    //       "User must include uppercase, lowercase, numerics & Special characters in their password",
    //   });

    // !watch("changePassword") &&
    //   setError("changePassword", {
    //     type: "required",
    //     message: msg_config?.crm_psw_required,
    //   });

    if (
      password === confirmPassword &&
      watch("password") &&
      watch("changePassword")
    ) {
      if (suggestUsInit?.returnCode === true) {
        setIsLoading(true);
        CallAPI(config?.create_password_process_id, {
          p_usr_id: user_id,
          p_user_password: md5(confirmPassword),
        }).then((res) => {
          setIsLoading(false);
          if (res?.returnCode === true) {
            setEncryptedID(FIRST_TIME_LOGIN, "Y");
            if(res?.returnData[0]?.usr_type === LOGGED_IN_USER_ROLE){
              setEncryptedID(IS_DOCS_PENDING, "N");
            }
            setLoginScreens(res?.returnData[0]?.current_screen);
            navigate("/");
            //Setting local storage of browser
            setEncryptedID(USER_PROFILE,res?.returnData[0]);
            setEncryptedID(SIGN_IN_WITH, "phone");
            // setSuggestUsIDs(
            //   res?.returnData[0]?.patient_id,
            //   res?.returnData[0]?.rol_id,
            //   res?.returnData[0]?.usr_id
            // );
            setEncryptedID(USER_LOGGED_IN, "Y");
            setContext((prev: any) => ({
              ...prev,
              UserAuth: res?.returnData[0],
            }));
          }
        });
      }
    } else {
      setIsAlert({
        type: "error",
        msg: msg_config?.loginpage_password_not_match,
        isTrue:true,
      });
    }
  };
 
  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onPasswordChange = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setPassword("");
      setPassWordStrength("");
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email" || fieldName === "password") {
      let newString = cleanInput(e.target.value);
      setPassword(newString);
      setPassWordStrength(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setPassword(newString);
      setPassWordStrength(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setPassword(e.target.value);
      setPassWordStrength(e.target.value);
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  const onVerifyPasswordChange = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setConfirmPassword("");
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email" || fieldName === "password") {
      let newString = cleanInput(e.target.value);
      setConfirmPassword(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setConfirmPassword(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setConfirmPassword(e.target.value);
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  return (
    <div className={Styles["loginContainer"]}>
      {/* <Link className={`${Styles?.appLogo}`} to="/" draggable="false">
        <img src={AppLogo} alt="app_logo" draggable="false" />
      </Link> */}
      <Heading className={`${Styles?.page_heading}`}>
        {Config?.heading_create_password}
      </Heading>
      <form onSubmit={handleSubmit(createPasswordHandler)}>
        <div className={Styles["inputField"]}>
          <div className={`${Styles?.create_pass_label_main}`}>
            <Label className={`${Styles?.form_label}`} for="password">
              {Config?.lbl_Login_Psw}
            </Label>
            <PopConfirm
              title="Password must be atleast 8 characters and contain uppercase, lowercase, number and special character"
              // onConfirm={(setIsConfirmPopup: any) =>
              //   onDeleteHandler(
              //     item?.p_patadd_id,
              //     setIsConfirmPopup
              //   )
              // }
            >
              <FormInput<RegistrationFormFields>
                name="password"
                id="password"
                type={Config?.lbl_Inp_type_pass}
                placeholder={Config?.inp_P_Holder_Login_Psw}
                size={Config?.lbl_Inp_size_medium}
                style={{ width: "360px" }}
                inputAutoFocus={true}
                register={register}
                value={password}
                rules={{
                  required: msg_config?.psw_required,
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/gm,
                    message:
                      "Password must be atleast 8 characters and contain uppercase, lowercase, number and special character",
                  },
                }}
                onInputChangeHandler={(e: any) => {
                  onPasswordChange(e, "password");
                }}
                errors={errors}
                maxLength={20}
              />
            </PopConfirm>
          </div>

          <div className={`${Styles?.create_pass_label_main}`}>
            <Label className={`${Styles?.form_label}`} for="changePassword">
              {Config?.lbl_Confirm_Psw}
            </Label>
            <FormInput<RegistrationFormFields>
              name="changePassword"
              id="changePassword"
              type={Config?.lbl_Inp_type_pass}
              placeholder={Config?.inp_P_Holder_Confirm_Psw}
              size={Config?.lbl_Inp_size_medium}
              style={{ width: "360px" }}
              register={register}
              value={confirmPassword}
              rules={{
                required: msg_config?.crm_psw_required,
                // pattern: {
                //   value:
                //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,15}$/gm,
                //   message:
                //     "User must include uppercase, lowercase, numerics, Special characters,minimum 8 characters in their password",
                // },
                // onChange: (e) => setConfirmPassword(e.target.value),
              }}
              onInputChangeHandler={(e: any) =>
                onVerifyPasswordChange(e, "password")
              }
              errors={errors}
              maxLength={20}
            />
          </div>
          <div className={`${Styles?.create_pass_label_main}`} >
            <Label
              className={`${Styles?.pswStrength_label}`}
              for="passwordStrength"
            >
              {Config?.lbl_Psw_strength}
            </Label>
            <PasswordStrengthBar
              password={passWordStrength}
              scoreWordStyle={{ height: "20px" }}
            />
          </div>
        </div>

        <div className={Styles["verifyButton"]}>
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            style={{ width: "360px" }}
            loading={isLoading}
          >
            {Config?.lbl_continue_Btn}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreatePassword;
