import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import userSkeleton from "../../../assets/svg/user_1.svg";
import Button from "../../../components/UI/Button";
import DatePicker from "../../../components/UI/DatePicker";
import FileUpload from "../../../components/UI/FileUpload";
import { FormInput } from "../../../components/UI/FormInput";
import Heading from "../../../components/UI/Heading";
import { PhoneFormInput } from "../../../components/UI/PhoneFormInput";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import btnConfig from "../../../config/button";
import Config from "../../../config/label";
import { msg_config } from "../../../config/messages";
import { config } from "../../../config/processId";
import { siteConfig } from "../../../config/site";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { CloseIcon } from "../../../utils/appIcons";
import { JSONCheckerFunc, emailPattern, getDecryptedID, objDecrypt, objEncrypt } from "../../../utils/utils";
import Styles from "./index.module.css";
import IconButton from "../../../components/UI/IconButton";
import Delete from "../../../assets/icons/Delete_icon.svg";
import { FormErrorMessage } from "../../../components/UI/FormErrorMessage";
import { UserAuthContext } from "../../../store/UserAuthContext";
import ImageEditor from "../../../components/ImageEditor";
import usersvg from "../../../assets/sf_svg/user.svg";
import { USER_PROFILE } from "../../../config/Config";
// import Delete from "../../../assets/icons/Delete_icon.svg";

export type ModalProps = {
  setIsEditModalOpen?: any;
  editHeading?: string;
  userInfoHandler?: any;
  userInfo?: any;
  profileForm?: any;
  setProfileForm?: any;
  setModalOpen?: any;
  register?: any;
  control?: any;
  errors?: any;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  dobErrorMsg?: any;
  setDobErrorMsg?: any;
  uploadedDocumentId?: any;
  setUploadedDocumentId?: any;
  genderList?: any;
  setGenderList?: any;
  getGenderList?: any;
  loading?: any;
  setLoading?: any;
};

export type RegistrationFormFields = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
};

const UpdateProfile: React.FC<ModalProps> = (props) => {
  const {
    setIsEditModalOpen,
    editHeading,
    userInfoHandler,
    userInfo,
    profileForm,
    setProfileForm,
    setModalOpen,
    register,
    control,
    errors,
    setValue,
    setError,
    clearErrors,
    dobErrorMsg,
    setDobErrorMsg,
    uploadedDocumentId,
    setUploadedDocumentId,
    genderList,
    setGenderList,
    getGenderList,
    loading,
    setLoading,
  } = props;

  const [fileContents, setFileContents] = useState<any>([]);
  const [context, setContext] = useContext(UserAuthContext);
  // const [loading, setLoading] = useState(false);
  const [suggestUsInit] = useContext(APIContext);

  const [checkFields, setCheckFields] = useState({
    isPhDisable: false,
    isEmailDisable: false,
  });

  const phoneInputRef = useRef<any>(null);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // let userProfileJson = JSONCheckerFunc(userProfile);
  const chkFieldDisable = userProfileJson?.usr_signup_type === "phone";
  const [editedImage, setEditedImage] = useState("");
  const [isImageEditor, setIsImageEditor] = useState(false);

  useEffect(() => {
    if (chkFieldDisable === true) {
      setCheckFields({ isPhDisable: true, isEmailDisable: false });
    } else {
      setCheckFields({ isPhDisable: false, isEmailDisable: true });
    }
  }, [chkFieldDisable]);

  const uploadFileDataAxios = (data: any) => {
    return axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const deleteDocument = () => {
    setEditedImage("");
    setFileContents([]);
    setUploadedDocumentId([]);
    setProfileForm((prev: any) => {
      return { ...prev, patient_photo: userSkeleton, document_id: "" };
    });
  };

  async function rxUploadDocument(filename: any) {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: filename,
        p_doc_remarks: "",
        p_doctype_code: siteConfig?.profile_image_p_doctype_code,
        p_trnt_code: siteConfig?.profile_image_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: siteConfig?.profile_image_p_docsubtype_code,
        p_doc_uploaded_filename: filename,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }

  async function callUploadDocuments(urlData: any) {
    Promise.resolve(rxUploadDocument(urlData)).then((res) => {
      if (res?.returnCode) {
        setUploadedDocumentId(res?.returnData[0]?.document_id);
        //generateRx(res?.returnData[0]?.document_id);
      }
    });
    setLoading(false);
  }

  const handleImageEdit = (imgData: any) => {
    const imageURL = imgData?.imageBase64;
    setEditedImage(imageURL);
    fetch(imageURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "image", { type: "image/png" });
        let formData = new FormData();
        formData.append("uploadedFile", file);

        Promise.resolve(uploadFileDataAxios(formData)).then((res) => {
          if (res?.data?.result === "true") {
            callUploadDocuments(res?.data?.url);
          }
        });
      });
    setIsImageEditor(false);
  };

  const handleClose = () => {
    setFileContents([]);
    setIsImageEditor(false);
    setLoading(false);
  };

  useEffect(() => {
    if (fileContents.length > 0) {
      setLoading(true);
      setIsImageEditor(true);
    }
  }, [fileContents]);

  useEffect(() => {
    //getting phone from the context
    const defaultPhoneNumber = context?.UserAuth?.usr_phone;
    setValue("firstName", profileForm?.firstName);
    setValue("lastName", profileForm?.lastName);
    setValue("phone", profileForm?.phone || defaultPhoneNumber);
    setValue("email", profileForm?.email);
    setContext((prev: any) => ({
      ...prev,
      phLength: profileForm?.phone,
    }));
    setValue(
      "gender",
      genderList.find((i: any) => i?.value === profileForm?.gender)
    );
  }, [profileForm, genderList]);

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: "" };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: newString };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setProfileForm((prev: any) => {
        return { ...prev, [fieldName]: e.target.value };
      });
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  useEffect(() => {
    if (profileForm?.dob !== "Invalid date") {
      setDobErrorMsg(false);
    }
  }, [profileForm]);

  return (
    <>
      <div className={`${Styles?.maincontainer}`}>
        <div className={`${Styles?.gridRightItem}`}>
          <div className={`${Styles?.headerContainer}`}>
            <div className={`${Styles?.headerContainerHeading}`}>
              <img
                src={usersvg}
                alt="Icon"
                width="30"
                height="30"
                style={{ color: "#32C5F4" }}
                draggable="false"
              />
              <h2 className={`${Styles?.heading}`}>{editHeading}</h2>
            </div>
          </div>

          {/* First row */}
          <div className={`${Styles?.Container}`}>
            <div className={`${Styles?.tempDiv}`}>
              <div className={`${Styles?.imageContainer}`}>
                <div className={`${Styles?.profileImage}`}>
                  <img
                    src={
                      editedImage ||
                      fileContents[0]?.uri ||
                      profileForm?.patient_photo ||
                      userSkeleton
                    }
                    alt="User Profile"
                    draggable="false"
                  />
                  <div className={`${Styles?.uploadControl}`}>
                    <FileUpload
                      type="icon"
                      name="profile_photo"
                      id="profile_photo"
                      setFileContents={setFileContents}
                      fileContents={fileContents}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
              {/* user_128.png is the default skeleton image sended by backend if no profile pic present */}
              {fileContents?.length > 0 ? (
                <div
                  className={`${Styles?.deleteIconWrapper}`}
                  onClick={() => deleteDocument()}
                >
                  {/* <img
                    src={Delete}
                    alt="delete"
                    style={{ width: "25px", height: "25px" }}
                    draggable="false"
                  /> */}
                  <i className="fa-solid fa-trash-can"  draggable="false"></i>
                </div>
              ) : (
                profileForm?.patient_photo?.includes("user_128.png") ===
                  false &&
                profileForm?.patient_photo === "" &&
                ""
              )}
              {(profileForm?.patient_photo?.includes("user_128.png") ===
                false &&
                profileForm?.patient_photo !== "" && (
                  <div
                    className={`${Styles?.deleteIconWrapper}`}
                    onClick={() => deleteDocument()}
                  >
                    {/* <img
                      src={Delete}
                      alt="delete"
                      style={{ width: "25px", height: "25px" }}
                      draggable="false"
                    /> */}
                    <i className="fa-solid fa-trash-can" draggable="false"></i>
                  </div>
                )) ||
                ""}

              {/* <div
              className={`${Styles?.deleteIconWrapper}`}
              onClick={() => deleteDocument()}
            >
              <img
                src={Delete}
                alt="delete"
                style={{ width: "25px", height: "25px" }}
                draggable="false"
              />
            </div> */}

              <div className={`${Styles?.form_control}`}>
                <div className={`${Styles?.form_inner_control}`}>
                  <FormInput<RegistrationFormFields>
                    name="firstName"
                    id="firstName"
                    type={Config?.lbl_Inp_type_text}
                    maxLength={25}
                    placeholder={Config?.myprofile_placeholder_1}
                    size={Config?.lbl_Inp_size_small}
                    register={register}
                    rules={{ required: msg_config?.first_name_required }}
                    errors={errors}
                    value={profileForm?.firstName}
                    onChange={(e: any) => {
                      onInputChangeHandler(e, "firstName");
                      // setProfileForm((prev: any) => {
                      //   return { ...prev, firstName: e.target.value };
                      // });
                    }}
                    // onKeyDown={(val: any) =>
                    //   onInputKeyPressHandler(val, "firstName")
                    // }
                  />
                </div>
                <div className={`${Styles?.form_inner_control}`}>
                  <FormInput<RegistrationFormFields>
                    name="lastName"
                    id="lastName"
                    maxLength={25}
                    type={Config?.lbl_Inp_type_text}
                    placeholder={Config?.myprofile_placeholder_2}
                    size={Config?.lbl_Inp_size_small}
                    register={register}
                    rules={{ required: msg_config?.last_name_required }}
                    errors={errors}
                    value={profileForm?.lastName}
                    onChange={(e: any) => {
                      onInputChangeHandler(e, "lastName");
                      // setProfileForm((prev: any) => {
                      //   return { ...prev, lastName: e.target.value };
                      // });
                    }}
                    // onKeyDown={(val: any) =>
                    //   onInputKeyPressHandler(val, "lastName")
                    // }
                  />
                </div>
              </div>
              {/* Second line */}
              <div className={`${Styles?.form_control}`}>
                <div className={`${Styles?.form_inner_control}`}>
                  <PhoneFormInput<RegistrationFormFields>
                    name="phone"
                    placeholder={Config?.myprofile_placeholder_3}
                    disabled={userProfileJson?.usr_signup_type === "phone"}
                    register={register}
                    rules={{ required: msg_config?.phone_required }}
                    errors={errors}
                    phoneInputRef={phoneInputRef}
                    control={control}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>

                <div className={`${Styles?.form_inner_control}`}>
                  <SelectDropdown
                    name="gender"
                    options={genderList}
                    size="small"
                    placeholder={Config?.myprofile_placeholder_6}
                    loading={loading}
                    control={control}
                    rules={{ required: msg_config?.gender_required }}
                    errors={errors}
                    onSelectChange={(e: any) => {
                      setProfileForm((prev: any) => ({
                        ...prev,
                        gender: e.value,
                      }));
                    }}
                  />
                </div>
              </div>

              {/* Third line */}
              <div style={{ marginTop: "0px", marginBottom: "20px" }}>
                <DatePicker
                  register={register}
                  placeholder={Config?.myprofile_placeholder_4}
                  onChange={(val: any) => {
                    if (val instanceof Date) {
                      const selectedDate = new Date(val); // Create a new Date object from the selected date
                      selectedDate.setDate(selectedDate.getDate());
                      setProfileForm((prev: any) => {
                        return { ...prev, dob: selectedDate.toISOString() }; // Convert DOB to ISO string format
                      });
                    }
                  }}
                  // selected={selectedDate}
                  value={
                    profileForm?.dob === "Invalid date" ? "" : profileForm?.dob
                  }
                  dateFormat={"LL"}
                  maxDate={new Date()}
                  // className="custom-datepicker"
                  className={Styles?.customDatepicker}
                />
                {dobErrorMsg ? (
                  <FormErrorMessage className={`${Styles?.error_msg}`}>
                    You must select your D.O.B
                  </FormErrorMessage>
                ) : (
                  ""
                )}
              </div>

              {/* Fourth line */}
              <div style={{ marginTop: "0px", marginBottom: "10px" }}>
                <FormInput<RegistrationFormFields>
                  name="email"
                  id="email"
                  maxLength={50}
                  type="text"
                  placeholder={Config?.myprofile_placeholder_5}
                  size={Config?.lbl_Inp_size_small}
                  disabled={userProfileJson?.usr_signup_type !== "phone"}
                  register={register}
                  rules={{
                    required: msg_config?.email_required,
                    pattern: emailPattern,
                  }}
                  errors={errors}
                  value={profileForm?.email}
                  onChange={(e: any) => {
                    onInputChangeHandler(e, "email");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isImageEditor && (
        <ImageEditor
          imgSrc={fileContents[0]?.uri}
          handleClose={handleClose}
          handleSave={handleImageEdit}
        />
      )}
    </>
  );
};
export default UpdateProfile;
