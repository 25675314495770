import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import HomeLayout from "../../layouts/HomeLayout";
import Styles from "./index.module.css";
import { UserAuthContext } from '../../store/UserAuthContext';
import Header from '../../components/Header';
import { triggerLocalStorage } from '../../utils/utils';

function TermsConditions(props:any) {
   const {isPopup, setOpenTermModalPopup} = props;
    const [context, setContext] = useContext(UserAuthContext);
    triggerLocalStorage();
  return (
    <div className={`${isPopup?.isTrue ? Styles?.pageContainerIfPopup  :Styles?.pageContainer }`}>
 {isPopup ? "" :  <Header/>} 
    <div className="outer_container">
      <div className={`${Styles?.detailsContainer}`}>
        <div className={`${Styles?.detailsContainer_1}`}>
          <div className={`${Styles?.det_title}`}>
          {isPopup?.isTrue ? 
            <>
            <p> Terms &amp; Conditions</p>
            <span
            onClick={()=>{
              setContext((prev:any)=>{
                return {...prev, termsModal  : {
                  isTrue: false,
                  type : "terms"
                }}
              })
            }}
            ><i className="fa-solid fa-xmark"></i></span>
            </>
            : <p>Terms &amp; Conditions</p>}
            
           </div>
          <div className={`${Styles?.det_header}`}>
            LAST UPDATED - NOVEMBER 10, 2021
          </div>
          <div className={`${Styles?.det_terms}`}>
            <div className={`${Styles?.det_terms_det}`}>
              Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
              before using the{" "}
              {/* todo */}
              <a href="www.safepharmacy.in">https://www.safepharmacy.in</a> website
              (the “Service”) operated by www.safepharmacy.in (“us”, “we”, or “our”).
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </div>
            <div className={`${Styles?.det_terms_title}`}>
              INTELLECTUAL PROPERTY
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              The Service and its original content, features and functionality
              are and will remain the exclusive property of www.safepharmacy.in and
              its licensors.
            </div>
            <div className={`${Styles?.det_terms_title}`}>
              LINKS TO OTHER WEB SITES
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by www.safepharmacy.in.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
            www.safepharmacy.in has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that www.safepharmacy.in shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with use of or reliance on any such content,
              goods or services available on or through any such web sites or
              services.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </div>
            <div className={`${Styles?.det_terms_title}`}>TERMINATION</div>
            <div className={`${Styles?.det_terms_det}`}>
              We may terminate or suspend access to our Service immediately,
              without prior notice or liability, for any reason whatsoever,
              including without limitation if you breach the Terms.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </div>
            <div className={`${Styles?.det_terms_title}`}>DISCLAIMER</div>
            <div className={`${Styles?.det_terms_det}`}>
              Your use of the Service is at your sole risk. The Service is
              provided on an “AS IS” and “AS AVAILABLE” basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement or course of performance.
            </div>
            <div className={`${Styles?.det_terms_title}`}>GOVERNING LAW</div>
            <div className={`${Styles?.det_terms_det}`}>
              These Terms shall be governed and construed in accordance with the
              laws of Canada without regard to its conflict of law provisions.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </div>
            <div className={`${Styles?.det_terms_title}`}>CHANGES</div>
            <div className={`${Styles?.det_terms_det}`}>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </div>
            <div className={`${Styles?.det_terms_det}`}>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </div>
            <div className={`${Styles?.det_terms_title}`}>CONTACT US</div>
            <div className={`${Styles?.det_terms_det}`}>
              If you have any questions about these Terms, please{" "}
              <Link
                to="/contact"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setContext((prev:any)=>{
                    return {...prev, termsModal  : {
                      isTrue: false,
                      type : "terms"
                    }}
                  })
                }}
                draggable="false"
              >
                contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
      {isPopup ? "" : <Footer/> }
    </div>
  );
}

export default TermsConditions;
