import { useContext, useEffect, useState } from "react";
import "./SlideDrawer.css";
import APIContext from "../../../store/api-context";
import Config from "../../../config/label";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { config } from "../../../config/processId";
import Styles from "./index.module.css";
import Label from "../../../components/UI/Label";
import Group3 from "../../../assets/svg/Group3.svg";
import trackingBlue from "../../../assets/png/trackingBlue.png";
import trackingWhite from "../../../assets/png/trackingWhite.png";
import ProfileImage from "../../../assets/svg/user_1.svg";
import StepsDetail from "./StepsDetail";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import Alert from "../../../components/UI/Alert";
import { Link, useNavigate } from "react-router-dom";
import { JSONCheckerFunc, getDecryptedID, getID, getProductImage, objDecrypt, setEncryptedID } from "../../../utils/utils";
import Button from "../../../components/UI/Button";
import IconButton from "../../../components/UI/IconButton";
import addCart from "../../../assets/svg/addCart.svg";
import moment from "moment";
import AddtocartPopup from "../../../components/ProductDetails/AddtocartPopup";
import OrderCancelPopup from "../../../components/OrderCancelPopup";
import { color_config } from "../../../config/colorConfig";
// import trackingSVG from "../../../assets/svg/trackingSVG.svg";

import {
  ShimmerTable,
  ShimmerThumbnail,
  ShimmerText,
} from "react-shimmer-effects";
import { siteConfig } from "../../../config/site";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { CART_COUNT, PRODUCT_CARD_IMAGES, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";

const SlideDrawer = ({
  show,
  details,
  setDrawerOpen,
  headingName,
  name,
  userInfo,
  setUserInfo,
  userProfileInfo,
  setUserProfileInfo,
  itemsList,
  setItemsList,
  orderDetails,
  setOrderDetails,
  isLoading,
  setIsLoading,
  isOrderInfoLoading,
  setIsOrderInfoLoading,
  getOrderList,
  setRxDetails,
  setDrawerOpenRx,
}) => {
  const localStorageCopy = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };
  const user_id = decryptData(getID(SG_USER_ID));
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [suggestUsInit] = useContext(APIContext);
  const [activeStep, setActiveStep] = useState(0);
  const [context, setContext] = useContext(UserAuthContext);
  // const [orderDetails, setOrderDetails] = useState({});
  // const [itemsList, setItemsList] = useState([]);
  // const [userProfileInfo, setUserProfileInfo] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [isOrderInfoLoading, setIsOrderInfoLoading] = useState(true);
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [isImageError, setImageError] = useState("");

  const [orderCancelPopUpOpene, setOrderCancelPopUpOpen] = useState(false);
  const [productdetails, setProductDetails] = useState("");
  const [addToCartLoading, setAddToCartLoading] = useState("");
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });

  const gaEventTracker = useAnalyticsEventTracker("Rx request detail modal");

  let drawerClassz = "side-drawerz";
  if (show) {
    drawerClassz = "side-drawerz open";
  }

  const userInfohandler = () => {
    if (show === true) {
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.my_profile_process_id, {
          p_user_id: user_id,
        }).then((res) => {
          if (res?.returnCode === true) {
            setUserProfileInfo([
              {
                icon: <i className="fa-solid fa-user"></i>,
                label: Config?.lbl_First_N,
                value:
                  res?.returnData[0]?.first_name === ""
                    ? "NA"
                    : res?.returnData[0]?.first_name +
                    " " +
                    res?.returnData[0]?.last_name,
              },
              // {
              //   label: Config?.lbl_Last_N,
              //   value:
              //     res?.returnData[0]?.last_name === ""
              //       ? "NA"
              //       : res?.returnData[0]?.last_name,
              // },
              {
                icon: <i className="fa-solid fa-phone"></i>,
                label: Config?.lbl_Phone,
                value:
                  res?.returnData[0]?.usr_phone === ""
                    ? "NA"
                    : res?.returnData[0]?.usr_phone,
              },
              {
                icon: <i className="fa-solid fa-envelope"></i>,
                label: Config?.lbl_Email,
                value:
                  res?.returnData[0]?.usr_email === ""
                    ? "NA"
                    : res?.returnData[0]?.usr_email,
              },
              {
                icon: <i className="fa-solid fa-calendar-days"></i>,
                label: Config?.lbl_BirthDay,
                value:
                  res?.returnData[0]?.usr_dob === null
                    ? "NA"
                    : res?.returnData[0]?.usr_dob,
              },
            ]);
            setProfilePic(res?.returnData[0]?.patient_img_path);
            setIsLoading(false);
          } else console.error("error", res?.error);
        });

        CallAPI(config?.fetch_order_details, {
          p_org_id: localStorageCopy?.org_id,
          p_patient_id: localStorageCopy?.patient_id,
          p_user_id: localStorageCopy?.usr_id,
          p_order_id: details?.ord_id,
        }).then((res) => {
          if (res?.returnCode) {
            setOrderDetails(res?.returnData[0]);
            setUserInfo([
              {
                icon: <i className="fa-solid fa-object-group"></i>,
                label: "Order Number",
                value: res?.returnData[0]?.order_number,
              },
              {
                icon: <i className="fa-regular fa-calendar-days"></i>,
                label: "Placed on",
                value: res?.returnData[0]?.order_date,
              },
              // {
              //   icon :<i className="fa-solid fa-list-check"></i>,
              //   label: "Order status",
              //   value: res?.returnData[0]?.order_status,
              // },
              {
                icon: <i className="fa-solid fa-credit-card"></i>,
                label: "Payment method",
                value: res?.returnData[0]?.payment_method,
              },
            ]);

            let items = JSONCheckerFunc(res?.returnData[0]?.order_items);

            setItemsList(items);
            setIsOrderInfoLoading(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    userInfohandler();
  }, [suggestUsInit, show]);

  useEffect(() => {
    switch (orderDetails?.order_status_code) {
      case "pre_bill_created":
        setActiveStep(1);
        break;
      // case "clinical_review":
      //   setActiveStep(2);
      //   break;
      case "invoiced":
        setActiveStep(2);
        break;
      case "packed":
        setActiveStep(3);
        break;
      case "picked_up":
        setActiveStep(4);
        break;
      case "in_transit":
        setActiveStep(5);
        break;
      case "delivered":
        setActiveStep(6);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [orderDetails]);

  const getProductstatus = (type) => {
    if (type === "rx_approved") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["greenCircle"]} />
          <p>Approved</p>
          <p>{Config?.Prescription_text}</p>
        </div>
      );
    } else if (type === "rx_pending") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["orangeCircle"]} />
          <p>{Config?.Prescription_text}</p>
          <p>Pending</p>
        </div>
      );
    } else {
      return (
        <div className={Styles["drugStatus"]}>
          <p>OTC</p>
          <p>products</p>
        </div>
      );
    }
  };

  const getPrice = (price, actualPrice) => {
    const price_label = `${siteConfig?.site_currency}${price}`;
    const actual_price_label = `${siteConfig?.site_currency}${actualPrice}`;
    if (Number(price) === Number(actualPrice)) {
      return (
        <div className={Styles["priceWrapper"]}>
          <h2 className={Styles["itemPrice"]}>{price_label}</h2>
        </div>
      );
    } else {
      return (
        <div className={Styles["priceWrapper"]}>
          <del className={Styles["delitemPrice"]}>{actual_price_label}</del>
          <h2 className={Styles["itemPrice"]}>{price_label}</h2>
        </div>
      );
    }
  };

  const addCartHandler = (product) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    setIsCartPopUPOpen(false);
    setAddToCartLoading(product?.srv_id);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res) => {
        if (res?.returnCode) {
          gaEventTracker(
            Config?.lbl_land_page_etrck_cart,
            res?.returnData[0].cart_item_count
          );
          setEncryptedID(
            CART_COUNT,
            res?.returnData[0].cart_item_count
          );
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          // userInfohandler();
          setAddToCartLoading("");
          setIsAlert({
            istrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
          setAddToCartLoading("");
        }
      });
    }
  };

  return (
    <div className={drawerClassz}>
      <div className={`${Styles?.drawerInner}`}>
        <div className={`${Styles?.headerContainer}`}>
          {/* <img
            src={Group3}
            alt="orders"
            className={`${Styles?.headerImgStyle}`}
            draggable="false"
          /> */}
          <i class="fa-solid fa-cart-plus"></i>
          <p className={`${Styles?.header}`}>{headingName}</p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className={`bi bi-x-lg pointer ${Styles?.closeIcon}`}
          viewBox="0 0 16 16"
          onClick={() => setDrawerOpen(false)}
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
      </div>

      {/* Drawer body */}

      <div className={`${Styles?.drawerBody}`}>
        <div className={`${Styles?.drawerbody_wrapper}`}>
          <div className={`${Styles?.drawer_progressbar_wrapper}`}>
            {/* Order stepper */}
            {/* Excluding stepss for cancelled orders */}
            {activeStep > 0 ? (
              <div className={`${Styles?.drawer_progressbar}`}>
                <StepsDetail activeStep={activeStep} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={`${Styles?.drawer_user_info_wrapper}`}>
            {/* Profile info card */}
            <div className={`${Styles?.user_wrapper}`}>
              {isLoading ? (
                <ShimmerTable row={1} col={5} />
              ) : (
                <div className={`${Styles?.userInfo}`}>
                  <div className={`${Styles?.user_profile_data}`}>
                    <div className={Styles["checkImgWrapper"]}>
                      <img
                        src={profilePic || ProfileImage}
                        alt="ProfileImg"
                        draggable="false"
                      />
                    </div>
                    <div className={`${Styles?.label_wrapper}`}>
                      {userProfileInfo?.length > 0 &&
                        userProfileInfo?.map((items) => {
                          return <div>
                            {/* <Label for="firstName" className={`${Styles?.infoLabel}`}>
                            {items?.label}
                          </Label> */}
                            {items?.icon}
                            <p className={`${Styles?.infoValue}`}>
                              {items?.label === "Birth Date"
                                ? moment(items?.value)?.format("MMM D, YYYY")
                                : items?.value}
                            </p>
                          </div>
                        })}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${Styles?.userInfo} ${Styles?.order_details_main}`}
              >
                {userInfo?.length > 0 &&
                  userInfo?.map((items) => (

                    <div>
                      {/* <Label for="firstName" className={`${Styles?.infoLabel}`}>
                          <p>{items?.label}</p>
                        </Label> */}
                      {items?.icon}
                      <p className={`${Styles?.infoValue}`}>{items?.value}</p>
                    </div>
                  ))}

                <div>
                  {/* <Label for="firstName" className={`${Styles?.infoLabel}`}>
                      <p>{Config?.tracking_code_label}</p>
                    </Label> */}
                  {/* <p className={`${Styles?.infoValue}`}>
                    {orderDetails?.tracking_code
                      ? orderDetails?.tracking_code
                      : "N/A"}
                  </p> */}
                </div>
                <div>
                  {/* <Label for={Config?.rx_number} className={`${Styles?.infoLabel}`}>
                      <p>{Config?.rx_number}</p>
                    </Label> */}
                  <p
                    className={`${Styles?.infoValue} ${orderDetails?.rx_number ? Styles?.orderHyperLink : ""
                      }`}
                    onClick={() => {
                      if (orderDetails?.rx_number) {
                        setDrawerOpenRx && setDrawerOpenRx(true);
                        setRxDetails &&
                          setRxDetails({
                            ...orderDetails,
                            id: orderDetails?.rx_id,
                          });
                      }
                    }}
                  >
                    {orderDetails?.rx_number
                      ? "# " + orderDetails?.rx_number
                      : ""}
                  </p>
                </div>

                {orderDetails?.tracking_code &&
                  orderDetails?.tracking_public_url && (
                    <a
                      href={orderDetails?.tracking_public_url}
                      target="_blank"
                      className={`${Styles?.customAddButton}`}
                      rel="noreferrer"
                    >
                      {" "}
                      <span className={`${Styles?.addToolTip}`}>
                        {" "}
                        {Config?.track_order_label}
                      </span>{" "}
                      <img
                        src={trackingBlue}
                        // src={trackingWhite}
                        alt="trackingImg"
                        style={{
                          height: "45px",
                          width: "45px",
                          // backgroundColor: color_config?.common_bg_color,
                          // padding: "4px",
                          // borderRadius: "99%",
                        }}
                      />
                    </a>
                  )}
              </div>
              <div className={`${Styles?.addressWrapper}`}>
                <h3 className={`${Styles?.addressHeader}`}>
                  {Config?.orderdetails_adrs}
                </h3>
                <p className={`${Styles?.address}`}>
                  {orderDetails?.order_location || ""}
                </p>
              </div>
            </div>

            {/* Order header card */}
            {isOrderInfoLoading ? (
              <ShimmerTable row={4} col={5} />
            ) : (
              <>
                {/* <!------------------------------------------ RX Card Section Starts ------------------------------------------> */}
                <section className={`${Styles?.RxCard}`}>
                  <div className={`${Styles?.mainCard}`}>
                    <table className={Styles["tableWrapper"]}>
                      <tr className={Styles["cartItemColumn"]}>
                        <th className={Styles["itemColumnProduct"]}>Product</th>
                        <th className={Styles["itemColumnPrice"]}>Price</th>
                        <th className={Styles["itemColumnQty"]}>QTY</th>
                        <th className={Styles["itemColumnQty"]}>Amount</th>
                        {context?.UserAuth?.usr_type === "customer" && (
                          <th className={Styles["itemColumnAction"]}></th>
                        )}
                      </tr>
                      {itemsList?.map((item, idx) => {
                        return (
                          <tr
                            key={item?.srv_id+"##"+idx}
                            className={Styles["dynamicTableData"]}
                          >
                            <td>
                              <div className={Styles["cartItemProduct"]}>
                                {/* {getProductstatus(item?.ord_item_type)} */}
                                <Link
                                  to={`/product-detail/${item?.slug}`}
                                  draggable="false"
                                >
                                  <div className={Styles["productImage"]}>
                                    <img
                                      src={getProductImage(
                                        item?.salt_composition,
                                        item?.srv_image
                                      )}
                                      alt={item?.srv_name}
                                      draggable="false"
                                      onError={(e) => {
                                        e.target.src = getProductImage(
                                          item?.salt_composition,
                                          ""
                                        );
                                      }}
                                    />
                                  </div>
                                </Link>
                                <div className={Styles["productTitle"]}>
                                  <Link
                                    to={`/product-detail/${item?.slug}`}
                                    style={{ textDecoration: "none" }}
                                    className={Styles["productLink"]}
                                    draggable="false"
                                  >
                                    <h2 className={Styles["productName"]}>
                                      {item?.srv_name +
                                        " " +
                                        item?.srv_packaging}
                                    </h2>
                                    <p className={Styles["productdescription"]}>
                                      {item?.srv_description}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              {getPrice(
                                item?.ord_item_applicable_price,
                                item?.ord_item_price
                              )}
                            </td>
                            <td>
                              <div className={Styles["qtyContainer"]}>
                                <p>{item?.ord_qty}</p>
                              </div>
                            </td>
                            <td>
                              <div className={Styles["qtyContainer"]}>
                                <p>
                                  {siteConfig?.site_currency +
                                    "" +
                                    item?.ord_item_grand_total}
                                </p>
                              </div>
                            </td>
                            {context?.UserAuth?.usr_type === "customer" && (
                              <td>
                                <div className={Styles["cartItemActions"]}>
                                  {/* <IconButton
                                  title="Add to cart"
                                  onClick={() => addCartHandler(item)}
                                  className={`${Styles?.iconButton}`}
                                >
                                  <img
                                    src={addCart}
                                    alt="add to cart"
                                    draggable="false"
                                  />
                                  {"Add to cart"}
                                  <i class="fa-solid fa-cart-plus"></i>
                                </IconButton> */}
                                  <p onClick={() => addCartHandler(item)}>
                                    {addToCartLoading === item?.srv_id ? (
                                      <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                      Config?.checkout_stepper_Heading__1
                                    )}
                                  </p>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  {/* <div className={`${Styles?.mobilewrapper} ${Styles?.desktop_wraaper}`}>
                    <div className={`${Styles?.productdetails_heading}`}>
                      <div className={`${Styles?.product_heading}`}>
                        Product
                      </div>
                      <div className={`${Styles?.price_heading}`}>Price</div>
                      <div className={`${Styles?.quantity_heading}`}>QTY</div>
                      <div className={`${Styles?.amount_heading}`}>Amount</div>
                    </div>

                    <div className={`${Styles?.productdetails_detail}`}>
                      {itemsList?.map((item) => {
                        return (
                          <div
                            key={item?.srv_id}
                            className={Styles?.orderItems}
                          >
                            <div className={Styles?.orderItemHeader}>
                              <h4>{item?.srv_name}</h4>
                              <p onClick={() => addCartHandler(item)}>
                                {addToCartLoading === item?.srv_id ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  Config?.checkout_stepper_Heading__1
                                )}
                              </p>
                            </div>
                            <div className={Styles?.orderItemFooter}>
                              <div className={Styles?.itemPrice}>
                                <p>
                                  {siteConfig?.site_currency +
                                    "" +
                                    item?.ord_item_applicable_price}
                                </p>
                              </div>
                              <div className={Styles?.itemQuantity}>
                                <p>{item?.ord_qty}</p>
                              </div>
                              <div className={Styles?.itemGrandTotal}>
                                <p>
                                  {siteConfig?.site_currency +
                                    "" +
                                    item?.ord_item_grand_total}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </section>
              </>
            )}

            <div
              className={`${Styles?.amountWrapper} ${Styles?.amoutdesktop_wrapper}`}
            >
              {isOrderInfoLoading ? (
                <div className={`${Styles?.DeliveryWrapper}`}>
                  <ShimmerThumbnail width={615} height={380} rounded />
                  <ShimmerThumbnail width={615} height={380} rounded />
                </div>
              ) : (
                <div className={`${Styles?.DeliveryWrapper}`}>
                  {/* <div className={`${Styles?.addressWrapper}`}>
                    <h3 className={`${Styles?.addressHeader}`}>
                      {Config?.orderdetails_adrs}
                    </h3>
                    <p className={`${Styles?.address}`}>
                      {orderDetails?.order_location || ""}
                    </p>
                  </div> */}

                  <div className={`${Styles?.total}`}>
                    <h4 className={`${Styles?.addressHeader}`}>
                      {Config?.orderdetails_sum}
                    </h4>

                    <div className={`${Styles?.calculationContent}`}>
                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>
                          {Config?.orderdetails_stot}
                        </p>
                        {/* {isCartEmpty ? (
                        <p className={`${Styles?.amount}`}>$00.00</p>
                      ) : ( */}
                        <p className={`${Styles?.amount}`}>
                          {" "}
                          {`${siteConfig?.site_currency}${Number(orderDetails?.ord_subtotal) > 0
                            ? Number(orderDetails?.ord_subtotal).toFixed(2)
                            : 0.0
                            }`}
                        </p>
                        {/* )} */}
                      </div>
                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>
                          {" "}
                          {Config?.orderdetails_disc}
                        </p>
                        <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                          }${Number(orderDetails?.ord_discount) > 0
                            ? Number(orderDetails?.ord_discount).toFixed(2)
                            : 0.0
                          }`}</p>
                      </div>
                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>{Config?.checkkout_billing_title_5}</p>
                        <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                          }${Number(orderDetails?.ord_tax) > 0
                            ? (Number(orderDetails?.ord_tax) / 2).toFixed(2)
                            : Number(0).toFixed(2)
                          }`}</p>
                      </div>
                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>{Config?.checkkout_billing_title_6}</p>
                        <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                          }${Number(orderDetails?.ord_tax) > 0
                            ? (Number(orderDetails?.ord_tax) / 2).toFixed(2)
                            : Number(0).toFixed(2)
                          }`}</p>
                      </div>

                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>
                          {Config?.orderdetails_fee}
                        </p>
                        <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                          }${Number(orderDetails?.ord_shipping) > 0
                            ? Number(orderDetails?.ord_shipping).toFixed(2)
                            : Number(0).toFixed(2)
                          }`}</p>
                      </div>
                      {/* <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.amountLabel}`}>{Config?.checkout_billing_title_3}</p>
                        <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                          }${Number(orderDetails?.ord_net_total) > 0
                            ? Number(orderDetails?.ord_net_total).toFixed(2)
                            : Number(0).toFixed(2)
                          }`}</p>
                      </div> */}
                    </div>

                    <div className={`${Styles?.totalAmountWrapper}`}>
                      <div className={`${Styles?.calculationWrapper}`}>
                        <p className={`${Styles?.totalAmountLabel}`}>
                          {Config?.orderdetails_tot}
                        </p>
                        <p
                          className={`${Styles?.amount} ${Styles?.totalAmountLabel}`}
                        >{`${siteConfig?.site_currency}${Number(orderDetails?.ord_net_payable) > 0
                          ? Number(orderDetails?.ord_net_payable).toFixed(2)
                          : Number(0).toFixed(2)
                          }`}</p>
                      </div>
                      {/* <p className={`${Styles?.paymentMode}`}>
                      {Config?.orderdetails_paymeth}
                    </p> */}
                    </div>
                    <p></p>
                  </div>
                </div>
              )}
            </div>
            {/* <!------------------------------------------ RX Card Section Ends ------------------------------------------> */}
            <div
              className={`${Styles?.actionButtonWrapper} ${Styles?.actionbtn_desktop}`}
            >
              {activeStep < 3 && activeStep > 0 ? (
                <Button
                  type={Config?.lbl_Btn_type_primary}
                  className={`${Styles?.orderAgain}`}
                  size="medium"
                  onClick={() => setOrderCancelPopUpOpen(true)}
                >
                  {Config?.orderdetails_cnc_ord}
                </Button>
              ) : (
                ""
              )}

              {/* {activeStep >= 4 &&
                orderDetails?.tracking_code &&
                orderDetails?.tracking_public_url && (
                  <a
                    id="track_order"
                    href={orderDetails?.tracking_public_url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      type={Config?.lbl_Btn_type_primary}
                      className={`${Styles?.orderAgain}`}
                      size="medium"
                    >
                      {Config?.orderdetails_Track_ord}
                    </Button>
                  </a>
                )} */}

              {/* <Button
                type={Config?.lbl_Btn_type_primary}
                className={`${Styles?.orderAgain}`}
                size="medium"
              >
                {Config?.orderdetails_ord_again}
              </Button> */}
            </div>
            {isAlert?.istrue && (
              <Alert type={isAlert?.type} info={isAlert?.msg} />
            )}
            {isCartPopUpOpen && (
              <AddtocartPopup
                img={productdetails?.srv_image.split("~")[0]}
                title={productdetails?.srv_name + productdetails?.srv_packaging}
                setModalOpen={setIsCartPopUPOpen}
              />
            )}

            {orderCancelPopUpOpene && (
              <OrderCancelPopup
                orderDetails={orderDetails}
                setDrawerOpen={setDrawerOpen}
                setModalOpen={setOrderCancelPopUpOpen}
                getOrderList={getOrderList}
              />
            )}
          </div>
        </div>
        {/*---- mnoile order ui start here ---*/}
        <div className={`${Styles?.mobilewrapper}`}>
          <div className={`${Styles?.productdetails_heading}`}>
            <div className={`${Styles?.product_heading}`}>Product</div>
            <div className={`${Styles?.price_heading}`}>Price</div>
            <div className={`${Styles?.quantity_heading}`}>QTY</div>
            <div className={`${Styles?.amount_heading}`}>Amount</div>
          </div>

          <div className={`${Styles?.productdetails_detail}`}>
            {itemsList?.map((item) => {
              return (
                <div key={item?.srv_id} className={Styles?.orderItems}>
                  <div className={Styles?.orderItemHeader}>
                    <h4>{item?.srv_name}</h4>
                    <p onClick={() => addCartHandler(item)}>
                      {addToCartLoading === item?.srv_id ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        Config?.checkout_stepper_Heading__1
                      )}
                    </p>
                  </div>
                  <div className={Styles?.orderItemFooter}>
                    <div className={Styles?.itemPrice}>
                      <p>
                        {siteConfig?.site_currency +
                          "" +
                          item?.ord_item_applicable_price}
                      </p>
                    </div>
                    <div className={Styles?.itemQuantity}>
                      <p>{item?.ord_qty}</p>
                    </div>
                    <div className={Styles?.itemGrandTotal}>
                      <p>
                        {siteConfig?.site_currency +
                          "" +
                          item?.ord_item_grand_total}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`${Styles?.amountWrapper} ${Styles?.mobileamout_wrapper}`}
        >
          {isOrderInfoLoading ? (
            <div className={`${Styles?.DeliveryWrapper}`}>
              <ShimmerThumbnail width={615} height={380} rounded />
              <ShimmerThumbnail width={615} height={380} rounded />
            </div>
          ) : (
            <div className={`${Styles?.DeliveryWrapper}`}>
              {/* <div className={`${Styles?.addressWrapper}`}>
                    <h3 className={`${Styles?.addressHeader}`}>
                      {Config?.orderdetails_adrs}
                    </h3>
                    <p className={`${Styles?.address}`}>
                      {orderDetails?.order_location || ""}
                    </p>
                  </div> */}

              <div className={`${Styles?.total}`}>
                <h4 className={`${Styles?.addressHeader}`}>
                  {Config?.orderdetails_sum}
                </h4>

                <div className={`${Styles?.calculationContent}`}>
                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.amountLabel}`}>
                      {Config?.orderdetails_stot}
                    </p>
                    {/* {isCartEmpty ? (
                        <p className={`${Styles?.amount}`}>$00.00</p>
                      ) : ( */}
                    <p className={`${Styles?.amount}`}>
                      {" "}
                      {`${siteConfig?.site_currency}${Number(orderDetails?.ord_subtotal) > 0
                        ? Number(orderDetails?.ord_subtotal).toFixed(2)
                        : 0.0
                        }`}
                    </p>
                    {/* )} */}
                  </div>
                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.amountLabel}`}>
                      {" "}
                      {Config?.orderdetails_disc}
                    </p>
                    <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                      }${Number(orderDetails?.ord_discount) > 0
                        ? Number(orderDetails?.ord_discount).toFixed(2)
                        : 0.0
                      }`}</p>
                  </div>
                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.amountLabel}`}>Tax</p>
                    <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                      }${Number(orderDetails?.ord_tax) > 0
                        ? Number(orderDetails?.ord_tax).toFixed(2)
                        : Number(0).toFixed(2)
                      }`}</p>
                  </div>

                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.amountLabel}`}>
                      {Config?.orderdetails_fee}
                    </p>
                    <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                      }${Number(orderDetails?.ord_shipping) > 0
                        ? Number(orderDetails?.ord_shipping).toFixed(2)
                        : Number(0).toFixed(2)
                      }`}</p>
                  </div>
                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.amountLabel}`}>Net total</p>
                    <p className={`${Styles?.amount}`}>{`${siteConfig?.site_currency
                      }${Number(orderDetails?.ord_net_total) > 0
                        ? Number(orderDetails?.ord_net_total).toFixed(2)
                        : Number(0).toFixed(2)
                      }`}</p>
                  </div>
                </div>

                <div className={`${Styles?.totalAmountWrapper}`}>
                  <div className={`${Styles?.calculationWrapper}`}>
                    <p className={`${Styles?.totalAmountLabel}`}>
                      {Config?.orderdetails_tot}
                    </p>
                    <p
                      className={`${Styles?.amount} ${Styles?.totalAmountLabel}`}
                    >{`${siteConfig?.site_currency}${Number(orderDetails?.ord_net_payable) > 0
                      ? Number(orderDetails?.ord_net_payable).toFixed(2)
                      : Number(0).toFixed(2)
                      }`}</p>
                  </div>
                  {/* <p className={`${Styles?.paymentMode}`}>
                      {Config?.orderdetails_paymeth}
                    </p> */}
                </div>
                <p></p>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${Styles?.actionButtonWrapper} ${Styles?.actionbtn_mobile}`}
        >
          {activeStep < 3 && activeStep > 0 ? (
            <Button
              type={Config?.lbl_Btn_type_primary}
              className={`${Styles?.orderAgain}`}
              size="medium"
              onClick={() => setOrderCancelPopUpOpen(true)}
            >
              {Config?.orderdetails_cnc_ord}
            </Button>
          ) : (
            ""
          )}

          {/* {activeStep >= 4 &&
                orderDetails?.tracking_code &&
                orderDetails?.tracking_public_url && (
                  <a
                    id="track_order"
                    href={orderDetails?.tracking_public_url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      type={Config?.lbl_Btn_type_primary}
                      className={`${Styles?.orderAgain}`}
                      size="medium"
                    >
                      {Config?.orderdetails_Track_ord}
                    </Button>
                  </a>
                )} */}

          {/* <Button
                type={Config?.lbl_Btn_type_primary}
                className={`${Styles?.orderAgain}`}
                size="medium"
              >
                {Config?.orderdetails_ord_again}
              </Button> */}
        </div>
        {/*---- mnoile order ui end here ---*/}
      </div>
    </div>
  );
};

export default SlideDrawer;
