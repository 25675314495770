// @ts-ignore
import React, { memo, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIContext from "../../store/api-context";
import { UserAuthContext } from "../../store/UserAuthContext";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import style from "./index.module.css";
import Banners from "./Banners";
import ProductWidget from "./ProductWidget";
import BrandWidget from "./BrandWidget";
import CategoryWidget from "./CategoryWidget";
import InsuranceModal from "../../components/InsuranceModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import MyRXWidgetNew from "./MyRXWidgetNew";
import LoginPopup from "./LoginPopup";
import DefaultWidget from "./DefaultWidget";
import Alert from "../../components/UI/Alert";
import { GlobalContext } from "../../store/global-context.js";
import ReactGA from "react-ga";
import { JSONCheckerFunc, getID, objDecrypt, objEncrypt, setEncryptedID, setID, triggerLocalStorage } from "../../utils/utils";
import HowItWorks from "../Landingpage/HowItWorks";
import OurProducts from "../Landingpage/OurProducts";
import HealthInfoCards from "../../components/HealthInfoCards";
import SelectType from "../SignUpVendor/SelectType/SelectType";
import SignUpVendorContainer from "../SignUpVendor/SignUpVendorContainer/SignUpVendorContainer";
import SignUpVendor from "../SignUpVendor";
import CompanyProfile from "../SignUpVendor/CompanyProfile/CompanyProfile";
import styles from "./index.module.css";
import CreateAccount from "../SignUpVendor/CreateAccount";
import HowItWorks20 from "../Landingpage/HowItWork20";
import { registerMerchant } from "./utils";
import MyDocuments from "../../components/MyDocuments";
import TermModalPopup from "../../components/TermsModalpopup";
import HomeBanners from "./Home-Banner";
import btnConfig from "../../config/button";
import Config from "../../config/label";
import DocViewer from "../../components/MyDocuments/DocViewer/DocViewer";
import { msg_config } from "../../config/messages";
import {
  IS_INSURANCE,
  IS_PROFILE_UPDATED,
  LOGGED_IN_USER_ROLE,
  ON_BOARDING_STATUS_UPDATE_FOR_PENDING,
  PROFILE_IMAGE2,
  SG_PATIENT_ID,
  SG_ROLE_ID,
  SG_USER_ID,
  USER_LOGGED_IN,
} from "../../config/Config";
import { useSelector } from "react-redux";
import { getDecryptedID } from "../../utils/utils";
import { USER_PROFILE } from "../../config/Config";
import { decryptData, encryptData } from "../../utils/util_helper";


const HomePage = (props) => {
  const [suggestUsInit] = useContext(APIContext);
  const location = useLocation();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [openHealthInfoCard, setOpenHealthInfoCard] = useState(false);
  const [openCompanyProfileCard, setOpenCompanyProfileCard] = useState(false);
  const [openCreateCompanyCard, setOpenCreateCompanyCard] = useState(false);
  const [openTermModalPopup, setOpenTermModalPopup] = useState(false);
  const [openMyDocuments, setOpenMyDocuments] = useState(false);
  const [checked, setChecked] = useState(false);
  const formData = useSelector((state) => state?.signUpVendorReducer);
  const [openDocViewer, setOpenDocViewer] = useState({
    isOpen: false,
    docData: {},
  });
  const [openSelectType, setOpenSelectType] = useState(
    context?.UserAuth?.usr_type === null ? true : false
    // true
  );
  // const [openHealthInfoCard, setOpenHealthInfoCard] = useState(
  //   context?.UserAuth?.usr_type === null ? true : false
  //   // true
  // );
  const [homeContext, setHomeContext] = useState([
    { widget_type: "no_widget" },
  ]);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [user] = useAuthState(auth);
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false); //boolean
  triggerLocalStorage();
  useEffect(() => {
    try {
      let userDetailsFromSession = getDecryptedID(USER_PROFILE) || {};
      setOpenCompanyProfileCard(
        userDetailsFromSession?.usr_type === "pro" &&
          userDetailsFromSession?.usr_company_name === null
          ? true
          : false
      );
      if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
        setIsEditing(context?.isEditProProfile)
      }
    } catch (error) {
      console.error(error);
    }
  }, [context]);




  // useEffect(()=>{
  //   try {
  //     const localStorageData = objDecrypt(localStorage.getItem(USER_PROFILE));
  //     setOpenHealthInfoCard(JSONCheckerFunc(localStorageData)?.usr_name.trim("") === "" ? true : false)
  //   } catch (error) {
  //     console.error(error);
  //   }

  // },[localStorage.getItem(USER_PROFILE)])

  useEffect(() => {
    try {
      if (
        context?.UserAuth?.usr_type === "customer" &&
        getDecryptedID(USER_LOGGED_IN) === "Y"
      ) {
        let isProfileUpdated = getDecryptedID(IS_PROFILE_UPDATED) === "Y" ? false : true;
        setOpenHealthInfoCard(isProfileUpdated);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getID(IS_PROFILE_UPDATED)]);

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.home_process_id, {}).then((res) => {
        if (res?.returnCode) {
          res?.returnData?.length > 0 && setHomeContext(res?.returnData);
        }
      });
    }

    var user_profile = getDecryptedID(USER_PROFILE);
    const eRoleId = encryptData(user_profile?.rol_id);
    const eUserId = encryptData(user_profile?.usr_id);
    const ePatientId = encryptData(user_profile?.patient_id);
    // user_profile = JSONCheckerFunc(user_profile);
    setID(SG_ROLE_ID, eRoleId || "");
    setID(SG_USER_ID, eUserId || "");
    setID(SG_PATIENT_ID, ePatientId || "");

    //Insurance popup
    if (
      user_profile?.insurance_flag === "N" &&
      getDecryptedID(IS_INSURANCE) !== "Clicked"
    ) {
      setIsInsuranceModalOpen(false);
    }

    // Temporary calling BELOW Profile API to set Image URL Globally till further changes from backend
    // *****************************************************************************************

    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.my_profile_process_id, {
        p_user_id: user_profile?.usr_id,
      }).then((res) => {
        setEncryptedID(PROFILE_IMAGE2, res?.returnData[0]?.patient_img_path || "");
        setGlobalContext((prev) => {
          return {
            ...prev,
            profile_image: res?.returnData[0]?.patient_img_path,
          };
        });
      });
    }

    // Temporary calling ABOVE Profile API to set Image URL Globally till further changes from backend
    // *****************************************************************************************
  }, [suggestUsInit, sessionStorage?.getItem(USER_PROFILE)]);

  const getWidget = (widget, idx) => {
    const { widget_type } = widget || { widget_type: "" };
    switch (widget_type) {
      case "banners":
        return (
          <div className="outer_container">
            <div className={styles.main_banner_ads}>
              <div className={`${styles?.top_homebanner}`}>
                {/* <HomeBanners
              widget={widget}
              buttonText={btnConfig?.homebanner_Button_1}
              headingText={Config?.heading_homebanner}
              subheadingTxt={Config?.text_homebanner}
              getStarted={btnConfig?.homebanner_Button_2}
              key={idx}
            /> */}
                <Banners
                  widget={widget}
                />
              </div>
            </div>
          </div>
        );

      case "brands":
        return <BrandWidget widget={widget} key={idx} />;
      case "category":
        return <CategoryWidget widget={widget} key={idx} />;
      case "products":
        return (
          <ProductWidget
            widget={widget}
            setIsAlert={setIsAlert}
            isAlert={isAlert}
            key={idx}
          />
        );
      case "how_works":
        return <HowItWorks20 widget={widget} key={idx} />;
      case "my_rx":
        return (
          <MyRXWidgetNew widget={widget} setIsAlert={setIsAlert} key={idx} />
        ); //replaced MyRxWidget

      case "our_products":
        return (
          <OurProducts
            setIsLoginPopup={setIsLoginPopup}
            setIsAlert={setIsAlert}
            widget={widget}
            key={idx}
          />
        );

      case "no_widget":
        return <DefaultWidget index={idx} key={idx} />;

      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(location?.pathname + location?.search);
  }, [location]);

  useEffect(() => {
    try {
      setOpenCompanyProfileCard(context?.isEditProProfile)
    } catch (error) {
      console.error(error);
    }
  }, [context])

  // useEffect(() => {
  //   if (JSON.stringify(context?.UserAuth) !== "{}") {
  //     let usertype = context?.UserAuth?.usr_type;
  //     let onboardStatus = context?.UserAuth?.usr_onboarding_status;
  //     if (
  //       usertype === LOGGED_IN_USER_ROLE &&
  //       onboardStatus === ON_BOARDING_STATUS_UPDATE_FOR_PENDING
  //     ) {
  //       // setOpenMyDocuments(false);
  //       setOpenMyDocuments(true);
  //     }
  //   }
  //   // if(context?.UserAuth?.usr_onboarding_status === LOGGED_IN_USER_ROLE){
  //   // }
  // }, [context]);

  return (
    <div>

      {isAlert?.istrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}
      <div className={`${style?.container}`}>
        {Array?.isArray(homeContext) &&
          homeContext?.map((widget, idx) => {
            return getWidget(widget, idx);
          })}
      </div>
      {/* Step 1: */}
      {/* {true && ( */}
      {/* {(context?.UserAuth?.usr_type === null || openSelectType) && (
      <div className={`${sverify_otptyles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <SelectType
              setOpenHealthInfoCard={setOpenHealthInfoCard}
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              setOpenSelectType={setOpenSelectType}
              handleNext={() => {
                // setOpenSelectType(false);
              }}
            />
          </div>
        </div>
      )
      } */}
      {/* Step 2 Basic details of company */}
      {/* {openCompanyProfileCard && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <CompanyProfile
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              handleNext={() => {
                setOpenCompanyProfileCard(false);
                setOpenCreateCompanyCard(true);
                  setOpenSelectType(false);
              }}
              handleBack={() => {
                setOpenCompanyProfileCard(false);
                setOpenSelectType(true);
              }}
            />
          </div>
        </div>
      )} */}
      {/* Step 3 More details about company */}
      {/* {openCreateCompanyCard && ( */}
      {openCreateCompanyCard && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <CreateAccount
              handleNext={() => {
                registerMerchant();
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);
                if (!isEditing) {
                  setOpenMyDocuments(false);
                } else {
                  setOpenMyDocuments(true);
                }
              }}
              setIsAlert={setIsAlert}
              setOpenCreateCompanyCard={setOpenCreateCompanyCard}
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              handleBack={() => {
                let userAuth = context?.UserAuth;
                userAuth = { ...userAuth, usr_company_name: null };
                // setContext((prev) => {
                //   return { ...prev, UserAuth: userAuth };
                // });  
                setOpenCompanyProfileCard(true);
                setOpenCreateCompanyCard(false);
              }}
            />
          </div>
        </div>
      )}
      {/* Step 4 Upload documents */}

      {openMyDocuments && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <MyDocuments
              setOpenMyDocuments={setOpenMyDocuments}
              setOpenDocViewer={setOpenDocViewer}
              setOpenTermModalPopup={setOpenTermModalPopup}
              handleNext={() => {
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);
                // setOpenMyDocuments(false);
                // setIsAlert({
                //   msg: msg_config?.msg_profile_submitted_for_approval,
                //   type: "success",
                //   istrue: true,
                // });
                // TODO ONBOARDING STATUS - HARRY
              }}
              checked={checked}
              handleBack={() => {
                setOpenMyDocuments(false);
                setOpenTermModalPopup(false);
                setOpenCreateCompanyCard(true);
              }}
            />
          </div>
        </div>
      )}

      {/* Step 5 Terms and conditions */}
      {openTermModalPopup && (
        // <div className={`${styles?.modalBackground}`}>
        //   <div className={`${styles?.modalContainer}`}>
        <TermModalPopup
          checked={checked}
          setChecked={setChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
            setOpenMyDocuments(true);
            // navigate("/product-list");
            // setOpenMyDocuments(true);
          }}
        />
        //   </div>
        // </div>
      )}
      {/* Step 5 My Documents */}

      {openDocViewer?.isOpen && (
        <div className={`${styles?.modalBackground}`}>
          <div className={`${styles?.modalContainer}`}>
            <DocViewer
              openDocViewer={openDocViewer}
              setOpenDocViewer={setOpenDocViewer}
            />
          </div>
        </div>
      )}
      {/* {openHealthInfoCard && (
        <HealthInfoCards
          setIsPasswordModalOpen={setOpenHealthInfoCard}
          screenType="HomePage"
        />
      )} */}
      {openHealthInfoCard && (
        <HealthInfoCards
          setIsPasswordModalOpen={setOpenHealthInfoCard}
          screenType="HomePage"
        />
      )}
      {isInsuranceModalOpen && (
        <InsuranceModal
          setIsInsuranceModalOpen={setIsInsuranceModalOpen}
          setIsAlert={setIsAlert}
          setOpenHealthInfoCard={setOpenHealthInfoCard}
        />
      )}
      {context?.isLoginPopUP && <LoginPopup setModalOpen={setContext} />}
      {isLoginPopup && <LoginPopup setModalOpen={setIsLoginPopup} />}
    </div>
  );
};

export default memo(HomePage);
