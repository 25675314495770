import React from "react";
import s from "./index.module.css";
interface LoadingCircleProps {
  loadingColor?: String;
}
const LoadingCircle: React.FC<LoadingCircleProps> = (props) => {
  return (
    <div className={s["lds-ring"]}>
      <div
        style={{
          borderColor: `${props.loadingColor ? props.loadingColor : ""
            } transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          borderColor: `${props.loadingColor ? props.loadingColor : ""
            } transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          borderColor: `${props.loadingColor ? props.loadingColor : ""
            } transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          borderColor: `${props.loadingColor ? props.loadingColor : ""
            } transparent transparent transparent`,
        }}
      ></div>
    </div>
  );
};
export default LoadingCircle;
