import React, { useContext } from "react";
import Styles from "./index.module.css";
import deleteIcon from "../../../../assets/svg/FileDelete.svg";
import Input from "./Input";
import APIContext from "../../../../store/api-context";
import { config } from "../../../../config/processId.js";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import Config from "../../../../config/label";
import IconButton from "../../../../components/UI/IconButton";
import PopConfirm from "../../../../components/UI/PopConfirm";
import Delete from "../../../../assets/icons/Delete_icon.svg";
import Saveforlater_icon from "../../../../assets/icons/Saveforlater_icon.svg";
import QtyInput from "../QtyInput";
import { getDecryptedID, getID, setEncryptedID } from "../../../../utils/utils";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID } from "../../../../config/Config";
import { decryptData } from "../../../../utils/util_helper";

export type productProps = {
  id?: string;
  type?: string;
  imgUrl?: any;
  imgAlt?: string;
  name?: string;
  detail?: string;
  price?: string;
  qty?: string;
  setLoading?: any;
  getProducts?: any;
  getCartTotal?: any;
};

const ProductCard: React.FC<productProps> = (props) => {
  const {
    id,
    type,
    imgUrl,
    imgAlt,
    name,
    detail,
    price,
    qty,
    setLoading,
    getProducts,
    getCartTotal,
  } = props;
  const [suggestUsInit] = useContext(APIContext);

  const getProductstatus = (type: string) => {
    if (type === "rx_approved") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["greenCircle"]} />
          <p>Approved</p>
          <p>{Config?.Prescription_text}</p>
        </div>
      );
    } else if (type === "rx_pending") {
      return (
        <div className={Styles["drugStatus"]}>
          <div className={Styles["orangeCircle"]} />
          <p>{Config?.Prescription_text}</p>
          <p>Pending</p>
        </div>
      );
    } else {
      return (
        <div className={Styles["drugStatus"]}>
          <p>OTC</p>
          <p>products</p>
        </div>
      );
    }
  };

  const getPrice = (price: string) => {
    const price_label = `$${price}`;
    return <h2 className={Styles["itemPrice"]}>{price_label}</h2>;
  };

  const getTotal = (total: string, type: string) => {
    if (type === "other") {
      //const total_copay = (total * 0.3).toFixed(2);
      const total_label = `$${total}`;
      //const total_copay_label = `$${total_copay}`;
      return (
        <div className={Styles["productTotalCopay"]}>
          <h1>{total_label}</h1>
          <div>
            <h2>{/*total_copay_label*/}</h2>
            <p>Co-Pay</p>
          </div>
        </div>
      );
    } else {
      const total_label = `$${total}`;
      return (
        <div className={Styles["productTotal"]}>
          <h2>{total_label}</h2>
        </div>
      );
    }
  };

  const onDeleteHandler = (id: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.delete_cart_item_process_id, {
        p_cart_item_id: id,
        p_user_id: decryptData(getID(SG_USER_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          //window.location.reload();
          // setLoading(true);
          getProducts();
          getCartTotal();
          // setLoading(false);
        }
      });
    }
  };

  const onAddSFLHandler = (cart_id: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.move_cart_item_to_saveforlater_process_id, {
        p_cart_item_id: cart_id,
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          // setLoading(true);
          getProducts();
          getCartTotal();
          // setLoading(false);
          //window.location.reload();
        }
        if (
          res?.returnCode === false &&
          res?.msg === "Product already exists in the cart."
        ) {
          var count: any = getDecryptedID(CART_COUNT);
          setEncryptedID(CART_COUNT, (parseInt(count) - 1).toString());
          // setLoading(true);
          getProducts();
          getCartTotal();
          // setLoading(false);
          //window.location.reload();
        }
      });
    }
  };

  const qtyChange = (p_id: string, p_qty: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.update_cart_item_qty_process_id, {
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_qty: p_qty,
        p_cart_item_id: p_id,
      }).then((res) => {
        if (res?.returnCode) {
          window.location.reload();
          getProducts();
          getCartTotal();
        }
      });
    }
  };

  function handleChange(event: any) {
    if (event.target.value === "") {
    } else if (event.target.value === "0") {
      event.target.value = 1;
      qtyChange(event.target.id, event.target.value);
    } else {
      qtyChange(event.target.id, event.target.value);
    }
  }

  return (
    <div className={Styles["productCard"]}>
      <div className={Styles["cardName"]}>
        <div className={Styles["productImage"]}>
          <img src={imgUrl} alt={imgAlt} draggable="false" />
        </div>
        <div className={Styles["product-details"]}>
          {getProductstatus(type!)}
          <h3>{name}</h3>
          <p>{detail}</p>
          <div className={Styles["price-columns"]}>
            <div className={Styles["price-col"]}>
              <h2>Price</h2>
              {getPrice(price!)}
            </div>
            <div className={Styles["tot-price-col"]}>
              <h2>Total</h2>
              {getTotal(price!, type!)}
            </div>
          </div>
        </div>
      </div>
      <div className={Styles["cardFooter"]}>
        <div>
          <h2>QTY</h2>
          <div className={Styles["qtyContainer"]}>
            <QtyInput
              name="productqty"
              id={id!}
              className={`${Styles?.qtyInp}`}
              type={Config?.lbl_Inp_type_text}
              defaultValue={qty}
              placeholder={qty!}
              size="small"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={Styles["cartItemActions"]}>
          <IconButton
            title="Save for later"
            onClick={() => onAddSFLHandler(id!)}
          >
            <img
              src={Saveforlater_icon}
              alt="save for later"
              draggable="false"
            />
          </IconButton>
          <IconButton onClick={() => onDeleteHandler(id!)} title="Delete">
            <img src={Delete} alt="delete" draggable="false" />
          </IconButton>
          {/* <PopConfirm
            title="Are you sure ?"
            onConfirm={() => onDeleteHandler(id!)}
          >
            <IconButton title="Delete">
              <img src={Delete} alt="delete" draggable="false" />
            </IconButton>
          </PopConfirm> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
