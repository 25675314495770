import React, { useContext, useState } from "react";
import { DoneIcon, CloseIcon } from "../../../utils/appIcons";
import Button from "../Button";
import Styles from "./index.module.css";
import btnConfig from "../../../config/button";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Config from "../../../config/label";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION } from "../../../config/Config";
import { getDecryptedID, getID, removeKey } from "../../../utils/utils";
import { decryptData } from "../../../utils/util_helper";

export type ModalProps = {
  heading?: string;
  detail?: string;
  setModalOpen?: any;
};

const Modal: React.FC<ModalProps> = (props) => {
  const { setModalOpen, heading, detail } = props;
  let navigate = useNavigate();
  const [context, setContext] = useContext(UserAuthContext);


  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            // setModalOpen(false);
            setContext((prev: any) => {
              return { ...prev, isPrescriptionUploaded: false, data : "" }
            })
            const navigationAddress =  getDecryptedID(CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION) || "/";
            navigate(navigationAddress);
            removeKey(CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION);
            // if(localStorage.getItem("rxCart_flag") === "Y"){
            //   localStorage.removeItem("rxCart_flag");
            //   navigate("/purchase");
            // } else {
            //   navigate("/", { replace: true });
            // }
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={DoneIcon} alt="Right check icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>{heading}</Heading>

          <p className={`${Styles?.detail}`}>{detail}</p>
        </div>

        <Button
          type={Config?.lbl_Btn_type_primary}
          htmlType={Config?.lbl_Btn_action_submit}
          size={Config?.lbl_Btn_size_medium}
          onClick={() => {
            // setModalOpen(false);
            // navigate("/", { replace: true });
            // const navigationAddress =  getDecryptedID(CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION) || "/";
            // navigate(navigationAddress);
            // removeKey(CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION);
            // if(localStorage.getItem("rxCart_flag") === "Y"){
            //   localStorage.removeItem("rxCart_flag");
            //   navigate("/purchase");              
            // } else {
            //   navigate("/");
            // }
            navigate("/");
            setContext((prev: any) => {
              return { ...prev, isPrescriptionUploaded: false, data : "" }
            })
          }}
        >
          {btnConfig?.verification_Complete}
        </Button>
      </div>
    </div>
  );
};
export default Modal;
