import { get } from "lodash";
import s from "./index.module.css";
import { useRef, useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../FormErrorMessage";

export type TextAreaProps = {
  name: string;
  id: string;
  placeholder: string;
  className?: string;
  required?: any;
  value?: any;
  disabled?: any;
  onChangeHandler?: any;
  maxLength?: any;
  register?: any;
  rules?: any;
  errors?: any;
  inputAutoFocus?:any;
};

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    name,
    id,
    placeholder,
    className,
    required,
    value,
    disabled,
    onChangeHandler,
    maxLength,
    register,
    rules,
    errors,
    inputAutoFocus= false,
  } = props;

  const errorMessages = get(errors, name);
  const inputRef = useRef(null);
  const hasError = !!(errors && errorMessages);
  
  useEffect(() => {
    //@ts-ignore
    inputAutoFocus && inputRef.current.focus();
  }, []);
  const { ref, onChange, ...rest } = register && register(name, rules);

  return (
    <>
    <textarea
    aria-invalid={hasError}
      name={name}
      ref={(e: any) => {
        ref(e);
        inputRef.current = e;
      }}
      id={id}
      placeholder={placeholder}
      className={`${s?.textAreaStyle} ${className}`}
      required={required}
      value={value}
      disabled={disabled}
      onChange={onChangeHandler}
      maxLength={maxLength} 
    />
    <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className={`${s?.error_msg}`}>
            {message}
          </FormErrorMessage>
        )}
      />
    </>
    
  );
};

export default TextArea;
