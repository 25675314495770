import { useDispatch, useSelector } from "react-redux";
import Config from "../../../config/label";
import SignUpVendorContainer from "../../../pages/SignUpVendor/SignUpVendorContainer/SignUpVendorContainer";
import { CloseIcon } from "../../../utils/appIcons";
import Input from "../../UI/Input";
import Label from "../../UI/Label";
import styles from "./index.module.css";
import { mylistPageActions } from "../../../pages/MyListPage/slice";
import TextArea from "../../UI/TextArea";
import { useState } from "react";
import Button from "../../UI/Button";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { config } from "../../../config/processId";
import btnConfig from "../../../config/button";
import { useEffect } from "react";
import { getDecryptedID, getID } from "../../../utils/utils";
import { SG_USER_ID } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
import { useForm } from "react-hook-form";
import Alert from "../../UI/Alert";
const AddNewList = (props) => {
  const { userProfileJson, editMode,  onClose } = props;
  const [isAlert, setIsAlert] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });  const dispatch = useDispatch();
  const [listName, setListName] = useState("");
  const [listDescription, setListDescription] = useState("");
  const myLists = useSelector((state) => state?.myListPageReducer?.myLists);
  const selectedList = useSelector(
    (state) => state?.myListPageReducer?.selectedList
  );
  const [loading, setLoading] = useState(false);


  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
    clearErrors,
    setError,
    register,
  } = useForm();
  useEffect(() => {
    if (editMode) {
      const currentList = myLists.filter(
        (i) => i.p_list_id === selectedList.p_list_id
      )[0];
      setListName(currentList?.p_list_title);
      setListDescription(currentList?.p_list_description);
    }
    return () => {
      dispatch(mylistPageActions?.CLEAR_SELECTION());
    };
  }, []);


  useEffect(()=>{
    try {
      if(isAlert?.isTrue){
        setTimeout(() => {
          setIsAlert((prev)=>{
            return {...prev, isTrue : false}
          })
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  },[isAlert])


  return (
    <SignUpVendorContainer>
      <div className={`${styles?.create_new_list_wrapper}`}>
        {isAlert?.isTrue &&  <Alert type={isAlert?.type} info={isAlert?.msg} />}
        <div className={`${styles?.create_list_arrow}`}
          onClick={() => {
            dispatch(
              mylistPageActions?.TOGGLE_CREATE_LIST({
                showAddNewMyList: false,
              })
            );
          }}
        >
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
        <div className={`${styles?.create_list_title}`}>
          {editMode ? <h5>Edit list</h5> : <h5>Create new list</h5>}
        </div>
      </div>
      <div className={`${styles?.myListContainer}`}>
        <div className={`${styles?.myListRightContainer}`}>
          <form>
            <Label className={`${styles?.form_label}`} for="selected_my_list">
              {Config?.myList_field_list_name}
            </Label>
            <Input
              name={"p_list_title"}
              // aria-invalid={hasError}
              onChange={(e) => {
                setListName(e.target.value);
              }}
              id={"p_list_title"}
              placeholder="List title"
              value={listName}
              maxLength={100}
            // value={newValue}
            // onKeyDown={onInputKeyPressHandler}
            />
            {/* {isAlert?.isTrue && <p>{isAlert?.msg}</p>} */}
            <div className={`${styles?.textare_wrapper}`}>
              <Label
                className={`${styles?.form_label}`}
                for="selected_my_list2"
              >
                {Config?.myList_field_list_description}
              </Label>
              <textarea
                name="p_list_description"
                id="p_list_description"
                placeholder="List Description"
                value={listDescription}
                onChange={(e) => {
                  setListDescription(e.target.value);
                }}
                maxLength={250}
                className={`${styles?.textAreaStyle}`}
              />
            </div>
          </form>{" "}
          <div className={`${styles?.addnewlist_btn}`}>
            <Button
              className={`${styles?.cancel_btn}`}
              onClick={() => {
                dispatch(
                  mylistPageActions?.TOGGLE_CREATE_LIST({
                    showAddNewMyList: false,
                  })
                );
              }}
            >
              {btnConfig?.mylist_cancel_list}
              {/* <img src={CloseIcon} alt="Cross icon" draggable="false" /> */}
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                setIsAlert({
                  isTrue: false,
                  type: "",
                  msg: "",
                });
                // if (listDescription === "" && listName === "") {
                //   setIsAlert({
                //     istrue: true,
                //     type: "error",
                //     msg: "List name and description can not be empty",
                //   });
                // } else
                if (listName === "") {
                  setIsAlert({
                    isTrue: true,
                    type: "error",
                    msg: "List name can not be empty",
                  });
                  return true;
                }
                //  else if (listDescription === "") {
                //   setIsAlert({
                //     istrue: true,
                //     type: "error",
                //     msg: "List description can not be empty",
                //   });
                //   return true;
                // } 
                else if (listName !== "") {
                  //calling api to submit the list 
                  setLoading(true);
                  CallAPI(config?.pro_user_save_my_list_process_id,
                    {
                      p_usr_id: decryptData(getID(SG_USER_ID)),
                      p_cus_id: userProfileJson?.customer_id,
                      p_mylist_id: editMode ? selectedList?.p_list_id : "",
                      p_list_title: listName,
                      p_list_description: listDescription,
                      p_internal_flag: "",
                    })
                    .then((res) => {
                      if (res.returnCode === true) {
                        dispatch(
                          mylistPageActions?.TOGGLE_CREATE_LIST({
                            showAddNewMyList: false,
                          })
                        );
                        if (onClose) onClose(res?.returnData[0]);

                        // setIsAlert({
                        //   isTrue: true,
                        //   type: "success",
                        //   msg: "Successfully added list!",
                        // });
                        if (editMode) {
                          dispatch(
                            mylistPageActions?.UPDATE_LIST({
                              p_list_id: selectedList?.p_list_id,
                              newList: res?.returnData[0],
                            })
                          );
                        } else
                          dispatch(
                            mylistPageActions?.ADD_NEW_LIST({
                              newList: res?.returnData[0],
                            })
                          );
                        setLoading(false);
                      } else {
                        setIsAlert({
                          isTrue: true,
                          type: "error",
                          msg: "Error while creating list",
                        });
                        setLoading(false);
                      }
                    })
                  // (async () => {
                  //   const res = await CallAPI(
                  //     config?.pro_user_save_my_list_process_id,
                  //     {
                  //       p_usr_id: localStorage?.getItem(SG_USER_ID),
                  //       p_cus_id: userProfileJson?.customer_id,
                  //       p_mylist_id: editMode ? selectedList?.p_list_id : "",
                  //       p_list_title: listName,
                  //       p_list_description: listDescription,
                  //       p_internal_flag: "",
                  //     }
                  //   );

                  //   if (res.returnCode === true) {
                  //     dispatch(
                  //       mylistPageActions?.TOGGLE_CREATE_LIST({
                  //         showAddNewMyList: false,
                  //       })
                  //     );
                  //     if (onClose) onClose(res?.returnData[0]);

                  //     setIsAlert({
                  //       istrue: true,
                  //       type: "success",
                  //       msg: "Succesfully added list!",
                  //     });
                  //     if (editMode) {
                  //       dispatch(
                  //         mylistPageActions?.UPDATE_LIST({
                  //           p_list_id: selectedList?.p_list_id,
                  //           newList: res?.returnData[0],
                  //         })
                  //       );
                  //     } else
                  //       dispatch(
                  //         mylistPageActions?.ADD_NEW_LIST({
                  //           newList: res?.returnData[0],
                  //         })
                  //       );
                  //   } else {
                  //     setIsAlert({
                  //       istrue: true,
                  //       type: "error",
                  //       msg: "Error while creating list",
                  //     });
                  //   }
                  // })();
                }
                setIsAlert({
                  isTrue: false,
                  type: "",
                  msg: "",
                });
              }}
            >
              {editMode
                ? btnConfig?.mylist_save_list
                : btnConfig?.mylist_create_list}

              {/* <img src={CloseIcon} alt="Cross icon" draggable="false" /> */}
            </Button>
          </div>
        </div>
      </div>
    </SignUpVendorContainer>
      );
};

export default AddNewList;
