import s from "./index.module.css";
import Rx from "./Rx";
import ConsentForm from "../../../../components/ConsentForm";
import PatientDetails from "./PatientDetails";
import "rc-datepicker/lib/style.css";

const TransferRxForm = (props: any) => {
  const {
    activeStep,
    pharmacyType,
    setPharmacyType,
    setPharmacyId,
    setAddPharmacyAddress,
    addPharmacyAddress,
    queryStatus,
    setActiveStep,
    setIsSigned,
    setImageURL,
    setConsentFullName,
    consentFullName,
    pharmacyList,
    setPharmacyList,
    addPharmacy,
    control,
    register,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
    healthDetails,
    setHealthDetails,
    setUploadedDocumentIDFront,
    uploadedDocumentIDFront,
    uploadedDocumentIDBack,
    setUploadedDocumentIDBack,
    onSubmitHealthCardHandler,
    dob,
    setdob,
    dobErrorMsg,
    setDobErrorMsg,
    rxContext,
    setRxContext,
  } = props;

  const getRxForm = () => {
    switch (queryStatus) {
      case "submit":
        setActiveStep(0);
        return (
          <Rx
            activeStep={activeStep}
            pharmacyType={pharmacyType}
            setPharmacyType={setPharmacyType}
            setPharmacyId={setPharmacyId}
            setAddPharmacyAddress={setAddPharmacyAddress}
            addPharmacyAddress={addPharmacyAddress}
            rxContext={rxContext}
            setRxContext={setRxContext}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
          />
        );
      case "patient-details":
        setActiveStep(1);
        return (
          <PatientDetails
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setHealthDetails={setHealthDetails}
            healthDetails={healthDetails}
            setUploadedDocumentIDFront={setUploadedDocumentIDFront}
            uploadedDocumentIDFront={uploadedDocumentIDFront}
            setUploadedDocumentIDBack={setUploadedDocumentIDBack}
            uploadedDocumentIDBack={uploadedDocumentIDBack}
            onSubmitHealthCardHandler={onSubmitHealthCardHandler}
            dob={dob}
            setdob={setdob}
            dobErrorMsg={dobErrorMsg}
            setDobErrorMsg={setDobErrorMsg}
          />
        );

      case "consent":
        setActiveStep(2);
        return (
          <ConsentForm
            headerStyle={{ textAlign: "left" }}
            setIsSigned={setIsSigned}
            setImageURL={setImageURL}
            setConsentFullName={setConsentFullName}
            consentFullName={consentFullName}
            watch={watch}
          />
        );

      default:
        break;
    }
  };

  return <div className={s?.pageContainer}>{getRxForm()}</div>;
};

export default TransferRxForm;
