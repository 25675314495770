import React, { useState, useRef, useContext, useEffect } from "react";
import Input from "../../../components/UI/Input";
import PhoneNumber from "../../../components/PhoneNumber";
import Label from "../../../components/UI/Label";
import styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";
import { RegistrationFormFields } from "../EnterPhoneNumber";
import { useForm } from "react-hook-form";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import Button from "../../../components/UI/Button";
import btnConfig from "../../../config/button";
import { FormInput } from "../../../components/UI/FormInput";
import { msg_config } from "../../../config/messages";
import { PhoneFormInput } from "../../../components/UI/PhoneFormInput";
import { Phone } from "../../../components/UI/Phone";
import { useDispatch, useSelector } from "react-redux";
import { signUpVendorActions, signUpVendorReducer } from "../slice/slice";
import { store } from "../../../store/store";
import { config } from "../../../config/processId";
import { CallAPI } from "../../../suggest-us/callSuggest";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { clearSession, getDecryptedID, objEncrypt, removeKey, setEncryptedID } from "../../../utils/utils";
import AppLogoPro from "../../../assets/pro_app_logo.png";
import { useNavigate } from "react-router-dom";
import { CART_COUNT, DEFAULT_FLAG, FIRST_TIME_LOGIN, HEALTH_BACK_ID, HEALTH_CARD_FRONT_ID, HEALTH_INFO, INSURANCE_BACK_ID, INSURANCE_FRONT_ID, IS_DOCS_PENDING, IS_EDITING, IS_INSURANCE, LOGGED_IN_USER_ROLE, PATIENT_ID, PROFILE_IMAGE, PROFILE_IMAGE2, QB_ID, SG_PATIENT_ID, SG_ROLE_ID, SG_USER_ID, SIGN_IN_WITH, USER_ID, USER_LOGGED_IN, USER_PROFILE, USER_ROLE_ID } from "../../../config/Config";
const CreateAccount = (props: any) => {
  const { isVendor,setOpenCreateCompanyCard , userInfohandler} = props;
  const [phoneNumber, setPhoneNumber] = useState();
  const formData = useSelector((state: any) => state?.signUpVendorReducer);
  const [context, setContext] = useContext(UserAuthContext);
  const isEditing = context?.isEditProProfile; //boolean
  let navigate = useNavigate();
  const handleChange = (e: any, fieldName: any) => {
    try {
      let phone = "";
      let name = "";
      if (e?.target) {
        phone = e.target.value;
        name = e.target.name;
      } else {
        phone = e;
        name = fieldName;
      }
      dispatch(
        // @ts-ignore
        signUpVendorActions?.updateFormField({
          name: name || "",
          value: phone || "",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const filledPhoneNumber = context?.UserAuth?.usr_phone || "";
  const filledPhoneNumberalternate = context?.UserAuth?.usr_company_alternate_phone || "";
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    //@ts-ignore
    defaultValues: { p_company_landline_no: filledPhoneNumber,
      p_company_alternate_phone :  filledPhoneNumberalternate},
  });
  dispatch(
    signUpVendorActions?.updateFormField({
      name: "p_company_landline_no",
      value: filledPhoneNumber,
    })
  );
  const { setIsAlert } = props;
  const onCreateAccountSubmit = (data: any) => {
    try {
      setLoading(true);
    const {
      p_company_alternate_phone,
      p_company_landline_no,
      p_company_email,
      p_company_alternate_email,
    } = data;
    if (p_company_alternate_phone === p_company_landline_no) {
      setIsAlert({
        istrue: true,
        type: "error",
        msg: "Primary and alternate contacts can't be same.",
      });
    } else if (p_company_email === p_company_alternate_email) {
      setIsAlert({
        istrue: true,
        type: "error",
        msg: "Primary and alternate emails can't be same.",
      });
    } else {
      const state = store.getState()?.signUpVendorReducer;
      const payload = {
        ...state,
        p_org_id: context?.UserAuth?.org_id,
        p_user_id: context?.UserAuth?.usr_id,
      };
      let userAuth = context?.UserAuth;
      if (props?.handleNext) {
        //getting user auth object from the context
        userAuth = {
          ...userAuth,
          usr_company_email: data?.p_company_email,
          usr_company_alternate_email: data?.p_company_alternate_email,
          usr_company_alternate_phone: data?.p_company_alternate_phone,
          usr_company_gstin_no: data?.p_company_gstin_no,
          usr_company_landline_no: data?.p_company_landline_no,
          usr_company_size: data?.p_company_size,
          usr_name: data?.p_first_name + " " + data?.p_last_name,
        };
        // setContext((prev:any)=>{
        //   return {...prev, UserAuth :userAuth}
        // })
        props?.handleNext();
      }
      (async () => {
        const res = await CallAPI(config?.onboard_pharmacy_process_id, payload);
        if (res) setLoading(false);
        if(res?.returnCode){
          setContext((prev: any) => ({
            ...prev,
            UserAuth : res?.returnData[0],
          }));
          userInfohandler && userInfohandler();
          setEncryptedID(USER_PROFILE,res?.returnData[0]);
          if(  getDecryptedID(IS_EDITING) === undefined
           || getDecryptedID(IS_EDITING) === null  ||
             getDecryptedID(IS_EDITING) === "" ||
             getDecryptedID(IS_EDITING) === "N"
             ){
            setEncryptedID(IS_DOCS_PENDING, "Y");
          }
          setEncryptedID(FIRST_TIME_LOGIN, "N");
          setEncryptedID(IS_EDITING , "N")
        }else {
            console.error(res?.msg);
        }
      })();
    }
    } catch (error) {
      console.error(error);
    }
    
  };
  const validateConfirmEmail = (value: any, confirmEmailValue: any) => {
    if (!value) {
      return "Confirm Email is required";
    }
    if (value === confirmEmailValue) {
      return "Email and Alternate must not be same";
    }
    return "";
  };
  const phoneInputRef = useRef();
  const alternatePhoneInputRef = useRef();
  return (
    <>
      <div className={`${styles?.container}`}>
        <div className={`${styles.createaccount_logo}`}>
          <img src={AppLogoPro} />
          {getDecryptedID(IS_EDITING) === "Y" ? "" :<p
            className={styles?.logoutLink}
            onClick={() => {
              navigate("/");
              setContext((prev: any) => ({
                ...prev,
                UserAuth: {},
              }));
              // localStorage.clear();
              removeKey(USER_PROFILE);
              removeKey(SG_ROLE_ID);
              removeKey(SG_USER_ID);
              removeKey(SG_PATIENT_ID);
              removeKey(IS_INSURANCE);
              removeKey(PROFILE_IMAGE);
              removeKey(PROFILE_IMAGE2);
              removeKey(USER_ID);
              removeKey(QB_ID);
              removeKey(USER_ROLE_ID);
              removeKey(DEFAULT_FLAG);
              removeKey(PATIENT_ID);
              removeKey(CART_COUNT);
              removeKey(INSURANCE_FRONT_ID);
              removeKey(INSURANCE_BACK_ID);
              removeKey(HEALTH_CARD_FRONT_ID);
              removeKey(HEALTH_BACK_ID);
              removeKey(HEALTH_INFO);
              clearSession();
              setEncryptedID(USER_LOGGED_IN, "N");
              setEncryptedID(SIGN_IN_WITH, " ");
              // setGlobalContext((prev) => {
              //   return {
              //     ...prev,
              //     profile_image: "",
              //   };
              // });
              props?.setOpenCreateCompanyCard(false);
              window.location.reload();
            }}
          >
            {Config?.lbl_logout}
          </p>}
        </div>
        <form onSubmit={handleSubmit(onCreateAccountSubmit)}>
        {getDecryptedID(IS_EDITING) === "Y" ? 
         <div className={`${styles?.form_control}`}>
         <div className={`${styles?.form_inner_control}`}>
         <Label
             className={`${styles?.form_label}`}
             for="p_legal_company_name"
           >
             {Config?.lbl_company_legal_name}
           </Label>
           <FormInput
             name="p_legal_company_name"
             id="p_legal_company_name"
             type={Config?.lbl_Inp_type_text}
             placeholder={"Company Name"}
             size={Config?.lbl_Inp_size_small}
             register={register}
             value={formData?.p_legal_company_name}
             rules={{
               required: msg_config?.trnRx_pharmacy_required,
             }}
             errors={errors}
             maxLength={100}
             onChange={(e: any) => {
               dispatch(
                 // @ts-ignore
                 signUpVendorActions?.updateFormField({
                   name: e.target.name,
                   value: e.target.value,
                 })
               );
               //getting all values from the context
               let userAuth = context?.UserAuth;
               //@ts-ignore
               //updating context object named UserAuth
               if (isEditing) {
                 userAuth = { ...userAuth, usr_company_name: e.target.value };
                 // setContext((prev : any)=>{
                 //   return {...prev, UserAuth :userAuth}
                 // })
               }
             }}
           />
           {/* <Input
               name="firstName"
               id="firstName"
               type={Config?.lbl_Inp_type_text}
               placeholder={Config?.inp_P_Holder_First_N}
               size={Config?.lbl_Inp_size_medium}
             /> */}
         </div>
         <div className={`${styles?.form_inner_control}`}>
         <Label
             className={`${styles?.form_label}`}
             for="p_company_postal_code"
           >
             {Config?.lbl_Postal_C}
           </Label>
           <FormInput
             name="p_company_postal_code"
             id="p_company_postal_code"
             type={Config?.lbl_Inp_type_text}
             placeholder={"Postal Code"}
             size={Config?.lbl_Inp_size_small}
             register={register}
             value={formData?.p_company_postal_code}
             rules={{
               required: msg_config?.trnRx_pharmacy_postal_code_required,
               pattern: {
                 value: /^\d{6}$/,
                 message: "Please enter a valid 6 digit postal code.",
               },
             }}
             errors={errors}
             // minLength={6}
             maxLength={6}
             onChange={(e: any) => {
               dispatch(
                 // @ts-ignore
                 signUpVendorActions?.updateFormField({
                   name: e.target.name,
                   value: e.target.value,
                 })
               );
               let userAuth = context?.UserAuth;
               if (isEditing) {
                 userAuth = { ...userAuth, usr_company_postal: e.target.value };
                 // setContext((prev : any)=>{
                 //   return {...prev, UserAuth :userAuth}
                 // })
               }
             }}
           />
         </div>
       </div> :""}
       
          <div className={`${styles?.form_control}`}>
            <div className={`${styles?.form_inner_control}`}>
              <Label className={`${styles?.form_label}`} for="p_first_name">
                {Config?.lbl_First_N}
              </Label>
              <FormInput
                name="p_first_name"
                id="p_first_name"
                type={Config?.lbl_Inp_type_text}
                placeholder={"First name"}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_first_name}
                rules={{
                  required: msg_config?.trnRx_firstName_required,
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Please enter valid First name.",
                  },
                }}
                errors={errors}
                maxLength={100}
                onChange={handleChange}
                inputAutoFocus ={true}
              />
              {/* <Input
                  name="firstName"
                  id="firstName"
                  type={Config?.lbl_Inp_type_text}
                  placeholder={Config?.inp_P_Holder_First_N}
                  size={Config?.lbl_Inp_size_medium}
                /> */}
            </div>
            <div className={`${styles?.form_inner_control}`}>
              <Label className={`${styles?.form_label}`} for="p_last_name">
                {Config?.lbl_Last_N}
              </Label>
              <FormInput
                name="p_last_name"
                id="p_last_name"
                type={Config?.lbl_Inp_type_text}
                placeholder={"Last name"}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_last_name}
                rules={{
                  required: msg_config?.trnRx_lastName_required,
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Please enter valid Last name.",
                  },
                }}
                errors={errors}
                maxLength={100}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={`${styles?.form_control}`}>
            <div className={`${styles?.form_inner_control}`}>
              <Label className={`${styles?.form_label}`} for="p_company_email">
                {Config?.lbl_Email}
              </Label>
              <FormInput
                name="p_company_email"
                id="p_company_email"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_Email}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_company_email}
                rules={{
                  required: msg_config?.trnRx_email_required,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter valid email address.",
                  },
                }}
                errors={errors}
                maxLength={100}
                onChange={handleChange}
              />
            </div>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_company_alternate_email"
              >
                {Config?.lbl_Alternate_Email}
              </Label>
              <FormInput
                name="p_company_alternate_email"
                id="p_company_alternate_email"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_Email}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_company_alternate_email}
                rules={{
                  required: msg_config?.trnRx_alternateEmail_required,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter valid email address.",
                  },
                  // validate: (value) =>
                  //   validateConfirmEmail(
                  //     value,
                  //     // @ts-ignore
                  //     register("p_company_email").value
                  //   ),
                }}
                errors={errors}
                maxLength={100}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={`${styles?.form_control}`}>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_company_landline_no"
              >
                {Config?.lbl_Phone}
              </Label>
              <PhoneFormInput
                name="p_company_landline_no"
                register={register}
                rules={{
                  required: "Please enter valid landline number",
                  pattern: {
                    value: /^(?:\+?91)?[\s\d]{10}$/,
                    message: "Please enter valid landline number",
                  },
                }}
                // @ts-ignore
                errors={errors}
                control={control}
                placeholder={Config?.inp_P_Holder_Login_number}
                phoneInputRef={phoneInputRef}
                setError={setError}
                clearErrors={clearErrors}
                value={formData?.p_company_landline_no}
                OnChangePhoneNumber={handleChange}
                minLength={10}
              />
            </div>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_company_alternate_phone"
              >
                {Config?.lbl_Alternate_Phone}
              </Label>
              <PhoneFormInput
                name="p_company_alternate_phone"
                // @ts-ignore
                register={register}
                rules={{
                  required: "Please enter valid landline number",
                  pattern: {
                    value: /^(?:\+?91)?[\s\d]{10}$/,
                    message: "Please enter valid landline number",
                  },
                }}
                // @ts-ignore
                errors={errors}
                control={control}
                placeholder={Config?.inp_P_Holder_Login_number}
                // maxLength={10}
                setError={setError}
                clearErrors={clearErrors}
                phoneInputRef={alternatePhoneInputRef}
                // value={formData?.p_company_alternate_phone}
                value={formData?.p_company_alternate_phone}
                OnChangePhoneNumber={handleChange}
                minLength={10}
              />
              {/* <PhoneNumber
                  // name="phone"
                  // id="phone"
                  // type="number"
                  placeholder={Config?.inp_P_Holder_Phone}
                  phoneNumber={phoneNumber}
                  onChange={setPhoneNumber}
                  // size={Config?.lbl_Inp_size_medium}
                /> */}
            </div>
          </div>
          <div className={`${styles?.form_control}`}>
            <div className={`${styles?.form_inner_control}`}>
              <Label className={`${styles?.form_label}`} for="p_company_size">
                {Config?.lbl_Company_Size}
              </Label>
              <FormInput
                name="p_company_size"
                id="p_company_size"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_Company_Size}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_company_size}
                rules={{
                  pattern: {
                    value: /^(?!0+$)\d+$/,
                    message: "Company size cannot be 0.",
                  },
                  required: msg_config?.trnRx_pharmacy_company_size_required,
                }}
                errors={errors}
                maxLength={10}
                onChange={handleChange}
              />
              {/* <SelectDropdown
                  name="company-size"
                  options={[
                    {
                      value: "20-49",
                      label: "20-49",
                    },
                    {
                      value: "100-250",
                      label: "100-250",
                    },
                    {
                      value: "500-1000",
                      label: "500-1000",
                    },
                    // "500-1000",
                    ,
                  ]}
                  size="small"
                  placeholder={Config?.inp_P_Holder_Company_Size}
                  // loading={loading}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                  onSelectChange={() => {}}
                /> */}
              {/* <Input
                name="company-size"
                id="company-size"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_Company_Size}
                size={Config?.lbl_Inp_size_medium}
              /> */}
            </div>
            <div className={`${styles?.form_inner_control}`}>
              <Label
                className={`${styles?.form_label}`}
                for="p_company_gstin_no"
              >
                {Config?.lbl_GSTIN}
              </Label>
              <FormInput
                name="p_company_gstin_no"
                id="p_company_gstin_no"
                type={Config?.lbl_Inp_type_text}
                placeholder={Config?.inp_P_Holder_GSTIN}
                size={Config?.lbl_Inp_size_small}
                register={register}
                value={formData?.p_company_gstin_no}
                rules={{
                  required: msg_config?.trnRx_pharmacy_GSTIN_required,
                  pattern: {
                    value:
                      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z0-9]{2})$/,
                    message: "Please enter a valid GST number.",
                  },
                }}
                errors={errors}
                maxLength={15}
                minLength={15}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.footerBtn}>
          {getDecryptedID(IS_EDITING) === "Y" ? <Button
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => {
                setOpenCreateCompanyCard(false)
                setEncryptedID(IS_EDITING, "N");
      
              }}
              htmlType="button"
              className={`${styles?.ghostBtn} ${styles?.backBtn}`}
            >
              {btnConfig?. signUp_close_button}
            </Button>: 
            <Button
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => {
                // props?.setOpenCreateCompanyCard(false);
                // props?.setOpenCompanyProfileCard(true);
                // let userAuth = context?.UserAuth;
                // userAuth = {...userAuth, usr_company_name : null}
                // setContext((prev:any)=>{
                //   return {...prev, UserAuth : userAuth}
                // })
                props?.handleBack();
                if(context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE){
                  setContext((prev:any)=>{
                    return {...prev, isEditProProfile : true}
                  })
                }
              }}
              htmlType="button"
              className={`${styles?.ghostBtn} ${styles?.backBtn}`}
            >
              {btnConfig?.signUp_Ghost_Text_Back}
            </Button>}

            {getDecryptedID(IS_EDITING) === "Y" ? 
            <Button
              loading={loading}
              type={Config?.lbl_Btn_type_primary}
              htmlType={"submit"}
              size={Config?.lbl_Btn_size_medium}
            >
              {btnConfig?.signUp_Primary_Text_Update}
            </Button> : 
            <Button
            loading={loading}
            type={Config?.lbl_Btn_type_primary}
            onClick={() => {
              setIsAlert({
                istrue: false,
                type: "",
                msg: "",
              });
            }}
            htmlType={"submit"}
            size={Config?.lbl_Btn_size_medium}
          >
            {btnConfig?.signUp_Primary_Text_Continue}
          </Button> 
}
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAccount;
