import React from "react";
import s from "./index.module.css";

// Heading 1
// *****************************************************
export type TypoProps = {
  style?: object;
  className?: string;
  onClick?: any;
};

export const H1: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <h2 className={`${className}`} style={{ ...style }}>
      {props.children}
    </h2>
  );
};

// Heading 2
// *****************************************************

export const H2: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <h2 className={`${s._h2} ${className}`} style={{ ...style }}>
      {props.children}
    </h2>
  );
};

// Heading 3
// *****************************************************

export const H3: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <h3 className={`${s._h3} ${className}`} style={{ ...style }}>
      {props.children}
    </h3>
  );
};

// Heading 4
// *****************************************************

export const H4: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <h4 className={`${s._h4} ${className}`} style={{ ...style }}>
      {props.children}
    </h4>
  );
};

// Heading 5
// *****************************************************

export const H5: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <h5 className={`${s._h5} ${className}`} style={{ ...style }}>
      {props.children}
    </h5>
  );
};

// Heading 6
// *****************************************************

export const H6: React.FC<TypoProps> = (props) => {
  const { style, className, onClick } = props;

  return (
    <h6
      className={`${s._h6} ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      {props.children}
    </h6>
  );
};

// Paragraph
// *****************************************************

export const Paragraph: React.FC<TypoProps> = (props) => {
  const { style, className } = props;

  return (
    <p className={`${s._p} ${className}`} style={{ ...style }}>
      {props.children}
    </p>
  );
};
