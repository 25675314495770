import Styles from "./index.module.css";
import Heading from "../../../../../components/UI/Heading";

function RxTypes({ type, title, icon, discription, backgroundColor }: any) {
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className={Styles[`container-${type}`]}
    >
      <div className={Styles["rxSubContainerLeft"]}>
        <img src={icon} alt={title} draggable="false" />
      </div>
      <div className={Styles[`rxSubContainerRight`]}>
        <Heading className={Styles[`rxSubContainerRight-${type}`]}>
          {title}
        </Heading>
        {discription}
      </div>
    </div>
  );
}

export default RxTypes;
