import React, { FC } from "react";

export type FormErrorMessageProps = {
  className?: string;
};

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  children,
  className,
}) => (
  <p className={className} style={{ color: "#f00" }}>
    {children}
  </p>
);
