import React, { useContext, useEffect, useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Slider from "react-slick";
import { config } from "../../../config/processId.js";
import { color_config } from "../../../config/colorConfig.js";
import APIContext from "../../../store/api-context";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { ChevronLeft, ChevronRight } from "../../../utils/appIcons";
import RelatedProductCards from "../../RelatedProductCards";
import AddtocartPopup from "../AddtocartPopup";
import styles from "./index.module.css";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import Alert from "../../UI/Alert/index.js";
import { siteConfig } from "../../../config/site";
import {
  JSONCheckerFunc,
  getDecryptedID,
  getID,
  objDecrypt,
  setEncryptedID,
} from "../../../utils/utils";
import ProductCard from "../../ProductCard";
import Config from "../../../config/label.js";
import {
  CART_COUNT,
  SG_PATIENT_ID,
  SG_USER_ID,
  USER_PROFILE,
} from "../../../config/Config.js";
import { decryptData } from "../../../utils/util_helper.jsx";
import { useParams } from "react-router";

export type productProps = {
  setIsPopUPOpen?: any;
  setIsAlert?: any;
  getRelatedProducts?: any;
  isRelatedProductLoading?: any;
  setIsRelatedProductLoading?: any;
  productList?: any;
  setProductList?: any;
  getProductDetails?: any;
  setMainProductDetails?: any;
  mainProductdetails?: any;
};

const RelatedProducts: React.FC<productProps> = (props) => {
  // const [productList, setProductList] = useState<any>([]);
  const [suggestUsInit] = useContext(APIContext);
  // const [isloading, setIsLoading] = useState(true);
  const [context, setContext] = useContext(UserAuthContext);
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [productdetails, setProductDetails] = useState<any>("");
  const [loading, setLoading] = useState({
    isTrue: false,
    productId: "",
  });
  const {
    setIsPopUPOpen,
    setIsAlert,
    getRelatedProducts,
    isRelatedProductLoading,
    setIsRelatedProductLoading,
    productList,
    setProductList,
    getProductDetails,
    setMainProductDetails,
    mainProductdetails,
  }: any = props;
  const gaEventTracker = useAnalyticsEventTracker("Home page");
  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };

  let isUserLoggedIn = sessionStorageCopy&&  Object.keys(sessionStorageCopy)?.length > 0 ? true : false;
  const { prodID } = useParams();

  useEffect(() => {
    // if (suggestUsInit?.returnCode === true) {
    //   setIsLoading(true);
    //   CallAPI(config?.related_product_list_process_id, {
    //     p_srv_id: "1",
    //     p_max_offset: "12",
    //   }).then((res) => {
    //     if (res?.returnCode) {
    //       setProductList(res?.returnData);
    //     }
    //     setIsLoading(false);
    //   });
    // }
    getRelatedProducts();
    window.scrollTo(0, 0);
  }, [suggestUsInit, prodID]);

  const wishListDeleteHandler = (product: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        // p_cus_id: sessionStorageCopy?.customer_id,
        p_wshlst_id: product?.wshlst_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          // getProductDetails();
          gaEventTracker("Items removed from wishlist");
          setProductList(
            productList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return { ...item, wshlst_status: "N" };
              }
            })
          );

          if (mainProductdetails?.srv_id === product?.srv_id) {
            setMainProductDetails((prev: any) => {
              return { ...prev, wshlst_status: "N" };
            });
          }
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
        }
      });
    }
  };

  const wishListAddHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_add_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_cus_id: decryptData(getID(SG_PATIENT_ID)),
        p_srv_id: product?.srv_id,
      }).then((res: any) => {
        if (res?.returnCode) {
          // getProductDetails();
          gaEventTracker("Items added to wishlist");
          setProductList(
            productList?.map((item: any) => {
              if (item?.srv_id !== product?.srv_id) {
                return { ...item };
              } else {
                return {
                  ...item,
                  wshlst_status: "Y",
                  wshlst_id: res?.returnData[0]?.wshlst_id,
                };
              }
            })
          );

          if (mainProductdetails?.srv_id === product?.srv_id) {
            setMainProductDetails((prev: any) => {
              return {
                ...prev,
                wshlst_status: "Y",
                wshlst_id: res?.returnData[0]?.wshlst_id,
              };
            });
          }
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
        }
      });
    }
  };

  const likeButtonHandler = (product: any) => {
    switch (product?.wshlst_status) {
      case "Y":
        wishListDeleteHandler(product);
        break;

      default:
        wishListAddHandler(product);
        break;
    }
  };

  const addCartHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      setLoading({
        isTrue: true,
        productId: product?.srv_id,
      });
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res: any) => {
        if (res?.returnCode) {
          setEncryptedID(CART_COUNT, res?.returnData[0].cart_item_count);
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          setLoading({
            isTrue: false,
            productId: "",
          });
          setIsAlert({
            istrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
          setLoading({
            isTrue: false,
            productId: "",
          });
        }
      });
    }
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.arrow} ${className} ${styles?.next_arrow}`}
        style={{
          ...style,
          display: "grid",
          justifyContent: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.arrow} ${className} ${styles?.prev_arrow}`}
        style={{
          ...style,
          display: "grid",
          justifyContent: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1660,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`${styles?.relatedProductsMain}`}>
      <div className="our_product_main">
        <div className={`${styles?.upperPart}`}>
          <h2 className={`${styles?.header}`}>{Config?.relatedProducts}</h2>
        </div>
        <div className={`${styles?.wrapper}`}>
          <Slider {...settings}>
            {isRelatedProductLoading
              ? [1, 2, 3, 4]?.map((item) => {
                  return (
                    <div className={`${styles?.shim_wrap}`} key={item}>
                      <ShimmerThumbnail height={250} rounded />
                    </div>
                  );
                })
              : productList?.map((product: any, idx: any) => (
                  <ProductCard
                    pricingData={JSONCheckerFunc(product?.packsize_json) || []}
                    product={product}
                    key={idx}
                    type={product?.service_type}
                    // productUrl={"/product-detail/" + product?.srv_id}
                    productUrl={product?.srv_id}
                    imgUrl={product?.srv_image.split("~")[0]}
                    imgAlt={product?.srv_name}
                    detail={product?.srv_name}
                    packagingId={product?.srv_packaging}
                    brand={product?.srv_brand}
                    price={product?.discounted_amount}
                    actualPrice={product?.srv_price}
                    discountPercent={product?.discount_percent}
                    coPay={product?.copayment_amount}
                    loading={loading}
                    ratingCount={
                      product?.rating_view_flag === "Y" &&
                      product?.rating_star_count
                    }
                    isWishListAdded={
                      product?.wshlst_status === "Y" ? true : false
                    }
                    ribbon={true}
                    ribbonType="folded"
                    onLikeButtonClick={() => {
                      if (isUserLoggedIn) {
                        likeButtonHandler(product);
                      } else {
                        setIsPopUPOpen(true);
                      }
                    }}
                    onAddtoCartButtonClick={() => {
                      if (isUserLoggedIn) {
                        addCartHandler(product);
                      } else {
                        setIsPopUPOpen(true);
                      }
                    }}
                    productType={product?.salt_composition} //TABLE || SYRUP || CAPSULE || BOTTLE
                  />
                ))}
          </Slider>
        </div>
      </div>

      {/* Added to cart pop up */}
      {isCartPopUpOpen && (
        <AddtocartPopup
          img={productdetails?.srv_image.split("~")[0]}
          title={productdetails?.srv_name + productdetails?.srv_packaging}
          setModalOpen={setIsCartPopUPOpen}
        />
      )}
    </div>
  );
};

export default RelatedProducts;
