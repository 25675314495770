import React, { useContext, useEffect, useState } from "react";
import Style from "./index.module.css";
import SideBar from "../../../../../../../components/ProfileSideBar/SideBar";
import { SettingsIcon } from "../../../../../../../utils/appIcons";
import Config from "../../../../../../../config/label";
import Button from "../../../../../../../components/UI/Button";
import FileUpload from "../../../../../../../components/UI/FileUpload";
import { DeleteIcon } from "../../../../../../../utils/appIcons";
import Alert from "../../../../../../../components/UI/Alert";
import APIContext from "../../../../../../../store/api-context";
import { siteConfig } from "../../../../../../../config/site";
import { config } from "../../../../../../../config/processId";
import { CallAPI } from "../../../../../../../suggest-us/callSuggest";
import axios from "axios";
import HealthCardImage from "../../../../../../../assets/png/HealthCard.png";
import { Controller, useForm } from "react-hook-form";
import { FormInput } from "../../../../../../../components/UI/FormInput";
import { msg_config } from "../../../../../../../config/messages";
import { DatePickerInput } from "rc-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../../../../../../../components/UI/FormErrorMessage";
import moment from "moment";
import Label from "../../../../../../../components/UI/Label";
import { ShimmerTitle } from "react-shimmer-effects";
import IconButton from "../../../../../../../components/UI/IconButton";
import Editaddress_icon from "../../../../../../../assets/png/icons-edit.png";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../../../../../utils/utils";
import { UserAuthContext } from "../../../../../../../store/UserAuthContext.js";
import { USER_PROFILE } from "../../../../../../../config/Config";

export type RegistrationFormFields = {
  card_num: string;
  issue_date: string;
  exp_date: string;
};

const AddHealthCard = (props: any) => {
  const {
    register,
    errors,
    watch,
    setHealthDetails,
    healthDetails,
    setUploadedDocumentIDFront,
    uploadedDocumentIDFront,
    uploadedDocumentIDBack,
    setUploadedDocumentIDBack,
    // onSubmitHealthCardHandler,
  } = props;

  const [frontImage, setFrontImage] = useState<any>([]);

  const [backImage, setBackImage] = useState<any>([]);

  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);
  // const [uploadedDocumentIDFront, setUploadedDocumentIDFront] =
  //   useState<any>(null);
  // const [uploadedDocumentIDBack, setUploadedDocumentIDBack] =
  //   useState<any>(null);

  const [getLoading, setGetLoading] = useState(false);
  const [frontImgLoading, setFrontImageLoading] = useState<any>(false);
  const [backImgLoading, setBackImageLoading] = useState<any>(false);
  const [context, setContext] = useContext(UserAuthContext);

  const exp_dateWatch = watch("exp_date");
  const [update, setUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const [alert, setAlert] = useState(false);
  const [cardNoVal, setCardNoVal] = useState("");

  const apiContext = useContext(APIContext);
  const [suggestUsInit] = apiContext;
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // let userProfileJson = JSONCheckerFunc(userProfile);

  const uploadFileDataAxios = (data: any) => {
    return axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const upLoadDocumentFront = async (filename: any) => {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: frontImage[0]?.data?.name?.split(".")[0],
        p_doc_remarks: "",
        p_doctype_code: config?.health_card_front_image_p_doctype_code,
        p_trnt_code: config?.health_card_front_image_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: config?.health_card_front_image_p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  };

  const upLoadDocumentBack = async (filename: any) => {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: userProfileJson?.patient_id,
        p_usr_id: userProfileJson?.usr_id,
        p_doc_name: backImage[0]?.data?.name?.split(".")[0],
        p_doc_remarks: "",
        p_doctype_code: config?.health_card_back_image_p_doctype_code,
        p_trnt_code: config?.health_card_back_image_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: config?.health_card_back_image_p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  };

  const onSubmitHealthCardHandler = (data: any, frontId: any, backId: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.add_health_card_process_id, {
        p_id:
          healthDetails?.healthcard_ref_id !== ""
            ? healthDetails?.healthcard_ref_id
            : "",
        p_user_id: _user_profile?.usr_id,
        p_customer_id: _user_profile?.customer_id,
        p_healthcard_no: data?.card_num || "",
        p_healthcard_issue_date:
          data?.issue_date !== undefined
            ? moment(data?.issue_date).format("YYYY-MM-DD")
            : "",
        p_healthcard_expiry_date:
          data?.exp_date !== undefined
            ? moment(data?.exp_date).format("YYYY-MM-DD")
            : "",
        p_healthcard_front_image_document_id: frontId || "",
        p_healthcard_back_image_document_id: backId || "",
        p_internal_flag: "",
      });
    }
  };

  const callUploadDocumentFront = async (urlData: any) => {
    var upLoadDocumentIDFront: number[] = await Promise.all(
      urlData.map(async (items: any): Promise<number> => {
        return await upLoadDocumentFront(items);
      })
    );
    if (
      Array?.isArray(upLoadDocumentIDFront) &&
      upLoadDocumentIDFront?.length > 0
    ) {
      // if (uploadedDocumentIDFront?.[0]?.returnCode === true) {
      setUploadedDocumentIDFront(upLoadDocumentIDFront);
      setFrontImageLoading(false);

      setHealthDetails((prev: any) => {
        return {
          ...prev,
          front_url: frontImage[0]?.uri || "",
          front_img_id:
            // @ts-ignore
            upLoadDocumentIDFront?.[0]?.returnData[0]?.document_id || "",
        };
      });

      setContext((prev: any) => ({
        ...prev,

        healthCardFrontId:
          // @ts-ignore
          upLoadDocumentIDFront?.[0]?.returnData[0]?.document_id || "",
        healthCardFrontUrl: frontImage[0]?.uri || "",
        healthCardBackId: healthDetails?.back_img_id || "",
        healthCardBackurl: healthDetails?.back_url || "",
        healthcard_ref_id:
          healthDetails?.healthcard_ref_id !== ""
            ? healthDetails?.healthcard_ref_id
            : "",
      }));

      // onSubmitHealthCardHandler(
      //   "",
      //   //@ts-ignore
      //   upLoadDocumentIDFront?.[0]?.returnData[0]?.document_id || "",
      //   uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id || ""
      // );
      // }
    }
  };

  const callUploadDocumentBack = async (urlData: any) => {
    var upLoadDocumentIDBack: number[] = await Promise.all(
      urlData.map(async (items: any): Promise<number> => {
        return await upLoadDocumentBack(items);
      })
    );
    if (
      Array?.isArray(upLoadDocumentIDBack) &&
      upLoadDocumentIDBack?.length > 0
    ) {
      //@ts-ignore
      // if (upLoadDocumentIDBack?.[0]?.returnCode === true) {
      setUploadedDocumentIDBack(upLoadDocumentIDBack);
      setBackImageLoading(false);

      setHealthDetails((prev: any) => {
        return {
          ...prev,
          back_url: backImage[0]?.uri || "",

          back_img_id:
            //@ts-ignore
            upLoadDocumentIDBack?.[0]?.returnData[0]?.document_id || "",
        };
      });

      setContext((prev: any) => ({
        ...prev,

        healthCardFrontId:
          // @ts-ignore
          healthDetails?.front_img_id || "",
        healthCardFrontUrl: healthDetails?.front_url || "",
        healthCardBackId:
          // @ts-ignore
          upLoadDocumentIDBack?.[0]?.returnData[0]?.document_id || "",
        healthCardBackurl: backImage[0]?.uri || "",
        healthcard_ref_id:
          healthDetails?.healthcard_ref_id !== ""
            ? healthDetails?.healthcard_ref_id
            : "",
      }));
      // onSubmitHealthCardHandler();
      // }
    }
  };

  useEffect(() => {
    if (frontImage.length > 0) {
      setFrontImageLoading(true);
      let urlData: any;
      Promise.all(
        frontImage?.map((items: any) => {
          let formData = new FormData();
          formData.append("uploadedFile", items?.data);
          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];
          callUploadDocumentFront(urlData);
        })
        .catch((err) => {
          console.error("Error1", err);
          setFrontImageLoading(false);
        });
    }
  }, [frontImage]);

  useEffect(() => {
    if (backImage.length > 0) {
      setBackImageLoading(true);
      let urlData: any;
      Promise.all(
        backImage?.map((items: any) => {
          let formData = new FormData();
          formData.append("uploadedFile", items?.data);
          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];
          callUploadDocumentBack(urlData);
        })
        .catch((err) => {
          console.error("Error2", err);
          setBackImageLoading(false);
        });
    }
  }, [backImage]);

  const getHealthCardInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      setGetLoading(true);
      CallAPI(config?.get_health_card_process_id, {
        p_patient_id: userProfileJson?.patient_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          // setValue("card_num", `${res?.returnData[0]?.healthcard_no || ""}`);
          setCardNoVal(res?.returnData[0]?.healthcard_no || "");
          // setValue(
          //   "issue_date",
          //   `${res?.returnData[0]?.healthcard_issue_date || ""}`
          // );
          // setValue(
          //   "exp_date",
          //   `${res?.returnData[0]?.healthcard_expiry_date || ""}`
          // );
          console.error("GET -->backId", res);
          setHealthDetails((prev: any) => {
            return {
              ...prev,
              issue_date: `${res?.returnData[0]?.healthcard_issue_date || ""}`,
              exp_date: `${res?.returnData[0]?.healthcard_expiry_date || ""}`,
              card_num: `${res?.returnData[0]?.healthcard_no || ""}`,
              front_url: `${res?.returnData[0]?.healthcard_front || ""}`,
              front_img_id: `${res?.returnData[0]?.healthcard_front_id || ""}`,
              back_url: `${res?.returnData[0]?.healthcard_back || ""}`,
              back_img_id: `${res?.returnData[0]?.healthcard_back_id || ""}`,
              healthcard_ref_id: `${
                res?.returnData[0]?.healthcard_ref_id || ""
              }`,
            };
          });
          setGetLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getHealthCardInfo();

    // getHealthCardFrontImageInfo();
  }, [suggestUsInit]);

  // useEffect(() => {
  //   if (
  //     healthDetails?.front_img_id === "" &&
  //     context?.healthCardFrontId !== ""
  //   ) {
  //     setHealthDetails((prev: any) => {
  //       return {
  //         ...prev,
  //         front_url: context?.healthCardFrontUrl || "",
  //         front_img_id: context?.healthCardFrontId || "",
  //         back_url: context?.healthCardBackurl || "",
  //         back_img_id: context?.healthCardBackId || "",
  //         healthcard_ref_id: context?.healthcard_ref_id || "",
  //       };
  //     });
  //   }

  //   if (healthDetails?.back_img_id === "" && context?.healthCardBackId !== "") {
  //     setHealthDetails((prev: any) => {
  //       return {
  //         ...prev,
  //         front_url: context?.healthCardFrontUrl || "",
  //         front_img_id: context?.healthCardFrontId || "",
  //         back_url: context?.healthCardBackurl || "",
  //         back_img_id: context?.healthCardBackId || "",
  //         healthcard_ref_id: context?.healthcard_ref_id || "",
  //       };
  //     });
  //   }
  // }, [healthDetails]);

  const getHealthCardFrontImageInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_health_card_process_id, {
        p_patient_id: userProfileJson?.patient_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          console.error("res---front", res);
          setHealthDetails((prev: any) => {
            return {
              ...prev,
              front_url: `${res?.returnData[0]?.healthcard_front || ""}`,
              front_img_id: `${res?.returnData[0]?.healthcard_front_id || ""}`,
            };
          });
        }
      });
    }
  };

  const getHealthCardBackImageInfo = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_health_card_process_id, {
        p_patient_id: userProfileJson?.patient_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          setHealthDetails((prev: any) => {
            return {
              ...prev,
              back_url: `${res?.returnData[0]?.healthcard_back || ""}`,
              back_img_id: `${res?.returnData[0]?.healthcard_back_id || ""}`,
            };
          });
        }
      });
    }
  };

  const deleteFrontCardHandler = () => {
    // if (suggestUsInit?.returnCode === true && healthDetails?.front_url !== "") {
    //   setFrontImageLoading(true);
    //   CallAPI(config?.add_health_card_process_id, {
    //     p_id:
    //       healthDetails?.healthcard_ref_id !== ""
    //         ? healthDetails?.healthcard_ref_id
    //         : "",
    //     p_user_id: userProfileJson?.usr_id,
    //     p_customer_id: userProfileJson?.customer_id,
    //     // p_healthcard_no: healthDetails?.card_num || "",
    //     p_healthcard_no: "",
    //     // p_healthcard_issue_date: moment(healthDetails?.issue_date).format(
    //     //   "YYYY-MM-DD"
    //     // ),
    //     p_healthcard_issue_date: "",
    //     // p_healthcard_expiry_date: moment(healthDetails?.exp_date).format(
    //     //   "YYYY-MM-DD"
    //     // ),
    //     p_healthcard_expiry_date: "",
    //     p_healthcard_front_image_document_id: "",
    //     p_healthcard_back_image_document_id:
    //       healthDetails?.back_img_id ||
    //       uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id,
    //     p_internal_flag: "",
    //   }).then((res) => {
    //     if (res?.returnCode) {
    //       setUploadedDocumentIDFront(null);
    //       getHealthCardFrontImageInfo();
    //       setFrontImageLoading(false);
    //       setFrontImage([]);

    //       setContext((prev: any) => ({
    //         ...prev,
    //         healthCardFrontId:
    //           // @ts-ignore
    //           "",
    //         healthCardFrontUrl: "",
    //         // healthCardBackId:
    //         //   // @ts-ignore
    //         //   healthDetails?.back_img_id ||
    //         //   uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id ||
    //         //   "",
    //         // healthCardBackurl: healthDetails?.back_url || "",
    //         healthcard_ref_id:
    //           healthDetails?.healthcard_ref_id !== ""
    //             ? healthDetails?.healthcard_ref_id
    //             : "",
    //       }));
    //     }
    //   });
    // } else {
    //   setFrontImage([]);
    // }
    setFrontImage([]);
    setUploadedDocumentIDFront(null);
    setHealthDetails((prev: any) => {
      return {
        ...prev,
        front_url: "",
        front_img_id: "",
      };
    });
    setContext((prev: any) => ({
      ...prev,
      healthCardFrontId:
        // @ts-ignore
        "",
      healthCardFrontUrl: "",

      healthcard_ref_id:
        healthDetails?.healthcard_ref_id !== ""
          ? healthDetails?.healthcard_ref_id
          : "",
    }));
  };

  const deleteBackCardHandler = () => {
    // if (suggestUsInit?.returnCode === true && healthDetails?.back_url !== "") {
    //   setBackImageLoading(true);
    //   CallAPI(config?.add_health_card_process_id, {
    //     p_id:
    //       healthDetails?.healthcard_ref_id !== ""
    //         ? healthDetails?.healthcard_ref_id
    //         : "",
    //     p_user_id: userProfileJson?.usr_id,
    //     p_customer_id: userProfileJson?.customer_id,
    //     p_healthcard_no: "",
    //     p_healthcard_issue_date: "",
    //     p_healthcard_expiry_date: "",
    //     p_healthcard_front_image_document_id:
    //       healthDetails?.front_img_id ||
    //       uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id,
    //     p_healthcard_back_image_document_id: "",
    //     p_internal_flag: "",
    //   }).then((res) => {
    //     if (res?.returnCode) {
    //       setUploadedDocumentIDBack(null);
    //       getHealthCardBackImageInfo();
    //       setBackImageLoading(false);
    //       setBackImage([]);
    //       setContext((prev: any) => ({
    //         ...prev,

    //         healthCardBackId:
    //           // @ts-ignore
    //           "",
    //         healthCardBackurl: "",
    //         healthcard_ref_id:
    //           healthDetails?.healthcard_ref_id !== ""
    //             ? healthDetails?.healthcard_ref_id
    //             : "",
    //       }));
    //     }
    //   });
    // } else {
    //   setBackImage([]);
    // }
    setUploadedDocumentIDBack(null);
    setBackImage([]);
    setContext((prev: any) => ({
      ...prev,

      healthCardBackId:
        // @ts-ignore
        "",
      healthCardBackurl: "",
      healthcard_ref_id:
        healthDetails?.healthcard_ref_id !== ""
          ? healthDetails?.healthcard_ref_id
          : "",
    }));
    setHealthDetails((prev: any) => {
      return {
        ...prev,
        back_url: "",
        back_img_id: "",
      };
    });
  };

  const expiryDateHandler = (val: any) => {
    setHealthDetails((prev: any) => {
      return { ...prev, exp_date: val };
    });
  };

  const issueDateHandler = (val: any) => {
    setHealthDetails((prev: any) => {
      return { ...prev, issue_date: val };
    });
  };

  // Function to remove spaces and special characters form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setCardNoVal("");
    } else if (fieldName === "card_num") {
      let newString = cleanInput(e.target.value);

      setCardNoVal(newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setCardNoVal(newString);
    } else {
      setCardNoVal(e.target.value);
    }
  };

  const getAlertInfo = () => {
    // healthDetails?.front_url || frontImage[0]?.uri
    if (healthDetails?.front_url === "" || frontImage[0]?.uri === "") {
    } else {
    }
  };

  return (
    <div className={`${Style?.maincontainer}`}>
      <div className={`${Style?.grid}`}>
        {/* <div className={`${Style?.gridLeftItem}`}>
          <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div> */}
        <div className={`${Style?.gridRightItem}`}>
          {/* <div className={`${Style?.headerContainer}`}>
            <div style={{ display: "flex" }}>
              <img
                src={HealthCardImage}
                alt="Health Card"
                width="30"
                height="30"
                style={{ marginTop: "4px", color: "#32C5F4" }}
                draggable="false"
              />
              <h2 className={`${Style?.heading}`}>
                {Config?.myHealth_Page_Heading_1}
              </h2>
            </div>
            {details?.healthcard_ref_id && (
              <IconButton
                title="Edit Health Card"
                className={`${Style?.editIcon}`}
                onClick={() => setIsEdit(!isEdit)}
                htmlType="button"
              >
                <img src={Editaddress_icon} alt="Edit btn" draggable="false"/>
              </IconButton>
            )}
          </div> */}

          <div className={`${Style?.infoGrid}`}>
            <div className={`${Style?.detUpload}`}>
              {/* <div className={`${Style?.detUpload_field_1}`}>
                <Label className={`${Style?.form_label}`} for="card_num">
                  {Config?.health_card_no_label}
                </Label>
                {getLoading ? (
                  <ShimmerTitle line={1} gap={10} variant="primary" />
                ) : (
                  <FormInput<RegistrationFormFields>
                    name="card_num"
                    id="card_num"
                    type="text"
                    placeholder={"Health Card No."}
                    size="small"
                    disabled={isEdit ? false : healthDetails?.card_num}
                    register={register}
                    rules={{ required: msg_config?.health_card_required }}
                    errors={errors}
                    maxLength={20}
                    value={cardNoVal}
                    onChange={(e: any) => onInputChangeHandler(e, "card_num")}
                  />
                )}
              </div> */}

              {/* <div className={`${Style?.detUpload_field_2}`}>
                <Label className={`${Style?.form_label}`} for="issue_date">
                  {Config?.health_card_issue_date_label}
                </Label>
                <Controller
                  control={control}
                  name="issue_date"
                  rules={{ required: msg_config?.issue_date_required }}
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <div>
                        {getLoading ? (
                          <ShimmerTitle line={1} gap={10} variant="primary" />
                        ) : (
                          <DatePickerInput
                            onChange={(e: any) => {
                              onChange(e);
                              issueDateHandler(e);
                            }}
                            placeholder={"Issue Date"}
                            displayFormat={"DD-MM-YYYY"}
                            disabled={isEdit ? false : details?.issue_date}
                            value={details?.issue_date}
                            maxDate={new Date()}
                          />
                        )}

                        <div className={`${Style?.error_msg}`}>
                          <ErrorMessage
                            errors={{ [name]: { ...error } }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            name={name as any}
                            render={({ message }) => {
                              return (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </div> */}

              {/* <div className={`${Style?.detUpload_field_3}`}>
                <Label className={`${Style?.form_label}`} for="exp_date">
                  {Config?.health_card_exp_date_label}
                </Label>
                <Controller
                  control={control}
                  name="exp_date"
                  rules={{ required: msg_config?.expiry_date_required }}
                  render={({
                    field: { name, onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <div>
                        {getLoading ? (
                          <ShimmerTitle line={1} gap={10} variant="primary" />
                        ) : (
                          <DatePickerInput
                            onChange={(e: any) => {
                              onChange(e);
                              expiryDateHandler(e);
                            }}
                            placeholder={"Expiry Date"}
                            displayFormat={"DD-MM-YYYY"}
                            disabled={isEdit ? false : details?.exp_date}
                            value={details?.exp_date}
                            minDate={details?.issue_date}
                          />
                        )}

                        <div className={`${Style?.error_msg}`}>
                          <ErrorMessage
                            errors={{ [name]: { ...error } }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            name={name as any}
                            render={({ message }) => {
                              return (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </div> */}
            </div>

            <div className={`${Style?.imgUpload}`}>
              <div className={`${Style?.imgUpload_card}`}>
                <div className={`${Style?.imgUpload_card_img}`}>
                  {!healthDetails?.front_url &&
                  !(frontImage[0]?.uri && uploadedDocumentIDFront) ? (
                    //if there is no front image****************
                    <div className={`${Style?.imgUpload_card_img_FileUpload}`}>
                      <FileUpload
                        type="update"
                        name="health-card-front"
                        id="health-card-front"
                        setFileContents={setFrontImage}
                        fileContents={frontImage}
                        loading={frontImgLoading}
                      />
                    </div>
                  ) : (
                    //if there is upload front image****************
                    <>
                      <img
                        src={healthDetails?.front_url || frontImage[0]?.uri}
                        alt="front"
                        draggable="false"
                      />

                      {isEdit && (
                        <button
                          className={Style?.editCard}
                          onClick={deleteFrontCardHandler}
                          type="button"
                        >
                          {frontImgLoading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              draggable="false"
                            />
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className={`${Style?.imgUpload_card_details}`}>
                  <div className={`${Style?.imgUpload_card_title}`}>
                    {Config?.health_Card_Upload_front}
                  </div>
                  <div className={`${Style?.imgUpload_card_title_side}`}>
                    {/* {Config?.health_Card_Upload_front} */}
                  </div>
                </div>
              </div>
              <div className={`${Style?.imgUpload_card}`}>
                <div className={`${Style?.imgUpload_card_img}`}>
                  {!healthDetails?.back_url &&
                  !(uploadedDocumentIDBack && backImage[0]?.uri) ? (
                    //if there is no upload back image****************
                    <div className={`${Style?.imgUpload_card_img_FileUpload}`}>
                      <FileUpload
                        type="update"
                        name="health-card-back"
                        id="health-card-back"
                        setFileContents={setBackImage}
                        fileContents={backImage}
                        loading={backImgLoading}
                      />
                    </div>
                  ) : (
                    //if there is upload back image****************
                    <>
                      <img
                        src={healthDetails?.back_url || backImage[0]?.uri}
                        alt="back"
                        draggable="false"
                      />

                      {isEdit && (
                        <button
                          className={Style?.editCard}
                          onClick={deleteBackCardHandler}
                          type="button"
                        >
                          {backImgLoading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              draggable="false"
                            />
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className={`${Style?.imgUpload_card_details}`}>
                  <div className={`${Style?.imgUpload_card_title}`}>
                    {Config?.health_Card_Upload_back}
                  </div>
                  <div className={`${Style?.imgUpload_card_title_side}`}>
                    {/* {Config?.health_Card_Upload_back} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert === true && (
        <>
          <Alert type="warning" info={msg_config?.health_card_alert} />
        </>
      )}
    </div>
  );
};

export default AddHealthCard;
