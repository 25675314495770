/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CheckValidation from "../../../components/CheckValidation";
import Alert from "../../../components/UI/Alert";
import Button from "../../../components/UI/Button";
import RxSteps from "../../../components/UI/rxSteps";
import UploadRX from "../../../components/UI/uploadRx";
import Modal from "../../../components/UI/VerificationModal";
import btnConfig from "../../../config/button";
import Config from "../../../config/label";
import { config } from "../../../config/processId.js";
import { siteConfig } from "../../../config/site";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { RightArrow } from "../../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, removeKey } from "../../../utils/utils";
import s from "./index.module.css";
import ProfilePopup from "../../Landingpage/ProfilePopup";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { msg_config } from "../../../config/messages";
import { ORDER_DETAILS, USER_PROFILE } from "../../../config/Config";

export const UploadRxPage = (props: any) => {
  const { isPopup } = props;
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileContents, setFileContents] = useState<any>([]);
  const [isSigned, setIsSigned] = useState(false);
  const [generatedRx, setGeneratedRx] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState<any>(null);
  const [uploadedSignatureId, setUploadedSignatureId] = useState<any>(null);
  const [imageURL, setImageURL] = useState<any>(null);
  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);
  const [isContinueAlert, setIsContinueAlert] = useState(false);
  const [isSignatureAlert, setIsSignatureAlert] = useState(false);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [consentFullName, setConsentFullName] = useState("");
  const orderDetails = getDecryptedID(ORDER_DETAILS) || {};
  const orderId = orderDetails?.ord_id || ""
  const [suggestUsInit] = useContext(APIContext);
  const [context, setContext] = useContext(UserAuthContext);
  const [succesDocUploaded, setDocUploaded] = useState({
    isTrue: false,
    type: "",
    msg: "",
  });

  //process id for upload document if more change it
  const isUploadMore = context?.isNewProcessId;
  const rx_id = context?.rx_id;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
  } = useForm();

  const getUploadRxForm = () => {
    switch (activeStep) {
      case 1:
        return (
          <UploadRX
            setFileContents={setFileContents}
            fileContents={fileContents}
          />
        );
      /*case 2:
        return (
          <ConsentForm
            setIsSigned={setIsSigned}
            setImageURL={setImageURL}
            setConsentFullName={setConsentFullName}
            consentFullName={consentFullName}
            watch={watch}
          />
        );*/

      default:
        break;
    }
  };

  useEffect(() => {
    try {
      if (succesDocUploaded?.isTrue) {
        setTimeout(() => {
          setDocUploaded({
            isTrue: false,
            msg: "",
            type: ""
          })
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  }, [succesDocUploaded])

  const uploadFileDataAxios = (data: any) => {
    return axios({
      method: "POST",
      data,
      url: siteConfig?.upload_by_parts,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const onUploadDocumentsHandler = () => {
    if (fileContents?.length > 0) {
      setLoading(true);
      let urlData: any;
      let documentData: any;
      Promise.all(
        fileContents?.map((item: any, i: any) => {
          let formData = new FormData();
          formData.append("uploadedFile", item?.data);
          return uploadFileDataAxios(formData);
        })
      )
        .then((res) => {
          urlData = [...res];

          callUploadDocuments(urlData);
        })
        .catch((err) => {
          console.error("errr", err);
        });
    } else {
      setIsContinueAlert(true);
      setTimeout(() => {
        setIsContinueAlert(false);
      }, 5000);
    }
  };

  async function rxUploadDocument(filename: any) {
    const p_docsubtype_code = filename?.data?.url?.includes("pdf")
      ? config?.upload_p_docsubtype_code
      : config?.upload_image_p_docsubtype_code;

    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: _user_profile?.patient_id,
        p_usr_id: _user_profile?.usr_id,
        p_doc_name: filename?.data?.url,
        p_doc_remarks: "",
        p_doctype_code: config?.upload_p_doctype_code,
        p_trnt_code: config?.upload_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code,
        p_doc_uploaded_filename: filename?.data?.url,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }
  async function callUploadDocuments(urlData: any) {
    var uploaDocumentID: number[] = await Promise.all(
      urlData.map(async (item: any): Promise<number> => {
        return await rxUploadDocument(item);
      })
    );

    if (Array?.isArray(uploaDocumentID) && uploaDocumentID?.length > 0) {
      setLoading(false);
      setUploadedDocumentIds(
        uploaDocumentID?.map((item: any) => item?.returnData[0]?.document_id)
      );
      // setTimeout(() => {
      setContext((prev: any) => {
        return { ...prev, isRxUploadActive: false, isRxUploaded: true }
      })
      // }, 2000);
      var ids: string[] = uploaDocumentID?.map(
        (item: any) => item?.returnData[0]?.document_id
      );
      if (isUploadMore) {
        uploadMoreRx(ids?.join("~"));
      } else {
        generateRx("", ids?.join("~"));
      }
      // Navigate the user to Consent Form
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  useEffect(() => {
    return () => {
      setContext((prev: any) => {
        return { ...prev, isRxUploadActive: false, isRxUploaded: false }
      })
    }
  }, [])

  async function rxUploadSignature(filename: any) {
    if (suggestUsInit?.returnCode === true) {
      return await CallAPI(config?.rx_upload_process_id, {
        p_patient_id: _user_profile?.patient_id,
        p_usr_id: _user_profile?.usr_id,
        p_doc_name: filename,
        p_doc_remarks: "",
        p_doctype_code: config?.upload_p_doctype_code,
        p_trnt_code: config?.upload_sig_p_trnt_code,
        p_doc_transaction_id: "",
        p_doc_path: "",
        p_doc_id: "",
        p_docsubtype_code: config?.upload_sig_p_doctype_code,
        p_doc_uploaded_filename: filename,
        p_userdata_patient_id: "",
        p_internal_flag: "",
      });
    }
  }

  const uploadMoreRx = (docId: string) => {
    try {
      const processId = config?.xcelecom_save_trn_rx_request_document_upload
      const params = {
        p_usr_id: _user_profile?.usr_id,
        p_document_ids: docId,
        p_rx_id: rx_id,
      }
      CallAPI(processId, params)
        .then((res) => {
          if (res?.returnCode) {
            setLoading(false);
            removeKey(ORDER_DETAILS);
            setDocUploaded({
              isTrue: true,
              msg: msg_config?.document_uploaded,
              type: "success",
            })
          } else {
            setDocUploaded({
              isTrue: true,
              msg: res?.msg,
              type: "error",
            })
          }
        })
    } catch (error) {
      console.error(error);
    }
  }
  const generateRx = (signatureId: any, docId: string) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.generate_rx_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_document_ids: docId, //uploadedDocumentIds?.join("~"),
        p_customer_id: _user_profile?.customer_id,
        p_rx_type: "UP",
        p_rx_patient_id: _user_profile?.patient_id,
        p_resource_id: "",
        p_pharmacy_id: "",
        p_signature_document_id: signatureId,
        p_rx_patient_type: "",
        p_internal_flag: "",
        p_order_id: orderId,
      }).then((res) => {
        if (res?.returnCode) {
          if (res?.returnData?.length > 0) {
           removeKey(ORDER_DETAILS);
            setLoading(false);
            setGeneratedRx(res);
            // setIsModalOpen(true);
            setContext((prev: any) => {
              return { ...prev, isPrescriptionUploaded: true, data: res?.returnData[0]?.rx_number };
            })
          }
        }
      });
    }
  };

  async function callUploadSignature(urlData: any) {
    Promise.resolve(rxUploadSignature(urlData)).then((res) => {
      if (res?.returnCode) {
        setUploadedSignatureId(res?.returnData[0]?.document_id);
        //generateRx(res?.returnData[0]?.document_id);
      }
    });
  }

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (siteConfig?.check_Profile === "Y") {
      if (
        _user_profile?.usr_email === "" ||
        _user_profile?.usr_name === "" ||
        _user_profile?.usr_phone === "" ||
        _user_profile?.usr_dob === ""
      ) {
        setIsProfilePopup(true);
      } else {
        onUploadDocumentsHandler();
      }
    } else {
      onUploadDocumentsHandler();
    }

    /*
    if (isSigned) {
      setLoading(true);
      fetch(imageURL)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image", { type: "image/png" });
          let formData = new FormData();
          formData.append("uploadedFile", file);

          Promise.resolve(uploadFileDataAxios(formData)).then((res) => {
            if (res?.data?.result === "true") {
              callUploadSignature(res?.data?.url);
            }
          });
        });
    } else {
      setIsSignatureAlert(true);
      setTimeout(() => {
        setIsSignatureAlert(false);
      }, 5000);
    }*/
  };
  return (
    <div className="">
      {isPopup && <div className={`${s?.closeRxUpload}`}>
        <i className="fa-solid fa-xmark"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setContext((prev: any) => {
              return { ...prev, isRxUploadActive: false }
            })
          }}
        ></i>
      </div>}
      <div className={`${s?.uploadRxContainer} ${isPopup ? s?.uploadRxPopup : ""}`}>
        {!isPopup && <div className={`${s?.uploadRxLeftContainer}`}>
          <RxSteps activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>}
        <div className={`${s?.UploadRxRightcontainer}`}>
          <form encType="multipart/form-data" onSubmit={onSubmitHandler}>
            {getUploadRxForm()}

            <div className={`${s?.actionButton}`}>
              {/* <Button className={`${s?.upload_back_btn}`}
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              htmlType={Config?.lbl_Btn_action_btn}
              onClick={() => {
                // if (activeStep === 1) {
                //   navigate(`/submit-rx`);
                // } else {
                //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
                // }
                const route =sessionStorage.getItem("currentRouteForUploadPrescription") || "/";
                navigate(route);
                sessionStorage.removeItem("currentRouteForUploadPrescription");
              }}
            >
              {btnConfig?.uploadRx_Ghost_Text_Back}
            </Button> */}

              <CheckValidation
                show={activeStep >= 1}
                fallback={
                  <Button
                    type={Config?.lbl_Btn_type_primary}
                    htmlType={Config?.lbl_Btn_action_btn}
                    size={Config?.lbl_Btn_size_medium}
                    onClick={() => {
                      onUploadDocumentsHandler();
                    }}
                    loading={loading}
                  >
                    <span className="flex items-center justify-center gap-4">
                      {btnConfig?.uploadRx_Primary_Text_V1}
                      <img src={RightArrow} alt="" draggable="false" />
                    </span>
                  </Button>
                }
              >
                <Button
                  type={Config?.lbl_Btn_type_primary}
                  htmlType={Config?.lbl_Btn_action_submit}
                  size={Config?.lbl_Btn_size_medium}
                  loading={loading}
                >
                  <span className="flex items-center justify-center gap-4">
                    {btnConfig?.uploadRx_Primary_Text_V1}
                    <img src={RightArrow} alt="" draggable="false" />
                  </span>
                </Button>
              </CheckValidation>
            </div>
          </form>
          {isContinueAlert && (
            <Alert type="warning" info="Please upload atleast one document." />
          )}
          {isSignatureAlert && (
            <Alert type="warning" info="Please complete your signature" />
          )}
          {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}
          {succesDocUploaded?.isTrue && <Alert type={succesDocUploaded?.type} info={succesDocUploaded?.msg} />}
        </div>
        {false && (
          <Modal
            setModalOpen={setIsModalOpen}
            heading={Config?.uploadRx_Modal_Heading
              //  +
              // " " +
              // generatedRx?.returnData[0]?.rx_number
            }
            detail={
              Config?.uploadRx_Modal_detail__1 +
              " " +
              generatedRx?.returnData[0]?.rx_number
              //  +
              // " " +
              // Config?.uploadRx_Modal_detail__2
            }
          />
        )}
      </div>
    </div>
  );
};

export default UploadRxPage;
