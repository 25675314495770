import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ChatDocPopUp from "./PopupModal/ChatDocPopUp";

var url = ""
const formatter = (timestamp) => {
  return moment(timestamp).fromNow();
};
const MyMessage = ({
  message,
  messageService,
  getDocType,
  participant,
  lastMessage,
  author,
  myIdentity,
  getStatus,
  tempFile,
  setTempFile,
  conversationProxy,
  handleDelete,
  mediaUrl,
}) => {
  const [status, setStatus] = useState({});
  url = message?.media?.fileUri
  const [timestampString, setTimestampString] = useState("");
  /**************************  CHECK IF FIRST MESSAGE IS NOT BY ME START *******************************/
  const isFirstMessageByUser =
    !lastMessage || lastMessage?.author !== myIdentity;
  /**************************  CHECK IF FIRST MESSAGE IS NOT BY ME END *******************************/

  /************************** GET MESSAGE STATUS START *******************************/
  useEffect(() => {
    getStatus.then((value) => setStatus(value));
  }, [getStatus, message]);

  useEffect(() => {
    const timer = setInterval(
      () => setTimestampString(formatter(message?.dateCreated)),
      1000
    );
    setTimestampString(formatter(message?.dateCreated));
    return () => clearInterval(timer);
  }, []);

  /************************** GET MESSAGE STATUS END *******************************/
  /************************** GET MESSAGE STATUS VALUE START *******************************/
  const getReceipt = (val) => {
    if (val?.Delivered)
      return (
        <span>
          <i
            style={{ color: "grey" }}
            className="fa fa-check"
            aria-hidden="true"
          ></i>
          <i
            style={{ color: "grey" }}
            className="fa fa-check"
            aria-hidden="true"
          ></i>
        </span>
      );
    if (val?.Sending) {
      return <i className="fa fa-spinner" aria-hidden="true"></i>;
    }
    if (val?.Read >= 2) {
      return (
        <span>
          <i
            className="fa fa-check"
            style={{ color: "#0077B5" }}
            aria-hidden="true"
          ></i>
          <i
            className="fa fa-check"
            style={{ color: "#0077B5" }}
            aria-hidden="true"
          ></i>
        </span>
      );
    }

    return <span></span>;
  };

  // const getFileType = (value) => {
  //   if ((["vnd.ms-powerpoint", "vnd.openxmlformats-officedocument.presentationml.presentation"])?.includes(value)) {
  //     return "ppt"
  //   }
  //   if ((["vnd.openxmlformats-officedocument.spreadsheetml.sheet", "vnd.ms-excel"])?.includes(value)) {
  //     return "xlsx"
  //   }
  //   return value
  // }

  /************************** GET MESSAGE STATUS VALUE END *******************************/
  try {
    return (
      <> 
        {message?.attributes?.type === "queue" || message?.attributes?.type === "info" || message?.attributes?.type === "welcome" || message?.attributes?.type === "guest_info" ?
          <>
          {message?.attributes?.type === "welcome" ?
              <div className="center">
                  <div
                    className="msg welcome-message">
                    <div className="message">
                      <div className="message-body-container" dangerouslySetInnerHTML={{__html: message?.body !== "" && message?.body !== "null" && message?.body}}>
                      </div>
                    </div>
                  </div>
              </div>
              :
                <> 
                  {message?.attributes?.type != "guest_info" &&
                     <div className="center">
                        <div className="msg">
                          <div className="message">
                            <div className="message-body-container">
                              {message?.body !== "" && message?.body !== "null" && message?.body}
                            </div>
                          </div>
                        </div>
                      </div>
                  }
                </>
           
          }
          </>
        : 
          <div className="right">
            {isFirstMessageByUser && (
              <div className="chat-avatar">
                <div className="name">{author}</div>
                <div className="icon">
                  <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
              </div>
            )}
            <div
              className="msg"
              onClick={() => {
                if (message?.media?.sid) {
                  if(mediaUrl!=undefined){
                    messageService.sendMessage(
                      "TwilioDOC",
                      {
                        params: {
                          state: true,
                          index: 0,
                          src: "Chat",
                          files: [{
                            file_name: message?.media?.filename,
                            file_view_path: mediaUrl,
                            file_ext: message?.media?.contentType?.split("/").pop(),
                            file_download_path: mediaUrl
                          }]
                        }
                      },
                      "chat-doc-pop-up"
                    );
                  }else{
                    toast.error("Please wait media is loading.");
                  }
                }
              }}
            >
              <div className="message-body-container"> {message?.body !== "" && message?.body !== "null" && message?.body}
              </div>

              <div
                className="image-container"
                style={{
                  display: message?.media === null && "none",
                }}
              >
                {mediaUrl !== undefined ? (
                  <>
                    {!["jpeg", "png", "jpg", "gif"].includes(
                      message?.media?.contentType?.split("/").pop()
                    ) ? (
                      <i className={getDocType} />
                    ) : (
                      <img
                        src={mediaUrl}
                        height="100"
                        width="100"
                        loading="lazy"
                      />
                    )}
                  </>
                ) :
                  message?.media?.fileUri ? (
                    <>
                      {!["jpeg", "png", "jpg", "gif"].includes(
                        message?.media?.contentType?.split("/").pop()
                      ) ? (
                        <i className={getDocType} />
                      ) : (
                        <img
                          src={message?.media?.fileUri}
                          height="100"
                          width="100"
                          loading="lazy"
                        />
                      )}
                    </>
                  ) :
                    (
                      <div className="loader">
                        <span
                          className="spinner-border-black spinner-border-sm loadingSpinner "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
              </div>
            </div>
            <div className="timestampe">
              <div>{timestampString}</div>
              <div>{getReceipt(conversationProxy?.attributes?.subType === undefined ? status : { Read: 2 })}</div>
            </div>
          </div>}
      </>

    );
  } catch (e) {
    console.log("Error: ", e.message);
  }
};

export default MyMessage;
