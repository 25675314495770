import React from 'react';
import { btn_submit_type_primary, btn_submit_type_secondary } from '../Config/config';

const Button = (props) => {
    const { loading = false, action, title, children, data, ref } = props;
    const ButtonType = (value) => {
        switch (value) {
            case btn_submit_type_primary:
                return (
                    <button
                        id={props?.id}
                        title={props?.tooltip}
                        ref={props?.ref}
                        className={props?.className}
                        style={loading ? { ...props?.style, opacity: '0.8' } : props?.style}
                        onClick={props?.onClick}
                        type={props?.type}
                        disabled={loading}
                        data={data}
                    >
                        {loading &&
                            <span
                                className="spinner-border spinner-border-sm loadingSpinner"
                                role="status"
                                aria-hidden="true"
                            ></span>}
                        {title}
                        {children}
                    </button>)
                break;
            case btn_submit_type_secondary:
                return (
                    <button
                        id={props?.id}
                        title={props?.tooltip}
                        ref={props?.ref}
                        className={props?.className}
                        style={loading ? { ...props?.style, opacity: '0.8' } : props?.style}
                        onClick={props?.onClick}
                        type={props?.type}
                        disabled={loading}
                        data={data}
                    >
                        {title}
                        {children}
                    </button>)
                break;

            default:
                return (
                    <button
                        id={props?.id}
                        title={props?.tooltip}
                        ref={props?.ref}
                        className={props?.className}
                        style={props?.style}
                        onClick={props?.onClick}
                        type={props?.type}
                        disabled={props?.disabled}
                        data={data}
                    >
                        {title}
                        {children}
                    </button>)
                break;
        }
    }
    return (
        ButtonType(action)
    )
}

export default Button