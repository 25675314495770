import React, { FC, forwardRef, DetailedHTMLProps, InputHTMLAttributes, useState, useContext } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import styles from "./index.module.css";
import en from "react-phone-number-input/locale/en.json";
import { UserAuthContext } from "../../../store/UserAuthContext.js";

export type InputProps = {
  name: string;
  placeholder?: string;
  className?: string;
  ref?: any;
  disabled?: any;
  control?: any;
  phoneInputRef?: any;
  setError?: any;
  clearErrors?: any;
  OnChangePhoneNumber?: any;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "size">;

export const Phone: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      setError,
      clearErrors,
      control,
      name,
      placeholder,
      phoneInputRef,
      OnChangePhoneNumber,
      ...props
    },
    ref
  ) => {
    const whitelisted_conutries = ["IN", "US", "CA"];
    const [maxLength, setMaxLength] = useState(15);
    const [context, setContext] = useContext(UserAuthContext);
    const fieldName = name || "";

    const cleanPersonNameInput = (inp: any) => {
      if (inp) {
        if (inp.includes("+")) {
          if (inp.includes("+1")) {
            let newNum = inp.replace("+1", "").replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
            return newNum;
          } else if (inp.includes("+91")) {
            let newNum = inp.replace("+91", "").replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
            return newNum;
          } else {
            return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
          }
        } else {
          return inp.replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
        }
      }

      return "";
    };

    const handleOnchange = (val: any) => {
      const phVal = phoneInputRef?.current?.value;
      if (phVal.includes("(") || phVal.includes(")") || phVal.includes("-")) {
        setMaxLength(14);
      } else if (!phVal.includes(" ")) {
        setMaxLength(11);
      // } else if (phVal.startsWith("+")) {
      //   setMaxLength(15);
      } else {
        setMaxLength(16);
      }
      let newNumber = cleanPersonNameInput(phVal);

      clearErrors("phone");
      setContext((prev: any) => ({
        ...prev,
        phLength: newNumber?.length,
      }));
      if (OnChangePhoneNumber) {
        OnChangePhoneNumber(val, fieldName);
      }
    };

    return (
      <PhoneInputWithCountry
        id="phone"
        // initialValueFormat="national"
        // maxlength={11}
        maxLength={maxLength}
        minLength={10}
        name={name}
        defaultCountry={"IN"}
           // @ts-ignore
        countries={whitelisted_conutries}
        control={control}
        className={`${styles.inputStyle}`}
        labels={en}
        placeholder={placeholder}
        ref={phoneInputRef}
        disabled={disabled}
        onChange={handleOnchange}
      />
    );
  }
);
