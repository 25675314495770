// export const LOGGED_IN_USER_ROLE = "CUSTOMER"
//importing images 
import capsuleImg  from "../assets/productPlaceHolder/capsules.svg"
import bottleImg from "../assets/productPlaceHolder/Bottle.svg"
import syrupImg  from "../assets/productPlaceHolder/syrup.svg"
import tabletImg  from "../assets/productPlaceHolder/tablets.svg"
import capsules  from "../assets/productPlaceHolder/capsules.svg"
import devices  from "../assets/productPlaceHolder/devices.svg"
import drops  from "../assets/productPlaceHolder/Drops.svg"
import injection  from "../assets/productPlaceHolder/injections.svg"
import lotion  from "../assets/productPlaceHolder/lotion.svg"
import spray  from "../assets/productPlaceHolder/spray.svg"
import tube  from "../assets/productPlaceHolder/tube.svg"
import placeholderImg from "../assets/productPlaceHolder/general.svg"
import instass from "../assets/png/instas.png"
import drReddy from "../assets/png/drReddy.png"
import ciplaHealth from "../assets/png/ciplaHealth.png"
import abbott from "../assets/png/Abbott.png"
import sunPharma from "../assets/png/sunPharma.png"
import confirmDeleteImage from "../assets/sf_svg/delete_item.svg"
import MY_PROFILE from "../assets/svg/My_profile.svg"
import MY_WISHLIST from "../assets/svg/wishlist.svg"
import CART from "../assets/svg/cart.svg"
import ORDERS from "../assets/svg/orders.svg"
import CHANGE_PASSWORD from "../assets/svg/password.svg"
import LOGOUT from "../assets/sf_svg/logout_myProfile.svg"
import NO_ADDRESS_IMAGE_img from "../assets/sf_svg/address_state.svg"


export const LOGGED_IN_USER_ROLE = "pro";
export const PARAM_CODE_TO_GET_DOCTYPES = "DC032";
export const DOCUMENT_FILE_UPLOAD_ALLOWED = ['png', 'gif', 'jpeg', 'jpg','pdf'];
export const ON_BOARDING_STATUS_UPDATE_FOR_PENDING = "pending_approval";
export const SHOW_PRO_USER_LOGIN_PAGE_FOOTER = false;
export const SHOW_PASSWORD_FLAG = true ;
export const VERIFY_PHONE_OTP_LENGTH = 6;


export const PRODUCT_CARD_IMAGES = {
    capsule : capsuleImg,
    bottle : bottleImg,
    syrup : syrupImg,
    tablet : tabletImg,
    capsules : capsules,
    devices : devices,
    drops : drops,
    injection : injection,
    spray : spray,
    tube : tube,
    lotion : lotion,
    defaultImg : placeholderImg,
}

//images for my profile popup
export const MY_PROFILE_MENU_IMAGES = {
    myProfile : MY_PROFILE,
    myWishlist :MY_WISHLIST ,
    cart :CART ,
    orders :ORDERS,
    changePassword :CHANGE_PASSWORD ,
    logOut : LOGOUT,
}

export const CONFIRM_DELETE_POPUP_IMAGE  = confirmDeleteImage;

export const PRODUCT_TYPE = {
    capsule : "capsule", //
    bottle : "bottle", //
    syrup : "syrup", //
    tablet : "tablet",//
    devices : "device",
    drops : "DROPS",
    injection : "injection",
    tube : "tube",
    spray : "spray",
    lotion : "lotion"
}


export const PRO_USER_LOGIN_PAGE_FOOTER_IMAGES = [{id : "1",image : drReddy,
    },
    {
        id : "2",
        image : instass,
    },
    {
        id : "3",
        image : ciplaHealth,
    },
    {
        id : "4",
        image : abbott,
    },
    {
        id : "5",
        image : sunPharma,
    },
]
export const LOADING_ARRAY_CONFIG  = [1,2,3,4,5,6,7,8,9,0,0,0,0,0,0,0,0,0];
//IMAGE IF NO ADDRESS FOUND IN CHECKOUT =========>
export const NO_ADDRESS_IMAGE = NO_ADDRESS_IMAGE_img;


///session configs 
export const sgUserId = "sg_user_id";
export const USER_PROFILE = "user-profile";
export const USER_LOGGED_IN = "user-loggedin";
export const SG_ROLE_ID = "sg_roleId";
export const SG_USER_ID = "sg_userId";
export const SG_PATIENT_ID = "sg_patientId";
export const IS_INSURANCE = "isInsurance";
export const PROFILE_IMAGE = "profile_image";
export const PROFILE_IMAGE2 = "profile-image";
export const USER_ID = "user_id";
export const QB_ID = "QB_ID";
export const USER_ROLE_ID = "usr_role_id";
export const DEFAULT_FLAG = "Default_flag";
export const PATIENT_ID = "patient_id";
export const CART_COUNT = "cart_count";
export const INSURANCE_FRONT_ID = "insurance_front_id";
export const INSURANCE_BACK_ID = "insurance_back_id";
export const HEALTH_CARD_FRONT_ID = "healthcard_front_id";
export const HEALTH_BACK_ID = "healthcard_back_id";
export const HEALTH_INFO = "healthInfo";
export const SIGN_IN_WITH = "sign_in_with";
export const IS_DOCS_PENDING = "isDocsPending";
export const FIRST_TIME_LOGIN = 'firstTimeLogin'; 
export const IS_PROFILE_UPDATED = "isProfileUpdated";
export const IS_CHECKED = 'is_Checked';
export const LOGINS_IN_BY = "login_by";
export const PASSWORD_MSG = "passwordMsg";
export const PHONE_NO = "phone_no";
export const CURRENT_SCREEN = "current_screen";
export const FORGOT_PASSWORD_PHONE_NO = "forgot_pswrd_phone_no";
export const IS_EDITING = "isEditing";
export const SUGGEST_US_INIT = "suggestUsInitialize";
export const ORG_NAME = "org_name";
export const ORG_CODE = "org_code";
export const ORG_ID = "org_id";
export const ORG_CURRENCY = "org_currency";
export const SG_ORG_ID = "sg_org_id";
export const DOC_UPDATE = "document_Update";
export const RX_CART_FLAG = "rxCart_flag";
export const CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION = "currentRouteForUploadPrescription";
export const ORDER_DETAILS = "orderDetails";
export const C_PWD = "c_PWD";
export const ACTIVE_PRODUCT_ID = "activeProductId"