import React, { useEffect, useContext, useState, useRef } from "react";
import SelectDropdown from "../../../../../components/UI/SelectDropdown";
import Label from "../../../../../components/UI/Label";
import LinkButton from "../../../../../components/UI/LinkButton";
import { JSONCheckerFunc, getDecryptedID, getInitials, objDecrypt } from "../../../../../utils/utils";
import Initial from "../../../../../components/UI/Initial";
import Config from "../../../../../config/label";
import s from "./index.module.css";
import GoogleMap from "../../../../../components/UI/GoogleMap";
import APIContext from "../../../../../store/api-context";
import { CallAPI } from "../../../../../suggest-us/callSuggest.js";
import { config } from "../../../../../config/processId.js";
import { useLocation } from "react-router-dom";
import { msg_config } from "../../../../../config/messages";
import { debounce } from "lodash";
import Input from "../../../../../components/UI/Input";
import LoadingCircle from "../../../../../components/LoadingCircle";
import { FormInput } from "../../../../../components/UI/FormInput";
import { PhoneFormInput } from "../../../../../components/UI/PhoneFormInput";
import LocationSearchInput from "../../../../../components/LocationSearchInput";
import { USER_PROFILE } from "../../../../../config/Config";

export type RegistrationFormFields = {
  practitionerName: string;
  phone: string;
  location: string;
};

const Rx = (props: any) => {
  const {
    activeStep,
    practitionerType,
    setPractitionerType,
    setPractitionerId,
    setAddPractitionerAddress,
    addPractitionerAddress,
    setIsAddPractitioner,
    setPractitioner,
    practitioner,
    practitionerList,
    addPractitioner,
    rxContext,
    setRxContext,
    register,
    control,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
  } = props;

  const [suggestUsInit] = useContext(APIContext);
  const [displayOut, setDisplayOut] = useState(false);
  const [searchText, setSearchText] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [practitionerDetails, setPractitionerDetails] = useState<any>({});
  const [addressDetails, setaddressDetails] = useState([]);
  const [nameValue, setNameValue] = useState("");
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [first, setfirst] = useState({ lat: 0, lng: 0 });
  const location = useLocation();
  const refPractitionerSearch: any = useRef(null);
  const phoneInputRef = useRef<any>(null);

  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);
  const options = [
    ...practitionerList?.map((item: any) => {
      return {
        value: item?.resource_id,
        label: (
          <div className={s?.option}>
            <Initial>{getInitials(`${item?.resource_name}`)}</Initial>
            <span className={`${s?.opText}`}>{item?.resource_name}</span>
          </div>
        ),
      };
    }),
  ];

  useEffect(() => {
    // if (suggestUsInit?.returnCode === true) {
    //   CallAPI(config?.newRx_practitioner_list_process_id, {}).then((res) => {
    //     if (res?.returnCode) {
    //       setPractitionerList([...res?.returnData]);
    //     }
    //   });
    // }
    addPractitioner();
  }, [suggestUsInit]);

  const onPractitionerChange = (selectedOption: any) => {
    setPractitioner(selectedOption.value);
  };

  const handleClickSearchOutside = (event: any) => {
    if (
      refPractitionerSearch.current &&
      !refPractitionerSearch.current.contains(event.target)
    ) {
      if (event.target.id === "practitionerSearch") {
        setDisplayOut(true);
      } else {
        setDisplayOut(false);
      }
    }
  };

  useEffect(() => {
    if (
      rxContext.practitionerData !== "" &&
      typeof rxContext.practitionerData !== "undefined"
    ) {
      setIsPractitioner(true);
      clearErrors("practitionerName");
      clearErrors("phone");
      clearErrors("location");
      setNameValue(rxContext.practitionerData?.practitionerName);
      setValue(
        "practitionerName",
        rxContext.practitionerData?.practitionerName
      );
      setValue("phone", rxContext.practitionerData?.phone);
      setValue("location", rxContext.practitionerData?.location);
    }
  }, [rxContext]);

  useEffect(() => {
    if (displayOut)
      document.addEventListener("click", handleClickSearchOutside, true);
    return () => {
      document.removeEventListener("click", handleClickSearchOutside, true);
    };
  }, [displayOut]);

  const action = (val: any) => {
    if (val !== "") {
      setSearchText([]);
      setIsLoading(true);
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.newRx_search_practitioner_list_process_id, {
          p_search_text: val,
          p_max_offset: "10",
        }).then((res) => {
          if (res?.returnCode) {
            setSearchText([...res?.returnData]);
            setIsLoading(false);
          }
        });
      }
    }
  };
  const debounceSearch = debounce(action, 600);

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setRxContext((prev: any) => ({
        ...prev,
        practitioner: "",
      }));
      setIsPractitioner(false);
    } else {
      debounceSearch(e?.target?.value);
      setRxContext((prev: any) => ({
        ...prev,
        practitioner: e?.target?.value,
      }));
      setSearchText([]);
      setIsLoading(true);
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onNameInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setNameValue("");
      setValue("name", "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setNameValue(newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setNameValue(newString);
    } else {
      setNameValue(e.target.value);
      setValue("name", e.target.value);
    }
  };

  const handlePractitionerClick = (doc: any) => {
    setfirst({
      lat: Number(doc?.resource_latitude),
      lng: Number(doc?.resource_longitude),
    });
    setPractitionerDetails(doc);
    setRxContext((prev: any) => ({
      ...prev,
      practitioner: doc?.resource_name,
    }));
    setPractitionerType("Select");
    setPractitionerId(doc?.resource_id);
    clearErrors("practitionerName");
    clearErrors("phone");
    clearErrors("location");
    setValue("practitionerName", doc?.resource_name);
    setValue("phone", doc?.resource_phone_no);
    setValue("location", doc?.resource_location);
    setIsPractitioner(true);
  };

  const handleAddPractitioner = () => {
    setRxContext((prev: any) => ({
      ...prev,
      practitioner: "Other Practitioner",
    }));
    setPractitionerType("Add");
    clearErrors("practitionerName");
    clearErrors("phone");
    clearErrors("location");
    setValue("practitionerName", "");
    setValue("phone", "");
    setValue("location", "");
    setIsPractitioner(true);
  };

  const center = {
    lat: first?.lat,
    lng: first?.lng,
  };
  return (
    <>
      <div className={s?.ratioBox}>
        <GoogleMap
          height="150px"
          first={first}
          setfirst={setfirst}
          center={center}
        />
      </div>

      <div className={`${s?.form_inner_control}`}>
        <div className={`${s?.form_inner_label}`}>
          {/* <Label className={`${s?.form_label}`} for="firstName">
            {Config?.newRX_Label}
          </Label>
          <LinkButton
            to={location?.pathname + location?.search}
            onClick={() => setIsAddPractitioner(true)}
            draggable="false"
          >
            {Config?.newRX_Lnk_Title}
          </LinkButton> */}
        </div>
        {/* <SelectDropdown
          options={options}
          size="medium"
          filterType={"initials"}
          placeholder={"Practitioner"}
          name="practitioner"
          rules={{ required: msg_config?.newRx_select_required }}
          // value={
          //   options?.length > 0 &&
          //   options?.filter(({ value }) => value === practitioner)
          // }
          // onChange={onPractitionerChange}
          // isClearable={false}
          control={control}
          errors={errors}
        /> */}
        <div className={s["headerSearchbox"]}>
          <div className={s["headerSearchbar"]}>
            <Input
              name="practitionerSearch"
              id="practitionerSearch"
              type="practitionerSearch"
              placeholder={Config?.newRX_Select_Placeholder}
              size="medium"
              value={rxContext?.practitioner}
              maxLength={50}
              onChange={(e: any) => {
                onInputChangeHandler(e, "");
              }}
              onFocus={() => {
                setDisplayOut(true);
                setRxContext((prev: any) => ({
                  ...prev,
                  practitioner: "",
                }));
                setRxContext((prev: any) => ({
                  ...prev,
                  practitionerData: "",
                }));
                setIsPractitioner(false);
              }}
            />
            {rxContext?.practitioner !== "" && displayOut && (
              <div ref={refPractitionerSearch}>
                <div className={`${s?.searchDropDown} `}>
                  <ul>
                    <li className={`${s?.searchDropDownItem} ${s?.disabled}`}>
                      <div></div>
                    </li>
                    {searchText?.length > 0 ? (
                      <>
                        <li
                          key={"AddOther"}
                          className={`${s?.searchDropDownItem}`}
                          onClick={() => {
                            handleAddPractitioner();
                            setDisplayOut(false);
                          }}
                        >
                          <div className={s?.option}>
                            <Initial>{`+`}</Initial>
                            <span className={`${s?.opText}`}>
                              {Config?.newRx_add_title}
                            </span>
                          </div>
                        </li>
                        {searchText?.map((item: any) => (
                          <li
                            key={item?.srv_id}
                            className={`${s?.searchDropDownItem}`}
                            onClick={() => {
                              handlePractitionerClick(item);
                              setDisplayOut(false);
                            }}
                          >
                            <div className={s?.option}>
                              <Initial>
                                {getInitials(`${item?.resource_name}`)}
                              </Initial>
                              <span className={`${s?.opText}`}>
                                {item?.resource_name}
                              </span>
                              <span className={`${s?.locationText}`}>
                                {item?.resource_location}
                              </span>
                            </div>
                          </li>
                        ))}
                      </>
                    ) : isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingCircle loadingColor={"#2d4266"} />
                      </div>
                    ) : (
                      <li
                        key={"AddOther"}
                        className={`${s?.searchDropDownItem}`}
                        onClick={() => {
                          handleAddPractitioner();
                          setDisplayOut(false);
                        }}
                      >
                        <div className={s?.option}>
                          <Initial>{`+`}</Initial>
                          <span className={`${s?.opText}`}>
                            {Config?.newRx_add_title}
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isPractitioner &&
        (practitionerType === "Select" ? (
          <>
            <div className={`${s?.form_control}`}>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="practitionerName">
                  {Config?.General_prac_label}
                </Label>
                <FormInput<RegistrationFormFields>
                  name="practitionerName"
                  id="practitionerName"
                  type="text"
                  placeholder={Config?.newRx_practitioner_name_placeholder}
                  size="small"
                  register={register}
                  value={practitionerDetails?.name}
                  errors={errors}
                  disabled
                />
              </div>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="practitionerPhone">
                  {Config?.Practitioner_Contact_no}
                </Label>
                <PhoneFormInput<RegistrationFormFields>
                  name="phone"
                  placeholder={Config?.Pharmacy_Contact_no_placeholder}
                  register={register}
                  errors={errors}
                  control={control}
                  phoneInputRef={phoneInputRef}
                  clearErrors={clearErrors}
                  setError={setError}
                  disabled
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Label className={s["form_label"]} for="practitionerLocation">
                {Config?.Practitioner_location}
              </Label>
              <FormInput<RegistrationFormFields>
                name="location"
                id="location"
                type="text"
                placeholder={Config?.Pharmacy_location_placeholder}
                size="small"
                register={register}
                value={practitionerDetails?.location}
                errors={errors}
                disabled
              />
            </div>
          </>
        ) : (
          <>
            <div className={`${s?.form_control}`}>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="practitionerName">
                  {Config?.General_prac_label}
                </Label>
                <FormInput
                  name="practitionerName"
                  id="practitionerName"
                  type={Config?.lbl_Inp_type_text}
                  placeholder={Config?.newRx_practitioner_name_placeholder}
                  size={Config?.lbl_Inp_size_small}
                  register={register}
                  value={nameValue}
                  rules={{
                    required: msg_config?.newRx_pharmacy_required,
                  }}
                  errors={errors}
                  maxLength={100}
                  onChange={onNameInputChangeHandler}
                />
              </div>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="practitionerPhone">
                  {Config?.Practitioner_Contact_no}
                </Label>
                <PhoneFormInput
                  name="phone"
                  placeholder={Config?.Pharmacy_Contact_no_placeholder}
                  register={register}
                  errors={errors}
                  control={control}
                  maxLength={15}
                  phoneInputRef={phoneInputRef}
                  clearErrors={clearErrors}
                  setError={setError}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Label className={s["form_label"]} for="practitionerLocation">
                {Config?.Practitioner_location}
              </Label>
              <LocationSearchInput
                name="location"
                placeholder={Config?.Practitioner_location_placeholder}
                size="small"
                setState={setAddPractitionerAddress}
                state={addPractitionerAddress}
                setaddressDetails={setaddressDetails}
                rules={{ required: msg_config?.newRx_location_required }}
                register={register}
                setValue={setValue}
                watch={watch}
                clearErrors={clearErrors}
                errors={errors}
                isFormattedAddress={true}
                maxLength={150}
              />
            </div>
          </>
        ))}
    </>
  );
};

export default Rx;
