import { configureStore } from "@reduxjs/toolkit";
import { signUpVendorReducer } from "../pages/SignUpVendor/slice/slice";
import { paymentModeReducer } from "../components/Payments/slice/slice";
import { cartSliceReducer } from "../components/ProductDetails/slice/slice";
import { unitPricingDropdownReducer } from "../components/UnitPricingDropdown/slice";
import { myListPageReducer } from "../pages/MyListPage/slice";
const store = configureStore({
  reducer: {
    signUpVendorReducer,
    paymentModeReducer,
    cartSliceReducer,
    unitPricingDropdownReducer,
    myListPageReducer,
  },
});

export { store };
