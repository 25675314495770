import React from "react";
import CheckValidation from "../../../CheckValidation";
import s from "./index.module.css";
import PNG from "../../../../assets/file-svg/png_doc.svg";
import PDF from "../../../../assets/file-svg/pdf_doc.svg";
import { ArrowUpCircleFill, FileDelete } from "../../../../utils/appIcons";

const UploadButton = (props: any) => {
  const {
    contentInfo,
    onGetSize,
    onFileDeleteHandler,
    onFileUploadHandler,
    setContentInfo,
  } = props;
  const { 0: content } = contentInfo;

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  return (
    <CheckValidation
      show={!content?.name}
      fallback={
        <label
          className={`${s?._file_contents}`}
          style={{
            ...onGetSize(),
            ...props?.style,
          }}
        >
          <label className={`${s?._file_left_contents}`}>
            <img
              src={content?.type?.includes("pdf") ? PDF : PNG}
              width="30"
              alt="Document icon"
              draggable="false"
            />
            <label className={`${s.contents}`}>
              <p className={`${s.content_name}`}>{content?.name}</p>
              <p className={`${s.content_size}`}>{content?.size}</p>
            </label>
          </label>

          <label
            title="File delete button"
            className={`${s.content_delete}`}
            onClick={onFileDeleteHandler}
          >
            <img
              src={FileDelete}
              width="20"
              alt="File delete icon"
              draggable="false"
            />
          </label>
        </label>
      }
    >
      <label
        className={`${s?._file_upload} ${
          props.title ? "flex items-center" : "place-center"
        } `}
        style={{
          ...onGetSize(),
          ...props?.style,
        }}
      >
        <input
          name={props?.name}
          id={props?.name}
          type="file"
          onChange={onFileUploadHandler}
          multiple={props.multiple}
          accept="image/png,.image/jpeg ,application/pdf"
        />
        <span className="flex items-center gap-4">
          <ArrowUpCircleFill />
          {props.title && (
            <p className={`${s.upload_content}`}>{props.title}</p>
          )}
        </span>
      </label>
    </CheckValidation>
  );
};

export default UploadButton;
