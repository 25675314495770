import React from "react";

const Attachments = ({ file, onFileRemove }) => {
  /**************************  GET DOCUMENT TYPE START *******************************/
  const getDocType = (val) => {

    try {
      const text = val.split("/").pop();
      if (["jpeg", "png", "jpg", "gif"].includes(text)) {
        return "fa fa-picture-o";
      }
      if (["pdf"].includes(text)) {
        return "fa fa-file-pdf-o";
      }
      // if (["vnd.openxmlformats-officedocument.presentationml.presentation", "vnd.ms-powerpoint"].includes(text)) {
      //   return "fa fa-file-powerpoint-o"
      // }
      // if (["vnd.openxmlformats-officedocument.spreadsheetml.sheet", "vnd.ms-excel"].includes(text)) {
      //   return "fa fa-file-excel-o"
      // }
      return "fa fa-file-word-o";
    } catch (e) {
      console.log(e?.message, "ERROR :");
    }
  };

  /**************************  GET DOCUMENT TYPE END *******************************/
  try {
    return (
      <div className="image-container">
        <span onClick={() => onFileRemove(file)}>x</span>

        {getDocType(file?.type) === "fa fa-picture-o" ? (
          <img
            src={URL.createObjectURL(file)}
            height="50"
            width="50"
            alt={"file"}
          />
        ) : (
          <i className={file?.type && getDocType(file?.type)} />
        )}
      </div>
    );
  } catch (e) {
    console.log("Error:", e.message);
  }
};

export default Attachments;
