import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./index.module.css";
import Heading from "../../../../../components/UI/Heading";
import Config from "../../../../../config/label";
import { Link } from "react-router-dom";
import FormDigitInput from "../../../../../components/UI/FormDigitInput";
import { CallAPI } from "../../../../../suggest-us/callSuggest";
import { config } from "../../../../../config/processId";
import APIContext from "../../../../../store/api-context";
import useAnalyticsEventTracker from "../../../../../hooks/useAnalyticsEventTracker";
import EnterPassword from "../../EnterPassword";
import { UserAuthContext } from "../../../../../store/UserAuthContext.js";
import { msg_config } from "../../../../../config/messages";
import { getDecryptedID } from "../../../../../utils/utils";
import { FORGOT_PASSWORD_PHONE_NO } from "../../../../../config/Config";

const EnterOTP = (props: any) => {
  const {
    register,
    setIsAlert,
    // SetResendAlert,
    errors,
    clearErrors,
    setFocus,
    setValue,
  } = props;
  const phone_no = getDecryptedID(FORGOT_PASSWORD_PHONE_NO);

  const [suggestUsInit] = useContext(APIContext);
  const [context, setContext] = useContext(UserAuthContext);
  const gaEventTracker = useAnalyticsEventTracker("Forgot password screen");

  const SetResendAlert = (res: any) => {
    if (res?.returnCode === true) {
      // setIsAlert({ type: "success", msg: "Otp sent successfully!" });
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "success",
        msg: msg_config?.loginpage_otp_sent_success,
      }));
    }
    if (res?.returnCode === false) {
      // setIsAlert({ type: "error", msg: res?.msg });
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "error",
        msg: msg_config?.loginpage_otp_sent_fail,
      }));
    }
  };

  //Function call for resend otp
  const resendOTP = () => {
    setIsAlert({ istrue: false, type: "", msg: "" });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.resend_otp_process_id, {
        p_usr_id: "",
        p_phone_number: phone_no,
      }).then((res: any) => {
        if (res?.returnCode === true) {
          SetResendAlert(res);
          gaEventTracker("Resended OTP in forgot password screen");
          onClickReset();
        } else {
          SetResendAlert(res);
        }
      });
    }
  };

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref: any = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e: any) => {
    var now: any = new Date();
    const total: any = Date.parse(e) - Date.parse(now);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e: any) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:01:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + 1);
    // deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  return (
    <div className={`${Styles?.container}`}>
      {/* <Heading className={`${Styles?.page_heading}`}>
        {Config?.heading_OTP}
      </Heading> */}
      <div className={`${Styles?.content_wrapper}`}>
        <p className={`${Styles?.content}`}>{Config?.txt_OTP_number}</p>
        {/* <p className={`${Styles?.content}`}>
          {phone_no} */}
           {/* {Config?.txt_OTP_number_confirm} */}
        {/* </p> */}
      </div>

      <FormDigitInput
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        setFocus={setFocus}
        setValue={setValue}
      />

      <div className={`${Styles.verifyButton}`}>
        {timer === "00:00:00" ? (
          <>
            <p>{Config?.lbl_Not_Otp_rec}</p>
            <Link
              className={Styles["Link"]}
              to=""
              onClick={() => resendOTP()}
              draggable="false"
            >
              {Config?.lbl_Resend_otp}
            </Link>
          </>
        ) : (
          <p id="timer">{timer}</p>
        )}
      </div>
    </div>
  );
};

export default EnterOTP;
