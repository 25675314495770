import React from "react";
import Button from "../UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../config/button";
import Heading from "../UI/Heading";
import { useNavigate } from "react-router-dom";
import Cart from "../../assets/png/Cart.png";
import CloseIcon from "../../assets/svg/close.svg";
import Config from "../../config/label";
import { setEncryptedID } from "../../utils/utils";
import { CART_COUNT } from "../../config/Config";

export type ModalProps = {
  heading?: string;
  detail?: string;
  setModalOpen?: any;
  orderNumber?: any;
  subHeading?: any;
};

const OrderCompletion: React.FC<ModalProps> = (props) => {
  const { setModalOpen, heading, detail, orderNumber, subHeading } = props;
  let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setModalOpen({
              isActive: false,
              orderNumber: "",
            });
            navigate("/", { replace: true });
            setEncryptedID(CART_COUNT,"");
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={Cart} alt="Cart icon" draggable="false" />
          </div>
          <h3 className={`${Styles?.order_succesful_text}`}>Order successful</h3>
          <Heading className={`${Styles?.page_heading}`}>{heading}</Heading>

          <p>
            {subHeading}
            {orderNumber?.orderNumber}
          </p>
        </div>

        <Button
          type={Config?.lbl_Btn_type_primary}
          htmlType={Config?.lbl_Btn_action_submit}
          size={Config?.lbl_Btn_size_medium}
          onClick={() => {
            setModalOpen({
              isActive: false,
              orderNumber: "",
            });
            navigate("/", { replace: true });
            setEncryptedID(CART_COUNT,"");
          }}
          className={`${Styles?.modalBtn}`}
        >
          {btnConfig?.order_Completion}
        </Button>
      </div>
    </div>
  );
};
export default OrderCompletion;
