import { useState } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

export type LinkButtonProps = {
  title?: string;
  style?: object;
  onClick?: any;
  type?: string;
  to: any;
  size?: string;
  className?: string;
  draggable?: any;
};

/*  
    'type' prop for link button is optional
    without type props it will be displayed as normal link without text decoration, having default color
    and font size of 18px 

    Specify type of Link Button 
*   as  - primary    
        - secondary
        -ghost
        -ghost-primary
        -ghost-secondary
* 

*/

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { type, draggable } = props;
  const [onHoverStyle, setonHoverStyle] = useState({});

  const getHoverLeaveStyle = () => {
    setonHoverStyle({
      opacity: "1",
    });
  };

  const getHoverEnterStyle = () => {
    setonHoverStyle({
      opacity: "0.9",
    });
  };

  return (
    <Link
      to={props.to}
      onClick={props.onClick}
      style={{
        ...props?.style,
        ...onHoverStyle,
      }}
      onMouseEnter={getHoverEnterStyle}
      onMouseLeave={getHoverLeaveStyle}
      className={`${styles?._lnkStyle} ${styles[`${type}`]} ${
        props?.className
      }`}
      draggable={draggable}
    >
      {props?.children}
      {props?.title}
    </Link>
  );
};

export default LinkButton;
