import { useEffect, useState } from "react";
import ProductListScreen from "../ProductListScreen";
import SideFilter from "../ProductListScreen/SideFilter";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import ProductCardMerchant from "../../components/ProductCardMerchant";
const ProductList = () => {
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [productList, setProductList] = useState([]);
  const { typeID, manfID } = useParams();

  useEffect(() => {
    const getSideBar = async () => {
      // setSideBarLoading(true);
      //Api call for side bar filters
      const res = await CallAPI(config?.side_bar_product_list_process_id, {
        p_sg_id: typeID,
      });
      setSideBarFilters(res?.returnData);
    };

    (async () => {
      const res = await CallAPI(config?.product_list_process_id, {});
      setProductList(res?.returnData);
    })();

    getSideBar();
  }, []);
  return (
    <div className={`${styles?.productListContainer}`}>
      <div className={`${styles?.sideBarContainer}`}>
        {sideBarFilters.map((section, idx) => (
          <SideFilter
            key={section?.title}
            section={section}
            // listCheckBoxItems={listCheckBoxItems}
            // setListCheckBoxItems={setListCheckBoxItems}
            // resetPriceRange={resetPriceRange}
            // setResetPriceRange={setResetPriceRange}
            // reff={ref}
            // brandsItems={brandsItems}
            // setBrandsItems={setBrandsItems}
            minValue={0}
            maxValue={100}
            // setMinValue={setMinValue}
            // setMaxValue={setMaxValue}
            // sideBarLength={sideBarLength}
            idx={idx}
          />
        ))}
      </div>
      <div className={`${styles?.itemsContainer}`}>
        {productList?.map((product, i) => (
          <ProductCardMerchant
            key={"ProductCardMerchant" + i}
            type={product?.service_type}
            viewType={"LIST"}
            productUrl={"/product-detail/" + product?.srv_id}
            imgUrl={product?.srv_image.split("~")[0]}
            imgAlt={product?.srv_name}
            detail={product?.srv_name}
            packagingId={product?.srv_packaging}
            brand={product?.srv_brand}
            price={product?.discounted_amount}
            actualPrice={product?.srv_price}
            discountPercent={product?.discount_percent}
            coPay={product?.copayment_amount}
            ratingCount={
              product?.rating_view_flag === "Y" && product?.rating_star_count
            }
            isWishListAdded={product?.wshlst_status === "Y" ? true : false}
            ribbon={true}
            ribbonType="folded"
            onLikeButtonClick={() => {}}
            onAddtoCartButtonClick={() => {}}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
