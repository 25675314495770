import s from "./index.module.css";
export type FlexProps = {
  style?: object;
  className?: string;
};

const FlexBox: React.FC<FlexProps> = (props) => {
  const { style, className, children } = props;
  return (
    <div className={`${s?.flex} ${className}`} style={{ ...style }}>
      {children}
    </div>
  );
};

export default FlexBox;
