import React from "react";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../../config/label";
import { config } from "../../../config/processId.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { useEffect, useState } from "react";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../utils/utils";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { CONFIRM_DELETE_POPUP_IMAGE, USER_PROFILE } from "../../../config/Config";
const userProfileJson: any = getDecryptedID(USER_PROFILE);
// const userProfileJson = JSONCheckerFunc(userProfile);

export type ModalProps = {
  setModalOpen?: any;
  isDeleteConfirm?: any;
  getAllAddress?: any;
  propGetAllAddress?: any;
  propsGetDeafultAddress?: any;
};

const ConfirmDeletePopup: React.FC<ModalProps> = (props) => {

  const { setModalOpen, isDeleteConfirm, getAllAddress, propsGetDeafultAddress, propGetAllAddress } = props;
  const [isLoading, setLoading] = useState(false);


  const onConfirmDeleteHandler = () => {
    setLoading(true);
    CallAPI(config?.patient_address_delete_process_id, {
      p_patadd_id: isDeleteConfirm?.id,
    }).then((res) => {
      if (res?.returnCode) {
        getAllAddress();
        propGetAllAddress && propGetAllAddress();
        propsGetDeafultAddress && propsGetDeafultAddress();
        setModalOpen(false);
        setLoading(false);
      }
    });

  }

  //   let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={CONFIRM_DELETE_POPUP_IMAGE} alt="Cart icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            {Config?.lbl_delete_req_txt}
          </Heading>
          <p></p>
        </div>
        <div className={Styles["buttons"]}>
          <Button className={Styles["login_cancel_btn"]}
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}

            onClick={() => setModalOpen(false)}
          >
            {Config?.lbl_cancel_txt}
          </Button>

          {/* <Link
            to="/log-in"
            style={{ textDecoration: "none" }}
            draggable="false"
          > */}
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            loading={isLoading}
            onClick={onConfirmDeleteHandler}

          >
            {Config?.lbl_delete_txt}
          </Button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};
export default ConfirmDeletePopup;
