import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { twilioSendSMS } from "./Config/function";
import { getMessageStatus } from "./api";

import Attachments from "./Attachments";
import MyMessage from "./MyMessage";
import TheirMessage from "./TheirMessage";
import moment from "moment";
import axios from "axios";
import ConvSettings from "./ConvSettings/ConvSettings";

var readed = 0
var uploadedFile = []
var unread = []
const Conversation = (props) => {
  const {
    id,
    name,
    userType,
    singleConversation,
    twilioUrl,
    messageService,
    myIdentity,
    conversationProxy,
    onBack,
    unjoinedGroups,
    setSearchActive,
    otherConversations,
    setHideTabs,
    availableUsers,
    activeUsers,
    chatServiceSid,
    setChatState,
    isGuest,
    guestData
  } = props;
  const [state, setState] = useState({
    loadingState: "initializing",
    boundConversations: new Set(),
  });

  const myId = id
  const MAX_FILE_SIZE = 52428800; // Maximum file size allowed i.e 5MB

  const CONVERSATION_PAGE_SIZE = 20 // Maximum message per page 

  const inputRef = useRef(null); // Input filed Ref
  const inputTextRef = useRef(null)

  const enterRef = useRef(null); // Enter ref
  const [files, setFiles] = useState([]);
  const chatContainer = useRef(null); // Ref to chat container for scroll to bottom
  const showOptionsRef = useRef(null); //ref to show options
  const messageEndRef = useRef(null)
  const [messages, setMessages] = useState([]); // Set Incoming messages
  const [message, setMessage] = useState(""); // Set Message to send
  const [showOptions, setShowOptions] = useState(false); // more options i.e is leave button
  const [hide, setHide] = useState(false); // show hide media file before sending message
  const [mediaUrl, setMediaUrl] = useState([]); // store all media url
  const [tempFile, setTempFile] = useState([]); //
  const [height, setHeight] = useState(5000);
  const [heightBottom, setHeightBottom] = useState(5000)
  const [hasMore, setHasMore] = useState(
    messages.length === CONVERSATION_PAGE_SIZE
  );
  const [paginator, setPaginator] = useState(null);
  const [loading, setLoading] = useState(false);
  var messageList = [...new Set(messages?.map((v) => v))]
  const [scrollHeightLast, setScrollHeightLast] = useState(0)
  const [showSettings, setShowSettings] = useState(false)
  const [joinLoader, setJoinLoader] = useState(false)
  const [handleJoin, setHandleJoin] = useState(false)
  const [handleStart, setHandleStart] = useState(false)
  const [participant, setParticipant] = useState([])
  const [showConfirmDialog, SetShowConfirmDialog] = useState(false)
  const [leaveLoading, setLeaveLoading] = useState(false)


  const getMedia = async (val) => {
    try {
      const data = await Promise.all(
        val
          ?.filter((m) => !!m?.media !== false)
          ?.map(async (v) => {
            const url = await v?.media?.getContentTemporaryUrl();
            return {
              uri: url,
              doc_name: v?.media?.filename,
              sid: v?.media?.sid,
              fileType: v?.media?.contentType,
            };
          })
      );
      setMediaUrl(prev => [...data, ...prev]);
      messageService.sendMessage("TwilioChat", data?.concat(mediaUrl), "DocuViewer");

    } catch (err) {
      console.log("Error:", err.message);
    }
  };
  /************************** HANDLE GET MESSAGES START *******************************/
  const loadMessagesFor = async (thisConversation) => {
    try {
      if (conversationProxy === thisConversation) {
        // if mine conversation
        setSearchActive(false)
        thisConversation
          ?.getMessages(CONVERSATION_PAGE_SIZE) // get all messages
          .then((messagePaginator) => {
            if (conversationProxy === thisConversation) {
              if (conversationProxy?.attributes?.deletedUsr === id) {
                const list = messagePaginator?.items?.filter(m => m?.index > conversationProxy?.attributes?.lastMessageIndex)
                setMessages(list); // set messages
              }
              else {
                setMessages(messagePaginator?.items); // set messages
              }
              setTempFile([])
              setTimeout(() => {
                getMedia(messagePaginator?.items)
              }, 1500)
              setHasMore(messagePaginator.hasPrevPage);
              setPaginator(messagePaginator);
              setState((prev) => ({
                ...prev,
                loadingState: "ready",
              }));
              conversationProxy?.getUnreadMessagesCount()?.then(res => {
                if (res !== null) {
                  messageService.sendMessage("TopHeader", res, "read");
                }
              })
            }
          })
          .catch((err) => {
            console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
            setState((prev) => ({ ...prev, loadingState: "failed" }));
          });

        if (thisConversation?.attributes?.type === "queue") {
          setTimeout(() => {
            setParticipant([])
            thisConversation?._participants?.forEach((v) => {
              setParticipant(prev => [...prev, v])
            })
          }, 1000)
          setHandleJoin(
            !thisConversation?.attributes?.joined_user_id?.includes(id)
          )
        }
      }
    }
    catch (e) {
      console.log("Error :", e?.message)
    }
  };

  useEffect(() => { loadMessagesFor(conversationProxy) }, [conversationProxy]);

  useEffect(() => {
    inputTextRef?.current?.focus();
  });

  const getTotalCount = async (val) => {
    const data = await Promise.all(
      val?.map((v) => {
        return v?.getUnreadMessagesCount()
      }))
    messageService.sendMessage("ChatModal", data.reduce((p, c) => p + c, 0), "totalCount");
  }

  useEffect(() => {
    if (participant?.length > 0 && conversationProxy?.attributes?.type === "queue") {
      if (participant?.length === 1) {
        setHandleStart(true)
      }
      else {
        setHandleStart(false)
      }
    }

  }, [participant, conversationProxy])
  // in this useeffect we will check working hours logic coming from backend and implement logic accordingly 
  useEffect(() => {
      setTimeout(() => {
        // first check if you get any unjoined groups
        if(unjoinedGroups!=null && unjoinedGroups!=undefined){
          if(unjoinedGroups.length>0){
             unjoinedGroups.forEach(async (val) => {
                let cg_id=val.cg_id;
                let availability_flag=val.availability_flag;
                let contact_group_greeting_message=val.contact_group_greeting_message;
                let contact_group_offline_message=val.contact_group_offline_message;
                // now match cg_id if match then we need to setup offline working hours logic
                if(conversationProxy.attributes.cg_id ===cg_id){
                    //now check if availability of admin is Y or no
                    if(availability_flag==="Y"){ // means if admin has online hours then we will send welcome message
                     // send message in conversation
                         await axios({
                           method: "POST",
                           url: `${twilioUrl}/chat/createMessage`,
                           data: {
                             body: {
                               sid: conversationProxy?.sid,
                               chatServiceSid,
                               identity: id,
                               body: contact_group_greeting_message
                             },
                             attributes: {
                               type: "welcome",
                               action: "welcome"
                             }
                           },
                           headers: {
                             "Content-Type": "application/json",
                           },
                         })
                    }else{// means admin is offlien we will send offline message to user
                         // send message in conversation
                         await axios({
                           method: "POST",
                           url: `${twilioUrl}/chat/createMessage`,
                           data: {
                             body: {
                               sid: conversationProxy?.sid,
                               chatServiceSid,
                               identity: id,
                               body: contact_group_offline_message
                             },
                             attributes: {
                               type: "welcome",
                               action: "welcome"
                             }
                           },
                           headers: {
                             "Content-Type": "application/json",
                           },
                         })  
                    }
                }
             });
          }
        }
        // check if user is guest user or normal user. In case user is guest user then send guest data to admin
        if(isGuest){
           let guest_name=guestData.name;
           let guest_email=guestData.email;
           let name_info_message="Guest Name: "+guest_name;
           let email_info_message="Guest Email: "+guest_email;
              axios({
              method: "POST",
              url: `${twilioUrl}/chat/createMessage`,
              data: {
                body: {
                  sid: conversationProxy?.sid,
                  chatServiceSid,
                  identity: id,
                  body: name_info_message
                },
                attributes: {
                  type: "guest_info",
                  action: "guest_info"
                }
              },
              headers: {
                "Content-Type": "application/json",
              },
            })
            axios({
              method: "POST",
              url: `${twilioUrl}/chat/createMessage`,
              data: {
                body: {
                  sid: conversationProxy?.sid,
                  chatServiceSid,
                  identity: id,
                  body: email_info_message
                },
                attributes: {
                  type: "guest_info",
                  action: "guest_info"
                }
              },
              headers: {
                "Content-Type": "application/json",
              },
            })
        }
      }, 2000);
  }, [])

  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((m) => {
      if (m.senderId === "message_added" && m.target === "mediaUrl") {
        setHeightBottom(5000)
        if (conversationProxy?.sid === m?.text?.conversation?.sid) {
          if (m?.text?.media?.sid) {
            m?.text?.media?.getContentTemporaryUrl().then(url => {
              const body = {
                uri: url,
                doc_name: m?.text?.media?.filename,
                sid: m?.text?.media?.sid,
                fileType: m?.text?.media?.contentType,
              };
              setMediaUrl(prev => [...prev, body]);
              setMessages(prev => prev?.map((v, index) => {
                if (uploadedFile?.includes(v?.fileUri)) {
                  return messages[index] = m.text
                }
                return v
              }))
              setMessages(prev => [...prev, m.text])
              messageService.sendMessage("TwilioChat", mediaUrl?.concat([body]), "DocuViewer");

            })
          }
          else {
            setMessages(prev => [...prev, m.text])
          }
        }
        else {
          getTotalCount(otherConversations)
          messageService.sendMessage(
            "ChatModal",
            {
              count: 1,
              sound: true
            },
            "playSound"
          );
        }
      }
      if (m.senderId === "handleStart" && m.target === "twilioChat") {

        setHandleStart(false)
      }
      if (m.senderId === "startChat" && m.target === "twilioChat") {

        if (conversationProxy?.sid === m?.text?.sid) {
          setParticipant(m?.text?.participant)
        }
      }
    })
    return () => {
      // if (subscription != null && subscription != undefined) {
      //   subscription.unsubscribe();
      // }

    }
  }, [])
  /************************** HANDLE GET MESSAGES END *******************************/
  // Removig the duplicates if any
  const fetchMore = async (val) => {
    try {
      if (!val) {
        return;
      }
      setLoading(true);
      setHeightBottom(800)
      val?.prevPage(CONVERSATION_PAGE_SIZE).then(res => {
        setMessages(prev => [...res?.items, ...prev])
        setPaginator(res);
        setHasMore(res.hasPrevPage);
        setLoading(false);
        setTimeout(() => {
          getMedia(res?.items)
        }, 1500)
      });
    }
    catch (e) {
      setLoading(false);
      console.log("Error :", e?.message);
    }
  };


  // /************************** HANDLE SCROLL TO BOTTOM START *******************************/

  useEffect(() => {
    chatContainer?.current?.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      if (messageList.length > CONVERSATION_PAGE_SIZE) {
        target.scroll({ top: heightBottom });
      }
      else {
        target.scroll({ top: target.scrollHeight });
      }
    })
  }, [messageList, heightBottom])

  const markReadAll = async (conv) => {

    await conv?.getUnreadMessagesCount().then(res => {
      if (res !== null) {
        messageService.sendMessage("TopHeader", res, "read");
      }
    })
  }
  useEffect(() => {
    if (messageList?.length && messageList[messageList.length - 1].index !== -1 && unread?.length === 0) {
      conversationProxy.updateLastReadMessageIndex(messageList[messageList.length - 1].index);
      markReadAll(conversationProxy)
    }
  }, [messageList, conversationProxy, unread])
  /************************** HANDLE SCROLL TO BOTTOM END *******************************/

  /************************** HANDLE ADD MESSAGE START *******************************/
  const onMessageChanged = async (event) => {
    try {
      setMessage(event?.target?.value);
      await conversationProxy.typing();
    }
    catch (e) {
      console.log("Error:", e?.message)
    }
  };

  /************************** HANDLE ADD MESSAGE END *******************************/

  /************************** HANDLE SEND MESSAGE OR MEDIA START *******************************/

  const handleJoinUpdate = async (val) => {
    try {
      setJoinLoader(true)
      if (val?.attributes?.assign_to?.usr_id) {
        await axios({
          method: "POST",
          url: `${twilioUrl}/chat/createMessage`,
          data: {
            body: {
              sid: val?.sid,
              chatServiceSid,
              identity: id,
              body: `${val?.attributes?.assign_to?.usr_name} has joined the conversation`
            },
            attributes: {
              type: "info",
              joinedId: id,
              action: "join",
              staffs: val?.attributes?.staffs?.map(v => v?.usr_id)
            }
          },
          headers: {
            "Content-Type": "application/json",
          },
        })

        const joined_user_id = val?.attributes?.joined_user_id?.filter(v => v !== val?.attributes?.assign_by?.usr_id)
        const attributes = {
          ...val?.attributes,
          joined_user_id: [...joined_user_id, id],
        }
        await axios({
          method: "POST",
          url: `${twilioUrl}/chat/createMessage`,
          data: {
            body: {
              sid: val?.sid,
              chatServiceSid,
              identity: val?.attributes?.assign_by?.usr_id,
              body: `${val?.attributes?.assign_by?.usr_name} has left the conversation`
            },
            attributes: {
              type: "queue",
              leftId: val?.attributes?.assign_by?.usr_id,
              action: "leave",
              staffs: val?.attributes?.staffs?.map(v => v?.usr_id)
            }
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        const parti = []
        val?._participants?.forEach(v => {

          return parti?.push(v)

        })
        const remainingParti = parti?.filter(p => !attributes?.joined_user_id?.includes(p?.identity))
        remainingParti?.forEach(async (c) => {
          await val?.removeParticipant(c?.sid)
        })
        delete attributes.assign_by;
        delete attributes.assign_to;
        await val?.updateAttributes(attributes);
        const includingMe = [{ value: id, label: name }]
        includingMe?.forEach(async (l) => {
          val?._participants?.forEach(async (v) => {
            if (v?.identity === l?.value) {
              await axios({
                method: "POST",
                data: {
                  partiSid: v?.sid,
                  convoSid: val?.sid,
                  attributes: {
                    userID: l?.value,
                    userName: l?.label,
                  },
                },
                url: `${twilioUrl}/chat/updateParti`,
                headers: {
                  "Content-Type": "application/json",
                },
              });
            }
          })
        })
        setHandleJoin(false)
        setJoinLoader(false)

      }
      else {
        await axios({
          method: "POST",
          url: `${twilioUrl}/chat/createMessage`,
          data: {
            body: {
              sid: val?.sid,
              chatServiceSid,
              identity: id,
              body: `${name} has joined the conversation`
            },
            attributes: {
              type: "info",
              action: "join",
              staffs: val?.attributes?.staffs?.map(v => v?.usr_id)
            }
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        const attributes = {
          ...val?.attributes,
          joined_user_id: [...val?.attributes?.joined_user_id, id],
        }

        const parti = []
        val?._participants?.forEach(v => {
          if (v?.identity !== id) {
            return parti?.push(v)
          }
        })
        const remainingParti = parti?.filter(p => !val?.attributes?.joined_user_id?.includes(p?.identity))
        remainingParti?.forEach(async (c) => {
          await val?.removeParticipant(c?.sid)
        })
        await val?.updateAttributes(attributes);
        const includingMe = [{ value: id, label: name }]
        includingMe?.forEach(async (l) => {
          val?._participants?.forEach(async (v) => {
            if (v?.identity === l?.value) {
              await axios({
                method: "POST",
                data: {
                  partiSid: v?.sid,
                  convoSid: val?.sid,
                  attributes: {
                    userID: l?.value,
                    userName: l?.label,
                  },
                },
                url: `${twilioUrl}/chat/updateParti`,
                headers: {
                  "Content-Type": "application/json",
                },
              });
            }
          })
        })
        setHandleJoin(false)
        setJoinLoader(false)

      }

    }
    catch (e) {
      console.log("Error :", e?.message)
      setJoinLoader(false)
    }
  }

  const onMessageSend = async (event) => {
    event.preventDefault();
    try {
      if (handleJoin && !joinLoader) {
        handleJoinUpdate(conversationProxy)
      }
      else if (handleStart && !joinLoader) {
        setJoinLoader(true)
        conversationProxy?.attributes?.staffs?.forEach(async (v) => {
          await conversationProxy?.add(v?.usr_id)
        })
        setJoinLoader(false)
        setHandleStart(false)
      }
      else {
        setHeightBottom(scrollHeightLast)
        readed++
        // const { convo, client, messages } = props;
        let removed = []
        conversationProxy?._participants.forEach(async (p) => {
          conversationProxy?.attributes?.users?.forEach((i) => {
            if (i?.usr_id !== p?.identity) {
              removed.push(i)
            }
          })
        })

        if (removed?.length > 0) {
          removed?.forEach(async (r) => {
            await conversationProxy.add(r?.usr_id);
          })
        }

        const messagesToSend = [];
        const messagesData = [];
        const currentDate = new Date();

        // SEND TEXT MESSAGE
        if (message) {
          const newMessage = Object.assign({}, messages[messages.length], {
            ...messages[messages.length],
            author: myIdentity,
            body: message,
            attributes: {},
            dateCreated: currentDate,
            index: -1,
            participantSid: "",
            sid: conversationProxy.sid,
            aggregatedDeliveryReceipt: null,
          });
          //add message to state
          messagesToSend.push(newMessage);
          messagesData.push(message);
          if (conversationProxy?.attributes?.subType === 'sms') {
            twilioSendSMS(twilioUrl, conversationProxy?.attributes?.users.filter(i => i?.usr_id !== myId)[0]?.usr_id, message)
          }
          //if promise is filled then is sent. If not failed. Update state of message
          //change state for the message to sent (or failed)
        }

        // SEND MEDIA MESSAGE
        const sortedFiles = files.sort((a, b) => (a.size > b.size) ? 1 : -1)
        for (const file of sortedFiles) {
          const newMessage = Object.assign({}, messages[messages.length], {
            ...messages[messages.length],
            author: myIdentity,
            body: null,
            attributes: {},
            dateCreated: currentDate,
            index: -1,
            participantSid: "",
            sid: conversationProxy.sid,
            aggregatedDeliveryReceipt: null,
            fileUri: URL.createObjectURL(file),
            media: {
              size: file.size,
              filename: file.name,
              contentType: file.type,
              fileUri: URL.createObjectURL(file)
            },
          });
          //add message to state
          setMessages(prev => [...prev, newMessage])
          messageList.push(newMessage)
          messagesToSend.push(newMessage);
          messagesData.push({ fileUri: newMessage?.fileUri, file });
        }
        setMessage("");
        document.getElementById("add-message-input").value = ""; //Empty the message input field
        setHide(true);
        setFiles([]);
        // SENDING MEDIA FILES ONE BY ONE
        const indexes = [];
        for (const msg of messagesData) {
          if (msg?.file) {
            const fileData = new FormData();
            fileData.append('file', msg?.file);
            const index = await conversationProxy.sendMessage(fileData)
            indexes.push(index);
            uploadedFile?.push(msg?.fileUri)
          }
          else {
            const index = await conversationProxy.sendMessage(msg)
            indexes.push(index);
          }
        }
        await conversationProxy.updateLastReadMessageIndex(Math.max(...indexes));
      }
    } catch (e) {
      setJoinLoader(false)
      console.log("Error", e?.message);
    }
  };


  /************************** HANDLE SEND MESSAGE OR MEDIA END *******************************/
  /************************** HANDLE LEAVE CONVERATION  START *******************************/
  const handleLeave = async () => {
    try {
      messageService.sendMessage("Conversation_leave", {}, "TwilioConv");
      setLeaveLoading(true)
      await conversationProxy?.setAllMessagesRead()
      // onBack("");
      // setFiles([]);
      // // setTabs("CHAT")
      // setHideTabs(false)
      if (conversationProxy?.attributes?.type === "queue") {
        const joined_user_id = conversationProxy?.attributes?.joined_user_id?.filter(v => v !== id)
        const chatEnd = !conversationProxy?.attributes?.staffs?.map(v => v?.usr_id)?.includes(id) ? "client" : "staff"
        const attributes = {
          ...conversationProxy?.attributes,
          joined_user_id: [...joined_user_id],
          chatEnd
        }
        await conversationProxy?.updateAttributes(attributes)
        await axios({
          method: "POST",
          url: `${twilioUrl}/chat/createMessage`,
          data: {
            body: {
              sid: conversationProxy?.sid,
              chatServiceSid,
              identity: id,
              body: `${name} has end the conversation`
            },
            attributes: {
              type: "info",
              action: "leave"
            }
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
         // reset auto join values
         messageService.sendMessage("Conversation_left", {}, "TwilioConv");
         // close chat conversation
         setTimeout(() => {
           messageService.sendMessage("TopHeader", false, "chat_side_modal");
        }, 1000);
      }
      if (conversationProxy?.attributes?.type === "group") {
        await axios({
          method: "POST",
          url: `${twilioUrl}/chat/createMessage`,
          data: {
            body: {
              sid: conversationProxy?.sid,
              chatServiceSid,
              identity: id,
              body: `${name} has left the conversation`
            },
            attributes: {
              type: "info",
              action: "leave"
            }
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
      }
      if (conversationProxy?.attributes?.type === "single") {
        const attributes = {
          ...conversationProxy?.attributes,
          lastMessageReadIndex: conversationProxy?.lastReadMessageIndex,
          lastMessageIndex: conversationProxy?.lastMessage?.index,
          deletedUsr: id,

        }
        await conversationProxy?.updateAttributes(attributes)
      }
      await conversationProxy?.leave();
      setTimeout(() => {
        setChatState((prev) => {
          return { ...prev, isQueueJoined: false, joinedQueueSid: null }
        })
        setLeaveLoading(false)
      }, 1000);
      // toast.success("Support request closed.");
    }
    catch (e) {
      console.log("Error:", e?.message)
      setLeaveLoading(false)
    }

  };

  /************************** HANDLE LEAVE CONVERATION  START *******************************/

  /************************** HANDLE FILE ONCHANGE EVENT  END *******************************/
  const onFilesChange = async (e) => {
    const { files: assets } = e.target;
    setHide(false);
    await conversationProxy.typing();
    if (!assets?.length) {
      return;
    }
    if (assets?.length > 10) {
      setFiles([]);
      toast.error("Maximum 10 Files Allowed.");
    } else {
      const validFiles = Array.from(assets).filter(
        ({ size }) => size < MAX_FILE_SIZE + 1
      );
      if (validFiles.length < assets.length) {
        // TODO: show error
        toast.error("File must less then 5MB.");
      }
      setFiles((prev) => [...prev, ...validFiles]);
    }
    e.target.value = null;
  };

  const staffsId = conversationProxy?.attributes?.staffs?.map(v => v?.usr_id)

  /************************** HANDLE FILE ONCHANGE EVENT  END *******************************/

  /************************** HANDLE FILE REMOVE START *******************************/

  const onFileRemove = (v) => {
    const newFiles = files?.filter((fi) => fi !== v);
    setFiles(newFiles);
  };
  /************************** HANDLE FILE REMOVE END *******************************/

  /************************** GET MEDIA TYPE  START *******************************/

  const getDocType = (val) => {
    try {
      const text = val?.split("/").pop();
      if (["jpeg", "png", "jpg", "gif"].includes(text)) {
        return "fa fa-picture-o";
      }
      if (["pdf"].includes(text)) {
        return "fa fa-file-pdf-o";
      }
      if (["vnd.openxmlformats-officedocument.presentationml.presentation", "vnd.ms-powerpoint"].includes(text)) {
        return "fa fa-file-powerpoint-o"
      }
      if (["vnd.openxmlformats-officedocument.spreadsheetml.sheet", "vnd.ms-excel"].includes(text)) {
        return "fa fa-file-excel-o"
      }
      return "fa fa-file-word-o";
    } catch (e) {
      console.log(e?.message, "ERROR :");
    }
  };

  /************************** GET MEDIA TYPE  END *******************************/

  /************************** DROP FILE HANDLER  START *******************************/
  const onDrop = (acceptedFiles) => {
    const assets = acceptedFiles;
    if (!assets?.length) {
      return;
    }
    if (assets?.length > 10) {
      setFiles([]);
      toast.error("Maximum 10 Files Allowed.");
    } else {
      const validFiles = Array.from(assets).filter(
        ({ size }) => size < MAX_FILE_SIZE + 1
      );

      if (validFiles.length < assets.length) {
        // TODO: show error
        toast.error("File must less then 5MB.");
      }
      setFiles((prev) => [...prev, ...validFiles]);
    }
    e.target.value = null;
  };
  /************************** DROP FILE HANDLER  END *******************************/

  /************************** GET OTHER PARTICIEPENT DATA START *******************************/
  var participants = []
  conversationProxy?._participants?.forEach((v) => { participants.push(v) });

  /************************** GET OTHER PARTICIEPENT DATA END *******************************/

  /************************** GET MEDIA URL START *******************************/



  /************************** GET MEDIA URL END *******************************/

  /************************** GET Author Name START  *******************************/
  const getUserName = (message) => {
    var name = ""
    if (conversationProxy?.attributes?.type === "single") {
      conversationProxy?.attributes?.users?.forEach((v) => {
        if (message?.author === v?.usr_id) {
          name = v?.usr_name
        }
      })
    }
    else {
      conversationProxy?.attributes?.userIds?.forEach((v) => {
        if (message?.author === v?.usr_id) {
          name = v?.usr_name
        }
      })
    }
    return name
  };
  /************************** GET Author Name END  *******************************/
  /************************** GROUPING THE MESSAGE LIST WITH DATE START  *******************************/

  unread = readed === 0 && messageList?.filter((m) => conversationProxy?.lastReadMessageIndex !== null && m?.index > conversationProxy?.lastReadMessageIndex && m.author !== myIdentity) || []

  const newUnread = unread?.filter(m => !m?.attributes?.staffs?.includes(id) && m?.attributes?.type !== "queue")

  const groups = [...messageList]?.reduce((groups, message) => {
    const date = moment(message.dateCreated).format("ll");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  // Groupeded messages with date
  const groupedMessages = Object.keys(groups).map((date) => {

    return {
      date,
      messageses: newUnread?.length > 0 ? groups[date].filter(n => !newUnread.includes(n)) : groups[date],
    };
  });

  const groupsUnRead = [...newUnread]?.reduce((groups, message) => {
    const date = moment(message.dateCreated).format("ll");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});


  // Groupeded messages with date
  const groupedUnreadMessages = Object.keys(groupsUnRead).map((date) => {
    return {
      date,
      messageses: groupsUnRead[date],
    };
  });


  /************************** GROUPING THE MESSAGE LIST WITH DATE END  *******************************/

  const getName = (val) => {
    const name = val?.users?.find((v) => v?.usr_id !== id)
    return name?.usr_name
  }

  const handleScroll = (e) => {
    let element = e.target
    setHeight(element.scrollTop)
    setScrollHeightLast(element?.scrollHeight)
    if (element.scrollTop === 0 && hasMore) {
      fetchMore(paginator)
    }
    // setLoading(false);
  }

  const getUri = (val) => {
    const uri = mediaUrl?.find(v => v?.sid === val)
    return uri?.uri
  }

  const handleClickOutside = (event) => {
    if (!showOptionsRef?.current?.contains(event.target)) {
      setShowOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSettings = () => {
    if (conversationProxy?.attributes?.type === "group" || conversationProxy?.attributes?.type === "queue" && !handleJoin) {
      setShowSettings(true)
    }
    else {
      return false
    }
  }


  const isOnline = activeUsers?.map((v) => v?.identity)
  const getOnlineStatus = (val) => {
    var value = false
    val?._participants?.forEach((v) => {
      if (v?.identity !== id) {
        if (isOnline?.includes(v?.identity))
          value = true
      }
    })

    return value
  }

  const handleBack = async () => {
    if (!showSettings) {
      const index = messageList[messageList.length - 1]?.index || 0
      if (conversationProxy?.attributes?.type === "single") {
        const attributes = {
          ...conversationProxy?.attributes,
          lastMessageReadIndex: index,
        }
        await conversationProxy?.updateAttributes(attributes)
      }
      await conversationProxy?.updateLastReadMessageIndex(index);
      setFiles([]);
      setTempFile([])
      readed = 0
      uploadedFile = []
      // setTabs("CHAT")
      onBack("");
      setHideTabs(false)
    }
    else {
      setShowSettings(false)
    }

  }
  try {
    return (
      <div className="chat-container" style={{ minHeight: "380px" }}>
        <div className="chat-header">
          {conversationProxy?.attributes?.type === "queue" && userType === 'patient' ?
               null
            :
           <>
            {singleConversation === false ?
                < div
                    className="padding-2"
                    onClick={() => handleBack()
                    }>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </div>
            :
            <>
              {showSettings === true ?
                  < div
                      className="padding-2"
                      onClick={() => handleBack()
                      }>
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  </div>
              :
                  <></>
              }
            </>
            }
           </>
          }
          {/* onClick={handleSettings} */}
          <div className="title" >    
            <div className="icon chat_modal_loading">
              {conversationProxy?.attributes?.contact_group_image ?
                <img src={conversationProxy?.attributes?.contact_group_image} height={30} width={30} alt="" />
                : conversationProxy?.attributes?.type === "group" || conversationProxy?.attributes?.type === "queue" ?
                   <span className="spinner-border spinner-border-sm loadingSpinner marginTop-1" role="status" aria-hidden="true"></span>
                  :
                  <>
                   <span className="spinner-border spinner-border-sm loadingSpinner marginTop-1" role="status" aria-hidden="true"></span>
                    {conversationProxy?.attributes?.subType === undefined ?
                      <div className={getOnlineStatus(conversationProxy) ? "status-circle-online" : "status-circle-offline"}></div>
                      :
                      <div className="sms">sms</div>
                    }
                  </>
              }
            </div>
            <div className="name">
              {conversationProxy?.attributes?.type === "queue" ? conversationProxy?.createdBy === id ? conversationProxy?.attributes?.friendlyName?.split("-")[0] : conversationProxy?.attributes?.friendlyName : conversationProxy?.attributes?.type === "group" ? conversationProxy?.friendlyName
                : getName(conversationProxy?.attributes)
              }
            </div>

          </div>
          {!showSettings && !handleJoin &&
            < div
              ref={showOptionsRef}
              className="chat-options"
              onClick={() => SetShowConfirmDialog(!showConfirmDialog)}
              title={conversationProxy?.attributes?.type === "queue" ? "End chat" : "Leave chat"}
            >

              <div className="option" >
                <i className={conversationProxy?.attributes?.type === "queue" ? "fa fa-sign-out" : "fa-solid fa-arrows-turn-right"} aria-hidden="true"> </i>
              </div>
            </div>}

        </div>
        {/* File Drop zone */}
        {
          !showSettings ? <> {<Dropzone onDrop={conversationProxy?.attributes?.subType === undefined && onDrop} >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} onClick={() => { }} id="OpenChannel">
                <div className="chat-message">
                  <div className="conversations-messages">
                    <div>
                      {loading && <div style={{ position: 'absolute', top: "60px", left: '50%' }}>
                        <span
                          className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>}
                      <div ref={chatContainer} className="chat-overflow"
                        onScroll={handleScroll}
                      >
                        {showConfirmDialog &&
                          <div className="modal_backdrop" >
                            <div className="modal_container">
                              <h3 className="modal_title">
                                <button
                                  className="ui-button-textOnly closeBtn"
                                  title={"close"}
                                  onClick={() => SetShowConfirmDialog(!showConfirmDialog)}
                                >
                                  <i className="fa">&#x2715;</i>
                                </button>
                              </h3>
                              <p>
                                Are you sure you want to {conversationProxy?.attributes?.type === "queue" ? "end" : "leave"} the conversation?
                              </p>

                              <div className="modal_footer">
                                <button
                                  type="button"
                                  title="Cancel"
                                  className="ui-button-outline btn-vhelp"
                                  onClick={() => SetShowConfirmDialog(!showConfirmDialog)}
                                >
                                  No
                                </button>
                                <button
                                  type="button"
                                  title="Cancel"
                                  className="ui-button-default btn-vhelp"
                                  onClick={handleLeave}
                                >
                                  {leaveLoading ? <span
                                    className="spinner-border spinner-border-sm loadingSpinner"
                                    role="status"
                                    aria-hidden="true"
                                  ></span> : "Yes"}
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                        {isDragActive && conversationProxy?.attributes?.subType === undefined && (
                          <div
                            className="drapUpload"
                            style={{
                              position: "absolute",
                              top: "0",
                              zIndex: "10",
                            }}
                          >
                            <div className="dropzone">
                              <i className="fa fa-upload" aria-hidden="true"></i>
                              <h6>Drop here to upload</h6>
                            </div>
                          </div>
                        )}

                        {state.loadingState === "ready" ? (<>
                          {groupedMessages?.map((v) => {
                            const groupedMessageList = v?.messageses;
                            const tempList = [...groupedMessageList, ...tempFile]
                            const keys = Object.keys(tempList); // Getting the index value of groupedMessageList
                            return (
                              <>
                                {groupedUnreadMessages?.length === 0 && <div className="dates">
                                  {moment().subtract(1, "days").format("ll") ===
                                    v?.date
                                    ? "Yesterday"
                                    : moment().format("ll") === v?.date
                                      ? "Today"
                                      : v?.date}
                                </div>}
                                {keys?.map((key, index) => {
                                  const message = tempList[key];
                                  const lastMessageKey =
                                    index === 0 ? null : keys[index - 1]; //lastMessage index
                                  const isMyMessage =
                                    myIdentity === message.author; //isMyMessage author
                                  return (
                                    <div
                                      id={'unread'}
                                      key={`msg_${index}`}
                                      style={{ width: "100%" }}
                                      className="chats"
                                    >
                                      {isMyMessage ? (
                                        <MyMessage
                                          message={message}
                                          messageService={messageService}
                                          lastMessage={
                                            tempList[lastMessageKey]
                                          }
                                          getDocType={getDocType(
                                            message?.media?.contentType
                                          )}
                                          setTempFile={setTempFile}
                                          tempFile={tempFile}
                                          handleDelete={(e) => {
                                            e.remove()
                                            setMessages(prev => prev?.filter(k => k !== e))
                                          }}
                                          mediaUrl={getUri(message?.media?.sid)}
                                          conversationProxy={conversationProxy}
                                          author={
                                            message?.author === myIdentity &&
                                            name
                                          }
                                          getStatus={getMessageStatus(
                                            conversationProxy,
                                            message,
                                            conversationProxy?._participants
                                          )}
                                          myIdentity={myIdentity}

                                          participant={
                                            conversationProxy?._participants
                                          }
                                        />
                                      ) : (
                                        <TheirMessage
                                          id={id}
                                          message={message}
                                          messageService={messageService}
                                          getDocType={getDocType(
                                            message?.media?.contentType
                                          )}

                                          mediaUrl={getUri(message?.media?.sid)}
                                          lastMessage={
                                            tempList[lastMessageKey]
                                          }
                                          author={
                                            getUserName(message)
                                          }
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            );
                          })}
                          {groupedUnreadMessages?.length > 0 &&
                            <>
                              <div className="unread">
                                {newUnread?.length > 1 && newUnread?.length} Unread Message
                              </div>
                              {groupedUnreadMessages?.map((v) => {
                                const groupedMessageList = v?.messageses;
                                const tempList = [...groupedMessageList, ...tempFile]
                                const keys = Object.keys(tempList); // Getting the index value of groupedMessageList
                                return (
                                  <>
                                    <div className="dates">

                                      {moment().subtract(1, "days").format("ll") ===
                                        v?.date
                                        ? "Yesterday"
                                        : moment().format("ll") === v?.date
                                          ? "Today"
                                          : v?.date}
                                    </div>

                                    {keys?.map((key, index) => {
                                      const message = tempList[key];
                                      const lastMessageKey =
                                        index === 0 ? null : keys[index - 1]; //lastMessage index
                                      const isMyMessage =
                                        myIdentity === message.author; //isMyMessage author

                                      return (
                                        <div
                                          key={`msg_${index}`}
                                          style={{ width: "100%" }}
                                          className="chats"
                                        >
                                          {!isMyMessage && (
                                            <TheirMessage
                                              id={id}
                                              messageService={messageService}
                                              message={message}
                                              getDocType={getDocType(
                                                message?.media?.contentType
                                              )}

                                              mediaUrl={getUri(message?.media?.sid)}
                                              lastMessage={
                                                tempList[lastMessageKey]
                                              }
                                              author={
                                                getUserName(message)
                                              }
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </>
                          }
                          <div ref={messageEndRef}></div>
                        </>

                        ) : (
                          <div className="messageLoading">
                            <span
                              className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        )}
                      </div>
                      {/* </InfiniteScroll> */}
                    </div>
                  </div>

                  <form onSubmit={onMessageSend} >
                    {!handleJoin ?
                      handleStart ? conversationProxy?.attributes?.chatEnd === "client" ? " " :
                        <div className="padding-2">
                          <button type='submit' className="ui-button-default btn-vhelp" style={{ width: "100%" }} >
                            {joinLoader ? <span
                              className="spinner-border spinner-border-sm loadingSpinner"
                              role="status"
                              aria-hidden="true"
                            ></span> : "START"}
                          </button>
                        </div>
                        :
                        <div className="input-area">
                          {files?.length > 0 && (
                            <div
                              className="chat-files  "
                              style={{ display: hide && "none" }}
                            >
                              {files?.map((f, index) => (
                                <Attachments
                                  key={index}
                                  file={f}
                                  onFileRemove={(e) => {
                                    onFileRemove(e);
                                  }}
                                />
                              ))}
                            </div>
                          )}
                          {conversationProxy?.attributes?.subType === undefined &&
                            <div className="">
                              <div
                                className="ui-button-outline btn-vhelp chatbox-attachbtn"
                                onClick={() => {
                                  inputRef.current.click();
                                  uploadedFile = []
                                }}
                              >
                                <i className="fa fa-paperclip" aria-hidden="true"></i>
                                <input
                                  id="add-message-input"
                                  ref={inputRef}
                                  type="file"
                                  accept={['.png', '.gif', '.jpeg', '.jpg', '.pdf',]}
                                  // accept={['.png', '.gif', '.jpeg', '.jpg', '.doc', '.docx', '.pdf', '.pptx', '.ppt', '.xlsx', '.xls']}
                                  max={10}
                                  multiple={true}
                                  autoComplete="off"
                                  style={{ display: "none" }}
                                  onChange={onFilesChange}
                                />
                              </div>
                            </div>
                          }
                          <div className="addmessage-input">
                            <input
                              id="add-message-input"
                              type="text"
                              ref={inputTextRef}
                              value={message}
                              autoComplete="off"
                              autoFocus={true}
                              className="form-control "
                              onKeyPress={(e) => {
                                if (e.keyPress === 13) {
                                  enterRef.current.click();
                                }
                              }}
                              onChange={onMessageChanged}
                              placeholder={"Type your message here..."} />
                          </div>
                          <button ref={enterRef} type='submit' className="ui-button-textOnly btn-vhelp addmessage-sendbtn" >
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                          </button>
                        </div> : <div className="padding-2">
                        <button type='submit' className="ui-button-default btn-vhelp" style={{ width: "100%" }} >
                          {joinLoader ? <span
                            className="spinner-border spinner-border-sm loadingSpinner"
                            role="status"
                            aria-hidden="true"
                          ></span> : "JOIN"}
                        </button>
                      </div>}

                  </form>
                </div>
              </div>
            )
            }
          </Dropzone >} </> : <ConvSettings id={id} name={name} twilioUrl={twilioUrl} chatServiceSid={chatServiceSid} conversation={conversationProxy} participants={participants} availableUsers={availableUsers} setShowSettings={setShowSettings} />
        }
      </div >
    );
  } catch (e) {
    console.log("Error:", e.message);
  }
};

export default React.memo(Conversation);
