import React from "react";
import Styles from "./index.module.css";
import Config from "../../../config/label";
import signupImg from "../../../assets/svg/signupImg.svg";
import uploadImg from "../../../assets/svg/uploadImg.svg";
import relaxImg from "../../../assets/svg/relaxImg.svg";
import prescriptionUpload from "../../../assets/sf_svg/documenttext1.svg"
import PaymentCheckout from "../../../assets/sf_svg/walletmoney.svg"
import CustomerSupport from "../../../assets/sf_svg/usersquare.svg"
import TrustSecurity from "../../../assets/sf_svg/securitysafe.svg"

const HowItWorks20 = ({ widget }: any) => {
  return (
    <div
      className={Styles["bodyHowItWorks"]}
      style={{ order: `${widget?.sequence}` }}
      >
      <div className="outer_container">
      
      <div className={Styles["hiwBody"]}>
      <div className={Styles["hiwTitle"]}>
        <h1>{Config?.header_htw_title2}</h1>
        <p>{Config?.header_htw_description2}</p>
      </div>
      <div className={`${Styles['cardBodyContainer']} ${Styles['cardBodyContainerLeft']}`}>
      <div className={Styles["bodyCard"]}>
          <img src={prescriptionUpload} alt={Config?.header_htw_card1_title2} draggable="false" />
          <div>
            <h3>{Config?.header_htw_card1_title2}</h3>
            <p>{Config?.header_htw_card1_detail2}</p>
          </div>
        </div>
        <div className={Styles["bodyCard"]}>
          <img src={PaymentCheckout} alt={Config?.header_htw_card2_title2} draggable="false" />
          <div>
            <h3>{Config?.header_htw_card2_title2}</h3>
            <p>{Config?.header_htw_card2_detail2}</p>
          </div>
        </div>
      </div>
      <div className={`${Styles['cardBodyContainer']} ${Styles['cardBodyContainerRight']}`}>
      <div className={Styles["bodyCard"]}>
          <img src={CustomerSupport} alt={Config?.header_htw_card3_title2} draggable="false" />
          <div>
            <h3>{Config?.header_htw_card3_title2}</h3>
            <p>{Config?.header_htw_card3_detail2}</p>
          </div>
        </div>
        <div className={Styles["bodyCard"]}>
          <img src={TrustSecurity} alt={Config?.header_htw_card4_title2} draggable="false" />
          <div>
            <h3>{Config?.header_htw_card4_title2}</h3>
            <p>{Config?.header_htw_card4_detail2}</p>
          </div>
        </div>
      </div>

       
       
      </div>
      </div>
    </div>
  );
};

export default HowItWorks20;
