import React, { useState } from "react";
import CheckValidation from "../../CheckValidation";
import UploadButton from "./UploadButton";
import PlusUploadButton from "./PlusUploadButton";
import IconUpload from "./IconUpload";
import CardUpload from "./CardUpload";

export type FileUploadProps = {
  name: string;
  id: string;
  title?: string;
  style?: object;
  onClick?: any;
  type?: string;
  size?: string;
  multiple?: any;
  fileContents?: any;
  setFileContents?: any;
  setIsExist?: any;
  isExist?: boolean;
  loading?: boolean;
  icon?: any;
};

interface IContent {
  0: { name: string; size: number; type: string };
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const {
    type = "icon",
    setFileContents,
    setIsExist,
    fileContents,
    loading,
    icon,
  } = props;

  const [contentInfo, setContentInfo] = useState<IContent>({
    0: {
      name: "",
      size: 0,
      type: "",
    },
  });

  const getSize = () => {
    switch (props?.size) {
      case "large":
        return { width: "209px", height: "61px", fontSize: "20px" };

      case "medium":
        return { width: "200px", height: "56px", fontSize: "18px" };
      case "small":
        return { width: "114px", height: "38px", fontSize: "16px" };

      default:
        return {};
    }
  };

  const fileDeleteHandler = () => {
    setContentInfo({
      0: {
        name: "",
        size: 0,
        type: "",
      },
    });
  };

  const IconUploadHandler = (event: any) => {
    setContentInfo(event.target.files);

    const uploadedContent = {
      data: event.target.files[0],
      id: new Date().getTime().toString() + Math.floor(Math.random() * 1000000),
      uri: URL.createObjectURL(event.target.files[0]),
    };

    setFileContents([uploadedContent]);
    event.target.value = null;
  };

  const fileUploadHandler = (event: any) => {
    setContentInfo(event.target.files);

    const uploadedContent = {
      data: event.target.files[0],
      id: new Date().getTime().toString() + Math.floor(Math.random() * 1000000),
      uri: URL.createObjectURL(event.target.files[0]),
    };

    if (event.target.files.length) {
      /* Get files in array form */
      const files: any = Array.from(event.target.files);

      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file: any) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev: any) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (data: any) => {
          /* Once all promises are resolved, update state with image URI array */
          data?.forEach((element: any, i: any) => {
            files[i] = {
              uri: element,
              data: files[i],
              id:
                new Date().getTime().toString() +
                Math.floor(Math.random() * 1000000),
            };
          });

          let isExist: boolean = false;
          setIsExist && setIsExist(false);

          setFileContents((prev: any) => {
            const newFileName = files?.map((c: any) => c?.data?.name);

            for (const prevContent of prev) {
              if (newFileName.includes(prevContent?.data?.name)) {
                setIsExist &&
                  setIsExist(newFileName.includes(prevContent?.data?.name));
                isExist = newFileName.includes(prevContent?.data?.name);
                break;
              }
            }

            if (isExist) {
              return [...prev];
            } else {
              return [...prev, uploadedContent];
            }
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }

    event.target.value = null;
  };

  const getUploadButton = () => {
    switch (type) {
      case "button":
        return (
          <UploadButton
            {...props}
            contentInfo={contentInfo}
            setContentInfo={setContentInfo}
            onGetSize={getSize}
            onFileUploadHandler={fileUploadHandler}
            onFileDeleteHandler={fileDeleteHandler}
          />
        );
      case "update":
        return (
          <CardUpload {...props} onFileUploadHandler={fileUploadHandler} />
        );

      case "plus":
        return (
          <PlusUploadButton
            {...props}
            onFileUploadHandler={fileUploadHandler}
          />
        );

      case "icon":
        return (
          <IconUpload
            onFileUploadHandler={IconUploadHandler}
            loading={loading}
            icon={icon}
          />
        );

      default:
        return <div></div>;
    }
  };

  return getUploadButton();
};

export default FileUpload;
