import React from "react";
import SubmitRx from "../../components/submitRx";
import Styles from "./index.module.css";

export default function SubmitRxPage() {
  return (
    <div className={Styles["submitRxPage"]}>
      <SubmitRx />
    </div>
  );
}
