import React from "react";
import Input from "../../../components/UI/Input";
import Label from "../../../components/UI/Label";
import styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";

const EnterEmail = (props: any) => {
  return (
    <div className={`${styles?.container}`}>
      <div>
        <Heading className={`${styles?.page_heading}`}>
          {Config?.forgetPassword_heading_Create_Account}
        </Heading>

        <div className={`${styles?.form_control}`}>
          <div className={`${styles?.form_inner_control}`}>
            <Label className={`${styles?.form_label}`} for="email">
              {Config?.lbl_Email}
            </Label>
            <Input
              name={Config?.lbl_Inp_type_email}
              id={Config?.lbl_Inp_type_email}
              type={Config?.lbl_Inp_type_email}
              placeholder={Config?.inp_P_Holder_Email}
              size={Config?.lbl_Inp_size_medium}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterEmail;
