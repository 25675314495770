import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../config/label";
import { msg_config } from "../../config/messages";
import {
  auth,
  registerWithEmailAndPassword,
  SignInWithGoogle,
} from "../../firebase";
import "./Register.css";
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div className="register">
      <div className="register__container">
        <input
          type={Config?.lbl_Inp_type_text}
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type={Config?.lbl_Inp_type_text}
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type={Config?.lbl_Inp_type_pass}
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className="register__btn" onClick={register}>
          {Config?.lbl_register_txt}
        </button>
        <button
          className="register__btn register__google"
          onClick={SignInWithGoogle}
        >
          {msg_config?.ggl_reg}
        </button>
        <div>
          {msg_config?.ggl_reg_qry}
          <Link to="/" draggable="false">
            {Config?.lbl_login_txt}
          </Link>
          {msg_config?.ggl_login_qry_1}
        </div>
      </div>
    </div>
  );
}
export default Register;
