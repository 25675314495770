import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import HealthCard from "../../../assets/svg/health_card.svg";
import HealthInfo from "../../../assets/svg/health_info.svg";
import Insurance from "../../../assets/svg/insuance_card.svg";
import userSkeleton from "../../../assets/svg/user_1.svg";
import bagheartfill from "../../../assets/svg/wishlist.svg";
import bookmarksfill from "../../../assets/svg/save.svg";
// import usersvg from "../../../assets/svg/usersvg.svg";
import Group3 from "../../../assets/svg/orders.svg";
import Kart from "../../../assets/svg/cart.svg";
import ManageAddress from "../../../assets/svg/manage_address.svg";
import Payments from "../../../assets/svg/manage_payment.svg";
import settings from "../../../assets/svg/password.svg";
import usersvg from "../../../assets/svg/My_profile.svg";
import { GlobalContext } from "../../../store/global-context.js";
import { Filter } from "../../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../utils/utils";
import Heading from "../../UI/Heading";
import IconButton from "../../UI/IconButton";
import DetailProfileModal from "./DetailProfileModal/index";
import styles from "./index.module.css";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { LOGGED_IN_USER_ROLE, USER_PROFILE } from "../../../config/Config";
import profileImg from "../../../assets/proile_img.png";
import myListIcon from "../../../assets/sf_svg/my_list_v2.svg";
import RxImage from "../../../assets/svg/rx.svg"

export type sectionProps = {
  section?: any;
  modalOpen?: any;
  setModalOpen?: any;
};

const SideBar: React.FC<sectionProps> = (props) => {
  const { modalOpen, setModalOpen } = props;

  const navigate = useNavigate();
  const [globalContext] = useContext(GlobalContext);
  const [context, setContext] = useContext(UserAuthContext)

  const handleOnClick = (val: any) => {
    switch (val) {
      case "Manage Address":
        navigate("/add-address");
        break;

      case "Prescriptions":
        navigate("/my-rx-request");
        break;

      case "My Profile":
        navigate("/my-profile");
        break;

      case "Cart":
        navigate("/purchase");
        break;

      case "Orders":
        navigate("/my-orders");
        break;

      case "My Wishlist":
        navigate("/wishlist");
        break;

      case "Change Password":
        navigate("/change-password");
        window.scrollTo(0, 0);
        break;
      case "Manage Payments":
        navigate("/manage-payments");
        window.scrollTo(0, 0);
        break;
      case "Insurance Cards":
        navigate("/my-insurance");
        window.scrollTo(0, 0);
        break;

      case "Health Information":
        navigate("/my-health");
        window.scrollTo(0, 0);
        break;
      case "Health Card":
        navigate("/my-health-card");
        window.scrollTo(0, 0);
        break;

      case "Save For Later":
        navigate("/saved");
        break;
      case "My List":
        navigate("/my-list");
        break;


      default:
        break;
    }
  };

  const details = [
    {
      icon: <img src={usersvg} alt="user" draggable="false" />,
      value: "My Profile",
      type: "IP",
    },
    {
      icon: <img src={myListIcon} alt="user" draggable="false" />,
      value: "My List",
      type: "IP",
    },
    {
      icon: <img src={ManageAddress} alt="address" draggable="false" />,
      value: "Manage Address",
      type: "IP",
    },
    {
      icon: (
        <img
          src={Kart}
          alt="cart"
          style={{ marginLeft: "-3px" }}
          draggable="false"
        />
      ),
      value: "Cart",
      type: "IP",
    },
    // {
    //   icon: <img src={Payments} alt="Payments" draggable="false" />,
    //   value: "Manage Payments",
    //   type: "IP",
    // },
    {
      icon: <img src={Group3} alt="orders" draggable="false" />,
      value: "Orders",
      type: "IP",
    },
    {
      icon: <img src={RxImage} alt="address" draggable="false" style={{"width": "100%"}} />,
      value: "Prescriptions",
      type: "I",
    },
    // {
    //   icon: <img src={Insurance} alt="Insurance" style={{ height: "0.9rem", width: "0.9rem" }} draggable="false" />,
    //   value: "Insurance Cards",
    //   type: "I",
    // },
    // {
    //   icon: <img src={HealthCard} alt="Health Card" style={{ height: "0.9rem", width: "0.9rem" }} draggable="false" />,
    //   value: "Health Card",
    //   type: "I",
    // },
    // {
    //   icon: <img src={HealthInfo}
    //     alt="Health"
    //     style={{ height: "0.9rem", width: "0.9rem" }}
    //     draggable="false"
    //   />
    //   ,
    //   value: "Health Information",
    //   type: "I",
    // },
    {
      icon: <img src={bagheartfill} alt="wishlist" draggable="false" />,
      value: "My Wishlist",
      type: "I",
    },
    // {
    //   icon: <img src={bookmarksfill} alt="save for later" draggable="false" />,
    //   value: "Save For Later",
    //   type: "I",
    // },
    {
      icon: <img src={settings} alt="password" draggable="false" />,
      value: "Change Password",
      type: "IP",
    },
  ];
  const sessionStorageCopy: any =getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  const newArr = details?.filter((item) => {
    if (
      sessionStorageCopy?.usr_signup_type === "gmail" ||
      sessionStorageCopy?.usr_signup_type === "apple" ||
      sessionStorageCopy?.usr_signup_type === "Gmail" ||
      sessionStorageCopy?.usr_signup_type === "Apple"
    ) {
      return item?.value !== "Change Password";
    } else {
      return item;
    }
  });

  const newArr2 = details?.filter((item) => {
    const user_type = context?.UserAuth?.usr_type;
    if (user_type === "pro") {
      return item?.type === "IP" || item?.type === "P" ? true : false;
    } else {
      if (item?.type === "P") return false;
    }
    return true;
  });

  return (
    <section className={styles["sideBarWrapper"]}>
      <div className={styles["title"]}>
        <div className={styles["checkImgWrapper"]}>
          <img
            src={globalContext?.profile_image || userSkeleton}
            alt="ProfileImg"
            draggable="false"
          />
          {/* <img src={profileImg} /> */}
        </div>

        <div className={`${styles?.hideMenuWrapper}`}>
          <div className={`${styles?.filterButton}`}>
            <IconButton
              onClick={(e: any) => {
                if (modalOpen) {
                  setModalOpen(false);
                } else {
                  setModalOpen(true);
                }

                e.stopPropagation();
              }}
              className={`${styles?.iconButton}`}
              title="Side filter"
            >
              <Filter width="18" height="18" className={`${styles?.icon}`} />
            </IconButton>
            {modalOpen && (
              <DetailProfileModal
                setModalOpen={setModalOpen}
                className={`${styles?.detailProfileModel}`}
              />
            )}
          </div>

          <div className={styles["subDetailsWrapper"]}>
            <div>
              <Heading className={`${styles?.page_heading}`}>
              {sessionStorageCopy?.usr_type === "customer" ?  sessionStorageCopy?.usr_name !== ""
                ? sessionStorageCopy?.usr_name
                : sessionStorageCopy?.usr_phone : ""}
                {sessionStorageCopy?.usr_type === LOGGED_IN_USER_ROLE && sessionStorageCopy?.usr_company_name || "" } 

              </Heading>
              <p className={`${styles?.location}`}>
                {sessionStorageCopy?.usr_phone !== null
                  ? sessionStorageCopy?.usr_phone
                  : ""}
              </p>
            </div>
          </div>

          
        </div>
      </div>
      <div className={styles["profileInfo"]}>
        <div>
          {newArr2?.map((item) => (
            <div
              className={styles["dynamicDetails"]}
              style={{
                borderBottom:
                // (item?.value === "Cart" && "1px solid #dfe3e6") ||
                  (item?.value === "Manage Payments" && "1px solid #dfe3e6") ||
                  (item?.value === "Prescription Requests" &&
                    "1px solid #dfe3e6") ||
                  (item?.value === "Health Information" &&
                    "1px solid #dfe3e6") ||
                  "",
              }}
              onClick={() => handleOnClick&&  handleOnClick(item?.value)}
            >
              <div className={styles["iconWrapper"]}>{item?.icon}</div>
              <div
                // title=""
                // to=""
                className={`${styles?.linkStyling}`}
                draggable="false"
              >
                <p>{item?.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SideBar;
