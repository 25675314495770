import React from "react";
import Styles from "./index.module.css";
import StarRating from "../StarRating";
import LikeButton from "../LikeButton";
import { useNavigate } from "react-router-dom";
import Config from "../../config/label";
import IconButton from "../UI/IconButton";
import { PlusSquare } from "../../utils/appIcons";

export type productProps = {
  imgUrl?: any;
  imgAlt?: string;
  detail?: string;
  price?: string;
  quantity?: number;
  actualPrice?: string;
  productUrl?: string;
  viewType?: string;
  className?: string;
};

const ProductCard: React.FC<productProps> = (props) => {
  const {
    imgUrl,
    imgAlt,
    detail,
    productUrl,
    price,
    quantity = 1, //Please delete default value after
    actualPrice,
    viewType,
    className,
  } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`${Styles?.container} ${
        viewType === "LIST" && Styles?.listView
      } ${className}`}
    >
      <div
        className={`${Styles?.imageBox} ${
          viewType === "LIST" && Styles?.listView
        }`}
        onClick={() => navigate(`${productUrl}`)}
      >
        <img src={imgUrl} alt={imgAlt} draggable="false" />
      </div>

      <div className="w-full">
        <p className={Styles?.detail}>{detail}</p>

        <div className="flex items-center flex-wrap">
          <p className={Styles?.quantityLabel}>{Config?.product_quantity}</p>
          <span className={Styles?.quantity}>{quantity}</span>
        </div>

        <div className="flex items-center flex-wrap">
          <p className={Styles?.price}>
            {Config?.product_currency}
            {price}
          </p>
          <p className={`${Styles?.actualPrice} flex items-center`}>
            {Config?.product_currency}
            <del>{actualPrice}</del>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
