function isJson(str:String) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
}

const {
  REACT_APP_firebase_Config,
  REACT_APP_upload_by_parts,
  REACT_APP_site_url,
  REACT_APP_Google_map_key,
  REACT_APP_country_for_address_search,
  REACT_APP_TWILIO_URL,
  REACT_APP_Payments_APPLICATION_ID,
  REACT_APP_Payments_LOCATION_ID,
  REACT_APP_Square_script,
  REACT_APP_Square_script_id,
  REACT_APP_Google_analytics_tracking_id,
}= process.env as Record<string, string>;
export const siteConfig = {
  upload_by_parts: REACT_APP_upload_by_parts,
  site_url: REACT_APP_site_url,
  Google_map_key:REACT_APP_Google_map_key,
  country_for_address_search:REACT_APP_country_for_address_search,
  firebase_Config:JSON.parse(REACT_APP_firebase_Config),
  TWILIO_URL:REACT_APP_TWILIO_URL,
  Payments_APPLICATION_ID:REACT_APP_Payments_APPLICATION_ID,
  Payments_LOCATION_ID:REACT_APP_Payments_LOCATION_ID,
  Square_script:REACT_APP_Square_script,
  Square_script_id:REACT_APP_Square_script_id,
  Google_analytics_tracking_id:REACT_APP_Google_analytics_tracking_id,
  /** Config for checking usr email+name+dob+phone and showing profile popup */
  check_Profile: "Y",
  checkout_popup: false,
  login_default: "1",
  login_signedin: "31",
  SPD_OPENSSL_KEY: "bf3c199c2470cb477d907b1e0917c17b",
  SPD_OPENSSL_IV: "aeghei1Di8tieNg0",

  site_currency: "₹",

  // Profile Image Upload
  // *******************
  profile_image_p_doctype_code: "ecom_patient_image_document_type_id_code",
  profile_image_p_docsubtype_code: "ecom_patient_image_document_sub_type_id",
  profile_image_p_trnt_code: "ecom_patient_image_transaction_type_code",

  //insurance card image upload front + back
  insurance_front_p_doctype_code:
    "user_insurance_image_front_page_document_type_code",
  insurance_front_p_docsubtype_code:
    "user_insurance_image_front_page_document_sub_type_code",
  insurance_front_p_trnt_code:
    "user_insurance_image_front_page_transaction_type_code",

  insurance_back_p_doctype_code:
    "user_insurance_image_back_page_document_type_code",
  insurance_back_p_docsubtype_code:
    "user_insurance_image_back_page_document_sub_type_code",
  insurance_back_p_trnt_code:
    "user_insurance_image_back_page_transaction_type_code",

  // Health Card Front Image Upload
  // ******************************
  health_card_front_image_p_doctype_code:
    "user_healthcard_image_front_page_document_type_code",
  health_card_front_image_p_docsubtype_code:
    "user_healthcard_image_front_page_document_sub_type_code",
  health_card_front_image_p_trnt_code:
    "user_healthcard_image_front_page_transaction_type_code",

  // Health Card Back Image Upload
  // ******************************
  health_card_back_image_p_doctype_code:
    "user_healthcard_image_back_page_document_type_code",
  health_card_back_image_p_docsubtype_code:
    "user_healthcard_image_back_page_document_sub_type_code",
  health_card_back_image_p_trnt_code:
    "user_healthcard_image_back_page_transaction_type_code",

  form_auto_complete_flag: "off",
};
