import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { CallAPI } from "../../suggest-us/callSuggest";
import APIContext from "../../store/api-context";
import { config } from "../../config/processId";
import Styles from "./index.module.css";
import { CloseIcon } from "../../utils/appIcons";
import Label from "../UI/Label";
import Config from "../../config/label";
import { FormInput } from "../UI/FormInput";
import { msg_config } from "../../config/messages";
import SelectDropdown from "../UI/SelectDropdown";
import Button from "../UI/Button";
import { decryptData } from "../../utils/util_helper";
import { getDecryptedID, getID } from "../../utils/utils";
import { SG_PATIENT_ID, USER_PROFILE } from "../../config/Config";
import LocationSearchInput from "../LocationSearchInput";
import { siteConfig } from "../../config/site";



const userProfile = getDecryptedID(USER_PROFILE);
const AddressV2 = (props: any) => {
    const { setModalOpen, propsSelectedAddress, isAddAddressPopup, getAddress, getDefaultAddress,setSelectedAddress } = props;
    const addressType = isAddAddressPopup?.addressType !== "NEW"
    const addressId = isAddAddressPopup?.addressData?.p_patadd_id || ""
    const modalType = addressId === "" ? "new" : "edit"
    const addressData = isAddAddressPopup?.addressData || {};
    const isDefaultAdress = addressData?.default_address_flag === "Y";
    const [loadingCountry, setLoadingCountry] = useState(false);
    const [addresses, setAddresses] = useState<any>([]);
    const [cities, setCities] = useState<any>([]);
    const [province, setProvince] = useState<any>([]);
    const [country, setCountry] = useState<any>([]);
    const [suggestUsInit] = useContext(APIContext);
    const [loadingProvince, setLoadingProvince] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const jsonProfileData = getDecryptedID(USER_PROFILE);
    const [alert2, setAlert2] = useState({
        isTrue: false,
        msg: "",
        type: "",
    })
    const [profileField, setProfileField] = useState<any>({
        coutry_desc: addressData?.coutry_desc || "",
        p_address: addressData?.p_address || "",
        p_street: addressData?.p_street || "",
        city_desc: addressData?.city_desc || "",
        state_desc: addressData?.state_desc || "",
        p_pcode: addressData?.p_pcode || "",
        p_country: addressData?.p_country || "",
        p_province: addressData?.p_province || "",
        p_city: addressData?.p_city || "",
        patadddet_pnt_name: addressData?.patadddet_pnt_name || jsonProfileData?.usr_first_name,
        patadddet_pnt_last_name: addressData?.patadddet_pnt_last_name ||  jsonProfileData?.usr_last_name,
        country: {
            label: addressData?.coutry_desc || "",
            value: addressData?.p_country || "",
        },
        city: {
            label: addressData?.city_desc || "",
            value: addressData?.p_city || "",
        },
        province: {
            label: addressData?.state_desc || "",
            value: addressData?.p_province || "",
        },
        default_address_flag: addressData?.default_address_flag || "N",
        latLng: addressData?.latLng || {}

    });
    const [checked, setChecked] = useState(isDefaultAdress);
    const [submitLoad, submitLoading] = useState(false);
    const [isNewEntry, setIsNewEntry] = useState(true);
    const [location, setLocation] = useState(addressData?.p_address);
    const [addressDetails, setaddressDetails] = useState([]);
    const [addressForFill, setAddressForFill] = useState({});


    useEffect(() => {
        try {
            if (alert2?.isTrue) {
                setAlert2({
                    isTrue: false,
                    type: "",
                    msg: ""
                })
            }
        } catch (error) {
            console.error(error);
        }
    }, [alert2])


    useEffect(() => {
        try {
            if (country?.length > 0 && modalType === "edit") {
                country &&
                    setValue("country", country.find((i: any) => i));
            }
            if (country?.length > 0 && addressDetails?.length > 0) {
                const selectedCountry: any = addressDetails?.find((i: any) => i?.types === "country");
                const newConntry = country.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedCountry?.long_name?.trim()?.toLowerCase())
                setProfileField((prev: any) => {
                    return { ...prev, p_country: newConntry?.value }
                })
                setValue("country", newConntry);
            }
        } catch (error) {
            console.error(error);
        }
    }, [country, addressDetails])

    useEffect(() => {
        try {
            if (province?.length > 0 && modalType === "edit") {
                province &&
                    setValue(
                        "province",
                        province.find((i: any) => i.value === addressData?.p_province)
                    );
            }
            if (province?.length > 0 && addressDetails?.length > 0) {
                const selectedProvince: any = addressDetails?.find(
                    (i: any) => i?.types === "administrative_area_level_1"
                );
                const newProvince = province.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedProvince?.long_name?.trim()?.toLowerCase())
                setValue("province", newProvince);
                setProfileField((prev: any) => { return { ...prev, p_province: newProvince?.value } })
            }
        } catch (error) {
            console.error(error);
        }

    }, [province, addressDetails]);

    useEffect(() => {
        try {
            if (cities?.length > 0 && modalType === "edit") {
                cities &&
                    setValue(
                        "city",
                        cities.find((i: any) => i.value === addressData?.p_city)
                    );
            }
            if (cities?.length > 0 && addressDetails?.length > 0) {
                const selectedCity: any = addressDetails?.find(
                    (i: any) => i?.types === "locality"
                );
                const newCity = cities.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedCity?.long_name?.trim()?.toLowerCase() || i?.label?.trim()?.toLowerCase() === selectedCity?.short_name?.trim()?.toLowerCase() );
                setValue("city", newCity);
                setProfileField((prev: any) => { return { ...prev, p_city: newCity?.value } })

                let postalCode: any = addressDetails?.find((i: any) => i?.types === "postal_code");
                postalCode = postalCode ? postalCode?.long_name || postalCode?.short_name : "";
                setValue("p_pcode", `${postalCode}`);
                setProfileField((prev: any) => { return { ...prev, p_pcode: postalCode || "" } })

            }
        } catch (error) {
            console.error(error);
        }
    }, [cities, addressDetails]);


    useEffect(() => {
        try {
            if (addressType) {
                let countryObject = {
                    //@ts-ignore
                    value: profileField?.p_country || "",
                    //@ts-ignore
                    label: profileField?.coutry_desc || "",
                };
                setValue("country", countryObject);
                let provinceObject = {
                    //@ts-ignore
                    value: profileField?.p_province || "",
                    //@ts-ignore
                    label: profileField?.state_desc || "",
                };
                setValue("province", provinceObject);
                let cityObject = {
                    //@ts-ignore
                    value: profileField?.p_city || "",
                    //@ts-ignore
                    label: profileField?.city_desc || "",
                };
                setValue("city", cityObject);
                //@ts-ignore
                setValue("patadddet_pnt_name", addressData?.patadddet_pnt_name ||  jsonProfileData?.usr_first_name);
                //@ts-ignore
                setValue("patadddet_pnt_last_name", addressData?.patadddet_pnt_last_name  || jsonProfileData?.usr_last_name);
                //@ts-ignore
                setValue("p_pcode", profileField?.p_pcode || "");
                //@ts-ignore
                setValue("p_address", profileField?.p_address || "");
                setLocation(profileField?.p_address || "");
            }

        } catch (error) {
            console.error(error);
        }
    }, [])


    const {
        register,
        handleSubmit,
        control,
        setError,
        setValue,
        reset,
        watch,
        clearErrors,
        resetField,
        formState: { errors },
    } = useForm();

    const _country = watch("country");
    const _province = watch("province");


    useEffect(() => {
        try {
            if (_country) {
                onCountrySelectHandler(_country);
            }
        } catch (error) {
            console.error(error);
        }
    }, [_country]);

    useEffect(() => {
        try {
            if (_province) {
                onProvinceSelectHandler(_province);
            }
        } catch (error) {
            console.error(error);
        }
    }, [_province]);

    useEffect(() => {
        try {
            if (suggestUsInit?.returnCode) {
                setLoadingCountry(true);
                CallAPI(config?.country_process_id, {}).then((res) => {
                    if (res?.returnCode) {
                        setCountry([
                            ...res?.returnData?.map((item: any) => {
                                return {
                                    value: item?.cnt_id,
                                    label: item?.cnt_description,
                                };
                            }),
                        ]);
                        setLoadingCountry(false);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [suggestUsInit]);


    const onProvinceSelectHandler = (e: any) => {
        try {
            setCities([]);
            if (suggestUsInit?.returnCode) {
                setLoadingCities(true);
                CallAPI(config?.city_process_id, { p_stt_id: e?.value }).then((res) => {
                    if (res?.returnCode) {
                        setCities([
                            ...res?.returnData?.map((item: any) => {
                                return {
                                    value: item?.cit_id,
                                    label: item?.cit_description,
                                };
                            }),
                        ]);
                        setLoadingCities(false);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };


    const onCountrySelectHandler = (e: any) => {
        try {
            setProvince([]);
            if (suggestUsInit?.returnCode) {
                setLoadingProvince(true);
                CallAPI(config?.province_process_id, { cnt_id: e?.value }).then(
                    (res) => {
                        if (res?.returnCode) {
                            setProvince([
                                ...res?.returnData?.map((item: any) => {
                                    return {
                                        value: item?.stt_id,
                                        label: item?.stt_description,
                                    };
                                }),
                            ]);
                            setLoadingProvince(false);
                        }
                    }
                );
            }
        } catch (error) {
            console.error(error);
        }
    };


    const onAddressSubmitHandler = (formData: any) => {
        try {
            if (profileField) {
                let params = {
                    p_address_id: addressId || "",
                    p_pnt_id: decryptData(getID(SG_PATIENT_ID)) || "",
                    p_patadd_line_1: location?.trim() || "",
                    p_patadd_city_id: formData?.city.value || "",
                    p_patadd_state_id: formData?.province.value || "",
                    p_patadd_country_id: formData?.country.value || "",
                    p_patadd_zipcode: formData?.p_pcode?.trim() || "",
                    p_patadddet_pnt_name: formData?.patadddet_pnt_name?.trim() || "",
                    p_patadddet_pnt_last_name: formData?.patadddet_pnt_last_name?.trim() || "",
                    p_default_address_flag: profileField?.default_address_flag || "N",
                };
                if (suggestUsInit?.returnCode === true) {
                    submitLoading(true);
                    CallAPI(config?.patient_address_add_update_process_id, params)
                        .then((res) => {
                            if (res?.returnCode) {
                                // resetField("patadddet_pnt_name", { defaultValue: "" });
                                // resetField("patadddet_pnt_last_name", { defaultValue: "" });
                                // resetField("p_pcode", { defaultValue: "" });
                                // resetField("p_address", { defaultValue: "" });
                                // setValue("country", "");
                                // setValue("province", "");
                                // setValue("city", "");
                                setChecked(false);
                                //@ts-ignore
                                // document.querySelector("#addresscheckbox").checked = false;
                                setProfileField({});
                                // getAllAddress();
                                // propsGetDeafultAddress && propGetAllAddress()
                                // propsGetDeafultAddress && propsGetDeafultAddress()
                                setIsNewEntry(false);
                                submitLoading(false);
                                setAlert2((prev: any) => {
                                    return { ...prev, type: "success", msg: msg_config?.msg_address_updated_successfully }
                                })
                                getAddress();
                                getDefaultAddress();
                                setModalOpen(false);
                                // setTimeout(() => {
                                // }, 2000);

                            } else {
                                submitLoading(false);
                                setAlert2((prev: any) => {
                                    return { ...prev, type: "error", msg: res?.msg }
                                })
                            }
                        })
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onInputChangeHandler = (e: any) => {
        if (e.target.value.trim() !== "") {
            setProfileField((prev: any) => {
                return { ...prev, [e.target.name]: e.target.value };
            });
        } else {
            setProfileField((prev: any) => {
                return { ...prev, [e.target.name]: "" };
            });
        }

    };


    const onChangeSelection = (e: any, name: any) => {
        try {
            setProfileField((prev: any) => {
                return { ...prev, [name]: e };
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e: any) => {
        try {
            if (e.target.checked) {
                setChecked(e.target.checked);
                setProfileField((prev: any) => {
                    return { ...prev, default_address_flag: "Y" };
                });
            } else {
                setChecked(e.target.checked);
                setProfileField((prev: any) => {
                    return { ...prev, default_address_flag: "N" };
                });
            }
        } catch (error) {
            console.error(error);
        }

        // setChecked(!checked);
    };


    const handleLabel = () => {
        if (
            profileField?.default_address_flag == "N" ||
            profileField?.default_address_flag == ""
        ) {
            setChecked(true);
            setProfileField((prev: any) => {
                return { ...prev, default_address_flag: "Y" };
            });
        } else {
            setChecked(false);
            setProfileField((prev: any) => {
                return { ...prev, default_address_flag: "N" };
            });
        }
    };


    return (
        <div className={Styles['modal_deliveryaddress_Background']}>
            <div className={Styles['modal_deliveryaddress_Container']}>
                <div className={Styles["modal_deliveryaddress_row_secondContainer"]}>
                    <div className={Styles["titleCloseBtn"]}
                        title="Close button"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        draggable="false"
                    >
                        <img src={CloseIcon} alt="Cross icon" draggable="false" />
                    </div>
                    <form onSubmit={handleSubmit(onAddressSubmitHandler)}
                        autoComplete={siteConfig?.form_auto_complete_flag}

                        className={Styles["modal_deliveryaddress_mainform"]}>
                        <div className={Styles["modal_deliveryaddress_form"]}>
                            <div className={`${Styles?.form_inner_control}`}>
                                <span>
                                    <Label
                                        className={`${Styles?.form_label}`}
                                        for="patadddet_pnt_name"
                                    >
                                        {Config?.form_deliveryaddress_modalelementname}
                                    </Label>

                                    <FormInput
                                        name="patadddet_pnt_name"
                                        id="patadddet_pnt_name"
                                        type="text"
                                        placeholder={Config?.address_placeholder_1}
                                        size="small"
                                        register={register}
                                        rules={{
                                            required: msg_config?.first_name_required,
                                            pattern: {
                                                value: /^[A-Za-z\s]+$/,
                                                message: "Please enter a valid First name.",
                                            },
                                        }}
                                        value={profileField?.patadddet_pnt_name}
                                        onInputChangeHandler={onInputChangeHandler}
                                        errors={errors}
                                        maxLength={25}
                                    />
                                </span>
                                <span>
                                    <Label
                                        className={`${Styles?.form_label}`}
                                        for="patadddet_pnt_last_name"
                                    >
                                        {Config?.form_deliveryaddress_modalelementlastname}
                                    </Label>
                                    <FormInput
                                        name="patadddet_pnt_last_name"
                                        id="patadddet_pnt_last_name"
                                        type="text"
                                        placeholder={Config?.address_placeholder_2}
                                        size="small"
                                        register={register}

                                        rules={{
                                            required: msg_config?.last_name_required,
                                            pattern: {
                                                value: /^[A-Za-z\s]+$/,
                                                message: "Please enter valid Last name.",
                                            },
                                        }}
                                        errors={errors}
                                        maxLength={25}
                                        // value={selectedAddresses?.patadddet_pnt_last_name|| profileField?.lastName}
                                        value={profileField?.patadddet_pnt_last_name}
                                        onInputChangeHandler={onInputChangeHandler}
                                    />
                                </span>
                            </div>
                            <div
                                className={`${Styles?.form_inner_control} ${Styles?.address_edit}`}
                            >
                                <span className={`${Styles?.form_Address}`}>
                                    <Label className={`${Styles?.form_label}`} for="address">
                                        {Config?.form_deliveryaddress_modalelement1}
                                    </Label>
                                    {/* <FormInput
                                        name="p_address"
                                        id="p_address"
                                        type={Config?.lbl_Inp_type_text}
                                        placeholder={"Address"}
                                        size={Config?.lbl_Inp_size_small}
                                        register={register}
                                        value={profileField?.p_address}
                                        rules={{
                                            required: msg_config?.addaddress_housenumber,

                                        }}
                                        errors={errors}
                                        maxLength={100}
                                        onInputChangeHandler={onInputChangeHandler}
                                    // onInputChangeHandler={(e: any) => {
                                    //   onInputChangeHandler(e, "address");
                                    // }}
                                    /> */}
                                    <LocationSearchInput
                                        name="p_address"
                                        setState={setLocation}
                                        state={location}
                                        register={register}
                                        setaddressDetails={setaddressDetails}
                                        rules={{ required: msg_config?.address_required }}
                                        setValue={setValue}
                                        watch={watch}
                                        clearErrors={clearErrors}
                                        errors={errors}
                                        placeholder={Config?.address_placeholder_3}
                                        isFormattedAddress={true}
                                        maxLength={150}
                                        setAddressForFill={setAddressForFill}
                                        setProfileField={setProfileField}
                                        setSelectedAddress={setSelectedAddress}
                                    />
                                </span>
                            </div>
                            <div className={`${Styles?.form_inner_control}`}>
                                <span className={`${Styles?.form_country}`}>
                                    <Label className={`${Styles?.form_label}`} for="country">
                                        {Config?.form_deliveryaddress_modalelement5}
                                    </Label>

                                    <SelectDropdown
                                        name="country"
                                        options={country}
                                        // options={selectedAddresses?.p_country!== "" ? [{ value: selectedAddresses?.p_country, label: selectedAddresses?.coutry_desc}, {...country}] : {...country}}
                                        size="small"
                                        placeholder={Config?.address_placeholder_4}
                                        isClearable={true}
                                        loading={loadingCountry}
                                        control={control}
                                        rules={{ required: msg_config?.country_required }}
                                        errors={errors}
                                        op_style={{
                                            fontSize: "16px",
                                        }}
                                        sg_style={{
                                            fontSize: "16px",
                                        }}
                                        onSelectChange={(e: any) => {
                                            onChangeSelection(e, "country");
                                        }}
                                    />
                                    {/* <FormInput
                                        name="coutry_desc"
                                        id="country"
                                        type="text"
                                        placeholder={Config?.address_placeholder_4}
                                        size="small"
                                        register={register}

                                        rules={{
                                            required: msg_config?.country_required,
                                            // pattern: {
                                            //     value: /^[A-Za-z\s]+$/,
                                            //     message: "Please enter valid Last name.",
                                            // },
                                        }}
                                        errors={errors}
                                        maxLength={25}
                                        // value={selectedAddresses?.patadddet_pnt_last_name|| profileField?.lastName}
                                        value={profileField?.coutry_desc}
                                        onInputChangeHandler={onInputChangeHandler}
                                    /> */}
                                </span>
                                <span className={`${Styles?.form_province}`}>
                                    <Label className={`${Styles?.form_label}`} for="province">
                                        {Config?.form_deliveryaddress_modalelement4}
                                    </Label>

                                    <SelectDropdown
                                        name="province"
                                        options={province}
                                        size="small"
                                        placeholder={Config?.address_placeholder_5}
                                        isClearable={true}
                                        loading={loadingProvince}
                                        control={control}
                                        disabled={!_country}
                                        rules={{ required: msg_config?.province_required }}
                                        onSelectChange={(e: any) => {
                                            onChangeSelection(e, "province");
                                        }}
                                    />
                                    {/* <FormInput
                                        name="state_desc"
                                        id="state"
                                        type="text"
                                        placeholder={Config?.address_placeholder_5}
                                        size="small"
                                        register={register}

                                        rules={{
                                            required: msg_config?.province_required,
                                            // pattern: {
                                            //     value: /^[A-Za-z\s]+$/,
                                            //     message: "Please enter valid Last name.",
                                            // },
                                        }}
                                        errors={errors}
                                        maxLength={25}
                                        // value={selectedAddresses?.patadddet_pnt_last_name|| profileField?.lastName}
                                        value={profileField?.state_desc}
                                        onInputChangeHandler={onInputChangeHandler}
                                    /> */}
                                </span>
                            </div>
                            <div className={`${Styles?.form_inner_control}`}>
                                <span className={`${Styles?.form_city}`}>
                                    <Label className={`${Styles?.form_label}`} for="city">
                                        {Config?.form_deliveryaddress_modalelement3}
                                    </Label>

                                    <SelectDropdown
                                        name="city"
                                        options={cities}
                                        size="small"
                                        placeholder={Config?.address_placeholder_6}
                                        isClearable={true}
                                        loading={loadingCities}
                                        control={control}
                                        disabled={!_province}
                                        rules={{
                                            required: msg_config?.city_required,
                                        }}
                                        onSelectChange={(e: any) => {
                                            onChangeSelection(e, "city");
                                        }}
                                    />
                                    {/* <FormInput
                                        name="city_desc"
                                        id="city"
                                        type="text"
                                        placeholder={Config?.address_placeholder_6}
                                        size="small"
                                        register={register}

                                        rules={{
                                            required: msg_config?.city_required,
                                            // pattern: {
                                            //     value: /^[A-Za-z\s]+$/,
                                            //     message: "Please enter valid Last name.",
                                            // },
                                        }}
                                        errors={errors}
                                        maxLength={25}
                                        // value={selectedAddresses?.patadddet_pnt_last_name|| profileField?.lastName}
                                        value={profileField?.city_desc}
                                        onInputChangeHandler={onInputChangeHandler}
                                    /> */}
                                </span>
                                <span>
                                    <Label className={`${Styles?.form_label}`} for="p_pcode">
                                        {Config?.form_deliveryaddress_modalelement6}
                                    </Label>

                                    <FormInput
                                        name="p_pcode"
                                        id="p_pcode"
                                        type={Config?.lbl_Inp_type_text}
                                        placeholder={Config?.address_placeholder_7}
                                        size={Config?.lbl_Inp_size_small}
                                        register={register}
                                        value={profileField?.p_pcode}
                                        onInputChangeHandler={onInputChangeHandler}
                                        rules={{
                                            required: msg_config?.zip_code_required,
                                            minLength: {
                                                value: 5,
                                                message: "Postal Code must be at least 5 characters long.",
                                            },

                                        }}
                                        errors={errors}
                                        maxLength={9}
                                    />
                                </span>
                            </div>
                            <div
                                className={`${Styles?.form_checkbox}`}
                                style={{ marginTop: "35px", marginBottom: "35px" }}
                            >
                                {/* <Checkbox
                     //@ts-ignore
                     id="addresscheckbox"
                    name="Set as default address"
                    // value={checked ? true : false}
                    onChange={handleChange}
                  /> */}
                                <input
                                    type="checkbox"
                                    id="default_address_flag"
                                    name="default_address_flag"
                                    onChange={handleChange}
                                    checked={checked}
                                />
                                <Label
                                    className={`${Styles?.form_label_setaddress}`}
                                    for="checkbox"
                                    onClick={() => handleLabel()}
                                //@ts-ignore
                                // onClick={() => {
                                //   if(profileField?.default_address_flag == "N" || profileField?.default_address_flag == ""){
                                //     //@ts-ignore
                                //     // document.querySelector("#addresscheckbox").checked=true;
                                //   }else{
                                //     //@ts-ignore
                                //     document.querySelector("#addresscheckbox").checked=false;
                                //   }
                                //         }}
                                >
                                    {Config?.form_deliveryaddress_modalelement7}
                                </Label>
                            </div>
                            <div className={Styles["modalpopup_button"]}>
                                <Button
                                    type={Config?.lbl_Btn_type_ghost}
                                    size={Config?.lbl_Btn_size_medium}
                                    // onClick={() => {}}
                                    className={`${Styles?.ghostBtn}`}
                                    loading={submitLoad}
                                >
                                    {/* {isNewEntry || addresses.length === 0 ? Config?.lbl_add_address : Config?.lbl_update_address} */}
                                    {addressId === ""
                                        ? Config?.lbl_add_address
                                        : Config?.lbl_update_address}
                                    {/* {Object.keys(profileField).length === 0 ? 'Add' : 'Update'} */}
                                    {/* {profileField === "" ? (
                       btnConfig?.uploadRx_Add
                      ) : (
                        btnConfig?.signUp_Primary_Text_Update
                      )} */}
                                    {/* {handleAddNewAddress? 'Add' : 'Update'} */}
                                    {/* {btnConfig?.uploadRx_Add} */}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default AddressV2;