import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import OtpVerification from "../../../../assets/svg/OtpVerification.svg";
import Alert from "../../../../components/UI/Alert";
import Button from "../../../../components/UI/Button";
import DigitInput from "../../../../components/UI/DigitInput";
import { FormErrorMessage } from "../../../../components/UI/FormErrorMessage";
import Heading from "../../../../components/UI/Heading";
import Config from "../../../../config/label";
import { msg_config } from "../../../../config/messages";
import { config } from "../../../../config/processId.js";
import APIContext from "../../../../store/api-context";
import { UserAuthContext } from "../../../../store/UserAuthContext.js";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { getDecryptedID, objEncrypt, setEncryptedID } from "../../../../utils/utils";
import styles from "./index.module.css";
import AppLogo from "../../../../assets/appLogo/safe_pharmacy_logo.png";
import { CURRENT_SCREEN, DEFAULT_FLAG, PHONE_NO, USER_ID, USER_LOGGED_IN, USER_PROFILE, VERIFY_PHONE_OTP_LENGTH } from "../../../../config/Config";


const VerifyOTP = (props: any) => {
  const {
    enteredPhResponse,
    setIsLoading,
    setIsAlert,
    type,
    isLoading,
    setLoginScreens,
    handleNext,
  } = props;
  const [context, setContext] = useContext(UserAuthContext);
  const [isRequired, setIsRequired] = useState(false);
  const navigate = useNavigate();

  const [suggestUsInit] = useContext(APIContext);

  const { handleSubmit } = useForm();

  const phone_no = getDecryptedID(PHONE_NO);
  const user_id = getDecryptedID(USER_ID);
  const current_screen = getDecryptedID(CURRENT_SCREEN);

  // Created Digital inputs ref for focusing on next field when previous filled

  const pin1Ref = React.useRef<any>(null);
  const pin2Ref = React.useRef();
  const pin3Ref = React.useRef();
  const pin4Ref = React.useRef();
  const pin5Ref = React.useRef();
  const pin6Ref = React.useRef();

  // created useStates for setting Digital input's current value

  const [pin1, setPin1] = useState();
  const [pin2, setPin2] = useState();
  const [pin3, setPin3] = useState();
  const [pin4, setPin4] = useState();
  const [pin5, setPin5] = useState();
  const [pin6, setPin6] = useState();

  useEffect(() => {
    pin1Ref.current.focus();
  }, []);

  const SetResendAlert = (res: any) => {
    if (res?.returnCode === true) {
      // setIsAlert({ type: "success", msg: "Otp sent successfully!" });
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "success",
        msg: msg_config?.loginpage_otp_sent_success,
      }));
    }
    if (res?.returnCode === false) {
      // setIsAlert({ type: "error", msg: res?.msg });
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "error",
        msg: msg_config?.loginpage_otp_sent_fail,
      }));
    }
  };
  //Function call for resend OTP
  const resendOtpHandler = () => {
    // setIsAlert({ type: " ", msg: " " });
    setContext((prev: any) => ({
      ...prev,
      searchDisplayOut: false,
      type: " ",
      msg: " ",
    }));
    if (suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      CallAPI(config?.resend_otp_process_id, {
        p_usr_id: user_id,
        p_phone_number: phone_no,
      }).then((res) => {
        setIsLoading(false);

        if (res?.returnCode === true) {
          setLoginScreens(res?.returnData[0]?.user_id?.current_screen);
          SetResendAlert(res);
          onClickReset();
        } else {
          SetResendAlert(res);
        }
      });
    }
  };

  const SetLocalStorage = (res: any) => {
    setEncryptedID(USER_PROFILE,res?.returnData[0])
    setEncryptedID(USER_LOGGED_IN, "Y");
    setContext((prev: any) => ({
      ...prev,
      UserAuth: res?.returnData[0],
    }));
  };

  //Function call for OTP confirmation

  const SetAlert = (res: any) => {
    if (res?.returnCode === true) {
      // setIsAlert({ type: "success", msg: "OTP confirmed successfully!" });
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "success",
        msg: msg_config?.loginpage_otp_confirm_success,
      }));
    }
    if (res?.returnCode === false) {
      setContext((prev: any) => ({
        ...prev,
        searchDisplayOut: true,
        type: "error",
        msg: msg_config?.loginpage_otp_confirm_fail,
      }));
      // setIsAlert({ type: "error", msg: "OTP was not confirmed!" });
    }
  };

  // useEffect(() => {
  //   if (pin1 && pin2 && pin3 && pin4 && pin5 && pin6) {
  //     setIsRequired(false);
  //   } else {
  //     setIsRequired(true);
  //   }
  // }, [pin1, pin2, pin3, pin4, pin5, pin6]);

  const confirmOTPHandler = () => {
    // setIsAlert({ type: " ", msg: " " });
    setContext((prev: any) => ({
      ...prev,
      searchDisplayOut: false,
      type: " ",
      msg: " ",
    }));
    const formattedOTP = [pin1, pin2, pin3, pin4, pin5, pin6];

    let newNumber = formattedOTP?.join("");
    if (newNumber.length === VERIFY_PHONE_OTP_LENGTH) {
      setIsRequired(false);
      //Same API call is made for login using social-media and phone
      if (suggestUsInit?.returnCode === true) {
        setIsLoading(true);
        if (suggestUsInit?.returnCode === true) {
          CallAPI(config?.confirm_otp_process_id, {
            p_usr_id: user_id,
            p_otp: newNumber,
            p_usr_phone_number: phone_no,
          }).then((res) => {
            setIsLoading(false);
            SetAlert(res);
            if (res?.returnCode === true) {
              if (localStorage?.getItem("is_Checked") === "Y") {
                setEncryptedID(DEFAULT_FLAG,
                  
                  JSON.stringify({ flag: "N", date: new Date() })
                );
              } else {
                setEncryptedID(DEFAULT_FLAG,
                  JSON.stringify({ flag: "Y", date: new Date() })
                );
              }
              localStorage.removeItem("is_Checked");
              //Differentiating redirection of screens using type props for social-media login OTP and phone login OTP
              const create_PWD = {
                current_screen: res?.returnData[0]?.current_screen,
                sgMessageIsCode: res?.returnData[0]?.sgMessageIsCode,
              };
              sessionStorage.setItem("create_PWD", JSON.stringify(create_PWD));
              switch (res?.returnData[0]?.current_screen) {
                case "create_password":
                  navigate("/createPassword");
                  break;

                default:
                  SetLocalStorage(res);
                  navigate("/");
                  break;
              }
            } else {
              // SetAlert(res);
            }
          });
        }
      }
    } else {
      setIsRequired(true)
    }


    if (handleNext) {
      handleNext();
    }
  };

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref: any = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e: any) => {
    var now: any = new Date();
    const total: any = Date.parse(e) - Date.parse(now);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e: any) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:01:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + 1);
    // deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <div className={`${styles?.container}`}>
      {/* <div className={`${styles?.image}`}>
        <img
          src={AppLogo}
          alt="security lock with asterisk symbol for 6 digit verification code"
          draggable="false"
          onClick={()=>{
            navigate("/")
          }}
        />
      </div> */}
      <Heading className={`${styles?.page_heading}`}>
        {Config?.verification_OTP}
      </Heading>
      <div className={`${styles?.content_wrapper}`}>
        <p className={`${styles?.content}`}>
          {Config?.txt_verification_OTP_1}
          <span className={`${styles?.number}`}>{phone_no}</span>
          {/* NEED TO MAKE A LINK */}
          <span className={styles?.change_mobile_number} onClick={() => { navigate("/log-in") }}>{Config?.lbl_change_mobile_number}</span>
        </p>
      </div>
      <form onSubmit={handleSubmit(confirmOTPHandler)}>
        <ul>
          <li>
            <DigitInput
              name="dg1"
              id="dg1"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin1Ref}
              value={pin1}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin1(onlyNumbers);
                if (onlyNumbers.length > 0) {
                  const { current }: any = pin2Ref;
                  current.focus();
                }
              }}
              // value={pin1}
              // onChange={(e: any) => {
              //   setPin1(e.target.value);
              //   if (e.target.value.length > 0) {
              //     const { current }: any = pin2Ref;
              //     current.focus();
              //   }
              // }}
              onKeyPress={({ nativeEvent }: any) => {
                if (pin1) {
                  const { current }: any = pin2Ref;
                  current.focus();
                  setPin2(nativeEvent.key);
                }
              }}
            />
          </li>
          <li>
            <DigitInput
              name="dg2"
              id="dg2"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin2Ref}
              value={pin2}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin2(onlyNumbers);

                if (onlyNumbers.length > 0) {
                  const { current }: any = pin3Ref;
                  current.focus();
                } else if (
                  e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                  const { current }: any = pin1Ref;
                  current.focus();
                }
              }}

              // onChange={(e: any) => {
              //   setPin2(e.target.value);
              //   if (e.target.value.length > 0) {
              //     const { current }: any = pin3Ref;
              //     current.focus();
              //   } else if (
              //     e.nativeEvent.inputType === "deleteContentBackward"
              //   ) {
              //     const { current }: any = pin1Ref;
              //     current.focus();
              //   }
              // }}
              onKeyPress={({ nativeEvent }: any) => {
                if (pin2) {
                  const { current }: any = pin3Ref;
                  current.focus();
                  setPin3(nativeEvent.key);
                }
              }}
            />
          </li>
          <li>
            <DigitInput
              name="dg3"
              id="dg3"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin3Ref}
              value={pin3}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin3(onlyNumbers);

                if (onlyNumbers.length > 0) {
                  const { current }: any = pin4Ref;
                  current.focus();
                }
                else if (
                  e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                  const { current }: any = pin2Ref;
                  current.focus();
                }
              }}
              // onChange={(e: any) => {
              //   setPin3(e.target.value);
              //   if (e.target.value.length > 0) {
              //     const { current }: any = pin4Ref;
              //     current.focus();
              //   } else if (
              //     e.nativeEvent.inputType === "deleteContentBackward"
              //   ) {
              //     const { current }: any = pin2Ref;
              //     current.focus();
              //   }
              // }}
              onKeyPress={({ nativeEvent }: any) => {
                if (pin3) {
                  const { current }: any = pin4Ref;
                  current.focus();
                  setPin4(nativeEvent.key);
                }
              }}
            />
          </li>
          <li>
            <DigitInput
              name="dg4"
              id="dg4"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin4Ref}
              value={pin4}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin4(onlyNumbers);

                if (onlyNumbers.length > 0) {
                  const { current }: any = pin5Ref;
                  current.focus();
                } else if (
                  e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                  const { current }: any = pin3Ref;
                  current.focus();
                }
              }}
              // onChange={(e: any) => {
              //   setPin4(e.target.value);

              //   if (e.target.value.length > 0) {
              //     const { current }: any = pin5Ref;
              //     current.focus();
              //   } else if (
              //     e.nativeEvent.inputType === "deleteContentBackward"
              //   ) {
              //     const { current }: any = pin3Ref;
              //     current.focus();
              //   }
              // }}
              onKeyPress={(nativeEvent: any) => {
                if (pin4) {
                  const { current }: any = pin5Ref;
                  current.focus();
                  setPin5(nativeEvent.key);
                }
              }}
            />
          </li>
          <li>
            <DigitInput
              name="dg5"
              id="dg5"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin5Ref}
              value={pin5}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin5(onlyNumbers);

                if (onlyNumbers.length > 0) {
                  const { current }: any = pin6Ref;
                  current.focus();
                } else if (
                  e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                  const { current }: any = pin4Ref;
                  current.focus();
                }
              }}
              // onChange={(e: any) => {
              //   setPin5(e.target.value);

              //   if (e.target.value.length > 0) {
              //     const { current }: any = pin6Ref;
              //     current.focus();
              //   } else if (
              //     e.nativeEvent.inputType === "deleteContentBackward"
              //   ) {
              //     const { current }: any = pin4Ref;
              //     current.focus();
              //   }
              // }}
              onKeyPress={(nativeEvent: any) => {
                if (pin5) {
                  const { current }: any = pin6Ref;
                  current.focus();
                  setPin6(nativeEvent.key);
                }
              }}
            />
          </li>
          <li>
            <DigitInput
              name="dg6"
              id="dg6"
              type={Config?.lbl_Inp_type_text}
              placeholder={Config?.inp_P_Holder_OTP}
              myref={pin6Ref}
              value={pin6}
              onChange={(e: any) => {
                const { value } = e.target;
                const onlyNumbers = value.replace(/[^0-9]/g, '');
                setPin6(onlyNumbers);

                if (onlyNumbers.length > 0) {
                  const { current }: any = pin6Ref;
                  current.focus();
                } else if (
                  e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                  const { current }: any = pin5Ref;
                  current.focus();
                }
              }}
            // onChange={(e: any) => {
            //   setPin6(e.target.value);

            //   if (e.target.value.length > 0) {
            //     const { current }: any = pin6Ref;
            //     current.focus();
            //   } else if (
            //     e.nativeEvent.inputType === "deleteContentBackward"
            //   ) {
            //     const { current }: any = pin5Ref;
            //     current.focus();
            //   }
            // }}
            />
          </li>
        </ul>

        {isRequired && (
          <FormErrorMessage className={`${styles?.error_msg}`}>
            {msg_config?.OTP_required}
          </FormErrorMessage>
        )}

        <div className={styles["OtpVerification"]}>
          {timer === "00:00:00" ? (
            <>
              <p>{Config?.lbl_Not_Otp_rec}</p>
              <Link
                className={styles["Link"]}
                to=""
                onClick={() => resendOtpHandler()}
                draggable="false"
              >
                {Config?.lbl_Resend_otp}
              </Link>
            </>
          ) : (
            <p id="timer" style={{ marginBottom: "0px" }}>
              {timer}
            </p>
          )}
        </div>

        <div className={styles["verifyButton"]}>
          {/* <Button
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            style={{ width: "360px" }}
            loading={isLoading}
            onClick={()=>{
              navigate("/log-in")
            }}
            htmlType={"button"}
          >
            {Config?.lbl_back}
          </Button> */}
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            style={{ width: "360px" }}
            loading={isLoading}
            htmlType={"submit"}
          >
            {Config?.lbl_otp_confirm}
          </Button>
        </div>
      </form>
      {context?.searchDisplayOut ? (
        <Alert type={context?.type} info={context?.msg} />
      ) : (
        ""
      )}
    </div>
  );
};

export default VerifyOTP;
