import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import modalStyles from "./index.module.css";
import Input from "../UI/Input";
import Config from "../../config/label";
import Label from "../UI/Label";
import { useForm } from "react-hook-form";
import { CloseIcon } from "../../utils/appIcons";
import { mylistPageActions } from "../../pages/MyListPage/slice";
import { UserAuthContext } from "../../store/UserAuthContext";
import Button from "../UI/Button";
import btnConfig from "../../config/button";
import procheck from "../../assets/sf_svg/Radio active.svg";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import {
  JSONCheckerFunc,
  getDecryptedID,
  getID,
  objDecrypt,
} from "../../utils/utils";
import AddNewList from "./AddNewLIst";
import Alert from "../UI/Alert";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { SG_USER_ID, USER_PROFILE } from "../../config/Config";
import { useParams } from "react-router-dom";

const MyList = (props) => {
  const { setIsAlert ,isAlert} = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listLoading, setlistLoading] = useState(true);
  const dispatch = useDispatch();
  const [context, setContext] = useContext(UserAuthContext);
  const handleMouseEnter = (e) => {
    e.target.style.cursor = "pointer";
  };
  const state = useSelector((state) => state?.myListPageReducer);
  const { prodID } = useParams();

  const userProfileJson = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const handleMouseLeave = (e) => {
    e.target.style.cursor = "default";
  };
  const [update, setUpdate] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: {} });
  const [items, setItems] = useState([]);
  let list = [];
  for (let i = 0; i < 4; i++) {
    list[i] = i;
  }
  const handleItemClick = (e, setFn, p_list_id) => {
    setFn((prev) => {
      let temp = [...prev];
      temp = temp.map((i) => {
        if (i.p_list_id === p_list_id) {
          return { ...i, isSelected: !i?.isSelected };
        }
        return i;
      });
      return temp;
    });
  };
  function searchJSONArray(jsonData, searchString) {
    const results = [];

    jsonData.forEach((obj, index) => {
      for (const key in obj) {
        if (obj[key].toString().includes(searchString)) {
          results.push({ ...obj, isActive: true });
          break;
        }
      }
    });

    return results;
  }

  useEffect(() => {
    (async () => {
      const res = await CallAPI(config?.pro_user_get_my_list_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        // sgUserId: getID(SG_USER_ID),
        p_srv_id: state?.selectedProduct?.srv_id || prodID,
      });
      setItems(
        res?.returnData?.map((i) => ({
          ...i,
          isSelected: false,
          isActive: true,
        }))
      );
      setFilteredItems(
        res?.returnData?.map((i) => ({
          ...i,
          isSelected: false,
          isActive: true,
        }))
      );
      setlistLoading(false);
    })();
  }, [update]);

  // if (state?.showAddToMyList === true) {
  //   return (

  //   );
  // }

  return (
    <div className={modalStyles.popup_wrapper}>
      {state?.showAddNewMyList && (
        <AddNewList
          userProfileJson={userProfileJson}
          editMode={false}
          setUpdate={setUpdate}
          setIsAlert={setIsAlert}
          isAlert ={isAlert}
          onClose={(newItem) => {
            setItems((prev) => [
              ...prev,
              { ...newItem, isSelected: false, isActive: true },
            ]);
            setFilteredItems((prev) => [
              ...prev,
              { ...newItem, isSelected: false, isActive: true },
            ]);
          }}
                  />
      )}
      <div className={modalStyles.mylist_title_wrapper}>
        <div className={modalStyles.mylist_title}>
          <h5>Add to MyList</h5>
        </div>
        <div className={modalStyles.mylist_create_list}>
          <Button
            onClick={() => {
              dispatch(
                mylistPageActions?.TOGGLE_CREATE_LIST({
                  showAddNewMyList: true,
                  mode: "add",
                })
              );
            }}
          >
            + Create List
            {/* <img src={CloseIcon} alt="Cross icon" draggable="false" /> */}
          </Button>
        </div>
      </div>

      <Input
        placeholder="Search"
        onChange={(e) => {
          const input = e.target.value;
          if (input === "") {
            setFilteredItems(items);
          } else {
            const results = searchJSONArray(items, input);
            // const newState = items?.filter((i)=>results.includes());
            setFilteredItems(results);
          }
          // if (input !== "") {
          // }
        }}
      />
      {/* <form onSubmit={handleSubmit(selectTypeHandler)}> */}
      <div>
        <div className={modalStyles.pro_wrapper}>
          {listLoading ? (
            list?.map(() => {
              return (
                <div className={modalStyles.item}>
                  <ShimmerThumbnail height={30} width={30} rounded />
                </div>
              );
            })
          ) : filteredItems.length > 0 ? (
            filteredItems?.map(
              (item) =>
                item?.isActive && (
                  <React.Fragment key={"myList_" + item.p_list_id}>
                    <div
                      className={modalStyles.item}
                      onClick={(e) => {
                        handleItemClick(e, setFilteredItems, item.p_list_id);
                        if (props.handleNext) {
                          props.handleNext();
                        }
                      }}
                      data-myListID={item?.p_list_id}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        border: "none",
                        background: "none",
                        border: "1.5px solid",
                        borderColor: selectedItems.includes(item?.p_list_id)
                          ? "#006699"
                          : "#DFE3E6",
                        borderRadius: "5px",
                        cursor: "default",
                        // width: "%",
                        // display: "block",
                      }}
                    >
                      {(
                          <span className={`${ item?.isSelected || item?.p_has_srv !== "N" ?  modalStyles?.custom_checkbox_checked :  ""  } ${modalStyles?.custom_checkbox}`}></span>
                      )}
                      <div className={modalStyles.selecttype_icon}>
                        <img src={""} alt="" />
                      </div>
                      <div className={modalStyles.selecttype_title}>
                        <h3>{item.p_list_title}</h3>
                        <p style={{ fontSize: "8px" }}>
                          {item?.p_list_description}
                        </p>
                      </div>
                      <div
                        key={"myList_Selected_" + item?.p_list_id}
                        className={modalStyles.selecttype_checkbox}
                      >
                        {/* {(item?.isSelected || item?.p_has_srv !== "N") && (
                          <img src={procheck} />
                        )} */}
                      </div>
                    </div>
                  </React.Fragment>
                )
            )
          ) : (
            <div className={modalStyles.no_items_found}>
              <p>No items found.</p>
            </div>
          )}
        </div>
      </div>
      <div className={modalStyles.mylist_btn}>
        <Button
          className={modalStyles.cancel_btnn}
          onClick={() => {
            dispatch(
              mylistPageActions?.TOGGLE_MY_LIST({ showAddToMyList: false })
            );
          }}
        >
          Cancel
          {/* <img src={CloseIcon} alt="Cross icon" draggable="false" /> */}
        </Button>
        <Button
          disabled={filteredItems?.length === 0 ? true : false}
          loading={loading}
          onClick={() => {
            setIsAlert({
              istrue: "",
              type: "",
              msg: "",
            });
            const lists = filteredItems.filter((i) => i.isSelected === true);
            lists.forEach((i) => {
              (async () => {
                setLoading(true);
                const res = await CallAPI(
                  config?.pro_user_add_product_my_list_process_id,
                  {
                    p_usr_id: userProfileJson?.usr_id,
                    p_cus_id: userProfileJson?.customer_id,
                    p_mylist_id: i?.p_list_id,
                    p_srv_id: state?.selectedProduct?.srv_id || prodID,
                    p_pack_detail_id: state?.selectedProductQty.unitId,
                    p_internal_flag: "",
                  }
                );
                setLoading(false);
                if (res?.returnCode === true) {
                  dispatch(
                    mylistPageActions?.TOGGLE_MY_LIST({
                      showAddToMyList: false,
                    })
                  );
                  setIsAlert({
                    istrue: true,
                    type: "success",
                    msg: "Item added to list succesfully!",
                  });
                } else {
                  setIsAlert({
                    istrue: true,
                    type: "error",
                    msg: "Error while adding item to list!",
                  });
                  console.error("Error while saving to mylist");
                }
              })();
            });
          }}
        >
          Submit
          {/* <img src={CloseIcon} alt="Cross icon" draggable="false" /> */}
        </Button>
        {/* </form> */}
      </div>
    </div>
  );
};

export default MyList;
