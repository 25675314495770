import React, { useEffect, useState, useContext } from "react";
import Styles from "./index.module.css";
import StarRating from "../StarRating";
import LikeButton from "../LikeButton";
import { useNavigate } from "react-router-dom";
import Config from "../../config/label";
import IconButton from "../UI/IconButton";
import addCart from "../../assets/svg/addtocart_img.svg";
import UnitPricingDropdown from "../UnitPricingDropdown";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import { UserAuthContext } from "../../store/UserAuthContext";
import img from "../../assets/product_merchant.svg";
import myList from "../../assets/mylist.svg";
import { JSONCheckerFunc, getProductImage, proAddCartHandler } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import list_checked from "../../assets/icons/list_checked.svg";
import { mylistPageActions } from "../../pages/MyListPage/slice";
import { unitPricingDropdownActions } from "../UnitPricingDropdown/slice";

export type productMerchantProps = {
  type?: string;
  imgUrl?: any;
  imgAlt?: string;
  detail?: string;
  price?: string;
  actualPrice?: string;
  productUrl?: string;
  ratingCount?: number;
  viewType?: string;
  brand?: string;
  packagingId?: any;
  isWishListAdded?: any;
  ribbon?: boolean;
  ribbonType?: string;
  onLikeButtonClick?: any;
  onAddtoCartButtonClick?: any;
  discountPercent?: any;
  coPay?: any;
  packsize_json: any;
  setIsAlert: any;
  gaEventTracker: any;
  suggestusInit: any;
  setIsCardPopUPOpen: any;
  setIsAdding: any;
  product: any;
  icon?: any;
  onIconClick?: any;
  isSelected?: any;
  idx?: any;
};
/**
 * Use ribbon to true or false
 *Use ribbonType to specify the particular type of ribbon
 * @ribbon true or false // default --> false
 * ribbon prop can be used to activate or deactivate ribbon
 *
 * @ribbonType  corner-left/corner-right/folded    //default --> 'folded'
 * Do not use left or right values in case of 'floded' ribbon
 *
 * eg: <ProductCard  ribbon=true ribbonType='folded' />
 */
const ProductCardMerchant: React.FC<productMerchantProps> = (props) => {
  const {
    type,
    imgUrl,
    imgAlt,
    detail,
    productUrl,
    price,
    actualPrice,
    ratingCount,
    viewType,
    brand,
    packagingId,
    isWishListAdded,
    ribbon,
    ribbonType = "folded",
    onLikeButtonClick,
    onAddtoCartButtonClick,
    discountPercent,
    coPay,
    packsize_json,
    setIsAlert,
    gaEventTracker,
    suggestusInit,
    setIsCardPopUPOpen,
    setIsAdding,
    product,
    isSelected,
  } = props;
  const navigate = useNavigate();
  const [productQty, setProductQty] = useState({
    productQuantity: "1",
    unitId: "",
  });

  //getting product slug 
  const productSlug = product?.slug ||"";

  const [packDetailID, setPackDetailID] = useState("");
  const [context, setContext] = useContext(UserAuthContext);
  const [unitPrices, setUnitPrices] = useState([
    // {
    //   srv_pack_units: "srv_pack_units",
    //   srv_pack_det_id: "srv_pack_det_id",
    //   srv_pack_name: "srv_pack_name",
    //   save_price: "save_price",
    // },
  ]);
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [loading, setLoading] = useState({
    isTrue: false,
    productId: "",
  });
  const [isImageError, setImageError] = useState(false);


  const dispatch = useDispatch();

  const getRibbon = () => {
    switch (ribbonType) {
      case "corner-left":
        return `${Styles?.ribbon1} ${Styles?.left}`;
      case "corner-right":
        return `${Styles?.ribbon1} ${Styles?.right}`;
      case "folded":
        return `${Styles?.ribbon2}`;
      default:
        return `${Styles?.ribbon1} ${Styles?.left}`;
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (!!product?.packsize_json) {
      const UniPricesjson = JSONCheckerFunc(product?.packsize_json);
      const pricingData = JSONCheckerFunc(product?.packsize_json)?.map(
        (item: any) => ({
          srv_pack_units: item?.srv_pack_units,
          srv_pack_det_id: item?.srv_pack_det_id,
          srv_pack_name: item?.srv_pack_name,
          save_price: item?.save_price,
        })
      );
      setUnitPrices(UniPricesjson);
      setDiscountedPrice(UniPricesjson[0]?.discounted_price || "₹--")
      // if (location.pathname === "/my-list") {
      dispatch(
        unitPricingDropdownActions?.SELECT_PRICING({
          srv_id: product?.srv_id,
          selected: pricingData[0],
        })
      );
      setProductQty({
        productQuantity: "1",
        unitId: pricingData[0].srv_pack_det_id,
      });
      // }
    } else {
      // if (location.pathname === "/my-list") {
      setUnitPrices([]);
      dispatch(
        unitPricingDropdownActions?.SELECT_PRICING({
          srv_id: product?.srv_id,
          selected: {},
        })
      );
      // }
    }
  }, [product]);

  return (
    <>
      <div
        className={`${Styles?.product_list} ${viewType === "LIST" && Styles?.listView
          }`}
      >
        <div
          className={`${Styles?.container} ${ribbonType?.includes("corner") && Styles?.overflowHidden
            }`}
        >
          <div className={`flex justify-between ${Styles?.medicine_main}`}>
            <div
              className={`flex  justify-between gap-4 ${Styles?.medicine_image_main}`}
            >
              <div
                className={`${Styles?.imageBoxMerchant} ${viewType === "LIST" && Styles?.listView
                  }`}
                onClick={() => productUrl && navigate(`${productUrl}`)}
              >
                <img
                  src={getProductImage(product?.salt_composition,isImageError ?  "" : imgUrl)}
                  alt={imgAlt}
                  draggable="false"
                  className={`${Styles?.productImageMerchant}`}
                  onError={(e:any)=>{
                    e.target.src = getProductImage(product?.salt_composition,"")
                  }}
                />
              </div>
              <div className="medicine_name_details">
                <p className={Styles?.brandWrapperMerchant}>
                  <p className={Styles?.brand}>{brand}</p>
                </p>
                <div
                  className={`${Styles?.detailWrapper}`}
                  onClick={() => productUrl && navigate(`${productUrl}`)}
                >
                  <p className={`${Styles?.detail}`}>
                    {detail + " " + packagingId}
                  </p>
                  <p className={`${Styles?.tabs_detail}`}>
                    {product?.salt_composition}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center  justify-between">
              <div>
                <div
                  className={`flex items-center ${Styles?.medicine_price_main}`}
                >
                  <div className={Styles?.price}>
                    {Config?.product_currency}
                    {Number(discountedPrice).toFixed(2) || product?.discounted_amount.toFixed(2) || ""}
                    <p className={Styles?.stripe_price}></p>
                  </div>
                  <p className={Styles?.mrp_price}>
                    {Config?.product_currency}
                    {Number(product?.srv_price).toFixed(2) +""+Config?.per_strip || "" }
                  </p>
                </div>

                {(Number(coPay) > 0 && (
                  <p className={Styles?.coPay}>
                    {Config?.estimated_cost_pay_text} {Config?.product_currency}
                    {coPay}
                  </p>
                )) ||
                  ""}
              </div>
            </div>
          </div>

          <div
            className={`flex items-center  justify-between ${Styles?.medicine_margin_main}`}
          >
            <p className={`${Styles?.productCardScheme}`}>
              {"Scheme"}:{product?.srv_med_remarks}
            </p>
            {ribbon && discountPercent !== "0" && discountPercent !== null && (
              <>
                <p className={`${Styles?.ribbon} ${getRibbon()}`}>
                  {discountPercent}% Margin
                </p>
              </>
            )}
          </div>

          <div
            className={`flex items-center  justify-between ${Styles?.medicine_select_price_main}`}
          >
            {/* {ribbon && discountPercent !== "0" && discountPercent !== null && ( */}
    
            <>
              <UnitPricingDropdown
                pricingData={unitPrices}
                setProductQty={setProductQty}
                productQty={productQty}
                packDetailID={packDetailID}
                setPackDetailID={setPackDetailID}
                product={product}
                isProductCard={true}
                setDiscountedPrice={setDiscountedPrice}
                isMerchant={true}

              />
            </>
          
            {/* )} */}
            <div
              className={`${Styles?.margindetailWrapper}`}
              onClick={() => productUrl && navigate(`${productUrl}`)}
            >
              <div className={Styles?.productCardDescBottom}>
                <div
                  className={`flex items-center ${ratingCount ? "justify-between" : "justify-end"
                    } mt-2`}
                >
                  {context?.UserAuth?.usr_type !== "pro" && type !== "OTC" && (
                    <div className={`${Styles?.badge_pulsate_outer}`}>
                      <div className={`${Styles?.badge_pulsate}`}>
                        {Config?.Rx_text}
                        <div className={`${Styles?.rx_tooltip}`}>
                          {Config?.need_prescription}
                        </div>
                      </div>
                    </div>
                  )}

                  {ratingCount && <StarRating count={ratingCount} />}
                </div>
              </div>
            </div>
            <div className={`${Styles?.margindetailWrapper}`}>
              {/* TODO HARRY */}
              {/* <p className="productCardScheme">{"Scheme"}</p> */}
              {/* <hr style={{ width: "100%" }}></hr> */}

              <div className={Styles?.productCardDescBottom}>
                <div
                  className={`flex items-center ${ratingCount ? "justify-between" : "justify-end"
                    } mt-2 gap-2`}
                >
                  {ratingCount && <StarRating count={ratingCount} />}

                  <div className={`${Styles?.buttonWhislist}`}>
                    {location.pathname === "/my-list" && (
                      <>
                        <IconButton
                          title="Remove from my list"
                          onClick={props?.onIconClick}
                        >
                          <img
                            src={list_checked}
                            alt="Remove from my list"
                            draggable="false"
                          />
                        </IconButton>
                        <IconButton className={`${Styles?.selected_btn}`}
                          style={
                            product.isSelected
                              ? {

                                border: "1px solid var(--ui-secondary)",


                              }
                              : { color: "#111" }
                          }
                          title=""
                          onClick={() => {
                            (async () => {
                              await dispatch(
                                mylistPageActions?.SELECT_PRODUCT({
                                  idx: props?.idx,
                                })
                              );
                            })();
                          }}
                        >
                          {/* <img
                            src={props?.icon}
                            alt="Remove from cart"
                            draggable="false"
                          /> */}
                          <i style={
                            product.isSelected
                              ? {

                                color: "var(--ui-secondary)"

                              }
                              : { color: "#111" }
                          } className="fa-solid fa-check"></i>
                        </IconButton>
                      </>
                    )}

                    {location.pathname.startsWith("/product-list") && (
                      <>
                        <IconButton
                          title={Config?.lbl_myList}
                          onClick={() => {
                            if(unitPrices.length > 0){
                              dispatch(
                                mylistPageActions?.TOGGLE_MY_LIST({
                                  showAddToMyList: true,
                                  product,
                                  productQty,
                                })
                              );
                            }
                          }}
                          className={`${Styles?.iconButton} ${unitPrices.length === 0 ?  Styles?.["disabledMyList"] : ""}`}
                        >
                          <img src={myList} alt="My List" draggable="false" />
                        </IconButton>
                        <IconButton
                          title={Config?.lbl_add_to_cart}
                          onClick={() => {
                            if(unitPrices.length > 0){
                              proAddCartHandler(
                                product,
                                productQty,
                                setIsAlert,
                                gaEventTracker,
                                suggestusInit,
                                setIsCardPopUPOpen,
                                setIsAdding,
                                setLoading,

                              );
                            }
                          }}
                          className={`${Styles?.iconButton} ${unitPrices.length === 0 ?  Styles?.["disabledAddtoCart"] : ""}`}
                        >
                          {loading?.isTrue && loading?.productId ===product?.srv_id ? 
                           <i className="fa fa-spinner fa-spin"></i>
                           :
                           <img
                            src={addCart}
                            alt={Config?.lbl_add_to_cart}
                            draggable="false"
                          />}

                        </IconButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles?.productCardDesc}`}>
            <div className="w-full">
              <div className={`${Styles?.productCardDescTop}`}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductCardMerchant;
