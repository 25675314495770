import React from "react";
import { H3 } from "../../../../components/UI/Typography";
import Config from "../../../../config/label";
import style from "./index.module.css";
import Button from "../../../../components/UI/Button";
import paymentCard from "../../../../assets/temp-svg/payment-card.svg";
import RadioButton from "../../../../components/UI/RadioButton";

const PurchaseConfirm = () => {
  return (
    <div className={`${style?.container}`}>
      <H3 className={`${style?.title}`}>{Config?.payment_Card_Title}</H3>

      {/* This below image code is temporary implemented only for the purpose of UI display, Please remove this code, while implementing Card API */}
      <div className={`${style?.radioWrapper}`}></div>
      <img
        style={{ width: "100%", marginBottom: "1rem" }}
        src={paymentCard}
        alt="payment card information"
        draggable="false"
      />
      <div className={`${style?.radioWrapper}`}>
        <RadioButton
          type="custom"
          name="card"
          id="card"
          title={Config?.payment_Card_Radio_Btn_Title}
          size="1.2rem"
        />
      </div>
    </div>
  );
};

export default PurchaseConfirm;
