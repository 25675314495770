export const config = {
  home_process_id: "xcelecomconfig_get_mst_ecom_application_config",
  my_profile_process_id: "xcelecom_get_ecom_customer_my_profile",
  update_my_profile_process_id:
    "xcelecom_update_trn_ecom_patient_master_edit_profile",
  // product_list_process_id: "xcelecomconfig_get_mst_ecom_product_list",
  product_list_process_id: "xcelecomconfig_get_mst_ecom_product_list_v3",
  xcelecomconfig_get_mst_ecom_product_detail:
    "xcelecomconfig_get_mst_ecom_product_detail",
  //new process id ====> for product list screen
  // new_product_list_for_product_list_screen : "xcelecomconfig_get_mst_ecom_product_list_v3",

  xcelecomconfig_get_mst_ecom_product_list_for_search:
    "xcelecomconfig_get_mst_ecom_product_list_for_search",
  brand_list_process_id: "xcelecomconfig_get_mst_ecom_services_brands",
  category_list_process_id: "xcelecomconfig_get_mst_ecom_services_category",
  // side_bar_product_list_process_id:"xcelecomconfig_get_mst_ecom_side_bar_filters",
  side_bar_product_list_process_id:
    "xcelecomconfig_get_mst_ecom_side_bar_filters_v2",
  gmail_process_id: "xcelecomconfig_save_mst_ecom_user_for_signup_log_details",
  verify_phone_process_id:
    "xcelecomconfig_save_mst_ecom_user_register_phone_send_otp",
  resend_otp_process_id: "xcelecomconfig_save_resend_otp_for_user",
  confirm_otp_process_id: "xcelecomconfig_verify_phone_otp_for_ecom_user",
  create_password_process_id:
    "xcelecomconfig_save_mst_ecom_user_create_password",
  wish_list_add_process_id: "xcelecom_save_trn_service_cus_wishlist",
  wish_list_delete_process_id: "xcelecom_delete_trn_service_cus_wishlist",
  process_specific_product_list_id:
    "xcelecomconfig_get_mst_ecom_process_specific_product_list",
  get_org_details_process_id: "sgconf_get_mst_organization_by_ai_code",
  onboard_pharmacy_process_id: "xcelecomconfig_update_pro_user_details",
  verify_User_password_process_id: "xcelecomconfig_validate_ecom_user",
  patient_address_list_process_id:
    "xcelecom_get_trn_ecom_patient_master_address_list",
  patient_address_add_update_process_id:
    "xcelecom_save_trn_ecom_patient_master_address",
  patient_address_delete_process_id:
    "xcelecom_update_trn_ecom_patient_master_address",
  country_process_id: "xcelecomconfig_get_country_master_ecom",
  province_process_id: "xcelecomconfig_get_state_master_ecom",
  city_process_id: "xcelecomconfig_get_city_master_ecom",
  insuranceModal_process_id: "xcelecom_update_patient_insurance_status",
  get_wish_list_process_id: "xcelecom_get_trn_service_cus_wishlist",
  conform_phone_process_id:
    "xcelecomconfig_save_mst_ecom_user_register_phone_send_otp",
  rx_generate_process_id: "xcelecom_save_trn_rx_request",
  upload_p_trnt_code: "rx_upload_transaction_type_code_upload",
  upload_p_docsubtype_code: "rx_upload_document_sub_type_id_upload",
  upload_image_p_docsubtype_code: "rx_upload_document_image_sub_type_id_upload",
  change_password_process_id: "xcelecom_update_trn_ecom_user_change_password",

  related_product_list_process_id:
    "xcelecomconfig_get_mst_ecom_related_product_list",
  myRx_list_process_id: "xcelecom_get_trn_rx_request_service_customer_my_rx",
  // Generate RX
  generate_rx_process_id: "xcelecom_save_trn_rx_request",
  // RX Process ID's
  // *****************************************************
  rx_upload_process_id: "xcelecom_save_trn_ecom_document_upload",
  xcelecom_save_trn_rx_request_document_upload  : "xcelecom_save_trn_rx_request_document_upload",

  // RX Upload
  // *********
  // Generate RX
  customer_cart_add_product_process_id:
    "xcelecom_save_trn_customer_cart_cart_item",
  // Rx Signature Upload
  // *******************
  upload_sig_p_doctype_code: "rx_signature_document_sub_type_id",
  upload_sig_p_trnt_code: "rx_signature_transaction_type_code",
  upload_p_doctype_code: "rx_upload_document_type_id_code",
  get_cart_process_id: "xcelecom_get_trn_customer_cart_cart_items",
  get_cart_total_process_id: "xcelecom_get_trn_customer_cart_total",
  update_cart_item_qty_process_id:
    "xcelecom_update_trn_cart_item_quantity_customer",
  delete_cart_item_process_id: "xcelecom_update_trn_customer_cart_item_cancel",
  move_cart_item_to_wishlist_process_id:
    "xcelecom_save_trn_service_cus_wishlist_from_cart",
  move_wishlist_item_to_cart_process_id:
    "xcelecom_save_trn_customer_cart_item_from_wishlist",

  // Checkout
  // *******************
  get_default_address_process_id:
    "xcelecom_get_trn_ecom_patient_master_address_default",
  get_default_payment_process_id: "xcelecomconfig_get_ecom_ct_payment_mode",
  get_payment_method_process_id: "xcelecomconfig_get_ecom_ct_payment_mode",
  save_address_payment_process_id:
    "xcelecom_update_trn_customer_order_address_payment_method",
  place_order_process_id: "xcelecom_save_trn_customer_order_place",
  // RX New
  // *******************
  newRx_search_practitioner_list_process_id:
    "xcelecomconfig_get_mst_ecom_resource",
  newRx_practitioner_list_process_id: "xcelecomconfig_get_mst_ecom_resource",
  newRx_practitioner_add_process_id: "xcelecomconfig_save_mst_ecom_resource",
  gender_dropdown_process_id: "sgconf_get_ct_gender_dropdown",
  newRx_update_patient_process_id:
    "xcelecom_update_trn_ecom_patient_master_dob_gender",
  newRx_save_other_patient_process_id:
    "xcelecom_save_trn_ecom_patient_master_partner",

  // Rx New Signature Upload
  // *******************
  newRx_sig_p_doctype_code: "rx_upload_document_sub_type_id_new",
  newRX_sig_p_trnt_code: "rx_upload_transaction_type_code_new",

  //My rx fetch list id

  myRx_fetch_list_process_id:
    "xcelecom_get_trn_rx_request_service_customer_my_rx",
  // RX Transfer
  // *******************
  transferRx_search_pharmacy_list_process_id:
    "xcelecomconfig_get_mst_ecom_pharmacy",
  transferRx_pharmacy_list_process_id: "xcelecomconfig_get_mst_ecom_pharmacy",
  transferRx_pharmacy_add_process_id: "xcelecomconfig_save_mst_ecom_pharmacy",
  //Move to cart API product id
  move_to_cart_from_wish_list:
    "xcelecom_save_trn_customer_cart_item_from_wishlist",

  //Get rx request list API product id
  get_rx_request_list: "xcelecom_get_trn_rx_request_customer",

  //Get order List process id
  get_order_list_process_id: "xcelecom_get_trn_customer_order",

  // Process id for adding payments
  add_payments: "sgconf_create_customer_card_square_up_db",

  // Check customer process id
  check_customer: "sgconf_get_mst_user_payment_gateway_details",

  // Fetch added cards
  fetch_added_cards: "sgconf_get_mst_user_payment_gateway_card_mapping",

  // Delete customer process id
  delete_customer_card: "sgconf_delete_customer_card_square_up_db",

  // Cart count process id
  cart_count_process_id: "xcelecom_get_trn_customer_cart_count",
  //Update user insurance status
  update_user_insurance: "xcelecomconfig_update_patient_insurance_status",

  // Save for Later apis
  // *******************
  move_cart_item_to_saveforlater_process_id:
    "xcelecom_save_trn_service_cus_saveforlater_from_cart",
    move_to_wishlist_process_id  : "xcelecom_save_trn_service_cus_wishlist_from_cart",
  get_save_for_later_items_process_id:
    "xcelecom_get_trn_service_cus_saveforlater",
  move_saved_items_to_cart_process_id:
    "xcelecom_save_trn_customer_cart_item_from_saveforlater",
  //Health Card process id
  // Health Information process id
  get_health_info_process_id: "xcelecom_get_trn_user_health_info",
  add_update_health_info_process_id: "xcelecom_save_trn_user_health_info",

  add_health_card_process_id: "xcelecom_save_trn_user_health_card",
  get_health_card_process_id: "xcelecom_get_trn_user_health_card",
  delete_update_health_card_process_id:
    "xcelecomconfig_update_mst_ecom_usr_details_health_card_delete",
  //rx uploaded documents
  fetch_rx_list_uploaded_documents:
    "xcelecom_get_trn_rx_request_customer_documents",
  xcelecom_update_trn_rx_request_document_upload_status : "xcelecom_update_trn_rx_request_document_upload_status",
  //fetch rx list added products
  fetch_rx_list_added_products: "xcelecom_get_trn_rx_request_services",
  delete_saved_items_process_id: "xcelecom_update_trn_save_for_later_status",
  //Health Card process id
  // Insurance apis
  // *******************
  get_insurances_list_process_id: "xcelecomconfig_get_mst_insurance",
  get_insurances_coverage_list_process_id:
    "xcelecomconfig_get_mst_insurance_coverage",
  get_customer_insurance_process_id: "xcelecom_get_trn_patient_payer_plan",
  save_insurance_process_id: "xcelecom_save_trn_patient_payer_plan",
  //insurance card image upload front + back
  insurance_front_p_doctype_code:
    "user_insurance_image_front_page_document_type_code",
  insurance_front_p_docsubtype_code:
    "user_insurance_image_front_page_document_sub_type_code",
  insurance_front_p_trnt_code:
    "user_insurance_image_front_page_transaction_type_code",
  insurance_back_p_doctype_code:
    "user_insurance_image_back_page_document_type_code",
  insurance_back_p_docsubtype_code:
    "user_insurance_image_back_page_document_sub_type_code",
  insurance_back_p_trnt_code:
    "user_insurance_image_back_page_transaction_type_code",

  // Health Card Front Image Upload
  // ******************************
  health_card_front_image_p_doctype_code:
    "user_healthcard_image_front_page_document_type_code",
  health_card_front_image_p_docsubtype_code:
    "user_healthcard_image_front_page_document_sub_type_code",
  health_card_front_image_p_trnt_code:
    "user_healthcard_image_front_page_transaction_type_code",

  // Health Card Back Image Upload
  // ******************************
  health_card_back_image_p_doctype_code:
    "user_healthcard_image_back_page_document_type_code",
  health_card_back_image_p_docsubtype_code:
    "user_healthcard_image_back_page_document_sub_type_code",
  health_card_back_image_p_trnt_code:
    "user_healthcard_image_back_page_transaction_type_code",

  // Contact Us page api
  // ***********************************
  contact_us_process_id: "xcelecom_save_trn_customer_contactus_request",
  // Landing Page
  landing_page_config: "xcelecomconfig_get_mst_ecom_landing_page_config",
  //Process id to fetch order details
  fetch_order_details: "xcelecom_get_trn_customer_order_detail",

  //Cancel order process id
  cancel_order_process_id: "xcelecom_update_trn_customer_order_status",

  //Terms & Condition modalpopup process id
  term_modalpopup_process_id: "sgconf_get_org_terms_for_type",

  //api for call product units pricing table ========>
  xcelecomconfig_get_mst_ecom_product_list_packsize:
    "xcelecomconfig_get_mst_ecom_product_list_packsize",
  xcelecomconfig_update_trn_cart_address_id:
    "xcelecomconfig_update_trn_cart_address_id",
  sgconf_get_org_terms_for_type: "sgconf_get_org_terms_for_type",

  //process id for upload documents
  pro_user_get_documents_type_process_id:
    "xcelrcmconfig_get_ct_documents_dropdown",
  delete_document_process_id: "xcelrcm_delete_trn_document",
  save_document_process_id: "xcelrcm_save_trn_document_upload",
  get_document_process_id: "xcelrcm_get_trn_document_for_patient",
  upload_documents_pro_user_process_id: "xcelrcm_save_trn_document_upload",
  get_upload_documents_process_id: "xcelrcm_get_trn_document_for_patient",
  delete_single_document_process_id: "xcelrcm_delete_trn_document",
  sgconf_get_org_terms_for_type: "sgconf_get_org_terms_for_type",

  //process id for upload documents
  pro_user_get_documents_type_process_id:
    "xcelrcmconfig_get_ct_documents_dropdown",
  pro_user_get_my_list_process_id: "xcelecom_get_trn_cus_mylist",
  pro_user_save_my_list_process_id: "xcelecom_save_trn_cus_mylist",
  pro_user_add_product_my_list_process_id:
    "xcelecom_save_trn_cus_service_mylist",
  get_my_list_products: "xcelecom_get_trn_service_cus_mylist",
  pro_user_remove_item_my_list_process_id:
    "xcelecom_update_trn_cus_service_mylist",
  pro_user_disable_my_list_process_id: "xcelecom_update_trn_cus_mylist_disable",
  xcelecomconfig_update_pro_user_onboarding_status:
    "xcelecomconfig_update_pro_user_onboarding_status",
  pro_user_duplicate_my_list_process_id:
    "xcelecom_save_trn_duplicate_cus_mylist",
  pro_user_save_items_from_my_list_process_id:
    "xcelecom_save_trn_customer_cart_item_from_mylist",

  //process ids for apply coupon code
  xcelecom_update_trn_customer_cart_coupon_delete:
    "xcelecom_update_trn_customer_cart_coupon_delete",
  xcelecom_save_trn_customer_cart_coupon:
    "xcelecom_save_trn_customer_cart_coupon",
    
    xcelecomconfig_get_mst_ecom_product_detailV2 : "xcelecomconfig_get_mst_ecom_product_detailV2", 
};
