import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar/index";
import { Location, PenFill, TrashIcon } from "../../utils/appIcons";
import Button from "../../components/UI/Button";
import AddInsurance from "./AddInsurance";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import PopConfirm from "../../components/UI/PopConfirm";
import Editaddress_icon from "../../assets/icons/Editaddress_icon.svg";
import Delete_icon from "../../assets/icons/Delete_icon.svg";
import noshow from "../../assets/svg/noshow.svg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Insurance from "../../assets/svg/insuance_card.svg";
import InsuranceCard from "../../components/InsuranceCard";
import Config from "../../config/label";
import { msg_config } from "../../config/messages";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import PrimaryCardModal from "../../components/PrimaryCardModal";
import { color_config } from "../../config/colorConfig";
import { USER_PROFILE } from "../../config/Config";

const MyInsurance = () => {
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState<any>(false);
  const [insuranceListContainer, setInsuranceListContainer] = useState<any>([]);
  const [primaryInsurance, setPrimaryInsurance] = useState<any>();
  const [secondaryInsurance, setSecondaryInsurance] = useState<any>([]);

  const [modalType, setModalType] = useState("Add");
  const [modalOpen, setModalOpen] = useState(false);
  const [editInsuranceProfile, setEditInsuranceProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isDefault, setIsDefault] = useState<any>("0");

  const [isprimaryCardModalOpen, setIsPrimaryCardModalOpen] = useState(false);
  const apiContext = useContext(APIContext);
  const [suggestUsInit] = apiContext;

  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);

  const getInsurance = () => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_customer_insurance_process_id, {
        p_insurance_ref_id: "",
        p_patient_id: userProfileJson?.patient_id,
        p_org_id: userProfileJson?.org_id,
      }).then((res) => {
        if (res?.returnCode) {
          setInsuranceListContainer(res?.returnData);

          setPrimaryInsurance(
            res?.returnData?.filter((val: any) => val?.sequence === "1")
          );
          setSecondaryInsurance(
            res?.returnData.filter((val: any) => val?.sequence === "0")
          );
        }
        setIsLoading(false);
      });
    }
  };

  const onEditHandler = (id: any) => {
    setIsInsuranceModalOpen(true);
    setModalType("Edit");
    setEditInsuranceProfile(
      insuranceListContainer?.filter((val: any) => val?.insurance_ref_id === id)
    );
  };

  const onDeleteHandler = (id: any) => {
    if (suggestUsInit?.returnCode) {
      CallAPI(config?.save_insurance_process_id, {
        p_ptmpln_id: id,
        p_patient_id: userProfileJson?.patient_id,
        p_org_id: userProfileJson?.org_id,
        p_ptmpln_status: "N",
        p_insurance_id: "",
        p_ppln_id: "",
        p_ptmpln_membership_number: "",
        p_ptmpln_date_from: "",
        p_ptmpln_date_to: "",
        p_ptmpln_sequence: "",
        p_insurance_front_image_document_id: "",
        p_insurance_back_image_document_id: "",
        p_internal_flag: "",
      }).then((res) => {
        if (res?.returnCode) {
          getInsurance();
        }
      });
    }
  };

  useEffect(() => {
    getInsurance();
  }, [suggestUsInit]);

  return (
    <>
      <div
        className={`${styles?.mainContainer}`}
        onClick={(e) => {
          setModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="outer_container">
        <div className={`${styles?.grid}`}>
          <div className={`${styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
          <div className={`${styles?.gridRightItem}`}>
            <div className={`${styles?.headerContainer}`}>
              <div style={{ display: "flex", alignItems:"center" }}>
                <img
                  src={Insurance}
                  style={{ height: 40, width: 45 }}
                  alt="Icon"
                  draggable="false"
                />
                <h2 className={`${styles?.heading}`}>Insurance Cards</h2>
              </div>
              {insuranceListContainer?.length < 4 && (
                <Button
                  type={Config?.lbl_Btn_type_primary}
                  className={`${styles?.customAddButton}`}
                  onClick={() => {
                    setIsInsuranceModalOpen(true);
                    setModalType("Add");
                    // setIsPrimaryCardModalOpen(true);
                  }}
                >
                  <span className={`${styles?.addToolTip}`}>
                    {" "}
                    Add New Card{" "}
                  </span>{" "}
                  +
                </Button>
              )}
            </div>
            {isLoading ? (
              <>
                <br />
                <br />
                <br />
                <ShimmerSimpleGallery row={2} col={3} imageHeight={150} />
              </>
            ) : (
              <div className={`${styles?.infoContainer}`}>
                {insuranceListContainer?.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      color: color_config?.common_bg_color,
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      paddingTop: "100px",
                    }}
                  >
                    <div className={styles["checkIconWrapper"]}>
                      <img
                        src={noshow}
                        alt="Empty Cart icon"
                        draggable="false"
                      />
                    </div>
                    <b>No Insurance found</b>
                  </div>
                ) : (
                  <div className={`${styles?.insurances}`}>
                    <div className={`${styles?.insurance}`}>
                      <h3>Primary</h3>
                      {primaryInsurance?.length > 0 ? (
                        <div className={`${styles?.primaryInsurance}`}>
                          <InsuranceCard
                            name={primaryInsurance[0]?.description}
                            number={
                              primaryInsurance[0]?.ptmpln_membership_number
                            }
                            endDate={primaryInsurance[0]?.ptmpln_date_to}
                            frontImg={primaryInsurance[0]?.insurance_front}
                            backImg={primaryInsurance[0]?.insurance_back}
                            primaryCardHolder={
                              primaryInsurance[0]?.primary_card_holder
                            }
                            startDate={primaryInsurance[0]?.ptmpln_date_from}
                            coverage={primaryInsurance[0]?.coverage_desc}
                            getInsurance={getInsurance}
                            oneditbtnclick={() => {
                              onEditHandler(
                                primaryInsurance[0]?.insurance_ref_id
                              );
                            }}
                            ondeletebtnclick={() => {
                              onDeleteHandler(
                                primaryInsurance[0]?.insurance_ref_id
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            color: color_config?.common_bg_color,
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div className={styles["checkIconWrapper"]}>
                            <img
                              src={noshow}
                              alt="Empty Cart icon"
                              draggable="false"
                            />
                          </div>
                          <b> No Primary Insurance found</b>
                        </div>
                      )}
                    </div>
                    <div className={`${styles?.insurance}`}>
                      <h3>Secondary</h3>
                      {secondaryInsurance?.length > 0 ? (
                        <div className={`${styles?.insuranceGrid}`}>
                          {secondaryInsurance?.map((item: any) => (
                            <InsuranceCard
                              name={item?.description}
                              number={item?.ptmpln_membership_number}
                              frontImg={item?.insurance_front}
                              endDate={item?.ptmpln_date_to}
                              startDate={item?.ptmpln_date_from}
                              coverage={item?.coverage_desc}
                              backImg={item?.insurance_back}
                              primaryCardHolder={item?.primary_card_holder}
                              getInsurance={getInsurance}
                              oneditbtnclick={() =>
                                onEditHandler(item?.insurance_ref_id)
                              }
                              ondeletebtnclick={() =>
                                onDeleteHandler(item?.insurance_ref_id)
                              }
                            />
                          ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            color: color_config?.common_bg_color,
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div className={styles["checkIconWrapper"]}>
                            <img
                              src={noshow}
                              alt="Empty Cart icon"
                              draggable="false"
                            />
                          </div>
                          <b> No Secondary Insurance found</b>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        </div>
      </div>
      {isInsuranceModalOpen && (
        <AddInsurance
          setIsInsuranceModalOpen={setIsInsuranceModalOpen}
          heading={"Add New Insurance"}
          modalType={modalType}
          editHeading={"Edit insurance"}
          getInsurance={getInsurance}
          editInsuranceProfile={
            editInsuranceProfile !== undefined ? editInsuranceProfile : ""
          }
          setIsDefault={setIsDefault}
          isDefault={isDefault}
        />
      )}

      {isprimaryCardModalOpen && (
        <PrimaryCardModal setIsInsuranceModalOpen={setIsPrimaryCardModalOpen} />
      )}
    </>
  );
};

export default MyInsurance;
