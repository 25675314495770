import React, { useState, useEffect, useContext } from "react";
import Styles from "./index.module.css";
import StarRating from "../StarRating";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../config/label";
import IconButton from "../UI/IconButton";
import Delete_icon from "../../assets/icons/Delete_icon.svg";
import MovetoCart_icon from "../../assets/icons/Movetocart_icon.svg";
import PopConfirm from "../UI/PopConfirm";
import { getProductImage } from "../../utils/utils";

export type productProps = {
  imgUrl?: any;
  imgAlt?: string;
  detail?: string;
  price?: string;
  actualPrice?: string;
  productUrl?: string;
  ratingCount?: number;
  viewType?: string;
  brand?: string;
  packagingId?: any;
  ribbon?: boolean;
  ribbonType?: string;
  onaddbuttonclick?: any;
  ondeletebuttonclick?: any;
  discountPercent?: any;
  type?: any;
  coPay?: any;
  productType?: any;
  loading?: any;
  productId?: any;
  product?:any
};

const WishlistCard: React.FC<productProps> = (props) => {
  const {
    imgUrl,
    imgAlt,
    detail,
    productUrl,
    price,
    actualPrice,
    ratingCount,
    viewType,
    brand,
    packagingId,
    ribbon = false,
    ribbonType,
    onaddbuttonclick,
    ondeletebuttonclick,
    discountPercent,
    type,
    coPay,
    productType,
    loading,
    productId,
    product
  } = props;
  const navigate = useNavigate();
  //getting product slug 
  const productSlug = product.slug ||"";
  const [isImageError, setImageError] = useState(false);
  const isPriceAvailable = product?.discounted_amount && product?.srv_price ? product?.discounted_amount === "0.00" || product?.discounted_amount === "0" && product?.srv_price === "0.00"   || product?.srv_price === "0" : false;

  const getRibbon = () => {
    switch (ribbonType) {
      case "corner-left":
        return `${Styles?.ribbon1} ${Styles?.left}`;
      case "corner-right":
        return `${Styles?.ribbon1} ${Styles?.right}`;
      case "folded":
        return `${Styles?.ribbon2}`;

      default:
        return `${Styles?.ribbon1} ${Styles?.left}`;
    }
  };


  const getProductPrice = (dPrice: any, price: any) => {
    try {
      const isPriceEqual = dPrice === "0.00" ? false : dPrice === price ? false : true;
      if (!isPriceEqual) {
        return (
          <div className={`${Styles?.priceDiv}`}>
            <p className={Styles?.price}>
              {Config?.product_currency}
              {price}
            </p>
          </div>
        )
      } else {
        return (
          <div className={`${Styles?.priceDiv}`}>
            <p className={Styles?.price}>
              {Config?.product_currency}
              {dPrice}
            </p>
              <p className={`${Styles?.actualPrice} flex items-center`}>
                {Config?.product_currency}
                <del>{price}</del>
              </p>
          </div>
        )
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div
      className={`${Styles?.container} ${viewType === "LIST" && Styles?.listView
        }  ${ribbonType?.includes("corner") && Styles?.overflowHidden}`}
    >
      {/* {ribbon && discountPercent !== "0" && discountPercent !== null && (
        <p className={`${Styles?.ribbon} ${getRibbon()}`}>{discountPercent}%</p>
      )} */}
      <Link to={`/product-detail/${productSlug}`}>
      <div
        className={`${Styles?.imageBox} ${viewType === "LIST" && Styles?.listView
          }`}
        // onClick={() => productUrl && navigate(`${productUrl}`)}
      >
        <img src={getProductImage(productType, imgUrl)} alt={imgAlt} draggable="false"
          onError={(e: any) => {
            e.target.src = getProductImage(productType, "");
          }}
        />
      </div>
      </Link>
      <div className={`${Styles?.wishDescp}`}>
        <div className="w-full whish-title">
          <div className={`${Styles?.wishDescpHead}`}>
            {type !== "OTC" && (
              <div className={`${Styles?.badge_pulsate_outer}`}>
                <div className={`${Styles?.badge_pulsate}`}>
                  {Config?.Rx_text}
                  <div className={`${Styles?.rx_tooltip}`}>
                    {Config?.need_prescription}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <Link to={`/product-detail/${productSlug}`}
          className={Styles?.productAnchor}
          >
          <div
            className={`${Styles?.detailWrapper}`}
            // onClick={() => productUrl && navigate(`${productUrl}`)}
          >
            <p className={`${Styles?.detail}`}>{detail + " " + packagingId}</p>
          </div>
          </Link>
          
          <div className={Styles?.brandWrapper}>
            <p className={Styles?.brand}>{brand}</p>
          </div>
        </div>
        <div className={`${Styles?.whishFooter}`}>
          <div>
            {getProductPrice(price, actualPrice)}
            {(Number(coPay) > 0 && (
              <p className={Styles?.coPay}>
                {Config?.estimated_cost_pay_text} {Config?.product_currency}
                {coPay}
              </p>
            )) ||
              ""}
          </div>
          <div className={Styles?.whish_buttons}>

            {/* <PopConfirm title="Are you sure ?" onConfirm={ondeletebuttonclick}>
              {/*<TrashIcon
            width="20"
            height="20"
            style={{ color: "red" }}
            className={`${Styles?.trashIcon}`}
          />}
              <IconButton title="Delete" className={Styles["actionButton"]}>
                <img src={Delete_icon} alt="Delete" draggable="false" />
              </IconButton>
            </PopConfirm> */}


            <IconButton
              onClick={!isPriceAvailable ? onaddbuttonclick : undefined}
              title="Move to cart"
              className={`${Styles["actionButton"]} ${Styles["cart_btn"]} ${isPriceAvailable ? Styles['addToCartDisabled'] : ""}`}
            >
              {/* <img
                  src={MovetoCart_icon}
                  alt="Move to cart"
                  draggable="false"
                /> */}
              {loading?.wishlistId === productId ? <i className="fa fa-spinner fa-spin"></i> :
                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0898 21.25C15.554 21.25 15.9991 21.0656 16.3273 20.7374C16.6555 20.4092 16.8398 19.9641 16.8398 19.5C16.8398 19.0359 16.6555 18.5908 16.3273 18.2626C15.9991 17.9344 15.554 17.75 15.0898 17.75C14.6257 17.75 14.1806 17.9344 13.8524 18.2626C13.5242 18.5908 13.3398 19.0359 13.3398 19.5C13.3398 19.9641 13.5242 20.4092 13.8524 20.7374C14.1806 21.0656 14.6257 21.25 15.0898 21.25ZM7.08984 21.25C7.55397 21.25 7.99909 21.0656 8.32728 20.7374C8.65547 20.4092 8.83984 19.9641 8.83984 19.5C8.83984 19.0359 8.65547 18.5908 8.32728 18.2626C7.99909 17.9344 7.55397 17.75 7.08984 17.75C6.62571 17.75 6.1806 17.9344 5.85241 18.2626C5.52422 18.5908 5.33984 19.0359 5.33984 19.5C5.33984 19.9641 5.52422 20.4092 5.85241 20.7374C6.1806 21.0656 6.62571 21.25 7.08984 21.25ZM3.67984 2.69L3.47984 5.14C3.43984 5.61 3.80984 6 4.27984 6H19.5898C20.0098 6 20.3598 5.68 20.3898 5.26C20.5198 3.49 19.1698 2.05 17.3998 2.05H5.10984C5.00984 1.61 4.80984 1.19 4.49984 0.84C3.99984 0.31 3.29984 0 2.57984 0H0.839844C0.429844 0 0.0898438 0.34 0.0898438 0.75C0.0898438 1.16 0.429844 1.5 0.839844 1.5H2.57984C2.88984 1.5 3.17984 1.63 3.38984 1.85C3.59984 2.08 3.69984 2.38 3.67984 2.69ZM19.3498 7.5H4.00984C3.58984 7.5 3.24984 7.82 3.20984 8.23L2.84984 12.58C2.81551 12.983 2.86524 13.3888 2.99587 13.7717C3.1265 14.1545 3.3352 14.506 3.60875 14.804C3.88229 15.102 4.21473 15.3399 4.58501 15.5028C4.95529 15.6656 5.35534 15.7498 5.75984 15.75H16.8798C18.3798 15.75 19.6998 14.52 19.8098 13.02L20.1398 8.35C20.1494 8.24106 20.1359 8.13133 20.1004 8.02791C20.0649 7.92448 20.0081 7.82965 19.9336 7.74955C19.8592 7.66945 19.7687 7.60585 19.6682 7.56287C19.5676 7.51988 19.4592 7.49847 19.3498 7.5Z" fill="#006699" />
                </svg>}
            </IconButton>

            {ratingCount && <StarRating count={ratingCount} />}
            <IconButton onClick={ondeletebuttonclick} title="Delete" className={Styles["actionButton"]}>
              {/* <img src={Delete_icon} alt="Delete" draggable="false" /> */}
              {loading?.delete === productId ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa-solid fa-trash-can"></i>}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistCard;
