import React, { useEffect, useState, useContext } from "react";
import APIContext from "../../../../store/api-context";
import { CallAPI } from "../../../../suggest-us/callSuggest";
import Styles from "./index.module.css";
import ChangePassword from "./ChangePassword";
import EnterOTP from "./EnterOTP";
import EnterPhoneNumber from "./EnterPhoneNumber";
import StepsDetail from "./StepDetail";
import { ChevronRight, CloseIcon } from "../../../../utils/appIcons";
import { AppLogo } from "../../../../utils/appIcons";
import proAppLogo from "../../../../assets/pro_app_logo.png";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/UI/Button";
import btnConfig from "../../../../config/button";
import Alert from "../../../../components/UI/Alert";
import { config } from "../../../../config/processId";
import { useForm } from "react-hook-form";
import { UserAuthContext } from "../../../../store/UserAuthContext";
import VerificationModalPsw from "./VerificationModalPsw";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { msg_config } from "../../../../config/messages";
import Config from "../../../../config/label";
import { getDecryptedID, objEncrypt, setEncryptedID } from "../../../../utils/utils";
import { C_PWD, FORGOT_PASSWORD_PHONE_NO, LOGGED_IN_USER_ROLE, LOGINS_IN_BY, USER_LOGGED_IN, USER_PROFILE } from "../../../../config/Config";
import { parsePhoneNumber } from "react-phone-number-input";

var md5 = require("md5");

export type ModalProps = {
  setIsPasswordModalOpen?: any;
  setIsAlertChngPsw?: any;
  changePasswordHandler?: any;
  password?:any;
  setPassword?:any;
};

export type RegistrationFormFields = {
  phone: string;
};

const ForgotPassword: React.FC<ModalProps> = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [isAlert, setIsAlert] = useState<any>({
    istrue: false,
    type: "",
    msg: "",
  });
  const userType = getDecryptedID(LOGINS_IN_BY);

  const { setIsPasswordModalOpen, setIsAlertChngPsw, changePasswordHandler,password, setPassword} =
    props;

  const navigate = useNavigate();
  const [context, setContext] = useContext(UserAuthContext);

  const [suggestUsInit] = useContext(APIContext);
  const gaEventTracker = useAnalyticsEventTracker("Forgot password screen");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setFocus,
    setValue,
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onRegisterSubmit = (e: any) => {
    e.preventDefault();
    // handleNext();
  };

  const onBackHandler = (e: any) => {
    e.preventDefault();

    activeStep !== 0 && handleBack();
    if (activeStep === 0) {
      setIsPasswordModalOpen(false);
      navigate("/log-in");
    }
  };
  const onChangePasswordHandler = (data: any) => {
    const { newPassword } = data;
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    setButtonLoading(true);
    
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.create_password_process_id, {
          p_usr_id: "",
          p_phone_number: data?.phone,
          p_user_password: md5(newPassword),
        }).then((res) => {
          setButtonLoading(true);
          if (res?.returnCode === true) {
            setIsNewPassword(true);
            // localStorage.setItem(
            //   USER_PROFILE,
            //   objEncrypt(JSON.stringify(res?.returnData[0]))
            // );
            // localStorage.setItem("user-loggedin", "Y");
            // setContext((prev: any) => ({
            //   ...prev,
            //   UserAuth: res?.returnData[0],
            // }));
            // gaEventTracker("Password changed successfully");
            // setIsAlert({ istrue: "Y", type: "success", msg: res?.msg });
            navigate("/log-in");
          } else {
            console.error("error", res?.msg);
            setIsAlert({ istrue: true, type: "error", msg: res?.msg });
            gaEventTracker("Password change error");
          }
        });
      }
     else {
      console.error("error", "Passwords dont match");
      setIsAlert({
        istrue: true,
        type: "error",
        msg: msg_config?.loginpage_password_not_match,
      });
    }
  };
  // const onChangePasswordHandler = (data: any) => {
  //   const { confirmPassword, newPassword } = data;
  //   setIsAlert({
  //     istrue: false,
  //     type: "",
  //     msg: "",
  //   });
  //   setButtonLoading(true);
  //   if (newPassword === confirmPassword) {
  //     if (suggestUsInit?.returnCode === true) {
  //       CallAPI(config?.create_password_process_id, {
  //         p_usr_id: "",
  //         p_phone_number: data?.phone,
  //         p_user_password: md5(confirmPassword),
  //       }).then((res) => {
  //         setButtonLoading(true);
  //         if (res?.returnCode === true) {
  //           setIsConfirmPassword(true);
  //           localStorage.setItem(
  //            USER_PROFILE,
  //             objEncrypt(JSON.stringify(res?.returnData[0]))
  //           );
  //           localStorage.setItem("user-loggedin", "Y");
  //           setContext((prev: any) => ({
  //             ...prev,
  //             UserAuth: res?.returnData[0],
  //           }));
  //           gaEventTracker("Password changed successfully");
  //           // setIsAlert({ istrue: "Y", type: "success", msg: res?.msg });
  //           // navigate("/log-in");
  //         } else {
  //           setIsAlert({ istrue: "Y", type: "error", msg: res?.msg });
  //           gaEventTracker("Password change error");
  //         }
  //       });
  //     }
  //   } else {
  //     setIsAlert({
  //       istrue: true,
  //       type: "error",
  //       msg: msg_config?.loginpage_password_not_match,
  //     });
  //   }
  // };

  const SetAlert = (res: any) => {
    if (res?.returnCode === true) {
      setIsAlert({ istrue: true, type: "success", msg: res?.msg });
    }
    if (res?.returnCode === false) {
      setIsAlert({ istrue: true, type: "error", msg: res?.msg });
    }
  };

  const SetLocalStorage = (res: any) => {
    setEncryptedID(USER_PROFILE, res?.returnData[0])
    setEncryptedID(USER_LOGGED_IN, "Y");
    setContext((prev: any) => ({
      ...prev,
      UserAuth: res?.returnData[0],
    }));
  };

  const onEnterOtpHandler = (data: any) => {
    const values = Object.values(data?.otp);
    const p_otp = values.join("");
    setButtonLoading(true);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.confirm_otp_process_id, {
        p_usr_id: "",
        p_otp,
        p_usr_phone_number: data?.phone,
      }).then((res) => {
        if (res?.returnData?.length > 0) {
          SetLocalStorage(res);
        }
        setButtonLoading(false);
        if (res?.returnCode === true) {
          const c_PWD = {
            current_screen: res?.returnData[0]?.current_screen,
            sgMessageCode: res?.returnData[0]?.sgMessageCode,
          };
          setEncryptedID(C_PWD, JSON.stringify(c_PWD));
          gaEventTracker("OTP confirmed in forgot password screen");
          handleNext();
          if (res?.returnData[0]?.current_screen) {
          }
          SetAlert(res);
        } else {
          SetAlert(res);
          gaEventTracker("OTP confirmation error in forgot password screen");
        }
      });
    }
  };

  const onSentOTPHandler = (data: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    // console.log('data :>> ', data);
    const fullMobileNumber = parsePhoneNumber(`${data?.phone}`)
    const fullMobileNumberUpdated =  fullMobileNumber?.country ? `${fullMobileNumber?.country} +${fullMobileNumber?.countryCallingCode} ${fullMobileNumber?.nationalNumber}` : "";
    if(fullMobileNumberUpdated === ""){
      setIsAlert({ istrue: true, type: "error", msg: msg_config?.phone_valid });
    }else {
      if (suggestUsInit?.returnCode === true && context?.phLength >= 10) {
        setButtonLoading(true);
        CallAPI(config?.resend_otp_process_id, {
          p_phone_number: fullMobileNumberUpdated,
        })
          .then((res) => {
            setButtonLoading(false);
            if (res?.returnCode === true) {
              gaEventTracker("OTP sent in forgot password screen");
              setEncryptedID(FORGOT_PASSWORD_PHONE_NO, data?.phone);
              handleNext();
            } else {
              SetAlert(res);
              gaEventTracker("OTP sent error in forgot password screen");
              //setIsPasswordModalOpen(false);
            }
          })
          ?.catch((err) => {
            console.error("err", err);
          });
        //handleNext();
      } else if (context?.phLength < 10) {
        setError("phone", {
          type: "required",
          message: "Invalid phone number",
        });
      }
    }
   
  };

  const submitRecoverPassword = (data: any) => {
    switch (activeStep) {
      case 0:
        onSentOTPHandler(data);
        break;

      case 1:
        onEnterOtpHandler(data);
        break;

      case 2:
        onChangePasswordHandler(data);
        break;

      default:
        break;
    }
  };

  const getSignUpForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <EnterPhoneNumber
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            register={register}
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
          />
        );

      case 1:
        return (
          <EnterOTP
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            register={register}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            setFocus={setFocus}
            setValue={setValue}
          />
        );

      case 2:
        return (
          <ChangePassword
            setIsPasswordModalOpen={setIsPasswordModalOpen}
            setIsAlertChngPsw={setIsAlertChngPsw}
            // setConfirmPassword={setConfirmPassword}
            // confirmPassword={confirmPassword}
            password={password}
            setPassword={setPassword}
            register={register}
            control={control}
            errors={errors}
          />
        );

      default:
        break;
    }
  };

  const getNextButton = () => {
    return (
      
      <Button
        type={Config?.lbl_Btn_type_primary}
        size={Config?.lbl_Btn_size_medium}
        style={{ minWidth: `${activeStep !== 2 ? "" : "340px"}` }}
        loading={buttonLoading}
      >
        <div
          style={{
            display: "flex",
            justifyContent: `${activeStep !== 2 ? "center" : "center"}`,
            alignItems: "center",
            width: "fit-content",
            padding: "0 .5rem",
          }}
        >
          {getNextBtnName()}

          {/* {activeStep !== 2 && <ChevronRight font="1" color="#fff" />} */}
        </div>
      </Button>
    );
  };

  const getNextBtnName = () => {
    switch (activeStep) {
      case 0:
        return btnConfig?.signUp_Primary_Text_V1;
      case 1:
        return btnConfig?.signUp_Primary_Text_V2;
      case 2:
        return btnConfig?.signUp_Primary_Text_Continue;

      default:
        return "Next";
    }
  };

  return (
    <div className={`${Styles?.modalBackground}`}>
      <div className={`${Styles?.modalContainer}`}>
        <button
          className={`${Styles?.titleCLoseBtn}`}
          title="Close Button"
          onClick={() => setIsPasswordModalOpen(false)}
        >
          <img src={CloseIcon} alt="Cross Icon" draggable="false" />
        </button>
        <div className={`${Styles?.appLogo}`} >
        <img src={userType === LOGGED_IN_USER_ROLE ? proAppLogo : AppLogo} alt="app_logo" draggable="false" />
      </div>
        <h3 className={`${Styles?.forgotPasswordHeading}`}>
          {" "}
          Forgot Password{" "}
        </h3>
        <form onSubmit={handleSubmit(submitRecoverPassword)}>
          <div className={`${Styles?.forgetPassword}`}>
            <div className={`${Styles?.forgetPasswordTop}`}>
              <StepsDetail activeStep={activeStep} />
            </div>
            <div className={`${Styles?.forgetPasswordBottomOuter}`}>
              <div className={`${Styles?.forgetPasswordBottom}`}>
                {getSignUpForm()}
              </div>
              <div className={`${Styles?.page_buttons}`}>{getNextButton()}</div>
            </div>
          </div>
        </form>
        {/* {getBackButton()} */}
      </div>

      {/* {isConfirmPassword && (
        <VerificationModalPsw
          detail="Password changed successfully"
          setModalOpen={setIsConfirmPassword}
          setIsAlertChngPsw={setIsAlertChngPsw}
          setIsPasswordModalOpen={setIsPasswordModalOpen}
        />
      )} */}

{isNewPassword && (
        <VerificationModalPsw
          detail="Password changed successfully"
          setModalOpen={setIsNewPassword}
          setIsAlertChngPsw={setIsAlertChngPsw}
          setIsPasswordModalOpen={setIsPasswordModalOpen}
        />
      )}

      {isAlert?.istrue ? (
        <Alert type={isAlert?.type} info={isAlert?.msg} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ForgotPassword;
