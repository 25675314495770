import React from "react";
import Button from "../Button";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../../config/label";
import { LOGINS_IN_BY } from "../../../config/Config";
import { setEncryptedID } from "../../../utils/utils";

export type ModalProps = {
  setModalOpen?: any;
};

const OrderCompletion: React.FC<ModalProps> = (props) => {
  const { setModalOpen } = props;
  let navigate = useNavigate();

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() =>
            setModalOpen((prev: any) => ({
              ...prev,
              isLoginPopUP: false,
            }))
          }
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={Doctorclipart} alt="Cart icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            {Config?.lbl_login_req_txt}
          </Heading>
          <p></p>
        </div>
        <div className={Styles["buttons"]}>
          <Button className={Styles["login_cancel_btn"]}
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={() =>
              setModalOpen((prev: any) => ({
                ...prev,
                isLoginPopUP: false,
              }))
            }
          >
            {Config?.lbl_land_page_cancel}
          </Button>

          <Link
            to="/log-in"
            style={{ textDecoration: "none" }}
            draggable="false"
          >
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              onClick={() =>{
                setEncryptedID(LOGINS_IN_BY, "customer")
                setModalOpen((prev: any) => ({
                  ...prev,
                  isLoginPopUP: false,
                }))
              }
              }
            >
              {Config?.lbl_login_txt}
              {/* {"dljd"} */}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default OrderCompletion;
