import React, { useState } from "react";
import EnterEmail from "./EnterEmail";
import StepsDetail from "./StepsDetail";
import styles from "./index.module.css";
import ConfirmOTP from "./ConfirmOTP";
import Button from "../../components/UI/Button";
import { ChevronRight } from "../../utils/appIcons";
import { useNavigate } from "react-router-dom";
import btnConfig from "../../config/button";
import ChangePassword from "./ChangePassword";
import VerificationModal from "../../components/UI/VerificationModal";
import Config from "../../config/label";

const ForgotPassword = () => {
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onRegisterSubmit = (e: any) => {
    e.preventDefault();
    handleNext();
  };

  const onBackHandler = (e: any) => {
    e.preventDefault();

    activeStep !== 0 && handleBack();
    if (activeStep === 0) {
      navigate("/log-in");
    }
  };

  const getSignUpForm = () => {
    switch (activeStep) {
      case 0:
        return <EnterEmail />;

      case 1:
        return <ConfirmOTP />;

      case 2:
        return <ChangePassword />;

      default:
        break;
    }
  };

  const getBackButton = () => {
    switch (activeStep) {
      case 0:
        return (
          <Button
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={onBackHandler}
            className={`${styles?.ghostBtn}`}
          >
            {btnConfig?.signUp_Ghost_Text_Back}
          </Button>
        );
    }
  };

  const getNextButton = () => {
    switch (activeStep) {
      case 0:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {btnConfig?.signUp_Primary_Text_V1}
              <div style={{ marginTop: 1 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );
      case 1:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_V2}
              <div style={{ marginTop: 2 }}>
                <ChevronRight />
              </div>
            </div>
          </Button>
        );

      case 2:
        return (
          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            onClick={() => {
              if (isConfirmPassword) {
                navigate("/");
              } else {
                setIsConfirmPassword(true);
              }
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {btnConfig?.signUp_Primary_Text_Submit}
            </div>
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`${styles?.forgetPassword}`}>
      <div className={`${styles?.forgetPasswordLeft}`}>
        <StepsDetail activeStep={activeStep} />
      </div>
      <div className={`${styles?.forgetPasswordRight}`}>
        <form onSubmit={onRegisterSubmit}>
          {getSignUpForm()}

          <div className={`${styles?.page_buttons}`}>
            {getBackButton()}
            {getNextButton()}
          </div>
        </form>
      </div>
      {isConfirmPassword && (
        <VerificationModal
          detail="Password changed successfully"
          setModalOpen={setIsConfirmPassword}
        />
      )}
    </div>
  );
};

export default ForgotPassword;
