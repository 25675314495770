import React, { useEffect, useRef, useState } from "react";
import ManageParticipents from "./ManageParticipents";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";


const AddConversationModal = ({
  id,
  username,
  twilioUrl,
  setAddConversation,
  client,
  availableUsers,
  setAvailableUsers,
  onConversationClick
}) => {

  const inputRef = useRef(null)
  const [name, setName] = useState("");
  const [participants, setParticipants] = useState({
    value: "chat",
    label: "Chat",
  });
  const [participantsData, setParticipantsData] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const [chatType, setChatType] = useState({
    value: "group",
    label: "Group",
  });

  const [selectedUser, setSelectedUsers] = useState(null);


  useEffect(() => {
    inputRef?.current?.focus();
  });


  useEffect(() => {
    axios({
      method: "GET",
      url: `${twilioUrl}/chat/users`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setAvailableUsers(res?.data?.users);
    })
  }, [])




  /**************************  ADD SINGLE OR GROUP CONVERSATION  START *******************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!addLoading) {
      setAddLoading(true);
      if (client !== undefined) {
        try {
          const conversation = await client.createConversation({
            friendlyName: name || " ",
          });
          await conversation.join();
          await conversation.getParticipants();
          if (participants?.value === "chat" && selectedUser !== "") {
            // if (chatType?.value === "single") {
            //   await conversation.add(selectedUser?.value);
            //   const attributes = {
            //     users: [
            //       { usr_id: id, usr_name: username },
            //       { usr_id: selectedUser?.value, usr_name: selectedUser?.label },
            //     ],
            //     type: chatType?.value,
            //   };
            //   await conversation.updateAttributes(attributes);
            //   // const data = await conversation.getParticipants();
            //   // const partSid = data?.find(
            //   //   (p) => p?.identity !== id
            //   // );

            //   //update participantsData

            //   const includingMe = [selectedUser, { value: id, label: username }];

            //   includingMe?.forEach(async (v) => {
            //     conversation?._participants?.forEach(async (val) => {
            //       if (val?.identity === v?.value) {
            //         await axios({
            //           method: "POST",
            //           data: {
            //             partiSid: val?.sid,
            //             convoSid: conversation?.sid,
            //             attributes: {
            //               userID: v?.value,
            //               userName: v?.label,
            //             },
            //           },
            //           url: `${twilioUrl}/chat/updateParti`,
            //           headers: {
            //             "Content-Type": "application/json",
            //           },
            //         });
            //       }
            //     })
            //   })

            //   await onConversationClick(conversation?.sid)
            //   // await axios({
            //   //   method: "POST",
            //   //   url: `${twilioUrl}/chat/updateUser/${selectedUser?.value}`,
            //   //   data: {
            //   //     friendlyName: selectedUser?.label,
            //   //   },
            //   //   headers: {
            //   //     "Content-Type": "application/json",
            //   //   },
            //   // });
            //   setAddConversation(false);
            //   setAddLoading(false);
            //   toast.success("New conversation added successfully");
            // } else {
            if (selectedUser?.length > 1) {
              const userID = selectedUser?.map(
                (v) => { return { usr_id: v.value, usr_name: v.label } })
              const attributes = {
                userIds: [{ usr_id: id, usr_name: username }, ...userID],
                type: chatType?.value,
              };
              await selectedUser?.forEach(async (v) => await conversation.add(v.value));

              await conversation.updateAttributes(attributes);
              const includingMe = [...selectedUser, { value: id, label: username }];
              includingMe?.forEach(async (v) => {
                conversation?._participants?.forEach(async (val) => {
                  await axios({
                    method: "POST",
                    data: {
                      partiSid: val?.sid,
                      convoSid: conversation?.sid,
                      attributes: {
                        userID: v?.value,
                        userName: v?.label,
                      },
                    },
                    url: `${twilioUrl}/chat/updateParti`,
                    headers: {
                      "Content-Type": "application/json",
                    },
                  });

                })
              })
              setAddConversation(false);
              setAddLoading(false);
              // toast.success("New conversation added successfully");
            }
            else {
              toast.error("Please add  at least two users for group chat")
              setAddLoading(false);
            }
          }
          else if (participants?.value === "sms" && chatType?.value === 'single') {
            let allParti = [
              {
                value: participantsData?.name,
                label: participantsData?.name
              },
              {
                value: id,
                label: username
              }
            ]
            const userID = allParti?.map(
              (v) => { return { usr_id: v.value, usr_name: v.label } })
            const attributes = {
              users: userID,
              type: chatType?.value,
              subType: participants?.value
            };
            allParti?.forEach(async (v) => await conversation.add(v.value));
            await conversation.updateAttributes(attributes);
            userID?.forEach(async (v) => {
              conversation?._participants?.forEach(async (val) => {
                await axios({
                  method: "POST",
                  data: {
                    partiSid: val?.sid,
                    convoSid: conversation?.sid,
                    attributes: {
                      userID: v?.value,
                      userName: v?.label,
                    },
                  },
                  url: `${twilioUrl}/chat/updateParti`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
              })
            })
            setAddConversation(false);
            setAddLoading(false);
          }
          // } else {
          //   if (chatType?.value === "single") {
          //     await conversation?.addNonChatParticipant(siteConfig.TWILIO_CALLER_ID, participantsData?.name, {
          //       friendlyName: participantsData?.name,
          //     });
          //     const attributes = {
          //       users: [
          //         { usr_id: id, usr_name: username },
          //         { usr_id: participantsData?.name, usr_name: `${participants?.label}~${participantsData?.name}` },
          //       ],
          //       type: chatType?.value,
          //     };
          //     await conversation.updateAttributes(attributes);
          //     setAddConversation(false);
          //     setAddLoading(false);
          //     toast.success("New conversation added successfully");
          //   }
          // }

        } catch (e) {
          toast.error(e?.message);
          setAddLoading(false);
        }
      }
      else {
        setAddLoading(false);
        toast.error("select the required filed");
      }
    }


  };
  /**************************  ADD SINGLE OR GROUP CONVERSATION  END *******************************/
  const options = [
    {
      value: "chat",
      label: "Chat",
    },
    {
      value: "sms",
      label: "Sms",
    },
    // {
    //   value: "whatsapp",
    //   label: "Whatsapp",
    // },
  ];

  const optionsType = [
    {
      value: "single",
      label: "Single",
    },
    {
      value: "group",
      label: "Group",
    },
  ];


  return (
    <div className="add-chat">
      <div className="chat-header">
        <div className="padding-2" onClick={() => setAddConversation(false)}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
        <div className="title"> <div className="name">Create New Group</div></div>
      </div>
      <div className="body">
        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <div className="field">
              <p>Chat Type</p>
              <Select
                value={chatType}
                defaultValue={chatType}
                onChange={setChatType}
                options={optionsType}
                datalabel={`chat type`}
                filedName={"chatType"}
                id={"Chat_Type"}
                required={true}
              />
            </div>

            {chatType?.value !== "single" && (
              <div className="field">
                <div className="user-phone-select">
                  <p>Group Name</p>
                  <input
                    className="form-control "
                    id="conversation-name-input"
                    type="text"
                    ref={inputRef}
                    autoFocus={true}
                    required={true}
                    name="name"
                    placeholder={"Enter group name here..."}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            )}
            {chatType?.value !== 'group' && (
              <div className="field">
                <p>Chat mode</p>
                <Select
                  value={participants}
                  defaultValue={participants}
                  onChange={setParticipants}
                  options={options}
                  datalabel={`Manage Participants`}
                  filedName={"Manage Participants"}
                  id={"Manage_Participants"}
                  required={true}
                />
              </div>
            )}
            <ManageParticipents
            id={id}
              participants={participants?.value}
              chatType={chatType?.value !== "single"}
              availableUsers={availableUsers}
              setParticipantsData={setParticipantsData}
              selectedUser={selectedUser}
              setSelectedUsers={setSelectedUsers}
              handleValue={(e) => {
                setParticipantsData((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }))

              }}
            />
          </div>
          <div className="add-conv-btn">
            <button
              type="submit"
              className="ui-button-default btn-vhelp"
            >
              {addLoading ? <span
                className="spinner-border spinner-border-sm loadingSpinner"
                role="status"
                aria-hidden="true"
              ></span> : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddConversationModal;
