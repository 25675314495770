import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Client } from '@twilio/conversations';
import { getTypingMessage } from "./api";

const formatter = (timestamp) => {
  return moment(timestamp).fromNow();
};
var spd_user_id = ""
var spd_user_name = ""
const ConversationView = (props) => {
  const {
    selectedConversationSid,
    conv,
    tabs,
    client,
    setAvailableUsers,
    availableUsers,
    onConversationClick,
    participants,
    lastMessage,
    myMessage,
    typingData,
    unReadMessagesLength,
    setMessage,
    id,
    name,
    twilioUrl,
    messageService,
    setSearchToAdd,
    handleJoin,
    setTotalUnreadCount,
    setNewConversation,
    setHideTabs,
    setQueueCount,
    activeUsers,
    setTabs,
    chatServiceSid
  } = props;
  const [updatedMassage, setUpdatedMassage] = useState(null)
  const [timestampString, setTimestampString] = useState("");
  const [joiningLoading, setJoiningLoading] = useState({
    id: "",
    loading: false
  })
  const [rejectLoading, setRejectLoading] = useState({
    id: "",
    loading: false
  })
  const [onlineUser, setOnlineUser] = useState([])
  /************************** UPDATE LAST READ INDEX START *******************************/
  // const updateLastReadMessageIndex = async (props) => {
  //   const index = props?.lastMessage?.index;
  //   await props?.updateLastReadMessageIndex(index);
  // };
  spd_user_id = id
  spd_user_name = name
  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((m) => {
      if (m.senderId === "message_added" && m.target === "mediaUrl") {
        setUpdatedMassage(m.text)
      }
    });
    return () => {
      if (subscription != null && subscription != undefined) {
        subscription.unsubscribe();
      }
    }
  }, [])



  useEffect(() => {
    const date = lastMessage?.date
      ? lastMessage?.date
      : conv?.dateCreated


    const timer = setInterval(
      () => setTimestampString(formatter(date)),
      1000
    );
    setTimestampString(formatter(date));
    getOnlineState()
    return () => clearInterval(timer);
  }, [handleJoin, lastMessage, conv]);


  // const participant=
  // useEffect(()=>{},[conv])

  /************************** UPDATE LAST READ INDEX END *******************************/
  const getMessage = async () => {
    try {
      await conv?.getMessages().then((res) => {
        setMessage((prev) => [...prev, ...res?.items]);
      });
    }
    catch (e) {
      console.log("Error:", e?.message)
    }
  };

  useEffect(() => {
    getMessage(conv);
    getOnlineState()
  }, [typingData, updatedMassage]);

  const typingInfo =
    typingData !== null ? getTypingMessage(typingData, id) : "";

  /************************** GET OTHER PARTICIPENTS START *******************************/

  const getOnlineState = async () => {
    try {
      const participants = await conv?.getParticipants();

      participants?.forEach(async (participant) => {
        if (participant?.identity !== spd_user_id) {
          const user = await participant.getUser();
          if (user?.isOnline) {
            setOnlineUser(prev => [...prev, user])
          }
        }
      })
    }
    catch (e) {
      console.log("Error:", e?.message)
    }
  }
  const getName = (val) => {
    const name = val?.users?.find((v) => v?.usr_id !== spd_user_id)
    return name?.usr_name
  }

  const count = conv?.sid === updatedMassage?.conversation?.sid && updatedMassage?.conversation?.lastReadMessageIndex === null ? 1 + unReadMessagesLength : unReadMessagesLength
  /************************** GET OTHER PARTICIPENTS END *******************************/

  const addNewUser = async (cg_ids, conval) => {
    try {
      var availableUsersId = await axios({
        method: "GET",
        url: `${twilioUrl}/chat/users`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      setAvailableUsers(availableUsersId?.data?.users);
      await conval.getParticipants()
      conval?.attributes?.userIds?.forEach(async (user) => {
        if (user?.usr_id !== spd_user_id) {
          await conval.add(user?.usr_id)
        }
      })

      // await onConversationClick(conval?.sid)

      // setHideTabs(true)
      setJoiningLoading({
        id: "",
        loading: false
      })
    } catch (error) {
      setJoiningLoading({
        id: "",
        loading: false
      })
      console.log("Error: ", error.message);
    }
  };

  const generateToken = async (val, cg_ids, conv) => {
    // CREATING TOKEN FOR THE SELECTED USER TO ACTIVATE ACCOUNT
    await Promise.all(val?.map(async (v) => {
      const data = await axios({
        method: "GET",
        url: `${twilioUrl}/chat/token?identity=${encodeURIComponent(
          v
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      new Client(data?.data?.token)
      return data
    })
    ).then(res => {
      if (res?.length > 0) {
        setTimeout(() => {
          addNewUser(cg_ids, conv)
        }, 2000)
      }
    })

  }

  const handleJoining = async (val) => {
    try {
      if (!joiningLoading?.loading) {
        setJoiningLoading({
          id: val?.attributes?.cg_id,
          loading: true
        })
        const conversation = await client?.createConversation({
          friendlyName: `${val?.friendlyName} - ${spd_user_name}`
        })
        await conversation?.join();
        await conversation?.getParticipants();
        const attributes = {
          ...val?.attributes,
          friendlyName: `${val?.attributes?.contact_group_name} - ${spd_user_name}`,
          userIds: [{ usr_id: spd_user_id, usr_name: spd_user_name }, ...val?.attributes?.usr_ids],
          joined_user_id: [spd_user_id],
        };
        delete attributes.usr_ids
        await conversation?.updateAttributes(attributes);
        const includingMe = val?.attributes?.urs_ids
        includingMe?.forEach(async (l) => {
          conversation?._participants?.forEach(async (val) => {
            if (val?.identity === l?.usr_id) {
              await axios({
                method: "POST",
                data: {
                  partiSid: val?.sid,
                  convoSid: conversation?.sid,
                  attributes: {
                    userID: l?.usr_id,
                    userName: l?.usr_name,
                  },
                },
                url: `${twilioUrl}/chat/updateParti`,
                headers: {
                  "Content-Type": "application/json",
                },
              });
            }
          })
        })
        const availableUsersId = availableUsers?.map(m => m.identity)
        const cg_ids = val?.attributes?.usr_ids?.map(n => n?.usr_id)
        const notAvalIDs = cg_ids?.filter(v => !availableUsersId?.includes(v))
        if (notAvalIDs?.length > 0) {
          generateToken(notAvalIDs, cg_ids, conversation)
        }
        else {
          val?.attributes?.usr_ids?.forEach(async (user) => {
            if (user?.usr_id !== spd_user_id) {
              await conversation.add(user?.usr_id)
            }
          })
          // await onConversationClick(conversation?.sid)
          // setHideTabs(true)
          setJoiningLoading({
            id: "",
            loading: false
          })
        }
      }
      else {
        return false
      }
    }
    catch (e) {
      console.log("Error :", e?.message)
      setHideTabs(false)
      setJoiningLoading({
        id: "",
        loading: false
      })
    }
  }


  const handleAccept = async (e) => {
    e?.stopPropagation()
    try {
      if (!joiningLoading?.loading) {
        setTotalUnreadCount(0)
        setJoiningLoading({
          id: conv?.sid,
          loading: true
        })

        if (conv?.attributes?.assign_to?.usr_id) {
          const joined_user_id = conv?.attributes?.joined_user_id?.filter(v => v !== conv?.attributes?.assign_by?.usr_id)
          const attributes = {
            ...conv?.attributes,
            joined_user_id: [...joined_user_id, spd_user_id],
          }
          await axios({
            method: "POST",
            url: `${twilioUrl}/chat/createMessage`,
            data: {
              body: {
                sid: conv?.sid,
                chatServiceSid,
                identity: conv?.attributes?.assign_by?.usr_id,
                body: `${conv?.attributes?.assign_by?.usr_name} has left the conversation`
              },
              attributes: {
                type: "queue",
                leftId: conv?.attributes?.assign_by?.usr_id,
                action: "leave",
                staffs: conv?.attributes?.staffs?.map(v => v?.usr_id)
              }
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
          const parti = []
          conv?._participants?.forEach(v => {

            return parti?.push(v)

          })
          const remainingParti = parti?.filter(p => !attributes?.joined_user_id?.includes(p?.identity))
          remainingParti?.forEach(async (c) => {
            await conv?.removeParticipant(c?.sid)
          })
          delete attributes.assign_by;
          delete attributes.assign_to;
          await conv?.updateAttributes(attributes);
          const includingMe = [{ value: spd_user_id, label: spd_user_name }]
          includingMe?.forEach(async (l) => {
            conv?._participants?.forEach(async (v) => {
              if (v?.identity === l?.value) {
                await axios({
                  method: "POST",
                  data: {
                    partiSid: v?.sid,
                    convoSid: val?.sid,
                    attributes: {
                      userID: l?.value,
                      userName: l?.label,
                    },
                  },
                  url: `${twilioUrl}/chat/updateParti`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              }
            })
          })

          await onConversationClick(conv?.sid)
          await axios({
            method: "POST",
            url: `${twilioUrl}/chat/createMessage`,
            data: {
              body: {
                sid: conv?.sid,
                chatServiceSid,
                identity: null,
                body: `${conv?.attributes?.assign_to?.usr_name} has joined the conversation`
              },
              attributes: {
                type: "info",
                joinedId: null,
                action: "join",
                staffs: conv?.attributes?.staffs?.map(v => v?.usr_id)
              }
            },
            headers: {
              "Content-Type": "application/json",
            },
          })

          setHideTabs(true)
          setJoiningLoading({
            id: "",
            loading: false
          })
        }
        else {

          const attributes = {
            ...conv?.attributes,
            joined_user_id: [...conv?.attributes?.joined_user_id, spd_user_id],
          }

          const parti = []
          conv?._participants?.forEach(v => {
            if (v?.identity !== spd_user_id) {
              return parti?.push(v)
            }
          })
          const remainingParti = parti?.filter(p => !conv?.attributes?.joined_user_id?.includes(p?.identity))
          remainingParti?.forEach(async (c) => {
            await conv?.removeParticipant(c?.sid)
          })
          await conv?.updateAttributes(attributes);
          const includingMe = [{ value: spd_user_id, label: spd_user_name }]
          includingMe?.forEach(async (l) => {
            conv?._participants?.forEach(async (v) => {
              if (v?.identity === l?.value) {
                await axios({
                  method: "POST",
                  data: {
                    partiSid: v?.sid,
                    convoSid: val?.sid,
                    attributes: {
                      userID: l?.value,
                      userName: l?.label,
                    },
                  },
                  url: `${twilioUrl}/chat/updateParti`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              }
            })
          })
          await onConversationClick(conv?.sid)
          await axios({
            method: "POST",
            url: `${twilioUrl}/chat/createMessage`,
            data: {
              body: {
                sid: conv?.sid,
                chatServiceSid,
                identity: null,
                body: `${spd_user_name} has joined the conversation`
              },
              attributes: {
                type: "info",
                action: "join",
                staffs: conv?.attributes?.staffs?.map(v => v?.usr_id)
              }
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
          setHideTabs(true)
          setJoiningLoading({
            id: "",
            loading: false
          })
        }

      }


    }
    catch (e) {
      console.log("Error:", e?.message)
      setHideTabs(false)
      setJoiningLoading({
        id: "",
        loading: false
      })
    }
  }
  const handleReject = async (e) => {
    e?.stopPropagation()

    try {
      if (!rejectLoading?.loading) {
        setRejectLoading({
          id: conv?.attributes?.cg_id,
          loading: true
        })

        if (conv?.attributes?.assign_to?.usr_id) {

          const joined_user_id = conv?.attributes?.joined_user_id?.filter(v => v !== spd_user_id)
          const attributes = {
            ...conv?.attributes,
            joined_user_id: [...joined_user_id],
          }
          await axios({
            method: "POST",
            url: `${twilioUrl}/chat/createMessage`,
            data: {
              body: {
                sid: conv?.sid,
                chatServiceSid,
                identity: conv?.attributes?.assign_by?.usr_id,
                body: `${conv?.attributes?.assign_to?.usr_name} has rejected assignment request`
              },
              attributes: {
                type: "queue",
                leftId: spd_user_id,
                action: "reject",
                staffs: conv?.attributes?.staffs?.map(v => v?.usr_id)
              }
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
          const parti = []
          conv?._participants?.forEach(v => {

            return parti?.push(v)

          })
          const remainingParti = parti?.find(p => p?.identity === spd_user_id)

          await conv?.removeParticipant(remainingParti?.sid)
          delete attributes.assign_by;
          delete attributes.assign_to;
          await conv?.updateAttributes(attributes);


          await conv?.leave()
          setRejectLoading({
            id: "",
            loading: false
          })
        }

        else {
          await axios({
            method: "POST",
            url: `${twilioUrl}/chat/createMessage`,
            data: {
              body: {
                sid: conv?.sid,
                identity: null,
                chatServiceSid,
                body: `${conv?.attributes?.assign_to?.usr_name} has rejected assignment request`
              },
              attributes: {
                type: "queue"
              }
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
          await conv?.leave()
          setRejectLoading({
            id: "",
            loading: false
          })

        }
      }
    }
    catch (e) {
      setRejectLoading({
        id: "",
        loading: false
      })
      console.log("Error:", e?.message)
    }
  }
  const isOnline = activeUsers?.map((v) => v?.identity)
  const getOnlineStatus = (val) => {
    var value = false
    val?._participants?.forEach((v) => {
      if (v?.identity !== spd_user_id) {
        if (isOnline?.includes(v?.identity))
          value = true
      }
    })
    return value
  }
  try {
    return (
      <div
        className="filter-files  "
        onClick={(e) => {
          // e?.preventDefault()
          // e?.stopPropagation()
          if (handleJoin && tabs === "CHAT") {
            setTotalUnreadCount(0)
            handleJoining(conv)
          } else if (handleJoin && tabs === "QUEUE") {
            setHideTabs(true)
            onConversationClick(conv?.sid);
            setSearchToAdd("")
            setTotalUnreadCount(0)
          }
          else {
            setHideTabs(true)
            onConversationClick(conv?.sid);
            setSearchToAdd("")
            setTotalUnreadCount(0)

          }
        }}
      >
        <div className="wapper">
          <div className="wapper-left">
            {conv?.attributes?.contact_group_image ?
              <img src={conv?.attributes?.contact_group_image} height={30} width={30} alt="" />
              :
              conv?.attributes?.type === "group" || conv?.attributes?.type === "queue" ?
                <i className="fa fa-users" aria-hidden="true"></i>
                :
                <>
                  <i className="fa fa-user" aria-hidden="true"></i>
                  {conv?.attributes?.subType === undefined ?
                    <div className={getOnlineStatus(conv) ? "status-circle-online" : "status-circle-offline"}></div>
                    :
                    <div className="sms">sms</div>
                  }
                </>
            }
          </div>
          <div className="wapper-right">
            <div className="message ">
              <div className="auther-message">
                <h1 className="author ">
                  {conv?.attributes?.type == "queue"
                    ? conv?.createdBy === spd_user_id ? conv?.attributes?.friendlyName?.split("-")[0] : conv?.attributes?.friendlyName : conv?.attributes?.type === "group" ? conv?.friendlyName
                      : getName(conv?.attributes)}
                </h1>

                <div className={`message-preview  marginLeft-1`}>
                  {typingInfo
                    ? typingInfo
                    : lastMessage?.message
                      ? lastMessage?.message
                      : ``}
                </div>
              </div>
              <div
                style={{ fontWeight: "400" }}
                className="marginTop-0 lastMessage"
              >
                <div className={`message-time ${handleJoin && 'marginTop-0'}`}
                  style={{
                    fontSize: "var(--ui-0-52)",
                    fontWeight: "500",
                    textAlign: "right",
                    textTransform: "lowercase",
                    fontStyle: "italic",
                    lineHeight: "var(--ui-0-52)",
                    margin: "0 0 var(--ui-0-30)"
                  }}
                >
                  {conv?.createdBy === null ? "" : timestampString}
                </div>
                <div className="countWrapper">
                  {count > 0 && (
                    <div className={`notification ${handleJoin && 'marginTop-0'}`}>
                      {count}
                    </div>
                  )}
                </div>


                {joiningLoading?.id === conv?.attributes?.cg_id && (
                  <span
                    className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}

                <div className="chatJoiningButtons">
                  {handleJoin && tabs === "QUEUE" && <>
                    <button

                      className="filter ui-button-outline ui-button-small  marginTop-1"
                      type="button"
                      onClick={handleAccept}
                    >
                      {/* <i className="fa fa-plus marginRight-1 items-center "></i> */}
                      {joiningLoading?.id === conv?.sid ? <span
                        className="spinner-border spinner-border-sm loadingSpinner"
                        role="status"
                        aria-hidden="true"
                      ></span> : <i className="fa-solid fa-check"></i>}
                      <div className="tool-tip"><p>Accept</p></div>
                    </button>
                    <button
                      className="filter ui-button-outline ui-button-small  marginTop-1"
                      type="button"
                      onClick={handleReject}
                    >
                      {/* <i className="fa fa-plus marginRight-1 items-center "></i> */}
                      {rejectLoading?.id === conv?.attributes?.cg_id ? (
                        <span
                          className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : <i className="fa">&#x2715;</i>}
                      <div className="tool-tip"><p>Reject</p></div>
                    </button></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  } catch (e) {
    console.log("Error:", e?.message);
  }
};

export default ConversationView;
