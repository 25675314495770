import React, { memo, useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import StarRating from "../StarRating";
import LikeButton from "../LikeButton";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../config/label";
import IconButton from "../UI/IconButton";
import addCart from "../../assets/svg/addCart.svg";
import DummyImage from "../../assets/800391.png";
import { UserAuthContext } from "../../store/UserAuthContext";
import ProductCardMerchant from "../ProductCardMerchant";
import myList from "../../assets/mylist.svg";
import {
  ACTIVE_PRODUCT_ID,
  LOGGED_IN_USER_ROLE,
  MY_PROFILE_MENU_IMAGES,
  PRODUCT_CARD_IMAGES,
  PRODUCT_TYPE,
  USER_PROFILE,
} from "../../config/Config";
import UnitPricingDropdown from "../UnitPricingDropdown";
import { checkIfValueContainInArray } from "../../Suggestus_client_lib/util/util_functions";
import { unitPricingDropdownActions } from "../UnitPricingDropdown/slice";
import { useDispatch } from "react-redux";
import { JSONCheckerFunc, getDecryptedID, getProductImage, pricerConverterToDecimal } from "../../utils/utils";
import { mylistPageActions } from "../../pages/MyListPage/slice";
export type productProps = {
  type?: string;
  imgUrl?: any;
  imgAlt?: string;
  detail?: string;
  price?: string;
  actualPrice?: string;
  productUrl?: string;
  ratingCount?: number;
  viewType?: string;
  brand?: string;
  packagingId?: any;
  isWishListAdded?: any;
  ribbon?: boolean;
  ribbonType?: string;
  onLikeButtonClick?: any;
  onAddtoCartButtonClick?: any;
  discountPercent?: any;
  coPay?: any;
  pricingData?: any;
  productType?: any;
  product?: any;
  loading?: any;
  idx?: any;
};

/**
 * Use ribbon to true or false
 *Use ribbonType to specify the particular type of ribbon
 * @ribbon true or false // default --> false
 * ribbon prop can be used to activate or deactivate ribbon
 *
 * @ribbonType  corner-left/corner-right/folded    //default --> 'folded'
 * Do not use left or right values in case of 'floded' ribbon
 *
 * eg: <ProductCard  ribbon=true ribbonType='folded' />
 */
const ProductCard: React.FC<productProps> = (props) => {
  const {
    type,
    imgUrl,
    imgAlt,
    detail,
    //now getting only product id in productURL
    productUrl,
    price,
    actualPrice,
    ratingCount,
    viewType,
    brand,
    packagingId,
    isWishListAdded,
    ribbon,
    ribbonType = "folded",
    onLikeButtonClick,
    onAddtoCartButtonClick,
    discountPercent,
    coPay,
    pricingData,
    productType,
    product,
    loading,
  } = props;

  const productSlug = product?.slug || "";
  const isPriceAvailabe = parseFloat(product?.discounted_amount) === 0 && parseFloat(product?.srv_price) === 0;

  const navigate = useNavigate();
  const [isImageError, setImageError] = useState(false);
  const [unitPrices, setUnitPrices] = useState(
    !!pricingData
      ? pricingData
      : [
        {
          srv_pack_units: "0",
          srv_pack_det_id: "",
          srv_pack_name: "",
          save_price: "00",
        },
      ]
  );
  // const getIcon = ()=>{
  //   switch (productType {
  //     case value:

  //       break;

  //     default:
  //       break;
  //   }
  // }

  const getRibbon = () => {
    switch (ribbonType) {
      case "corner-left":
        return `${Styles?.ribbon1} ${Styles?.left}`;
      case "corner-right":
        return `${Styles?.ribbon1} ${Styles?.right}`;
      case "folded":
        return `${Styles?.ribbon2}`;
      default:
        return `${Styles?.ribbon1} ${Styles?.left}`;
    }
  };



  // const getProductImage = () => {
  //   try {
  //     if (productType) {
  //       switch (productType.trim()) {
  //         case PRODUCT_TYPE?.tablet:
  //           return PRODUCT_CARD_IMAGES?.tablet;
  //         case PRODUCT_TYPE?.syrup:
  //           return PRODUCT_CARD_IMAGES?.syrup;
  //         case PRODUCT_TYPE?.bottle:
  //           return PRODUCT_CARD_IMAGES?.bottle;
  //         case PRODUCT_TYPE?.capsule:
  //           return PRODUCT_CARD_IMAGES?.capsule;
  //         default:
  //           return PRODUCT_CARD_IMAGES?.defaultImg;
  //       }
  //     } else {
  //       return PRODUCT_CARD_IMAGES?.defaultImg;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const dispatch = useDispatch();
  const [context, setContext] = useContext(UserAuthContext);
  const [productQty, setProductQty] = useState({
    productQuantity:
      context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? "" : "1",
    unitId: "",
  });
  // const [discountedPrice, setDiscountedPrice] = useState(unitPrices[0]?.srv_pack_units * Number(price) || "N/A");
  const [discountedPrice, setDiscountedPrice] = useState(
    unitPrices[0]?.discounted_price || "--"
  );
  const [sActualPrice, setActualPrice] = useState(
    unitPrices[0]?.srv_pack_units * unitPrices[0]?.unit_price || "--"
  );

  const isUserLoggedIn = getDecryptedID(USER_PROFILE);




  const getProductPrice = (price: any, dPrice: any) => {
    try {
      const finalPrice = price === "0.00" ? false : dPrice === price ? false : true;
      if (!finalPrice) {
        return (
          <div className={`flex items-center gap-2 ${Styles?.product_orignal_price}`}>
            <p className={Styles?.price}>{Config?.product_currency}{dPrice}</p>
          </div>
        )
      } else {

        return (
          <div className={`flex items-center gap-2 ${Styles?.product_orignal_price}`}>
            <p className={Styles?.price}>
              {Config?.product_currency}
              {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE
                ? discountedPrice.toFixed(2)
                : price}
            </p>
            <p className={`${Styles?.actualPrice} flex items-center`}>
              <del>
                {" "}
                {/* {Config?.product_currency} */}
                {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE
                  //@ts-ignore
                  ? sActualPrice === "--" ? "" : Config?.product_currency + sActualPrice.toFixed(2)
                  // ? sActualPrice ==="--" ? "" : Config?.product_currency + Math.trunc( sActualPrice )
                  :
                  // actualPrice === price ? "" : Config?.product_currency + "" + actualPrice || "--"
                  dPrice}
              </del>
            </p>
          </div>
        )
      }

    } catch (error) {
      console.error(error);
    }
  }




  useEffect(() => {
    if (!!product?.packsize_json) {
      const pricingData = JSONCheckerFunc(product?.packsize_json)?.map(
        (item: any) => ({
          srv_pack_units: item?.srv_pack_units,
          srv_pack_det_id: item?.srv_pack_det_id,
          srv_pack_name: item?.srv_pack_name,
          save_price: item?.save_price,
          discounted_price: item?.discounted_price,
          is_default: item?.is_default,
          unit_price: item?.unit_price,
          srv_pack_unit_discount: item?.srv_pack_unit_discount,
          srv_pack_id: item?.srv_pack_id,
        })
      );
      setUnitPrices(pricingData);
      // if (location.pathname === "/my-list") {
      dispatch(
        unitPricingDropdownActions?.SELECT_PRICING({
          srv_id: product?.srv_id,
          selected: pricingData[0],
        })
      );
      // }
    } else {
      // if (location.pathname === "/my-list") {
      setUnitPrices([]);
      dispatch(
        unitPricingDropdownActions?.SELECT_PRICING({
          srv_id: product?.srv_id,
          selected: {},
        })
      );
      // }
    }
    if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
      if (unitPrices.length > 0) {
        setProductQty((prev: any) => {
          return {
            ...prev,
            productQuantity: unitPrices[0].srv_pack_units,
            unitId: unitPrices[0]?.srv_pack_det_id,
          };
        });
      }
    }
  }, []);

  return (
    <div
      className={`${Styles?.container} ${viewType === "LIST" && Styles?.listView
        }  ${ribbonType?.includes("corner") && Styles?.overflowHidden}`}
    >
      {/* {ribbon && discountPercent !== "0" && discountPercent !== null && (
        <p className={`${Styles?.ribbon} ${getRibbon()}`}>{discountPercent}%</p>
      )} */}
      <div
        className={`${Styles?.imageBox} ${viewType === "LIST" && Styles?.listView
          }`}
        onClick={() => {
          // productUrl && navigate(`${productUrl}`)
          // window.open(productUrl, '_blank')?.focus();
        }}
      >
        <Link 
        to={`/product-detail/${productSlug}`}
        >
          <img
            src={getProductImage(productType, imgUrl)}
            alt={imgAlt}
            draggable="false"
            onError={(e: any) => {
              e.target.src = getProductImage(productType, "")
            }}

          />
        </Link>
        <div className={`${Styles?.buttonWhislist}`}>
          <LikeButton
            width="20"
            height="20"
            isWishListAdded={isWishListAdded}
            onLikeButtonClick={onLikeButtonClick}
          />
        </div>
        <div className={`${Styles?.productCardDescTop}`}>
          <div
            className={`flex items-center ${ratingCount ? "justify-between  mb-2 mt-2" : "justify-end mt-1"
              } `}
          >
            {type !== "OTC" && (
              <div className={`${Styles?.badge_pulsate_outer}`}>
                <div className={`${Styles?.badge_pulsate}`}>
                  {Config?.Rx_text}
                  <div className={`${Styles?.rx_tooltip}`}>
                    {Config?.need_prescription}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
      <div className={`${Styles?.productCardDesc}`}>
        <div className="w-full">
          {/* <div className={`${Styles?.productCardDescTop}`}>
            <div
              className={`flex items-center ${ratingCount ? "justify-between  mb-2 mt-2" : "justify-end mt-1"
                } `}
            >
              {type !== "OTC" && (
                <div className={`${Styles?.badge_pulsate_outer}`}>
                  <div className={`${Styles?.badge_pulsate}`}>
                    {Config?.Rx_text}
                    <div className={`${Styles?.rx_tooltip}`}>
                      {Config?.need_prescription}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <Link
            to={`/product-detail/${productSlug}`}
            className={Styles?.productAnchor}>
            <div className={`${Styles?.detailWrapper}`}>
              <p className={`${Styles?.detail}`}>{detail + " " + packagingId}</p>
            </div>
          </Link>
          <div className={Styles?.brandWrapper}>
            <p className={Styles?.brand}>{brand}</p>
          </div>

          {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? (
            <div
              className={`${Styles?.unitPricingDataWrapper}`}
            >
              {pricingData !== undefined &&
                pricingData !== null &&
                pricingData.length > 0
                ? ""
                : ""}
              <UnitPricingDropdown
                pricingData={unitPrices}
                isCartScreen={false}
                setProductQty={setProductQty}
                productQty={productQty}
                isProductCard={true}
                setDiscountedPrice={setDiscountedPrice}
                setActualPrice={setActualPrice}
                price={price}
                product={product}
              />
            </div>
          ) : (
            ""
          )}

          <div className={Styles?.productCardDescBottom}>
            <div className="flex items-center  justify-between">
              {/* <div className="rating">
                {ratingCount && <StarRating count={5} />}
                {<StarRating count={5} />}

              </div> */}
              <div className="flex items-center" style={{ gap: "10px" }} >
                <div
                  className={`${Styles[`addToCardArea`]} ${(context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE &&
                    pricingData?.length === 0) ||
                    (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE &&
                      pricingData === undefined)
                    ? Styles[`addToCardAreaDisabled`]
                    : ""
                    } ${price ? "" : Styles[`addToCardAreaDisabled`]}`}
                  onClick={() => {
                    //if user is pro
                    if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
                      //if pricing data is not coming in pro user
                      if (
                        pricingData?.length === 0 ||
                        pricingData === undefined ||
                        pricingData === null
                      ) {
                      } else {
                        if (!isPriceAvailabe) {
                          onAddtoCartButtonClick(productQty);
                        }
                        // setLoading(false);
                      }
                    } else {
                      //if user is customer
                      price ? !isPriceAvailabe && onAddtoCartButtonClick() : ""

                    }
                  }}
                >

                  <div title="add to cart" className={`${Styles["addToCardButton"]} ${isPriceAvailabe ? Styles['addToCartDisabled'] : Styles['addToCartEnabled']}`}>
                    {loading?.isTrue && loading?.productId === product?.srv_id ? <i className="fa fa-spinner fa-spin"></i> : <img src={MY_PROFILE_MENU_IMAGES?.cart} />}

                  </div>
                </div>
                <IconButton
                  title={Config?.lbl_myList}
                  onClick={() => {
                    if (isUserLoggedIn) {
                      dispatch(
                        mylistPageActions?.TOGGLE_MY_LIST({
                          showAddToMyList: true,
                          product,
                          productQty,
                        })
                      );
                    } else {
                      setContext((prev: any) => {
                        return { ...prev, loginPopup: true }
                      })
                    }
                  }}
                  className={`${Styles?.iconButton} ${unitPrices.length === 0 ? Styles?.["disabledMyList"] : ""
                    }`}
                >
                  <img src={myList} alt="My List" draggable="false" />
                </IconButton>
              </div>
              <div>
                {getProductPrice(price, actualPrice)}

                <div className={`flex items-center gap-2 ${Styles?.product_orignal_price}`}>

                </div>



                {/* {(Number(coPay) > 0 && (
                  <p className={Styles?.coPay}>
                    {Config?.estimated_cost_pay_text} {Config?.product_currency}
                    {coPay}
                  </p>
                )) ||
                  ""} */}
              </div>
              {/* <IconButton
                title="Add to cart"
                onClick={onAddtoCartButtonClick}
                className={`${Styles?.iconButton}`}
              >
                <img src={addCart} alt="add to cart" draggable="false" />
              </IconButton> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// export default ProductCard;
export default memo(ProductCard);
