import React, { useState, useEffect } from "react";
import Styles from "./index.module.css";
// import { HeartFill, Heart } from "../../utils/appIcons";
import IconButton from "../UI/IconButton";
import HeartFill from "../../assets/sf_svg/Red Heart.svg"
import Heart from "../../assets/sf_svg/Grey Heart.svg"

export type iconProps = {
  color?: string;
  font?: string;
  width?: string;
  height?: string;
  fillColor?: string;
  className?: string;
  style?: object;
  isWishListAdded?: any;
  onLikeButtonClick?: any;
};

const LikeButton: React.FC<iconProps> = (props) => {
  const {
    color,
    font,
    width,
    height,
    fillColor,
    className,
    style,
    isWishListAdded,
    onLikeButtonClick,
  } = props;

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    setLiked(isWishListAdded);
  }, [isWishListAdded]);

  return (
    <div className={`${Styles?.likeButton}`}>
      <IconButton
        onClick={() => {
          onLikeButtonClick();
          // setLiked(!liked);
        }}
        title={liked ? "Remove from wishlist" : "Add to wishlist"}
      >
        {liked ? (
          // <HeartFill
          //   font={font}
          //   color={color}
          //   fillColor={fillColor}
          //   width={width}
          //   height={height}
          //   style={style}
          //   className={`${className} ${Styles?.heartFill}`}
          // />
          <img src= {HeartFill} />
        ) : (
          // <Heart
          //   font={font}
          //   color={color}
          //   fillColor={fillColor}
          //   width={width}
          //   height={height}
          //   style={style}
          //   className={`${className} ${Styles?.heart}`}
          // />
          <img src= {Heart} />

        )}
      </IconButton>
    </div>
  );
};

export default LikeButton;
