import ContextMenu from "../../components/ContextMenu/ContextMenu";
import { useState } from "react";
import styles from "./index.module.css";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import { useDispatch, useSelector } from "react-redux";
import { mylistPageActions } from "./slice";
import editIcon from "../../assets/icons/Edit.svg";
import deleteIcon from "../../assets/icons/Delete.svg";
import duplicateIcon from "../../assets/icons/Duplicate.svg";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import { decryptData } from "../../utils/util_helper";
import { getID } from "../../utils/utils";
import { SG_USER_ID } from "../../config/Config";

const SidebarContextMenu = (props) => {

  const { p_list_id, userProfileJson, setEditMode ,setmyListRefresh, selectedList, setSelectedList} = props;
  const [showDeleteConfirm, setShowDeleteConfirm] = useState({
    istrue:false,
    deleteType:""
  });
  const list = useSelector(
    (state) =>
      state?.myListPageReducer.myLists.filter(
        (i) => i.p_list_id === p_list_id
      )[0]
  );
  const dispatch = useDispatch();
  const options = [
    {
      value: (
        <>
          <img src={editIcon}></img>Edit
        </>
      ),
      onClick: (e, p_list_id) => {
        setEditMode(true);
        dispatch(
          mylistPageActions?.EDIT_LIST({
            showAddNewMyList: true,
            p_list_id: p_list_id,
          })
        );
      },
    },
    {
      value: (
        <>
          {/* <img src={deleteIcon}></img> */}
          <i className="fa-solid fa-trash-can" draggable="false"></i>
          Delete{" "}
        </>
      ),
      onClick: (e, p_list_id) => {
        setShowDeleteConfirm({
          istrue:true,
          deleteType:"list"

        });
        setSelectedList((prev)=>{
          return {...prev, optId : p_list_id}
        })
      },
    },
    {
      value: (
        <>
          <img src={duplicateIcon}></img>Duplicate{" "}
        </>
      ),
      onClick: (e, p_list_id) => {
        (async () => {
          const res = await CallAPI(
            config?.pro_user_duplicate_my_list_process_id,
            {
              p_cus_id: userProfileJson?.customer_id,
              p_usr_id: decryptData(getID(SG_USER_ID)),
              p_list_id,
              p_list_title: list?.p_list_title,
              p_list_description: list?.p_list_description,
              p_internal_flag: "",
            }
          );
          if (res.returnCode === true) {
            dispatch(
              mylistPageActions?.ADD_NEW_LIST({
                newList: res.returnData[0],
              })
            );
          }
        })();
      },
    },
  ];
  return (
    <div
      className={`${styles?.SidebarContextMenu}`}
      key={"SidebarContextMenu_" + p_list_id}
    >
      <ContextMenu options={options} id={p_list_id} setSelectedList={setSelectedList}></ContextMenu>
      {showDeleteConfirm.istrue && (
        <DeleteConfirmPopup
          setModalOpen={setShowDeleteConfirm}
          userProfileJson={userProfileJson}
          p_list_id={p_list_id}
          setmyListRefresh={setmyListRefresh}
          showDeleteConfirm= {showDeleteConfirm}
          selectedList ={selectedList}
          setSelectedList={setSelectedList}
          
        />
      )}{" "}
    </div>
  );
};

export default SidebarContextMenu;
