import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import style from "./index.module.css";
import AppLink from "../../../components/UI/AppLink";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { JSONCheckerFunc } from "../../../utils/utils";

export type bannersProps = {
  widget: any;
};

const Banners: React.FC<bannersProps> = (props) => {
  const { widget } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (widget) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [widget]);

  const horizontal_settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    className: `${style?.slider}`,
  };

  const single_settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: `${style?.slider}`,
  };

  const getBannerURL = (targetType: string, targetValue: string) => {
    switch (targetType) {
      case "PRODUCT_DETAIL":
        return `/product-detail/${targetValue}`;
      case "CATEGORY":
        return `/product-list/${targetValue}`;
      case "BRAND":
        return `/product-brand/${targetValue}`;
      case "URL":
        return targetValue;
      default:
        return "";
    }
  };

  const HorizontalScroll = ({ widget }: any) => {
    const reference_details_data = JSONCheckerFunc(widget?.widget_type_reference_details_data);

   

    return (
      <Slider {...horizontal_settings}>
        {Array.isArray(reference_details_data) &&
          reference_details_data?.map((detailData: any) => {
            return (
              <AppLink
                to={getBannerURL(
                  detailData?.banner_target_type,
                  detailData?.banner_target_value,
                )}
                type={detailData?.banner_target_type === "URL" ? "link" : ""}
              >
                <div className={`${style?.slide}`}>
                  {isLoading ? (
                    <ShimmerThumbnail height={250} rounded />
                  ) : (
                    <img
                      src={detailData?.banner_url}
                      alt={detailData?.banner_target_type}
                      style={{}}
                      draggable="false"
                    />
                  )}
                </div>
              </AppLink>
            );
          })}
      </Slider>
    );
  };

  const SinlgeMatrix = ({ widget }: any) => {
    const reference_details_data = JSONCheckerFunc(
      widget?.widget_type_reference_details_data
    );

  
    return (
      <Slider {...single_settings}>
        {Array.isArray(reference_details_data) &&
          reference_details_data?.map((detailData: any) => (
            <AppLink
              to={getBannerURL(
                detailData?.banner_target_type,
                detailData?.banner_target_value,
              )}
              type={detailData?.banner_target_type === "URL" ? "link" : ""}
            >
              {isLoading ? (
                <ShimmerThumbnail height={250} rounded />
              ) : (
                <img
                  src={detailData?.banner_url}
                  alt={detailData?.banner_target_type}
                  style={{}}
                  draggable="false"
                />
              )}
            </AppLink>
          ))}
      </Slider>
    );
  };

  const DoubleMatrix = ({ widget }: any) => {
    const reference_details_data = JSONCheckerFunc(
      widget?.widget_type_reference_details_data
    );

    return (
      <div className={`${style?.matrix_wrapper}`}>
        {Array.isArray(reference_details_data) &&
          reference_details_data?.map((detailData: any) => (
            <AppLink
              to={getBannerURL(
                detailData?.banner_target_type,
                detailData?.banner_target_value,
              )}
              type={detailData?.banner_target_type === "URL" ? "link" : ""}
            >
              <div className={`${style?.banner_img_box2}`}>
                {isLoading ? (
                  <ShimmerThumbnail height={250} rounded />
                ) : (
                  <img
                    src={detailData?.banner_url}
                    alt={detailData?.banner_target_type}
                    draggable="false"
                  />
                )}
              </div>
            </AppLink>
          ))}
      </div>
    );
  };

  const TripleMatrix = ({ widget }: any) => {
    const reference_details_data = JSONCheckerFunc(
      widget?.widget_type_reference_details_data
    );
  
    return (
      <div className={`${style?.matrix_wrapper} ${style?.TripleMatrix}`}>
        {Array.isArray(reference_details_data) &&
          reference_details_data?.map((detailData: any) => (
            <AppLink
              to={getBannerURL(
                detailData?.banner_target_type,
                detailData?.banner_target_value,
              )}
              type={detailData?.banner_target_type === "URL" ? "link" : ""}
            >
              <div className={`${style?.banner_img_box}`}>
                {isLoading ? (
                  <ShimmerThumbnail height={250} rounded />
                ) : (
                  <img
                    src={detailData?.banner_url}
                    alt={detailData?.banner_target_type}
                    draggable="false"
                  />
                )}
              </div>
            </AppLink>
          ))}
      </div>
    );
  };

  const getWidget = (widgetType: string) => {
    switch (widgetType) {
      case "horizontal_scroll":
        return <HorizontalScroll widget={widget} />;
      case "sinlge_matrix":
        return <SinlgeMatrix widget={widget} />;
      case "double_matrix":
        return <DoubleMatrix widget={widget} />;
      case "triple matrix":
        return <TripleMatrix widget={widget} />;

      default:
        break;
    }
  };

  return (
    <section
      key={widget?.id}
      className={`${style?.widget}`}
      style={{ order: `${widget?.sequence}` }}
    >
      {getWidget(widget?.widget_type_reference_details_view_method)}
    </section>
  );
};

export default Banners;
