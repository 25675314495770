import React, { useState } from 'react'
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
const ConvSettings = ({ id, name, twilioUrl, conversation, participants, setShowSettings, chatServiceSid }) => {

    const [selectedUser, setSelectedUser] = useState(null)
    const [addLoading, setAddLoading] = useState(false);
    const [onlineStatus, setOnlineStatus] = useState([])
    const [loading, setLoading] = useState(false)
    const staffIds = conversation?.attributes?.staffs?.filter((s) => conversation?.createdBy !== s?.usr_id)?.map(v => v?.usr_id)
    const userIsAdmin = conversation?.attributes?.joined_user_id?.find(v => staffIds.includes(v))


    const getStaffOnlineStatus = async (val) => {
        try {
            setLoading(true)
            val?.forEach(async (user) => {
                if (user !== id) {
                    await axios({
                        method: "GET",
                        url: `${twilioUrl}/chat/singleUser?id=${user}`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }).then((res) => {
                        setOnlineStatus(prev => [...prev, res?.data?.user]);
                        setLoading(false)
                    });
                }
            })
        }
        catch (e) {
            console.log("Error : ", e?.message)
            setLoading(false)
        }
    }

    const getParticipantName = (val) => {
        var name = ""
        conversation?.attributes?.userIds?.forEach((v) => {
            if (val === v?.usr_id) {
                name = v?.usr_name
            }
        })
        return name
    }

    useEffect(() => {
        if (staffIds && onlineStatus?.length === 0) {
            getStaffOnlineStatus(staffIds)
        }
    }, [staffIds, onlineStatus])

    /**************************  REGISTERD USER END *******************************/
    const handleSubmit = async (e) => {
        e?.preventDefault()
        if (selectedUser === null) {
            toast.error("Please select the field")
        }
        else {
            try {
                setAddLoading(true)
                const attributes = {
                    ...conversation?.attributes,
                    assign_by: { usr_id: id, usr_name: name },
                    assign_to: { usr_id: selectedUser?.value, usr_name: selectedUser?.label }
                };
                await axios({
                    method: "POST",
                    url: `${twilioUrl}/chat/createMessage`,
                    data: {
                        body: {
                            sid: conversation?.sid,
                            chatServiceSid,
                            identity: id,
                            body: `${name} has assigned to ${selectedUser?.label}`
                        },
                        attributes: {
                            type: "queue",
                            action: "assign",
                            assignTo: `${selectedUser?.label}`,
                            staffs: conversation?.attributes?.staffs?.map(v => v?.usr_id)
                        }
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                await conversation?.updateAttributes(attributes)
                await conversation.add(selectedUser?.value)
                setShowSettings(false)
                setAddLoading(false)
            }
            catch (e) {
                setAddLoading(false)
                toast.error(e?.message)
            }
            // console.log(selectedUser, "assign")
        }
    }



    const onlineStaffs = onlineStatus?.map(p => {
        if (p?.isOnline === true) {
            return p?.identity
        }
    }).filter(v => v !== undefined)

    const notMeUsers = conversation?.attributes?.staffs?.map(
        (v) => {
            if (onlineStaffs?.includes(v?.usr_id))
                return { value: v?.usr_id, label: v?.usr_name }
        }
    ).filter(v => v !== undefined)

    return (
        <div className="convoSettings" >
            <div className="body">
                <div className='participantWrapper'>
                    <div className='field'>Participants</div>
                    <div className='participantList'>
                        {participants?.map(p => <div className='participant'>
                            <div className='participantListPer'>
                                <i className="fa fa-user" aria-hidden="true"></i>
                                {getParticipantName(p?.identity)}
                            </div>
                            <div>

                            </div>

                        </div>)}
                    </div>

                    {userIsAdmin === id && <>
                        <div className='assign'>Assign</div>
                        <div className="field participationGroupAdd">
                            <p className='marginTop-2'>Select user</p>
                            {loading ? <span
                                className="spinner-border spinner-border-sm loadingSpinner"
                                role="status"
                                aria-hidden="true"
                            ></span> : notMeUsers?.length === 0 && <span className='participationAddLabel'> *No staff is online </span>}
                            <div className="user-add-select">
                                <ReactMultiselectCheckboxes
                                    name={"users"}
                                    id={"users"}
                                    hideSearch={true}
                                    value={selectedUser}
                                    ff_parent_field_id={"users"}
                                    isSearchable={true}
                                    options={notMeUsers}
                                    isMulti={false}
                                    onChange={setSelectedUser}
                                />
                            </div>
                            {/* The identity used by the participant in Conversations. */}
                        </div>
                        <div className="add-conv-btn marginTop-2">
                            <button
                                type='button'
                                className="ui-button-default btn-vhelp"
                                onClick={handleSubmit}
                            >
                                {addLoading ?
                                    <span
                                        className="spinner-border spinner-border-sm loadingSpinner"
                                        role="status"
                                        aria-hidden="true"
                                    ></span> : "Assign"}
                            </button>
                        </div>
                    </>

                    }
                </div>
            </div>
        </div >
    )
}

export default ConvSettings