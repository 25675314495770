import { useContext, useEffect, useState } from "react";
import "./SlideDrawer.css";
import APIContext from "../../../../store/api-context";
import Config from "../../../../config/label";
import { CallAPI } from "../../../../suggest-us/callSuggest";
import { config } from "../../../../config/processId";
import Styles from "./index.module.css";
import Label from "../../../../components/UI/Label";
import Group3 from "../../../../assets/svg/my_wishlist.svg";
import ProfileImage from "../../../../assets/svg/user_1.svg";
import StepsDetail from "./StepsDetail";
import UploadedFileContainer from "./uploadedFileContainer";
import Drawer from "./PreviewDrawer/Drawer";
import RxProductCard from "./RxProductCard";
import AddtocartPopup from "../../../../components/ProductDetails/AddtocartPopup";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import Alert from "../../../../components/UI/Alert";
import { siteConfig } from "../../../../config/site";
import { color_config } from "../../../../config/colorConfig";
import Button from "../../../Landingpage/Button";
import { UserAuthContext } from "../../../../store/UserAuthContext";
import { AddIcon } from "../../../../utils/appIcons";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import btnConfig from "../../../../config/button";
import { getID, setEncryptedID } from "../../../../utils/utils";
import { decryptData } from "../../../../utils/util_helper";
import { CART_COUNT, SG_USER_ID } from "../../../../config/Config";
import moment from "moment";


const SlideDrawer = ({
  show,
  rxDetails,
  setDrawerOpen,
  headingName,
  name,
  userInfo,
  setUserInfo,
  profilePic,
  setProfilePic,
  rxFileContents,
  setRxFileContents,
  myRxContext,
  setMyRxContext,
  productdetails,
  setProductDetails,
  orderNumber
}) => {
  const user_id = decryptData(getID(SG_USER_ID));
  // const [userInfo, setUserInfo] = useState([]);
  const [suggestUsInit] = useContext(APIContext);
  // const [profilePic, setProfilePic] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  // const [rxFileContents, setRxFileContents] = useState([]);
  const [previewDrawerOpen, setPreviewdDrawerOpen] = useState(false);
  const [clickedPreview, setClickedPreview] = useState({ uri: "", name: "" });
  // const [myRxContext, setMyRxContext] = useState([]);
  const [context, setContext] = useContext(UserAuthContext)
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  // const [productdetails, setProductDetails] = useState("");
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });
  const [toDeleteData, setToDeleteData] = useState({});
  const [loading, setLoading] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker(
    "Prescription request detail modal"
  );

  let drawerClassz = "side-drawerz";
  if (show) {
    drawerClassz = "side-drawerz open";
  }


  useEffect(() => {
    try {
      if (toDeleteData?.delete) {
        setLoading(true)
        const process_id = toDeleteData?.process_id;
        const params = {
          p_rx_document_id: toDeleteData?.p_rx_document_id,
          p_usr_id: toDeleteData?.p_usr_id,
        }
        CallAPI(process_id, params)
          .then((res) => {
            setLoading(false)
            userInfohandler();
            setToDeleteData((prev) => {
              return { ...prev, delete: false, openConfirm: false }
            })
          })
      }
    } catch (error) {
      console.error(error);
    }
  }, [toDeleteData])

  const userInfohandler = () => {
    if (show === true) {
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.my_profile_process_id, {
          p_user_id: user_id,
        }).then((res) => {
          if (res?.returnCode === true) {
            setUserInfo([
              {
                icon: <i className="fa-solid fa-user"></i>,
                label: Config?.lbl_First_N,
                value:
                  res?.returnData[0]?.first_name === ""
                    ? "NA"
                    : res?.returnData[0]?.first_name + " " + res?.returnData[0]?.last_name,
              },
              // {
              //   label: Config?.lbl_Last_N,
              //   value:
              //     res?.returnData[0]?.last_name === ""
              //       ? "NA"
              //       : res?.returnData[0]?.last_name,
              // },
              {
                icon: <i className="fa-solid fa-phone"></i>,
                label: Config?.lbl_Phone,
                value:
                  res?.returnData[0]?.usr_phone === ""
                    ? "NA"
                    : res?.returnData[0]?.usr_phone,
              },
              {
                icon: <i className="fa-solid fa-envelope"></i>,
                label: Config?.lbl_Email,
                value:
                  res?.returnData[0]?.usr_email === ""
                    ? "NA"
                    : res?.returnData[0]?.usr_email,
              },
              {
                icon: <i className="fa-solid fa-calendar-days"></i>,
                label: Config?.lbl_BirthDay,
                value:
                  res?.returnData[0]?.usr_dob === null
                    ? "NA"
                    : res?.returnData[0]?.usr_dob,
              },
            ]);
            setProfilePic(res?.returnData[0]?.patient_img_path);
          } else console.error("error", res?.error);
        });

        CallAPI(config?.fetch_rx_list_uploaded_documents, {
          p_customer_id: rxDetails?.customer_id,
          p_rx_id: rxDetails?.id,
          p_rxrequest_upload_id: "",
        }).then((res) => {
          if (res?.returnCode) {
            setRxFileContents(res?.returnData);
          }
        });

        CallAPI(config?.fetch_rx_list_added_products, {
          p_id: "",
          p_rx_request_id: rxDetails?.id,
          // p_rx_request_id: "",
          p_service_id: "",
          p_status: "",
        }).then((res) => {
          if (res?.returnCode) {
            setMyRxContext(res?.returnData);
          }
        });
      }
    }
  };

  useEffect(() => {
    userInfohandler();
  }, [suggestUsInit, show]);

  useEffect(() => {
    if (context?.isRxUploaded) {
      userInfohandler();
    }
  }, [context])

  useEffect(() => {
    switch (rxDetails?.rx_stage_type) {
      case "NEW":
        setActiveStep(1);
        break;
      case "ASSIGNED":
        setActiveStep(2);
        break;
      case "TRANSCRIBED":
        setActiveStep(2);
        break;
      case "AWAITING":
        setActiveStep(3);
        break;
      case "PENDING":
        setActiveStep(3);
        break;
      case "RXREQUESTED":
        setActiveStep(3);
        break;
      case "RXRECD":
        setActiveStep(4);
        break;
      case "COMPLETED":
        setActiveStep(5);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [rxDetails]);

  const filePreviewHandler = (content) => {
    setClickedPreview({
      uri: content?.rx_documment_url,
      name: content?.rx_file_name,
    });
    setPreviewdDrawerOpen(true);
  };

  const addCartHandler = (product) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "OTC",
      }).then((res) => {
        if (res?.returnCode) {
          setEncryptedID(CART_COUNT, res?.returnData[0].cart_item_count);
          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          gaEventTracker(
            "Items added to cart",
            res?.returnData[0].cart_item_count
          );
        } else {
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
        }
      });
    }
  };

  const handleDeleteFile = (item) => {
    try {
      const process_id = config?.xcelecom_update_trn_rx_request_document_upload_status;
      const params = {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_rx_document_id: item?.doc_id,
        process_id: process_id,
        delete: false,
        openConfirm: true,
        loading: true,
      }
      setToDeleteData(params);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={drawerClassz}>
      <div
        style={{
          // backgroundColor: color_config?.common_bg_color,
          backgroundColor: "var(--ui-secondary)",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 10px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            src={Group3}
            alt="orders"
            style={{ height: "25px", width: "25px" }}
            draggable="false"
          />
          <p
            style={{
              color: color_config?.white_color,
              fontSize: "15px",
              marginLeft: "15px",
            }}
          >
            {headingName}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-lg pointer"
          viewBox="0 0 16 16"
          style={{
            color: color_config?.white_color,
            fontSize: "25",
            fontWeight: 200,
            marginTop: "3px",
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
      </div>

      {/* Drawer body */}

      <div className={`${Styles?.drawerBody}`}>
        {/* Prescription stepper */}
        <div>
          {/* <StepsDetail activeStep={activeStep} /> */}
        </div>
        <div className={`${Styles?.drawer_user_info_wrapper}`}>
          {/* Profile info card */}
          <div className={`${Styles?.userInfo}`}>
            <div className={Styles["checkImgWrapper"]}>
              <img
                src={profilePic || ProfileImage}
                alt="ProfileImg"
                draggable="false"
              />
            </div>
            <div className={`${Styles?.user_info_wrapper}`}>
              {userInfo?.length > 0 &&
                userInfo?.map((items, idx) => {
                  return <div key={idx}>
                    {/* <Label for="firstName" className={`${Styles?.infoLabel}`}>
                  {items?.label}
                </Label> */}
                    {items?.icon}
                    <p className={`${Styles?.infoValue}`}>
                    {items?.label === "Birth Date"
                                ? moment(items?.value)?.format("MMM D, YYYY")
                                : items?.value}
                    </p>
                  </div>
                })}
            </div>
          </div>
          {/* <!------------------------------------------ RX Card Section Starts ------------------------------------------> */}
          <section className={`${Styles?.RxCard}`}>
            <div className={`${Styles?.mainCard}`}>
              <div className={`${Styles?.cardHeader}`}>
                <div className={`${Styles?.row}`}>
                  <div className={` ${Styles?.d_flex}`}></div>
                </div>
              </div>
              <div
                className={`${Styles?.pl_3} ${Styles?.pr_3} ${Styles?.pb_3} ${Styles?.pt_2}`}
              >
                <form className={`${Styles?.customer_details}`}>
                  <div className={`${Styles?.rowGrid}`}>
                    <div>
                      <h2 className={`${Styles?.rx_title}`}>
                        {/* {`${rxDetails?.rx_number} (${rxDetails?.rx_type})`} */}
                        {rxDetails?.order_number && <i className="fa-solid fa-object-group"></i>}
                        {rxDetails?.order_number && `${" " + rxDetails?.order_number || ""}`}
                      </h2>
                    </div>
                    <div>
                      <h2 className={`${Styles?.rx_title}`}>
                        {/* {`${rxDetails?.rx_number} (${rxDetails?.rx_type})`} */}
                        {`${"# " + rxDetails?.rx_number}`}
                      </h2>
                    </div>

                    {/* <div>
                    <h4
                      className={`${Styles?.drawer_title}`}
                      style={{ fontSize: "16px" }}
                    >
                      {" "}
                      {
                        userInfo?.find((value) => value?.label === "First name")
                          ?.value
                      }{" "}
                      {
                        userInfo?.find((value) => value?.label === "Last name")
                          ?.value
                      }
                    </h4>
                  </div> */}

                    {!["", null, undefined]?.includes(rxDetails?.rx_source) ? (
                      <div>
                        {/* <label> {Config?.Prescription_req_detail_source} </label> */}

                        <h4 style={{ fontSize: "16px" }}>
                          {"# " + rxDetails?.rx_source}{" "}
                        </h4>
                      </div>
                    ) : (
                      ""
                    )}

                    <div>
                      {/* <label> {Config?.Prescription_req_detail_date}</label> */}
                     {rxDetails?.rx_created_on &&  <i className="fa-regular fa-calendar-days"></i>}
                      {rxDetails?.rx_created_on && <h4
                        className={`${Styles?.drawer_title}`}

                      >
                        { rxDetails?.rx_created_on}{" "}
                      </h4>}
                    </div>

                    {!["", null, undefined]?.includes(
                      rxDetails?.rx_source_location
                    ) ? (
                      <div>
                        <label>
                          {" "}
                          {Config?.Prescription_req_detail_location}{" "}
                        </label>
                        <h4 style={{ fontSize: "16px" }}>
                          {" "}
                          {rxDetails?.rx_source_location}{" "}
                        </h4>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
        {/* <!------------------------------------------ RX Card Section Ends ------------------------------------------> */}
        {/* Uploaded rx */}
        {rxFileContents?.length > 0 ? (
          <div className={`${Styles?.UploadedUserRxInfo}`}>
            {rxFileContents?.map((content, idx) => {
              return (
                <UploadedFileContainer
                  content={content}
                  key = {idx}
                  // onDeleteContent={deleteContentHandler}
                  // setDrawerOpen={setDrawerOpen}
                  filePreviewHandler={filePreviewHandler}
                  handleDeleteFile={handleDeleteFile}
                />
              );
            })}
            <div
              style={{ cursor: "pointer", display: "flex" }}
              className={Styles?.addMoreRxButton}
              onClick={() => {
                setContext((prev) => {
                  return { ...prev, isRxUploadActive: true, isNewProcessId: true, rx_id: rxDetails?.rx_id }
                })
              }}
            >
              <img src={AddIcon} />
              <p>{btnConfig?.btn_add_more}</p>

            </div>
          </div>
        )
          :
          (
            <div className={`${Styles?.UploadedUserRxInfo}`}>
              <div
                style={{ cursor: "pointer", display: "flex" }}
                className={Styles?.addMoreRxButton}
                onClick={() => {
                  setContext((prev) => {
                    return { ...prev, isRxUploadActive: true, isNewProcessId: true, rx_id: rxDetails?.rx_id }
                  })
                }}
              >
                <img src={AddIcon} />
                <p>{btnConfig?.btn_add}</p>

              </div>
            </div>

          )
        }

        {/* Added rx */}
        {myRxContext?.length > 0 && (
          <div className={`${Styles?.UploadedUserRxCard}`}>
            {myRxContext?.map((product, idx) => {
              return (
                <RxProductCard
                key= {idx}
                  productUrl={"/product-detail/" + product?.srv_id}
                  productImage={product?.srv_image}
                  heading={product?.srv_name}
                  PrescribedQnty={product?.prescribed_qty}
                  RefillQnty={product?.refill_qty}
                  refill={product?.refill}
                  product_address={product?.srv_source_location}
                  BalanceQuantity={product?.rx_balance_qty}
                  // addedToCart={product?.cart_status === "N" ? false : true}
                  addedToCart={false}
                  saltComposition={product?.salt_composition}
                  onClick={() => addCartHandler(product)}
                />
              );
            })}
          </div>
        )}
        <Drawer
          drawerOpen={previewDrawerOpen}
          setDrawerOpen={setPreviewdDrawerOpen}
          name={clickedPreview?.name}
          uri={clickedPreview?.uri}
          headingName={clickedPreview?.name}
        />

        {isCartPopUpOpen && (
          <AddtocartPopup
            img={productdetails?.srv_image.split("~")[0]}
            title={productdetails?.srv_name + productdetails?.srv_packaging}
            setModalOpen={setIsCartPopUPOpen}
          />
        )}

        {toDeleteData?.openConfirm && <ConfirmDeletePopup
          setToDeleteData={setToDeleteData}
          toDeleteData={toDeleteData}
          loading={loading}
        />}

        {isAlert?.istrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}
      </div>
    </div>
  );
};

export default SlideDrawer;
