import React, { useEffect, useState } from "react";
import { Client } from "@twilio/conversations";
import ConversationList from "./ConversationList";
import Conversation from "./Conversation";
import SearchUser from "./SearchUser";
import axios from "axios";
import jwt_decode from "jwt-decode"
import { toast } from "react-toastify";
import { spd_processId_config } from "./Config/processID";
var chatClient = null
var spd_user_id = ""
let mCount=0;
let mConversationListFlag=false;
let mLeaveFlag=true;

const TwilioConversation = ({
  token,
  id,
  name,
  userType,
  messageService,
  CallAPI,
  setAvailableUsers,
  setClient,
  client,
  availableUsers,
  setState,
  state,
  setSearchActive,
  searchActive,
  setTotalUnreadCount,
  customChannel,
  unjoinedGroups,
  tabs,
  twilioUrl,
  setHideTabs,
  setQueueCount,
  setTabs,
  activeUsers,
  setActiveUsers,
  showChat,
  isGuest,
  guestData,
}) => {
  const [searchToAdd, setSearchToAdd] = useState("");
  const [updateConversation, setupdateConversation] = useState(mCount);
  const [newConversation, setNewConversation] = useState([])
  const [unjoinedGroup, setUnjoinedGroup] = useState({
    id: "",
    processing: false
  })
  const [loading, setLoading] = useState(false);
  spd_user_id = id
  const decoded = jwt_decode(token)
  useEffect(() => {
    try {
      chatClient = new Client(token);
      setClient(chatClient);

      /************************** CLIENT INIT EVENTS START *******************************/
      chatClient.on("connectionStateChanged", (state) => {
        if (state === "connecting")
          setState((prev) => ({
            ...prev,
            statusString: "Connecting to Twilio…",
            status: "default",
          }));

        if (state === "connected") {
          setState((prev) => ({
            ...prev,
            statusString: "You are connected.",
            status: "success",
          }));
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              clientLoaded: false,
              loadingMessage: "No Chat Found"
            }))
          }, 10000)
        }
        if (state === "disconnecting") {
          setState((prev) => ({
            ...prev,
            statusString: "Disconnecting from Twilio…",
            conversationsReady: false,
            status: "default",
          }));
        }
        if (state === "disconnected") {
          setState((prev) => ({
            ...prev,
            statusString: "Disconnected.",
            conversationsReady: false,
            status: "warning",
          }));
        }
        if (state === "denied") {
          setState((prev) => ({
            ...prev,
            statusString: "Failed to connect.",
            conversationsReady: false,
            status: "error",
          }));
        }
      });
      /************************** CLIENT INIT EVENTS END *******************************/
      /************************** NEW MESSAGE ADDED  EVENTS START *******************************/
      chatClient.on("messageAdded", (event) => {
        messageService.sendMessage(
          "message_added",
          event,
          "mediaUrl"
        );
        messageService.sendMessage(
          "topHeader",
          true,
          "playSound"
        );
      });
      /************************** NEW MESSAGE ADDED  EVENTS END *******************************/
      /************************** CONVERSATION  EVENTS START *******************************/
      chatClient.on("conversationAdded", async (conversation) => {
        setState((prev) => ({
          ...prev,
          clientLoaded: false,
          loadingMessage: ""
        }))
        mConversationListFlag=true;
        conversation.on("typingEnded", (participant) => {
          messageService.sendMessage(
            "typingEnded",
            { isTyping: false },
            "twilioChat"
          );
        });
      });
      chatClient.on("conversationAdded", async (conversation) => {
        messageService.sendMessage(
          "conversationAdded", true
          ,
          "twilioChat"
        )
        mConversationListFlag=true;
        setupdateConversation(mCount++);
        setState((prev) => ({
          ...prev,
          clientLoaded: false,
          loadingMessage: "",
          conversations: [...prev.conversations, conversation],
        }))
        conversation.on("participantLeft", (part) => {
          var parti = []
          part?.conversation?._participants?.forEach(v => { parti?.push(v) })
          messageService.sendMessage(
            "startChat",
            {
              sid: part?.conversation?.sid,
              participant: parti
            },
            "twilioChat"
          )


          if (part?.identity === spd_user_id) {
            setState(prev => ({ ...prev, selectedConversationSid: "" }))
            setHideTabs(false)
          }
        })
        conversation.on("typingStarted", (participant) => {
          messageService.sendMessage(
            "typingStarted",
            {
              convoSid: participant?.conversation?.sid,
              psid: participant?.sid,
              name: participant?.attributes?.userName,
              userID: participant?.attributes?.userID,
              isTyping: true,
            },
            "twilioChat"
          );
        });

        conversation.on("typingEnded", (participant) => {
          messageService.sendMessage(
            "typingEnded",
            { isTyping: false },
            "twilioChat"
          );
        });
      });
      chatClient.on("tokenAboutToExpire", async () => {
        await axios({
          method: "GET",
          url: `${twilioUrl}/chat/token?identity=${encodeURIComponent(
            spd_user_id
          )}`,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          chatClient.updateToken(response?.data?.token); // set the token
        })
      })
      chatClient.on("tokenExpired", async () => {
        messageService.sendMessage("removeChatToken", "", "chat_side_modal");
      })
      //listen to user reachability status updates   
      chatClient.on("userUpdated", ({ user, updateReasons }) => {
        if (updateReasons.includes("reachabilityOnline")) {
          if (user?.isOnline) {
            setActiveUsers(prev => [...prev, user])
          }
          if (!user?.isOnline) {
            setActiveUsers(prev => prev?.filter(u => u?.identity !== user?.identity))
          }
        }
      });
      chatClient.on("conversationJoined", (conversation) => {
        setState((prev) => ({
          ...prev,
          clientLoaded: false,
          loadingMessage: ""
        }))
        mConversationListFlag=true;
        setState((prev) => ({
          ...prev,
          conversations: [...prev.conversations, conversation],
        }));
      });
      chatClient.on("conversationLeft", (thisConversation) => {
        messageService.sendMessage("conversationLeft", true, "chat_side_modal");
        setState((prev) => ({
          ...prev,
          conversations: [
            ...prev.conversations.filter((it) => it !== thisConversation),
          ],
        }));
      });
    }
    catch (err) {
      console.log("error", err?.message);
    }

    /************************** CONVERSATION  EVENTS END *******************************/

    return () => {
      chatClient?.removeAllListeners();
      chatClient?.shutdown()
      setClient("")
    };
  }, []);
  const { conversations, selectedConversationSid, status } = state;

  /************************** SELECTED CONVERSATION START *******************************/
  let selectedConversation = conversations?.find(
    (it) => it?.sid === selectedConversationSid
  );
  /************************** SELECTED CONVERSATION END *******************************/

  /************************** UNIQUE CONVERSATION START *******************************/

  const customId = customChannel?.map(c => c?.sid)
  // while unmounting the component reset the global varibales
  useEffect(() => { 
    // // return unsubscribe method to execute when component unmounts
    return () => {
       chatClient = null
       spd_user_id = ""
       mCount=0;
       mConversationListFlag=false;    
       mLeaveFlag=true;  
    }
  }, []);
  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((m) => {
      if (m.senderId === "Conversation_left" && m.target === "TwilioConv") {
        mConversationListFlag=false
        mLeaveFlag=false;
        setUnjoinedGroup({
                  id: '',
                  processing: false
                })
      }
      if (m.senderId === "Conversation_leave" && m.target === "TwilioConv") {
        mLeaveFlag=false;
      }
      if (m.senderId === "Chat_closed" && m.target === "TwilioConv") {
        mLeaveFlag=true;
      }
    });
    // // return unsubscribe method to execute when component unmounts
    return () => {
      if (subscription != null && subscription != undefined) {
        subscription.unsubscribe();
      }
    }
  }, []);
  useEffect(() => {
    // conversations?.forEach(async (v) => await v?.delete())
    const val = [...new Map(conversations.map((item) => [item["sid"], item])).values()]?.filter(v => v !== "")
    if(val.length === 0){
      setState((prev) => ({
        ...prev,
        clientLoaded: false,
        loadingMessage: "Please wait while we are connecting..."
      }))
    }
    const valIds = val?.map(s => (s?.attributes?.cg_id && s?.createdBy === spd_user_id) && s?.attributes?.cg_id)
    // const updatedList = customChannel?.filter((item) => !valIds?.includes(item?.attributes?.cg_id)).concat(val)
    const updatedList = customChannel?.filter((item) => !valIds?.includes(item?.attributes?.cg_id));
    // incase if we are only getting unjoined group no other conversation then auto join the conversation
    if(Object.keys(updatedList).length>0){
      if(unjoinedGroup.id !=updatedList[0].sid && state.status==='success'&& showChat){
        if(mLeaveFlag){
          setUnjoinedGroup({
            id: updatedList[0].sid,
            processing: true
          })
          // function will auto join the unjoined group 
          setTimeout(() => {
             if(mConversationListFlag===false){
              setState((prev) => ({
                ...prev,
                clientLoaded: false,
                loadingMessage: "Please wait while we are connecting..."
              }))
              autoJoinGroup(updatedList[0]);
             }
          }, 3000)
          if(mConversationListFlag===false){
            setState((prev) => ({
              ...prev,
              clientLoaded: false,
              loadingMessage: "Please wait while we are connecting..."
            }))
          }
        }else{
          setState((prev) => ({
            ...prev,
            clientLoaded: false,
            loadingMessage: "Please wait while we are connecting..."
          }))
           // this will update the new conversation list on screen
           setNewConversation(val);
        }
      }else{
            setState((prev) => ({
              ...prev,
              clientLoaded: false,
              loadingMessage: "Please wait while we are connecting..."
            }))
            // now create logic in which if we are getting one conversation then auto open that conversation
            if(mLeaveFlag){
              if(val.length===1){
                setTimeout(() => {
                  setState((prev) => ({
                    ...prev,
                    singleConversation: true,
                    selectedConversationSid: val[0].sid,
                  }));
                }, 300);
              }
            }
            // this will update the new conversation list on screen
            setNewConversation(val);
      }
    }else{
          setState((prev) => ({
            ...prev,
            clientLoaded: false,
            loadingMessage: "Please wait while we are connecting..."
          }))
          // now create logic in which if we are getting one conversation then auto open that conversation
          if(mLeaveFlag){
            if(val.length===1){
              setTimeout(() => {
                setState((prev) => ({
                  ...prev,
                  singleConversation: true,
                  selectedConversationSid: val[0].sid,
                }));
              }, 300);
            }
          }
          // this will update the new conversation list on screen
          setNewConversation(val);
    }
  }, [conversations, customChannel,unjoinedGroup,state.status,updateConversation,showChat])

  // function will auto join user to support group
  const autoJoinGroup = async (val) => {
    try {
      setTotalUnreadCount(0);
      const conversation = await client?.createConversation({
        friendlyName: `${val?.friendlyName} - ${name}`
      })
      await conversation?.join();
      await conversation?.getParticipants();
      const attributes = {
        ...val?.attributes,
        friendlyName: `${val?.attributes?.contact_group_name} - ${name}`,
        userIds: [{ usr_id: spd_user_id, usr_name: name }, ...val?.attributes?.usr_ids],
        joined_user_id: [spd_user_id],
      };
      delete attributes.usr_ids
      await conversation?.updateAttributes(attributes);
      const includingMe = val?.attributes?.urs_ids;
      await includingMe?.forEach(async (l) => {
        await conversation?._participants?.forEach(async (val) => {
          if (val?.identity === l?.usr_id) {
            await axios({
              method: "POST",
              data: {
                partiSid: val?.sid,
                convoSid: conversation?.sid,
                attributes: {
                  userID: l?.usr_id,
                  userName: l?.usr_name,
                },
              },
              url: `${twilioUrl}/chat/updateParti`,
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        })
      })
      var availableUsersId = await axios({
        method: "GET",
        url: `${twilioUrl}/chat/users`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      setAvailableUsers(availableUsersId?.data?.users);
      availableUsersId= availableUsersId?.data?.users;
      availableUsersId=availableUsersId?.map(m => m.identity);
      const cg_ids = val?.attributes?.usr_ids?.map(n => n?.usr_id)
      const notAvalIDs = cg_ids?.filter(v => !availableUsersId?.includes(v))
      // check if user is guest user or not
      let conv_name=attributes.friendlyName;
      try {
        if(isGuest){
          conv_name=attributes.friendlyName +" | "+guestData.email;
        }else{
          conv_name=attributes.friendlyName;
        }
      } catch (error) {
        console.log('Error',error);
      }
      // call API to send email to admin users for queue request
      CallAPI(spd_processId_config?.sgchat_save_trn_chat_request, {
          p_chat_request_title: conv_name,
          p_cg_id:attributes.cg_id
         })
      if (notAvalIDs?.length > 0) {
        generateToken(notAvalIDs, cg_ids, conversation)
      }
      else {
        await val?.attributes?.usr_ids?.forEach(async (user) => {
          if (user?.usr_id !== spd_user_id) {
            await conversation.add(user?.usr_id)
          }
        })
        setupdateConversation(mCount++);
         // this will take user inside newly added conversation 
        setTimeout(() => {
          setState((prev) => ({
            ...prev,
            singleConversation: true,
            selectedConversationSid: conversation?.sid,
          }));
        }, 300);
      }
    } catch (error) { 
       console.log('Error',error);
    }
  }
  // This function will add new users in conversation and start the chat
  const addNewUser = async (cg_ids, conval) => {
    try {
      var availableUsersId = await axios({
        method: "GET",
        url: `${twilioUrl}/chat/users`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      setAvailableUsers(availableUsersId?.data?.users);
      await conval.getParticipants()
      await conval?.attributes?.userIds?.forEach(async (user) => {
        if (user?.usr_id !== spd_user_id) {
          await conval.add(user?.usr_id)
        }
      })
      setupdateConversation(mCount++);
      // this will take user inside newly added conversation 
      setTimeout(() => {
      setState((prev) => ({
        ...prev,
        singleConversation: true,
        selectedConversationSid: conval?.sid,
      }));
    }, 300);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };
// this function will basically create new user on twilio update the information
  const generateToken = async (val, cg_ids, conv) => {
    // CREATING TOKEN FOR THE SELECTED USER TO ACTIVATE ACCOUNT
    await Promise.all(val?.map(async (v) => {
      const data = await axios({
        method: "GET",
        url: `${twilioUrl}/chat/token?identity=${encodeURIComponent(
          v
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
      new Client(data?.data?.token)
      return data
    })
    ).then(res => {
      if (res?.length > 0) {
        setTimeout(() => {
          addNewUser(cg_ids, conv)
        }, 2000)
      }
    })

  }
  // const customIds = newConversation?.map(i => i?.attributes?.cg_id)
  // custom = custom?.filter(v => ![...new Set(customIds?.map(i => i))]?.includes(v?.cg_id))
  /************************** UNIQUE CONVERSATION END *******************************/
  try {
    return (
      <div className="conversation-container">
        {!selectedConversationSid ? (
          <div className="conversation-left">
            <SearchUser
              searchToAdd={searchToAdd}
              searchLoading={loading}
              searchActive={searchActive}
              setSearchToAdd={setSearchToAdd}
            />
            {!state?.clientLoaded ?
              <ConversationList
                chatServiceSid={decoded?.grants?.chat?.service_sid}
                activeUsers={activeUsers}
                setQueueCount={setQueueCount}
                conversations={newConversation}
                availableUsers={availableUsers}
                id={id}
                name={name}
                userType={userType}
                messageService={messageService}
                twilioUrl={twilioUrl}
                CallAPI={CallAPI}
                setTabs={setTabs}
                tabs={tabs}
                setHideTabs={setHideTabs}
                setNewConversation={setNewConversation}
                setTotalUnreadCount={setTotalUnreadCount}
                setSearchToAdd={setSearchToAdd}
                client={client}
                state={state}
                customId={customId}
                setAvailableUsers={setAvailableUsers}
                searchToAdd={searchToAdd}
                setSearchLoading={setLoading}
                clientLoading={state?.status}
                searchLoading={loading}
                searchActive={searchActive}
                selectedConversationSid={selectedConversationSid}
                setState={setState}
                onConversationClick={(item) => {
                  setState((prev) => ({
                    ...prev,
                    selectedConversationSid: item,
                  }));
                }}
              />
              :
              <div className="ui-flex ui-horizontal-align-center">
                <span
                  className="spinner-border spinner-border-sm loadingSpinner marginTop-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>}

          </div>
        ) : (
          <div className="conversation-right">
            {selectedConversation !== undefined && (
              <Conversation
                id={id}
                name={name}
                CallAPI={CallAPI}
                userType={userType}
                singleConversation={state?.singleConversation}
                twilioUrl={twilioUrl}
                messageService={messageService}
                chatServiceSid={decoded?.grants?.chat?.service_sid}
                setTabs={setTabs}
                activeUsers={activeUsers}
                unjoinedGroups={unjoinedGroups}
                availableUsers={availableUsers}
                setHideTabs={setHideTabs}
                setSearchActive={setSearchActive}
                otherConversations={newConversation?.filter(v => v !== "")?.filter(c => c?.sid !== selectedConversation?.sid)}
                conversationProxy={selectedConversation}
                myIdentity={state?.name}
                setChatState={setState}
                isGuest={isGuest}
                guestData={guestData}
                onBack={(item) => {
                  setState((prev) => ({
                    ...prev,
                    conversations: [...prev.conversations, item],
                    selectedConversationSid: item,
                  }));
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  } catch (err) {
    console.log("Error: ", err?.message);
  }
};

export default React.memo(TwilioConversation);
