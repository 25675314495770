import React from "react";
import SlideDrawer from "./SlideDrawer/SlideDrawer.js";
import Backdrop from "./SlideDrawer/Backdrop.js";

const Drawer = ({
  drawerOpen,
  setDrawerOpen,
  rxDetails,
  headingName,
  name,
  userInfo,
  setUserInfo,
  profilePic,
  setProfilePic,
  rxFileContents,
  setRxFileContents,
  myRxContext,
  setMyRxContext,
  productdetails,
  setProductDetails,
  orderNumber
}) => {
  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop setDrawerOpen={setDrawerOpen} />;
  }
  return (
    <div>
      <SlideDrawer
        show={drawerOpen}
        rxDetails={rxDetails}
        name={name}
        setDrawerOpen={setDrawerOpen}
        headingName={headingName}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        rxFileContents={rxFileContents}
        setRxFileContents={setRxFileContents}
        myRxContext={myRxContext}
        setMyRxContext={setMyRxContext}
        productdetails={productdetails}
        setProductDetails={setProductDetails}
        orderNumber = {orderNumber}
      />
      {backdrop}
    </div>
  );
};

export default Drawer;
