import React, { useContext } from "react";
import Button from "../UI/Button";
import Styles from "./index.module.css";
import Heading from "../UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../config/label";
import Cart from "../../assets/png/Cart.png";
import { CancelOrder } from "../../utils/appIcons";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";

export type ModalProps = {
  img?: any;
  title?: string;
  setModalOpen?: any;
  orderDetails?: any;
  setDrawerOpen?: any;
  getOrderList?: any;
};

const OrderCancelPopup: React.FC<ModalProps> = (props) => {
  const {
    img,
    title,
    setModalOpen,
    orderDetails,
    setDrawerOpen,
    getOrderList,
  } = props;
  const [suggestUsInit] = useContext(APIContext);
  let navigate = useNavigate();

  const handleCancleOrder = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.cancel_order_process_id, {
        p_ord_id: orderDetails?.order_id,
        p_ord_status: "cancelled",
      }).then((res) => {
        if (res?.returnCode) {
          setDrawerOpen(false);
          setModalOpen(false);
          getOrderList();
        }
      });
    }
  };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            {/* <img src={CancelOrder} alt="Cart icon" draggable="false" /> */}
            <CancelOrder
              size="56"
              style={{ marginBottom: "10px", margin: "auto" }}
            />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            Are you sure that you want to cancel the order!
          </Heading>

          {/* <p>
            {subHeading}
            {orderNumber}
          </p> */}
        </div>
        <div className={Styles["buttons"]}>
          <Button className={`${Styles?.order_cancel_btn}`}
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={() => setModalOpen(false)}
          >
            No
          </Button>

          <Button
            type={Config?.lbl_Btn_type_primary}
            size={Config?.lbl_Btn_size_medium}
            onClick={() => handleCancleOrder()}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OrderCancelPopup;
