export const msg_config = {
  /*** Using Globally for App Forms */
  first_name_required: "Enter first name",
  last_name_required: "Enter last name",
  address_required: "Enter address",
  zip_code_required: "Enter postal code",
  phone_required: "Enter phone number",
  phone_valid: "Enter valid phone number",
  email_valid: "Enter a valid email address",
  email_required: "Enter email address",
  country_required: "Select country",
  province_required: "Select province",
  city_required: "Select city",
  gender_required: "Select gender",
  DOB_required: "Select date of birth",
  state_required: "Select state",

  Msg_required: "Enter some text here",

  /*** Using for login form only */
  login_phone_required: "Enter phone number.",

  /*** Using for password form only */
  psw_phone_required: "Enter your password.",

  /*** Using for OTP form only */
  OTP_required: "Enter verification code",

  /*** Using for Create Password form only */
  psw_required: "Enter new password",
  crm_psw_required: "Please confirm your entered password",

  /*** Using for Forgot Password form only */
  forgot_required: "You must enter your phone number.",

  /*** Using for Change Password form only */
  old_psw_required: "Enter old password",
  new_psw_required: "Enter new password",
  confirm_psw_required: "Passwords don't match",

  /*** Using for Transfer Rx form only */
  trnRx_select_required: "You must select pharmacy.",

  /*** Using for New Rx form only */
  newRx_select_required: "You must select practitioner.",

  /*** Using for Transfer Rx Add Pharmacy form only */
  trnRx_pharmacy_required: "Please enter legal company name.",
  trnRx_firstName_required: "Please enter First name",
  trnRx_lastName_required: "Please enter Last name",
  trnRx_email_required: "Please enter Email",
  trnRx_alternateEmail_required: "Please enter Alternate email",
  trnRx_pharmacy_company_size_required: "Please enter company size",
  trnRx_location_required: "Enter pharmacy location.",
  trnRx_contact_required: "Enter pharmacy contact number",
  trnRx_pharmacy_postal_code_required: "Please enter postal code",
  trnRx_pharmacy_GSTIN_required:
    "Please enter a valid 15 character GST identification number",
  /*** Using for new Rx Add Pharmacy form only */
  newRx_contact_required: "Enter practitioner contact number",
  newRx_pharmacy_required: "Enter practitioner name.",
  newRx_location_required: "Enter practitioner location.",
  // trnRx_contact_required: "You must enter pharmacy Contact number",

  rx_other_first_name_required: "You must enter patient's first name.",
  rx_other_last_name_required: "You must enter patient's last name.",
  rx_other_gender_required: "You must select patient's gender.",
  rx_other_DOB_required: "You must select patient's DOB.",

  new_transfer_rx_msg_dob: "You must select your D.O.B",

  /*** Using for Health Card form only */
  health_card_required: "You must enter your health card details",
  issue_date_required: "You must enter issue date",
  expiry_date_required: "You must enter expiry date",

  /*** Using for Add insurance form only */
  insurance_name_required: "You must select insurance name",
  insurance_startdate_required: "You must select insurance start date",
  insurance_enddate_required: "You must select insurance end date",
  insurance_number_required: "You must enter insurance number",
  insurance_coverage_required: "You must select insurance coverage",
  health_card_alert: "Loading",
  health_card_number: "Health Card No.",
  health_card_Idate: "Issue Date",
  health_card_Edate: "Expiry Date",

  // Change password page
  alert_success_change: "Password changed Sucessfully",
  alert_warning_confirm: "New & Confirm passwords do not match!",

  // About Us page
  abt_us_header: "ABOUT US",
  abt_us_service: "Serving the community for the past 5 years",
  abt_us_abt:
    "We are dedicated to providing the best care possible to all of our patients. Each and every one of our pharmacy team members is here to serve you to get you back to a healthy life.",
  abt_us_det_head_1: "Who We Are",
  abt_us_det_body_1:
    "Your pharmacy team that is committed to being your long-term healthcare provider.",
  abt_us_det_head_2: "What We Do",
  abt_us_det_body_2:
    "In addition to providing your medications, we offer a variety of professional services to help create a customized care plan to coordinate your health needs",
  abt_us_det_head_3: "Our Mission",
  abt_us_det_body_3:
    "To provide the best care possible in order to help you achieve your health goals.",

  // Contact Page
  cnt_page_header: "CONTACT US",
  cnt_page_det: "Get in touch",
  cnt_page_mail: "care@safepharmacy.in",
  cnt_page_cnt_1: "Tel: +91 9041888515", //"Tel: +91 9809898020", //"(778) 394-3784",
  cnt_page_cnt_2: "(778) 395-3784",
  cnt_subject: "Safe Pharmacy",

  // Google Login Page
  ggl_login: "Login with Google",
  ggl_reg: "Register with Google",
  ggl_login_qry: "Don't have an account? ",
  ggl_reg_qry: "Already have an account? ",
  ggl_login_qry_1: " now.",

  // Health Info Page
  health_info_plc_1: "Allergies and Medication",
  health_info_plc_2: "(e.g. Vitamin A, etc.)",

  // Landing Page
  landing_page_shop: "Shop",

  // MyRxNew Page
  myrxnew_no_show: "No prescriptions to show",
  myrxnew_show: "Prescriptions will be shown here",
  myrxnew_recom: "Recommended Products",

  // Login Page
  loginpage_password_not_match: "Password did't match. Try again.",
  loginpage_password_valid: "Please enter valid password!",
  loginpage_otp_sent_success: "OTP sent successfully!",
  loginpage_otp_sent_fail: "OTP was not sent!",
  loginpage_otp_confirm_success: "OTP confirmed successfully!",
  loginpage_otp_confirm_fail: "Incorrect verification code!",
  loginpage_window_error: "Window was closed when clicked outside !",

  //document upload
  msg_select_document_type: "Please select document type",
  msg_account_is_in_verification: "Your account is now in verification.",
  msg_please_accept_terms_and_conditions: "Please accept terms & conditions",
  // Profile submitted for approval
  msg_profile_submitted_for_approval: "Profile submitted for approval",
  msg_please_select_payment_gateway: "Please select a payment gateway!",

  /*** Using for Add address Modalpopup form only */
  addaddress_housenumber: "Enter your address",

  document_uploaded: "Document uploaded successfully",
  msg_address_updated_successfully: "Address updated successfully!",
};
