import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar/index";
import { Location, PenFill, TrashIcon } from "../../utils/appIcons";
import Button from "../../components/UI/Button";
import AddAddress from "./AddAddress";
import APIContext from "../../store/api-context";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import PopConfirm from "../../components/UI/PopConfirm";
import Editaddress_icon from "../../assets/icons/Editaddress_icon.svg";
import Delete_icon from "../../assets/icons/Delete_icon.svg";
import noshow from "../../assets/svg/noshow.svg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import IconButton from "../../components/UI/IconButton";
import Config from "../../config/label";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../utils/utils";
import { color_config } from "../../config/colorConfig";
import AddNewAddress from "./AddNewAddress";
import { USER_PROFILE } from "../../config/Config";

const MyAddress = () => {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [addressListContainer, setAddressListContainer] = useState<any>([]);
  const [modalType, setModalType] = useState("Add");
  const [modalOpen, setModalOpen] = useState(false);
  const [editAddressProfile, setEditAddressProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const apiContext = useContext(APIContext);
  const [suggestUsInit] = apiContext;

  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);

  const getAddress = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.patient_address_list_process_id, {
        p_pnt_id: userProfileJson?.patient_id,
      }).then((res) => {
        if (res?.returnCode) {
          setAddressListContainer(res?.returnData);
        }
        setIsLoading(false);
      });
    }
  };

  const onDeleteHandler = (id: any) => {
    //setIsConfirmPopup(false);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.patient_address_delete_process_id, {
        p_patadd_id: id,
      }).then((res) => {
        if (res?.returnCode) {
          setAddressListContainer(res?.returnData);
          getAddress();
        }
        // window.location.reload();
      });
    }
  };

  /*   const onDeleteHandler = (id: any, setIsConfirmPopup: any) => {
      setIsConfirmPopup(false);
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.patient_address_delete_process_id, {
          p_patadd_id: id,
        }).then((res) => {
          if (res?.returnCode) {
            setAddressListContainer(res?.returnData);
            getAddress();
          }
          // window.location.reload();
        });
      }
    }; */

  useEffect(() => {
    getAddress();
  }, [suggestUsInit]);

  return (
    <>
      <div
        className={`${styles?.mainContainer}`}
        onClick={(e) => {
          setModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="outer_container">
          <div className={`${styles?.grid}`}>
            <div className={`${styles?.gridLeftItem}`}>
              <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
            <div className={`${styles?.gridRightItem}`}>
              <div className={`${styles?.headerContainer}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Location
                    width="25"
                    height="25"
                    style={{ marginTop: "0px", color: "#32C5F4" }}
                  />
                  <h2 className={`${styles?.heading}`}>
                    {Config?.myaddress_my_address}
                  </h2>
                </div>
                {/* 
              <Button
                className={`${styles?.customAddButton}`}
                onClick={() => {
                  // setIsAddAddressModalOpen(true);
                  // setIsAddressModalOpen(true);
                  // setModalType("Add");
                }}
              >
                {" "}
                <span className={`${styles?.addToolTip}`}>
                  {" "}
                  Add New Address
                </span>{" "}
                +
              </Button> */}
              </div>

              {isLoading ? (
                <>
                  <br />
                  <br />
                  <br />
                  <ShimmerSimpleGallery row={2} col={3} imageHeight={150} />
                </>
              ) : (
                <>
                  <AddNewAddress
                    // modal_deliveryaddress_rowContainer = "addnewaddressmanage"
                    containerClassBackGroundClass="modal_deliveryaddress_Background_profile"
                    mainContainerClass="modal_deliveryaddress_Container_profile"
                    rowContainerClass="modal_deliveryaddress_rowContainer"
                    showCancelButton={false}
                    // cancelbuttonClass ="modalpopup_cancelbutton"
                    isAddressPage={true}

                  />

                  {addressListContainer?.length === 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        marginTop: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        paddingTop: "100px",
                      }}
                      className={styles['nodatafound']}
                    >
                      <div className={styles["checkIconWrapper"]}>
                        <img
                          src={noshow}
                          alt="Empty Cart icon"
                          draggable="false"
                        />
                      </div>
                      <b>{Config?.myaddress_no_address}</b>
                    </div>
                  )}
                  <div className={`${styles?.addressGrid}`}>
                    {addressListContainer?.length > 0 &&
                      addressListContainer?.map((item: any) => (
                        <div
                          className={
                            item?.default_address_flag === "Y"
                              ? `${styles?.defaultAddressRow}`
                              : `${styles?.addressRow}`
                          }
                        >
                          <div className={`${styles?.clientBasicDetailsHeader}`}>
                            <p className={`${styles?.clientName}`}>
                              {item?.patadddet_pnt_name}{" "}
                              {item?.patadddet_pnt_last_name}
                            </p>
                            <div className={`${styles?.actionButtons}`}>
                              {/*<PenFill
                            width="16"
                            height="16"
                            style={{ color: "#9BA8BB" }}
                            handleOnClick={() => {
                              setIsAddressModalOpen(true);
                              setModalType("Edit");
                              setEditAddressProfile(
                                addressListContainer?.filter(
                                  (val: any) =>
                                    val?.p_patadd_id === item?.p_patadd_id
                                )
                              );
                              // onUpdateHandler([...addressList])
                            }}
                            className={`${styles?.penFill}`}
                          />*/}
                              <IconButton
                                title="Edit Address"
                                className={`${styles?.penFill}`}
                                onClick={() => {
                                  setIsAddressModalOpen(true);
                                  setModalType("Edit");
                                  setEditAddressProfile(
                                    addressListContainer?.filter(
                                      (val: any) =>
                                        val?.p_patadd_id === item?.p_patadd_id
                                    )
                                  );
                                  // onUpdateHandler([...addressList])
                                }}
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                              </IconButton>
                              <IconButton
                                title="Delete Address"
                                className={`${styles?.penFill} ${styles?.penFill_delete}`}
                                onClick={() => { onDeleteHandler(item?.p_patadd_id) }}
                              >
                                {/* <img
                                src={Delete_icon}
                                alt="Delete btn"
                                draggable="false"
                              /> */}
                                <i className="fa-solid fa-trash-can"></i>
                              </IconButton>
                              {/* <PopConfirm
                              title="Are you sure ?"
                              onConfirm={(setIsConfirmPopup: any) =>
                                onDeleteHandler(
                                  item?.p_patadd_id,
                                  setIsConfirmPopup
                                )
                              }
                            >
                              <IconButton
                                title="Delete Address"
                                className={`${styles?.penFill}`}
                              >
                                <img
                                  src={Delete_icon}
                                  alt="Delete btn"
                                  draggable="false"
                                />
                              </IconButton>
                            </PopConfirm> */}
                            </div>
                          </div>
                          <p className={`${styles?.clientBasicDetails}`}>
                            {item?.p_address}
                            {", "} {item?.p_street} {item?.city_desc}
                            {", "}
                            {item?.state_desc}
                            {", "} {item?.coutry_desc}
                            {", "} {item?.p_pcode}
                            {"."}
                          </p>

                          <p className={`${styles?.clientBasicDetailsPhone}`}>
                            {item?.patadddet_pnt_phone}
                          </p>
                        </div>
                      ))}
                  </div>

                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {isAddressModalOpen && (
        <AddAddress
          setIsAddressModalOpen={setIsAddressModalOpen}
          heading={"Add New Address"}
          modalType={modalType}
          editHeading={"Edit Address"}
          getAddress={getAddress}
          getDefaultAddress={""}
          setModalOpen={""}
          editAddressProfile={
            editAddressProfile !== undefined ? editAddressProfile : ""
          }
        />
      )}
    </>
  );
};

export default MyAddress;
