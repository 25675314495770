import React from "react";
import s from "./index.module.css";
import EditProfile from "../../../../assets/png/EditProfile_new.svg";

const IconUpload = (props: any) => {
  const { onFileUploadHandler, loading, icon } = props;
  return (
    <label className={`${s?.uploadContainer}`}>
      {loading ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        <img
          src={icon || EditProfile}
          alt="Plus sign icon button"
          draggable="false"
        />
      )}

      <input
        name={props?.name}
        id={props?.name}
        type="file"
        onChange={onFileUploadHandler}
        multiple={props.multiple}
        accept="image/*"
      />
    </label>
  );
};

export default IconUpload;
