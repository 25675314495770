import { useState } from "react";
import styles from "./buttonStyles.module.css";
import config from "../../../config/button";

export type ButtonProps = {
  title?: string;
  style?: object;
  onClick?: any;
  type?: string;
  htmlType?: any;
  size?: string;
  className?: string;
};

const Button: React.FC<ButtonProps> = (props) => {
  const [onHoverStyle, setonHoverStyle] = useState({});

  const getBtnSize = () => {
    switch (props?.size) {
      case "icon":
        return styles?.icon;

      case "large":
        return styles?.large;

      case "medium":
        return styles?.medium;

      case "small":
        return styles?.small;
      default:
        return "";
    }
  };

  const getBtnType = () => {
    switch (props?.type) {
      case "primary":
        return styles?.primary;

      case "ghost":
        return styles?.ghost;
      
      default:
        return;
    }
  };

  const getHoverLeaveStyle = () => {
    setonHoverStyle({
      opacity: "1",
    });
  };

  const getHoverEnterStyle = () => {
    setonHoverStyle({
      opacity: "0.9",
    });
  };

  return (
    <button
      onMouseEnter={getHoverEnterStyle}
      onMouseLeave={getHoverLeaveStyle}
      className={`${styles?._btnStyle} ${getBtnSize()} ${getBtnType()} ${
        props?.className
      }`}
      style={{
        ...props?.style,
        ...onHoverStyle,
      }}
      onClick={props.onClick}
      type={props?.htmlType}
    >
      {props?.children}
      {props?.title}
    </button>
  );
};

export default Button;
