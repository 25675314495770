import product1 from "../assets/products/svg/product1.svg";
import product2 from "../assets/products/svg/product2.svg";
import product3 from "../assets/products/svg/product3.svg";
import { siteConfig } from "./site";

var Config = {
  // ******************************************
  // Configuration Settings for the Sign Up page
  // *******************************************

  // Stepper Heading text
  // **************************************
  signUp_stepper_Heading__1: "Your details",
  signUp_stepper_Heading__2: "Location",
  signUp_stepper_Heading__3: "Insurance details",
  signUp_stepper_Heading__4: "Payment",
  signUp_stepper_Heading__5: "Consent",

  // Sign Up Stepper detail text
  // **************************************
  signUp_stepper_detail__1: "Please provide your name, email & password",
  signUp_stepper_detail__2: "Please provide your location",
  signUp_stepper_detail__3: "Please provide insurance & health records",
  signUp_stepper_detail__4: "Enter your payment method",
  signUp_stepper_detail__5: "Enter the consent form",

  // Heading text for create account Screen
  // **************************************
  heading_Create_Account: "Create Account",
  heading_create_company_account: "Create your Safe Pharmacy Company profile",

  // Input Labels for create account Screen
  // **************************************
  lbl_First_N: "First name",
  lbl_Last_N: "Last name",
  lbl_Email: "Email",
  lbl_Phone: "Phone no.",
  lbl_Msg: "Message",
  lbl_Psw: "Password",
  lbl_Cfrm_Pswd: "Confirm Password",
  lbl_BirthDay: "Birth Date",
  lbl_Gender: "gender",
  lbl_Postal_C: "Postal Code",
  lbl_Alternate_Email: "Alternate Email",
  lbl_Alternate_Phone: "Alternate No.",
  lbl_Company_Size: "Company Size",
  lbl_GSTIN: "GSTIN",
  lbl_F1: "Field 1",
  lbl_F2: "Field 2",

  // Input Placeholder Text for create account Screen
  // ***********************************************
  inp_P_Holder_First_N: "Your first name",
  inp_P_Holder_Last_N: "Your last name",
  inp_P_Holder_Email: "Enter your email",
  inp_P_Holder_Phone: "Your phone no.",
  inp_P_Holder_Psw: "Password",
  inp_P_Holder_Cfrm_Pswd: "Confirm password",
  inp_P_Holder_Company_N: "Your Company Name",
  inp_P_Holder_Postal_code: "160055",
  inp_P_Holder_Company_Size: "20-49",
  inp_P_Holder_GSTIN: "12AAAAA000A1AZ5",

  // Heading text for OTP Screen
  // ***************************
  heading_OTP: "Awesome, Thanks!",

  // Heading text for  verification OTP Screen
  // ***************************
  verification_OTP: "Verification",

  // Input Placeholder Text for OTP Screen
  // *************************************
  inp_P_Holder_OTP: "-",

  // Text Eg:(<p> Tag Text) for OTP Screen
  // ***************************
  txt_OTP_1: "Enter the 6 digit code we sent to",
  txt_OTP_2: "to verify your number.",

  // Text Eg:(<p> Tag Text) for Verification OTP Screen
  // ***************************
  txt_verification_OTP_1: "Enter the verification code sent to",

  // Input Labels for Location Screen
  // ********************************s
  lbl_Location: "Enter your location",

  // Input Labels for Location Screen
  // **************************************
  inp_P_Holder_Location: "Your Location",

  // Heading text for Insurance Screen
  // ***************************
  heading_Location: "Insurance Details",

  // Input Labels for Insurance Screen
  // **************************************
  lbl_Comp_N: "Company name",
  lbl_Insurance: "Insurance / Health card no.",
  lbl_Date_S: "Start date",
  lbl_Date_E: "End date",
  lbl_Upload: "Upload health card",
  lbl_Coverage: "Insurance coverage",

  // Input Placeholder Text for Insurance Screen
  // ***********************************************
  inp_P_Holder_Comp_N: "Select company name",
  inp_P_Holder_Insurance: "Enter insurance / health card no",
  inp_P_Holder_Date_S: "Please select start date",
  inp_P_Holder_Date_E: "Please select end date",
  inp_P_Holder_Upload: "Upload health card",
  inp_P_Holder_Coverage: "Select insurance coverage",

  // Heading text for Consent Screen
  // ***************************
  heading_Consent: "Consent form",

  // Input Labels for Consent Screen
  // **************************************
  lbl_Name: "Full name",
  lbl_Sign: "Digital sign",

  // Input Placeholder Text for Consent Screen
  // ***********************************************
  inp_P_Holder_Name: "Enter full name",

  // Text Eg:(<p> Tag Text) for Consent Screen
  // ***************************
  txt_Consent_1:
    "A Caregiver Consent Form, prepared in advance, assures that the caregiver will be able to make medical decisions guided by health care professionals in your absence. You can create these forms without the need for a lawyer. Place prepared consent form copies next to emergency phone numbers. Review the Caregiver Consent form and emergency phone numbers frequently to keep them current.",

  // Configuration Settings for the lOGIN page
  // *******************************************
  lbl_login_txt: "Get Started",
  lbl_login_req_txt: "Please login/register to continue",
  lbl_register_txt: "Register",
  lbl_signup_txt: "Sign Up",
  lbl_cancel_txt: "Cancel",
  lbl_no_keep_txt: "No, please keep",
  lbl_delete_req_txt: "Are you sure want to delete?",
  lbl_delete_txt: "Delete",

  // Heading text for lOGIN Screen
  // **************************************
  heading_login: "Welcome!",

  // Heading create password
  // **************************************
  heading_create_password: "Create Password",

  // Google Label
  // ********************************s
  lbl_Google: "Sign in with Google",

  // Apple Label
  // ********************************s
  lbl_Apple: "Sign in with Apple",

  // Divider Label
  // ********************************s
  lbl_Divider: "Enter your phone number to continue", //"Sign In / Register with your number",

  // Divider Label
  // ********************************
  lbl_confirm: "Please confirm your password",

  // Divider Label
  // ********************************
  lbl_confirm_phone: "Please confirm your number",
  lbl_welcome_safepharmacy: "Welcome to Safe Pharmacy Business",
  lbl_company_legal_name: "Legal Company name",

  // Input Labels
  // **************************************

  lbl_Login_User: "Email address",
  lbl_Login_Psw: "Password",
  lbl_Login_number: "Phone Number",
  lbl_Chk_Box: " Keep me signed in",
  lbl_Forgot: "Forgot Password?",
  lbl_Sign_In_Btn: "Sign in",
  lbl_verify_Btn: "Verify",
  lbl_Sign_Up_Lnk: "Sign up now",

  lbl_Not_Account: "Don't have an account?",
  lbl_Not_Otp_rec: "Didn't receive verification code?",
  lbl_Resend_otp: "Resend",
  lbl_otp_confirm: "Confirm",
  lbl_back: "Back",
  lbl_Confirm_Psw: "Confirm Password",
  lbl_continue_Btn: "Continue",
  lbl_email: "johndoe@gmail.com",
  lbl_Psw_strength: "Password strength",

  // Input Placeholder Text
  // ***********************************************
  inp_P_Holder_Login_User: "Enter your email",
  inp_P_Holder_Login_Psw: "Enter your password",
  inp_P_Holder_Confirm_Psw: "Confirm your password",
  inp_P_Holder_Login_number: "Phone Number",

  // *******************************************
  // Configuration Settings for the Health Card page
  // *******************************************

  health_Card_Button: "Save",
  health_Card_Button_Upd: "Update",
  health_Card_Button_Del: "Delete",
  health_Card_title: "Health Card",
  health_Card_img_front: "Front Side",
  health_Card_img_back: "Back Side",
  health_Card_Upload_front: "Front",
  health_Card_Upload_back: "Back",

  // *******************************************
  // Configuration Settings for the Health Information page
  // *******************************************
  health_Info_Rad_1: "Yes",
  health_Info_Rad_2: "No",
  health_Info_Qry_1: "1. Do you have allergies to any medications ?",
  health_Info_Qry_2:
    "2. Enter any over the counter supplements and medications that you are taking :",
  health_Info_Data_1: "Allergies to medications",
  health_Info_Data_2: "Counter supplements and medications",
  health_Info_Save: "Save",
  health_Info_Update: "Update",
  health_Info_Edit: "Edit",

  // *******************************************
  // Configuration Settings for the Submit Rx page
  // *******************************************

  // Heading text
  // *************
  submitRx_Page_Heading: "Submit Prescription",
  submitRx_Title_1: "Upload Prescription",
  submitRx_Title_2: "New Prescription",
  submitRx_Title_3: "Transfer Prescription",

  // Detail text
  // **************************************
  submitRx_detail__1A: "Take a picture of your prescription",
  submitRx_detail__1B: "and Safe Pharmacy will do the rest.",
  submitRx_detail__2A: "Ask your doctor to send your",
  submitRx_detail__2B: "prescription straight to Safe Pharmacy.",
  submitRx_detail__3A: "Safe Pharmacy can call your pharmacy ",
  submitRx_detail__3B: "to request your prescription.",

  // ******************************************
  // Configuration Settings for Upload Rx page
  // *******************************************

  // Stepper Heading text
  // **************************************
  uploadRx_stepper_Heading__1: "Upload Prescription",
  uploadRx_stepper_Heading__2: "View prescription",
  uploadRx_stepper_Heading__3: "Consent form",
  uploadRx_stepper_Heading__4: "Verification",

  // Stepper detail text
  // **************************************

  uploadRx_stepper_detail__1: "Upload your prescription",
  uploadRx_stepper_detail__2: "Check & manage your prescription",
  uploadRx_stepper_detail__3: "Please fill you consent form",
  uploadRx_stepper_detail__4: "Prescription verification",

  // Heading text
  // *************
  uploadRx_Page_Heading: "Upload Prescription",
  myHealth_Page_Heading: "Health Information",
  myHealth_Page_Heading_1: "Health Card",
  myInsurance_Page_Heading: "Insurance",

  // Input Labels
  // **************************************
  lbl_Upload_Empty_Content: "Click add to upload prescription",

  // ******************************************
  // Configuration Settings for Upload Verification Modal
  // ******************************************

  uploadRx_Modal_Heading: "Prescription request submitted successfully!",
  uploadRx_Modal_detail__1: "Your Prescription request Id is",
  uploadRx_Modal_detail__2: "is pending",

  // ******************************************
  // Configuration Settings for Rx page TABS
  // *******************************************

  rx_Tab_Title__1: "New prescription",
  rx_Tab_Title__2: "Transfer Prescription",

  rx_Tab_url__1: "new?status=submit",
  rx_Tab_url__2: "transfer?status=submit",

  // ******************************************
  // Configuration Settings for Rx page
  // *******************************************

  //New Rx Stepper Heading text
  // **************************************

  newRx_stepper_Heading__1: "Submit Prescription",
  newRx_stepper_Heading__2: "Patient Detail",
  newRx_stepper_Heading__3: "Consent form",
  newRx_stepper_Heading__4: "Verification",

  //New Rx Stepper detail text
  // **************************************

  newRx_stepper_detail__1: "To submit please select practitioner",
  newRx_stepper_detail__2: "Please fill patient details",
  newRx_stepper_detail__3: "Please fill your consent form",
  newRx_stepper_detail__4: "Prescription verification",

  // New Rx Input Labels
  // **************************************
  newRX_Label: "Select general practitioner",

  // New Rx Select Value and Title
  // ***********************************************
  newRx_Select_Title: "Dr. Kerry Graybiel",
  newRx_Select_Value: "KERRY_G",

  // New Rx Link Name
  // **************************************
  newRX_Lnk_Title: "Add practitioner",
  newRX_Select_Placeholder: "Search by practitioner name/location",
  newRx_add_title: "Other Practitioner",
  newRx_practitioner_name_placeholder: "Enter practitioner name",

  // ******************************************
  // Configuration Settings for New Rx Verification Modal
  // ******************************************
  newRx_Modal_Heading: "generated successfully!",
  newRx_Modal_detail__1: "Your request for your",
  newRx_Modal_detail__2: "is completed",

  // New Rx Patient Details Input Labels
  // **************************************
  nRx_Lbl_1: "First Name",
  nRx_Lbl_2: "Last Name",
  nRx_Lbl_3: "Gender",
  nRx_Lbl_4: "DOB",

  // New Rx Patient Details Input Placeholder Text
  // ***********************************************
  nRx_Inp_P_Holder_1: "Your first name",
  nRx_Inp_P_Holder_2: "Your last name",
  nRx_Select_P_Holder: "Your gender",

  nRx_Select_Title_1: "Male",
  nRx_Select_Value_1: "MALE",
  nRx_Select_Title_2: "Female",
  nRx_Select_Value_2: "FEMALE",

  nRx_Date_P_Holder: "Your DOB",

  // Transfer Rx Other Patient Details Input Labels
  // **************************************
  nRx_Other_Lbl_1: "First Name",
  nRx_Other_Lbl_2: "Last Name",
  nRx_Other_Lbl_3: "Gender",
  nRx_Other_Lbl_4: "DOB",

  // Transfer Rx Other Patient Details Input Placeholder Text
  // ***********************************************
  nRx_Other_Inp_P_Holder_1: "Your first name",
  nRx_Other_Inp_P_Holder_2: "Your last name",
  nRx_Other_Select_P_Holder: "Your gender",

  nRx_Other_Select_Title_1: "Male",
  nRx_Other_Select_Value_1: "MALE",
  nRx_Other_Select_Title_2: "Female",
  nRx_Other_Select_Value_2: "FEMALE",

  nRx_Other_Date_P_Holder: "Your DOB",

  // ******************************************
  // Configuration Settings for Transfer Rx page
  // *******************************************

  //Transfer Rx Stepper Heading text
  // **************************************

  transferRx_stepper_Heading__1: "Transfer Prescription",
  transferRx_stepper_Heading__2: "Patient Detail",
  transferRx_stepper_Heading__3: "Consent form",
  transferRx_stepper_Heading__4: "Verification",

  //Transfer Rx Stepper detail text
  // **************************************

  transferRx_stepper_detail__1: "Please select pharmacy",
  transferRx_stepper_detail__2: "Please fill patient details",
  transferRx_stepper_detail__3: "Please fill your consent form",
  transferRx_stepper_detail__4: "Prescription verification",

  // Transfer Rx Input Labels
  // **************************************
  transferRx_Label: "Select Pharmacy",

  // Transfer Rx Select Value and Title
  // ***********************************************
  transferRx_Select_P_Holder: "Your gender",
  transferRx_Select_Title: "Dean Pharmacy",
  transferRx_Select_Value: "DEAN_PHARMACY",

  // Transfer Rx Link Name
  // **************************************
  transferRx_Lnk_Title: "Add Pharmacy",
  transferRx_Select_Placeholder: "Search by pharmacy name/location",
  transferRx_Add_Pharmacy_Placeholder: "Other Pharmacy",

  // ******************************************
  // Configuration Settings for New Rx Verification Modal
  // ******************************************
  transferRx_Modal_Heading: "TPrescription #CO103521 generated successfully!",
  transferRx_Modal_detail__1:
    "Your request for your prescription #CO103521 is pending",

  // Transfer Rx Patient Details Input Labels
  // **************************************
  tRx_Lbl_1: "First name",
  tRx_Lbl_2: "Last name",
  tRx_Lbl_3: "Gender",
  tRx_Lbl_4: "Date of birth",

  // Transfer Rx Patient Details Input Placeholder Text
  // ***********************************************
  tRx_Inp_P_Holder_1: "Your first name",
  tRx_Inp_P_Holder_2: "Your last name",
  tRx_Select_P_Holder: "Your gender",

  tRx_Select_Title_1: "Male",
  tRx_Select_Value_1: "MALE",
  tRx_Select_Title_2: "Female",
  tRx_Select_Value_2: "FEMALE",

  tRx_Date_P_Holder: "Your DOB",

  // Transfer Rx Other Patient Details Input Labels
  // **************************************
  tRx_Other_Lbl_1: "First Name",
  tRx_Other_Lbl_2: "Last Name",
  tRx_Other_Lbl_3: "Gender",
  tRx_Other_Lbl_4: "DOB",

  // Transfer Rx Other Patient Details Input Placeholder Text
  // ***********************************************
  tRx_Other_Inp_P_Holder_1: "Patient's first name",
  tRx_Other_Inp_P_Holder_2: "Patient's last name",
  tRx_Other_Select_P_Holder: "Patient's gender",

  tRx_Other_Select_Title_1: "Male",
  tRx_Other_Select_Value_1: "MALE",
  tRx_Other_Select_Title_2: "Female",
  tRx_Other_Select_Value_2: "FEMALE",

  tRx_Other_Date_P_Holder: "Patient's DOB",

  AddPractitioner_Modal_Heading: "Add general practitioner ",

  // Transfer Rx Add Pharmacy heading, Input Labels and Placeholder Text
  // **********************************************************
  Pharmacy_Contact_no: "Contact number",
  Pharmacy_placeholder: "Enter pharmacy name",
  Pharmacy_Contact_no_placeholder: "Enter Contact number",
  Pharmacy_location_placeholder: "Search or enter pharmacy location",
  Practitioner_location_placeholder: "Search or enter practitioner location",
  Enter_your_address: "Address",
  AddPharmacy_Modal_Heading: "Add Pharmacy",
  lbl_postal_code: "Postal Code",

  General_prac_label: "General practitioner name",

  Pharmacy_label: "Pharmacy name",

  Practitioner_location: "Location",

  Pharmacy_location_label: "Location",

  Practitioner_Contact_no: "Contact number",

  // ******************************************
  // Configuration Settings for Order Completion Modal
  // ******************************************
  orderCompletion_Modal_Heading:
    "Thank you for placing your order with Safe Pharmacy! ",
  orderCompletion_subHeading: "Your order ID is ",
  orderCompletion_Modal_detail__1:
    "You will be receiving a confirmation email with order details.",

  // ******************************************
  // Configuration Settings for Product 1
  // ******************************************

  product_1_Heading: "Salbutamol HFA Inhaler (Teva) 100mcg/dose",
  product_1_containerCount: "5 Cap",
  product_1_CountLeft: 4,
  product_1_OnFile: "Jan 12, 2022",
  product_1_Address: "Guildford Drugs, 200-15135 101st Ave",

  // *************************************************************
  // Configuration Settings for Product Filter and Sort By Select
  // *************************************************************

  Filter_Sort_Options: [
    { value: undefined, label: <div>Relevance</div> },
    { value: "LTOH", label: <div>Price Low to High</div> },
    { value: "HTOL", label: <div>Price High to Low</div> },
  ],

  // ******************************************
  // Configuration Settings for Product Card
  // ******************************************

  // product_currency: "$",
  // product_currency: "$",
  product_currency: siteConfig?.site_currency,
  per_strip: "/Strip",
  product_quantity: "Quantity:",

  products: [
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      currency: "$",
      price: "25.00",
      actualPrice: "32.00",
      rating: 3,
    },

    {
      imgUrl: product2,
      imgAlt: "Magnum After Burner",
      detail: "Magnum After Burner",
      currency: "$",
      price: "40.00",
      actualPrice: "60.00",
      rating: 5,
    },
    {
      imgUrl: product3,
      imgAlt: "EFX Sports Kre-Alkalyn EFX",
      detail: "EFX Sports Kre-Alkalyn EFX",
      currency: "$",
      price: "30.00",
      actualPrice: "55.00",
      rating: 4,
    },
    {
      imgUrl: product2,
      imgAlt: "Magnum After Burner",
      detail: "Magnum After Burner",
      currency: "$",
      price: "40.00",
      actualPrice: "60.00",
      rating: 5,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      currency: "$",
      price: "25.00",
      actualPrice: "32.00",
      rating: 3,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      currency: "$",
      price: "25.00",
      actualPrice: "32.00",
      rating: 3,
    },
    // {
    //   imgUrl: product1,
    //   imgAlt: "Carbamide Forte Biotin from Sesbania",
    //   detail: "Carbamide Forte Biotin from Sesbania",
    //   currency: "$",
    //   price: "25.00",
    //   actualPrice: "32.00",
    //   rating: 3,
    // },
  ],

  myRxProducts: [
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      onFile: "January 22,2022",
      product_heading: "Walmoxy 500mg",
      containerCount: "50 Cap",
      product_CountLeft: 4,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },

    {
      imgUrl: product2,
      imgAlt: "Magnum After Burner",
      detail: "Magnum After Burner",
      onFile: "January 24,2022",
      product_heading: "Salbutamol inhaler",
      containerCount: "1 pc",
      product_CountLeft: 2,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product3,
      imgAlt: "EFX Sports Kre-Alkalyn EFX",
      detail: "EFX Sports Kre-Alkalyn EFX",
      onFile: "January 26,2022",
      product_heading: "Atorvastatin(Apo) 10mg",
      containerCount: "90 Tab",
      product_CountLeft: 3,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product2,
      imgAlt: "Magnum After Burner",
      detail: "Magnum After Burner",
      onFile: "January 28,2022",
      product_heading: "Almopidine (Sandoz) 5mg",
      containerCount: "100 Tab",
      product_CountLeft: 6,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      onFile: "January 30,2022",
      product_heading: "Dolo (Paracetamol) 650mg",
      containerCount: "30 Tab",
      product_CountLeft: 10,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      onFile: "January 30,2022",
      product_heading: "Dolo (Paracetamol) 650mg",
      containerCount: "30 Tab",
      product_CountLeft: 10,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      onFile: "January 30,2022",
      product_heading: "Dolo (Paracetamol) 650mg",
      containerCount: "30 Tab",
      product_CountLeft: 10,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
    {
      imgUrl: product1,
      imgAlt: "Carbamide Forte Biotin from Sesbania",
      detail: "Carbamide Forte Biotin from Sesbania",
      onFile: "January 30,2022",
      product_heading: "Dolo (Paracetamol) 650mg",
      containerCount: "30 Tab",
      product_CountLeft: 10,
      product_address: "Guildford Drugs, 200-15135 101st Ave",
      addedToCart: false,
    },
  ],

  product_Filter_SideBar: [
    {
      isCollapsable: "Y",
      title: "Category",
      type: "list",
      values: [
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "1",
          ssg_code: "SSG_1",
          ssg_description: "A",
          ssg_id: "124",
          ssg_status: "A",
        },
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "1",
          ssg_code: "SSG_2",
          ssg_description: "BV",
          ssg_id: "125",
          ssg_status: "A",
        },
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "1",
          ssg_code: "SSG_3",
          ssg_description: "BVC",
          ssg_id: "126",
          ssg_status: "A",
        },
      ],
    },
    {
      isCollapsable: "Y",
      title: "Sub Category",
      type: "list",
      values: [
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "11",
          ssg_code: "SSG_1",
          ssg_description: "BB",
          ssg_id: "180",
          ssg_status: "A",
        },
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "10",
          ssg_code: "SSG_2",
          ssg_description: "Vg",
          ssg_id: "181",
          ssg_status: "A",
        },
        {
          sbg_id: "",
          sbg_img_description: "",
          sbg_img_file_name: "",
          sbg_img_name: "",
          sbg_img_status: "",
          sg_id: "9",
          ssg_code: "SSG_3",
          ssg_description: "HH",
          ssg_id: "182",
          ssg_status: "A",
        },
      ],
    },
    {
      isCollapsable: "Y",
      title: "Brands",
      type: "checkbox",
      values: [
        {
          manf_img_description: "",
          manf_img_file_name: "",
          manf_img_name: "",
          manuf_code: "MC015",
          manuf_description: "Bausch Health\r\n",
          manuf_id: "16",
          manuf_status: "A",
        },
        {
          manf_img_description: "",
          manf_img_file_name: "",
          manf_img_name: "",
          manuf_code: "MC016",
          manuf_description: "Novartis Pharmaceuticals\r\n",
          manuf_id: "17",
          manuf_status: "A",
        },
      ],
    },
    {
      isCollapsable: "Y",
      title: "SubCatBrand",
      type: "checkbox",
      values: [
        {
          manf_img_description: "",
          manf_img_file_name: "",
          manf_img_name: "",
          manuf_code: "MC015",
          manuf_description: "Bausch Health\r\n",
          manuf_id: "18",
          manuf_status: "A",
        },
        {
          manf_img_description: "",
          manf_img_file_name: "",
          manf_img_name: "",
          manuf_code: "MC016",
          manuf_description: "Novartis Pharmaceuticals\r\n",
          manuf_id: "19",
          manuf_status: "A",
        },
      ],
    },

    {
      isCollapsable: "N",
      title: "Price Range",
      type: "slider",
      values: [
        {
          max_price: 99,
          min_price: 85,
        },
      ],
    },
  ],

  // ******************************************
  // Configuration Settings for Product Category
  // ******************************************

  productCategoryList: [
    {
      title: "Bath Preparations",
      id: "0",
    },
    {
      title: "Eye Makeup Preparations",
      id: "1",
    },
    {
      title: "Hair Preparations",
      id: "2",
    },
    {
      title: "Fragrance",
      id: "3",
    },
  ],

  // ******************************************
  // Configuration Settings for Tabs of product detail page
  // ******************************************

  product_tabPane1_heading: "Description",

  product_tabPane2_heading: "Reviews",

  product_tabPane2_heading_count: "20",

  product_tabPane3_heading: "Questions",

  product_tabPane3_heading_count: "04",
  // Configuration Settings for Header
  // ******************************************

  header_searchbar_placeholder: "Search medicines / healthcare products",
  header_rightmenu_1: "Offers",
  header_rightmenu_2: "Cart",
  header_rightmenu_3: "My Prescription",
  header_rightmenu_4: "En",
  header_rightmenu_5: "Business",
  header_rightmenu_6: "Upload Rx",
  header_rightmenu_7: "My account",
  header_rightmenu_username: "John",

  // ******************************************
  // Configuration Settings for Footer
  // ******************************************

  footer_serviceline_Text:
    "Service Line helps you to turn your complex multisite, multivendor imaging environment into a uniform and balanced operation.",
  footer_linkmenu_Title: "Links",
  footer_linkmenu_Option1: "Home",
  footer_linkmenu_Option2: "About",
  footer_linkmenu_Option3: "Services",
  footer_linkmenu_Option4: "Reviews",
  footer_linkmenu_Option5: "Articles",
  footer_linkmenu_Option6: "Contact",
  footer_linkmenu_Option7: "Blog",
  footer_aboutmenu_Title: "About",
  footer_aboutmenu_Option1: "Partners",
  footer_aboutmenu_Option2: "Careers",
  footer_aboutmenu_Option3: "Press",
  footer_aboutmenu_Option4: "Community",
  footer_address_Title: "Our Office",
  footer_address_Line1: "Safe Pharmacy",
  footer_address_Line2: "SCO 6, Phulkian enclave market, Patiala", //"15135 101 Ave #200, Surrey, BC V3R 7Z1",
  footer_address_Line3: "Unit of AXRIA Ventures",
  footer_address_Line4: "Tel: +91 9041888515", //"Tel: +91 9809898020", //"Tel: (778) 394-3784",
  footer_address_Line5: "Fax: (778) 395-3784",
  footer_bottom_text1: "Privacy",
  footer_bottom_text2: "Security",
  footer_bottom_text3: "Terms",

  // Forgot password stepper Heading text
  // **************************************
  forgotPassword_stepper_Heading__1: "Email address",
  forgotPassword_stepper_Heading__1_1: "PHONE NUMBER",
  forgotPassword_stepper_Heading__2: "VERIFICATION CODE",
  forgotPassword_stepper_Heading__3: "CHANGE PASSWORD",

  // HEalth cards stepper Heading text
  // **************************************
  Edit_profile_head_text: "Profile",
  Insurance_card_head_text: "Insurance Cards",
  Health_card_head_text: "Health Card",
  Health_info_head_text: "Health Information",

  // signUp_stepper_Heading__3: "Insurance details",
  // signUp_stepper_Heading__4: "Payment",
  // signUp_stepper_Heading__5: "Consent",

  // Sign Up Stepper detail text
  // **************************************
  forgotPassword_stepper_detail__1: "Please provide your email",
  forgotPassword_stepper_detail__1_1: "Please provide your number",
  forgotPassword_stepper_detail__2: "Please enter your received OTP",
  forgotPassword_stepper_detail__3: "Enter your new password",
  // signUp_stepper_detail__4: "Enter your payment method",
  // signUp_stepper_detail__5: "Enter the consent form",

  // Health cards Stepper detail text
  // **************************************
  Edit_Profile_detail_text: "Update your profile here",
  Insurance_card_head_detail_text: "Please upload insurance cards",
  Health_card_head_detail_text: "Please upload health card",
  Health_info_head_detail_text: "Please enter health information",

  //Forgot password heading text
  // **************************************
  forgetPassword_heading_Create_Account_1: "Phone number",
  forgetPassword_heading_Create_Account: "Email",

  //Change password heading text
  // **************************************
  changePassword_heading_Create_Account: "Change your password",

  // Text Eg:(<p> Tag Text) for forgot password OTP Screen
  // ***************************
  txt_OTP_email: "Enter the 6 digit code we sent to",
  txt_OTP_number: "Enter the 6 digit code sent to your phone number",
  txt_OTP_email_confirm: "to verify your email.",
  txt_OTP_number_confirm: "to verify your number",

  // Input Labels for change password Screen
  // **************************************
  new_password: "Enter your new password",
  confirm_password: "Confirm password",

  // Input Placeholder Text for create account Screen
  // ***********************************************
  inp_P_Holder_newPassword: "Enter new password",
  inp_P_Holder_confirmPassword: " Reenter new password",

  // Dynamic informaton for my profile Screen
  // ***********************************************
  balance_ammount: "$500",
  user_type: "SILVER USER",

  // ******************************************
  // Configuration Settings for Checkout Page
  // *******************************************

  checkoutPage_Title: "Checkout",
  checkoutPage_Title_2: "Billing Details",

  // Billing Labels
  // *************************************

  checkout_billing_title_1: "Item(s)",
  checkout_billing_heading_1: "Subtotal",
  checkout_billing_title_2: "Shipping",
  checkout_billing_title_3: "Total",
  checkkout_billing_title_4: "Total Discount",
  checkkout_billing_title_5: "CGST",
  checkkout_billing_title_6: "SGST",

  // Stepper Heading text
  // **************************************
  checkout_stepper_Heading__1: "Add to cart",
  checkout_stepper_Heading__2: "Checkout",
  checkout_stepper_Heading__3: "Confirmation",

  // Sign Up Stepper detail text
  // **************************************
  checkout_stepper_detail__1: "",
  checkout_stepper_detail__2: "",
  checkout_stepper_detail__3: "",

  // ******************************************
  // Configuration Settings for Payment Page
  // *******************************************

  paymentPage_Title: "Payment",
  paymentPage_Title_2: "Billing Details",

  // Billing Labels
  // *************************************

  payment_billing_title_1: "Subtotal",
  payment_billing_title_2: "Shipping",
  payment_billing_title_3: "Total",

  // Payment Shipping and Method Labels
  // *************************************
  payment_Contact_title: "Contact",
  payment_Shipping_title: "Ship to",
  payment_Method_title: "Method",

  // Payment Card Section Labels
  // *************************************
  payment_Card_Title: "Payment Method",
  payment_Card_Radio_Btn_Title: "Add another card",

  // Stepper Heading text
  // **************************************
  payment_stepper_Heading__1: "Add to cart",
  payment_stepper_Heading__2: "payment",
  payment_stepper_Heading__3: "Confirmation",

  // Sign Up Stepper detail text
  // **************************************
  payment_stepper_detail__1: "",
  payment_stepper_detail__2: "",
  payment_stepper_detail__3: "",

  // Sign Up Stepper detail text
  // **************************************
  label_insurance: "Do you have any",
  sub_label_insurance: "insurance?",

  // Ask primary card in my insurance scrren text
  // **************************************
  label_Text1: "Are you the primary card",
  label_Text2: "holder?",

  // ******************************************
  // Configuration Settings for Landing Page
  // ******************************************

  lbl_land_page_etrck: "Landing page",
  lbl_land_page_etrck_wishlist_removed: "Items removed from wishlist",
  lbl_land_page_etrck_wishlist_added: "Items added to wishlist",
  lbl_land_page_etrck_cart: "Items added to cart",
  lbl_land_page_cancel: "Cancel",

  // Header text
  // **************************************
  header_menu_option1: "About us",
  header_menu_option2: "Categories",
  header_title_line1: "Your path to",
  header_title_line2: "personalized care",
  header_body_line1: "Providing exceptional service is our first priority.",
  header_body_line2: "Locally-owned community pharmacy.",
  header_button_text: "Submit Prescription",

  // Body text
  // **************************************
  //How it works contatiner
  header_htw_title: "How it works",
  header_htw_description: "(Three Simple Steps)",
  header_htw_card1_title: "Login",
  header_htw_card1_detail:
    "It only takes a few seconds to become a member. A couple clicks, a little typing, and you're done!",
  header_htw_card2_title: "Upload Prescription",
  header_htw_card2_detail:
    "We'll reach out to your old pharmacy to get your prescriptions and prepare your medications for next-day delivery.",
  header_htw_card3_title: "Relax",
  header_htw_card3_detail:
    "Get back to your life with the peace of mind that your medications are on the way.",

  //How it works contatiner2
  header_htw_title2: "Shop for Medicine Online with Ease",
  header_htw_description2: "A Step-by-Step Guide",
  header_htw_card1_title2: "Prescription Upload",
  header_htw_card1_detail2:
    "We make it easy for you to upload your description directly to our website. Simply take a photo or scan of your prescription and upload it during the checkout process. If you have any questions or concerns about uploading your prescription, please don't hesitate to contact our customer support team.",
  header_htw_card2_title2: "Payment & Checkout",
  header_htw_card2_detail2:
    "We accept all major credit cards, as well as Paypal. Our checkout process is secure and easy to use, and you'll receive a confirmation email as soon as your order is placed. if you have any issues with the checkout process, please contact our customer support team.",
  header_htw_card3_title2: "Customer Support",
  header_htw_card3_detail2:
    "We are here to help to help! If you have any questions or concerns about our product or services, please don't hesitate to contact us. You can reach us by phone, email, or live chat, and our support team is available 24/7 to assist you.",
  header_htw_card4_title2: "Trust & Security",
  header_htw_card4_detail2:
    "We take your security seriously. Our website is SSL encrypted, which means that all of your personal and payment information is protected from unauthorized access. We also use secure payment gateways to process your payments, so you can trust that your information is safe with us.",

  //About us contatiner
  header_au_title: "About Us",
  header_au_title2: "Pleasant service is our top priority",
  header_au_detail:
    "We always provide the best service that we can, with the best doctors. In the world, we always take care of your health for a better world and for a more peaceful life.",
  header_au_btn: "Get started",
  //our products contatiner
  header_op_title: "Our Products",

  // Input Label Config
  // ********************************
  lbl_Inp_size_small: "small",
  lbl_Inp_size_medium: "medium",
  lbl_Inp_size_large: "large",
  lbl_Inp_type_text: "text",
  lbl_Inp_type_email: "email",
  lbl_Inp_type_pass: "password",
  lbl_Inp_type_num: "number",
  // Button Labels Config
  // ********************************
  lbl_Btn_type_primary: "primary",
  lbl_Btn_type_ghost: "ghost",
  lbl_Btn_size_small: "small",
  lbl_Btn_size_medium: "medium",
  lbl_Btn_size_large: "large",
  lbl_Btn_action_submit: "Submit",
  lbl_Btn_action_btn: "button",
  // Dashboard Label Config
  // *******************************
  lbl_dashboard_login: "Logged in as",
  lbl_dashboard_logout: "Logout",
  // Change Password Screen labels
  // ****************************************
  lbl_old_password: "Old password",
  inp_old_password: "oldPassword",
  lbl_new_password: "New password",
  inp_new_password: "newPassword",
  lbl_confirm_password: "Confirm password",
  inp_confirm_password: "confirmPassword",
  //Payment modal Info label
  payment_modal_info:
    "Enter your card information.We'll save this card so you can use it again later",

  // Add Address Input Placeholders Text
  //*********************************************** */

  address_placeholder_1: "First Name",
  address_placeholder_2: "Last Name",
  address_placeholder_3: "Address",
  address_placeholder_4: "Country",
  address_placeholder_5: "Province",
  address_placeholder_6: "City",
  address_placeholder_7: "Postal Code",
  address_placeholder_8: "Phone Number",
  address_placeholder_9: "Email Address",
  address_placeholder_10: "Enter Some Text",

  // Stepper Heading text
  // **************************************
  rxRequestList_stepper_Heading__1: "New prescription",
  rxRequestList_stepper_Heading__2: "Assigned",
  rxRequestList_stepper_Heading__3: "Awaiting",
  rxRequestList_stepper_Heading__4: "Received",
  rxRequestList_stepper_Heading__5: "Completed",

  /** Config for checking usr email+name+dob+phone and showing profile popup */
  // Manage Payment Page Labels
  // *****************************************
  manpay_gateway_type: "square_up",
  manpay_env: "TELE",
  manpay_heading: "Manage Payments",
  manpay_addcard: "Add New Card",
  manpay_nocard: " No card details found",

  // My Address Page Labels
  // *****************************************
  myaddress_add_address: "Add New Address",
  myaddress_my_address: "Manage Address",
  myaddress_no_address: " No address found!",

  // My Insurance Page Labels
  // ****************************************
  myinsurance_add_insurance: "Add New Insurance",
  myinsurance_my_insurance: "My Insurances",
  myinsurance_no_insurance: " No Insurance found",
  myinsurance_frontcard: "Front",
  myinsurance_backcard: "Back",
  myinsurance_label_1: "Insurance Card No: ",
  myinsurance_label_2: "Coverage: ",
  myinsurance_label_3: "Start Date: ",
  myinsurance_label_4: "End Date: ",

  // My Orders Page Labels
  // *****************************************
  myorders_stat_pending: "Pending",
  myorders_stat_processing: "Processing",
  myorders_stat_delivered: "Delivered",
  myorders_stat_cancelled: "Cancelled",
  myorders_activity_label_1: "Today",
  myorders_activity_label_2: "Yesterday",
  myorders_activity_label_3: "Last 7 days",
  myorders_activity_label_4: "Last 15 days",
  myorders_activity_label_5: "Last 30 days",
  myorders_activity_label_6: "Last month",
  myorders_activity_label_7: "Last six months",
  myorders_activity_label_8: "Last year",
  myorders_activity_id_1: "TODAY",
  myorders_activity_id_2: "YESTERDAY",
  myorders_activity_id_3: "LAST_7_DAYS",
  myorders_activity_id_4: "LAST_15_DAYS",
  myorders_activity_id_5: "LAST_30_DAYS",
  myorders_activity_id_6: "LAST_MONTH",
  myorders_activity_id_7: "LAST_SIX_MONTHS",
  myorders_activity_id_8: "LAST_YEAR",

  myorders_no_order: " No orders found",
  myorders_my_order: "Orders",

  myorders_table_col_1: "Order #",
  myorders_table_col_2: "Status",
  myorders_table_col_3: "Date purchased",
  myorders_table_col_4: "Total",
  myorders_table_col_5: "Products #",
  myorders_table_col_6: "Actions",
  myorders_table_col_7: "Request No #",

  orderdetails_heading: "Order details",
  orderdetails_dnld_inv: "Download invoice",
  orderdetails_cnc_ord: "Cancel Order",
  orderdetails_Track_ord: "Track Order",
  orderdetails_ord_again: "Order again",
  orderdetails_ord_id: "Order ID: ",
  orderdetails_ord_plc: "Placed on: ",
  orderdetails_ord_del: "Delivered on: ",
  orderdetails_adrs: "Delivery Address",
  orderdetails_sum: "Total summary",
  orderdetails_stot: "Sub total:",
  orderdetails_fee: "Delivery Charges:",
  orderdetails_disc: "Total Discount:",
  orderdetails_tot: "Total:",
  orderdetails_paymeth: "Pay by Credit/Debit card",

  // My Profile Page
  // **************************************
  myprofile_upd: "Update",
  myprofile_: "My Profile",
  myprofile_edit: "Edit Profile",
  myprofile_orders: "Your Orders",
  myprofile_insurance: "Active Insurances",
  myprofile_placeholder_1: "First name",
  myprofile_placeholder_2: "Last name",
  myprofile_placeholder_3: "Enter your phone no.",
  myprofile_placeholder_4: "Date of birth",
  myprofile_placeholder_5: "Email address",
  myprofile_placeholder_6: "Gender",

  // MyRX page
  // *****************************************
  myrx_heading: "My Prescription",

  // MyRX Request Page
  // *******************************************
  myrx_req_heading: "Prescriptions", // Requests
  myrx_req_notFo: " No prescription request found",
  myrx_req_label_1: "Today",
  myrx_req_label_2: "Yesterday",
  myrx_req_label_3: "Last 7 days",
  myrx_req_label_4: "Last 15 days",
  myrx_req_label_5: "Last 30 days",
  myrx_req_label_6: "Last month",
  myrx_req_label_7: "Last six months",
  myrx_req_label_8: "Last year",
  myrx_req_id_1: "TODAY",
  myrx_req_id_2: "YESTERDAY",
  myrx_req_id_3: "LAST_7_DAYS",
  myrx_req_id_4: "LAST_15_DAYS",
  myrx_req_id_5: "LAST_30_DAYS",
  myrx_req_id_6: "LAST_MONTH",
  myrx_req_id_7: "LAST_SIX_MONTHS",
  myrx_req_id_8: "LAST_YEAR",

  myrx_req_col_1: "Request No #",
  myrx_req_col_2: "Type",
  myrx_req_col_3: "Status",
  myrx_req_col_4: "Patient type",
  myrx_req_col_5: "Source",
  myrx_req_col_6: "Location",
  myrx_req_col_7: "Actions",
  myrx_req_col_8: "Order No #",

  // My Wishlist Page
  // ********************************************
  mywish_heading: "My Wishlist",
  mywish_nodata: "No items found",

  // Save for later Page
  // *************************************************
  svl_heading: "Save For Later",

  // Reset Page
  rst_txt: "Send password reset email",

  // Temp Nav Page
  tmpnav_lp: "Landing page",
  tmpnav_srx: "Submit Prescription",
  tmpnav_oc: "Order Completion",
  tmpnav_pls: "Product List Screen",
  tmpnav_wl: "Wishlist",
  tmpnav_mrx: "My Prescription",
  tmpnav_aa: "Add address",
  tmpnav_fp: "Forgot password",
  tmpnav_mp: "My profile",
  tmpnav_cp: "Change Password",
  tmpnav_I: "Insurance",
  tmpnav_hi: "Health Information",
  tmpnav_hc: "Health Card",
  tmpnav_more: "More",

  // Health Card Labels
  // ********************************************
  health_card_no_label: "Health Card No.",
  health_card_issue_date_label: "Issue date",
  health_card_exp_date_label: "Expiry date",

  // My Insurance Labels
  // ********************************************
  insurance_label: "Insurances",
  insurance_start_date_label: "Start date",
  insurance_end_date_label: "End date",
  insurance_card_no_label: "Insurance card no.",
  insurance_coverage_label: "Coverage percentage",

  // My Profile Page Labels
  // **************************************
  myprofile_btn_start: "Start",
  myprofile_btn_update: "Update",
  myprofile_tab_1: "My Profile",
  myprofile_tab_2: "Manage Address",
  myprofile_tab_3: "Manage Payments",
  myprofile_tab_4: "Health Information",
  myprofile_tab_5: "Health Card",
  myprofile_tab_6: "Insurance Cards",
  mycompanyprofile_tab: "Profile",
  myprofile_tab_7: "Manage Documents",

  Rx_text: "Rx",
  need_prescription: "Require prescription",

  primary_card_holder_Short_text: "PH",
  primary_card_holder_text: "Primary card holder",

  // Heading select card in checkout screen
  heading_select_card: "Select Card",

  // Changed Rx to prescription
  Prescription_text: "Prescription",

  //Transfer rx personal detail heading
  transfer_rx_personal_detail_heading: "Personal Details",

  //Transfer rx health info heading
  transfer_rx_health_info_heading: "Health Card",

  //Prescription request detail header name
  preescription_request_header: "Prescription request detail",
  Prescription_req_detail_customer: "Customer",
  Prescription_req_detail_source: "Source",
  Prescription_req_detail_date: "Prescription date",
  Prescription_req_detail_location: "Location",

  Rx_product_card_Prescribed: "Prescribed",
  Rx_product_card_RefillQnt: "Refill qnty.",
  Rx_product_card_Refill: "Refill",
  Rx_product_card_Balance: "Balance",

  estimated_cost_pay_text: "Co-pay",

  tracking_code_label: "Tracking code",
  rx_number: "Rx Number",
  track_order_label: "Track your order",

  skip_all_steps_text: "Skip all",
  profile_setup_modal_label: "Profile Setup",

  //cart page lalel configs ===>
  lbl_cart: "My Cart",
  lbl_save_for_later: "Save for later",
  lbl_move_to_wishlist: "Move to wishlist",
  lbl_cart: "My Cart",
  lbl_save_for_later: "Save for later",
  lbl_deliveryAddress: "Delivery Address",
  lbl_billingAddress: "Billing Address",
  lbl_same_as_delivery: "Same as delivery address",
  lbl_manageAddress: "+ Add new address",
  lbl_add_to_cart: "Add to cart",
  lbl_overview: "Overview",
  lbl_no_products: "No products in your cart",
  lbl_no_products_found: "No products found!",
  lbl_no_list_is_selected: "No list is selected!",
  lbl_voucher: "Voucher",
  lbl_enter_voucher_code: "Enter your coupon code if you have one",
  lbl_no_products_in_cart: "No products in your cart",

  ///upload documents component
  lbl_document_uploadHeading: "Required Documents",
  lbl_document_uploadCaption:
    "Please see the list of items below that we need in order to lodge your application",
  lbl_drop_files: "Drop files to upload or",
  lbl_browse: "Browse",
  lbl_upload_document: "Uploaded Document(s)",
  lbl_no_documents_found: "No documents found!",

  // Heading text for homebanner Screen
  // ***************************
  heading_homebanner:
    "Unlock the benefits of bulk medicine orders with Safe Pharmacy",
  text_homebanner: "Stay healthy and register now! Get 15% off on medicines",

  // Heading text for homebanner Screen after login
  // ***************************
  heading_homebanner_changed: "Your trusted partner for bulk medicine order",
  text_homebanner_after:
    "Get flat 25% off on your first per-paid/ COD Medicine order",

  myList_field_list_name: "List name",
  myList_field_list_description: "Description (optional)",

  lbl_change_mobile_number: "Change Phone Number",
  lbl_proceed: "Proceed",
  relatedProducts: "Related Products",

  //pro user login page labels
  lbl_coupenCode: "Coupon Codes",
  lbl_myList: "My List",
  lbl_bulkBuying: "Bulk Buying",
  lbl_discount: "Offers & Discounts",

  lbl_caption_couponCodes:
    "Lorem ipsum is simply dummy text of the printing and typesetting industry",
  lbl_caption_myList:
    "Lorem ipsum is simply dummy text of the printing and typesetting industry",
  lbl_caption_bulkBuying:
    "Lorem ipsum is simply dummy text of the printing and typesetting industry",
  lbl_caption_discount:
    "Lorem ipsum is simply dummy text of the printing and typesetting industry",

  lbl_logout: "Logout",
  lbl_Close: "Close",
  // Heading text for Delivery Address Modal popup
  // ***************************

  heading_deliveryaddress_modal: "Delivery address",
  text_deliveryaddress_modal: "+ Add new address",
  form_deliveryaddress_modalelementname: "First Name",
  form_deliveryaddress_modalelementlastname: "Last Name",
  form_deliveryaddress_modalelement1: "Address",
  form_deliveryaddress_modalelement2: "Street no.",
  form_deliveryaddress_modalelement3: "City",
  form_deliveryaddress_modalelement4: "State/UT",
  form_deliveryaddress_modalelement5: "Country",
  form_deliveryaddress_modalelement6: "Postal Code",
  form_deliveryaddress_modalelement7: "Set as default address",
  form_deliveryaddress_modalelement8: "Phone No.",
  form_deliveryaddress_modalelement9: "Email",

  lbl_select: "Select Address",
  lbl_selected: "Selected",
  lbl_update_address: "Update Address",
  lbl_add_address: "Add Address",
  lbl_change: "Change",
  lbl_change_edit: "Edit",

  lbl_delivery_charges: "Delivery Charges",

  //voucher labels
  success_voucher_applied: "The coupon code is applied",
  error_voucher_details: "Voucher code cannot be blank.",

  //cart screen
  lbl_cart_total: "Total",
  lbl_cart_Unit_price: "Unit Price",

  //about Us Page
  lbl_about_us_title: "About Us",

  text_about_us_content_top:
    "Welcome to Safe Pharmacy, your trusted destination for convenient and reliable healthcare solutions. We are dedicated to revolutionizing the way people access medicines and healthcare products throughout India. Our mission is to ensure that everyone has seamless access to the medications and healthcare services they need, delivered directly to their doorstep.",
  text_about_us_content_below:
    "We are driven by our vision to create a healthier nation, where individuals and families can thrive and lead fulfilling lives. Our core values of innovation, reliability, and customer satisfaction guide us in our quest to make a positive impact on the healthcare landscape in India.",

  lbl_about_us_heading_1: "Our Vision and Values",
  content_ourvision_values:
    "we envision a future where every individual in India has seamless access to the medications and healthcare services they need, irrespective of their location or circumstances. We strive to be the trusted platform that empowers individuals and families to take control of their health and well-being through convenient and reliable healthcare solutions.",

  lbl_about_us_values_heading1: "Customer-Centricity",
  lbl_about_us_values_heading2: "Accessibility and Convenicence",
  lbl_about_us_values_heading3: "Quality and Authenticity",
  lbl_about_us_values_heading4: " Innovation and Improvment",

  lbl_about_us_values_content1:
    "We place our customers at the heart of everything we do. Their health and satisfaction are our top priorities. We aim to anticipate and exceed their expectations by delivering exceptional service, personalized care, and prompt support.",
  lbl_about_us_values_content2:
    "We believe that healthcare should be easily accessible and convenient for all. Through our user-friendly app and reliable delivery network, we ensure that vital medications and healthcare products are delivered directly to our customers' doorsteps.",
  lbl_about_us_values_content3:
    "We are committed to ensuring the highest standards of quality and authenticity in the medications and healthcare products we offer. We source our products from trusted manufacturers and distributors.",
  lbl_about_us_values_content4:
    "We embrace innovation and leverage technology to continually enhance our services and offerings. We stay updated with the latest advancements in healthcare to provide cutting-edge solutions that meet the evolving needs of our customers.",

  lbl_about_us_heading_2: "Our Services",
  content_ourservices:
    "We offer a comprehensive range of healthcare services designed to cater to your specific needs, ensuring convenient access to medications and expert healthcare guidance right from the comfort of your home.",

  lbl_about_us_services_feature1: "Medicine Delivery",
  lbl_about_us_services_feature2: "Health and Wellness Resources",
  lbl_about_us_services_feature3: "Healthcare Plans",
  lbl_about_us_services_feature4: "Easy prescriptions Refills",

  lbl_default_address: "Default",

  lbl_summary_delivery_address: "Delivery Address",
  lbl_summary_biling_address: "Billing Address",


 //about Us medex Page
 lbl_about_us_medex_title: "About Us",

 text_about_us_medex_content_top:
   "Welcome to Safe Pharmacy, your trusted destination for convenient and reliable healthcare solutions. We are dedicated to revolutionizing the way people access medicines and healthcare products throughout India. Our mission is to ensure that everyone has seamless access to the medications and healthcare services they need, delivered directly to their doorstep.",
 text_about_us_medex_content_below:
   "We are driven by our vision to create a healthier nation, where individuals and families can thrive and lead fulfilling lives. Our core values of innovation, reliability, and customer satisfaction guide us in our quest to make a positive impact on the healthcare landscape in India.",

 lbl_about_us_medex_heading_1: "Our Vision and Values",
 about_us_medex_content_ourvision_values:
   "we envision a future where every individual in India has seamless access to the medications and healthcare services they need, irrespective of their location or circumstances. We strive to be the trusted platform that empowers individuals and families to take control of their health and well-being through convenient and reliable healthcare solutions.",

 lbl_about_us_medex_values_heading1: "Customer-Centricity",
 lbl_about_us_medex_values_heading2: "Accessibility and Convenicence",
 lbl_about_us_medex_values_heading3: "Quality and Authenticity",
 lbl_about_us_medex_values_heading4: " Innovation and Improvment",

 lbl_about_us_medex_values_content1:
   "We place our customers at the heart of everything we do. Their health and satisfaction are our top priorities. We aim to anticipate and exceed their expectations by delivering exceptional service, personalized care, and prompt support.",
 lbl_about_us_medex_values_content2:
   "We believe that healthcare should be easily accessible and convenient for all. Through our user-friendly app and reliable delivery network, we ensure that vital medications and healthcare products are delivered directly to our customers' doorsteps.",
 lbl_about_us_medex_values_content3:
   "We are committed to ensuring the highest standards of quality and authenticity in the medications and healthcare products we offer. We source our products from trusted manufacturers and distributors.",
 lbl_about_us_medex_values_content4:
   "We embrace innovation and leverage technology to continually enhance our services and offerings. We stay updated with the latest advancements in healthcare to provide cutting-edge solutions that meet the evolving needs of our customers.",

   lbl_about_us_medex_heading_2: "Our Services",
  content_ourservices:
    "We offer a comprehensive range of healthcare services designed to cater to your specific needs, ensuring convenient access to medications and expert healthcare guidance right from the comfort of your home.",

  lbl_about_us_medex_services_feature1: "Medicine Delivery",
  lbl_about_us_medex_services_feature2: "Health and Wellness Resources",
  lbl_about_us_medex_services_feature3: "Healthcare Plans",
  lbl_about_us_medex_services_feature4: "Easy prescriptions Refills",
// HEADING AND PAGE TEXT OF RXPAGE

lbl_upload_prescription_heading: "Upload Prescription",
lbl_drag_drop_text:"Drag & drop files or",
lbl_browse_button:"Browse",
lbl_supported_format:"Supported formats: JPEG, PNG, PDF",
lbl_max_size:"Max file size: 3MB",

lbl_prescription_elements:"Make sure the prescription you upload contains the following elements:",
lbl_doctor_details:"Doctor Details",
lbl_prescription_date:"Date of Prescription",
lbl_patient_details:"Patient Details",
lbl_meds_details:"Medicine Details",

lbl_prescription_processing_text:"Your Prescription will be sent to the pharmacist for processing",

// order info section 
lbl_order_info_heading:"Order Info",
lbl_order_executive_call:" Your order will be reviewed by our executive. Incase of any discrepancy our executive will call you.",



};

export default Config;
