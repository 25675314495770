import React from "react";
import { Controller } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "../FormErrorMessage";
import styles from "./index.module.css";
import { color_config } from "../../../config/colorConfig";

export type SelectProps = {
  size?: string;
  options: any;
  placeholder?: string;
  onSelectChange?: any;
  name?: string;
  value?: any;
  disabled?: any;
  isClearable?: any;
  control?: any;
  rules?: any;
  errors?: any;
  loading?: any;
  op_style?: any;
  sg_style?: any;
  watch?: any;
  clearErrors?: any;
  filterType?: any;
};

const SelectDropdown: React.FC<SelectProps> = (props: any) => {
  const {
    name,
    control,
    options,
    isClearable,
    disabled,
    rules,
    loading,
    errors,
    op_style,
    sg_style,
    filterType,
    onSelectChange,
  } = props;

  const getSize = () => {
    switch (props?.size) {
      case "large":
        return { height: "61px", fontSize: "20px" };

      case "medium":
        return { height: "56px", fontSize: "18px" };
      case "small":
        return { height: "38px", fontSize: "16px" };

      default:
        return {};
    }
  };
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "1px dotted grey",
      padding: 15,
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "600",
      ...op_style,
    }),
    singleValue: (provided: any) => {
      return {
        ...provided,
        color: color_config?.common_bg_color,
        fontWeight: "600",
        lineHeight: "28px",
        ...sg_style,
      };
    },
    control: (provided: any) => ({
      ...provided,
      borderRadius: "8px",
      cursor: "pointer",
      ...getSize(),
    }),

    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#9ba8bb",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
  };

  const getFilter = (option: any) => {
    switch (filterType) {
      case "initials":
        return option?.label?.props?.children[1]?.props?.children;

      default:
        return option?.label;
    }
  };

  const filterConfig: any = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    stringify: (option: any) => getFilter(option),
    matchFrom: "any",
  };

  const filterOption = createFilter(filterConfig);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { onChange } = field;
        return (
          <div>
            <Select
              className={`${styles?.selectDropDown}`}
              {...field}
              isDisabled={disabled}
              isClearable={isClearable}
              isSearchable
              placeholder={props?.placeholder}
              filterOption={filterOption}
              options={options}
              styles={customStyles}
              noOptionsMessage={() => (
                <i className="fa fa-spinner fa-spin loading"></i>
              )}
              isLoading={loading}
              onChange={(e: any) => {
                onChange(e);
                if (onSelectChange) {
                  onSelectChange(e);
                }
              }}
            />

            <ErrorMessage
              errors={{ [field?.name]: { ...error } }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              name={name as any}
              render={({ message }) => {
                return (
                  <FormErrorMessage className={`${styles?.error_msg}`}>
                    {message}
                  </FormErrorMessage>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default SelectDropdown;
