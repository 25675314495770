import React from "react";
import Styles from "./index.module.css";
import { Link } from "react-router-dom";
import usersvg from "../../../../assets/svg/My_profile.svg";
import Group3 from "../../../../assets/svg/orders.svg";
import Invoices from "../../../../assets/svg/Invoices.svg";
import Payments from "../../../../assets/svg/Payments.svg";
import ManageAddress from "../../../../assets/svg/manage_address.svg";
import { HeartFill } from "../../../../utils/appIcons";
import Kart from "../../../../assets/svg/cart.svg";
import { useNavigate } from "react-router-dom";
import bagheartfill from "../../../../assets/svg/wishlist.svg";
import bookmarksfill from "../../../../assets/svg/bookmarks-fill.svg";
import settings from "../../../../assets/svg/password.svg";
import HealthCard from "../../../../assets/png/HealthCard.png";
import HealthInfo from "../../../../assets/png/HealthInfo.png";
import Insurance from "../../../../assets/png/Insurance.png";
import { objDecrypt, getDecryptedID, JSONCheckerFunc } from "../../../../utils/utils";
import { USER_PROFILE } from "../../../../config/Config";
import myListIcon from "../../../../assets/svg/my_list.svg"

export type ProfileModalProps = {
  heading?: string;
  detail?: string;
  setModalOpen?: any;
  className?: string;
};

const DetailProfileModal: React.FC<ProfileModalProps> = (props) => {
  const navigate = useNavigate();

  const handleOnClick = (val: any) => {
    switch (val) {
      case "Manage Address":
        navigate("/add-address");
        break;

      case "Prescription Requests":
        navigate("/my-rx-request");
        break;

      case "My Profile":
        navigate("/my-profile");
        break;

      case "Cart":
        navigate("/purchase");
        break;

      case "Orders":
        navigate("/my-orders");
        break;

      case "Wishlist":
        navigate("/wishlist");
        break;

      case "Change Password":
        navigate("/change-password");
        window.scrollTo(0, 0);
        break;
      case "Manage Payments":
        navigate("/manage-payments");
        window.scrollTo(0, 0);
        break;
      case "Insurance Cards":
        navigate("/my-insurance");
        window.scrollTo(0, 0);
        break;

      case "Health Information":
        navigate("/my-health");
        window.scrollTo(0, 0);
        break;
      case "Health Card":
        navigate("/my-health-card");
        window.scrollTo(0, 0);
        break;

      case "Save For Later":
        navigate("/saved");
        break;
      case "My List":
        navigate("/my-list");
        break;
      default:
        break;
    }
  };

  const details = [
    {
      icon: <img src={usersvg} alt="user" draggable="false" />,
      value: "My Profile",
    },
    {
      icon: <img src={myListIcon} alt="user" draggable="false" />,
      value: "My List",
    },
    {
      icon: <img src={ManageAddress} alt="address" draggable="false" />,
      value: "Manage Address",
    },
    {
      icon: (
        <img
          src={Kart}
          alt="cart"
          style={{ marginLeft: "-3px" }}
          draggable="false"
        />
      ),
      value: "Cart",
    },
    // {
    //   icon: <img src={Payments} alt="Payments" draggable="false" />,
    //   value: "Manage Payments",
    // },
    {
      icon: <img src={Group3} alt="orders" draggable="false" />,
      value: "Orders",
    },
    // {
    //   icon: <img src={ManageAddress} alt="address" draggable="false" />,
    //   value: "Prescription Requests",
    // },
    // {
    //   icon: (
    //     <img
    //       src={Insurance}
    //       alt="Insurance"
    //       style={{ height: "0.9rem", width: "0.9rem" }}
    //       draggable="false"
    //     />
    //   ),
    //   value: "Insurance Cards",
    // },
    // {
    //   icon: (
    //     <img
    //       src={HealthCard}
    //       alt="Health Card"
    //       style={{ height: "0.9rem", width: "0.9rem" }}
    //       draggable="false"
    //     />
    //   ),
    //   value: "Health Card",
    // },
    // {
    //   icon: (
    //     <img
    //       src={HealthInfo}
    //       alt="Health"
    //       style={{ height: "0.9rem", width: "0.9rem" }}
    //       draggable="false"
    //     />
    //   ),
    //   value: "Health Information",
    // },
    {
      icon: <img src={bagheartfill} alt="wishlist" draggable="false" />,
      value: "My Wishlist",
    },
    // {
    //   icon: <img src={bookmarksfill} alt="save for later" draggable="false" />,
    //   value: "Save For Later",
    // },
    {
      icon: <img src={settings} alt="password" draggable="false" />,
      value: "Change Password",
    },
  ];
  const sessionStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let sessionStorageCopy = { ...JSONCheckerFunc(sessionStorageData) };
  const newArr = details?.filter((item) => {
    if (
      sessionStorageCopy?.usr_signup_type === "gmail" ||
      sessionStorageCopy?.usr_signup_type === "apple" ||
      sessionStorageCopy?.usr_signup_type === "Gmail" ||
      sessionStorageCopy?.usr_signup_type === "Apple"
    ) {
      return item?.value !== "Change Password";
    } else {
      return item;
    }
  });
  return (
    <div className={`${Styles["modalBackground"]} ${Styles?.className}`}>
      <div className={Styles["modalContainer"]}>
        <div className={Styles["subDetails"]}>
          {newArr?.map((item) => (
            <div
              className={Styles["dynamicDetails"]}
              style={{
                borderBottom:
                  (item?.value === "Manage Payments" && "1px solid #dfe3e6") ||
                  (item?.value === "Prescription Requests" &&
                    "1px solid #dfe3e6") ||
                  (item?.value === "Health Information" &&
                    "1px solid #dfe3e6") ||
                  "",
              }}
              onClick={() =>
                handleOnClick && handleOnClick(item?.value)}
            >
              <div style={{ marginTop: "5px" }}>{item?.icon}</div>
              <Link
                title=""
                to=""
                className={`${Styles?.linkStyling}`}
                draggable="false"
              >
                {item?.value}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default DetailProfileModal;
