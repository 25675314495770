import styles from "./index.module.css";
import { EyeOpen, EyeSlash } from "../../../utils/appIcons";
import React, {
  FC,
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  useState,
} from "react";
import { SHOW_PASSWORD_FLAG } from "../../../config/Config";

export type InputProps = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange?: any;
  style?: any;
  size: string;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  ref?: any;
  required?: any;
  defaultValue?: any;
  maxLength?: number;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size"
>;

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      required,
      defaultValue,
      id,
      name,
      type = "text",
      size = "medium",
      className = "",
      placeholder,
      maxLength,
      ...props
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newType, setNewType] = useState(type);

    const getInputSize = () => {
      switch (size) {
        case "large":
          return { height: "56px", fontSize: "18px" };

        case "medium":
          return { height: "46px", fontSize: "16px" };
        case "small":
          return { height: "36px", fontSize: "16px" };

        default:
          return {};
      }
    };

    // const cdf: any = (
    //   <EyeSlash
    //     size={"16"}
    //     style={{
    //       position: "absolute",
    //       marginTop: "-30px",
    //       marginLeft: "150px",
    //       cursor: "pointer",
    //     }}
    //     handleOnClick={() => {
    //       setPasswordVisible(true);
    //       setNewType("text");
    //     }}
    //   />
    // );

    return (
      <div
        // style={{
        //   paddingLeft: type === "password" ? "10px" : 0,
        //   paddingRight: type === "password" ? "10px" : 0,
        // }}
        className={`${styles.inputWrapper}`}
      >
        <input
          id={id}
          ref={ref}
          name={name}
          type={newType}
          placeholder={placeholder}
          // required={required}
          className={`${styles.inputStyle} ${className}`}
          style={{
            ...props?.style,
            ...getInputSize(),
            minWidth: type === "password" ? "90%" : "100%",
          }}
          {...props}
          maxLength={maxLength}
          onChange={props?.onChange}
        />

        {SHOW_PASSWORD_FLAG && type === "password" ? (
          <div className={styles.passwordEye} style={{ display: "grid", placeItems: "center" }}>
            {passwordVisible ? (
              <EyeOpen
                size={"16"}
                style={{
                  cursor: "pointer",
                }}
                handleOnClick={() => {
                  setPasswordVisible(false);
                  setNewType("password");
                }}
              />
            ) : (
              <EyeSlash
                size={"16"}
                style={{
                  cursor: "pointer",
                }}
                handleOnClick={() => {
                  setPasswordVisible(true);
                  setNewType("text");
                }}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

export default Input;
