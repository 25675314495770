import s from "./index.module.css";
import FileUpload from "../../../components/UI/FileUpload";
import CheckValidation from "../../CheckValidation";
import { useState } from "react";
import UploadedFileContainer from "../uploadedFileContainer";
import Heading from "../../../components/UI/Heading";
import Config from "../../../config/label";
import btnConfig from "../../../config/button";
import Drawer from "../../../components/UI/NewDrawer/Drawer";

function UploadRX(props: any) {
  const { setFileContents, fileContents } = props;
  const [isExist, setIsExist] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clickedPreview, setClickedPreview] = useState({ uri: "", name: "" });

  const deleteContentHandler = (info: string) => {
    setFileContents((prev: any) =>
      prev?.filter((c: any) => c?.data?.name !== info)
    );
  };
  const filePreviewHandler = (content: any) => {
    setClickedPreview({ uri: content?.uri, name: content?.data?.name });
    setDrawerOpen(true);
  };

  return (
    <div className={s["uploadRxContainer"]}>
      <Heading className={`${s?.page_heading}`}>
        {Config?.uploadRx_Page_Heading}
      </Heading>
      <div className={s["uploadRxContentWrapper"]}>
        <CheckValidation
          show={Object?.keys(fileContents)?.length > 0}
          fallback={
            <p className={`${s?.beforeUploadContent}`}>
              {Config?.lbl_Upload_Empty_Content}
            </p>
          }
        >
          {Array?.isArray(fileContents) &&
            fileContents?.map((content) => {
              return (
                <UploadedFileContainer
                  content={content}
                  onDeleteContent={deleteContentHandler}
                  setDrawerOpen={setDrawerOpen}
                  filePreviewHandler={filePreviewHandler}
                />
              );
            })}
          {isExist && (
            <p className={`${s?.validationError}`}>
              &#x0002A; File already exist.
            </p>
          )}
        </CheckValidation>
      </div>

      <FileUpload
        name="myRxDocuments"
        id="myRxDocuments"
        type="plus"
        multiple={false}
        title={
          Array.isArray(fileContents) && fileContents?.length > 0
            ? `${btnConfig?.uploadRx_Add_Another}`
            : `${btnConfig?.uploadRx_Add}`
        }
        fileContents={fileContents}
        setFileContents={setFileContents}
        setIsExist={setIsExist}
      />

      <Drawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        name={clickedPreview?.name}
        uri={clickedPreview?.uri}
        headingName={clickedPreview?.name}
      />
    </div>
  );
}

export default UploadRX;
