import { createSlice } from "@reduxjs/toolkit";

const signUpVendorSlice = createSlice({
  name: "Onboarding",
  initialState: {
    p_first_name: "",
    p_last_name: "",
    p_company_email: "",
    p_company_alternate_email: "",
    p_company_landline_no: "",
    p_company_alternate_phone: "",
    p_company_size: "",
    p_company_gstin_no: "",
    p_legal_company_name: "",
    p_company_postal_code: "",
    p_user_type: "",
  },
  reducers: {
    updateFormField(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    restoreFormFields(state, action) {
      state.p_first_name = action.payload.p_first_name
      state.p_last_name = action.payload.p_last_name
      state.p_company_email = action.payload.p_company_email
      state.p_company_alternate_email = action.payload.p_company_alternate_email
      state.p_company_landline_no = action.payload.p_company_landline_no
      state.p_company_alternate_phone = action.payload.p_company_alternate_phone
      state.p_company_size = action.payload.p_company_size
      state.p_company_gstin_no = action.payload.p_company_gstin_no
      state.p_legal_company_name = action.payload.p_legal_company_name
      state.p_company_postal_code = action.payload.p_company_postal_code
      state.p_user_type = action.payload.p_user_type
    }
    // SUBMIT_PHONE_NUMBER: (state, action) => {
    //   state.enteredPhoneNumber = action.payload.enteredPhoneNumber;
    // },
    // SUBMIT_OTP: (state, action) => {
    //   state.OTP = action.payload?.OTP;
    // },
    // SUBMIT_COMPANY_NAME: (state, action) => {
    //   state.companyProfile.p_legal_company_name =
    //     action?.payload?.p_legal_company_name;
    // },
    // SUBMIT_COMPANY_POSTAL_CODE: (state, action) => {
    //   state.companyProfile.p_company_postal_code =
    //     action?.payload?.p_company_postal_code;
    // },
    // SUBMIT_COMPANY_PROFILE_DETAILS: (state, action) => {
    //   state.companyProfileDetails = action.payload?.companyProfileDetails;
    // },
    // SUBMIT_USER_TYPE: (state, action) => {
    //   state.p_user_type = action.payload?.p_user_type;
    // },
  },
});

export const signUpVendorActions = signUpVendorSlice.actions;
export const signUpVendorReducer = signUpVendorSlice?.reducer;
