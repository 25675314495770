import React, { useEffect, useRef, useState } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
// The below files are imported for the purpose to use "react-slick" --- also check App.css file for customization
import "slick-carousel/slick/slick.css";
import BasicLayout from "../src/layouts/BasicLayout";
import "./App.css";
import ProductDetails from "./components/ProductDetails/index";
import { config } from "./config/processId.js";
import { siteConfig } from "./config/site";
import { logout } from "./firebase";
import HeaderLayout from "./layouts/HeaderLayout";
// import AboutUs from "./pages/AboutUs";
import AboutUsPage from "./pages/AboutUsPage";
import ChangePassword from "./pages/ChangePassword";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import HealthCard from "./pages/HealthCard";
import HealthPage from "./pages/HealthPage";
import HomePage from "./pages/Home-Page";
// import Landingpage from "./pages/Landingpage";
import LogInPage from "./pages/LogInPage";
import ManagePayments from "./pages/ManagePayments";
import MyAddress from "./pages/MyAddress/index";
import MyInsurance from "./pages/MyInsurance";
import MyOrders from "./pages/MyOrders";
import OrderDetail from "./pages/MyOrders/OrderDetail";
import MyProfile from "./pages/MyProfile";
import MyRx from "./pages/MyRx";
import MyRxRequest from "./pages/MyRxRequest";
import MyWishlist from "./pages/MyWishlist";
import Privacy from "./pages/Privacy";
import ProductListScreen from "./pages/ProductListScreen";
import ProductPurchase from "./pages/ProductPurchase";
import CartScreen from "./pages/ProductPurchase/CartScreen";
import Checkout from "./pages/ProductPurchase/NewCheckout";
import Payment from "./pages/ProductPurchase/Payment";
import Register from "./pages/Register";
import Reset from "./pages/Reset";
import SaveforlaterScreen from "./pages/SaveforlaterScreen";
import SignUp from "./pages/SignUp";
import SubmitRxPage from "./pages/SubmitRxPage";
import RxContainer from "./pages/SubmitRxPage/RxContainer";
import UploadRxPage from "./pages/SubmitRxPage/UploadRxPage";
import TermsConditions from "./pages/TermsConditions";
import { AppAlertContext } from "./store/alert-context.js";
import APIContext from "./store/api-context";
import { GlobalContext } from "./store/global-context.js";
import { UserAuthContext } from "./store/UserAuthContext.js";
import { CallAPI } from "./suggest-us/callSuggest.js";
import suggestUsInitialize, { orgID } from "./suggest-us/suggestApi.js";
import {
  JSONCheckerFunc,
  clearSession,
  getDecryptedID,
  getID,
  objDecrypt,
  removeKey,
  setEncryptedID,
  triggerLocalStorage,
} from "./utils/utils";
import SignUpVendor from "./pages/SignUpVendor";
// import ProductCardMerchant from "./components/ProductCardMerchant";
import { useDispatch } from "react-redux";
import Summary from "./pages/Summary/Summary";
import ProductList from "./pages/ProductList";
// import MyList from "./components/MyList";
import MyListPage from "./pages/MyListPage";
import { signUpVendorActions } from "./pages/SignUpVendor/slice/slice";
import Categories from "./components/Categories";
import ChatBot from "./twilio-chat/src/components/ChatModal/ChatBotTwilio";
import FABV2 from "./components/FABV2";
import suggestus_client_config from "./config/suggestus_client_config";
import {
  CART_COUNT,
  CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION,
  CURRENT_SCREEN,
  C_PWD,
  DEFAULT_FLAG,
  DOC_UPDATE,
  FIRST_TIME_LOGIN,
  FORGOT_PASSWORD_PHONE_NO,
  HEALTH_BACK_ID,
  HEALTH_CARD_FRONT_ID,
  HEALTH_INFO,
  INSURANCE_BACK_ID,
  INSURANCE_FRONT_ID,
  IS_CHECKED,
  IS_DOCS_PENDING,
  IS_EDITING,
  IS_INSURANCE,
  IS_PROFILE_UPDATED,
  LOGINS_IN_BY,
  ORDER_DETAILS,
  ORG_CODE,
  ORG_CURRENCY,
  ORG_ID,
  ORG_NAME,
  PASSWORD_MSG,
  PATIENT_ID,
  PHONE_NO,
  PROFILE_IMAGE,
  PROFILE_IMAGE2,
  QB_ID,
  RX_CART_FLAG,
  SG_ORG_ID,
  SG_PATIENT_ID,
  SG_ROLE_ID,
  SG_USER_ID,
  SIGN_IN_WITH,
  SUGGEST_US_INIT,
  USER_ID,
  USER_LOGGED_IN,
  USER_PROFILE,
  USER_ROLE_ID,
  sgUserId,
} from "./config/Config";
import { decryptData, encryptData } from "./utils/util_helper";
import Rxpage from "./components/RxPage";
import Medex from "./pages/Medex";
// import { decryptData, encryptData } from "./utils/util_helper";

//ProtectedRoute created for private routes
const ProtectedRoute = ({ auth, redirectPath = "/log-in", children }) => {
  triggerLocalStorage();
  // In case of false
  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }

  // In case of true
  return children;
};

export default function App() {
  const [suggestUsInit, setSuggestUsInit] = useState({
    returnCode: false,
    return_Error: "",
    org_id: "",
    user_id: "",
    curreny_sign: "",
    sign_in_with: "",
    isUserAuth: false,
    phForPassword: "",
    UserAuth: {},
    verifyPhone: {},
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const localStorageCopy = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };
  let isUserLoggedIn = localStorageCopy
    ? Object.keys(localStorageCopy).length > 0
      ? true
      : false
    : false;

  const [chnagePswrdAuthed, setChangePswrdAuthed] = useState(
    (isUserLoggedIn &&
      !["gmail", "apple", "Gmail", "Apple"]?.includes(
        localStorageCopy.usr_signup_type
      )) ||
      false
  );

  const [context, setContext] = useState({
    UserAuth: {},
    searchDisplayOut: false,
    type: "",
    msg: "",
    isUserLoggedIn: isUserLoggedIn,
    isLoginPopUP: false,
    cartCount: "",
    searchBox: "",
    healthCardFrontId: "",
    healthCardFrontUrl: "",
    healthCardBackId: "",
    healthCardBackurl: "",
    healthcard_ref_id: "",
    phLength: 0,
    openTermModalPopup: false,
  });

  const [globalContext, setGlobalContext] = useState({
    profile_image: "",
  });

  const [alertContext, setAlertContext] = useState({
    status: false,
    type: "success",
    info: "",
    cartCount: "",
  });

  useEffect(() => {
    try {
      if (alertContext?.status === true) {
        setTimeout(() => {
          setAlertContext((prev) => {
            return { ...prev, status: false };
          });
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    }
  }, [alertContext]);

  useEffect(() => {
    try {
      if (getDecryptedID(DEFAULT_FLAG) !== null) {
        const defData = getDecryptedID(DEFAULT_FLAG);
        // let defData = { ...JSONCheckerFunc(Data) };
        const date_Calc =
          Math.abs(Date.now() - new Date(defData.date).getTime()) /
          (1000 * 60 * 60 * 24);
        if (defData.flag === "Y") {
          if (date_Calc >= +siteConfig?.login_default) {
            logout();
            setContext((prev) => ({
              ...prev,
              UserAuth: {},
            }));
            remove;
            removeKey(USER_PROFILE);
            setEncryptedID(USER_LOGGED_IN, "N");
            removeKey(SG_ROLE_ID);
            removeKey(SG_USER_ID);
            removeKey(SG_PATIENT_ID);
            removeKey(IS_INSURANCE);
            removeKey(PROFILE_IMAGE);
            clearSession();
            setEncryptedID(SIGN_IN_WITH, " ");
            setGlobalContext((prev) => {
              return {
                ...prev,
                profile_image: "",
              };
            });
          }
        } else {
          if (date_Calc >= +siteConfig?.login_signedin) {
            logout();
            setContext((prev) => ({
              ...prev,
              UserAuth: {},
            }));
            // localStorage.clear();
            removeKey(USER_PROFILE);
            setEncryptedID(USER_LOGGED_IN, "N");
            removeKey(SG_ROLE_ID);
            removeKey(SG_USER_ID);
            removeKey(SG_PATIENT_ID);
            removeKey(IS_INSURANCE);
            removeKey(PROFILE_IMAGE);
            clearSession();
            setEncryptedID(SIGN_IN_WITH, " ");
            setGlobalContext((prev) => {
              return {
                ...prev,
                profile_image: "",
              };
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [getID(DEFAULT_FLAG)]);

  const [authed, setAuthed] = useState(isUserLoggedIn);

  const firstRender = useRef(true);

  async function initSuggestUs() {
    const result = await suggestUsInitialize();
    setEncryptedID(SUGGEST_US_INIT, result);
    if (result?.returnCode) {
      //Calling API for org details
      CallAPI(config?.get_org_details_process_id, {
        p_org_ai_code: suggestus_client_config?.SUGGESTUS_AI_CODE,
      }).then((res) => {
        if (res?.returnCode) {
          orgID(res?.returnData[0]?.org_id);
          setEncryptedID(ORG_CODE, res?.returnData[0]?.org_code || "");
          setEncryptedID(ORG_NAME, res?.returnData[0]?.org_name || "");
          setEncryptedID(ORG_ID, res?.returnData[0]?.org_id || "");
          //Settled here suggestus result and org id because in private window setSuggestusInit was not able to setup
          setSuggestUsInit({ ...result, org_id: res?.returnData[0]?.org_id });
          setEncryptedID(ORG_CURRENCY, res?.returnData[0]?.org_currency);
        }
      });
    } else {
      console.log("Initialize -- error - ", result?.return_Error);
    }
  }

  const updateAuthContext = () => {
    // const localStorageData = objDecrypt(localStorage.getItem(USER_PROFILE));
    const localStorageCopy = getDecryptedID(USER_PROFILE);
    setContext((prev) => ({
      ...prev,
      UserAuth: localStorageCopy,
    }));
    dispatch(
      signUpVendorActions?.restoreFormFields({
        p_first_name: localStorageCopy?.usr_first_name || "",
        p_last_name: localStorageCopy?.usr_last_name || "",
        p_company_email: localStorageCopy?.usr_email || "",
        p_company_alternate_email:
          localStorageCopy?.usr_company_alternate_email,
        p_company_landline_no: localStorageCopy?.usr_company_landline_no,
        p_company_alternate_phone:
          localStorageCopy?.usr_company_alternate_phone,
        p_company_size: localStorageCopy?.usr_company_size,
        p_company_gstin_no: localStorageCopy?.usr_company_gstin_no,
        p_legal_company_name: localStorageCopy?.usr_company_name,
        p_company_postal_code: localStorageCopy?.usr_company_postal,
        p_user_type: localStorageCopy?.usr_type,
      })
    );
  };
  useEffect(() => {
    if (firstRender?.current) {
      firstRender.current = false;
      initSuggestUs();
      const initValues = getDecryptedID(SUGGEST_US_INIT);
      const org_id = getDecryptedID(ORG_ID);
      setSuggestUsInit({ ...JSONCheckerFunc(initValues), org_id });
      updateAuthContext();

      const profile_image = getDecryptedID(PROFILE_IMAGE2);
      setGlobalContext((prev) => {
        return {
          ...prev,
          profile_image,
        };
      });
      return;
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      setAuthed(isUserLoggedIn);
      // setLoginScreensAuthed(false);
      if (
        ["gmail", "apple", "Gmail", "Apple"]?.includes(
          localStorageCopy?.usr_signup_type
        )
      ) {
        setChangePswrdAuthed(false);
      } else {
        setChangePswrdAuthed(true);
      }
    } else {
      setAuthed(false);
    }
  }, [isUserLoggedIn, location]);

  // useEffect(() => {
  //   try {
  //     let keyValArr = [];
  //     const sgArray = [SG_USER_ID, SG_ROLE_ID, SG_ORG_ID, SG_PATIENT_ID, "SessionExpiryNode", "device_unique_id"]
  //     for (let i = 0; i < localStorage?.length; ++i) {
  //       let s_key = localStorage?.key(i);
  //       let s_val = localStorage?.getItem(s_key);
  //       keyValArr.push({ key: s_key, val: s_val });
  //     }
  //     if (keyValArr?.length > 0) {
  //       // clearSession();
  //       keyValArr?.map((i) => {
  //         if (sessionStorage?.getItem(i?.key) === null) {
  //           const isTwilio = i?.key.includes("twilio") || i?.key.includes("loglevel")
  //           if(isTwilio){

  //           }else {
  //             const isSG = sgArray.includes(i?.key);
  //             console.log('isSG :>> ', isSG, i?.key);
  //             if(isSG){
  //               const eData= encryptData(i?.val);
  //               console.log('i?.key 111:>> ', i?.key);
  //               sessionStorage.setItem(i?.key,eData);
  //             }else {
  //               console.log('i?.key 222:>> ', i?.key);
  //               setEncryptedID(i?.key, i?.val)
  //             }
  //           }
  //           // sessionStorage?.setItem(i?.key, i?.val);
  //         }
  //         localStorage?.removeItem(i?.key);
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }

  // }, [localStorage?.length]);

  // useEffect(() => {
  //   setContext((prev) => ({
  //     // UserAuth: {},
  //     searchDisplayOut: false,
  //     type: "",
  //     msg: "",
  //     isUserLoggedIn: isUserLoggedIn,
  //     isLoginPopUP: false,
  //     cartCount: "",
  //     searchBox: "",
  //     healthCardFrontId: "",
  //     healthCardFrontUrl: "",
  //     healthCardBackId: "",
  //     healthCardBackurl: "",
  //     healthcard_ref_id: "",
  //     phLength: 0,
  //   }));
  // }, [isUserLoggedIn]);

  // Google analytics page tracker
  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      searchBox: "",
    }));
  }, [location]);

  const sessionStorageTransfer = (event) => {
    try {
      // if (!event.newValue) return;
      if (!event) {
        event = window?.event;
      } // ie suq

      if (event.key == "getSessionStorage") {
        // another tab asked for the sessionStorage -> send it
        const encrypted = encryptData(JSON.stringify(sessionStorage) || "");
        localStorage.setItem("TAB_KEY", encrypted);
        // the other tab should now have it, so we're done with it.
        // localStorage.removeItem('TAB_KEY'); // <- could do short timeout as well.
        setTimeout(() => {
          localStorage.removeItem("TAB_KEY"); // <- could do short timeout as well.
        }, 5000);
      } else if (event.key == "TAB_KEY") {
        const data = JSONCheckerFunc(decryptData(event.newValue));
        for (const key in data) {
          sessionStorage.setItem(key, data[key]);
        }
        updateAuthContext();
      } else if (event.key == "CREDENTIALS_FLUSH") {
        window.sessionStorage.removeItem(sgUserId);
        window.sessionStorage.removeItem(USER_PROFILE);
        window.sessionStorage.removeItem(USER_LOGGED_IN);
        window.sessionStorage.removeItem(SG_ROLE_ID);
        window.sessionStorage.removeItem(SG_USER_ID);
        window.sessionStorage.removeItem(SG_PATIENT_ID);
        window.sessionStorage.removeItem(IS_INSURANCE);
        window.sessionStorage.removeItem(PROFILE_IMAGE);
        window.sessionStorage.removeItem(PROFILE_IMAGE2);
        window.sessionStorage.removeItem(USER_ID);
        window.sessionStorage.removeItem(QB_ID);
        window.sessionStorage.removeItem(DEFAULT_FLAG);
        window.sessionStorage.removeItem(PATIENT_ID);
        window.sessionStorage.removeItem(CART_COUNT);
        window.sessionStorage.removeItem(INSURANCE_FRONT_ID);
        window.sessionStorage.removeItem(INSURANCE_BACK_ID);
        window.sessionStorage.removeItem(HEALTH_CARD_FRONT_ID);
        window.sessionStorage.removeItem(HEALTH_BACK_ID);
        window.sessionStorage.removeItem(HEALTH_INFO);
        window.sessionStorage.removeItem(SIGN_IN_WITH);
        window.sessionStorage.removeItem(IS_DOCS_PENDING);
        window.sessionStorage.removeItem(FIRST_TIME_LOGIN);
        window.sessionStorage.removeItem(IS_PROFILE_UPDATED);
        window.sessionStorage.removeItem(IS_CHECKED);
        window.sessionStorage.removeItem(LOGINS_IN_BY);
        window.sessionStorage.removeItem(PASSWORD_MSG);
        window.sessionStorage.removeItem(PHONE_NO);
        window.sessionStorage.removeItem(CURRENT_SCREEN);
        window.sessionStorage.removeItem(FORGOT_PASSWORD_PHONE_NO);
        window.sessionStorage.removeItem(IS_EDITING);
        window.sessionStorage.removeItem(SUGGEST_US_INIT);
        window.sessionStorage.removeItem(ORG_NAME);
        window.sessionStorage.removeItem(ORG_CODE);
        window.sessionStorage.removeItem(ORG_CURRENCY);
        window.sessionStorage.removeItem(SG_ORG_ID);
        window.sessionStorage.removeItem(DOC_UPDATE);
        window.sessionStorage.removeItem(RX_CART_FLAG);
        window.sessionStorage.removeItem(CURRENT_ROUTE_FOR_UPLOAD_PRESCRIPTION);
        window.sessionStorage.removeItem(ORDER_DETAILS);
        window.sessionStorage.removeItem(C_PWD);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorageTransfer, false);
    }
    return () => {
      window.removeEventListener("storage", sessionStorageTransfer, false);
    };
  }, []);

  return (
    <APIContext.Provider value={[suggestUsInit, setSuggestUsInit]}>
      <UserAuthContext.Provider value={[context, setContext]}>
        <AppAlertContext.Provider value={[alertContext, setAlertContext]}>
          <GlobalContext.Provider value={[globalContext, setGlobalContext]}>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/log-in"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              >
                {/* <Route path="/log-in/:phNumber" element={<LogInPage />} /> */}
              </Route>
              <Route
                path="/createPassword"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/verify-Phone"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/verify-otp/phone"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/verify-otp/social-media"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/enterPassword"
                element={
                  <ProtectedRoute auth={!authed} redirectPath="/">
                    <LogInPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/sign-up" element={<SignUp />} />
              <Route
                path="/sign-up-vendor"
                element={
                  <BasicLayout>
                    <SignUpVendor />
                  </BasicLayout>
                }
              />
              {/* //// TEMP ROUTE FOR DEVELOPMENT OF PRODUCTCARDMERCHANT */}
              <Route
                path="/product-list-vendor"
                element={
                  <BasicLayout>
                    <ProductList />
                  </BasicLayout>
                }
              />
              <Route
                path="/summary"
                element={
                  <BasicLayout>
                    <Summary />
                  </BasicLayout>
                }
              />
              <Route
                path="/submit-rx"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <HeaderLayout>
                      <SubmitRxPage />
                    </HeaderLayout>
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/submit-rx/upload-rx"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <HeaderLayout>
                      <div className={`modalBackground_app openrx_model_app`} style={{ zIndex: "10009" }}>
                        <div className={`modalContainer_app`}>
                          <UploadRxPage isPopup={true} />
                        </div>
                      </div>
                    </HeaderLayout>
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/submit-rx/rx"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <HeaderLayout>
                      <RxContainer />
                    </HeaderLayout>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":rxType"
                  element={
                    <ProtectedRoute auth={authed} redirectPath="/">
                      <HeaderLayout>
                        <RxContainer />
                      </HeaderLayout>
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/product-list"
                element={
                  <BasicLayout>
                    <ProductListScreen />
                  </BasicLayout>
                }
              >
                <Route path=":type_id" element={<ProductListScreen />} />
              </Route>
              <Route
                path="/categories"
                element={
                  <BasicLayout>
                    <Categories />
                  </BasicLayout>
                }
              >
                {/* <Route path=":typeID" element={<ProductListScreen />} /> */}
              </Route>
              <Route
                path="/product-brand"
                element={
                  <BasicLayout>
                    <ProductListScreen />
                  </BasicLayout>
                }
              >
                <Route path=":manfID" element={<ProductListScreen />} />
              </Route>
              {/* Needs to check with this component whether this is needed or not */}
              <Route path="/cart" element={<CartScreen />} />
              <Route
                path="/wishlist"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyWishlist />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/product-detail"
                element={
                  <BasicLayout>
                    <ProductDetails />
                  </BasicLayout>
                }
              >
                <Route
                  path=":prodID"
                  element={
                    <BasicLayout>
                      <ProductDetails />
                    </BasicLayout>
                  }
                />
              </Route>
              <Route
                path="/my-rx"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyRx />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-rx-request"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyRxRequest />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/rx-page"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <Rxpage/>
                      </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-address"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyAddress />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              {/* Needs to check with this component whether this is required or not */}
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/my-orders"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyOrders />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-profile"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyProfile />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/checkout"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <Checkout />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/purchase"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <ProductPurchase />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":id"
                  element={
                    <ProtectedRoute auth={authed} redirectPath="/">
                      <BasicLayout>
                        <ProductPurchase />
                      </BasicLayout>
                    </ProtectedRoute>
                  }
                />
              </Route>
              {/* Needs to check with this component whether this is required or not */}
              <Route
                path="/payment"
                element={
                  <BasicLayout>
                    <Payment />
                  </BasicLayout>
                }
              />
              <Route
                path="/"
                element={
                  <BasicLayout>
                    <HomePage />
                    {/* <Landingpage /> */}
                  </BasicLayout>
                }
              />
              <Route
                path="/change-password"
                element={
                  <ProtectedRoute auth={chnagePswrdAuthed} redirectPath="/">
                    <BasicLayout>
                      <ChangePassword />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-health"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <HealthPage />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-health-card"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <HealthCard />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-insurance"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyInsurance />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              {/* <Route path="/about-us" element={<AboutUs />} /> */}
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/medex" element={<Medex/>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsConditions />}
              />
              <Route
                path="/manage-payments"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <ManagePayments />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Saved"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <SaveforlaterScreen />
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
              {/* Needs to check with the component whether it is required or not */}
              <Route
                path="/Order-detail"
                element={
                  <BasicLayout>
                    <OrderDetail />
                  </BasicLayout>
                }
              >
                <Route
                  path=":orderID"
                  element={
                    <BasicLayout>
                      <OrderDetail />
                    </BasicLayout>
                  }
                />
              </Route>
              <Route
                path="/my-list"
                element={
                  <ProtectedRoute auth={authed} redirectPath="/">
                    <BasicLayout>
                      <MyListPage>{/* <MyList /> */}</MyListPage>
                    </BasicLayout>
                  </ProtectedRoute>
                }
              />
            </Routes>
            {isUserLoggedIn ? (
              <FABV2 />
            ) : (
              <div className="ChatTwillioContainer">
                <ChatBot />
              </div>
            )}
          </GlobalContext.Provider>
        </AppAlertContext.Provider>
      </UserAuthContext.Provider>
    </APIContext.Provider>
  );
}
