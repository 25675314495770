import React from "react";

export type HeadingProps = {
  style?: object;
  onClick?: any;
  className?: string;
};

const Heading: React.FC<HeadingProps> = (props) => {
  const { style, className, onClick } = props;
  return (
    <h2 className={`${className}`} style={{ ...style }} onClick={onClick}>
      {props.children}
    </h2>
  );
};

export default Heading;
