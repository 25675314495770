import Styles from "./index.module.css";
import React, { useContext, useEffect } from "react";
import Button from "../Button";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../../config/label";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { GlobalContext } from "../../../store/global-context";
import { LOGINS_IN_BY } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
import { getDecryptedID, getID, setEncryptedID } from "../../../utils/utils";

export type ModalProps = {
    setModalOpen?: any;
    isProUser?: any;
};

const ConfirmProUserPopup: React.FC<ModalProps> = (props) => {
    const { setModalOpen, isProUser } = props;
    let navigate = useNavigate();
    const [context, setContext] = useContext(UserAuthContext);
    const [globalContext, setGlobalContext] = useContext(GlobalContext);
    const loggingBy =  getDecryptedID(LOGINS_IN_BY);

    const handleIfConfirm = ()=>{
        try {
            setModalOpen(false);    
            setEncryptedID(LOGINS_IN_BY, isProUser?.whoLogging)
            navigate("/enterPassword");
        } catch (error) {
            console.error(error);
        }
    }

    const handleIfCancel = ()=>{
        setModalOpen(false)
    }


    const handleClickButton =(e:any)=>{
        try {
            if(e.keyCode === 13){
                handleIfConfirm();
            }else if (e.keyCode === 27) {
                handleIfCancel();
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
            document.addEventListener("keydown", handleClickButton)
            return ()=>{
                document.removeEventListener("keydown", handleClickButton)
            }
    },[])


    return (
        <div className={Styles["modalBackground"]}>
            <div className={Styles["modalContainer"]}>
                <div
                    className={Styles["titleCloseBtn"]}
                    title="Close button"
                    onClick={() => setModalOpen(false)}
                >
                    <img src={CloseIcon} alt="Cross icon" draggable="false" />
                </div>
                <div className={Styles["title"]}>
                    <div className={Styles["checkIconWrapper"]}>
                        <img src={Doctorclipart} alt="Cart icon" draggable="false" />
                    </div>
                    <Heading className={`${Styles?.page_heading}`}>
                        This phone number is already associated with {isProUser?.msg} user. Do you wish to continue?
                    </Heading>
                    <p></p>
                </div>
                <div className={Styles["buttons"]}>
                    <Button className={Styles["cancel_btn"]}
                        type={Config?.lbl_Btn_type_ghost}
                        size={Config?.lbl_Btn_size_medium}
                        onClick={() => setModalOpen(false)}
                    >
                        {Config?.lbl_land_page_cancel}
                    </Button>
                    <Button
                        type={Config?.lbl_Btn_type_primary}
                        size={Config?.lbl_Btn_size_medium}
                        onClick={() => {
                            handleIfConfirm();
                        }}
                    >
                        {Config?.lbl_otp_confirm}
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default ConfirmProUserPopup;
