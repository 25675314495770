import { googleMap } from "../../../../../utils/appIcons";
import SelectDropdown from "../../../../../components/UI/SelectDropdown";
import Label from "../../../../../components/UI/Label";
import LinkButton from "../../../../../components/UI/LinkButton";
import { getInitials } from "../../../../../utils/utils";
import Initial from "../../../../../components/UI/Initial";
import s from "./index.module.css";
import Config from "../../../../../config/label";
import GoogleMap from "../../../../../components/UI/GoogleMap";
import { useContext, useEffect, useRef, useState } from "react";
import APIContext from "../../../../../store/api-context";
import { CallAPI } from "../../../../../suggest-us/callSuggest.js";
import { config } from "../../../../../config/processId.js";
import { useLocation } from "react-router-dom";
import { msg_config } from "../../../../../config/messages";
import Input from "../../../../../components/UI/Input";
import LoadingCircle from "../../../../../components/LoadingCircle";
import { UserAuthContext } from "../../../../../store/UserAuthContext";
import { debounce } from "lodash";
import { FormInput } from "../../../../../components/UI/FormInput";
import { PhoneFormInput } from "../../../../../components/UI/PhoneFormInput";
import { useForm } from "react-hook-form";
import LocationSearchInput from "../../../../../components/LocationSearchInput";

export type RegistrationFormFields = {
  pharmacyName: string;
  phone: string;
  location: string;
};

const Rx = (props: any) => {
  const {
    activeStep,
    pharmacyType,
    setPharmacyType,
    setPharmacyId,
    setAddPharmacyAddress,
    addPharmacyAddress,
    rxContext,
    setRxContext,
    register,
    control,
    errors,
    setValue,
    watch,
    clearErrors,
    setError,
  } = props;

  const [suggestUsInit] = useContext<any>(APIContext);
  const [displayOut, setDisplayOut] = useState(false);
  const [searchText, setSearchText] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pharmaDetails, setPharmaDetails] = useState<any>({});
  const [addressDetails, setaddressDetails] = useState([]);
  const [nameValue, setNameValue] = useState("");
  const [isPharma, setIsPharma] = useState(false);
  const [first, setfirst] = useState({ lat: 0, lng: 0 });
  const location = useLocation();
  const refPharmacySearch: any = useRef(null);
  const phoneInputRef = useRef<any>(null);

  const handleClickSearchOutside = (event: any) => {
    if (
      refPharmacySearch.current &&
      !refPharmacySearch.current.contains(event.target)
    ) {
      if (event.target.id === "pharmaSearch") {
        setDisplayOut(true);
      } else {
        setDisplayOut(false);
      }
    }
  };

  useEffect(() => {
    if (
      rxContext.pharmacyData !== "" &&
      typeof rxContext.pharmacyData !== "undefined"
    ) {
      setIsPharma(true);
      clearErrors("pharmacyName");
      clearErrors("phone");
      clearErrors("location");
      setNameValue(rxContext.pharmacyData?.pharmacyName);
      setValue("pharmacyName", rxContext.pharmacyData?.pharmacyName);
      setValue("phone", rxContext.pharmacyData?.phone);
      setValue("location", rxContext.pharmacyData?.location);
    }
  }, [rxContext]);

  useEffect(() => {
    if (displayOut)
      document.addEventListener("click", handleClickSearchOutside, true);
    return () => {
      document.removeEventListener("click", handleClickSearchOutside, true);
    };
  }, [displayOut]);

  const action = (val: any) => {
    if (val !== "") {
      setSearchText([]);
      setIsLoading(true);
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.transferRx_search_pharmacy_list_process_id, {
          p_search_text: val,
          p_max_offset: "10",
        }).then((res) => {
          if (res?.returnCode) {
            setSearchText([...res?.returnData]);
            setIsLoading(false);
          }
        });
      }
    }
  };
  const debounceSearch = debounce(action, 600);

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setRxContext((prev: any) => ({
        ...prev,
        pharmacy: "",
      }));
      setIsPharma(false);
    } else {
      debounceSearch(e?.target?.value);
      setRxContext((prev: any) => ({
        ...prev,
        pharmacy: e?.target?.value,
      }));
      setSearchText([]);
      setIsLoading(true);
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onNameInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setNameValue("");
      setValue("name", "");
    } else if (fieldName === "email") {
      let newString = cleanInput(e.target.value);

      setNameValue(newString);
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setNameValue(newString);
    } else {
      setNameValue(e.target.value);
      setValue("name", e.target.value);
    }
  };

  const handlePharmacyClick = (pharma: any) => {
    setfirst({ lat: Number(pharma?.latitude), lng: Number(pharma?.longitude) });
    setPharmaDetails(pharma);
    setRxContext((prev: any) => ({
      ...prev,
      pharmacy: pharma?.name,
    }));
    setPharmacyType("Select");
    setPharmacyId(pharma?.id);
    clearErrors("pharmacyName");
    clearErrors("phone");
    clearErrors("location");
    setValue("pharmacyName", pharma?.name);
    setValue("phone", pharma?.phone_number);
    setValue("location", pharma?.location);
    setIsPharma(true);
  };

  const handleAddPharmacy = () => {
    setRxContext((prev: any) => ({
      ...prev,
      pharmacy: "Other Pharmacy",
    }));
    setPharmacyType("Add");
    clearErrors("pharmacyName");
    clearErrors("phone");
    clearErrors("location");
    setValue("pharmacyName", "");
    setValue("phone", "");
    setValue("location", "");
    setIsPharma(true);
  };

  // let long = "";
  // let lat = "";
  // const center = {
  //   lat: lat,
  //   lng: long,
  // };
  // const showPosition = (position: any) => {
  //   // long = position?.coords?.longitude;
  //   // lat = position?.coords?.latitude;
  //   center.lat = position?.coords?.latitude;
  //   center.lng = position?.coords?.longitude;
  //   // setCenter({
  //   //   lat: position?.coords?.latitude,
  //   //   lng: position?.coords?.longitude,
  //   // });
  // };
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   }
  // }, [navigator.geolocation]);

  const center = {
    lat: first?.lat,
    lng: first?.lng,
  };

  return (
    <>
      <div className={s?.ratioBox}>
        <GoogleMap
          height="150px"
          first={first}
          setfirst={setfirst}
          center={center}
        />
      </div>

      <div className={`${s?.form_inner_control}`}>
        <div className={`${s?.form_inner_label}`}>
          {/* <Label className={`${s?.form_label}`} for="firstName">
            {Config?.transferRx_Label}
          </Label> */}
          {/* <LinkButton
            // to="/submit-rx/rx/new"
            to={location?.pathname + location?.search}
            onClick={() => setIsAddPharmacy(true)}
            draggable="false"
          >
            {Config?.transferRx_Lnk_Title}
          </LinkButton> */}
        </div>
        <div className={s["headerSearchbox"]}>
          <div className={s["headerSearchbar"]}>
            <Input
              name="pharmaSearch"
              id="pharmaSearch"
              type="pharmasearch"
              placeholder={Config?.transferRx_Select_Placeholder}
              size="medium"
              value={rxContext?.pharmacy}
              maxLength={50}
              onChange={(e: any) => {
                onInputChangeHandler(e, "");
              }}
              onFocus={() => {
                setRxContext((prev: any) => ({
                  ...prev,
                  pharmacy: "",
                }));
                setDisplayOut(true);
                setIsPharma(false);
              }}
            />
            {rxContext?.pharmacy !== "" && displayOut && (
              <div ref={refPharmacySearch}>
                <div className={`${s?.searchDropDown} `}>
                  <ul>
                    <li className={`${s?.searchDropDownItem} ${s?.disabled}`}>
                      <div></div>
                    </li>
                    {searchText?.length > 0 ? (
                      <>
                        <li
                          key={"AddOther"}
                          className={`${s?.searchDropDownItem}`}
                          onClick={() => {
                            handleAddPharmacy();
                            setDisplayOut(false);
                          }}
                        >
                          <div className={s?.option}>
                            <Initial>{`+`}</Initial>
                            <span className={`${s?.opText}`}>
                              {Config?.transferRx_Add_Pharmacy_Placeholder}
                            </span>
                          </div>
                        </li>
                        {searchText?.map((item: any) => (
                          <li
                            key={item?.srv_id}
                            className={`${s?.searchDropDownItem}`}
                            onClick={() => {
                              handlePharmacyClick(item);
                              setDisplayOut(false);
                            }}
                          >
                            <div className={s?.option}>
                              <Initial>{getInitials(`${item?.name}`)}</Initial>
                              <span className={`${s?.opText}`}>
                                {item?.name}
                              </span>
                              <span className={`${s?.locationText}`}>
                                {item?.location}
                              </span>
                            </div>
                          </li>
                        ))}
                      </>
                    ) : isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingCircle loadingColor={"#2d4266"} />
                      </div>
                    ) : (
                      /* <div
                        style={{ textAlign: "center" }}
                        className={`${s?.noProductsFound}`}
                      >
                        No pharmacy found
                      </div> */
                      <li
                        key={"AddOther"}
                        className={`${s?.searchDropDownItem}`}
                        onClick={() => {
                          handleAddPharmacy();
                          setDisplayOut(false);
                        }}
                      >
                        <div className={s?.option}>
                          <Initial>{`+`}</Initial>
                          <span className={`${s?.opText}`}>
                            {Config?.transferRx_Add_Pharmacy_Placeholder}
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isPharma &&
        (pharmacyType === "Select" ? (
          <>
            <div className={`${s?.form_control}`}>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="pharmacyName">
                  {Config?.Pharmacy_label}
                </Label>
                <FormInput<RegistrationFormFields>
                  name="pharmacyName"
                  id="pharmacyName"
                  type="text"
                  placeholder={Config?.Pharmacy_placeholder}
                  size="small"
                  register={register}
                  value={pharmaDetails?.name}
                  errors={errors}
                  disabled
                />
              </div>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="GeneralPractitioner">
                  {Config?.Pharmacy_Contact_no}
                </Label>
                <PhoneFormInput<RegistrationFormFields>
                  name="phone"
                  placeholder={Config?.Pharmacy_Contact_no_placeholder}
                  register={register}
                  errors={errors}
                  control={control}
                  phoneInputRef={phoneInputRef}
                  clearErrors={clearErrors}
                  setError={setError}
                  disabled
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Label className={s["form_label"]} for="pharmacyLocation">
                {Config?.Pharmacy_location_label}
              </Label>
              <FormInput<RegistrationFormFields>
                name="location"
                id="location"
                type="text"
                placeholder={Config?.Pharmacy_location_placeholder}
                size="small"
                register={register}
                value={pharmaDetails?.location}
                errors={errors}
                disabled
              />
            </div>
          </>
        ) : (
          <>
            <div className={`${s?.form_control}`}>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="pharmacyName">
                  {Config?.Pharmacy_label}
                </Label>
                <FormInput
                  name="pharmacyName"
                  id="pharmacyName"
                  type={Config?.lbl_Inp_type_text}
                  placeholder={Config?.Pharmacy_placeholder}
                  size={Config?.lbl_Inp_size_small}
                  register={register}
                  value={nameValue}
                  rules={{
                    required: msg_config?.trnRx_pharmacy_required,
                  }}
                  errors={errors}
                  maxLength={100}
                  onChange={onNameInputChangeHandler}
                />
              </div>
              <div
                className={`${s?.form_inner_inner_control}`}
                style={{ marginTop: "20px" }}
              >
                <Label className={s["form_label"]} for="GeneralPractitioner">
                  {Config?.Pharmacy_Contact_no}
                </Label>
                <PhoneFormInput
                  name="phone"
                  placeholder={Config?.Pharmacy_Contact_no_placeholder}
                  register={register}
                  errors={errors}
                  control={control}
                  maxLength={15}
                  phoneInputRef={phoneInputRef}
                  clearErrors={clearErrors}
                  setError={setError}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Label className={s["form_label"]} for="pharmacyLocation">
                {Config?.Pharmacy_location_label}
              </Label>
              <LocationSearchInput
                name="location"
                placeholder={Config?.Pharmacy_location_placeholder}
                size="small"
                setState={setAddPharmacyAddress}
                state={addPharmacyAddress}
                setaddressDetails={setaddressDetails}
                rules={{ required: msg_config?.trnRx_location_required }}
                register={register}
                setValue={setValue}
                watch={watch}
                clearErrors={clearErrors}
                errors={errors}
                isFormattedAddress={true}
                maxLength={150}
              />
            </div>
          </>
        ))}
    </>
  );
};

export default Rx;
