import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../components/UI/Alert";
import Button from "../../../components/UI/Button";
import Modal from "../../../components/UI/VerificationModal";
import btnConfig from "../../../config/button";
import Config from "../../../config/label";
import { config } from "../../../config/processId.js";
import { siteConfig } from "../../../config/site";
import APIContext from "../../../store/api-context";
import { UserAuthContext } from "../../../store/UserAuthContext.js";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { RightArrow } from "../../../utils/appIcons";
import useQuery from "../../../utils/useQuery";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, objEncrypt, setEncryptedID } from "../../../utils/utils";
import AddPharmacy from "./AddPharmacy";
import AddPractitioner from "./AddPractitioner";
import s from "./index.module.css";
import ProfilePopup from "../../Landingpage/ProfilePopup";
import RxSteps from "./RxSteps";
import TransferRxForm from "./TransferRxForm";
import NewRxForm from "./NewRxForm";
import { USER_PROFILE } from "../../../config/Config";

const RxContainer = () => {
  let navigate = useNavigate();
  const { rxType } = useParams();

  const [activeTab, setActiveTab] = useState(rxType);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddPractitioner, setIsAddPractitioner] = useState(false);
  const [addPractitionerAlert, setAddPractitionerAlert] = useState(false);
  const [isAddPharmacy, setIsAddPharmacy] = useState(false);
  const [pharmacyType, setPharmacyType] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [addPharmacyAddress, setAddPharmacyAddress] = useState({ address: "" });
  const [practitionerType, setPractitionerType] = useState("");
  const [practitionerId, setPractitionerId] = useState("");
  const [addPractitionerAddress, setAddPractitionerAddress] = useState({
    address: "",
  });
  const [pracData, setPracData] = useState<any>({});
  const [pharmaData, setPharmaData] = useState<any>({});
  const [pharmacyList, setPharmacyList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState<any>(null);
  const [isSigned, setIsSigned] = useState(false);
  const [generatedRx, setGeneratedRx] = useState<any>("");
  const [practitioner, setPractitioner] = useState("");
  const [pharmacy, setPharmacy] = useState<any>(null);
  const [otherPatientParnerID, setOtherPatientParnerID] = useState("");
  const [consentFullName, setConsentFullName] = useState("");
  const [practitionerList, setPractitionerList] = useState<any>([]);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [context, setContext] = useContext(UserAuthContext);
  const [rxContext, setRxContext] = useState<any>({});
  const [patientDetails, setPatientDetails] = useState({});
  const [dob, setdob] = useState<any>(null);
  const [dobErrorMsg, setDobErrorMsg] = useState(false);
  const [healthDetails, setHealthDetails] = useState<any>({
    card_num: "",
    issue_date: "",
    exp_date: "",
    front_url: "",
    front_img_id: "",
    back_url: "",
    back_img_id: "",
    healthcard_ref_id: "",
  });
  const [uploadedDocumentIDFront, setUploadedDocumentIDFront] =
    useState<any>(null);
  const [uploadedDocumentIDBack, setUploadedDocumentIDBack] =
    useState<any>(null);

  const [alert, setAlert] = useState<any>({
    active: false,
    type: "success",
    msg: "",
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    resetField,
    setError,
    clearErrors,
  } = useForm();

  const patientWatch = watch("patient");

  const _user_profile: any = getDecryptedID(USER_PROFILE);
  // const _user_profile = JSONCheckerFunc(user_profile);

  const [suggestUsInit] = useContext<any>(APIContext);

  const query = useQuery();
  const queryStatus = query.get("status");

  const rx_Tab_url__1 = Config?.rx_Tab_url__1.substring(
    0,
    Config.rx_Tab_url__1.lastIndexOf("?") + 0
  );

  const rx_Tab_url__2 = Config?.rx_Tab_url__2.substring(
    0,
    Config.rx_Tab_url__2.lastIndexOf("?") + 0
  );

  useEffect(() => {
    if (generatedRx?.returnCode) {
      setIsModalOpen(true);
    }
  }, [generatedRx]);

  useEffect(() => {
    window.scroll(0, 0);
    switch (activeTab) {
      case "new":
        return navigate(`/submit-rx/rx/${Config?.rx_Tab_url__1}`);
      case "transfer":
        return navigate(`/submit-rx/rx/${Config?.rx_Tab_url__2}`);
      default:
        break;
    }
  }, [activeTab]);

  const getRxSteps = () => {
    switch (activeTab) {
      case `${rx_Tab_url__1}`:
        return newRxsteps;
      case `${rx_Tab_url__2}`:
        return transferRxsteps;
      default:
        break;
    }
  };

  const setBackSteps = () => {
    switch (queryStatus) {
      case "submit":
        setRxContext((prev: any) => ({
          ...prev,
          pharmacy: "",
        }));
        setRxContext((prev: any) => ({
          ...prev,
          pharmacyData: "",
        }));
        setRxContext((prev: any) => ({
          ...prev,
          practitioner: "",
        }));
        setRxContext((prev: any) => ({
          ...prev,
          practitionerData: "",
        }));
        navigate(`/submit-rx`);
        break;
      case "patient-details":
        navigate(
          `/submit-rx/rx/${
            rxType === "new" ? rx_Tab_url__1 : rx_Tab_url__2
          }?status=submit`
        );
        break;

      /*case 'consent':
        navigate(
          `/submit-rx/rx/${
            rxType === 'new' ? rx_Tab_url__1 : rx_Tab_url__2
          }?status=patient-details`
        );
        break;*/

      default:
        break;
    }
  };

  const setNextSteps = () => {
    switch (queryStatus) {
      case "submit":
        navigate(
          `/submit-rx/rx/${
            rxType === "new" ? rx_Tab_url__1 : rx_Tab_url__2
          }?status=patient-details`
        );
        break;
      case "patient-details":
        navigate(
          `/submit-rx/rx/${
            rxType === "new" ? rx_Tab_url__1 : rx_Tab_url__2
          }?status=consent`
        );
        break;

      /*case 'consent':
        setIsModalOpen(true);
        break;*/

      default:
        break;
    }
  };

  // New Rx Number of Steps and it's details

  const newRxsteps = [
    {
      label: `${Config?.newRx_stepper_Heading__1}`,
      description: `${Config?.newRx_stepper_detail__1}`,
    },
    {
      label: `${Config?.newRx_stepper_Heading__2}`,
      description: `${Config?.newRx_stepper_detail__2}`,
    },
    // {
    //   label: `${Config?.newRx_stepper_Heading__3}`,
    //   description: `${Config?.newRx_stepper_detail__3}`,
    // },
    {
      label: `${Config?.newRx_stepper_Heading__4}`,
      description: `${Config?.newRx_stepper_detail__4}`,
    },
  ];

  // Transfer Rx Number of Steps and it's details

  const transferRxsteps = [
    {
      label: `${Config?.transferRx_stepper_Heading__1}`,
      description: `${Config?.transferRx_stepper_detail__1}`,
    },
    {
      label: `${Config?.transferRx_stepper_Heading__2}`,
      description: `${Config?.transferRx_stepper_detail__2}`,
    },
    /*{
      label: `${Config?.transferRx_stepper_Heading__3}`,
      description: `${Config?.transferRx_stepper_detail__3}`,
    },*/
    {
      label: `${Config?.transferRx_stepper_Heading__4}`,
      description: `${Config?.transferRx_stepper_detail__4}`,
    },
  ];

  const primaryBtnText = () => {
    switch (rxType) {
      case "new":
        return btnConfig?.newRx_Primary_Text_V1;
      case "transfer":
        return btnConfig?.transferRx_Primary_Text_V1;
      default:
        break;
    }
    return "";
  };
  const ghostBtnText = () => {
    switch (rxType) {
      case "new":
        return btnConfig?.newRx_Ghost_Text_V1;
      case "transfer":
        return btnConfig?.transferRx_Ghost_Text_V1;
      default:
        break;
    }
    return "";
  };

  const updateSelfPatientDetails = (data: any) => {
    setAlert((prev: any) => {
      return {
        ...prev,
        active: false,
        type: "",
        msg: "",
      };
    });
    if (
      healthDetails?.front_img_id === "" ||
      healthDetails?.front_img_id === null ||
      healthDetails?.front_img_id === undefined ||
      uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id === "" ||
      uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id === null ||
      uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id === undefined
      // details?.front_img_id === "" &&
      // uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id === undefined
    ) {
      // setAlert(true)
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload your health card",
        };
      });
    } else if (
      healthDetails?.back_img_id === "" ||
      healthDetails?.back_img_id === null ||
      healthDetails?.back_img_id === undefined ||
      uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id === "" ||
      uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id === null ||
      uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id === undefined
    ) {
      setAlert((prev: any) => {
        return {
          ...prev,
          active: true,
          type: "error",
          msg: "Please upload your health card",
        };
      });
    }
    if (dob === "Invalid date" || dob === "" || dob === undefined) {
      setDobErrorMsg(true);
    } else if (suggestUsInit?.returnCode) {
      setDobErrorMsg(false);
      setLoading(true);
      CallAPI(config?.newRx_update_patient_process_id, {
        p_patient_id: _user_profile?.patient_id,
        p_patmas_gender_code: data?.gender?.value,
        p_patmas_dob: moment(data?.dob).format("YYYY-MM-DD"),
        p_user_id: _user_profile?.usr_id,
        p_patient_first_name: data?.firstName,
        p_patient_last_name: data?.lastName,
      }).then((res) => {
        if (res?.returnCode) {
          setEncryptedID(USER_PROFILE,res?.returnData[0]);
          setAlert((prev: any) => {
            return { ...prev, active: true, type: "success", msg: res?.msg };
          });
          // setLoading(false);
          // setNextSteps();
          switch (rxType) {
            case "new":
              if (practitionerType === "Add") {
                addPractitionerHandler();
              } else {
                generateRx("", "");
              }
              break;
            case "transfer":
              if (pharmacyType === "Add") {
                addPharmacyHandler();
              } else {
                generateRx("", "");
              }
              break;
          }
          onSubmitHealthCardHandler(data);
        } else {
          setAlert((prev: any) => {
            return { ...prev, active: true, type: "error", msg: res?.msg };
          });
          setLoading(false);
        }
      });
    }

    // if (suggestUsInit?.returnCode) {

    // }
  };

  const updateOtherPatientDetails = (data: any) => {
    if (suggestUsInit?.returnCode) {
      setLoading(true);
      CallAPI(config?.newRx_save_other_patient_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_patient_id: _user_profile?.patient_id,
        p_patmas_first_name: data?.p_firstName,
        p_patmas_last_name: data?.p_lastName,
        p_patmas_gender_code: data?.p_gender?.value,
        p_patmas_dob: moment(data?.p_dob).format("YYYY-MM-DD"),
      }).then((res) => {
        if (res?.returnCode) {
          setOtherPatientParnerID(res?.returnData[0]?.patient_partner_id);
          setAlert((prev: any) => {
            return { ...prev, active: true, type: "success", msg: res?.msg };
          });
          // setLoading(false);
          // setNextSteps();
          generateRx("", "");
        } else {
          setAlert((prev: any) => {
            return { ...prev, active: true, type: "error", msg: res?.msg };
          });
          setLoading(false);
        }
      });
    }
  };

  const updatePatientDetails = (e: any) => {
    const { patient } = e;

    switch (patient) {
      case "self":
        updateSelfPatientDetails(e);

        break;
      case "other":
        updateOtherPatientDetails(e);
        break;

      default:
        break;
    }
  };

  // const uploadFileDataAxios = (data: any) => {
  //   return axios({
  //     method: "POST",
  //     data,
  //     url: "https://phill-dev.speedum.tech/upload_files/upload_by_parts.php",
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });
  // };

  // async function rxUploadSignature(filename: any) {
  //   if (suggestUsInit?.returnCode === true) {
  //     return await CallAPI(config?.rx_upload_process_id, {
  //       p_patient_id: _user_profile?.patient_id,
  //       p_usr_id: _user_profile?.usr_id,
  //       p_doc_name: filename,
  //       p_doc_remarks: "",
  //       p_doctype_code: config?.upload_p_doctype_code,
  //       p_trnt_code: config?.newRX_sig_p_trnt_code,
  //       p_doc_transaction_id: "",
  //       p_doc_path: "",
  //       p_doc_id: "",
  //       p_docsubtype_code: config?.newRx_sig_p_doctype_code,
  //       p_doc_uploaded_filename: filename,
  //       p_userdata_patient_id: "",
  //       p_internal_flag: "",
  //     });
  //   }
  // }

  // async function callUploadSignature(urlData: any) {
  //   Promise.resolve(rxUploadSignature(urlData)).then((res) => {
  //     if (res?.returnCode) {
  //       generateRx(res?.returnData[0]?.document_id);
  //     }
  //   });
  // }

  const onGenerateRxComplete = (res: any) => {
    setRxContext((prev: any) => ({
      ...prev,
      pharmacy: "",
    }));
    setRxContext((prev: any) => ({
      ...prev,
      pharmacyData: "",
    }));
    setRxContext((prev: any) => ({
      ...prev,
      practitioner: "",
    }));
    setRxContext((prev: any) => ({
      ...prev,
      practitionerData: "",
    }));
    setLoading(false);
    setGeneratedRx(res);
  };

  const generateRx = (pracId: any, pharmaId: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.generate_rx_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_document_ids: "",
        p_customer_id: _user_profile?.customer_id,
        p_rx_type: rxType === "new" ? "NEW" : "TRANS",
        p_rx_patient_id:
          patientWatch === "self"
            ? _user_profile?.patient_id
            : otherPatientParnerID,
        p_resource_id: rxType === "new" ? practitionerId || pracId : "",
        p_pharmacy_id: rxType === "transfer" ? pharmacyId || pharmaId : "",
        p_signature_document_id: "",
        p_rx_patient_type: patientWatch,
        p_internal_flag: "",
      }).then((res) => {
        if (res?.returnCode) {
          if (res?.returnData?.length > 0) {
            onGenerateRxComplete(res);
          }
        }
      });
    }
  };

  const onSubmitHealthCardHandler = (data: any) => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.add_health_card_process_id, {
        p_id:
          healthDetails?.healthcard_ref_id !== ""
            ? healthDetails?.healthcard_ref_id
            : "",
        p_user_id: _user_profile?.usr_id,
        p_customer_id: _user_profile?.customer_id,
        p_healthcard_no: data?.card_num || "",
        p_healthcard_issue_date:
          data?.issue_date !== undefined
            ? moment(data?.issue_date).format("YYYY-MM-DD")
            : "",
        p_healthcard_expiry_date:
          data?.exp_date !== undefined
            ? moment(data?.exp_date).format("YYYY-MM-DD")
            : "",
        p_healthcard_front_image_document_id:
          healthDetails?.front_img_id ||
          uploadedDocumentIDFront?.[0]?.returnData[0]?.document_id ||
          "",
        p_healthcard_back_image_document_id:
          healthDetails?.back_img_id ||
          uploadedDocumentIDBack?.[0]?.returnData[0]?.document_id ||
          "",
        p_internal_flag: "",
      }).then((res) => {
        if (res?.returnData) {
          setContext((prev: any) => ({
            ...prev,
            healthCardFrontId: "",
            healthCardFrontUrl: "",
            healthCardBackId: "",
            healthCardBackurl: "",
            healthcard_ref_id: "",
          }));
        }
      });
    }
  };

  // const onSetConsentFullName = (consentFullName: any) => {
  //   const [firstName, lastName] =
  //     consentFullName && consentFullName?.trim()?.split(" ");

  //   if (suggestUsInit?.returnCode) {
  //     setLoading(true);
  //     CallAPI(config?.newRx_update_patient_process_id, {
  //       p_patient_id: _user_profile?.patient_id,
  //       p_patmas_gender_code: "",
  //       p_patmas_dob: "",
  //       p_user_id: _user_profile?.usr_id,
  //       p_patient_first_name: firstName,
  //       p_patient_last_name: lastName,
  //     }).then((res) => {
  //       if (res?.returnCode) {
  //         localStorage.setItem(
  //           USER_PROFILE,
  //           objEncrypt(JSON.stringify(res?.returnData[0]))
  //         );
  //         setAlert((prev: any) => {
  //           return { ...prev, active: true, type: "success", msg: res?.msg };
  //         });
  //         setLoading(false);
  //       } else {
  //         setAlert((prev: any) => {
  //           return { ...prev, active: true, type: "error", msg: res?.msg };
  //         });
  //         setLoading(false);
  //       }
  //     });
  //   }
  // };

  const onNewRxContinue = (e: any) => {
    switch (queryStatus) {
      case "submit":
        setNextSteps();
        break;

      case "patient-details":
        updatePatientDetails(e);
        break;

      // case "consent":
      //   if (isSigned && consentFullName) {
      //     if (
      //       patientWatch === "other" &&
      //       _user_profile?.usr_name.trim() === ""
      //     ) {
      //       onSetConsentFullName(consentFullName);
      //     }
      //     setLoading(true);
      //     fetch(imageURL)
      //       .then((res) => res.blob())
      //       .then((blob) => {
      //         const file = new File([blob], "image", { type: "image/png" });
      //         let formData = new FormData();
      //         formData.append("uploadedFile", file);
      //         Promise.resolve(uploadFileDataAxios(formData)).then((res) => {
      //           if (res?.data?.result === "true") {
      //             callUploadSignature(res?.data?.url);
      //           }
      //         });
      //       });
      //   } else {
      //     setAlert((prev: any) => {
      //       return {
      //         ...prev,
      //         active: true,
      //         type: "warning",
      //         msg: "Please complete your signature",
      //       };
      //     });
      //     setTimeout(() => {
      //       setAlert((prev: any) => {
      //         return {
      //           ...prev,
      //           active: false,
      //         };
      //       });
      //     }, 5000);
      //   }
      //   break;

      default:
        break;
    }
  };

  const onTransferRxContinue = (e: any) => {
    switch (queryStatus) {
      case "submit":
        setNextSteps();
        break;

      case "patient-details":
        updatePatientDetails(e);
        break;

      // case "consent":
      //   if (isSigned && consentFullName) {
      //     if (patientWatch === "other") {
      //       onSetConsentFullName(consentFullName);
      //     }
      //     setLoading(true);
      //     fetch(imageURL)
      //       .then((res) => res.blob())
      //       .then((blob) => {
      //         const file = new File([blob], "image", { type: "image/png" });
      //         let formData = new FormData();
      //         formData.append("uploadedFile", file);

      //         Promise.resolve(uploadFileDataAxios(formData)).then((res) => {
      //           if (res?.data?.result === "true") {
      //             callUploadSignature(res?.data?.url);
      //           }
      //         });
      //       });
      //   } else {
      //     setAlert((prev: any) => {
      //       return {
      //         ...prev,
      //         active: true,
      //         type: "warning",
      //         msg: "Please complete your signature",
      //       };
      //     });
      //     setTimeout(() => {
      //       setAlert((prev: any) => {
      //         return {
      //           ...prev,
      //           active: false,
      //         };
      //       });
      //     }, 5000);
      //   }
      //   break;

      default:
        break;
    }
  };

  const addPharmacyHandler = () => {
    setAlert((prev: any) => {
      return { ...prev, active: false, type: "", msg: "" };
    });
    if (suggestUsInit?.returnCode === true && addPharmacyAddress?.address) {
      setLoading(true);
      CallAPI(config?.transferRx_pharmacy_add_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_name: pharmaData?.pharmacyName,
        p_location: addPharmacyAddress?.address,
        p_phone_number: pharmaData?.phone,
      }).then((res) => {
        if (res?.returnCode) {
          setAlert((prev: any) => {
            return {
              ...prev,
              active: true,
              type: "success",
              msg: res?.msg,
            };
          });
          const ID = res?.returnData.filter(
            (pharma: any) =>
              pharma?.name === pharmaData?.pharmacyName &&
              pharma?.phone_number === pharmaData?.phone &&
              pharma?.location === addPharmacyAddress?.address
          )[0]?.id;
          setPharmacyId(ID);
          setLoading(false);
          generateRx("", ID);
        } else {
          setAlert((prev: any) => {
            return {
              ...prev,
              active: true,
              type: "error",
              msg: res?.msg,
            };
          });
          setLoading(false);
        }
      });
    }
  };

  const addPractitionerHandler = () => {
    if (suggestUsInit?.returnCode === true && addPractitionerAddress?.address) {
      setLoading(true);
      CallAPI(config?.newRx_practitioner_add_process_id, {
        p_usr_id: _user_profile?.usr_id,
        p_resource_name: pracData?.practitionerName,
        p_resource_location: addPractitionerAddress?.address,
        p_resource_phone_no: pracData?.phone,
        p_sgusr_id: _user_profile?.usr_id,
      }).then((res) => {
        if (res?.returnCode) {
          const ID = res?.returnData.filter(
            (doc: any) =>
              doc?.resource_name === pracData?.practitionerName &&
              doc?.resource_phone_no === pracData?.phone &&
              doc?.resource_location === addPractitionerAddress?.address
          )[0]?.resource_id;
          //setAddPractitionerAlert(true);
          setPractitionerId(ID);
          setLoading(false);
          generateRx(ID, "");
        } else {
          setAlert((prev: any) => {
            return {
              ...prev,
              active: true,
              type: "error",
              msg: res?.msg,
            };
          });
          setLoading(false);
        }
      });
    }
  };

  const onSubmitHandler = (data: any) => {
    switch (rxType) {
      case "new":
        if (practitionerType === "Add") {
          if (
            rxContext.practitionerData?.pharmacyName !==
              data?.practitionerName &&
            rxContext.practitionerData?.location !==
              addPractitionerAddress?.address
          ) {
            setPracData(data);
            setRxContext((prev: any) => ({
              ...prev,
              practitionerData: data,
            }));
          }
        }
        onNewRxContinue(data);
        break;
      case "transfer":
        if (pharmacyType === "Add") {
          if (
            rxContext.pharmacyData?.pharmacyName !== data?.pharmacyName &&
            rxContext.pharmacyData?.location !== addPharmacyAddress?.address
          ) {
            setPharmaData(data);
            //addPharmacyHandler(data);
            setRxContext((prev: any) => ({
              ...prev,
              pharmacyData: data,
            }));
          }
        }
        if (siteConfig?.check_Profile === "Y") {
          if (
            _user_profile?.usr_email === "" ||
            _user_profile?.usr_name === "" ||
            _user_profile?.usr_phone === "" ||
            _user_profile?.usr_dob === ""
          ) {
            setIsProfilePopup(true);
          } else {
            onTransferRxContinue(data);
          }
        } else {
          onTransferRxContinue(data);
        }
        break;
      default:
        break;
    }
  };

  const addPractitioner = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.newRx_practitioner_list_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setPractitionerList([...res?.returnData]);
        }
      });
    }
  };

  const addPharmacy = () => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.transferRx_pharmacy_list_process_id, {}).then((res) => {
        if (res?.returnCode) {
          setPharmacyList([...res?.returnData]);
        }
      });
    }
  };

  return (
    <div className={`${s?.Container}`}>
      <div className={`${s?.LeftContainer}`}>
        <RxSteps steps={getRxSteps()} activeStep={activeStep} />
      </div>
      <div className={`${s?.RightContainer}`}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {/* <Tabs
            active={activeTab}
            setActive={setActiveTab}
            ulStyle={{
              width: "100%",
              justifyContent: "start",
              paddingLeft: "25px",
            }}
            bottomBorderStyle={{
              width: "100%",
              height: "1px",
              opacity: 1,
              backgroundColor: "#E1E3E5",
              marginTop: "-11px",
            }}
            anchorClassName={`${s?.anchorClassName}`}
          >
            <TabPane name={Config?.rx_Tab_Title__2} key="transfer">
              <TransferRxForm
                activeStep={activeStep}
                setIsAddPharmacy={setIsAddPharmacy}               
                setActiveStep={setActiveStep}
                queryStatus={queryStatus}
                patientDetails={patientDetails}
                setPatientDetails={setPatientDetails}
                setConsentFullName={setConsentFullName}
                consentFullName={consentFullName}
                setIsSigned={setIsSigned}
                setImageURL={setImageURL}
                pharmacyList={pharmacyList}
                setPharmacyList={setPharmacyList}
                addPharmacy={addPharmacy}
              />
            </TabPane>
            <TabPane name={Config?.rx_Tab_Title__1} key="new">
              <NewRxForm
                activeStep={activeStep}
                setIsAddPractitioner={setIsAddPractitioner}
                queryStatus={queryStatus}
                setNewActiveStep={setActiveStep}
                setPatientDetails={setPatientDetails}
                patientDetails={patientDetails}
                setIsSigned={setIsSigned}
                setImageURL={setImageURL}
                setPractitioner={setPractitioner}
                practitioner={practitioner}
                setConsentFullName={setConsentFullName}
                consentFullName={consentFullName}
                practitionerList={practitionerList}
                addPractitioner={addPractitioner}
              />
            </TabPane>
          </Tabs> */}

          {rxType === "new" ? (
            <NewRxForm
              activeStep={activeStep}
              practitionerType={practitionerType}
              setPractitionerType={setPractitionerType}
              setPractitionerId={setPractitionerId}
              setAddPractitionerAddress={setAddPractitionerAddress}
              addPractitionerAddress={addPractitionerAddress}
              setIsAddPractitioner={setIsAddPractitioner}
              queryStatus={queryStatus}
              setNewActiveStep={setActiveStep}
              setPatientDetails={setPatientDetails}
              patientDetails={patientDetails}
              setIsSigned={setIsSigned}
              setImageURL={setImageURL}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              setConsentFullName={setConsentFullName}
              consentFullName={consentFullName}
              practitionerList={practitionerList}
              addPractitioner={addPractitioner}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
              clearErrors={clearErrors}
              setError={setError}
              setHealthDetails={setHealthDetails}
              healthDetails={healthDetails}
              setUploadedDocumentIDFront={setUploadedDocumentIDFront}
              uploadedDocumentIDFront={uploadedDocumentIDFront}
              setUploadedDocumentIDBack={setUploadedDocumentIDBack}
              uploadedDocumentIDBack={uploadedDocumentIDBack}
              onSubmitHealthCardHandler={onSubmitHealthCardHandler}
              dob={dob}
              setdob={setdob}
              dobErrorMsg={dobErrorMsg}
              setDobErrorMsg={setDobErrorMsg}
              rxContext={rxContext}
              setRxContext={setRxContext}
            />
          ) : (
            <TransferRxForm
              activeStep={activeStep}
              pharmacyType={pharmacyType}
              setPharmacyType={setPharmacyType}
              setPharmacyId={setPharmacyId}
              setAddPharmacyAddress={setAddPharmacyAddress}
              addPharmacyAddress={addPharmacyAddress}
              setActiveStep={setActiveStep}
              queryStatus={queryStatus}
              setConsentFullName={setConsentFullName}
              consentFullName={consentFullName}
              setIsSigned={setIsSigned}
              setImageURL={setImageURL}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
              clearErrors={clearErrors}
              setError={setError}
              setHealthDetails={setHealthDetails}
              healthDetails={healthDetails}
              setUploadedDocumentIDFront={setUploadedDocumentIDFront}
              uploadedDocumentIDFront={uploadedDocumentIDFront}
              setUploadedDocumentIDBack={setUploadedDocumentIDBack}
              uploadedDocumentIDBack={uploadedDocumentIDBack}
              onSubmitHealthCardHandler={onSubmitHealthCardHandler}
              dob={dob}
              setdob={setdob}
              dobErrorMsg={dobErrorMsg}
              setDobErrorMsg={setDobErrorMsg}
              rxContext={rxContext}
              setRxContext={setRxContext}
            />
          )}
          <div className={`${s?.actionButton}`}>
            <Button
              type="ghost"
              htmlType="button"
              size="medium"
              onClick={setBackSteps}
            >
              {ghostBtnText()}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="medium"
              loading={loading}
            >
              <span className="flex items-center justify-center gap-4">
                {primaryBtnText()}
                <img src={RightArrow} alt="Arrow icon" draggable="false" />
              </span>
            </Button>
          </div>
        </form>
      </div>

      {alert?.active && <Alert type={alert?.type} info={alert?.msg} />}

      {/* Verfication Model for both New and Transfer Rx */}
      {isModalOpen && (
        <Modal
          setModalOpen={setIsModalOpen}
          heading={`${Config?.uploadRx_Modal_Heading}`}
          detail={`${
            Config?.uploadRx_Modal_detail__1 +
            " " +
            generatedRx?.returnData[0]?.rx_number
          }`}
        />
      )}

      {/* Add Practitioner Form for New Rx  */}
      {isAddPractitioner && (
        <AddPractitioner
          setModalOpen={setIsAddPractitioner}
          heading={Config?.AddPractitioner_Modal_Heading}
          setAddPractitionerAlert={setAddPractitionerAlert}
          addPractitioner={addPractitioner}
        />
      )}

      {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}

      {addPractitionerAlert && (
        <Alert type="success" info="Practitioner added successfully" />
      )}
    </div>
  );
};

export default RxContainer;
