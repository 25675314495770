import React, { useContext } from "react";
import APIContext from "../../store/api-context";
import { UserAuthContext } from "../../store/UserAuthContext";
import { CloseIcon } from "../../utils/appIcons";
import Button from "../UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../config/button";
import Heading from "../UI/Heading";
import Config from "../../config/label";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import Alert from "../UI/Alert";
import { AppAlertContext } from "../../store/alert-context.js";
import { objDecrypt } from "../../utils/utils";

export type ModalProps = {
  heading?: string;
  setIsInsuranceModalOpen?: any;
  modalType?: string;
  editHeading?: string;
  setIsAlert?: any;
};

const PrimaryCardModal: React.FC<ModalProps> = (props) => {
  const [context, setContext] = useContext(UserAuthContext);
  const {
    setIsInsuranceModalOpen,
    heading,
    modalType,
    editHeading,
    setIsAlert,
  } = props;
  const [suggestUsInit] = useContext(APIContext);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);

  // const localStorageData: any = objDecrypt(
  //   localStorage.getItem(USER_PROFILE)
  // );
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };

  // const InsuranceClickHandler = (val: any) => {
  //   setIsAlert({
  //     istrue: false,
  //     type: "",
  //     msg: "",
  //   });
  //   setIsInsuranceModalOpen(false);

  //   if (suggestUsInit?.returnCode === true) {
  //     CallAPI(config?.insuranceModal_process_id, {
  //       p_pnt_id: localStorageCopy?.patient_id,
  //       p_status_flag: val,
  //     }).then((res) => {
  //       if (res?.returnCode === true) {
  //         localStorage.setItem("isInsurance", "Clicked");
  //         // setIsAlert({
  //         //   istrue: true,
  //         //   type: "success",
  //         //   msg: "Thank you for sharing insurance details!",
  //         // });
  //       }
  //     });
  //     CallAPI(config?.update_user_insurance, {
  //       p_usr_insurance_flag: "Y",
  //       p_customer_id: localStorageCopy?.patient_id,
  //     }).then((res) => {
  //       if (res?.returnCode === true) {
  //       }
  //     });
  //   }
  // };

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setIsInsuranceModalOpen(false);
            // localStorage.setItem("isInsurance", "Clicked");
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {modalType === "Edit" ? editHeading : heading}
          </Heading>
        </div>

        <div>
          <p className={Styles["insurance_label"]}>
            {Config?.label_Text1} <br />
            {Config?.label_Text2}
          </p>
          <div className={Styles["button_style"]} style={{ marginTop: "40px" }}>
            <Button
              type={Config?.lbl_Btn_type_primary}
              htmlType={Config?.lbl_Btn_action_submit}
              size="small"
              // onClick={() => {
              //   InsuranceClickHandler("Y");
              // }}
              style={{ margin: 0 }}
            >
              {btnConfig?.is_Insurance}
            </Button>

            <Button
              type={Config?.lbl_Btn_type_ghost}
              size="small"
              onClick={() => {
                // InsuranceClickHandler("N");
                setIsInsuranceModalOpen(false);
              }}
              style={{ margin: 0 }}
            >
              {btnConfig?.is_not_Insurance}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrimaryCardModal;
