import React from "react";
import style from "./index.module.css";
import { H2, H3 } from "../../../components/UI/Typography";
import Config from "../../../config/label";
import FlexBox from "../../../components/UI/FlexBox";
import btnConfig from "../../../config/button";
import LinkButton from "../../../components/UI/LinkButton";
import Card from "../../../components/UI/Card";
import Products from "../Products";
import BillingDetails from "../BillingDetails";
import ContactInfo from "./ContactInfo";
import PurchaseConfirm from "./PurchaseConfirm";

export type checkoutProps = {
  products?: any;
};
const Payment: React.FC<checkoutProps> = (props) => {
  // temporary default initialization of products
  const { products = Config?.products } = props;

  return (
    <div className={`${style?.container}`}>
      <H2 className={`${style?.pageTitle}`}>{Config?.paymentPage_Title}</H2>
      <H3 className={`${style?.pageTitle2}`}>{Config?.paymentPage_Title_2}</H3>

      <FlexBox className={`${style?.innerContainer}`}>
        <div className={`${style?.leftInnerContainer}`}>
          <Products products={products} />
        </div>

        <div className={`${style?.separator}`}></div>

        <div className={`${style?.rightInnerContainer}`}>
          <FlexBox style={{ flexDirection: "column" }}>
            {/* Contact Information */}
            <Card className="mb-4" style={{ borderRadius: "8px" }}>
              <ContactInfo />
            </Card>
            {/* Payment Card Info */}
            <Card className="mb-4" style={{ borderRadius: "8px" }}>
              <PurchaseConfirm />
            </Card>
            {/* Billing Details */}
            <Card className="mb-4" style={{ borderRadius: "8px" }}>
              <BillingDetails />
            </Card>

            {/* Payment Buttons */}
            <div className={`${style?.paymentButtonWrapper}`}>
              <div style={{ flexBasis: "50%" }}>
                <LinkButton
                  to="/purchase/cart"
                  type="ghost-secondary"
                  className={style["paymentButton"]}
                  draggable="false"
                >
                  {btnConfig?.payment_Button_1}
                </LinkButton>
              </div>
              <div style={{ flexBasis: "40%" }}>
                <LinkButton
                  to="/purchase/payment"
                  type="secondary"
                  className={style["paymentButton"]}
                  draggable="false"
                >
                  {btnConfig?.payment_Button_2}
                </LinkButton>
              </div>
            </div>
          </FlexBox>
        </div>
      </FlexBox>
    </div>
  );
};

export default Payment;
