import React from "react";
import styles from "./index.module.css";

export type widgetProps = {
  className?: string;
  style?: object;
};

const WidgetCard: React.FC<widgetProps> = (props) => {
  const { className, children, style } = props;
  return (
    // <div style={{ ...style }} className={`${styles?.container} ${className}`}>
    //   <div className="dealProductMain">
   
    //   </div>
      
    // </div>
    <>
       {children}</>
  );
};

export default WidgetCard;
