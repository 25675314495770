import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../../../../components/UI/Button";
import btnConfig from "../../../../../config/button";
import { UserAuthContext } from "../../../../../store/UserAuthContext.js";
import { CompletedIcon } from "../../../../../utils/appIcons";
import { JSONCheckerFunc, getDecryptedID, objDecrypt } from "../../../../../utils/utils";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import Config from "../../../../../config/label";
import { USER_PROFILE } from "../../../../../config/Config";

export type productProps = {
  pid?: string;
  leftCount?: number;
  heading?: string;
  onFile?: any;
  productImage?: any;
  product_CountLeft?: any;
  containerCount?: any;
  product_address?: any;
  productList?: any;
  setProductList?: any;
  index?: any;
  onClick?: any;
  addedToCart?: boolean;
  PrescribedQnty?: any;
  RefillQnty?: any;
  refill?: any;
  saltComposition?: any;
  productUrl?: any;
  BalanceQuantity?: any;
};
const ProductCard: React.FC<productProps> = (props) => {
  const {
    pid,
    heading,
    containerCount,
    product_CountLeft,
    onFile,
    productImage,
    product_address,
    addedToCart,
    onClick,
    PrescribedQnty,
    RefillQnty,
    refill,
    saltComposition,
    productUrl,
    BalanceQuantity,
  } = props;
  const [context, setContext] = useContext(UserAuthContext);
  const localStorageCopy: any =getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageData) };
  let isUserLoggedIn = localStorageCopy &&  Object.keys(localStorageCopy)?.length > 0 ? true : false;
  const navigate = useNavigate();

  //Splitting the string from multiple image src stringd
  let str = productImage;
  str = str.split("~")[0];

  return (
    <div className={`${styles?.cardContainer}`}>
      <div
        className={`${styles?.cardTopWrapper}`}
        onClick={() => productUrl && navigate(`${productUrl}`)}
      >
        <Link to={""} draggable="false">
          <div className={`${styles?.cardImageBox}`}>
            <img src={str} alt="" draggable="false" />
          </div>
        </Link>
        <div
          className={` `}
          style={
            {
              // marginTop: "40px",
            }
          }
        >
          <Link
            to={""}
            style={{ textDecoration: "none" }}
            className={styles["productLink"]}
          >
            <h2 className={`${styles?.heading} ${styles?.truncate}`}>
              {heading}
            </h2>
          </Link>
          <p className={`${styles?.saltCompo}`}>{saltComposition}</p>
        </div>
      </div>
      <hr />
      <div className={`${styles?.rxQuantity}`}>
        <div className={`${styles?.QuantityDetail}`}>
          <p>{PrescribedQnty}</p>
          <p>{Config?.Rx_product_card_Prescribed}</p>
        </div>
        <div className={`${styles?.QuantityDetail}`}>
          <p>{RefillQnty}</p>
          <p>{Config?.Rx_product_card_RefillQnt}</p>
        </div>
        <div className={`${styles?.QuantityDetail}`}>
          <p>{refill}</p>
          <p>{Config?.Rx_product_card_Refill}</p>
        </div>
        <div className={`${styles?.QuantityDetail}`}>
          <p>{BalanceQuantity}</p>
          <p>{Config?.Rx_product_card_Balance}</p>
        </div>
      </div>
      <hr />

      {addedToCart === true ? (
        <button className={`${styles?.ghostCardButton}`}>
          <div className={`${styles?.ghostInner}`}>
            <img
              src={CompletedIcon}
              alt="cartDone"
              style={{ width: "24px", height: "24px" }}
              draggable="false"
            />
            <p>{btnConfig?.product_Button_2}</p>
          </div>
        </button>
      ) : (
        <Button
          type="primary"
          size="small"
          className={`${styles?.cardButton}`}
          onClick={() => {
            if (isUserLoggedIn) {
              onClick();
            } else {
              setContext((prev: any) => ({
                ...prev,
                isLoginPopUP: true,
              }));
            }
          }}
        >
          {btnConfig?.product_Button_1}
        </Button>
      )}
    </div>
  );
};

export default ProductCard;
