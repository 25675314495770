import React, { useEffect, useState, useContext } from "react";
import { CloseIcon } from "../../../utils/appIcons";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../../config/button";
import Heading from "../../../components/UI/Heading";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import { ShimmerSimpleGallery, ShimmerBadge } from "react-shimmer-effects";
import square from "../../../assets/svg/Square-Logo.svg";
import Config from "../../../config/label";
import { color_config } from "../../../config/colorConfig";

export type ModalProps = {
  heading?: string;
  setIsAddCardModalOpen?: any;
  modalType?: string;
  editHeading?: string;
  getAddress?: any;
  isCardFieldsValid?: any;
  squareCard?: any;
  loaded?: any;
  squarePayments?: any;
  setSquarePayments?: any;
  APPLICATION_ID?: any;
  LOCATION_ID?: any;
  setSquareCard?: any;
  validFields?: any;
  setValidFields?: any;
  isSubmitting?: any;
  setSubmitting?: any;
  paymentRequestMock?: any;
  tokenizePaymentMethod?: any;
  verifytokenizePaymentMethod?: any;
  addPayment?: any;
  isAddCardModalOpen?: any;
  isButtonLoading?: any;
  setIsButtonLoading?: any;
};

const AddCard: React.FC<ModalProps> = (props) => {
  const {
    isAddCardModalOpen,
    setIsAddCardModalOpen,
    heading,
    modalType,
    editHeading,
    isCardFieldsValid,
    squareCard,
    setSquareCard,
    squarePayments,
    setValidFields,
    isSubmitting,
    setSubmitting,
    tokenizePaymentMethod,
    verifytokenizePaymentMethod,
    addPayment,
    isButtonLoading,
    setIsButtonLoading,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  // Set each card field validity on various events
  const handleCardEvents = ({ detail }: any) => {
    if (detail) {
      // const { currentState: { isCompletelyValid } = {}, field } = detail;
      const { currentState: { isCompletelyValid } = null, field } = detail;
      if (field) {
        setValidFields((prevState: any) => ({
          ...prevState,
          [field]: isCompletelyValid,
        }));
      }
    }
  };

  // Handle the form submission
  const handlePaymentMethodSubmission = async (paymentMethod: any) => {
    setIsButtonLoading(true);
    const isCard = paymentMethod?.element?.id === "card-container";

    if (isCard && !isCardFieldsValid) return;
    if (!isSubmitting) {
      // Disable the submit button as we await tokenization and make a
      // payment request
      if (isCard) setSubmitting(true);
      try {
        const tokenResult = await tokenizePaymentMethod(paymentMethod);

        const verifytokenResult = await verifytokenizePaymentMethod(
          tokenResult.token,
          squarePayments
        );

        // Create your own addPayment function to communicate with your API
        if (tokenResult.token && verifytokenResult) {
          let type = "saveCard";
          await addPayment(
            tokenResult.token,
            tokenResult,
            verifytokenResult,
            type
          );
        }

      } catch (error) {
        console.error("FAILURE", error);
        setIsButtonLoading(false);
        setSubmitting(false);
      } finally {
        isCard && setSubmitting(false);
      }
    }
  };

  // Attach the Square card to our container and setup event listeners
  const attachCard = (card: any) => {
    // We pass in the card object during initialization, but re-use it from
    // state for normal re-renders
    const cardObject = card || squareCard;
    cardObject.attach("#card-container");
    // Listeners: https://developer.squareup.com/reference/sdks/web/payments/objects/Card#Card.addEventListener
    cardObject.addEventListener("submit", () =>
      handlePaymentMethodSubmission(cardObject)
    );
    cardObject.addEventListener("focusClassAdded", handleCardEvents);
    cardObject.addEventListener("focusClassRemoved", handleCardEvents);
    cardObject.addEventListener("errorClassAdded", handleCardEvents);
    cardObject.addEventListener("errorClassRemoved", handleCardEvents);
    cardObject.addEventListener("cardBrandChanged", handleCardEvents);
    cardObject.addEventListener("postalCodeChanged", handleCardEvents);
  };

  const initializeSquareCard = async () => {
    // postalCode: false
    const card = await squarePayments.card();
    if (card) {
      setIsLoading(false);
    }
    setSquareCard(card);
    attachCard(card);
    // checkCustomer();

    // const cardOptions = {
    //   postalCode: true,
    // };
    // card.configure(cardOptions);
  };

  // Handle Square payment methods initialization and re-attachment
  useEffect(() => {
    if (squarePayments) {
      if (!squareCard) initializeSquareCard();
    }
    // Otherwise, we destroy the objects and reset state
    else {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
    }
  }, [squarePayments, isAddCardModalOpen]);

  // Some quick button styles
  let cardButtonStyles = {
    backgroundColor: color_config?.list_hover_bg_color,
    color: color_config?.white_color,
    padding: 16,
    fontFamily: "sans-serif",
    fontSize: "1rem",
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 0,
  };

  if (isCardFieldsValid) {
    cardButtonStyles = {
      ...cardButtonStyles,
      backgroundColor: color_config?.black_color,
    };
  }

  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => {
            setIsAddCardModalOpen(false);
            if (squareCard) {
              squareCard.destroy();
              setSquareCard(undefined);
            }
          }}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {modalType === "Edit" ? editHeading : heading}
          </Heading>
        </div>
        {isLoading ? (
          <>
            {/* <ShimmerSimpleGallery
              row={1}
              col={1}
              imageHeight={150}
              imageWidth={50}
            /> */}
            <ShimmerBadge width={280} />
            <ShimmerBadge width={280} />
            <ShimmerBadge width={280} />
            <ShimmerBadge width={280} />
          </>
        ) : (
          <div>
            <div style={{ marginBottom: 24 }}></div>
            <form id="payment-form">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "137px",
                }}
              >
                <div id="card-container"></div>
              </div>

              <div id="payment-status-container"></div>

              {(isLoading === false && (
                <div
                  style={{
                    // width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    // marginLeft: "190px",
                  }}
                >
                  <p className={`${Styles?.powerText}`}>Powered by</p>
                  <img
                    className={`${Styles?.squareLogo}`}
                    src={square}
                    alt="square"
                    draggable="false"
                  />
                </div>
              )) ||
                ""}
              <div className={Styles["button_style"]}>
                <Button className={Styles["payment_cancel_btn"]}
                  type={Config?.lbl_Btn_type_ghost}
                  size={Config?.lbl_Btn_size_medium}
                  onClick={() => {
                    setIsAddCardModalOpen(false);
                    if (squareCard) {
                      squareCard.destroy();
                      setSquareCard(undefined);
                    }
                  }}
                  style={{ margin: 0 }}
                >
                  {btnConfig?.pharmacy_modal_cancel}
                </Button>
                <Button 
                  type={Config?.lbl_Btn_type_primary}
                  htmlType={Config?.lbl_Btn_action_submit}
                  size={Config?.lbl_Btn_size_medium}
                  style={{ margin: 0 }}
                  disabled={!isCardFieldsValid || isSubmitting}
                  onClick={() => handlePaymentMethodSubmission(squareCard)}
                  loading={isButtonLoading}
                >
                  {modalType === "Edit" ? "Update" : "Add Card"}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddCard;
