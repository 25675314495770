import React from "react";
import styles from "./index.module.css";

/*
 * This is icon button component, it can be used for to display Icon as children
 *
 * You can use props for custom styling and events
 *
 */
export type IBtnProps = {
  style?: object;
  onClick?: any;
  type?: string;
  htmlType?: any;
  size?: string;
  className?: string;
  title?: string;
  caption? : string;
};

const IconButton: React.FC<IBtnProps> = ({ children, ...props }) => {
  return (
    <button
      title={props?.title}
      className={`${styles?._iconBtnStyle} ${props?.className}`}
      style={{
        ...props?.style,
      }}
      onClick={props.onClick}
      type={props?.htmlType}
      draggable="false"
    >
      {children}
      {props?.caption ? <p>{props?.caption}</p> : ""}
    </button>
  );
};

export default IconButton;
