import modalStyles from "./index.module.css";
const SignUpVendorContainer = (props) => {
  const { maxWidth } = props;
  if (!maxWidth) {
    maxWidth: "440px";
  }
  return (
    <div className={`${modalStyles?.modalBackground}`}>
      <div className={`${modalStyles?.modalContainer}`} style={{ maxWidth }}>
        {/*<div className={`${modalStyles?.create_new_list_wrapper}`}>
          <div className={`${modalStyles?.create_list_arrow}`}>
            <i class="fa-solid fa-arrow-left-long"></i>
          </div>
          <div className={`${modalStyles?.create_list_title}`}>
            <h5>Create new list</h5>
          </div>
          
        </div> */}

        {props?.children}
      </div>
    </div>
  );
};

export default SignUpVendorContainer;
