import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const formatter = (timestamp) => {
  return moment(timestamp).fromNow();
};
const TheirMessage = ({ id, lastMessage, message, messageService, author, getDocType, mediaUrl }) => {
  const isFirstMessageByUser =
    !lastMessage || lastMessage?.author !== message.author;
  // && lastMessage?.attributes?.type !== "queue" || lastMessage?.attributes?.type !== "info";
  const [timestampString, setTimestampString] = useState("");

  useEffect(() => {
    const timer = setInterval(
      () => setTimestampString(formatter(message?.dateCreated)),
      1000
    );
    setTimestampString(formatter(message?.dateCreated));
    return () => clearInterval(timer);
  }, []);

  // const getFileType = (value) => {
  //   if ((["vnd.ms-powerpoint", "vnd.openxmlformats-officedocument.presentationml.presentation"])?.includes(value)) {
  //     return "pptx"
  //   }
  //   if ((["vnd.openxmlformats-officedocument.spreadsheetml.sheet", "vnd.ms-excel"])?.includes(value)) {
  //     return "xlsx"
  //   }
  //   return value
  // }
  const isStaff = message?.attributes?.staffs !== undefined && message?.attributes?.staffs?.includes(id)

  try {
    return (
      <>
        {(message?.attributes?.type === "queue" || message?.attributes?.type === "info") ? isStaff ? 
          <div className="center">
            <div
              className="msg">
              <div className="message">
                <div className="message-body-container"> {message?.body !== "" && message?.body !== "null" && message?.body}
                </div>
              </div>
            </div>

          </div> 
          : 
          message?.attributes?.type === "info" && 
            <div className="center">
              <div
                className="msg">
                <div className="message">
                  <div className="message-body-container"> {message?.body !== "" && message?.body !== "null" && message?.body}
                  </div>
                </div>
              </div>
            </div> 
          :
            <div className="left">
              {isFirstMessageByUser && (
                <div className="chat-avatar">
                  <div className="icon">
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                  </div>
                  <div className="name">{author}</div>
                </div>
              )}
              <div
                className="msg"
                onClick={() => {
                  if (message?.media?.sid) {
                    if(mediaUrl!=undefined){
                      messageService.sendMessage(
                        "TwilioDOC",
                        {
                          params: {
                            state: true,
                            index: 0,
                            src: "Chat",
                            files: [{
                              file_name: message?.media?.filename,
                              file_view_path: mediaUrl,
                              file_ext: message?.media?.contentType?.split("/").pop(),
                              file_download_path: mediaUrl
                            }]
                          }
                        },
                        "chat-doc-pop-up"
                      );
                    }else{
                      toast.error("Please wait media is loading.");
                    }
                  }

                }}
              >
                <div className="message">
                  <div className="message-body-container"> {message?.body !== "" && message?.body !== "null" && message?.body}
                  </div>
                  <div
                    className="image-container"
                    style={{
                      display: message?.media === null && "none",
                    }}
                  >
                    {mediaUrl ? (
                      <>
                        {!["jpeg", "png", "jpg", "gif"].includes(
                          message?.media?.contentType?.split("/").pop()
                        ) ? (
                          <i className={getDocType} />
                        ) : (
                          <img
                            src={mediaUrl}
                            height="100"
                            width="100"
                            loading="lazy"
                          />
                        )}
                      </>
                    ) : (
                      <div className="loader">
                        <span
                          className="spinner-border-black spinner-border-sm loadingSpinner "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="timestampe">
                {timestampString}
              </div>
            </div>
        }
      </>
    );
  } catch (e) {
    console.log("Error: ", e.message);
  }
};

export default TheirMessage;
