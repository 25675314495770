import RadioButton from "../UI/RadioButton";
import { useState, useEffect } from "react";
import modes from "../../config/paymentModes";
import Style from "../HealthInfoCards/AddHealthInformation/index.module.css";
import styles from "./index.module.css";
import checkoutStyles from "../../pages/ProductPurchase/index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { paymentModeActions } from "./slice/slice";
import btnConfig from "../../config/button";
import Button from "../UI/Button";
import { CallAPI } from "../../suggest-us/callSuggest";
import { config } from "../../config/processId";
import PaymentRadioButton from "../UI/PaymentRadioButton";
import Alert from "../UI/Alert";
import Config from "../../config/label";
import OrderCompletion from "../OrderCompletion";
import { msg_config } from "../../config/messages";
import { getDecryptedID, getID } from "../../utils/utils";
import { SG_PATIENT_ID } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
const Payments = () => {
  const [paymentModes, setPaymentModes] = useState([]);
  const state = useSelector((state) => state?.paymentModeReducer);
  const [paymentAlert, setPaymentModesAlert] = useState({
    isTrue: false,
    msg: "",
    type: "",
  });
  const dispatch = useDispatch();
  const [isOrderCompletion, setIsOrderCompletion] = useState({
    isActive: false,
    orderNumber: "",
  });
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    (async () => {
      const res = await CallAPI(config?.get_default_payment_process_id, {});
      setPaymentModes(res?.returnData);
      // setPaymentModes([{
      //   pm_description: "Credit Card",pm_code:"CC",pm_status:"A"},
      //   {pm_description: "Cheque",pm_code:"Cheque",pm_status:"A"},
      //   {pm_description: "COD",pm_code:"COD",pm_status:"A"},
      // ])
    })();
  }, []);

  useEffect(() => {
    if (paymentAlert?.isTrue) {
      setTimeout(() => {
        setPaymentModesAlert({
          isTrue: false,
          msg: ""
        })
      }, 5000);
    }
  }, [paymentAlert])


  const handleProceedOrder = () => {
    try {
      let params = {}
      const processId = config?.place_order_process_id;
      if (state?.selectedMode === undefined) {
        setPaymentModesAlert({
          isTrue: true,
          msg: msg_config?.msg_please_select_payment_gateway,
          type: "error"
        });
      } else {
        params.p_gateway_type = state?.selectedMode;
        params.p_patient_id = decryptData(getID(SG_PATIENT_ID));
        params.p_card_nonce = "";
        params.p_card_nonce_type = "";
        setLoading(true);
        CallAPI(processId, params)
          .then((res) => {
            if (res?.returnCode) {
              setIsOrderCompletion({
                isActive: true,
                orderNumber: res?.returnData[0]?.order_number
              })
              setLoading(false);
            } else {
              console.error(res?.msg);
              setLoading(false);
            }
          })
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <form className={`${styles?.paymentModeContainer}`}>
        {paymentModes?.map((i) => (
          <div className={`${styles.paymentMode}`}>
            {i?.pm_image_path && (
              <img src={i?.pm_image_path} alt={i?.pm_description} />
            )}
            <PaymentRadioButton
              name={"checkoutPaymentMode"}
              value={i?.pm_code}
              onChange={(e) => {
                dispatch(
                  paymentModeActions?.SET_PAYMENT_MODE({
                    selectedMode: e?.target?.value,
                  })
                );
                // setSelectedMode(e?.target?.value);
              }}
              disabled={i?.pm_status === "A" ? false : true}
              title={i?.pm_description}
              className={`${Style?.infoGrid_radioBtn_Btn_rad}`}
              checked={state?.selectedMode === i?.pm_code}
            />
          </div>
        ))}
        <Button
          // to={cartTotal?.cart_subtotal === "0.00" ? setIsCartEmpty(true) :navigate("/purchase/checkout")}
          type="secondary"
          className={`${checkoutStyles["checkoutButton"]}`}
          onClick={(e) => {
            e?.preventDefault();
            handleProceedOrder()
          }}
          // draggable="fals e"
          disabled={paymentAlert?.isTrue}
          loading={loading}
        >
          {btnConfig?.cart_Button_4}
        </Button>
        {paymentAlert?.isTrue && <div className={styles?.paymentGatewayValidationError}>
          <p>{paymentAlert?.msg}</p>
        </div>}


      </form>
      {isOrderCompletion.isActive && <OrderCompletion
        setModalOpen={setIsOrderCompletion}
        heading={Config?.orderCompletion_Modal_Heading}
        subHeading={Config?.orderCompletion_subHeading}
        orderNumber={isOrderCompletion}
        detail={Config?.orderCompletion_Modal_detail__1}
      />}
    </>
  );
};

export default Payments;
