import React, { useContext, useEffect } from "react";
import Button from "../Button";
import Styles from "./index.module.css";
import Heading from "../../../components/UI/Heading";
import { useNavigate } from "react-router-dom";
import Doctorclipart from "../../../assets/svg/Doctorclipart.svg";
import CloseIcon from "../../../assets/svg/close.svg";
import { Link } from "react-router-dom";
import Config from "../../../config/label";
import { UserAuthContext } from "../../../store/UserAuthContext";
import { GlobalContext } from "../../../store/global-context";
import { removeKey, setEncryptedID } from "../../../utils/utils";
import { IS_INSURANCE, PROFILE_IMAGE, SG_PATIENT_ID, SG_ROLE_ID, SG_USER_ID, SIGN_IN_WITH, USER_LOGGED_IN, USER_PROFILE } from "../../../config/Config";

export type ModalProps = {
  setModalOpen?: any;
};

const LogoutPopup: React.FC<ModalProps> = (props) => {
  const { setModalOpen } = props;
  let navigate = useNavigate();
  const [context, setContext] = useContext(UserAuthContext);
  const [globalContext, setGlobalContext] = useContext(GlobalContext);


  const handleIfConfirm = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      setContext((prev: any) => {
        return {
          ...prev,
          UserAuth: {},
        };
      });
      removeKey(USER_PROFILE);
      setEncryptedID(USER_LOGGED_IN, "N");
      removeKey(SG_ROLE_ID);
      removeKey(SG_USER_ID);
      removeKey(SG_PATIENT_ID);
      removeKey(IS_INSURANCE);
      removeKey(PROFILE_IMAGE);
      
      setEncryptedID(SIGN_IN_WITH, " ");
      setGlobalContext((prev: any) => {
        return {
          ...prev,
          profile_image: "",
        };
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  const handleClickButton = (e: any) => {
    if (e.keyCode === 13) {
      handleIfConfirm();
    } else if (e.keyCode === 27) {
      setModalOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleClickButton)
    return () => {
      document.removeEventListener("keydown", handleClickButton)
    }
  }, [])


  return (
    <div className={Styles["modalBackground"]}>
      <div className={Styles["modalContainer"]}>
        <div
          className={Styles["titleCloseBtn"]}
          title="Close button"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="Cross icon" draggable="false" />
        </div>
        <div className={Styles["title"]}>
          <div className={Styles["checkIconWrapper"]}>
            <img src={Doctorclipart} alt="Cart icon" draggable="false" />
          </div>
          <Heading className={`${Styles?.page_heading}`}>
            You will be logged out of your current account. Do you wish to
            proceed?.
          </Heading>
          <p></p>
        </div>
        <div className={Styles["buttons"]}>
          <Button className={Styles["loggout_cancel_btn"]}
            type={Config?.lbl_Btn_type_ghost}
            size={Config?.lbl_Btn_size_medium}
            onClick={() => setModalOpen(false)}
          >
            {Config?.lbl_land_page_cancel}
          </Button>

          <Link
            to="/log-in"
            style={{ textDecoration: "none" }}
            draggable="false"
          >
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => {
                handleIfConfirm();
              }}
            >
              {Config?.lbl_otp_confirm}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default LogoutPopup;
