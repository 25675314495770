import React, { useState, useEffect, useContext, useRef } from "react";
import APIContext from "../../store/api-context";
import styles from "./index.module.css";
import RxProductCard from "../../components/RxProductCard/index";
import Footer from "../../components/Footer";
import Config from "../../config/label";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import { config } from "../../config/processId.js";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingCircle from "../../components/LoadingCircle";
import AddtocartPopup from "../../components/ProductDetails/AddtocartPopup";
import { AppAlertContext } from "../../store/alert-context.js";
import { ShimmerThumbnail } from "react-shimmer-effects";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import noshow from "../../assets/svg/noshow.svg";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt, setEncryptedID } from "../../utils/utils";
import Button from "../Home-Page/Button";
import { siteConfig } from "../../config/site";
import { Link, useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../store/UserAuthContext.js";
import ProfilePopup from "../Landingpage/ProfilePopup";
import { color_config } from "../../config/colorConfig";
import Alert from "../../components/UI/Alert";
import { CART_COUNT, SG_PATIENT_ID, SG_USER_ID, USER_PROFILE } from "../../config/Config";
import { decryptData } from "../../utils/util_helper";
// import Alert from "../../components/UI/Alert";

const MyRx = () => {
  // const [productList, setProductList] = useState(Config?.myRxProducts);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [productList, setProductList] = useState<any>([]);
  const [suggestUsInit] = useContext(APIContext);
  const [offSet, setOffset] = useState(0);
  const [productdetails, setProductDetails] = useState<any>("");
  const [isCartPopUpOpen, setIsCartPopUPOpen] = useState(false);
  const [alertContext, setAlertContext] = useContext(AppAlertContext);
  const navigate = useNavigate();
  const [context, setContext] = useContext(UserAuthContext);
  const [isProfilePopup, setIsProfilePopup] = useState(false);
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });

  const firstRender = useRef(true);

  const localStorageCopy: any = getDecryptedID(USER_PROFILE);
  // let localStorageCopy = { ...JSONCheckerFunc(localStorageCopy) };

  let isUserLoggedIn =  localStorageCopy && Object.keys(localStorageCopy)?.length > 0 ? true : false;

  const gaEventTracker = useAnalyticsEventTracker("My rx page");
  const fetchMoreData = async () => {
    if (suggestUsInit?.returnCode === true) {
      let newOffSet = 0;
      newOffSet = offSet + 12;
      setOffset(newOffSet);
      const res = await CallAPI(config?.myRx_fetch_list_process_id, {
        p_customer_id: userProfileJson?.customer_id,
        p_min_offset: newOffSet,
        p_max_offset: 12,
      }).then((res) => res);
      if (res?.returnCode) {
        // setIsLoading(false);
        setProductList((prev: any) => [...prev, ...res?.returnData]);
        return true;
      }
    }
  };

  const FetchProductList = () => {
    window.scrollTo(0, 0);

    //Api call for product list
    CallAPI(config?.myRx_fetch_list_process_id, {
      p_customer_id: userProfileJson?.customer_id,
      p_min_offset: 0,
      p_max_offset: 12,
    }).then((res) => {
      if (res?.returnCode) {
        setProductList(res?.returnData);
      }
    });
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      FetchProductList();
    }
  }, [suggestUsInit]);

  const updateProductList = (id: any) => {
    let _productList: any = [...productList];
    _productList = _productList?.map((item: any) => {
      if (item?.srv_id === id) {
        return { ...item, cart_status: "Y" };
      }
      return item;
    });

    setProductList([..._productList]);
  };

  const addCartHandler = (product: any) => {
    setIsAlert({
      istrue: false,
      type: "",
      msg: "",
    });
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.customer_cart_add_product_process_id, {
        p_usr_id: decryptData(getID(SG_USER_ID)),
        p_patient_id: decryptData(getID(SG_PATIENT_ID)),
        p_service_detail_string: product?.srv_id + "~1",
        p_flag: "RXAPPR",
      }).then((res: any) => {
        if (res?.returnCode) {
          if (res?.returnData[0].cart_count !== undefined) {
            setEncryptedID(CART_COUNT, res?.returnData[0].cart_count);
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_count
            );
            // setAlertContext({
            //   status: false,
            //   type: "success",
            //   info: res?.msg,
            //   cartCount: res?.returnData[0].cart_count,
            // });
          } else {
            setEncryptedID(
              CART_COUNT,
              res?.returnData[0].cart_item_count
            );
            gaEventTracker(
              "Items added to cart",
              res?.returnData[0].cart_item_count
            );
            // setAlertContext({
            //   status: false,
            //   type: "success",
            //   info: res?.msg,
            //   cartCount: res?.returnData[0].cart_item_count,
            // });
          }

          updateProductList(product?.srv_id);

          setProductDetails(product);
          setIsCartPopUPOpen(siteConfig?.checkout_popup);
          setIsAlert({
            istrue: true,
            type: "success",
            msg: res?.msg,
          });
        } else {
          setAlertContext({
            status: true,
            type: "error",
            info: res?.msg,
          });
          setIsAlert({
            istrue: true,
            type: "error",
            msg: res?.msg,
          });
        }
      });
    }
  };

  return (
    // <div className={`${styles?.pageContainer}`}>
    <div
      className={`${styles?.mainContainer}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

      <div className="outer_container">
        <div className={`${styles?.grid}`}>
          {/* <div className={`${styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div> */}
          {/* <div className={`${styles?.gridRightItem}`}> */}
          <div className={`${styles?.headerContainer}`}>
            <div style={{ display: "flex" }}>
              <h2 className={`${styles?.heading}`}>{Config?.myrx_heading}</h2>
            </div>

            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              // onClick={() => {
              //   navigate("/submit-rx");
              // }}
              onClick={() => {
                setContext((prev: any) => ({
                  ...prev,
                  isLoginPopUP: false,
                }));
                if (isUserLoggedIn === true) {
                  if (
                    localStorageCopy?.usr_email === "" ||
                    localStorageCopy?.usr_name === "" ||
                    localStorageCopy?.usr_phone === "" ||
                    localStorageCopy?.usr_dob === ""
                  ) {
                    setIsProfilePopup(true);
                  } else {
                    navigate("/submit-rx");
                  }
                } else {
                  setContext((prev: any) => ({
                    ...prev,
                    isLoginPopUP: true,
                  }));
                }
              }}
            >
              {Config?.header_button_text}
            </Button>
          </div>

          <div>
            <InfiniteScroll
              dataLength={productList?.length || 0}
              next={fetchMoreData}
              hasMore={
                productList?.length !== 0
                  ? productList?.length < productList[0]?.total_count
                  : false
              }
              loader={
                // <div
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     marginTop: "30px",
                //   }}
                // >
                //   <LoadingCircle loadingColor={color_config?.common_bg_color} />
                // </div>
                <div className={`${styles?.addressGrid}`}>
                  {[1, 2, 3, 4]?.map((item: any) => {
                    return (
                      <div className={`${styles?.widget_item_wrapper}`}>
                        <ShimmerThumbnail height={250} rounded />
                      </div>
                    );
                  })}
                </div>
              }
              // height={"620px"}
              endMessage={
                <p
                  style={{
                    textAlign: "center",
                    color: color_config?.common_bg_color,
                    marginTop: "0px",
                  }}
                >
                  {productList?.length !== 0 ? (
                    <b>
                      {productList?.length === 0 ? (
                        <div className={`${styles?.addressGrid}`}>
                          {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any) => {
                            return (
                              <div className={`${styles?.widget_item_wrapper}`}>
                                <ShimmerThumbnail height={250} rounded />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </b>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        margin: "30px 0",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        padding: "10% 0",
                      }}
                    >
                      <div className={styles["checkIconWrapper"]}>
                        <img
                          src={noshow}
                          alt="Empty Cart icon"
                          draggable="false"
                        />
                      </div>
                      <b>No prescription found</b>
                    </div>
                  )}
                </p>
              }
            >
              <div className={`${styles?.addressGrid}`}>
                {productList?.map((item: any, index: any) => {
                  return (
                    <RxProductCard
                      pid={item?.srv_id}
                      key={item?.srv_id}
                      productImage={item?.single_image}
                      onFile={item?.refill_on_text}
                      heading={item?.srv_name}
                      leftCount={item?.balance_qty}
                      containerCount={item?.srv_packaging}
                      // product_address={"Guildford Drugs, 200-15135 101st Ave"}
                      productList={productList}
                      setProductList={setProductList}
                      index={index}
                      // handleOnClick={() => {
                      //   setProductList((prev: any) =>
                      //     prev.map((data: any, i: any) =>
                      //       index !== i ? data : { ...data, cart_status: "Y" }
                      //     )
                      //   );
                      // }}
                      addedToCart={item?.cart_status === "Y" ? true : false}
                      onClick={() => addCartHandler(item)}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {isCartPopUpOpen && (
        <AddtocartPopup
          img={productdetails?.srv_image.split("~")[0]}
          title={productdetails?.srv_name + productdetails?.srv_packaging}
          setModalOpen={setIsCartPopUPOpen}
        />
      )}
      {isProfilePopup && <ProfilePopup setModalOpen={setIsProfilePopup} />}
      {isAlert?.istrue && <Alert type={isAlert?.type} info={isAlert?.msg} />}
    </div>
    // </div>
  );
};

export default MyRx;
