import React from "react";
import s from "./index.module.css";

export type CheckboxProps = {
  name?: string;
  value?: any;
  onChange?: any;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { name, value, onChange, className } = props;

  return (
    <label className={`${s?.container}`}>{name}
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className={`${s?.checkmark}`} ></span>
    </label>
  );
};

export default Checkbox;
