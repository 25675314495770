import { useState, FC } from "react";
import styles from "./buttonStyles.module.css";
import config from "../../../config/button";
import spin from "../../../assets/svg/spin.svg";

export type ButtonProps = {
  title?: string;
  style?: object;
  onClick?: any;
  type?: string;
  htmlType?: any;
  size?: string;
  className?: string;
  disabled?: any;
  loading?: boolean;
};

const Button: FC<ButtonProps> = (props) => {
  const { loading = false } = props;
  const [onHoverStyle, setonHoverStyle] = useState({});

  const getBtnSize = () => {
    switch (props?.size) {
      case "link":
        return styles?.link;

      case "large":
        return styles?.large;

      case "medium":
        return styles?.medium;

      case "small":
        return styles?.small;
      default:
        return "";
    }
  };

  const getBtnType = () => {
    switch (props?.type) {
      case "primary":
        return styles?.primary;

      case "ghost":
        return styles?.ghost;

      default:
        return;
    }
  };

  const getHoverLeaveStyle = () => {
    setonHoverStyle({
      opacity: "1",
    });
  };

  const getHoverEnterStyle = () => {
    setonHoverStyle({
      opacity: "0.9",
    });
  };

  return (
    <button
      onMouseEnter={getHoverEnterStyle}
      onMouseLeave={getHoverLeaveStyle}
      className={`${styles?._btnStyle} ${getBtnSize()} ${getBtnType()} ${
        props?.className
      }`}
      style={{
        ...props?.style,
        // ...onHoverS  tyle,
      }}
      onClick={props.onClick}
      type={props?.htmlType}
      disabled={props?.disabled}
      draggable="false"
    >
      <span className="flex items-center gap-2 justify-center">
        {loading && <i className="fa fa-spinner fa-spin"></i>}

        {props?.children}
        {props?.title}
      </span>
    </button>
  );
};

export default Button;
