import Styles from "./index.module.css";
import RxTypes from "./rxTypes";
import { Link } from "react-router-dom";
import Config from "../../config/label";
import { color_config } from "../../config/colorConfig";
import Heading from "../../components/UI/Heading";
import RxVector from "../../assets/svg/RxVector.svg";
import UploadRx from "../../assets/icons/uploadRx.png";
import TransferRx from "../../assets/icons/transferRx.png";
import NewRx from "../../assets/icons/newRx.png";

function SubmitRx() {
  const rxData = [
    {
      key: "STEP1",
      title: `${Config?.submitRx_Title_1}`,
      discription: (
        <>
          <p className={`${Styles?.rxDetail} mb-0p5`}>
            {Config?.submitRx_detail__1A}
          </p>
          <p className={Styles["rxDetail"]}> {Config?.submitRx_detail__1B}</p>
        </>
      ),
      icon: UploadRx,
      backgroundColor: color_config?.rx_step1_bg_color,
      link: "upload-rx",
    },
    {
      key: "STEP2",
      title: `${Config?.submitRx_Title_2}`,
      discription: (
        <>
          <p className={`${Styles?.rxDetail} mb-0p5`}>
            {Config?.submitRx_detail__2A}
          </p>
          <p className={Styles["rxDetail"]}> {Config?.submitRx_detail__2B}</p>
        </>
      ),

      icon: NewRx,
      backgroundColor: color_config?.rx_step2_bg_color,
      link: `rx/${Config?.rx_Tab_url__1}`,
    },
    {
      key: "STEP3",
      title: `${Config?.submitRx_Title_3}`,
      discription: (
        <>
          <p className={`${Styles?.rxDetail} mb-0p5`}>
            {Config?.submitRx_detail__3A}
          </p>
          <p className={Styles["rxDetail"]}> {Config?.submitRx_detail__3B}</p>
        </>
      ),
      icon: TransferRx,
      backgroundColor: color_config?.rx_step3_bg_color,
      link: `rx/${Config?.rx_Tab_url__2}`,
    },
  ];
  return (
    <div className={Styles["container"]}>
      <div className={Styles["imgeContainer"]}>
        <img src={RxVector} alt="" draggable="false" />
      </div>
      <div className={Styles["rxContainer"]}>
        <div className={Styles["rxInnerContainer"]}>
          <Heading className={`${Styles?.page_heading}`}>
            {Config?.submitRx_Page_Heading}
          </Heading>
          <div className={Styles["rxTypes"]}>
            {rxData.map((item) => (
              <div key={item?.key}>
                <Link
                  to={`/submit-rx/${item?.link}`}
                  style={{ textDecoration: "none" }}
                  draggable="false"
                >
                  <RxTypes
                    icon={item?.icon}
                    type={item?.key}
                    title={item.title}
                    discription={item?.discription}
                    backgroundColor={item?.backgroundColor}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitRx;
