import { MyLocation } from "../../../utils/appIcons";
import styles from "./index.module.css";

export type InputProps = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange?: any;
  style?: any;
  size: string;
};

const LocationInput: React.FC<InputProps> = (props) => {
  const { name, id, type, placeholder, onChange } = props;

  const getInputSize = () => {
    switch (props?.size) {
      case "large":
        return { width: "100%", height: "50px", fontSize: "16px" };
      default:
        return {};
    }
  };
  return (
    <div className={styles["inputFeild"]}>
      <input
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className={`${styles.inputStyle}`}
        style={{ ...props?.style, ...getInputSize() }}
      />
      <img src={MyLocation} alt="" draggable="false" />
    </div>
  );
};

export default LocationInput;
