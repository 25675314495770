import s from "./index.module.css";
import { AddIcon } from "../../../../utils/appIcons";

const CardUpload = (props: any) => {
  const { onFileUploadHandler, loading, icon } = props;
  return (
    <>
      <label className={`${s?.uploadContainer}`}>
        {loading ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : (
          <img
            src={icon || AddIcon}
            alt="Plus sign icon button"
            draggable="false"
          />
        )}
        {/* <img src={AddIcon} alt='Plus sign icon button' /> */}
        <p className={`${s?.uploadTitle}`}>{props?.title}</p>
        <input
          name={props?.name}
          id={props?.name}
          type="file"
          onChange={onFileUploadHandler}
          multiple={props.multiple}
          accept="image/*"
          style={{ display: "none" }}
        />
      </label>
    </>
  );
};

export default CardUpload;
