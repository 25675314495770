export const MessageStatus = {
  Sending: "Sending",
  Sent: "Sent",
  Delivered: "Delivered",
  Failed: "Failed",
  None: "none (incoming)",
  Read: "Read",
};

/**************************  GET MESSAGE STATUS API START *******************************/
export async function getMessageStatus(
  conversation,
  message,
  channelParticipants
) {
  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  channelParticipants?.forEach((participant) => {
    if (
      participant.type !== "chat"
    ) {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] = 2;
    } else if (participant.lastReadMessageIndex !== -1) {
      statuses[MessageStatus.Delivered] += 1;
    }
  });

  if (message?.aggregatedDeliveryReceipt) {
    const receipts = await message?.getDetailedDeliveryReceipts();
    receipts?.forEach((receipt) => {
      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}
/**************************  GET MESSAGE STATUS API END *******************************/

/**************************  GET TYPING MESSAGE  START *******************************/
export const getTypingMessage = (typingData, isMe) => {
  if (typingData?.userID !== undefined) {
    if (typingData?.userID === isMe) {
      return "";
    } else {
      return `${typingData?.name + " is typing..."}`;
    }
  }
};
/**************************  GET TYPING MESSAGE  END *******************************/
