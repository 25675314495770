import React, { useState, useContext, useEffect } from "react";
import styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar";
import WishlistCard from "../../components/WishlistCard";
import { Bagheart } from "../../utils/appIcons";
import APIContext from "../../store/api-context";
import { config } from "../../config/processId.js";
import { CallAPI } from "../../suggest-us/callSuggest.js";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingCircle from "../../components/LoadingCircle";
import noshow from "../../assets/svg/noshow.svg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import Config from "../../config/label";
import { JSONCheckerFunc, getDecryptedID, objDecrypt, setEncryptedID } from "../../utils/utils";
import Alert from "../../components/UI/Alert";
import { color_config } from "../../config/colorConfig";
import { CART_COUNT, USER_PROFILE } from "../../config/Config";

const MyWishlist = () => {
  const [productList, setProductList] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestUsInit] = useContext(APIContext);
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [offSet, setOffset] = useState(0);
  const [alert, setAlert] = useState({
    isTrue : false,
    msg : "",
    type : "",
  });
  const [loading, setLoading] = useState({
    wishlistId : "",
    delete: "",
  })
  const gaEventTracker = useAnalyticsEventTracker("My Wishlist page");

  const fetchMoreData = async () => {
    if (suggestUsInit?.returnCode === true) {
      let newOffSet = 0;
      newOffSet = offSet + 9;
      setOffset(newOffSet);
      const res = await CallAPI(config?.get_wish_list_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_min_offset: newOffSet,
        p_max_offset: 9,
      }).then((res) => res);
      if (res?.returnCode) {
        // setIsLoading(false);
        setProductList((prev: any) => [...prev, ...res?.returnData]);
        return true;
      }
    }
  };

  const FetchWishList = () => {
    window.scrollTo(0, 0);
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.get_wish_list_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_min_offset: 0,
        p_max_offset: 9,
      }).then((res) => {
        if (res?.returnCode) {
          setProductList(res?.returnData);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    FetchWishList();
  }, [suggestUsInit]);

  const moveToCartHandler = (product: any) => {
    setAlert({
      isTrue : false,
      type : "",
      msg : "",
    });
    if (suggestUsInit?.returnCode === true) {
      setLoading((prev:any)=>{
        return {...prev, wishlistId : product?.wishlist_id}
      })  

      CallAPI(config?.move_to_cart_from_wish_list, {
        p_org_id: userProfileJson?.org_id,
        p_usr_id: userProfileJson?.usr_id,
        p_cus_id: userProfileJson?.customer_id,
        p_wshlst_id: product?.wishlist_id,
        p_internal_flag: "",
      }).then((res) => {
        if(res?.returnCode){
          FetchWishList();
          if (res?.returnData[0].cart_count !== undefined) {
            setEncryptedID(CART_COUNT, res?.returnData[0].cart_count);
            gaEventTracker(
              "Items moved to cart from wishlist",
              res?.returnData[0].cart_count
            );
            setLoading((prev:any)=>{
              return {...prev, wishlistId : ""}
            })  
          } else {
            setEncryptedID(CART_COUNT,
              res?.returnData[0].cart_item_count
            );
            gaEventTracker(
              "Items moved to cart from wishlist",
              res?.returnData[0].cart_item_count
            );
            setLoading((prev:any)=>{
              return {...prev, wishlistId : ""}
            })  
          }
          setAlert({
            isTrue : true,
            type : "success",
            msg : res?.msg,
          });
        }else {
          setAlert({
            isTrue : true,
            type : "error",
            msg : res?.msg,
          });
          setLoading((prev:any)=>{
            return {...prev, wishlistId : ""}
          })  
        }
       
      });
    }
  };

  const onDeleteHandler = (product: any, setIsConfirmPopup: any) => {
    //setIsConfirmPopup(false);
    setAlert({
      isTrue : false,
      type : "",
      msg : "",
    });
    setLoading((prev:any)=>{
      return {...prev, delete : product?.wishlist_id}
    })  
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.wish_list_delete_process_id, {
        p_cus_id: userProfileJson?.customer_id,
        p_wshlst_id: product?.wshlst_id,
      }).then((res) => {
        if(res?.returnCode){
          // setAlert({
          //   isTrue : true,
          //   type : "success",
          //   msg : res?.msg,
          // });
          gaEventTracker("Items removed from wishlist");
        FetchWishList();
        setLoading((prev:any)=>{
          return {...prev, delete : ""}
        })  
        }else {
          setAlert({
            isTrue : true,
            type : "error",
            msg : res?.msg,
          });
          // FetchWishList();
          setLoading((prev:any)=>{
            return {...prev, delete : ""}
          })  
        }
      });
    }
  };


  return (
    <div
      className={`${styles?.mainContainer}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="outer_container">
        <div className={`${styles?.grid}`}>
          <div className={`${styles?.gridLeftItem}`}>
            <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
          <div className={`${styles?.gridRightItem}`}>
            <div className={`${styles?.headerContainer}`}>
              <div style={{ display: "flex" }}>
                <Bagheart
                  width="25"
                  height="25"
                  style={{ marginTop: "7px", color: "#32C5F4" }}
                />
                <h2 className={`${styles?.heading}`}>{Config?.mywish_heading}</h2>
              </div>
            </div>
            {isLoading ? (
              <>
                <br />
                <br />
                <br />
                <ShimmerSimpleGallery row={2} col={3} imageHeight={150} />
              </>
            ) : (
              <div>
                <InfiniteScroll
                  style={{ overflowX: "hidden" }}
                  dataLength={productList?.length || 0}
                  next={fetchMoreData}
                  hasMore={productList?.length < productList[0]?.total_count}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <LoadingCircle
                        loadingColor={color_config?.common_bg_color}
                      />
                    </div>
                  }
                  // height={"620px"}
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        color: color_config?.common_bg_color,
                        marginTop: "30px",
                      }}
                    >
                      <b>
                        {productList?.length === 0 ? (
                          <>
                            <div className={styles["checkIconWrapper"]}>
                              <img
                                src={noshow}
                                alt="Empty Cart icon"
                                draggable="false"
                              />
                            </div>
                            <div className={styles["iconTextWrapper"]}>
                              {Config?.mywish_nodata}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </b>
                    </p>
                  }
                >
                  <div className={`${styles?.addressGrid}`}>
                    {productList?.map((item: any) => (
                      <WishlistCard
                      productId = {item?.wishlist_id}
                        type={item?.service_type}
                        loading = {loading}
                        productUrl={"/product-detail/" + item?.srv_id}
                        imgUrl={item?.srv_image.split("~")[0]}
                        imgAlt={item?.srv_name}
                        detail={item?.srv_name}
                        packagingId={item?.srv_packaging}
                        brand={item?.srv_brand}
                        price={item?.discounted_amount}
                        actualPrice={item?.srv_price}
                        discountPercent={item?.discount_percent}
                        coPay={item?.copayment_amount}
                        ribbon={true}
                        ribbonType="folded"
                        ratingCount={
                          item?.rating_view_flag === "Y" &&
                          item?.rating_star_count
                        }
                        onaddbuttonclick={() => {
                          moveToCartHandler(item);
                        }}
                        ondeletebuttonclick={(setIsConfirmPopup: any) => {
                          onDeleteHandler(item, setIsConfirmPopup);
                        }}
                        productType = {item?.salt_composition}
                        product={item}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      </div>
      {alert?.isTrue && (
        <Alert type={alert?.type} info={alert?.msg} />
      )}
    </div>
  );
};

export default MyWishlist;
