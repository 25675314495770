/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import TabPane from "../Tabs-pane/tab-pane";
import Styles from "./index.module.css";

export type TabsProps = {
  children?: any;
  getActiveTab?: any;
  active?: any;
  setActive?: any;
  navClassName?: any;
  navStyle?: any;
  ulClassName?: any;
  ulStyle?: any;
  liClassName?: any;
  liStyle?: any;
  anchorClassName?: any;
  anchorStyle?: any;
  bottomBorderStyle?: any;
};

const Tabs: React.FC<TabsProps> = (props) => {
  const {
    children,
    getActiveTab,
    active,
    setActive,
    navClassName,
    navStyle,
    ulClassName,
    ulStyle,
    liClassName,
    liStyle,
    anchorClassName,
    anchorStyle,
    bottomBorderStyle,
  } = props;
  const [tabHeader, setTabHeader] = useState<any[]>([]);
  const [childContent, setChildConent] = useState<any>({});

  useEffect(() => {
    const headers: any = [];
    const childCnt: any = {};
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { name }: any = element.props;
      const { key }: any = element;

      if (isNaN(key)) {
        headers.push({ name, key });
        let el: any = element.props;
        let elChild = el.children;
        childCnt[key] = elChild;
      } else {
        let newKey = Number(key);
        headers.push({ name, key: newKey });
        let el: any = element.props;
        let elChild = el.children;
        childCnt[newKey] = elChild;
      }
    });
    setTabHeader(headers);
    setChildConent({ ...childCnt });
  }, [props, children]);

  const changeTab = (item: any) => {
    setActive(item?.key);
  };

  return (
    <div className={`${Styles?.tab_div}`}>
      <nav className={`${Styles.nav} ${navClassName}`} style={{ ...navStyle }}>
        <ul
          className={`${Styles.Tabs} ${ulClassName}`}
          style={{
            ...ulStyle,
          }}
          role="tablist"
        >
          {tabHeader?.map((item, idx): any => (
            <li
              onClick={() => changeTab(item)}
              key={idx}
              className={`${Styles.description_tab} ${Styles.Tab} ${
                active === item?.key && Styles.active
              } ${liClassName}`}
              role="tab"
              aria-controls="tab-description"
              style={{ ...liStyle }}
            >
              <div
                className={`${Styles.itemStyle} ${anchorClassName}`}
                style={{ ...anchorStyle }}
              >
                {item?.name}
              </div>
            </li>
          ))}
        </ul>
      </nav>
      <div
        style={{
          ...bottomBorderStyle,
        }}
      />
      <div>
        {Object.keys(childContent).map((key, idx): any => {
          if (isNaN(Number(key))) {
            if (key === active) {
              return <div key={idx} className="tab-child">{childContent[key]}</div>;
            } else {
              return null;
            }
          } else {
            let newKey = Number(key);
            if (newKey === active) {
              return <div className="tab-child" key={idx}>{childContent[newKey]}</div>;
            } else {
              return null;
            }
          }
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: function (props, propName, componentName) {
    const prop = props[propName];

    let error = null;
    React.Children.forEach(prop, function (child) {
      if (child.type !== TabPane) {
        error = new Error(
          "`" + componentName + "` children should be of type `TabPane`."
        );
      }
    });
    return error;
  },
};

export default Tabs;
