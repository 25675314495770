import React, { useEffect, useState, useContext, useRef } from "react";
import Card from "../UI/Card";
import Styles from "./address.module.css";
import Button from "../UI/Button";
import Config from "../../config/label";
import { formatPhoneNumber } from "react-phone-number-input";
import selectedAddressImg from "../../assets/sf_svg/Radio active.svg";
import notSelectedAddressImg from "../../assets/sf_svg/Radio.svg";
const AddressList = (props: any) => {
    const {
        address,
        setShowAddressList,
        handleOpenAddressManage,
        showAddressList,
        selectedAddress,
        setSelectedAddresses
    } = props;
    const ref: any = useRef(null);

    let addressID = ""
    const addressType = showAddressList?.addressType;
    try {
        if (addressType === "BILLING") {
            addressID = selectedAddress?.billingAddress?.p_patadd_id || "";
        } else {
            addressID = selectedAddress?.deliveryAddress?.p_patadd_id || "";
        }
    } catch (error) {
        console.error(error);
    }

    // const handleClickOutside = (event: any) => {
    //     try {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             if (showAddressList?.isTrue && event.target.id !== "addressListCo") {
    //                 setShowAddressList((prev: any) => {
    //                     return { ...prev, isTrue: false }
    //                 });
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }

    // };

    // useEffect(() => {
    //     if (showAddressList?.isTrue)
    //         document.addEventListener("click", handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside, true);
    //     };
    // }, [showAddressList]);
    return (
        <div className={Styles?.addresses_wrapper} ref={ref} id="addressListCo">
            {address.map((items: any, idx: any) => {
                const IsSelected = addressID === items?.p_patadd_id
                return (
                    <Card className="mb-7" style={{ borderRadius: "8px" }} key={idx}>
                        <div className=" items-center justify-between">
                            <div className={`${Styles?.listOfAddresses} manage_address_slider`}>
                                <div
                                    className={`${Styles["userAddress"]} ${Styles["userListAddress"]} ${IsSelected ? "activeAddress" : ""}`}
                                    onClick={() => {
                                        // handleSelectDeliveryAddress(items);
                                    }}
                                >
                                    <div className={`${Styles?.radioDetailWrapper}`}
                                        onClick={() => {
                                            setShowAddressList((prev: any) => {
                                                return {
                                                    ...prev,
                                                    isTrue: false,
                                                    selectedAddress: items || {}
                                                }
                                            })
                                            if (addressType === "BILLING") {
                                                setSelectedAddresses((prev: any) => {
                                                    return { ...prev, billingAddress: items }
                                                })
                                            } else {
                                                setSelectedAddresses((prev: any) => {
                                                    return { ...prev, deliveryAddress: items }
                                                })
                                            }

                                        }}
                                    >
                                        <div className={`${Styles?.selectionDiv}`}>
                                            {IsSelected ? <img src={selectedAddressImg} /> : <img src={notSelectedAddressImg} />}
                                        </div>
                                        <div className={`${Styles?.detailsPannel}`}>
                                            <h1>
                                                {
                                                    //@ts-ignore
                                                    items?.patadddet_pnt_name +
                                                    " " +
                                                    //@ts-ignore
                                                    items?.patadddet_pnt_last_name}
                                            </h1>
                                            {/* Name */}
                                            <p> {
                                                //@ts-ignore
                                                items?.p_address}</p>
                                            {/* Address line 1 */}
                                            <p>
                                                {
                                                    //@ts-ignore
                                                    items?.city_desc + ", " + items?.state_desc}
                                            </p>
                                            {/* City, Province*/}
                                            <p>

                                                {//@ts-ignore
                                                    items?.coutry_desc + ", " + items?.p_pcode}
                                            </p>
                                            {/* Country, PO box*/}
                                            <p>
                                                {formatPhoneNumber(//@ts-ignore
                                                    items?.patadddet_pnt_phone
                                                )}
                                            </p>
                                            {/* Phone no*/}
                                            {/* Default Address badge */}

                                            {
                                                //@ts-ignore
                                                items?.default_address_flag === "Y" ? (
                                                    <p className={`${Styles?.defaultBadge}`}>
                                                        {Config?.lbl_default_address}
                                                    </p>
                                                )
                                                    : ""}
                                        </div>
                                    </div>

                                    <div className={Styles?.actions}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                        <Button
                                            type={Config?.lbl_Btn_type_ghost}
                                            size={Config?.lbl_Btn_size_medium}
                                            htmlType={"button"}
                                            className={Styles?.actionButton}
                                            onClick={() => {
                                                handleOpenAddressManage(addressType, items)
                                            }}
                                        >
                                            {Config?.lbl_change_edit}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                )
            })}
            <div className={`${Styles?.manage_address_label}`}>
                <p
                    onClick={() => {
                        handleOpenAddressManage("NEW", {});
                    }}
                >
                    {Config?.lbl_manageAddress}
                </p>
            </div>
        </div>

    )
}
export default AddressList;