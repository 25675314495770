const {
  REACT_APP_SUGGESTUS_INTERNAL_URL,
  REACT_APP_SUGGESTUS_ULTRA_URL,
  REACT_APP_SUGGESTUS_EVN,
  REACT_APP_TWILIO_URL,
  REACT_APP_SUGGESTUS_AI_CODE,
} = process.env;
export default {
  // SUGGESTUS_INTERNAL_URL: 'http://192.168.1.16:5000',
  // SUGGESTUS_ULTRA_URL: 'http://192.168.1.16:8000',// dev hv server
  SUGGESTUS_INTERNAL_URL: REACT_APP_SUGGESTUS_INTERNAL_URL,
  SUGGESTUS_ULTRA_URL: REACT_APP_SUGGESTUS_ULTRA_URL, // dev hv server
  SUGGESTUS_EVN: REACT_APP_SUGGESTUS_EVN,
  TWILIO_URL: REACT_APP_TWILIO_URL,
  SUGGESTUS_AI_CODE: REACT_APP_SUGGESTUS_AI_CODE,
  MSG_DEFAULT_SUCCESS: "Process has been completed successfully.",
  MSG_DEFAULT_ERR: "We got some error, Please try again.",
  ERR_0: "Data save failed. Contact system administrator.",
  ERR_1: "Data saved successfully.",
  ERR_2: "Data has been deleted successfully.",
  ERR_1001: "Refund amount is more than the pending deposit amount.",
  ERR_1002: "Cannot cancel the Deposit .Deposit is already settled.",
  ERR_1003: "Settlement amount is more than the pending deposit amount.",
  ERR_1004: "Settlement amount is more than the pending bill amount.",
  ERR_1005: "Refund settlement can not be cancelled.",
  ERR_1006: "Document save failed .Transaction type does not exist.",
  ERR_1007: "Active package already exist for the patient.",
  ERR_1008: "Order date & time can not be less than visit date & time.",
  ERR_1009: "Order date & time can not be future date.",
  ERR_1010: "Active package does not exist for the patient.",
  ERR_1011: "Selected bill is already cancelled.",
  ERR_1012:
    "Bill is already settled. Cancel settlement before bill cancellation.",
  ERR_1013: "Please select services to approve.",
  ERR_1014: "Please select approval request to approve.",
  ERR_1015: "Package can not be cancelled. Its already prebilled.",
  ERR_1016: "Package can not be cancelled. Its already billed.",
  ERR_1017: "Package is already billed",
  ERR_1018: "Package can not be included in package itself.",
  ERR_1019:
    "Visit already exist for the patient for selected doctor for today.",
  ERR_1020: "Selected TPA , Payer and Plan is already addded.",
  ERR_1021: "Rule name already exist.",
  ERR_1022: "Selected Preauth is already cancelled.",
  ERR_1023: "Default organisation is already assigned to the user.",
  ERR_1024: "Default role is already assigned to the user.",
  ERR_1025: "User login id is already in use.",
  ERR_1026: "User email id is already in use.",
  ERR_1027: "Role name already exist.",
  ERR_1028: "Selected organisation is already assigned to the user.",
  ERR_1029: "Selected role is already assigned to the user.",
  ERR_1030: "Settlement is already cancelled.",
  ERR_1031: "Cannot bill the package , package status is not active.",
  ERR_1032: "Cannot cancel the package, package status is not active.",
  ERR_1033: "Cannot complete the package, package status is not billed active.",
  ERR_1121: "exist",
  ERR_1122: "not exist",
  ERR_1034: "Password and confirm password should be same.",
  ERR_1035: "Phone number is already in use.",
  ERR_1036: "Invalid image name.",
};
