  import { JSONCheckerFunc } from "../../../../../utils/utils";
import { SPD_OPENSSL_CYPHER_METHOD } from "./config";
  import axios from "axios";
  const CryptoJS = require("crypto-ts");
  
  export const ButtonCodeChecker = (allButton, code) => {
    let isValidBtn = false;
    if (allButton !== "") {
      allButton.map((items) => {
        if (items?.code === code) {
          isValidBtn = true;
        }
      });
    }
    return isValidBtn;
  };

export const decryptObj = (data) => {
    try {
      if (data !== null && typeof data !== "undefined") {
        const bytes = CryptoJS.AES.decrypt(data, SPD_OPENSSL_CYPHER_METHOD);
        const encrypted_data = bytes.toString(CryptoJS.enc.Utf8);
        if (encrypted_data !== "") {
          const originalObj = JSONCheckerFunc(bytes.toString(CryptoJS.enc.Utf8));
          return originalObj;
        } else {
          return "";
        }
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getID = (key) => {
    return sessionStorage.getItem(key);
  };

export const twilioSendSMS = async (url, recipient, text) => {
    return axios.post(`${url}/sms/sendMessage`,
      {
        recipient: recipient,
        text: text
      },
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
  }