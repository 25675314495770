import React, { useEffect, useState, useContext, useRef } from "react";
import { CloseIcon } from "../../../utils/appIcons";
import Button from "../../../components/UI/Button";
import Styles from "./index.module.css";
import btnConfig from "../../../config/button";
import Heading from "../../../components/UI/Heading";
import Checkbox from "../../../components/UI/Checkbox";
import Config from "../../../config/label";
import { FormInput } from "../../../components/UI/FormInput";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import checkedicon from "../../../assets/sf_svg/Radio active.svg";
import { isTemplateExpression } from "typescript";
import { useForm } from "react-hook-form";
import Label from "../../../components/UI/Label";
import { msg_config } from "../../../config/messages";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt } from "../../../utils/utils";
import notSelectedAddressImg from "../../../assets/sf_svg/Radio.svg";
import Input from "../../../components/UI/Input";
import Delete_icon from "../../../assets/icons/Delete_icon.svg";
import IconButton from "../../../components/UI/IconButton";
import { ShimmerTable } from "react-shimmer-effects";
import ConfirmDeletePopup from "../ConfirmDeletePopup";
import { JsonFunction } from "react-router-dom";
import { SG_PATIENT_ID, USER_PROFILE } from "../../../config/Config";
import { decryptData } from "../../../utils/util_helper";
import LocationSearchInput from "../../../components/LocationSearchInput";
import { siteConfig } from "../../../config/site";

export type ModalProps = {
  setIsAddAddressPopup?: any;
  setModalOpen?: any;
  modalType?: string;
  propsGetAddresss?: any;
  getAddress?: any;
  propGetAllAddress?: any;
  propsGetDeafultAddress?: any;
  containerClassBackGroundClass?: any;
  mainContainerClass?: any;
  rowContainerClass?: any;
  showCancelButton?: any;
  isAddAddressPopup?: any;
  propSetSelectedAddresses?: any;
  propsSelectedAddress?: any;
  isAddressPage?: any;
  setPermaSetSelect?: any;
};

const AddNewAddress: React.FC<ModalProps> = (props) => {
  const [addresses, setAddresses] = useState<any[]>([]);
  const [country, setCountry] = useState<any>([]);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [loadingProvince, setLoadingProvince] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDefault, setIsDefault] = useState("N");
  const [isNewEntry, setIsNewEntry] = useState(true);
  const [province, setProvince] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [submitLoad, submitLoading] = useState(false);
  //to store selected address values
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [isDeleteConfirm, setIsDeleteConfirm] = useState({
    isTrue: false,
    id: "",
  });
  const jsonProfileData = getDecryptedID(USER_PROFILE);
  // const jsonProfileData = JSONCheckerFunc(sessionStorageData)
  const [suggestUsInit] = useContext(APIContext);
  const [isSelecting, setIsSelecting] = useState(false);
  const [profileField, setProfileField] = useState<any>({
    coutry_desc: "",
    p_address: "",
    p_street: "",
    city_desc: "",
    state_desc: "",
    p_pcode: "",
    p_country: "",
    p_province: "",
    p_city: "",
    patadddet_pnt_name: "",
    patadddet_pnt_last_name: "",
  });
  const [location, setLocation] = useState("");
  const [addressDetails, setaddressDetails] = useState([]);
  const [addressForFill, setAddressForFill] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm();
  const {
    setIsAddAddressPopup,
    setModalOpen,
    modalType = "",
    propGetAllAddress,
    propsGetDeafultAddress,
    containerClassBackGroundClass,
    mainContainerClass,
    rowContainerClass,
    showCancelButton,
    isAddAddressPopup,
    propSetSelectedAddresses,
    propsSelectedAddress,
    isAddressPage,
    setPermaSetSelect,
  } = props;
  const isManagingAddress = isAddAddressPopup?.addressType === "NEW";

  //@ts-ignore
  let selectedAddressFromEdit: { patadddet_id: any; } | null = null;
  try {
    if (isAddAddressPopup?.addressType === "DELIVERY") {
      selectedAddressFromEdit = propsSelectedAddress?.deliveryAddress;
    } else if (isAddAddressPopup?.addressType === "BILLING") {
      selectedAddressFromEdit = propsSelectedAddress?.billingAddress;
    }
  } catch (error) {
    console.error(error);
  }

  const [tempSelected, setTempSelected] = useState<any>({
    temp: "",
    perma: selectedAddressFromEdit?.patadddet_id,
  });

  const _country = watch<any>("country");
  const _province = watch<any>("province");

  useEffect(() => {
    try {
      if (_country) {
        onCountrySelectHandler(_country);
      }
    } catch (error) {
      console.error(error);
    }
  }, [_country]);

  useEffect(() => {
    setProfileField((prev: any) => {
      return {
        ...prev,
        patadddet_pnt_name: jsonProfileData?.usr_first_name,
        patadddet_pnt_last_name: jsonProfileData?.usr_last_name,
      }
    })
    //@ts-ignore
    setValue("patadddet_pnt_name", jsonProfileData?.usr_first_name);
    //@ts-ignore
    setValue("patadddet_pnt_last_name", jsonProfileData?.usr_last_name);
    //@ts-ignore
  }, [])

  useEffect(() => {
    try {
      if (selectedAddresses) {
        //@ts-ignore
        setValue("country", selectedAddresses?.country)
        //@ts-ignore
        setValue("province", selectedAddresses?.province)
        //@ts-ignore
        setValue("city", selectedAddresses?.city)

        //@ts-ignore
        // setValue("patadddet_pnt_name",selectedAddresses?.patadddet_pnt_name)
        // //@ts-ignore
        // setValue("patadddet_pnt_name",selectedAddresses?.patadddet_pnt_last_name)
      }
    } catch (error) {
      console.error(error);
    }
  }, [selectedAddresses]);

  useEffect(() => {
    try {
      if (_province) {
        onProvinceSelectHandler(_province);
      }
    } catch (error) {
      console.error(error);
    }
  }, [_province]);

  useEffect(() => {
    getAllAddress();
    // setIsSelecting(true);
  }, []);

  useEffect(() => {
    try {
      if (suggestUsInit?.returnCode) {
        setLoadingCountry(true);
        CallAPI(config?.country_process_id, {}).then((res) => {
          if (res?.returnCode) {
            setCountry([
              ...res?.returnData?.map((item: any) => {
                return {
                  value: item?.cnt_id,
                  label: item?.cnt_description,
                };
              }),
            ]);

            setLoadingCountry(false);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [suggestUsInit]);

  const getAllAddress = () => {
    try {
      if (suggestUsInit?.returnCode === true) {
        CallAPI(config?.patient_address_list_process_id, {
          p_pnt_id: decryptData(getID(SG_PATIENT_ID)),
        }).then((res) => {
          let defaultAddress = {};
          let updatedvalue = res?.returnData.map((items: any, index: any) => {
            if (tempSelected?.temp === items?.patadddet_id) {
              defaultAddress = items;
              return { ...items, isSelected: true };
            } else {
              return { ...items, isSelected: false };
            }
          });
          setAddresses(res?.returnData);
          setIsNewEntry(true);
          // updatedvalue.map((items: any) => {
          //   if (tempSelected?.perma === items?.patadddet_id) {
          //     if (isAddAddressPopup?.addressType === "DELIVERY") {
          //       propSetSelectedAddresses((prev: any) => {
          //         return { ...prev, deliveryAddress: items };
          //       });
          //     }
          //     if (isAddAddressPopup?.addressType === "BILLING") {
          //       propSetSelectedAddresses((prev: any) => {
          //         return { ...prev, billingAddress: items };
          //       });
          //     }
          //   }
          // });
          // if (JSON.stringify(defaultAddress) === "{}") {
          //   // setProfileField({});
          //   setValue("patadddet_pnt_name", jsonProfileData?.usr_first_name);
          //   setValue("patadddet_pnt_last_name", jsonProfileData?.usr_last_name);
          //   setProfileField((prev: any) => {
          //     return {
          //       ...prev,
          //       patadddet_pnt_name: jsonProfileData?.usr_first_name,
          //       patadddet_pnt_last_name: jsonProfileData?.usr_last_name,
          //     }
          //   })

          // } else {
          //   if (defaultAddress !== undefined) {
          //     //@ts-ignore
          //     if (defaultAddress?.default_address_flag == "Y") {
          //       setChecked(true);
          //     } else {
          //       setChecked(false);
          //     }

          //     let countryObject = {
          //       //@ts-ignore
          //       value: defaultAddress?.p_country || "",
          //       //@ts-ignore
          //       label: defaultAddress?.coutry_desc || "",
          //     };
          //     setValue("country", countryObject);
          //     let provinceObject = {
          //       //@ts-ignore
          //       value: defaultAddress?.p_province || "",
          //       //@ts-ignore
          //       label: defaultAddress?.state_desc || "",
          //     };
          //     setValue("province", provinceObject);
          //     let cityObject = {
          //       //@ts-ignore
          //       value: defaultAddress?.p_city || "",
          //       //@ts-ignore
          //       label: defaultAddress?.city_desc || "",
          //     };

          //     setValue("city", cityObject);
          //     //@ts-ignore
          //     setValue("patadddet_pnt_name", defaultAddress?.patadddet_pnt_name || jsonProfileData?.usr_first_name);
          //     //@ts-ignore
          //     setValue("patadddet_pnt_last_name", defaultAddress?.patadddet_pnt_last_name || jsonProfileData?.usr_last_name);
          //     //@ts-ignore
          //     setValue("p_pcode", defaultAddress?.p_pcode || "");
          //     //@ts-ignore
          //     setValue("p_address", defaultAddress?.p_address || "");
          //     setProfileField({
          //       //@ts-ignore
          //       patadddet_pnt_name: defaultAddress?.patadddet_pnt_name || jsonProfileData?.usr_first_name,
          //       //@ts-ignore
          //       patadddet_pnt_last_name: defaultAddress?.patadddet_pnt_last_name || jsonProfileData?.usr_last_name,
          //       //@ts-ignore
          //       p_pcode: defaultAddress?.p_pcode || "",
          //       //@ts-ignore
          //       p_address: defaultAddress?.p_address || "",
          //       //@ts-ignore
          //       p_patadd_id: defaultAddress?.p_patadd_id || "",
          //       //@ts-ignore
          //       default_address_flag: defaultAddress?.default_address_flag || "",
          //       country: countryObject,
          //       province: provinceObject,
          //       city: cityObject,
          //       //@ts-ignore
          //       patadddet_id: defaultAddress?.patadddet_id || "",
          //       //@ts-ignore
          //       coutry_desc: defaultAddress?.coutry_desc || "",
          //       //@ts-ignore
          //       city_desc: defaultAddress?.city_desc || "",
          //       //@ts-ignore
          //       state_desc: defaultAddress?.state_desc || "",
          //       //@ts-ignore
          //       patadddet_status_flag: defaultAddress?.patadddet_status_flag || "",
          //     });
          //   } else {
          //     setProfileField({});
          //   }
          // }


          setLoading(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e: any) => {
    try {
      if (e.target.checked) {
        setChecked(e.target.checked);
        setProfileField((prev: any) => {
          return { ...prev, default_address_flag: "Y" };
        });
      } else {
        setChecked(e.target.checked);
        setProfileField((prev: any) => {
          return { ...prev, default_address_flag: "N" };
        });
      }
    } catch (error) {
      console.error(error);
    }

    // setChecked(!checked);
  };

  const handleLabel = () => {
    if (
      profileField?.default_address_flag == "N" ||
      profileField?.default_address_flag == ""
    ) {
      setChecked(true);
      setProfileField((prev: any) => {
        return { ...prev, default_address_flag: "Y" };
      });
    } else {
      setChecked(false);
      setProfileField((prev: any) => {
        return { ...prev, default_address_flag: "N" };
      });
    }
  };
  // USE THIS FUNCTION TO SELECT SELECTED ADDRESS AND SHOW IN RIGHT SIDE FORM

  const handleAddressSelect = (address: any) => {
    try {
      // setIsSelecting(true);
      setTempSelected((prev: any) => {
        return { ...prev, temp: address?.patadddet_id };
      });
      let update = addresses.map((items: any) => {
        if (items.patadddet_id == address.patadddet_id) {
          return { ...items, isSelected: true };
        }
        return { ...items, isSelected: false };
      });
      setAddresses(update);
      setLocation(address.p_address)
      setSelectedAddress(address);
      if (address?.default_address_flag == "Y") {
        //@ts-ignore
        setChecked(true);
      } else {
        setChecked(false);
        //@ts-ignore
      }
      setValue("p_pcode", address.p_pcode);
      setValue("p_address", address.p_address);
      setValue("patadddet_pnt_name", address.patadddet_pnt_name);
      setValue("patadddet_pnt_last_name", address.patadddet_pnt_last_name);

      setProfileField((prev: any) => {
        return {
          ...prev,
          p_address: address?.p_address,
          p_pcode: address.p_pcode,
          patadddet_pnt_name: address.patadddet_pnt_name,
          patadddet_pnt_last_name: address.patadddet_pnt_last_name,
        }
      })
      setIsNewEntry(false);
      // return false;
      // // if(isAddAddressPopup?.addressType === "DELIVERY"){
      // //   propSetSelectedAddresses((prev:any)=>{
      // //     return {...prev, deliveryAddress: address}
      // //   })
      // // }
      // // if(isAddAddressPopup?.addressType === "BILLING"){
      // //   propSetSelectedAddresses((prev:any)=>{
      // //     return {...prev, billingAddress: address}
      // //   })
      // // }
      // //@ts-ignore
      // // document.querySelector("#addresscheckbox").checked=false;



      // // setSelectedAddresses(address);
      // let addressObject = {};
      // //@ts-ignore
      // // addressObject.patadddet_pnt_name = address.patadddet_pnt_name;
      // //@ts-ignore
      // // addressObject.lastName = address.patadddet_pnt_last_name;
      // //@ts-ignore
      // // addressObject.postalcode = address.p_pcode;

      // // //@ts-ignore
      // // addressObject.country = {
      // //   value: address.p_country,
      // //   label: address.coutry_desc,
      // // };
      // // //@ts-ignore
      // // addressObject.province = {
      // //   value: address.p_province,
      // //   label: address.state_desc,
      // // };
      // // //@ts-ignore
      // // addressObject.city = {
      // //   value: address.p_city,
      // //   label: address.city_desc,
      // // };

      // let countryaddressObject = {
      //   value: address.p_country,
      //   label: address.coutry_desc,
      // };
      // setValue("country", countryaddressObject);

      // let provinceaddressobject = {
      //   value: address.p_province,
      //   label: address.state_desc,
      // };
      // setValue("province", provinceaddressobject);
      // let cityaddressObject = {
      //   value: address.p_city,
      //   label: address.city_desc,
      // };
      // setValue("city", cityaddressObject);
      // setValue("patadddet_pnt_name", address.patadddet_pnt_name);
      // setValue("patadddet_pnt_last_name", address.patadddet_pnt_last_name);
      // setValue("p_pcode", address.p_pcode);
      // setValue("p_address", address.p_address);
      // // setProfileField(addressObject)

      // setProfileField((prev: any) => {
      //   return {
      //     ...prev,
      //     city: cityaddressObject,
      //     province: provinceaddressobject,
      //     country: countryaddressObject,
      //     patadddet_pnt_name: address.patadddet_pnt_name,
      //     patadddet_pnt_last_name: address.patadddet_pnt_last_name,
      //     p_address: address.p_address,
      //     p_pcode: address.p_pcode,
      //     p_patadd_id: address.p_patadd_id,
      //     patientid: address.patadddet_id,
      //     default_address_flag: address.default_address_flag,
      //     patadddet_id: address.patadddet_id,
      //     coutry_desc: address.coutry_desc,
      //     city_desc: address.city_desc,
      //     state_desc: address.state_desc,
      //     patadddet_status_flag: address.patadddet_status_flag,
      //     p_city: address.p_city,
      //     p_province: address.p_province,
      //     p_country: address.p_country,
      //   };
      // });
    } catch (error) {
      console.error(error);
    }
  };

  //  SET PROVINCES IN THIS FUNCTION

  const onCountrySelectHandler = (e: any) => {
    try {
      setProvince([]);
      if (suggestUsInit?.returnCode) {
        setLoadingProvince(true);
        CallAPI(config?.province_process_id, { cnt_id: e?.value }).then(
          (res) => {
            if (res?.returnCode) {
              setProvince([
                ...res?.returnData?.map((item: any) => {
                  return {
                    value: item?.stt_id,
                    label: item?.stt_description,
                  };
                }),
              ]);
              setLoadingProvince(false);
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  SET CITIES VALUES  USING FUNCTIONS

  const onProvinceSelectHandler = (e: any) => {
    try {
      setCities([]);
      if (suggestUsInit?.returnCode) {
        setLoadingCities(true);
        CallAPI(config?.city_process_id, { p_stt_id: e?.value }).then((res) => {
          if (res?.returnCode) {
            setCities([
              ...res?.returnData?.map((item: any) => {
                return {
                  value: item?.cit_id,
                  label: item?.cit_description,
                };
              }),
            ]);
            setLoadingCities(false);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  INPUT TYPE ENTER IN FORMS

  const onInputChangeHandler = (e: any) => {
    if (e.target.value.trim() !== "") {
      setProfileField((prev: any) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setProfileField((prev: any) => {
        return { ...prev, [e.target.name]: "" };
      });
    }

  };

  //  ADD NEW ADDRESS  RESET FIELDS

  const handleAddNewAddress = () => {
    try {
      let updateAddress = addresses.map((items: any) => {
        return { ...items, isSelected: false };
      });
      setAddresses(updateAddress);
      setIsNewEntry(true);
      //@ts-ignore
      // document.querySelector("#addresscheckbox").checked = false;
      setProfileField({});
      // resetField("patadddet_pnt_name", { defaultValue: "" });
      // resetField("patadddet_pnt_last_name", { defaultValue: "" });
      // @ts-ignore
      setValue("patadddet_pnt_name", jsonProfileData?.usr_first_name);
      // @ts-ignore
      setValue("patadddet_pnt_last_name", jsonProfileData?.usr_last_name);
      //@ts-ignore
      resetField("p_pcode", { defaultValue: "" });
      resetField("p_address", { defaultValue: "" });
      setValue("country", "");
      setValue("province", "");
      setValue("city", "");
      setChecked(false);
      setLocation("")
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteHandler = (id: any) => {
    setIsDeleteConfirm({
      isTrue: true,
      id: id,
    });
    // try {
    //   if (suggestUsInit?.returnCode === true) {
    //     CallAPI(config?.patient_address_delete_process_id, {
    //       p_patadd_id: id,
    //     }).then((res) => {
    //       if (res?.returnCode) {
    //         getAllAddress();
    //         propGetAllAddress && propGetAllAddress();
    //         propsGetDeafultAddress && propsGetDeafultAddress();
    //       }
    //     });
    //   }

    // } catch (error) {
    //   console.error(error);
    // }

    // //@ts-ignore
    // reset();
  };
  useEffect(() => {
    try {
      if (addresses.length === 0) {
        // resetField("patadddet_pnt_name", { defaultValue: "" });
        // resetField("patadddet_pnt_last_name", { defaultValue: "" });
        resetField("p_pcode", { defaultValue: "" });
        resetField("p_address", { defaultValue: "" });
        setValue("country", "");
        setValue("province", "");
        setValue("city", "");
        setLocation("")
        // setProfileField({});
        setChecked(false);
        setTempSelected((prev: any) => {
          return { ...prev, temp: "" };
        });
        // propSetSelectedAddresses({
        //   billingAddress : {},
        //   deliveryAddress : {}
        //  })
      }
    } catch (error) {
      console.error(error);
    }
  }, [addresses]);

  useEffect(() => {
    try {
      // if (isSelecting) {
      if (JSON.stringify(selectedAddress) !== "{}") {
        setValue("country", {
          //@ts-ignore
          value: selectedAddress?.p_country,
          //@ts-ignore
          label: selectedAddress?.coutry_desc
        })
        setProfileField((prev: any) => {
          return {
            ...prev,
            country: {
              //@ts-ignore
              value: selectedAddress?.p_country,
              //@ts-ignore
              label: selectedAddress?.coutry_desc
            },
            //@ts-ignore
            p_province: selectedAddress?.p_country,
            //@ts-ignore
            state_desc: selectedAddress?.coutry_desc,
            //@ts-ignore
            p_patadd_id: selectedAddress.p_patadd_id || "",
            //@ts-ignore
            patadddet_id: selectedAddress.patadddet_id || "",
          };
        });
      } else {
        if (country?.length > 0 && modalType === "edit") {
          country &&
            setValue("country", country.find((i: any) => i));
        }
        if (country?.length > 0 && addressDetails?.length > 0) {
          const selectedCountry: any = addressDetails?.find((i: any) => i?.types === "country");
          const newConntry = country.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedCountry?.long_name?.trim()?.toLowerCase());
          setValue("country", newConntry);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [country, addressDetails, selectedAddress])

  useEffect(() => {
    try {
      // if (isSelecting) {
      if (JSON.stringify(selectedAddress) !== "{}") {
        setValue("province", {
          //@ts-ignore
          value: selectedAddress?.p_province,
          //@ts-ignore
          label: selectedAddress?.state_desc
        })
        setProfileField((prev: any) => {
          return {
            ...prev,
            province: {
              //@ts-ignore
              value: selectedAddress?.p_city,
              //@ts-ignore
              label: selectedAddress?.city_desc
            },
            //@ts-ignore
            p_province: selectedAddress?.p_provincev,
            //@ts-ignore
            state_desc: selectedAddress?.state_desc,
            //@ts-ignore
            p_patadd_id: selectedAddress.p_patadd_id || "",
            //@ts-ignore
            patadddet_id: selectedAddress.patadddet_id || "",
          };
        });
      } else {
        if (province?.length > 0 && modalType === "edit") {
          province &&
            setValue("province", province.find((i: any) => i));
        }
        if (province?.length > 0 && addressDetails?.length > 0) {
          const selectedProvince: any = addressDetails?.find(
            (i: any) => i?.types === "administrative_area_level_1"
          );
          const newProvince = province.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedProvince?.long_name?.trim()?.toLowerCase() || "");
          setValue("province", newProvince || {});
          // setProfileField((prev: any) => { return { ...prev, p_province: newProvince?.value } })
        }

      }
    } catch (error) {
      console.error(error);
    }
  }, [province, addressDetails, selectedAddress]);

  useEffect(() => {
    try {
      if (JSON.stringify(selectedAddress) !== "{}") {
        setValue("city", {
          //@ts-ignore
          value: selectedAddress?.p_city,
          //@ts-ignore
          label: selectedAddress?.city_desc
        })
        setProfileField((prev: any) => {
          return {
            ...prev,
            city: {
              //@ts-ignore
              value: selectedAddress?.p_city,
              //@ts-ignore
              label: selectedAddress?.city_desc
            },
            //@ts-ignore
            p_city: selectedAddress?.p_city,
            //@ts-ignore
            city_desc: selectedAddress?.city_desc,
            //@ts-ignore
            p_patadd_id: selectedAddress.p_patadd_id || "",
            //@ts-ignore
            patadddet_id: selectedAddress.patadddet_id || "",
          };
        });
      } else {
        if (cities?.length > 0 && modalType === "edit") {
          cities &&
            setValue("city", cities.find((i: any) => i));
        }
        if (cities?.length > 0 && addressDetails?.length > 0) {
          const selectedCity: any = addressDetails?.find(
            (i: any) => i?.types === "locality"
          );
          const newCity = cities.find((i: any) => i?.label?.trim()?.toLowerCase() === selectedCity?.long_name?.trim()?.toLowerCase() || i?.label?.trim()?.toLowerCase() === selectedCity?.short_name?.trim()?.toLowerCase());
          setValue("city", newCity);
          let postalCode: any = addressDetails?.find((i: any) => i?.types === "postal_code");
          postalCode = postalCode ? postalCode?.long_name || postalCode?.short_name : "";
          // setProfileField((prev: any) => { return { ...prev, p_city: newCity?.value || "", p_pcode: postalCode?.long_name } })
          setValue("p_pcode", `${postalCode}`);
          setProfileField((prev: any) => {
            return { ...prev, p_pcode: postalCode }
          })
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [cities, addressDetails, selectedAddress]);

  // SUBMIT BUTTON FUNCTION FUNCTIONALITY

  const onAddressSubmitHandler = (formData: any) => {
    try {
      if (profileField) {
        let params = {};
        //@ts-ignore
        params.p_address_id = profileField?.p_patadd_id || "";
        //@ts-ignore
        params.p_pnt_id = decryptData(getID(SG_PATIENT_ID)) || "";
        //@ts-ignore
        params.p_patadd_line_1 = formData?.p_address?.trim() || "";
        //@ts-ignore
        params.p_patadd_city_id = formData?.city?.value || "";
        //@ts-ignore
        params.p_patadd_state_id = formData?.province?.value || "";
        //@ts-ignore
        params.p_patadd_country_id = formData?.country?.value || "";
        //@ts-ignore
        params.p_patadd_zipcode = formData?.p_pcode?.trim() || "";
        //@ts-ignore
        params.p_patadddet_pnt_name = formData?.patadddet_pnt_name?.trim() || "";
        //@ts-ignore
        params.p_patadddet_pnt_last_name = formData?.patadddet_pnt_last_name?.trim() || "";
        //@ts-ignore
        params.p_default_address_flag = profileField?.default_address_flag || "N";
        if (suggestUsInit?.returnCode === true) {
          submitLoading(true);
          CallAPI(config?.patient_address_add_update_process_id, params).then(
            (res) => {
              if (res?.returnCode) {
                resetField("patadddet_pnt_name", { defaultValue: jsonProfileData?.usr_first_name });
                resetField("patadddet_pnt_last_name", { defaultValue: jsonProfileData?.usr_last_name });
                resetField("p_pcode", { defaultValue: "" });
                resetField("p_address", { defaultValue: "" });
                setValue("country", "");
                setValue("province", "");
                setValue("city", "");
                setLocation("")
                setChecked(false);
                //@ts-ignore
                // document.querySelector("#addresscheckbox").checked = false;
                setProfileField({});
                getAllAddress();
                // propsGetDeafultAddress && propGetAllAddress()
                // propsGetDeafultAddress && propsGetDeafultAddress()
                setIsNewEntry(false);
                submitLoading(false);
              } else {
                submitLoading(false);
              }
            }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSelection = (e: any, name: any) => {
    try {
      setProfileField((prev: any) => {
        return { ...prev, [name]: e };
      });
    } catch (error) {
      console.error(error);
    }
  };
  // containerClassBackGroundClass,
  // mainContainerClass,
  // rowContainerClass
  const handleSelectAddressFromLabel = (address: any) => {
    try {
      //call api to save data in cart
      if (isAddAddressPopup?.addressType === "DELIVERY") {
        propSetSelectedAddresses((prev: any) => {
          return { ...prev, deliveryAddress: address };
        });
        setTempSelected((prev: any) => {
          return { ...prev, perma: address?.patadddet_id };
        });
        // setPermaSetSelect((prev:any)=>{
        //   return {...prev, delivery : address}
        // })
      }
      if (isAddAddressPopup?.addressType === "BILLING") {
        propSetSelectedAddresses((prev: any) => {
          return { ...prev, billingAddress: address };
        });
        setTempSelected((prev: any) => {
          return { ...prev, perma: address?.patadddet_id };
        });
        // setPermaSetSelect((prev:any)=>{
        //   return {...prev, billing : address}
        // })
      }
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={Styles[containerClassBackGroundClass]}>
      <div className={Styles[mainContainerClass]}>
        <div className={Styles[rowContainerClass]}>
          <div className={Styles["modal_deliveryaddress_row_firstContainer"]}>
            <div className={Styles["title"]}>
              {/* <Heading className={`${Styles?.page_heading}`}> */}
              {/* <h3> {Config?.heading_deliveryaddress_modal}</h3> */}
              {/* </Heading> */}
            </div>
            <div className={Styles["modal_firstContainer_topelement"]}>
              {isLoading ? (
                <ShimmerTable row={3} col={1} />
              ) : (
                // <ShimmerText line={6} gap={10}  variant="primary"/>
                <>
                  {addresses.length > 0 ? (
                    addresses.map((items: any, index: any) => {
                      const isDefaultAddress = items?.default_address_flag === "Y" || false;
                      return (
                        <div
                          className={Styles["modal_topelement1"]}
                          key={items.id}
                        >
                          <div
                            className={Styles["modal_topelementaddress"]}
                            onClick={() => {
                              handleAddressSelect(items);
                            }}
                          >
                            {items.isSelected ? (
                              <img src={checkedicon} alt="Selected Image" />
                            ) : (
                              <img src={notSelectedAddressImg} />
                            )}
                            {/* <Checkbox></Checkbox> */}
                            <span className={Styles["modal_topelementcontent"]}>
                              {items.patadddet_pnt_name +
                                " " +
                                items?.patadddet_pnt_last_name}
                              <p></p>
                              <p>{items.p_address}</p>
                              <p>{items.city_desc + " " + items?.state_desc}</p>
                              <p>{items.coutry_desc + " " + items?.p_pcode}</p>
                            </span>
                          </div>
                          <div
                            className={Styles["add_new_address_btn_wrapper"]}
                          >
                            <IconButton
                              title="Delete Address"
                              className={Styles["penFill"]}
                              onClick={() => {
                                onDeleteHandler(items?.p_patadd_id);
                              }}
                            >
                              {/* <img
                                  src={Delete_icon}
                                  alt="Delete btn"
                                  draggable="false"
                                /> */}
                              <i className="fa-solid fa-trash-can"></i>
                            </IconButton>
                            {isAddressPage ? (
                              ""
                            ) : isManagingAddress ? (
                              ""
                            ) : //@ts-ignore
                              tempSelected?.perma === items?.patadddet_id ? (
                                <span className={Styles?.selectedLabel}>
                                  {Config?.lbl_selected}
                                </span>
                              ) : (
                                ""
                              )}
                          </div>
                          {isDefaultAddress && <div className={Styles['defaultAddressDiv']}>
                            {Config?.lbl_default_address}
                          </div>}
                        </div>
                      );
                    })
                  ) : (
                    <div className={Styles["noAddressFound"]}>
                      {Config?.myaddress_no_address}
                    </div>
                  )}
                </>
              )}
            </div>
            {addresses.length > 0 ? (
              <div
                className={Styles["modal_firstContainer_bottomelement_text"]}
              >
                <h4
                  onClick={() => {
                    handleAddNewAddress();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {Config?.text_deliveryaddress_modal}
                </h4>
              </div>
            ) : (
              ""
            )}

            {isAddressPage
              ? ""
              : addresses.length > 0 &&
              tempSelected?.temp !== "" && (
                <div className={Styles?.footer_buttons}>
                  {
                    <Button
                      type={Config?.lbl_Btn_type_ghost}
                      size={Config?.lbl_Btn_size_medium}
                      htmlType={"button"}
                      onClick={() => {
                        //@ts-ignore
                        if (
                          selectedAddressFromEdit?.patadddet_id !==
                          profileField?.patadddet_id
                        )
                          handleSelectAddressFromLabel(profileField);
                        setModalOpen(false);
                      }}
                      className={`${Styles?.ghostBtn}  ${Styles?.ghost_cancel_btn}`}
                    >
                      {/* {btnConfig?.newRx_Ghost_Text_V1} */}
                      {Config?.lbl_select}
                    </Button>
                  }
                </div>
              )}
          </div>

          <div className={Styles["modal_deliveryaddress_row_secondContainer"]}>
            <div
              className={Styles["titleCloseBtn"]}
              title="Close button"
              onClick={() => {
                setTempSelected({
                  temp: "",
                  perma: "",
                });
                setModalOpen(false);
              }}
              draggable="false"
            >
              <img src={CloseIcon} alt="Cross icon" draggable="false" />
            </div>
            <form
              onSubmit={handleSubmit(onAddressSubmitHandler)}
              className={Styles["modal_deliveryaddress_mainform"]}
              autoComplete={siteConfig?.form_auto_complete_flag}
            >
              <div className={Styles["modal_deliveryaddress_form"]}>
                <div className={`${Styles?.form_inner_control}`}>
                  <span>
                    <Label
                      className={`${Styles?.form_label}`}
                      for="patadddet_pnt_name"
                    >
                      {Config?.form_deliveryaddress_modalelementname}
                    </Label>
                    <FormInput
                      name="patadddet_pnt_name"
                      id="patadddet_pnt_name"
                      type="text"
                      placeholder={Config?.address_placeholder_1}
                      size="small"
                      register={register}
                      rules={{
                        required: msg_config?.first_name_required,
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: "Please enter a valid First name.",
                        },
                      }}
                      value={profileField?.patadddet_pnt_name}
                      onInputChangeHandler={onInputChangeHandler}
                      errors={errors}
                      maxLength={25}
                    />
                  </span>
                  <span>
                    <Label
                      className={`${Styles?.form_label}`}
                      for="patadddet_pnt_last_name"
                    >
                      {Config?.form_deliveryaddress_modalelementlastname}
                    </Label>
                    <FormInput
                      name="patadddet_pnt_last_name"
                      id="patadddet_pnt_last_name"
                      type="text"
                      placeholder={Config?.address_placeholder_2}
                      size="small"
                      register={register}

                      rules={{
                        required: msg_config?.last_name_required,
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: "Please enter valid Last name.",
                        },
                      }}
                      errors={errors}
                      maxLength={25}
                      // value={selectedAddresses?.patadddet_pnt_last_name|| profileField?.lastName}
                      value={profileField?.patadddet_pnt_last_name}
                      onInputChangeHandler={onInputChangeHandler}
                    />
                  </span>
                </div>
                <div
                  className={`${Styles?.form_inner_control} ${Styles?.address_edit}`}
                >
                  <span className={`${Styles?.form_Address}`}>
                    <Label className={`${Styles?.form_label}`} for="address">
                      {Config?.form_deliveryaddress_modalelement1}
                    </Label>
                    {/* <FormInput
                      name="p_address"
                      id="p_address"
                      type={Config?.lbl_Inp_type_text}
                      placeholder={"Address"}
                      size={Config?.lbl_Inp_size_small}
                      register={register}
                      value={profileField?.p_address}
                      rules={{
                        required: msg_config?.addaddress_housenumber,

                      }}
                      errors={errors}
                      maxLength={100}
                      onInputChangeHandler={onInputChangeHandler}
                    // onInputChangeHandler={(e: any) => {
                    //   onInputChangeHandler(e, "address");
                    // }}
                    /> */}
                    <LocationSearchInput
                      name="p_address"
                      setState={setLocation}
                      state={location}
                      register={register}
                      setaddressDetails={setaddressDetails}
                      rules={{ required: msg_config?.address_required }}
                      setValue={setValue}
                      watch={watch}
                      clearErrors={clearErrors}
                      errors={errors}
                      placeholder={Config?.address_placeholder_3}
                      isFormattedAddress={true}
                      maxLength={150}
                      setAddressForFill={setAddressForFill}
                      setProfileField={setProfileField}
                      setSelectedAddress={setSelectedAddress}
                    />
                  </span>
                  {/* <span>
                      <Label className={`${Styles?.form_label}`} for="p_street">
                        {Config?.form_deliveryaddress_modalelement2}
                      </Label>
                      <FormInput
                        name="p_street"
                        id="p_street"
                        type={Config?.lbl_Inp_type_text}
                        placeholder={"Enter Your Street"}
                        size={Config?.lbl_Inp_size_small}
                        register={register}
                        value={
                          //@ts-ignore
                          selectedAddresses.p_street || profileField?.p_street
                        }
                        errors={errors}
                        maxLength={100}
                        onInputChangeHandler={onInputChangeHandler}
                      />
                    </span> */}
                </div>
                <div className={`${Styles?.form_inner_control}`}>
                  <span className={`${Styles?.form_country}`}>
                    <Label className={`${Styles?.form_label}`} for="country">
                      {Config?.form_deliveryaddress_modalelement5}
                    </Label>

                    <SelectDropdown
                      name="country"
                      options={country}
                      // options={selectedAddresses?.p_country!== "" ? [{ value: selectedAddresses?.p_country, label: selectedAddresses?.coutry_desc}, {...country}] : {...country}}
                      size="small"
                      placeholder={Config?.address_placeholder_4}
                      isClearable={true}
                      loading={loadingCountry}
                      control={control}
                      rules={{ required: msg_config?.country_required }}
                      errors={errors}
                      op_style={{
                        fontSize: "16px",
                      }}
                      sg_style={{
                        fontSize: "16px",
                      }}
                      onSelectChange={(e: any) => {
                        onChangeSelection(e, "country");
                      }}
                    />
                  </span>
                  <span className={`${Styles?.form_province}`}>
                    <Label className={`${Styles?.form_label}`} for="province">
                      {Config?.form_deliveryaddress_modalelement4}
                    </Label>

                    <SelectDropdown
                      name="province"
                      options={province}
                      size="small"
                      placeholder={Config?.address_placeholder_5}
                      isClearable={true}
                      loading={loadingProvince}
                      control={control}
                      disabled={!_country}
                      rules={{ required: msg_config?.province_required }}
                      onSelectChange={(e: any) => {
                        onChangeSelection(e, "province");
                      }}
                    />
                  </span>
                </div>
                <div className={`${Styles?.form_inner_control}`}>
                  <span className={`${Styles?.form_city}`}>
                    <Label className={`${Styles?.form_label}`} for="city">
                      {Config?.form_deliveryaddress_modalelement3}
                    </Label>

                    <SelectDropdown
                      name="city"
                      options={cities}
                      size="small"
                      placeholder={Config?.address_placeholder_6}
                      isClearable={true}
                      loading={loadingCities}
                      control={control}
                      disabled={!_province}
                      rules={{
                        required: msg_config?.city_required,
                      }}
                      onSelectChange={(e: any) => {
                        onChangeSelection(e, "city");
                      }}
                    />
                  </span>
                  <span>
                    <Label className={`${Styles?.form_label}`} for="postalcode">
                      {Config?.form_deliveryaddress_modalelement6}
                    </Label>

                    <FormInput
                      name="p_pcode"
                      id="p_pcode"
                      type={Config?.lbl_Inp_type_text}
                      placeholder={Config?.address_placeholder_7}
                      size={Config?.lbl_Inp_size_small}
                      register={register}
                      value={profileField?.p_pcode}
                      onInputChangeHandler={onInputChangeHandler}
                      rules={{
                        required: msg_config?.zip_code_required,
                        minLength: {
                          value: 5,
                          message: "Postal Code must be at least 5 characters long.",
                        },

                      }}
                      errors={errors}
                      maxLength={9}
                    />
                  </span>
                </div>
                <div
                  className={`${Styles?.form_checkbox}`}
                  style={{ marginTop: "35px", marginBottom: "35px" }}
                >
                  {/* <Checkbox
                     //@ts-ignore
                     id="addresscheckbox"
                    name="Set as default address"
                    // value={checked ? true : false}
                    onChange={handleChange}
                  /> */}
                  <input
                    type="checkbox"
                    id="default_address_flag"
                    name="default_address_flag"
                    onChange={handleChange}
                    checked={checked}
                  />
                  <Label
                    className={`${Styles?.form_label_setaddress}`}
                    for="checkbox"
                    onClick={() => handleLabel()}
                  //@ts-ignore
                  // onClick={() => {
                  //   if(profileField?.default_address_flag == "N" || profileField?.default_address_flag == ""){
                  //     //@ts-ignore
                  //     // document.querySelector("#addresscheckbox").checked=true;
                  //   }else{
                  //     //@ts-ignore
                  //     document.querySelector("#addresscheckbox").checked=false;
                  //   }
                  //         }}
                  >
                    {Config?.form_deliveryaddress_modalelement7}
                  </Label>
                </div>
                <div className={Styles["modalpopup_button"]}>
                  <Button
                    type={Config?.lbl_Btn_type_ghost}
                    size={Config?.lbl_Btn_size_medium}
                    // onClick={() => {}}
                    className={`${Styles?.ghostBtn}`}
                    loading={submitLoad}
                  >
                    {/* {isNewEntry || addresses.length === 0 ? Config?.lbl_add_address : Config?.lbl_update_address} */}
                    {isNewEntry || addresses.length === 0
                      ? Config?.lbl_add_address
                      : Config?.lbl_update_address}
                    {/* {Object.keys(profileField).length === 0 ? 'Add' : 'Update'} */}
                    {/* {profileField === "" ? (
                       btnConfig?.uploadRx_Add
                      ) : (
                        btnConfig?.signUp_Primary_Text_Update
                      )} */}
                    {/* {handleAddNewAddress? 'Add' : 'Update'} */}
                    {/* {btnConfig?.uploadRx_Add} */}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isDeleteConfirm?.isTrue && (
        <ConfirmDeletePopup
          setModalOpen={setIsDeleteConfirm}
          isDeleteConfirm={isDeleteConfirm}
          getAllAddress={getAllAddress}
          propsGetDeafultAddress={propsGetDeafultAddress}
          propGetAllAddress={propGetAllAddress}
        />
      )}
    </div>
  );
};
export default AddNewAddress;
