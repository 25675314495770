import React, { useContext, useEffect, useState, Fragment } from "react";
import Slider from "react-slick";
import styles from "./index.module.css";
import APIContext from "../../../store/api-context";
import { CallAPI } from "../../../suggest-us/callSuggest.js";
import { config } from "../../../config/processId.js";
import BrandProductCard from "../../../components/BrandProductCard";
import WidgetCard from "../../../components/UI/WidgetCard";
import { H3 } from "../../../components/UI/Typography";
import { Link } from "react-router-dom";
import btnConfig from "../../../config/button";
import { ChevronLeft, ChevronRight } from "../../../utils/appIcons";
import AppLink from "../../../components/UI/AppLink";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { color_config } from "../../../config/colorConfig";

export type productsProps = {
  widget: any;
};

const BrandWidget: React.FC<productsProps> = (props) => {
  const [brandContext, setBrandContext] = useState([]);

  const [suggestUsInit] = useContext(APIContext);
  const { widget } = props;
  const { widget_type_reference_details_view_method: widgetType } = widget || {
    widget_type_reference_details_view_method: "",
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.next_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronRight size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${styles?.prev_arrow} ${className}`}
        style={{
          ...style,
          display: "grid",
          placeItems: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <ChevronLeft size="25" font="2" color={color_config?.black_color} />
      </div>
    );
  }

  const horizontal_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const thumb_settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const HorizontalScroll = () => {
    return (
      <Fragment>
        <div className={`${styles?.brand_section}`}>
          <div className="outer_container">
        <div className={`flex items-center justify-between ${styles.headingBrands}`}>
          <div className="flex items-center gap-2">
            {/* <img src={brand} alt={`${widget?.html_description} icon`} /> */}
            <H3 className={`${styles?.title}`} style={{ color: `` }}>
              {widget?.html_description}
            </H3>
          </div>
          {/* <Link to="" className={`${styles?.linkButton}`} draggable='false'>
            {btnConfig?.widget_link_button}
          </Link> */}
        </div>
        <Slider {...horizontal_settings}>
          {brandContext.length === 0
            ? [1, 2, 3, 4, 5]?.map((item: any) => {
                return (
                  <div className={`${styles?.widget_item_wrapper}`} key={item}>
                    <ShimmerThumbnail height={250} rounded />
                  </div>
                );
              })
            : Array?.isArray(brandContext) &&
              brandContext?.map((product: any, idx : any) => {
                return (
                  <div className={`${styles?.widget_item_wrapper}`} key={idx}>
                    <AppLink to={`/product-brand/${product?.manuf_id}`} type="">
                      <BrandProductCard
                        viewType={"Grid"}
                        // productUrl=""
                        imgUrl={product?.srv_brand_image}
                        imgAlt={product?.manuf_description}
                        brand={product?.manuf_description}
                      />
                    </AppLink>
                  </div>
                );
              })}
        </Slider>
        </div>
        </div>
      </Fragment>
    );
  };

  const ThumbnailView = () => {
    return (
      <Fragment>
        <div className="flex items-center gap-2 justify-between">
          <H3
            className={`${styles?.title}`}
            style={{ color: `${widget?.color_class}` }}
          >
            {widget?.html_description}
          </H3>
          <Link to="" className={`${styles?.linkButton}`} draggable="false">
            {btnConfig?.widget_link_button}
          </Link>
        </div>
        <Slider {...thumb_settings}>
          {brandContext.length === 0
            ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((item: any) => {
                return (
                  <div className={`${styles?.widget_item_wrapper}`} key={item}>
                    <ShimmerThumbnail height={200} rounded />
                  </div>
                );
              })
            : Array?.isArray(brandContext) &&
              brandContext?.map((product: any, idx :any) => {
                return (
                  <div className={`${styles?.widget_item_wrapper}`} key={idx}>
                    <AppLink to={`/product-list/${product?.manuf_id}`} type="">
                      <BrandProductCard
                        viewType={"Grid"}
                        // productUrl=""
                        imgUrl={product?.srv_brand_image}
                        imgAlt={product?.manuf_description}
                        brand={product?.manuf_description}
                      />
                    </AppLink>
                  </div>
                );
              })}
        </Slider>
      </Fragment>
    );
  };

  const getWidget = () => {
    switch (widgetType) {
      case "horizontal_scroll":
        return <HorizontalScroll  key={widgetType} />;
      case "thumbs":
        return <ThumbnailView />;

      default:
        break;
    }
  };

  const getMaxOffset = () => {
    switch (widgetType) {
      case "horizontal_scroll":
        return { p_max_offset: "15" };
      case "thumbs":
        return { p_max_offset: "8" };

      default:
        break;
    }
  };

  useEffect(() => {
    if (suggestUsInit?.returnCode === true) {
      CallAPI(config?.brand_list_process_id, { ...getMaxOffset() }).then(
        (res: any) => {
          if (res?.returnCode) {
            res?.returnData.length > 0 && setBrandContext(res?.returnData);
          }
        }
      );
    }
  }, [suggestUsInit]);

  return (
    <WidgetCard
      className={`${styles?.container}`}
      style={{ order: `${widget?.sequence}` }}
    >
      {getWidget()}
    </WidgetCard>
  );
};

export default BrandWidget;
