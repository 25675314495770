import SignUpVendorContainer from "../SignUpVendor/SignUpVendorContainer/SignUpVendorContainer";
import Styles from "./index.module.css";
import { CloseIcon } from "../../utils/appIcons";
import Heading from "../../components/UI/Heading";
import Button from "../Landingpage/Button";
import Config from "../../config/label";
import { useDispatch } from "react-redux";
import { mylistPageActions } from "./slice";
import { config } from "../../config/processId";
import { CallAPI } from "../../suggest-us/callSuggest";
import { useContext } from "react";
import { UserAuthContext } from "../../store/UserAuthContext";
import { useSelector } from "react-redux";

import { decryptData } from "../../utils/util_helper";
import { getID } from "../../utils/utils";
import { SG_USER_ID } from "../../config/Config";
import { CONFIRM_DELETE_POPUP_IMAGE } from "../../config/Config";
const DeleteConfirmPopup = (props) => {
  const {
    setIsAlert,
    selectedList,
    setModalOpen,
    userProfileJson,
    p_list_id = "",
    setmyListRefresh,
    showDeleteConfirm,
    setSelectedList
  } = props;
  const dispatch = useDispatch();
  const context = useContext(UserAuthContext);
  const products = useSelector(
    (state) => state?.myListPageReducer?.filteredProducts
  );
  let params = {};
  if (showDeleteConfirm?.deleteType === "product") {
    params.p_cus_id = userProfileJson?.customer_id;
    params.p_usr_id = userProfileJson?.usr_id;
    params.p_mylist_id = selectedList?.selectId || "";
    params.p_internal_flag = "";
    params.p_srv_id = showDeleteConfirm.p_srv_id;
  } else {
    params.p_cus_id = userProfileJson?.patient_id;
    // params.sgUserId =  localStorage.getItem("sg_userId");
    params.p_list_id = selectedList?.optId;
  }
  // const params = {
  //   p_cus_id: userProfileJson?.customer_id,
  //   sgUserId: localStorage.getItem("sg_userId"),
  //   p_list_id: p_list_id,
  // }

  const processId =
    showDeleteConfirm.deleteType === "product"
      ? config?.pro_user_remove_item_my_list_process_id
      : config?.pro_user_disable_my_list_process_id;

  return (
    <SignUpVendorContainer>
      <div className={Styles["modalBackground"]}>
        <div className={Styles["modalContainer"]}>
          <div
            className={Styles["titleCloseBtn"]}
            title="Close button"
            onClick={() => setModalOpen(false)}
          >
            <img src={CloseIcon} alt="Cross icon" draggable="false" />
          </div>
          <div className={Styles["title"]}>
            <div className={Styles["checkIconWrapper"]}>
              <img src={CONFIRM_DELETE_POPUP_IMAGE} alt="Cart icon" draggable="false" />
            </div>
            <Heading className={`${Styles?.page_heading}`}>
              {Config?.lbl_delete_req_txt}
            </Heading>
            <p></p>
          </div>
          <div className={Styles["buttons"]}>
            <Button className={Styles["login_cancel_btn"]}
              type={Config?.lbl_Btn_type_ghost}
              size={Config?.lbl_Btn_size_medium}
              onClick={() => setModalOpen(false)}
            >
              {Config?.lbl_cancel_txt}
            </Button>

            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              onClick={async () => {
                try {
                  let res = await CallAPI(processId, params);
                  if (res?.returnCode === true) {
                    if (showDeleteConfirm.deleteType === "product") {
                      const resGetMyList = await CallAPI(
                        config?.pro_user_get_my_list_process_id,
                        {
                          p_cus_id: userProfileJson?.customer_id,
                          // sgUserId: localStorage?.getItem("sg_userId"),
                          p_srv_id: "",
                        }
                      );
                      dispatch(
                        mylistPageActions?.SET_MY_LISTS({ myLists: resGetMyList.returnData })
                      );
                    } else {
                      dispatch(
                        mylistPageActions?.REMOVE_LIST({
                          p_list_id: p_list_id,
                        })
                      );
                      setSelectedList((prev) => {
                        return { ...prev, optId: ""  , selectId : ""}
                      })
                       const resGetMyList = await CallAPI(
                        config?.pro_user_get_my_list_process_id,
                        {
                          p_cus_id: userProfileJson?.customer_id,
                          // sgUserId: localStorage?.getItem("sg_userId"),
                          p_srv_id: "",
                        }
                      );
                      dispatch(
                        mylistPageActions?.SET_MY_LISTS({ myLists: resGetMyList.returnData })
                      );

                    }
                    setModalOpen(false);
                    setmyListRefresh((prevState) => !prevState);
                  } else {
                    setIsAlert &&
                      setIsAlert({
                        isTrue: true,
                        type: "error",
                        msg: `An unknown error occurred while deleting list!`,
                      });
                  }
                } catch (error) {
                  setIsAlert &&
                    setIsAlert({
                      isTrue: true,
                      type: "error",
                      msg: `An error occurred while processing your request. Please try again later.`,
                    });
                }
              }}
            >
              {Config?.lbl_otp_confirm}
            </Button>
          </div>
        </div>
      </div>
    </SignUpVendorContainer>
  );
};

// Process Id: xcelecom_update_trn_cus_mylist_disable
// Process Input Flag: Y
// Process Grid Code:
// Input Parameters:
// 1. p_cus_id (datajson)
// 2. sgUserId (userdata)
// 3. p_list_id (datajson)

export default DeleteConfirmPopup;
