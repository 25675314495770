import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.css";
import SideBar from "../../components/ProfileSideBar/SideBar/index";
import Button from "../../components/UI/Button";
import Heading from "../../components/UI/Heading";
import Label from "../../components/UI/Label";
import { CallAPI } from "../../suggest-us/callSuggest";
import APIContext from "../../store/api-context";
import Config from "../../config/label";
import { config } from "../../config/processId";
import { PersonFill } from "../../utils/appIcons";
import EditProfile from "./EditProfile";
import ProfileImage from "../../assets/png/userSkeleton.png";
import { GlobalContext } from "../../store/global-context.js";
import ManageAddress from "../../assets/svg/manage_address.svg";
import Payments from "../../assets/svg/manage_payment.svg";
import HealthInfo from "../../assets/svg/health_info.svg";
import HealthCard from "../../assets/svg/health_card.svg";
import Insurance from "../../assets/svg/insuance_card.svg";
import usersvg from "../../assets/svg/My_profile.svg";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CountUp from "react-countup";
import moment from "moment";
import Editaddress_icon from "../../assets/png/icons-edit.png";
import {
  ShimmerTitle,
  ShimmerThumbnail,
  ShimmerBadge,
} from "react-shimmer-effects";
import { JSONCheckerFunc, getDecryptedID, getID, objDecrypt, setEncryptedID } from "../../utils/utils";
import IconButton from "../../components/UI/IconButton";
import { UserAuthContext } from "../../store/UserAuthContext";
import { IS_EDITING, LOGGED_IN_USER_ROLE, PROFILE_IMAGE2, SG_PATIENT_ID, USER_PROFILE } from "../../config/Config";
import profileImg from "../../assets/proile_img.png";
import CompanyProfile from "../SignUpVendor/CompanyProfile/CompanyProfile";
import CreateAccount from "../SignUpVendor/CreateAccount";
import MyDocuments from "../../components/MyDocuments";
import TermModalPopup from "../../components/TermsModalpopup";
import DocViewer from "../../components/MyDocuments/DocViewer/DocViewer";
import { decryptData } from "../../utils/util_helper";

const MyProfile = () => {
  const userProfileJson: any = getDecryptedID(USER_PROFILE);
  const user_id = userProfileJson?.usr_id || "";
  // const userProfileJson = JSONCheckerFunc(userProfile);
  const [modalOpen, setModalOpen] = useState(false);
  const [change, setChange] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isFirstName, setIsFirstName] = useState(userProfileJson?.usr_name ||"");
  // const [isLastName, setIsLastName] = useState(userProfileJson);
  const [pathColor, setPathColor] = useState("");
  const [userInfo, setUserInfo] = useState<any>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
  const [openCreateCompanyCard, setOpenCreateCompanyCard] = useState(false);
  const [openTermModalPopup, setOpenTermModalPopup] = useState(false);
  const [openDocViewer, setOpenDocViewer] = useState({
    isOpen: false,
    docData: {},
  });
  const [checked, setChecked] = useState(false);
  const [openCompanyProfileCard, setOpenCompanyProfileCard] = useState(false);
  const [profileForm, setProfileForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    patient_photo: "",
    document_id: "",
    gender: "",
    completeness_my_address: null,
    completeness_my_health_cards: null,
    completeness_my_health_info: null,
    completeness_my_insurance_cards: null,
    completeness_my_profile: null,
  });
  const [profileData, setProfileData] = useState<any>([]);
  const [total, setTotal] = useState(0);

  const [checkDataLoading, setCheckDataLoading] = useState(true);
  const [checkData, setCheckData] = useState(true);
  const [addressesCount, setAddressesCount] = useState("0");
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const navigate = useNavigate();
  const [suggestUsInit] = useContext(APIContext);
  const [openMyDocuments, setOpenMyDocuments] = useState(false);
  const [context] = useContext(UserAuthContext);
  const [isAlert, setIsAlert] = useState({
    istrue: false,
    type: "",
    msg: "",
  });

  const userInfohandler = () => {
    if (suggestUsInit?.returnCode === true) {
      setTotal(0);
      CallAPI(config?.my_profile_process_id, {
        p_user_id: user_id,
      }).then((res) => {
        if (res?.returnCode === true) {
          setProfileData(res?.returnData[0]);
          if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
            setTotal(
              res?.returnData[0]?.completeness_my_address +
              // res?.returnData[0]?.completeness_manage_documents +
              // res?.returnData[0]?.completeness_my_health_cards +
              // res?.returnData[0]?.completeness_my_health_info +
              // res?.returnData[0]?.completeness_my_insurance_cards +
              res?.returnData[0]?.completeness_my_profile
            );
          } else {
            setTotal(
              res?.returnData[0]?.completeness_my_address +
              res?.returnData[0]?.completeness_my_health_cards +
              res?.returnData[0]?.completeness_my_health_info +
              res?.returnData[0]?.completeness_my_insurance_cards +
              // res?.returnData[0]?.completeness_manage_documents +
              res?.returnData[0]?.completeness_my_profile
            );
          }
          setUserInfo([
            {
              label: Config?.lbl_First_N,
              value:
                res?.returnData[0]?.first_name === ""
                  ? "NA"
                  : res?.returnData[0]?.first_name,
            },
            {
              label: Config?.lbl_Last_N,
              value:
                res?.returnData[0]?.last_name === ""
                  ? "NA"
                  : res?.returnData[0]?.last_name,
            },
            {
              label: Config?.lbl_Phone,
              value:
                res?.returnData[0]?.usr_phone === ""
                  ? "NA"
                  : res?.returnData[0]?.usr_phone,
            },
            {
              label: Config?.lbl_Email,
              value:
                res?.returnData[0]?.usr_email === ""
                  ? "NA"
                  : res?.returnData[0]?.usr_email,
            },
            {
              label: Config?.lbl_BirthDay,
              value:
                res?.returnData[0]?.usr_dob === null
                  ? "NA"
                  : res?.returnData[0]?.usr_dob,
            },
            {
              label: Config?.lbl_Gender,
              value:
                res?.returnData[0]?.usr_gender_code === null
                  ? "NA"
                  : res?.returnData[0]?.usr_gender_code,
            },
          ]);
          setProfileForm((prev: any) => {
            return {
              ...prev,
              firstName:
                res?.returnData[0]?.first_name === ""
                  ? ""
                  : res?.returnData[0]?.first_name,
              lastName:
                res?.returnData[0]?.last_name === ""
                  ? ""
                  : res?.returnData[0]?.last_name,
              phone:
                res?.returnData[0]?.usr_phone === ""
                  ? ""
                  : res?.returnData[0]?.usr_phone,
              email:
                res?.returnData[0]?.usr_email === ""
                  ? ""
                  : res?.returnData[0]?.usr_email,
              dob:
                res?.returnData[0]?.usr_dob === null
                  ? undefined
                  : res?.returnData[0]?.usr_dob,
              patient_photo: res?.returnData[0]?.patient_img_path,
              document_id:
                res?.returnData[0]?.customer_profile_picture_document_id,
              gender:
                res?.returnData[0]?.usr_gender_code === null
                  ? undefined
                  : res?.returnData[0]?.usr_gender_code,
              completeness_my_address:
                res?.returnData[0]?.completeness_my_address,
              completeness_my_profile:
                res?.returnData[0]?.completeness_my_profile,
              completeness_my_health_cards:
                res?.returnData[0]?.completeness_my_health_cards,
              completeness_my_health_info:
                res?.returnData[0]?.completeness_my_health_info,
              completeness_my_insurance_cards:
                res?.returnData[0]?.completeness_my_insurance_cards,
              completeness_manage_documents:
                res?.returnData[0]?.completeness_manage_documents,
            };
          });
          if (context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE) {
            // setIsFirstName(res?.returnData[0]?.usr_company_name)
          } else {
            // setIsFirstName(res?.returnData[0]?.first_name);
          }
          // setIsLastName(res?.returnData[0]?.last_name);
          setEncryptedID(PROFILE_IMAGE2,res?.returnData[0]?.patient_img_path || "");
          setLoading(false);
          setChange(false);
          setGlobalContext((prev: any) => {
            return {
              ...prev,
              profile_image: res?.returnData[0]?.patient_img_path || "",
            };
          });
        } else console.error("error", res?.error);
      });
      CallAPI(config?.fetch_added_cards, {
        p_cardholder_name: userProfileJson?.usr_name,
        environment: Config?.manpay_env,
        p_gateway_type: Config?.manpay_gateway_type,
      }).then((res) => {
        if (res?.returnCode === true) {
          setTotal((prev) => {
            return prev + 100;
          });
          setCheckDataLoading(false);
        } else {
          setCheckData(false);
          setCheckDataLoading(false);
        }
      });
    }
  };


  //calling api to get count of all address
  const fetchUserAddress = () => {
    try {
      CallAPI(config?.patient_address_list_process_id, {
        p_pnt_id: decryptData (getID(SG_PATIENT_ID)),
      }).then((res) => {
        if (res?.returnCode) {
          setAddressesCount(res.returnData.length);
        } else {
          setAddressesCount("0");
        }
      })

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    {
      total === 100 && setPathColor("#e7442e");
    }
    {
      total === 200 && setPathColor("#e7ab2e");
    }
    {
      total === 300 && setPathColor("#e1e72e");
    }
    {
      total === 400 && setPathColor("#b4e72e");
    }
    {
      total === 500 && setPathColor("#70e72e");
    }
    {
      total === 600 && setPathColor("#02b82a");
    }
  }, [suggestUsInit, change]);

  useEffect(() => {
    window.scrollTo(0, 0);
    userInfohandler();
  }, [suggestUsInit, isEditModalOpen]);

  useEffect(() => {
    fetchUserAddress();
  }, [])


  const getGender = (val: any) => {
    switch (val) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "O":
        return "Other";
    }
  };

  return (
    <>
      <div
        className={`${Styles?.mainContainer}`}
        onClick={(e) => {
          setModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="outer_container">
          <div className={`${Styles?.grid}`}>
            <div className={`${Styles?.gridLeftItem}`}>
              <SideBar modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>

            <div className={`${Styles?.gridRightItem}`}>
              {/* Header Start */}
              <div className={`${Styles?.headerContainer}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PersonFill
                    width="25"
                    height="25"
                    style={{ marginTop: "0px", color: "#32C5F4" }}
                  />

                  <h2 className={`${Styles?.heading}`}>
                    {Config?.myprofile_tab_1}
                  </h2>
                </div>
                {false && <div className={`${Styles?.progress_circle}`}>
                  {/* Documentation https://www.npmjs.com/package/react-circular-progressbar */}
                  <CircularProgressbar
                    value={Math.floor(total / 6)}
                    text={`${Math.floor(total / 6)}%`}
                    styles={buildStyles({
                      pathColor: pathColor,
                      pathTransitionDuration: 3,
                    })}
                    className={`${Styles?.progress_circle_animation}`}
                  />
                </div>}
              </div>
              {/* Header End */}

              {/* Body Start */}
              <div className={`${Styles?.profileGrid_1}`}>
                <div className={`${Styles?.profileGrid_1_child} `}>
                  {loading ? (
                    <>
                      <ShimmerThumbnail width={260} height={50} rounded />
                    </>
                  ) : (
                    <div className={Styles["personalInfoWrapper"]}>
                      <div className={Styles["checkImgWrapper"]}>
                        <img
                          src={globalContext?.profile_image || ProfileImage}
                          alt="ProfileImg"
                          draggable="false"
                        />
                        {/* <img src={profileImg} /> */}
                      </div>
                      <div className={Styles["detailsWrapper"]}>
                        <div>
                          <Heading className={`${Styles?.page_heading}`}>
                            {/* {context?.UserAuth?.usr_type === LOGGED_IN_USER_ROLE ? "" :   */}
                            <p>
                              {isFirstName?.length > 0 ? isFirstName : ""}{" "}
                              {/* {isLastName?.length > 0 ? isLastName : ""} */}
                            </p>
                          </Heading>
                          <div className={`${Styles?.profile_details}`}>
                            <div>{userProfileJson?.usr_phone && userProfileJson?.usr_phone}</div>
                            {/* 
                          <div>{profileForm?.email && profileForm?.email}</div>
                          <div>{profileForm?.dob && moment(profileForm?.dob).format("L")}</div>
                          <div>{profileForm?.gender && getGender(profileForm?.gender)}</div>*/}
                          </div>
                        </div>

                        <IconButton
                          title="Edit profile"
                          className={`${Styles?.penFill}`}
                          onClick={() => {

                            if (context?.UserAuth?.usr_type === "customer" || context?.UserAuth?.usr_type !== LOGGED_IN_USER_ROLE && context?.UserAuth?.usr_type === null) {
                              setIsEditModalOpen(true);
                              // setModalOpen(false);

                            } else {
                              setIsEditCompanyModalOpen(true);
                              setEncryptedID(IS_EDITING, "Y");
                              setOpenCreateCompanyCard(true);
                              // setOpenCompanyProfileCard(false)
                            }
                          }}
                        // onClick={() => {
                        //   setIsEditModalOpen(true);
                        // }}
                        >
                          {/* <img
                            src={Editaddress_icon}
                            alt="Edit btn"
                            draggable="false"
                          /> */}
                          <i className="fa-regular fa-pen-to-square"></i>
                        </IconButton>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${Styles?.profileGrid_1_child} ${Styles?.noOfOrders_1}`}
                  onClick={() => navigate("/my-orders")}
                >
                  <p className={`${Styles?.orderCount}`}>
                    {profileData?.order_count}
                  </p>
                  <p className={`${Styles?.orderLabel}`}>Orders</p>
                </div>

                <div
                  className={`${Styles?.profileGrid_1_child} ${Styles?.noOfOrders_1}`}
                  onClick={() => navigate("/add-address")}
                >
                  <p className={`${Styles?.orderCount}`}>
                    {addressesCount}
                  </p>
                  <p className={`${Styles?.orderLabel}`}>{Number(addressesCount) === 0 || Number(addressesCount) === 1 ? "Address" : "Addresses"} </p>
                </div>
                {/* {context?.UserAuth?.usr_type === "customer" && <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.noOfOrders_1}`}
                onClick={() => {
                  navigate("/my-insurance");
                }}
              >
                <p className={`${Styles?.orderCount}`}>
                  {profileData?.insurance_count}
                </p>
                <p className={`${Styles?.orderLabel}`}>Insurance</p>
              </div>} */}
                {/* <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
               
                if(  context?.UserAuth?.usr_type === "customer" || context?.UserAuth?.usr_type !== LOGGED_IN_USER_ROLE && context?.UserAuth?.usr_type === null){
                  setIsEditModalOpen(true);
                 
  
                }else {
                  setOpenCreateCompanyCard(true);
                
                  sessionStorage.setItem(IS_EDITING, "Y");
                  setOpenCompanyProfileCard(false)
                }
              }}
                
              > */}
                {/* <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={usersvg}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>
                    {context?.UserAuth?.usr_type === "customer" || context?.UserAuth?.usr_type !== LOGGED_IN_USER_ROLE && context?.UserAuth?.usr_type === null ? Config?.myprofile_tab_1:Config?.mycompanyprofile_tab}
                    {Config?.myprofile_tab_1}
                    </div>
                </div> */}
                {/* {loading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_profile === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp
                            end={profileData?.completeness_my_profile}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )} */}
                {/* </div> */}
                {false && <div
                  className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                  onClick={() => {
                    navigate("/add-address");
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                    <img
                      src={ManageAddress}
                      alt="Icon"
                      style={{ height: "2rem", width: "2rem" }}
                      draggable="false"
                    />
                    <div className={`${Styles?.profile_grid_title}`}>{Config?.myprofile_tab_2}</div>
                  </div>
                  {loading ? (
                    <div className={`${Styles?.shimmerWrap}`}>
                      <ShimmerThumbnail width={150} height={10} rounded />
                    </div>
                  ) : (
                    <div className={`${Styles?.profileGrid_1_child_data}`}>
                      {profileData?.completeness_my_address === 100 ? (
                        <>
                          <div className={`${Styles?.progress}`}>
                            <div className={`${Styles?.progress_value_1}`}></div>
                          </div>
                          {/* Documentation https://www.npmjs.com/package/react-countup */}
                          <div className={`${Styles?.profile_grid_count}`}>
                            <CountUp
                              end={profileData?.completeness_my_address}
                              duration={3}
                            />
                            %
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={`${Styles?.progress}`}>
                            <div className={`${Styles?.progress_value_2}`}></div>
                          </div>
                          {/* Documentation https://www.npmjs.com/package/react-countup */}
                          <div className={`${Styles?.profile_grid_count}`}>
                            <CountUp end={0} duration={3} />%
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>}
                {/* <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/manage-payments");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={Payments}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>{Config?.myprofile_tab_3}</div>
                </div>
                {checkDataLoading === true ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {checkData === true ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={100} duration={3} />%
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div> */}
                {/* {context?.UserAuth?.usr_type ==="pro" &&  <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                 sessionStorage.setItem('document_Update', "Y"); */}
                {/* // if(firstloggedin==="Y"){
                  setOpenMyDocuments(true); */}
                {/* // navigate("/my-health");
                  // window.scrollTo(0, 0);
                }}
              > */}
                {/* <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={HealthInfo}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>{Config?. myprofile_tab_7}</div>
                </div>
                {loading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_manage_documents === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp
                            end={profileData?.completeness_manage_documents}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>} */}


                {/* {context?.UserAuth?.usr_type ==="customer" &&  <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-health");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={HealthInfo}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>{Config?.myprofile_tab_4}</div>
                </div>
                {loading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_health_info === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp
                            end={profileData?.completeness_my_health_info}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>} */}
                {/* { context?.UserAuth?.usr_type === "customer" && <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-health-card");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={HealthCard}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>{Config?.myprofile_tab_5}</div>
                </div>
                {loading === null ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_health_cards === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp
                            end={profileData?.completeness_my_health_cards}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>} */}
                {/* {context?.UserAuth?.usr_type === "customer" && <div
                className={`${Styles?.profileGrid_1_child} ${Styles?.profileGrid_1_child_2}`}
                onClick={() => {
                  navigate("/my-insurance");
                  window.scrollTo(0, 0);
                }}
              >
                <div className={`${Styles?.profileGrid_1_child_dataName}`}>
                  <img
                    src={Insurance}
                    alt="Icon"
                    style={{ height: "2rem", width: "2rem" }}
                    draggable="false"
                  />
                  <div className={`${Styles?.profile_grid_title}`}>{Config?.myprofile_tab_6}</div>
                </div>
                {loading ? (
                  <div className={`${Styles?.shimmerWrap}`}>
                    <ShimmerThumbnail width={150} height={10} rounded />
                  </div>
                ) : (
                  <div className={`${Styles?.profileGrid_1_child_data}`}>
                    {profileData?.completeness_my_insurance_cards === 100 ? (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_1}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp
                            end={profileData?.completeness_my_insurance_cards}
                            duration={3}
                          />
                          %
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${Styles?.progress}`}>
                          <div className={`${Styles?.progress_value_2}`}></div>
                        </div> */}
                {/* Documentation https://www.npmjs.com/package/react-countup */}
                {/* <div className={`${Styles?.profile_grid_count}`}>
                          <CountUp end={0} duration={3} />%
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>} */}
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>

      {isEditModalOpen && (
        <EditProfile
          setIsEditModalOpen={setIsEditModalOpen}
          setModalOpen={setModalOpen}
          editHeading={"Edit Profile"}
          userInfoHandler={userInfohandler}
          userInfo={userInfo}
          profileForm={profileForm}
          setProfileForm={setProfileForm}
        />
      )}
      {/* {isEditCompanyModalOpen && <div className={`${Styles?.modalBackground}`}>
        <div className={`${Styles?.modalContainer}`}>
        <CompanyProfile
        // setModalOpen={setIsEditCompanyModalOpen}
        // setOpenCreateCompanyCard={setOpenCreateCompanyCard}
        setOpenCompanyProfileCard={setIsEditCompanyModalOpen}
        handleNext={() => {
          // setIsEditCompanyModalOpen(false);
          setIsEditCompanyModalOpen(false);
          setOpenCreateCompanyCard(true);
          // setOpenSelectType(false);
        }}
        handleBack={() => {
          // setIsEditCompanyModalOpen(false);
          setOpenCompanyProfileCard(false);
          setOpenCreateCompanyCard(false);
          // setOpenSelectType(true);
        }}

          // setIsEditModalOpen={setIsEditModalOpen}
          // editHeading={"Edit Profile"}
          // userInfoHandler={userInfohandler}
          // userInfo={userInfo}
          // profileForm={profileForm}
          // setProfileForm={setProfileForm}
        />
        </div></div>
      } */}
      {openCreateCompanyCard &&
        <div className={`${Styles?.modalBackground}`}>
          <div className={`${Styles?.modalContainer}`}>
            <CreateAccount
              setOpenCreateCompanyCard={setOpenCreateCompanyCard}
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              handleNext={() => {
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);

                if (getDecryptedID(IS_EDITING) === "Y") {
                  setOpenMyDocuments(false);
                } else {
                  setOpenMyDocuments(true);
                }
                // }
              }}
              setIsAlert={setIsAlert}
              //@ts-ignore
              setOpenCreateCompanyCard={setOpenCreateCompanyCard}
              setOpenCompanyProfileCard={setOpenCompanyProfileCard}
              handleBack={() => {
                // setIsEditCompanyModalOpen(true);
                setOpenCreateCompanyCard(false);
              }}
              userInfohandler={userInfohandler}
            />
          </div>
        </div>
      }
      {openMyDocuments && (
        <div className={`${Styles?.modalBackground}`}>
          <div className={`${Styles?.modalContainer}`}>
            <MyDocuments
              setOpenMyDocuments={setOpenMyDocuments}
              setOpenDocViewer={setOpenDocViewer}
              setOpenTermModalPopup={setOpenTermModalPopup}
              handleNext={() => {
                setOpenCreateCompanyCard(false);
                setOpenCompanyProfileCard(false);

              }}
              checked={checked}
              handleBack={() => {
                setOpenMyDocuments(false);
                setOpenTermModalPopup(false);
                // setOpenCreateCompanyCard(true);
              }}
            />
          </div>
        </div>
      )}
      {openTermModalPopup && (
        <TermModalPopup
          checked={checked}
          setChecked={setChecked}
          handleNext={() => {
            setOpenTermModalPopup(false);
            setOpenMyDocuments(true);
          }}
        />

      )}

      {openDocViewer?.isOpen && (
        <div className={`${Styles?.modalBackground}`}>
          <div className={`${Styles?.modalContainer}`}>
            <DocViewer
              openDocViewer={openDocViewer}
              setOpenDocViewer={setOpenDocViewer}
            />
          </div>
        </div>
      )}

    </>
  );
};

export default MyProfile;
