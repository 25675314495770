import React, { useState, useEffect } from "react";
import TwilioConversation from "./TwilioConversation";
import axios from "axios";
import AddConversationModal from "./Modal/AddConversationModal";
import { ButtonCodeChecker, decryptObj, getID } from "./Config/function";
import { SPD_MENU_BUTTON_CODE, SPD_ALL_BUTTON } from "./Config/config";
import { spd_processId_config } from "./Config/processID";
import { Client } from '@twilio/conversations';
import audioUrl from "./Assets/alert.wav";
// import './index.css';
// import './branding.css';
import './chatmodel.css';
import { JSONCheckerFunc } from "../../../../utils/utils";

var queueVisited = false
var spd_user_id = ""
var spd_user_name = ""
const ChatModal = ({
  token,
  twilioUrl,
  id,
  name,
  organisationId,
  userType,
  email,
  CallAPI,
  messageService,
  isGuest,
  guestData,
}) => {
  const [showChat, setShowChat] = useState(false);
  const [addConversation, setAddConversation] = useState(false);
  const [client, setClient] = useState(null);
  const [availableUsers, setAvailableUsers] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)
  const [playSound, setPlaySound] = useState(false);
  const [customChannel, setCustomChannel] = useState([]);
  const [unjoinedGroups, setunjoinedGroups] = useState([]);
  const [tabs, setTabs] = useState("CHAT")
  const [hideTabs, setHideTabs] = useState(false);
  const [queueCount, setQueueCount] = useState(0)
  const onShowSearch = event => {
    setSearchActive(current => !current);
  };
  const [activeUsers, setActiveUsers] = useState([])

  const [state, setState] = useState({
    name: id,
    loggedIn: false,
    token: "",
    clientLoaded: true,
    singleConversation: false,
    conversationsReady: false,
    messages: [],
    newMessage: "",
    conversations: [],
    loadingMessage: "",
    selectedConversationSid: "",
    isQueueJoined: false,
    joinedQueueSid: null
  });
  spd_user_id = id
  spd_user_name = name

  /**************************  SHOW CHAT MODAL START *******************************/
  messageService.onMessage().subscribe((m) => {
    try {
      if (m.target === "chat_side_modal" && m.senderId === "TopHeader") {
        if (m.text) {
          setShowChat(true);
        } else {
          setShowChat(false);
          setTimeout(() => {
            messageService.sendMessage("Chat_closed", {}, "TwilioConv");
          }, 1000)
        }
      }
    } catch (e) {
      console.log(e?.message, "ERROR :");
    }
  });
  /**************************  SHOW CHAT MODAL END *******************************/
  /************************** GET REGISTERD USER LIST START *******************************/
  const updateUserAttributes = async () => {
    try {
      const id = spd_user_id;
      await axios({
        method: "POST",
        url: `${twilioUrl}/chat/updateUser/${id}`,
        data: {
          friendlyName: name,
          attributes: {
            orgId: organisationId ? organisationId : null,
            usr_role: userType ? userType : "guest",
            email: email ? email : ''
          },
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      await axios({
        method: "GET",
        url: `${twilioUrl}/reachability`,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    catch (e) {
      console.log(e?.message, "ERROR :");
    }
  }

  const getCustomChannel = async () => {
    const customData = await CallAPI(isGuest ? spd_processId_config?.sgconf_get_mst_user_contact_group_mapping_for_non_dialog_list_for_guest : spd_processId_config?.sgconf_get_mst_user_contact_group_mapping_for_non_dialog_list, {
    })
    var data = []
    customData?.returnData?.forEach(v => {
      const ids = JSONCheckerFunc(v?.usr_info)
      const body = {
        friendlyName: v?.contact_group_name,
        sid: v?.cg_id,
        identity: null,
        createdBy: null,
        dateCreated: "Wed Nov 01 2022 11: 32: 36 GMT + 0530(India Standard Time)",
        dateUpdated: "Wed Nov 01 2022 11: 32: 36 GMT + 0530(India Standard Time)",
        lastMessage: undefined,
        attributes: {
          cg_id: v?.cg_id,
          friendlyName: v?.contact_group_name,
          contact_group_description: v?.contact_group_description,
          contact_group_image: v?.contact_group_image,
          contact_group_name: v?.contact_group_name,
          contact_group_type: v?.contact_group_type,
          icon_class_name: v?.icon_class_name,
          qb_dialog_joining_type: v?.qb_dialog_joining_type,
          staffs: ids?.map(v => ({ ...v, usr_id: v?.usr_id.toString() })),
          usr_ids: ids?.map(v => ({ ...v, usr_id: v?.usr_id.toString() })),
          type: "queue"
        }
      }
      return data?.push(body)
    })
    setCustomChannel(data)
    // set unjoined groups data for further use
    setunjoinedGroups(customData?.returnData);
  }
  /********************** MindEMR SUPPORT*************************************** */
  useEffect(() => {
    getCustomChannel()
  }, []);

  const generateToken = async (val, conv) => {
    // CREATING TOKEN FOR THE SELECTED USER TO ACTIVATE ACCOUNT
    val?.forEach(async (v) => {
      await axios({
        method: "GET",
        url: `${twilioUrl}/chat/token?identity=${encodeURIComponent(
          v
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response?.data?.token) {
          new Client(response?.data?.token);
          addNewUser(v, conv);
        }
      });
    })
  }


  const addNewUser = async (v, conv) => {
    try {
      var availableUsersId
      await axios({
        method: "GET",
        url: `${twilioUrl}/chat/users`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        availableUsersId = res?.data?.users?.map((val) => val?.identity)
        setAvailableUsers(res?.data?.users);
      })
      availableUsersId?.forEach(async (user) => { if (user !== spd_user_id) await conv.add(user) })
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };



  const createCustomeGroup = (val) => {
    val?.forEach(async (v) => {
      const conversation = await client?.createConversation({
        friendlyName: v?.contact_group_name
      })
      await conversation?.join();
      await conversation?.getParticipants();
      const availableUsersId = availableUsers?.map(m => m.identity)
      const notAvalIDs = v?.user_ids.split(',')?.filter(n => !availableUsersId?.includes(n)) || []
      const attributes = {
        cg_id: v?.cg_id,
        friendlyName: `${v?.contact_group_name} ~ ${spd_user_name}`,
        contact_group_image: v?.contact_group_image,
        icon_class_name: v?.icon_class_name,
        joined_user_id: [spd_user_id],
        type: "group"
      };
      await conversation?.updateAttributes(attributes);
      const includingMe = [{ value: spd_user_id, label: spd_user_name }]
      includingMe?.forEach(async (l) => {
        conversation?._participants?.forEach(async (val) => {
          if (val?.identity === l?.value) {
            await axios({
              method: "POST",
              data: {
                partiSid: val?.sid,
                convoSid: conversation?.sid,
                attributes: {
                  userID: l?.value,
                  userName: l?.label,
                },
              },
              url: `${twilioUrl}/chat/updateParti`,
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        })
      })
      if (notAvalIDs?.length > 0) {
        generateToken(notAvalIDs, conversation)
      }
      else {
        v?.user_ids?.split(',').forEach(async (user) => { if (user !== spd_user_id) { await conversation.add(user) } })
      }
    })
  }



  const getAllusers = async () => {
    await axios({
      method: "GET",
      url: `${twilioUrl}/chat/users`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setAvailableUsers(res?.data?.users);
    });
    await updateUserAttributes()
  }

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        getAllusers()
      }, 1500)
    }
  }, [token]);

  useEffect(() => {
     messageService.sendMessage("TopHeader", showChat, "showChatModal");
  }, [showChat])

  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((m) => {

      if (m.senderId === "message_added" && m.target === "mediaUrl") {
        if (m?.text?.conversation?.sid === state?.selectedConversationSid) {
          setTotalUnreadCount(0)
        }
        const presentSid = state?.conversations?.map(con => con?.sid)
        if (m?.text?.conversation?.sid !== state?.selectedConversationSid && presentSid?.includes(m?.text?.conversation?.sid)) {
          setTotalUnreadCount(prev => prev + 1)
          setPlaySound(true)
        }
      }
      if (m.senderId === "conversationAdded" && m.target === "twilioChat") {
        if (tabs === "CHAT") {
          queueVisited = false
        }
      }
      if (m.senderId === "queueCount" && m.target === "conversationList") {
        setQueueCount(prev => {
          if (m?.text > prev) {
            queueVisited = false
            return m.text
          }
          if (m?.text === 0) {
            queueVisited = true
            return 0
          }
          if (m?.text < prev) {
            queueVisited = true
            return m?.text
          }
          return prev
        })
      }
    });
    // // return unsubscribe method to execute when component unmounts
    return () => {
      if (subscription != null && subscription != undefined) {
        subscription.unsubscribe();
      }
    }
  }, [state]);
  useEffect(() => {
    if (totalUnreadCount > 0 && playSound) {
      if (totalUnreadCount % 5 === 0) {
        const audio = new Audio(audioUrl)
        audio.play()
      }
    }
  }, [totalUnreadCount, playSound])

  useEffect(() => {
    if (tabs === "QUEUE") {
      queueVisited = true
    }
    else {
      if (queueCount > 0 && tabs === "CHAT") {
        queueVisited = true
      }
      else {
        if (queueCount === 0 && tabs === "CHAT") {
          queueVisited = false
        }
      }
    }
  }, [tabs, queueCount])


  useEffect(() => {
    if (queueCount > 0 && playSound && tabs !== "QUEUE" && !queueVisited && showChat) {
      // if (totalUnreadCount % 5 === 0) {
      const audio = new Audio(audioUrl)
      audio.play()
      // }
    }
  }, [queueCount, playSound, tabs, queueVisited, showChat])

  useEffect(() => {
    if (state?.joinedQueueSid !== null) {
      setState((prev) => ({
        ...prev,
        selectedConversationSid: state?.joinedQueueSid,
      }));
    }
  }, [state?.isQueueJoined])
  /************************** GET REGISTERD USER LIST END *******************************/
  try {
    return (
      <div
        className={searchActive ? 'convoChat-container search-active' : 'convoChat-container'}
        style={{
          display: showChat ? "block" : "none",
        }}
      >
        <div id="caller-name">
          <div className="voip-head ">
            {/* <i class="fa fa-comments text-blue" aria-hidden="true" />{" "} */}
            <div className="marginLeft-1 text-blue chatModal"><i className="fa fa-comments" aria-hidden="true"></i> Chat {(totalUnreadCount > 0 && state?.selectedConversationSid) && <span className="data-count">{totalUnreadCount}</span>}</div>
            <div className="name">
              <h2 id="h2_caller_name"> </h2>
            </div>
          </div>
          <div className="ui-flex chatbox-header-right">
            {state?.selectedConversationSid === "" && userType === "staff" && !addConversation && ButtonCodeChecker(decryptObj(getID(SPD_ALL_BUTTON)), SPD_MENU_BUTTON_CODE?.HV_BTN_TWILIO_CHAT_SEARCH_USER) &&
              <button className="chatbox-searchbtn" title={"search"} onClick={onShowSearch}> <i className="fa fa-search" aria-hidden="true"></i></button>
            }
            {ButtonCodeChecker(decryptObj(getID(SPD_ALL_BUTTON)), SPD_MENU_BUTTON_CODE?.HV_BTN_TWILIO_CHAT_CREATE_GROUP) && userType === "staff" &&
              <button
                className="filter ui-button-outline ui-button-small marginRight-1 active addmore-btn"
                type="button"
                title={"Add Group"}
                onClick={(e) => {
                  e.preventDefault();
                  setAddConversation(true);
                  setSearchActive(false)
                }}
              >+</button>
            }
            <button
              className="ui-button-textOnly closeBtn"
              title={"close"}
              onClick={() => {
                setShowChat(!showChat);
                if (!state?.isQueueJoined) {
                  queueVisited = false;
                  messageService.sendMessage(
                    "topHeader",
                    false,
                    "playSound"
                  );
                  setSearchActive(false)
                  setTabs("CHAT")
                  setHideTabs(false)
                  setAddConversation(false);
                  // setState(prev => ({ ...prev, selectedConversationSid: "" }))
                }
              }}
            >
              {/* <i className="fa fa-close text-blue  " /> */}
              <i className="fa">&#x2715;</i>
            </button>
          </div>
          {userType === "staff" && !hideTabs && <div className="convoChat-tabs">
            <button
              className={`filter ui-button-outline ui-button-small marginRight-1 ${tabs === "CHAT" && "active"}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setTabs("CHAT");
                setAddConversation(false);
                setState(prev => ({ ...prev, selectedConversationSid: "" }))
              }}
            >
              Chats
            </button>
            <button
              className={`filter ui-button-outline ui-button-small marginRight-1 ${tabs === "QUEUE" && "active"}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setTabs("QUEUE");
                setAddConversation(false);
                setState(prev => ({ ...prev, selectedConversationSid: "" }))
              }}
            >
              <div className="chatModal">
                Queue  {queueCount > 0 && tabs !== "QUEUE" && <span className="data-count">{queueCount}</span>}
              </div>
            </button>
          </div>}
        </div>

        <div className="twillio-chatbox">
          <TwilioConversation
            token={token}
            id={id}
            name={name}
            messageService={messageService}
            twilioUrl={twilioUrl}
            userType={userType}
            CallAPI={CallAPI}
            setQueueCount={setQueueCount}
            setAvailableUsers={setAvailableUsers}
            tabs={tabs}
            setHideTabs={setHideTabs}
            customChannel={customChannel}
            unjoinedGroups={unjoinedGroups}
            searchActive={searchActive}
            availableUsers={availableUsers}
            setAddConversation={setAddConversation}
            addConversation={addConversation}
            client={client}
            setTabs={setTabs}
            isGuest={isGuest}
            guestData={guestData}
            setTotalUnreadCount={setTotalUnreadCount}
            setClient={setClient}
            state={state}
            setSearchActive={setSearchActive}
            setState={setState}
            setActiveUsers={setActiveUsers}
            activeUsers={activeUsers}
            showChat={showChat}
          />

          {addConversation && (
            <div className="add-conversation">
              <AddConversationModal
                id={id}
                username={name}
                twilioUrl={twilioUrl}
                client={client}
                setAvailableUsers={setAvailableUsers}
                onConversationClick={(item) => {
                  setState((prev) => ({
                    ...prev,
                    selectedConversationSid: item,
                  }));
                }}
                availableUsers={availableUsers}
                setAddConversation={setAddConversation}
              />
            </div>
          )}
        </div>
      </div >
    );
  } catch (err) {
    console.log("Error: ", err.message);
  }
};

export default ChatModal;
