import React, { useEffect, useRef, useState } from "react";
import "./ContextMenu.css"; // Import custom CSS file for styling
import DeleteConfirmPopup from "../../pages/MyListPage/DeleteConfirmPopup";

const ContextMenu = (props) => {
  const { options, id, setSelectedList } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const menuRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className="menu-container"
        ref={menuRef}
        key={"contextMenu_MyList_" + id}
        onClick={()=>{
          setSelectedList((prev)=>{
            return {...prev,optId : id}
          });
        }}
      >
        <button className="menu-button" onClick={handleClick}>
          <div className={`menu-icon ${isOpen ? "open" : ""}`}></div>
          <div className={`menu-icon ${isOpen ? "open" : ""}`}></div>
          <div className={`menu-icon ${isOpen ? "open" : ""}`}></div>
        </button>
        {isOpen && (
          <ul className="menu-items">
            {options?.map((i, idx) => (
              <li
                className="menu-item"
                onClick={(e) => {
                  i.onClick(e, id);
                  handleClose();
                }}
                key={"contextMenu_MyList_" + id + "_option_" + idx}
              >
                {i.value}
              </li>
            ))}
          </ul>
        )}
      </div>
      {props.children}
    </>
  );
};

export default ContextMenu;
