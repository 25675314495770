import React, { useState, useContext } from "react";
import APIContext from "../../../../store/api-context";
import Button from "../../../../components/UI/Button";
import Input from "../../../../components/UI/Input";
import Label from "../../../../components/UI/Label";
import { Link, useNavigate } from "react-router-dom";
import Styles from "./index.module.css";
import Config from "../../../../config/label";
import { AppLogo } from "../../../../utils/appIcons";
import Heading from "../../../../components/UI/Heading";
import { CallAPI } from "../../../../suggest-us/callSuggest.js";
import { config } from "../../../../config/processId.js";
import { UserAuthContext } from "../../../../store/UserAuthContext.js";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../../components/UI/FormInput";
import { msg_config } from "../../../../config/messages";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import ForgotPassword from "../ForgotPassword";
import { objDecrypt, objEncrypt, removeKey, setEncryptedID, getDecryptedID, JSONCheckerFunc } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { signUpVendorActions } from "../../../SignUpVendor/slice/slice";
import { DEFAULT_FLAG, FIRST_TIME_LOGIN, HEALTH_INFO, IS_CHECKED, IS_DOCS_PENDING, IS_PROFILE_UPDATED, LOGGED_IN_USER_ROLE, PASSWORD_MSG, PHONE_NO, SIGN_IN_WITH, USER_LOGGED_IN, USER_PROFILE } from "../../../../config/Config";
export type RegistrationFormFields = {
  password: string;
  propsshowForgetPassword?:any;
};

var md5 = require("md5");

function EnterPassword(props: any) {
  const [context, setContext] = useContext(UserAuthContext);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const userWelcomeMsg = getDecryptedID(PASSWORD_MSG) || "Please enter your password";
  const [isAlertChngPsw, setIsAlertChngPsw] = useState({
    istrue: "",
    type: "",
    msg: "",
  });
  const [passwordValue, setPasswordValue] = useState("");
  const userInfo: any = getDecryptedID(HEALTH_INFO);
  const _userInfo = JSONCheckerFunc(userInfo);
  const gaEventTracker = useAnalyticsEventTracker("Enter password page");
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { setIsLoading, setIsAlert, isLoading,propsshowForgetPassword} = props;

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<RegistrationFormFields>({
    defaultValues: {},
  });

  const setSuggestUsIDs = (patientID: any, roleID: any, userID: any) => {
    patientID(patientID);
    roleID(roleID);
    userID(userID);
  };
 const dispatch = useDispatch();
  const [suggestUsInit] = useContext(APIContext);

  const phone_no = getDecryptedID(PHONE_NO);

  const verifyPasswordHandler = (data: any) => {
    setIsAlert({ type: "", msg: "" });

    if (data?.password !== " " && suggestUsInit?.returnCode === true) {
      setIsLoading(true);
      CallAPI(config?.verify_User_password_process_id, {
        p_login: phone_no,
        p_password: md5(data?.password),
      }).then((res) => {
        setIsLoading(false);
       
        
        if (res?.returnCode === true) {
          removeKey(PASSWORD_MSG);
          //if user details not found show update profile popup
          if(res?.returnData[0]?.usr_type ==="customer" &&  res?.returnData[0].usr_name?.trim("") === ""){
            setEncryptedID(IS_PROFILE_UPDATED, "N")
          }else {
            setEncryptedID(IS_PROFILE_UPDATED, "Y")
          }
          if (getDecryptedID(IS_CHECKED) === "Y") {
            setEncryptedID(DEFAULT_FLAG,{ flag: "N", date: new Date() });
          } else {
            setEncryptedID(DEFAULT_FLAG,{ flag: "Y", date: new Date() });
          }
          removeKey(IS_CHECKED);

          // setIsAlert({ type: "success", msg: res?.msg });
          // navigate("/");
          gaEventTracker("User login by entering password");
          if (res?.returnData?.length > 0) {
            setEncryptedID(USER_PROFILE,res?.returnData[0]);
            if(res?.returnData[0]?.usr_type === LOGGED_IN_USER_ROLE){
              if(res?.returnData[0]?.usr_onboarding_status === "documents_pending"){
                setEncryptedID(IS_DOCS_PENDING, "Y")
              }else {
                setEncryptedID(IS_DOCS_PENDING, "N")
              }
              if(res?.returnData[0]?.usr_company_name === null ){
                setEncryptedID(FIRST_TIME_LOGIN, "Y");
              }else {
                setEncryptedID(FIRST_TIME_LOGIN, "N");
              }

              dispatch(signUpVendorActions?.restoreFormFields({
                p_first_name: res?.returnData[0]?.usr_first_name || "",
                p_last_name: res?.returnData[0]?.usr_last_name || "",
                p_company_email: res?.returnData[0]?.usr_email || "",
                p_company_alternate_email: res?.returnData[0]?.usr_company_alternate_email,
                p_company_landline_no: res?.returnData[0]?.usr_company_landline_no,
                p_company_alternate_phone: res?.returnData[0]?.usr_company_alternate_phone,
                p_company_size: res?.returnData[0]?.usr_company_size,
                p_company_gstin_no: res?.returnData[0]?.usr_company_gstin_no,
                p_legal_company_name: res?.returnData[0]?.usr_company_name,
                p_company_postal_code: res?.returnData[0]?.usr_company_postal,
                p_user_type: res?.returnData[0]?.usr_type,
              }))
            }
            setEncryptedID(USER_LOGGED_IN, "Y");
            setEncryptedID(SIGN_IN_WITH, "phone");

            // setSuggestUsIDs(
            //   res?.returnData[0]?.patient_id,
            //   res?.returnData[0]?.rol_id,
            //   res?.returnData[0]?.usr_id
            // );
            setContext((prev: any) => {
              return {
                ...prev,
                UserAuth: { ...res?.returnData[0] },
                isUserLoggedIn : true,
              };
            });
            navigate("/");
          }
        } else {
          setIsAlert({
            type: "error",
            msg: msg_config?.loginpage_password_valid,
          });
        }
      });
    } else {
      setIsAlert({
        type: "error",
        msg: msg_config?.loginpage_password_valid,
      });
    }
  };

  // Function to remove spaces form a string
  const cleanInput = (inp: any) => {
    if (inp) {
      return inp.replace(/\s/g, "");
    }
    return "";
  };

  // Function to remove special characters,numbers,space from a string
  const cleanPersonNameInput = (inp: any) => {
    if (inp) {
      return inp.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "");
    }
    return "";
  };

  const onInputChangeHandler = (e: any, fieldName: any) => {
    if (e.target.value.trim() === "") {
      setPasswordValue("");
      //@ts-ignore
      setValue(`${[fieldName]}`, "");
    } else if (fieldName === "email" || fieldName === "password") {
      let newString = cleanInput(e.target.value);
      setPasswordValue(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
      if (newString !== "") {
        if (fieldName === "password") {
          clearErrors("password");
        }
      }
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "p_firstName" ||
      fieldName === "p_lastName"
    ) {
      let newString = cleanPersonNameInput(e.target.value);

      setPasswordValue(newString);
      //@ts-ignore
      setValue(`${[fieldName]}`, newString);
    } else {
      setPasswordValue(e.target.value);
      //@ts-ignore
      setValue(`${[fieldName]}`, e.target.value);
    }
  };

  return (
    <>
      {/* <Link className={`${Styles?.appLogo}`} to="/" draggable="false">
        <img src={AppLogo} alt="app_logo" draggable="false" />
      </Link> */}
      <div className={Styles["loginContainer"]}>
        <form onSubmit={handleSubmit(verifyPasswordHandler)}>
          <h3 className={Styles["enter_pass_title"]}>Password</h3> 
        <div className={Styles['welocmeMsg']}>
      <h4>{userWelcomeMsg}</h4>
      <span
      onClick={()=>{navigate("/log-in")}}
      >Change Phone Number</span>
      </div>
          <span className={`${Styles?.confirmlabel}`}>
            {/* {Config?.lbl_confirm} */}
          </span>

          <div className={Styles["inputField"]}>
            <div style={{ marginTop: "0px" }}>
              <Label className={`${Styles?.form_label}`} for="password">
                {Config?.lbl_Login_Psw}
                <div
                  className={Styles["Link"]}
                  onClick={() =>propsshowForgetPassword(true)
                    // setIsPasswordModalOpen(true)
                  }
                >
                  <p style={{ color: "rgb(47, 151, 231)", cursor: "pointer" }}>
                    {Config?.lbl_Forgot}
                  </p>
                </div>
              </Label>

              <FormInput<RegistrationFormFields>
                name="password"
                id="password"
                type={Config?.lbl_Inp_type_pass}
                placeholder={Config?.inp_P_Holder_Login_Psw}
                size={Config?.lbl_Inp_size_medium}
                style={{ width: "100%" }}
                inputAutoFocus={true}
                register={register}
                rules={{ required: msg_config?.psw_phone_required }}
                errors={errors}
                maxLength={20}
                value={passwordValue}
                onInputChangeHandler={(e: any) => {
                  onInputChangeHandler(e, "password");
                }}
              />
            </div>
          </div>

          <div className={Styles["verifyButton"]}>
            {/* <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              style={{ width: "360px", margin:"auto" }}
              loading={isLoading}
              onClick={()=>{
                navigate("/log-in")
              }}
              htmlType={"button"}

            >
              
              {Config?.health_Card_Upload_back}
            </Button> */}
            <Button
              type={Config?.lbl_Btn_type_primary}
              size={Config?.lbl_Btn_size_medium}
              style={{ width: "360px", margin: "auto" }}
              loading={isLoading}
              htmlType={"submit"}
            >
              {Config?.lbl_verify_Btn}
            </Button>
          </div>
        </form>
      </div>
      {/* {isPasswordModalOpen && (
        <ForgotPassword
          setIsPasswordModalOpen={setIsPasswordModalOpen}
          setIsAlertChngPsw={setIsAlertChngPsw}
        />
      )} */}
    </>
  );
}

export default EnterPassword;
