import Config from "../../../../config/label";
import Styles from "./ConfirmDelete.module.css";
import CloseIcon from "../../../../assets/svg/close.svg";
import { CONFIRM_DELETE_POPUP_IMAGE } from "../../../../config/Config";
import Heading from "../../../../components/UI/Heading";
import Button from "../../../../components/UI/Button";


const ConfirmDocDelete = (props) => {
    const { toDeleteData, setToDeleteData, loading} = props;
    return (
        <div className={Styles["modalBackground"]}>
            <div className={Styles["modalContainer"]}>
                <div
                    className={Styles["titleCloseBtn"]}
                    title="Close button"
                    onClick={() => {
                        setToDeleteData((prev)=>{
                            return {...prev, openConfirm : false}
                        })
                    }}
                >
                    <img src={CloseIcon} alt="Cross icon" draggable="false" />
                </div>
                <div className={Styles["title"]}>
                    <div className={Styles["checkIconWrapper"]}>
                        <img src={CONFIRM_DELETE_POPUP_IMAGE} alt="Cart icon" draggable="false" />
                    </div>
                    <Heading className={`${Styles?.page_heading}`}>
                        {Config?.lbl_delete_req_txt}
                    </Heading>
                    <p></p>
                </div>
                <div className={Styles["buttons"]}>
                    <Button className={Styles["login_cancel_btn"]}
                        type={Config?.lbl_Btn_type_ghost}
                        size={Config?.lbl_Btn_size_medium}

                        onClick={() => {
                            setToDeleteData((prev)=>{
                                return {...prev, openConfirm : false}
                            })
                        }}
                    >
                        {Config?.lbl_cancel_txt}
                    </Button>

                    {/* <Link
                to="/log-in"
                style={{ textDecoration: "none" }}
                draggable="false"
              > */}
                    <Button
                        type={Config?.lbl_Btn_type_primary}
                        size={Config?.lbl_Btn_size_medium}
                        loading={loading}
                        onClick={()=>{
                            setToDeleteData((prev)=>{
                                return {...prev, delete :true}
                            })
                        }}
                    >
                        {Config?.lbl_delete_txt}
                    </Button>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    );
};
export default ConfirmDocDelete;