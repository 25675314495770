export function searchJSONArray(jsonData, searchString) {
  const results = [];
  let foundMatch = false;

  jsonData.forEach((obj, index) => {
    if (
      (obj['srv_name']?.toString().toLowerCase().includes(searchString.toLowerCase())) ||
      (obj['srv_brand']?.toString().toLowerCase().includes(searchString.toLowerCase()))
    ) {
      results.push({ ...obj, isActive: true });
      foundMatch = true;
    }
  });

  if (results.length === 0) {
    return [];
  }

  return results;
}
