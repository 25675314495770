import React from 'react';
import ChatModal from './components/ChatModal/ChatModal';

export const ChatModalExport = ({
  token,
  twilioUrl,
  userId,
  userName,
  organisationId,
  userType,
  userEmail,
  CallAPI,
  messageService,
  isGuest,
}) => {

  return (
    <ChatModal
      token={token || ""}
      twilioUrl={twilioUrl || ""}
      id={userId || ""}
      name={userName || ""}
      organisationId={organisationId || ""}
      userType={userType || "guest"}
      email={userEmail || ""}
      CallAPI={CallAPI || null}
      messageService={messageService || null}
      isGuest={isGuest || false}
    />
  )
}