import React from "react";
import Styles from "./index.module.css";

export type RatingProps = {
  count?: number;
  className?: string;
  starClassName?: string;
};
const StarRating: React.FC<RatingProps> = (props) => {
  const { count = 5, className, starClassName } = props;
  return (
    <div className={`${Styles?.starRating} `}>
      {[...Array(count)].map((star) => {
        return (
          <span className={`${Styles?.star} ${starClassName}`}>&#9733;</span>
        );
      })}
    </div>
  );
};
export default StarRating;
