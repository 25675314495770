import { DatePickerInput } from "rc-datepicker";

export type DigitProps = {
  placeholder: string;
  onChange?: any;
  style?: any;
  value?: any;
  className?: any;
  small?: any;
  dateFormat?: any;
  disabled?: any;
  defaultValue?: any;
  register?: any;
  maxDate?: any;
  minDate?: any;
};

const DatePicker: React.FC<DigitProps> = (props) => {
  const {
    placeholder,
    onChange,
    style,
    value,
    className,
    small,
    dateFormat,
    disabled,
    defaultValue,
    register,
    maxDate,
    minDate,
  } = props;

  return (
    <DatePickerInput
      onChange={(val) => onChange(val)}
      value={value}
      style={style}
      placeholder={placeholder}
      className={className}
      small={small}
      displayFormat={dateFormat}
      showOnInputClick={true}
      disabled={disabled}
      defaultValue={defaultValue}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};

export default DatePicker;
