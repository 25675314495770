import React from "react";
import s from "./index.module.css";

export type CardProps = {
  style?: object;
  className?: string;
};

const Card: React.FC<CardProps> = (props) => {
  const { style, className } = props;

  return (
    <div className={`${s?.container} ${className}`} style={{ ...style }}>
      {props.children}
    </div>
  );
};

export default Card;
